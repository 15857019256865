import { getSession } from '@techstyle/redux-core';

import {
  TRACK_QUICK_VIEW_MODAL_OPEN,
  TRACK_QUICK_VIEW_UPDATE_CART,
  TRACK_VIEW_CLICK_FULL_DETAILS,
  TRACK_QUICK_VIEW_CHANGE_PRODUCT_ATTRIBUTE,
  TRACK_QUICK_VIEW_ADD_TO_CART,
} from '../../actions/quickView';
import { getPartialTrackingData } from '../utils';

const trackQuickView = ({ getContext, trackEvent }) => {
  const trackModalOpen = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Button Click',
      properties: {
        feature: 'react',
        category: 'Quick View',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        label: action.payload.label,
      },
    };
  });

  const trackUpdateCart = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Button Click',
      properties: {
        feature: 'react',
        category: 'Quick View',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        label: 'Update Cart',
      },
    };
  });

  const trackAddToCart = trackEvent((action, prevState, nextState) => {
    const { payload } = action;

    const { cookies } = getContext();
    return {
      name: 'Button Click',
      properties: {
        feature: 'react',
        category: 'Quick View',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        label: payload,
      },
    };
  });

  const trackClickFullDetails = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Button Click',
      properties: {
        feature: 'react',
        category: 'Quick View',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        label: 'View Full Details',
      },
    };
  });

  const trackChangeProductAttribute = trackEvent(
    (action, prevState, nextState) => {
      const context = getContext();
      const additionalTrackingData = getPartialTrackingData(
        nextState,
        context,
        [
          'automated_test',
          'feature',
          'session_id',
          'store_group_id',
          'loggedin_status',
          'membership_status',
        ]
      );
      return {
        name: 'Change Product Attribute',
        properties: {
          ...additionalTrackingData,
          category: 'Quick View',
          ...action.payload,
        },
      };
    }
  );

  return {
    [TRACK_QUICK_VIEW_MODAL_OPEN]: trackModalOpen,
    [TRACK_QUICK_VIEW_UPDATE_CART]: trackUpdateCart,
    [TRACK_QUICK_VIEW_ADD_TO_CART]: trackAddToCart,
    [TRACK_VIEW_CLICK_FULL_DETAILS]: trackClickFullDetails,
    [TRACK_QUICK_VIEW_CHANGE_PRODUCT_ATTRIBUTE]: trackChangeProductAttribute,
  };
};

export default trackQuickView;
