import React, { useMemo } from 'react';

import Link from 'next/link';
import styled from 'styled-components';

import { NavBarBGColors } from '../../constants/navBarBGColors';
import { Component as XtraSavageIcon } from '../XtraSavageIcon';

import NavFullLogo from './NavFullLogo';
import NavLogoWithTitle from './NavLogoWithTitle';

const StyledLink = styled.a`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: inherit;
  z-index: ${props => props.theme.zIndex.navItems};

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    flex: 0;
  }
`;

type NavLogoProps = {
  className?: string;
  backgroundState?: string;
  shouldDisplayFullLogo?: boolean;
  title?: string;
};

type NavLogoRendererProps = Pick<
  NavLogoProps,
  'backgroundState' | 'shouldDisplayFullLogo' | 'title'
>;

function NavLogoRenderer({
  backgroundState,
  shouldDisplayFullLogo,
  title,
}: NavLogoRendererProps) {
  const icon = useMemo(
    () => (
      <XtraSavageIcon
        size={title ? 'medium' : 'large'}
        backgroundState={backgroundState}
      />
    ),
    [backgroundState, title]
  );
  if (shouldDisplayFullLogo) {
    return <NavFullLogo backgroundState={backgroundState} />;
  }
  if (title) {
    return <NavLogoWithTitle navLogo={icon} title={title} />;
  }
  return icon;
}

function NavLogo({
  className,
  backgroundState = NavBarBGColors.WHITE,
  shouldDisplayFullLogo,
  title,
}: NavLogoProps) {
  return (
    <Link href="/" passHref>
      <StyledLink className={className}>
        <NavLogoRenderer
          backgroundState={backgroundState}
          shouldDisplayFullLogo={shouldDisplayFullLogo}
          title={title}
        />
      </StyledLink>
    </Link>
  );
}

export default NavLogo;
