import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';
import { Component as StaticIcon } from '../StaticIcon';

const CloseButton = styled.button.attrs(
  ({
    theme,
    type = 'button',
    iconSize = 15,
    children = theme.themeVersion === 'v1' ? (
      <StaticIcon type="close" size={iconSize} />
    ) : (
      <NewThemeIcon type="close" size={iconSize} height="24px" width="24px" />
    ),
    'aria-label': ariaLabel = 'Close',
  }) => ({ type, iconSize, children, 'aria-label': ariaLabel })
)`
  position: absolute;
  top: 0;
  ${props => (props.align === 'left' ? 'left: 0' : 'right: 0')};
  width: 56px;
  height: 56px;
  border: 0;
  line-height: 56px;
  text-align: center;
  background: transparent;
  cursor: pointer;
`;

CloseButton.propTypes = {
  /**
   * The size of the default icon to render (if you don't pass your own
   * `children`).
   */
  iconSize: PropTypes.number,
  /**
   * The content of the close button, usually an icon. If none is provided,
   * the default close icon is rendered.
   */
  children: PropTypes.node,
};

export default CloseButton;
