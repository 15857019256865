import type { CSSProperties } from 'styled-components';

import { designSystemColors } from './newTheme';

/**
 * A utility to transform an object containing styles variants into
 * a format that can be used with the `withThemeProps` function from
 * `@techstyle/react-components`.
 * The `withThemeProps` function expects an object where the keys are the
 * variant names and the value is an object containing props that are passed
 * to the styled component.
 * This utility will transform an object like this `{ heading: { h1: css`...` }`
 * into this `{ heading: { textStyles: css`...` } }`.
 * For this to work, the `textStyles` prop must be passed to the styled component
 * that is wrapped by the `withThemeProps` HOC.
 */
export function transformStylesToVariants(
  stylesObj: Record<string, CSSProperties>,
  variantStyleName: string
) {
  const variantStyles = {} as Record<string, Record<string, CSSProperties>>;

  Object.entries(stylesObj).forEach(([styleName, style]) => {
    variantStyles[styleName] = {
      [variantStyleName]: style,
    };
  });

  return variantStyles;
}

/**
 * A utility function to generate a list of CSS Variables that can be used
 * to define design tokens in CSS. We use this to generate a list of CSS Variables
 * to add to the global styles, and use these variables for Builder.io's design
 * token functionality.
 */
export function generateDesignTokenVariables(vars: Record<string, string>) {
  return Object.entries(vars).map(([name, value]) => {
    if (name.startsWith('text-color')) {
      return `--${name}: ${value};`;
    }
    return `--colors-${name}: ${value};`;
  });
}

/**
 * A utility function to generate a list of design token colors that are used
 * by Builder.io's design system functionality.
 * This uses the `designSystemColors` that are transformed into CSS Variables
 * and added to the global styles.
 */
export function generateBuilderDesignTokenColors() {
  const designTokenColors = Object.entries(designSystemColors).map(
    ([name, value]) => ({
      name,
      value: `var(--colors-${name}, ${value})`,
    })
  );

  return designTokenColors;
}
