import { connect } from 'react-redux';

// `@techstyle/redux-core` provides this functionality now, but some components
// in `@techstyle/react-components` depend on this context. So we need to keep
// this until those are migrated.
import { RenderTime } from '@techstyle/react-components-legacy';
import { getDateNowFunction, getServerRenderDate } from '@techstyle/redux-core';

function mapStateToProps(state) {
  return {
    initialNow: getServerRenderDate(state).getTime(),
    now: getDateNowFunction(state),
  };
}

export default connect(mapStateToProps)(RenderTime);
