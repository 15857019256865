import { getPartialTrackingData } from '../utils';

const { trackBuilderTestEntered } = require('../../actions/builder');

const DEFAULT_VARIATION_NAME = 'Control';

const builderTestEnteredEvents = ({ getContext, trackEvent }) => {
  const builderTestEntered = trackEvent((action, prevState, nextState) => {
    const context = getContext();

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'loggedin_status',
      'session_id',
      'store_group_id',
      'is_vip',
    ]);

    // Builder doesn't return the default variation, so we always include it manually.
    // Default variation should be called "Control"
    const variationNames = [
      `${action?.payload?.name} - ${DEFAULT_VARIATION_NAME}`,
      ...Object.values(action?.payload?.variations ?? {}).map(
        variation => `${action?.payload?.name} - ${variation.name}`
      ),
    ];

    const variation = Object.values(action?.payload?.variations ?? {}).find(
      variation => variation.id === action?.payload?.testVariationId
    );

    const defaultVariationTestRatio = action?.payload?.testRatio ?? 0;

    // Applying trunc due to floating point issues which sometimes returns numbers such as 0.05000004 from builder
    // Using trunc so we don't round the number
    const testRatio =
      Math.trunc((variation?.testRatio ?? defaultVariationTestRatio) * 100) /
      100;

    const testVariationName = variation?.name ?? DEFAULT_VARIATION_NAME;

    return {
      name: 'Builder Test Entered',
      properties: {
        page_route: action?.payload?.data?.url,
        test_variation_id: action?.payload?.testVariationId,
        test_variation_name: `${action?.payload?.name} - ${testVariationName}`,
        id: action?.payload?.id,
        name: action?.payload?.name,
        test_ratio: testRatio,
        variation_names: variationNames,
        ...additionalTrackingData,
      },
    };
  });

  return { [trackBuilderTestEntered]: builderTestEntered };
};

export default builderTestEnteredEvents;
