import React, { useCallback, useEffect, useRef, useState } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import detectPassiveEvents from 'detect-passive-events';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import styled, { css } from 'styled-components';

if (process.browser) {
  require('intersection-observer');
}

const autoHideStyles = css`
  &.active.sticky-auto-hide > .sticky-inner-wrapper {
    /* When (1) pinned and (2) should auto-hide and (3) not hovered, force the
       element to slide up. Need to use !important because react-stickynode
       applies the transform via the style attribute. */
    transform: translate3d(0, -100%, 0) !important;
    transition-delay: 750ms;
  }
`;

const hideTopNavStyle = css`
  &.active.sticky-hide-top > .sticky-inner-wrapper {
    ${props => {
      return css`
        transform: translate3d(
          0,
          ${-Math.abs(props.theme.nav.mobileSubNav)}px,
          0
        ) !important;
      `;
    }}
  }
`;

const StyledSticky = styled(Sticky)`
  &.active > .sticky-inner-wrapper {
    /* Only transition when active, this will allow the unpinned version to
       immediately snap into place when scrolling to the top. */
    transition-property: transform;
    transition-duration: 500ms;
  }

  ${props =>
    props.autoHideBreakpoint
      ? props.autoHideBreakpoint`${autoHideStyles}`
      : autoHideStyles}
  ${props =>
    props.autoHideBreakpoint
      ? props.autoHideBreakpoint`${hideTopNavStyle}`
      : hideTopNavStyle}
`;

const Directions = {
  UP: 'up',
  DOWN: 'down',
};

const AutoHidingSticky = ({
  children,
  className,
  doubleNavThreshold,
  shouldPreserveOnScrollDown,
  ...rest
}) => {
  // Track whether the last scroll direction was up or down.
  const [scrollDirection, setScrollDirection] = useState(Directions.DOWN);
  // Track whether the area that the unpinned element consumes is on the
  // screen at all vs. 100% off-screen. This will prevent the element from
  // auto-hiding when doing so would reveal the space "underneath" the
  // element.
  const [isOnScreen, setIsOnScreen] = useState(true);
  // check wether should hide the toppest nav
  const [isTopNavHidden, setIsTopNavHidden] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const prevOffset = useRef(null);
  const scrollUpStart = useRef(null);
  const eventOptions = useRef(null);

  const handleScroll = useCallback(
    event => {
      const newOffset = window.pageYOffset;
      const newScrollDirection =
        newOffset < prevOffset.current ? Directions.UP : Directions.DOWN;

      if (doubleNavThreshold) {
        if (
          scrollDirection === Directions.DOWN &&
          newScrollDirection === Directions.UP
        ) {
          // track the starting point of Y offset for scrolling up
          scrollUpStart.current = prevOffset.current;
        }

        // check if scroll up constantly over this distance
        const shouldHideTopNav = !(
          scrollUpStart?.current - newOffset >
          doubleNavThreshold
        );

        setIsTopNavHidden(prev => {
          if (
            shouldHideTopNav === prev ||
            newScrollDirection === Directions.DOWN
          ) {
            return prev;
          }

          return shouldHideTopNav;
        });
      }

      prevOffset.current = newOffset;

      setScrollDirection(prev => {
        if (newScrollDirection === prev) {
          return prev;
        }

        return newScrollDirection;
      });
    },
    [doubleNavThreshold, scrollDirection]
  );

  const handleIntersectionChange = ({ isIntersecting }) =>
    setIsOnScreen(isIntersecting);
  const handleHover = () => setIsHovering(true);
  const handleUnhover = () => setIsHovering(false);

  useEffect(() => {
    eventOptions.current = detectPassiveEvents.hasSupport
      ? { capture: false, passive: true }
      : false;
    prevOffset.current = window.pageYOffset;
    scrollUpStart.current = window.pageYOffset;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, eventOptions?.current);

    return () => {
      window.removeEventListener('scroll', handleScroll, eventOptions?.current);
    };
  }, [handleScroll]);

  // Only auto-hide when scrolling down, not hovereing, and 100% of the
  // original unpinned element area is off-screen.
  if (
    !shouldPreserveOnScrollDown &&
    scrollDirection === Directions.DOWN &&
    !isHovering &&
    !isOnScreen
  ) {
    className = className
      ? `${className} sticky-auto-hide`
      : 'sticky-auto-hide';
  }

  if (
    scrollDirection === Directions.UP &&
    isTopNavHidden &&
    !isHovering &&
    !isOnScreen &&
    doubleNavThreshold
  ) {
    className = className ? `${className} sticky-hide-top` : 'sticky-hide-top';
  }

  return (
    <Observer onChange={handleIntersectionChange}>
      <StyledSticky className={className} {...rest}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            onHover: handleHover,
            onUnhover: handleUnhover,
          })
        )}
      </StyledSticky>
    </Observer>
  );
};
AutoHidingSticky.propTypes = {
  // An optional tagged template literal function like `mobile` or `desktop`
  // that will wrap the auto-hide styles in a media query.
  autoHideBreakpoint: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  doubleNavThreshold: PropTypes.number,
  shouldPreserveOnScrollDown: PropTypes.bool,
};

export default AutoHidingSticky;
