import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import type { StyledComponentPropsWithRef } from 'styled-components';

import { useAsset } from '@techstyle/react-assets';

import generateCdnImageUrl from '../../../src/utils/generateCdnImageUrl';

const FullImage = styled.img`
  width: 100%;
`;

const PlaceholderImage = styled.div`
  background-color: ${props => props.theme.colors.gray250};
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;
`;

const PlaceholderFullImage = styled(FullImage)``;

type ProductImageProps = StyledComponentPropsWithRef<typeof FullImage> & {
  // TODO: Extend this type further to include any potential products types that could be passed in
  /**
   * Data from the /products API for a single product. It should contain sku information.
   */
  product: any;
  modelIndex?: number;
  /**
   * The angle of the image we want to show. If no angle is supplied, we use the default.
   */
  angle?: string | number;
  /**
   * The size of the image we want to show
   */
  size?: number;
  src?: string;
  /**
   * Lazyload Images
   */
  lazyLoad?: boolean;
  pictureRef?: React.RefObject<HTMLImageElement>;
};

function ProductImage({
  product,
  modelIndex = 0,
  size = 3,
  angle = '1',
  // Default to `thumbnailImageSrc` when it's available
  src = product?.thumbnailImageSrc,
  lazyLoad = false,
  className,
  pictureRef,
  ...rest
}: ProductImageProps) {
  const [shouldShowPlaceHolder, setShouldShowPlaceHolder] = useState(false);

  const assetContainerPlaceholder = useAsset(
    'aspect_ratio_image_placeholder_container'
  );
  const { data } = assetContainerPlaceholder;
  const assetPlaceholder = data ? data.assets[0] : null;

  useEffect(() => {
    setShouldShowPlaceHolder(false);
  }, [src, product]);

  if (!src) {
    if (!product) {
      return null;
    }

    src = generateCdnImageUrl({
      angle,
      product,
      modelIndex,
      imageSize: size,
    });
    // If 3:4 Feature is enabled it must show the 3:4 image placeholder if the image doesn't exist
    if (shouldShowPlaceHolder || !src) {
      if (!assetPlaceholder) {
        return null;
      }
      return (
        <PlaceholderFullImage {...rest} src={assetPlaceholder.imageFilename} />
      );
    }

    if (!src) {
      return <PlaceholderImage />;
    }
  }

  const classNames = lazyLoad ? `${className} lazyload` : className;

  return (
    <FullImage
      // Remount when URL changes, otherwise there can be a delay where the
      // previous image is still shown while the new one loads.
      ref={pictureRef}
      key={src}
      src={lazyLoad ? null : src}
      data-src={lazyLoad ? src : null}
      className={classNames}
      alt={product.label}
      onError={() => {
        setShouldShowPlaceHolder(true);
      }}
      onLoad={() => {
        setShouldShowPlaceHolder(false);
      }}
      {...rest}
    />
  );
}

export default ProductImage;
