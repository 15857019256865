import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextLink } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import { SignUpMethod } from '../../constants/auth';
import { mobile } from '../../styles';
import { Container as SignIn } from '../SignIn';

const ModalParagraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0px 48px;
  padding: 0px;

  ${mobile`
    margin: 0px;
    margin-right: 10%;
    margin-left: 10%;
  `};
`;

const CustomSignIn = styled(SignIn)`
  max-width: 300px;
`;

function NewsletterSignupContent({
  emailText,
  onRequestClose,
  setShouldShowBackButton,
}) {
  useEffect(() => {
    setShouldShowBackButton(true);
  }, []);

  return (
    <>
      <ModalParagraph>
        <FormattedMessage
          id="global_cta.newsletter_modal_signup_description"
          defaultMessage="Create an account now and enjoy your exclusive offer when you become an Xtra VIP member."
        />
      </ModalParagraph>
      <CustomSignIn
        initialEmail={emailText}
        initialScreen="SIGN_UP"
        showEmailField={false}
        showReferrerField
        showSwitcher={false}
        signUpLabel={
          <FormattedMessage
            id="global_cta.footer_link_signup_button"
            defaultMessage="SIGN UP"
          />
        }
        extraSignUpData={{
          method: SignUpMethod.NEWSLETTER_EMAIL_SIGNUP,
        }}
      />
      <TextLink tagName="button" onClick={onRequestClose}>
        <FormattedMessage
          id="global_cta.newsletter_skip_signup"
          defaultMessage="No, thanks. Continue shopping"
        />
      </TextLink>
    </>
  );
}

NewsletterSignupContent.propTypes = {
  emailText: PropTypes.string,
  onRequestClose: PropTypes.func,
  setShouldShowBackButton: PropTypes.func,
};

export default NewsletterSignupContent;
