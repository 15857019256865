export const LOAD_REFERRER_REQUEST = 'LOAD_REFERRER_REQUEST';
export const LOAD_REFERRER_SUCCESS = 'LOAD_REFERRER_SUCCESS';
export const LOAD_REFERRER_FAILURE = 'LOAD_REFERRER_FAILURE';

export function loadReferrer() {
  return {
    bentoApi: {
      endpoint: `marketing/referrers`,
      method: 'GET',
      actions: [
        LOAD_REFERRER_REQUEST,
        LOAD_REFERRER_SUCCESS,
        LOAD_REFERRER_FAILURE,
      ],
    },
  };
}
