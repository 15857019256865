import React from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAsset, HTMLAsset } from '@techstyle/react-assets';
import { useBreakpoint } from '@techstyle/react-components';

import { desktop, mobile } from '../../styles';
import { toStringSet } from '../../utils/assets';
import {
  getProductPromos,
  getShouldShowPromoPrice,
  isClearanceItem,
} from '../../utils/selectors';
import { Component as ProductDetailName } from '../ProductDetailName';
import { Component as ProductImage } from '../ProductImage';

const Wrapper = styled.div`
  min-height: 155px;
  width: 100%;

  ${desktop`
    background-color: ${props => props.theme.colors.lavender200};
    flex: 0 0 50%;
    height: 100%;
    padding: 60px 70px;
  `}
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.lavender200};
  padding: ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.medium}px;

  ${desktop`
    background-color: ${props => props.theme.colors.white};
    border-radius: 4px;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    padding: 0 ${props => props.theme.spacing.medium}px;
    text-align: center;
    width: 100%;
  `}
`;

const ProductThumbnail = styled(ProductImage)`
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  width: 118px;
  height: 145px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));

  ${desktop`
    height: 350px;
    order: 2;
    width: 250px;
  `}
`;

const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto ${props => props.theme.spacing.small}px;
`;

const StyledProductDetailName = styled(ProductDetailName)`
  display: inline-block;
  flex: unset;
  margin-left: 0;

  ${ProductDetailName.ProductName} {
    font-size: 14px;
    font-weight: 500;
  }

  ${desktop`
    order: 1;
  `}

  ${mobile`
    padding-bottom: 4px;
  `}
`;

const PromoSection = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.lavender600};
  text-transform: uppercase;

  ${desktop`
    order: 3;
  `}
`;

const PromoAsset = styled(HTMLAsset)``;

const PromoText = styled.p``;

function ProductInfoRegistrationModal({ className }) {
  const {
    query: { productId },
  } = useRouter();
  const product =
    useSelector(state => state.products.byMasterProductId[productId]) || {};
  const { isMobile } = useBreakpoint();
  const pdpHtmlFplAsset =
    useAsset('pdp_html_fpl', {
      filter: parentFilter => {
        return (asset, index, assets) => {
          if (!parentFilter(asset, index, assets)) {
            return false;
          }
          const fplId = asset.options.customVars.fplid;
          if (fplId) {
            return product?.featured_product_location_id_list?.includes(fplId);
          }
          return true;
        };
      },
    })?.data?.assets[0] || {};
  const shouldShowPromoPrice = useSelector(state =>
    getShouldShowPromoPrice(state, { product })
  );
  const isClearanceProduct = useSelector(state =>
    isClearanceItem(state, { product })
  );
  const promos =
    useSelector(state => getProductPromos(state, { product })) || {};

  const RenderedProductDetailWrapper = isMobile
    ? ProductDetailWrapper
    : React.Fragment;

  const { pdpLabel = '', flags = '' } =
    pdpHtmlFplAsset.options?.customVars || {};
  const formattedflags = toStringSet(flags, true);

  if (!Object.keys(product).length) {
    return null;
  }

  const renderPromo = () => {
    const { promo_label: promoLabel } = promos;

    if (isClearanceProduct) {
      return <PromoText>{promoLabel}</PromoText>;
    }

    if (formattedflags.has('bundle')) {
      return (
        <>
          <PromoAsset asset={pdpHtmlFplAsset} />
          <PromoText>{pdpLabel}</PromoText>
        </>
      );
    }

    if (shouldShowPromoPrice || formattedflags.has('cartpromo')) {
      return (
        <PromoText>
          {pdpLabel || (shouldShowPromoPrice ? promoLabel : '')}
        </PromoText>
      );
    }

    return <PromoText>{promoLabel}</PromoText>;
  };

  return (
    <Wrapper className={className}>
      <ProductWrapper>
        <ProductThumbnail product={product} angle="laydown" size={7} />
        <RenderedProductDetailWrapper>
          <StyledProductDetailName
            className={className}
            product={product}
            isProductFlagHidden
          />
          <PromoSection>{renderPromo()}</PromoSection>
        </RenderedProductDetailWrapper>
      </ProductWrapper>
    </Wrapper>
  );
}

ProductInfoRegistrationModal.propTypes = {
  className: PropTypes.string,
};

export default ProductInfoRegistrationModal;
