/**
 *
 * @param {*} product
 * @param {*} param1
 * @returns
 */
const gatherProductTrackingData = async (
  product,
  { dispatch, customerDetails = {}, time = {}, externalProductDefaults }
) => {
  const { isPreorderable } = require('../../utils/selectors');
  const {
    isProductSet: getIsProductSet,
    getProductPromos,
  } = require('../../utils/selectors');
  const { loadProductSet } = require('../../actions/products');

  // Set object to keep track of unique product IDs
  const trackedProductIds = new Set();
  const isProductSet = getIsProductSet(product);
  const products = [];
  const promos = getProductPromos(undefined, { product }) || {};

  const {
    color: masterColor,
    defaultUnitPrice,
    itemNumber: _itemNumber,
    label: masterLabel,
    masterProductId: _masterProductId,
    position,
    retailUnitPrice,
    reviewCount: _reviewCount,
  } = product;
  const { averageReview, productCategoryIdList, defaultProductCategoryId } = {
    average_review: product.average_review || product.averageReview || null,
    productCategoryIdList:
      product.product_category_id_list || product.productCategoryIdList,
    defaultProductCategoryId:
      product.default_product_category_id || product.defaultProductCategoryId,
  };

  const reviewCount = product.review_count || _reviewCount || 0;
  const masterRetailUnitPrice = product.retail_unit_price || retailUnitPrice;
  const masterProductId = product.master_product_id || _masterProductId;
  const masterDefaultUnitPrice = product.default_unit_price || defaultUnitPrice;

  if (!masterProductId) {
    return products;
  }
  const itemNumber = product.item_number || _itemNumber;
  const defaultProductsJson = {
    category: defaultProductCategoryId,
    is_bundle: isProductSet,
    review_rating: averageReview,
    sku: itemNumber ? itemNumber.toString() : undefined,
    subcategory: productCategoryIdList?.slice(-1)[0],
    grid_position: position,
    ...externalProductDefaults,
  };

  if (isProductSet) {
    const { payload: productSetQuery } = await dispatch(
      loadProductSet(masterProductId)
    );

    const {
      componentProductIdObjectList: _componentProductIdObjectList,
      productsInSet: _productsInSet,
    } = productSetQuery;

    const componentProductIdObjectList =
      productSetQuery.component_product_id_object_list ||
      _componentProductIdObjectList;

    const productsInSet = productSetQuery.products_in_set || _productsInSet;

    productsInSet.forEach(productInSet => {
      const {
        alias,
        color,
        label,
        masterProductId: _masterProductId,
        reviewCount: _reviewCount,
      } = productInSet;

      const productId = productInSet.master_product_id || _masterProductId;
      const reviewCount = productInSet.review_count || _reviewCount || 0;

      if (!trackedProductIds.has(productId)) {
        const isPreOrder = isPreorderable({ time }, { product: productInSet });
        const pricePercentageObject = componentProductIdObjectList.find(
          obj =>
            obj.component_product_id === productId ||
            obj.componentProductId === productId
        );
        const { priceContributionPercentage: _priceContributionPercentage } =
          pricePercentageObject;
        const priceContributionPercentage =
          pricePercentageObject.price_contribution_percentage ||
          _priceContributionPercentage;
        const productsJson = {
          ...defaultProductsJson,
          bundle_alias: alias,
          bundle_name: masterLabel,
          bundle_price: promos?.promo_price
            ? parseFloat(promos.promo_price).toFixed(2)
            : masterDefaultUnitPrice,
          bundle_product_id: masterProductId.toString(),
          bundle_retail_price: masterRetailUnitPrice,
          bundle_vip_price: masterDefaultUnitPrice,
          has_reviews: !!reviewCount,
          name: label,
          review_num: reviewCount,
          price: parseFloat(
            (masterDefaultUnitPrice * priceContributionPercentage).toFixed(2)
          ),
          product_id: productId.toString(),
          retail_price: parseFloat(
            (masterRetailUnitPrice * priceContributionPercentage).toFixed(2)
          ),
          variant: color,
          vip_price: parseFloat(
            (masterDefaultUnitPrice * priceContributionPercentage).toFixed(2)
          ),
          is_preorder: isPreOrder,
        };

        trackedProductIds.add(productId);

        products.push(productsJson);
      }
    });
  } else {
    if (!trackedProductIds.has(masterProductId)) {
      const isPreOrder = isPreorderable({ time }, { product });

      const productsJson = {
        ...defaultProductsJson,
        bundle_name: null,
        bundle_price: null,
        bundle_retail_price: null,
        bundle_vip_price: null,
        bundle_product_id: null,
        has_reviews: !!reviewCount,
        review_num: reviewCount,
        name: masterLabel,
        price: masterDefaultUnitPrice,
        product_id: masterProductId.toString(),
        retail_price: masterRetailUnitPrice,
        variant: masterColor,
        vip_price: masterDefaultUnitPrice,
        is_preorder: isPreOrder,
      };

      trackedProductIds.add(masterProductId);

      products.push(productsJson);
    }
  }

  return products;
};

export default gatherProductTrackingData;
