import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { defineMessages, FormattedMessage } from '@techstyle/react-intl';

import { SignUpMethod } from '../../constants/auth';

const Title = styled.h1`
  font-size: 18px;
  text-align: center;
  margin: 0 0 4px 0;
`;

const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.3px;
  color: ${props => props.theme.colors.subdued};
  margin: 0;
`;

const signUpTitles = defineMessages({
  [SignUpMethod.CARTDRAWER]: {
    id: 'global_checkout.checkout_sign_up_title',
    defaultMessage: 'This item is going fast!',
  },
  [SignUpMethod.FAVORITES]: {
    id: 'global_checkout.favorite_nav_sign_up_title',
    defaultMessage: 'Wanna Save Your Fave Styles?',
  },
  [SignUpMethod.WISHLIST]: {
    id: 'global_checkout.favorite_sign_up_title',
    defaultMessage: 'Like this item?',
  },
  [SignUpMethod.NAVBAR]: {
    id: 'global_checkout.navbar_sign_up_title',
    defaultMessage: 'Want More Savage X?',
  },
});

const signUpSubTitles = defineMessages({
  [SignUpMethod.CARTDRAWER]: {
    id: 'global_checkout.checkout_sign_up_subtitle',
    defaultMessage: 'Create an account to checkout now',
  },
  [SignUpMethod.FAVORITES]: {
    id: 'global_checkout.favorite_nav_sign_up_subtitle',
    defaultMessage: 'Create an account & keep all your faves in one place',
  },
  [SignUpMethod.WISHLIST]: {
    id: 'global_checkout.favorite_sign_up_subtitle',
    defaultMessage: 'Create an account to save all your favorites',
  },
  [SignUpMethod.NAVBAR]: {
    id: 'global_checkout.navbar_sign_up_subtitle',
    defaultMessage:
      'Create an account & start accessing all the latest Savage X styles.',
  },
});

const signInTitles = defineMessages({
  [SignUpMethod.CARTDRAWER]: {
    id: 'global_checkout.checkout_sign_in_title',
    defaultMessage: 'Already have an account?',
  },
  [SignUpMethod.FAVORITES]: {
    id: 'global_checkout.favorite_nav_sign_in_title',
    defaultMessage: 'Wanna Save Your Fave Styles?',
  },
  [SignUpMethod.WISHLIST]: {
    id: 'global_checkout.favorite_sign_in_title',
    defaultMessage: 'Already have an account?',
  },
  [SignUpMethod.NAVBAR]: {
    id: 'global_checkout.navbar_sign_in_title',
    defaultMessage: 'Already Signed Up?',
  },
});

const signInSubTitles = defineMessages({
  [SignUpMethod.CARTDRAWER]: {
    id: 'global_checkout.checkout_sign_in_subtitle',
    defaultMessage: 'Sign in to checkout now.',
  },
  [SignUpMethod.FAVORITES]: {
    id: 'global_checkout.favorite_nav_sign_in_subtitle',
    defaultMessage:
      'Sign in to your account & keep all your faves in one place.',
  },
  [SignUpMethod.WISHLIST]: {
    id: 'global_checkout.favorite_sign_in_subtitle',
    defaultMessage: 'Sign in to save this item your favorites',
  },
  [SignUpMethod.NAVBAR]: {
    id: 'global_checkout.navbar_sign_in_subtitle',
    defaultMessage:
      'Sign in to your account to access all the latest Savage X styles.',
  },
});

const SignInContentHeadline = ({ location, shouldShowSignInTitles }) => {
  const title = (shouldShowSignInTitles ? signInTitles : signUpTitles)[
    location
  ];

  const subTitle = (shouldShowSignInTitles ? signInSubTitles : signUpSubTitles)[
    location
  ];

  const autoTag = shouldShowSignInTitles
    ? 'signin-open-message'
    : 'signup-open-message';

  if (!title && !subTitle) {
    return null;
  }

  return (
    <>
      <Title data-autotag={autoTag}>
        <FormattedMessage {...title} />
      </Title>
      <SubTitle>
        <FormattedMessage {...subTitle} />
      </SubTitle>
    </>
  );
};

SignInContentHeadline.propTypes = {
  location: PropTypes.string,
  shouldShowSignInTitles: PropTypes.bool,
};

SignInContentHeadline.defaultProps = {
  shouldShowSignInTitles: false,
};

SignInContentHeadline.Title = Title;
SignInContentHeadline.SubTitle = SubTitle;

export default SignInContentHeadline;
