import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateCustomerDetail, useCustomer } from '@techstyle/react-accounts';
import { useFeature } from '@techstyle/react-features';
import { useSession, updateSessionDetail } from '@techstyle/redux-core';

import { localStorage } from '../../utils/helpers';

function ForceEcho() {
  const dispatch = useDispatch();
  const feature = useFeature('echo_tier_1');
  const { shouldForceEcho } = useSelector(state => state.debug);
  const { isLoggedIn, sessionId } = useSession();
  const { id: customerId } = useCustomer();

  useEffect(() => {
    const isEchoFeatureLoaded =
      feature.networkStatus.isUpToDate && !feature.networkStatus.isLoading;
    const isEchoFeature = !!(feature.data && feature.data.value);
    const hasSetCustomerDetail = !!localStorage.getItem(
      `echoCustomerDetail_${customerId || sessionId}`
    );
    const update = isLoggedIn ? updateCustomerDetail : updateSessionDetail;

    // If someone is placed into the ECHO AB test, we want to set a customer detail
    // describing which cart they're in (necessary for the AB test on the backend).
    const isEchoFeatureActive =
      isLoggedIn &&
      isEchoFeatureLoaded &&
      !hasSetCustomerDetail &&
      !shouldForceEcho;

    if (isEchoFeatureActive || (shouldForceEcho && !hasSetCustomerDetail)) {
      const value = isEchoFeature || shouldForceEcho ? 'echo' : 'bento';
      dispatch(update({ name: 'cart_provider', value }));

      // Setting localStorage as well so we don't need to hit the API to
      // get customer_detail on every login
      localStorage.setItem(
        `echoCustomerDetail_${customerId || sessionId}`,
        value
      );
    }
  }, [customerId, dispatch, feature, isLoggedIn, sessionId, shouldForceEcho]);

  return null;
}

export default ForceEcho;
