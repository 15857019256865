import React, { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { useNav } from '@techstyle/react-navigation';

import { getProductCategoryTypes } from '../../utils/productDetail';
import { getProductIdFromUrl } from '../../utils/url';

const Context = React.createContext({
  metaNavState: '',
  setMetaNavState: () => {},
});

const MetaNavProvider = props => {
  const { asPath } = useRouter();
  const { data: navData } = useNav('site_meta_nav', {});
  const productList = useSelector(state => state.products.byMasterProductId);
  const productId = getProductIdFromUrl(asPath);
  const product = productList[productId] || null;
  const { isSport } = getProductCategoryTypes(
    product?.product_category_id_list
  );
  const navigationizerItems = navData?.navItems || [];

  const pathFromNavigationizer = navigationizerItems.filter(item =>
    asPath.includes(item.url)
  )[0];

  const [metaNavState, setMetaNavState] = useState(() => {
    if (pathFromNavigationizer?.itemKey === 'sport' || isSport) {
      return 'sport';
    } else if (pathFromNavigationizer) {
      return pathFromNavigationizer?.itemKey;
    } else {
      return 'lingerie';
    }
  });

  useEffect(() => {
    const getSessionMetaNavValue = () => {
      const metaNavValue = JSON.parse(sessionStorage.getItem('metaNavState'));
      if (
        metaNavValue &&
        window.performance.getEntriesByType('navigation')[0].type === 'reload'
      ) {
        setMetaNavState(metaNavValue);
      }
    };
    getSessionMetaNavValue();
  }, []);

  const value = useMemo(
    () => ({
      metaNavState,
      navData,
      setMetaNavState,
    }),
    [metaNavState, navData, setMetaNavState]
  );

  return <Context.Provider {...props} value={value} />;
};

MetaNavProvider.Context = Context;

export default MetaNavProvider;
