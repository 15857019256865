import { connect } from 'react-redux';

import ProgressLoader from './ProgressLoader';

function mapStateToProps(state) {
  return {
    loading: state.checkout.loading,
  };
}

export default connect(mapStateToProps)(ProgressLoader);
