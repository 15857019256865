import React, { useCallback, useContext, useEffect, useState } from 'react';

import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';

import { useMembership, useMembershipPeriod } from '@techstyle/react-accounts';
import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { useIntl, FormattedMessage } from '@techstyle/react-intl';
import {
  parseDate,
  useSession,
  getDateNowFunction,
} from '@techstyle/redux-core';

import { getUserToken } from '../../actions/account';
import { trackSubmitOrderFailure } from '../../actions/checkout';
import { trackEmpInfoDrawerOpen } from '../../actions/membership';
import { desktop, mobile } from '../../styles';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import {
  isEmpVip as getIsEmpVip,
  isBilledEmpVip as getIsBilledEmpVip,
  isNMP,
} from '../../utils/selectors';
import useJsonCustomerDetail from '../../utils/useJsonCustomerDetail';
import { Component as EMPNewTokenInformationDrawerModal } from '../EMPNewTokenInformationDrawerModal';
import { Component as MemberCreditsDrawerModal } from '../MemberCreditsDrawerModal';
import { Component as MemberCreditsDrawerModalProvider } from '../MemberCreditsDrawerModalProvider';
import { Component as QuantityBadge } from '../QuantityBadge';

import { MemberCreditsNavLabelV2 } from './MemberCreditsNavLabel';

const NEW_BADGE_ELAPSED_TIME = 2 * 60 * 60 * 24 * 30 * 1000; // 2 months

const MemberCreditsNavWrapper = styled.div`
  position: relative;
  ${props => !props.isVip && `margin-right: 4px;`}

  ${mobile`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;
    line-height: 40px;
  `}
`;

const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MemberCreditIconWrapper = styled.div`
  svg {
    margin: 0;
  }
`;

const MemberCreditIcon = () => {
  return (
    <MemberCreditIconWrapper>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 21V21C19 17.6863 16.3137 15 13 15V15C9.68629 15 7 17.6863 7 21V21"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M16.4919 9.97727C16.4919 11.8168 14.9666 13.3295 13.0584 13.3295C11.1502 13.3295 9.625 11.8168 9.625 9.97727C9.625 8.13771 11.1502 6.625 13.0584 6.625C14.9666 6.625 16.4919 8.13771 16.4919 9.97727Z"
          stroke="currentColor"
          strokeWidth="1.25"
        />
        <circle
          cx="13"
          cy="13"
          r="12"
          stroke="currentColor"
          strokeWidth="1.25"
        />
      </svg>
    </MemberCreditIconWrapper>
  );
};

const XtraSavageIconLabel = styled.div`
  margin: -6px 5px 0;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;

  > span {
    overflow: hidden;
    text-wrap: nowrap;
  }

  ${mobile`
    display: none;
  `}
`;

const newXtraVipLabelAnimation = keyframes`
  0% {
    transform: scale(0) translate(10px);
    opacity: 0;
  }
  3% {
    transform: scale(1.2) translate(0);
    opacity: 1;
  }
  5% {
    transform: scale(1) translate(0);
  }
  95% {
    transform: scale(1) translate(0);
  }
  97% {
    transform: scale(1.2) translate(0);
    opacity: 1;
  }
  100% {
    transform: scale(0) translate(10px);
    opacity: 0;
  }
`;

const NewXtraVipLabel = styled.div`
  display: grid;
  place-items: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.promo};
  position: absolute;
  font-size: 10px;
  line-height: 13px;
  padding: 2px 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 500px;
  transform: scale(0);
  transform-origin: left;
  border: 1px solid ${props => props.theme.colors.white};
  font-weight: bold;
  top: 10px;
  left: 29px;

  ${desktop`
    top: 20px;
    left: 31px;
    border: none;
    border-radius: 0px 8px 8px 8px;
  `}

  ${props =>
    props.shouldBadgeAnimate &&
    css`
      animation: ${newXtraVipLabelAnimation} 5s linear;
      animation-delay: 4s;
      animation-iteration-count: 1;
      animation-direction: alternate;
      transition-timing-function: ease;
    `}
`;

const StyledQuantityBadge = styled(QuantityBadge)`
  ${desktop`
    left: -9px;
    top: 13px;
    right: unset;
    border: none;
  `}
`;

const MobileButtonWrapper = ({ children }) => {
  const { isMobile } = useBreakpoint();
  if (isMobile) {
    return <button type="button">{children}</button>;
  }
  return <div>{children}</div>;
};

function MemberCreditsNav({ setMemberCredits = () => {} }) {
  const { isMobile } = useBreakpoint();
  const { 'token-to-cash': tokenToCashEnabled } = useLDFlags();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { availableTokenQuantity: creditsRemaining, isVip } = useMembership();
  const getDateNow = useSelector(getDateNowFunction);
  const { sessionId } = useSession();
  const isEmpVip = useSelector(getIsEmpVip);
  const [isNewEmpAccount, setIsNewEmpAccount] = useState(false);

  const userIsNMP = useSelector(isNMP);
  const {
    isLoaded: isEmpNavSettingsLoaded,
    updateJsonCustomerDetail,
    value: empNavSettings,
  } = useJsonCustomerDetail('emp_nav');
  const {
    isLoaded: isEMPNewTokenModalMetadataLoaded,
    updateJsonCustomerDetail: updateEMPTokenModalJsonCustomerDetail,
    value: empNewTokenModalMetaData,
  } = useJsonCustomerDetail('viewed_emp_new_token_modal');
  const isBilledEmpVip = useSelector(getIsBilledEmpVip);
  const {
    newLabelExpirationDate,
    previousCreditsRemaining,
    previousSessionId,
  } = empNavSettings;
  const isNewSession = previousSessionId !== sessionId;
  const [isDialogVisible, setDialogVisibility] = useState(false);
  const [shouldBadgeAnimate, setShouldBadgeAnimate] = useState(false);
  const [isBadgeReady, setIsBadgeReady] = useState(false);
  const {
    setIsModalOpen,
    setShouldForceNewCreditDialog,
    shouldForceNewCreditDialog,
  } = useContext(MemberCreditsDrawerModalProvider.Context);
  useMembershipPeriod();

  const { themeVersion } = useTheme();

  const handleError = useCallback(
    async err => {
      const responseBody = await err.originalError.response.json();
      dispatch(trackSubmitOrderFailure(responseBody || {}));
    },
    [dispatch]
  );

  const updateLastViewedDateAndShowDialog = useCallback(
    (date = parseDate(getDateNow())) => {
      updateEMPTokenModalJsonCustomerDetail({
        lastViewed: date,
      });
      setDialogVisibility(true);
    },
    [getDateNow, updateEMPTokenModalJsonCustomerDetail]
  );

  useEffect(() => {
    if (!isEmpNavSettingsLoaded) {
      return;
    }

    const isNewCreditValue = previousCreditsRemaining < creditsRemaining;

    // Badge should only animate if it's a new session. If the credits have
    // increased, then do not animate (and show dialog instead).
    setShouldBadgeAnimate(
      (isNewSession && !isNewCreditValue) || shouldForceNewCreditDialog
    );

    async function fetchUserTokens() {
      try {
        const {
          payload: { tokenDetails },
        } = await dispatch(getUserToken());
        if (tokenDetails && tokenDetails.length === 0) {
          setIsNewEmpAccount(true);
        }
      } catch (err) {
        handleError(err);
      }
      setIsBadgeReady(true);
    }

    if (isVip) {
      fetchUserTokens();
    } else {
      setIsBadgeReady(true);
    }
  }, [
    creditsRemaining,
    dispatch,
    handleError,
    isDialogVisible,
    isEmpNavSettingsLoaded,
    isNewSession,
    isVip,
    previousCreditsRemaining,
    sessionId,
    shouldForceNewCreditDialog,
  ]);

  useEffect(() => {
    setMemberCredits(creditsRemaining);
  }, [creditsRemaining, setMemberCredits]);

  /**
   * We want this logic to show the dialog a maximum of 12 times per year. We accomplish this by
   * Checking the absolute value of differenceInCalendarMonths, and if the value is 1 or more it means the
   * month has changed, and we can show the dialog. The user also needs to be a billed EMP VIP and needs to
   * be in a new session.
   *
   * If the value hasn't been set in customer details yet we set a default.
   */

  useEffect(() => {
    if (
      (isEMPNewTokenModalMetadataLoaded && isBilledEmpVip && isNewSession) ||
      shouldForceNewCreditDialog
    ) {
      const { lastViewed } = empNewTokenModalMetaData;
      const currentDate = parseDate(getDateNow());

      if (typeof lastViewed === 'string') {
        if (
          Math.abs(
            differenceInCalendarMonths(currentDate, parseDate(lastViewed))
          ) >= 1
        ) {
          updateLastViewedDateAndShowDialog(currentDate);
        }
      } else {
        updateLastViewedDateAndShowDialog(currentDate);
      }
    }
  }, [
    empNewTokenModalMetaData,
    getDateNow,
    isBilledEmpVip,
    isEMPNewTokenModalMetadataLoaded,
    isNewSession,
    shouldForceNewCreditDialog,
    updateEMPTokenModalJsonCustomerDetail,
    updateLastViewedDateAndShowDialog,
  ]);

  const handleAnimationEnd = (shouldForceNewCreditDialog = false) => {
    let expirationDate = newLabelExpirationDate;
    if (!expirationDate) {
      expirationDate = parseDate(getDateNow()).getTime();
      expirationDate = expirationDate + NEW_BADGE_ELAPSED_TIME;
    }
    updateJsonCustomerDetail({
      newLabelExpirationDate: expirationDate,
      previousCreditsRemaining: creditsRemaining,
      previousSessionId: sessionId,
    });
    setShouldForceNewCreditDialog(shouldForceNewCreditDialog);
  };

  const renderNewLabel = () => {
    const currentDate = getDateNow();
    const expiration = newLabelExpirationDate
      ? parseDate(newLabelExpirationDate)
      : currentDate;
    if (
      creditsRemaining === 0 &&
      currentDate <= expiration &&
      isNewEmpAccount &&
      isEmpVip &&
      isNewSession
    ) {
      return (
        <NewXtraVipLabel
          onAnimationEnd={() => handleAnimationEnd()}
          shouldBadgeAnimate={shouldBadgeAnimate}
        >
          <FormattedMessage
            defaultMessage="New"
            id="site_navigation.savage_xtra_membership_new_label"
          />
        </NewXtraVipLabel>
      );
    }
  };

  const handleClick = () => {
    if (!isMobile) {
      return;
    }
    setIsModalOpen(true);
    dispatch(trackEmpInfoDrawerOpen());
  };

  const handleCloseDialog = e => {
    e.stopPropagation();
    setDialogVisibility(false);
    setShouldForceNewCreditDialog(false);
  };

  return (
    <MemberCreditsNavWrapper isVip={isVip}>
      <MobileButtonWrapper>
        <ContentWrapper
          onClick={handleClick}
          aria-label={
            isMobile
              ? formatMessage({
                  id: 'site_navigation.emp_credits_drawer_title',
                  defaultMessage: 'Learn More About Your Member Credits',
                })
              : undefined
          }
        >
          {themeVersion === 'v1' && (
            <>
              <MemberCreditIcon />
              {isVip ? (
                <XtraSavageIconLabel>
                  {userIsNMP ? (
                    <FormattedMessage
                      defaultMessage="My Account"
                      id="site_navigation.savage_xtra_membership_xtra_vip_label"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Xtra VIP"
                      id="site_navigation.savage_xtra_membership_xtra_vip_label_emp"
                    />
                  )}
                </XtraSavageIconLabel>
              ) : null}

              {renderNewLabel()}
              {creditsRemaining > 0 && isBadgeReady ? (
                <StyledQuantityBadge
                  shouldBadgeAnimate={shouldBadgeAnimate}
                  onAnimationEnd={() =>
                    handleAnimationEnd(shouldForceNewCreditDialog)
                  }
                  hasNoDelay={shouldForceNewCreditDialog}
                  data-testid="member-credits-nav-token-quanitity"
                >
                  {creditsRemaining}
                </StyledQuantityBadge>
              ) : null}
            </>
          )}
          {themeVersion === 'v2' && <MemberCreditsNavLabelV2 />}
        </ContentWrapper>
      </MobileButtonWrapper>
      {userIsNMP && tokenToCashEnabled ? null : (
        <EMPNewTokenInformationDrawerModal
          isOpen={!!isDialogVisible}
          onExit={handleCloseDialog}
        />
      )}

      {isMobile ? <MemberCreditsDrawerModal /> : null}
    </MemberCreditsNavWrapper>
  );
}

MemberCreditsNav.propTypes = {
  setMemberCredits: PropTypes.func,
};

export default MemberCreditsNav;
