import { connect } from 'react-redux';

import { injectIntl } from '@techstyle/react-intl';

import { trackNavItemClick } from '../../actions/navigation';
import { currentDmGateway } from '../../utils/selectors';

import Footer from './Footer';

const mapStateToProps = state => {
  const tld = state.domain.tld;
  return {
    currentDmGateway: currentDmGateway(state),
    isInternational: tld !== '.com',
    isBorderfreeInitialized: state.borderfree.initialized,
    tld,
  };
};

const mapDispatchToProps = {
  trackNavItemClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Footer));
