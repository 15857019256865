import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { trackRegistrationSignUp } from '../../actions/auth';

import RegistrationModal from './RegistrationModal';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      trackRegistrationSignUp,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModal);
