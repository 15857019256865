import { handleActions } from 'redux-actions';

import {
  outOfDateStatus,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '@techstyle/redux-core';

import {
  LOAD_ADDRESSES_SUCCESS,
  CREATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
  LOAD_ADDRESSES_REQUEST,
  LOAD_ADDRESSES_FAILURE,
} from '../../actions/account';

export const defaultState = {
  data: [],
  networkStatus: outOfDateStatus(),
};

export default handleActions(
  {
    [LOAD_ADDRESSES_REQUEST]: startLoading,
    [LOAD_ADDRESSES_SUCCESS]: (state, action) => {
      return {
        data: action.payload,
        networkStatus: upToDateStatus(),
      };
    },
    [LOAD_ADDRESSES_FAILURE]: stopLoading,
    [CREATE_ADDRESS_SUCCESS]: (state, action) => {
      // Beware: "adding" can also update an existing address.
      let addressFound = false;
      const addresses = state.data.map(address => {
        if (address.id === action.payload.id) {
          addressFound = true;
          return action.payload;
        }
        // Reset others to non-defaults if necessary.
        return action.payload.isDefault && address.isDefault
          ? { ...address, isDefault: false }
          : address;
      });
      if (!addressFound) {
        addresses.push(action.payload);
      }
      return {
        data: addresses,
        networkStatus: upToDateStatus(),
      };
    },
    [UPDATE_ADDRESS_SUCCESS]: (state, action) => {
      let newAddresses = [...state.data];
      // Reset `isDefault` values to `false` if the default was updated.
      if (action.meta && action.meta.defaultUpdated) {
        newAddresses = newAddresses.map(address =>
          address.isDefault ? { ...address, isDefault: false } : address
        );
      }
      newAddresses = newAddresses.map(address =>
        address.id === action.payload.id ? action.payload : address
      );
      return {
        data: newAddresses,
        networkStatus: upToDateStatus(),
      };
    },
    [DELETE_ADDRESS_SUCCESS]: (state, action) => {
      const newAddresses = state.data.filter(
        address => address.id !== action.payload.id
      );
      state.data = newAddresses;
    },
  },
  defaultState
);
