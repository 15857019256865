import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { AssetContainer, ImageAsset } from '@techstyle/react-assets';
import { TextLink } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import { BasicModal } from '../../styles/modal';
import { Paragraph } from '../../styles/paragraph';

const overlayStyle = css`
  background: ${props => props.theme.colors.scrim};
`;

const PromoModal = styled(BasicModal)``;

const NoPromoModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.medium}px;
  min-height: 192px;
  width: 343px;
  align-items: center;
`;

const PromoModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 403px;
  width: 343px;
`;

const PromoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing.moderate}px 18px
    ${props => props.theme.spacing.medium}px 18px;
`;

const ImageCell = styled.div`
  flex: 1;
  position: relative;
  height: 179px;
  padding: 0;
`;

const StyledImageAsset = styled(ImageAsset)`
  width: 100%;
  height: 179px;
  background-position: top center;
`;

const PromoModalHeadline = styled(Paragraph)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

const PromoModalText = styled(Paragraph)`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin-bottom: ${props => props.theme.spacing.moderate}px;
`;

const ShopNowButton = styled(TextLink)`
  width: 157px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0 15px;
  background-color: ${props => props.theme.colors.default};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1px;
  outline: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  border-radius: 2px;
`;

const OkButton = styled(ShopNowButton)``;

class InfluencerPromoModal extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    influencerPromo: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    influencerPromo: {},
  };

  onClose = () => {
    this.props.onClose();
  };

  renderAsset(asset) {
    return (
      <ImageCell>
        <StyledImageAsset background asset={asset}>
          <span />
        </StyledImageAsset>
      </ImageCell>
    );
  }

  renderPromoContent() {
    const { influencerPromo } = this.props;
    return (
      <PromoContentContainer>
        <PromoModalHeadline id="promo-modal">
          <FormattedMessage
            defaultMessage="New Promo Available!"
            id="global_influencer.promo_header"
          />
        </PromoModalHeadline>
        <PromoModalText>
          <FormattedMessage
            defaultMessage="Welcome! {items} items to claim! You have a new promo code, start adding items to cart!"
            id="global_influencer.promo_text"
            values={{
              items: influencerPromo.maxProductQty,
            }}
          />
        </PromoModalText>
        <ShopNowButton href="/products/new">
          <FormattedMessage
            defaultMessage="SHOP NOW"
            id="global_influencer.shop_now_button"
          />
        </ShopNowButton>
      </PromoContentContainer>
    );
  }

  renderPromoAvailable() {
    return (
      <PromoModalContainer>
        <AssetContainer name="global_image_influencer_promo">
          {asset => {
            if (!asset || !asset.data) {
              return null;
            }
            return this.renderAsset(asset.data);
          }}
        </AssetContainer>
        {this.renderPromoContent()}
      </PromoModalContainer>
    );
  }

  renderNoPromo() {
    return (
      <NoPromoModalContainer>
        <PromoModalHeadline>
          <FormattedMessage
            defaultMessage="No Promo Available"
            id="global_influencer.no_promo_header"
          />
        </PromoModalHeadline>
        <PromoModalText>
          <FormattedMessage
            defaultMessage="You don’t have an active promo code, and this is your ambassador account. Want to work with us again? Email us: ambassadors@savagex.com"
            id="global_influencer.no_promo_text"
          />
        </PromoModalText>
        <OkButton href="/products/new">
          <FormattedMessage
            defaultMessage="OK"
            id="global_influencer.ok_button"
          />
        </OkButton>
      </NoPromoModalContainer>
    );
  }

  render() {
    const { influencerPromo, isOpen } = this.props;
    const hasActivePromo = influencerPromo.userHasActivePromo;
    return (
      <PromoModal
        isOpen={isOpen}
        onExit={this.onClose}
        overlayStyle={overlayStyle}
        titleId="promo-modal"
      >
        {hasActivePromo ? this.renderPromoAvailable() : this.renderNoPromo()}
      </PromoModal>
    );
  }
}

export default InfluencerPromoModal;
