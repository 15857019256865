import { getSession } from '@techstyle/redux-core';

const sizeChangeEvents = ({ getContext, trackEvent }) => {
  const { TRACK_SIZE_CHANGE } = require('../../actions/products');
  const sizeChange = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Change Product Size',
      properties: {
        feature: 'react',
        category: 'pdp',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        ...action.payload,
      },
    };
  });

  return { [TRACK_SIZE_CHANGE]: sizeChange };
};

export default sizeChangeEvents;
