import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled, { createGlobalStyle, css } from 'styled-components';

import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { OffCanvasMenu } from '@techstyle/react-components-legacy';
import { useFeature } from '@techstyle/react-features';
import { NavContainer } from '@techstyle/react-navigation';

import { mobile } from '../../styles';
import { useNavFilter } from '../../utils/navigation';
import { v1, v2 } from '../../utils/themeVersioning';
import { useTopNavSelector } from '../../utils/useTopNavSelector';
import { Component as CloseButton } from '../CloseButton';
import { Component as NewNavMenuLinks } from '../NewNavMenuLinks';
import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';
import SearchProvider from '../SearchProvider/SearchProvider';
import { Component as StaticIcon } from '../StaticIcon';

const BurgerNavModalStyles = createGlobalStyle`
  /* Force no scrolling of content underneath modal on Mobile Safari.
   * See: https://gist.github.com/claus/622a938d21d80f367251dc2eaaa1b2a9
   */
  @media (max-width: ${props => props.theme.breakpoints.xlarge - 1}px) {
    .BurgerNavModal {
      position: fixed;
      width: 100%;

      & [aria-hidden="true"] {
        background-color: rgba(255, 255, 255, 0.5);
      };
    }
  }
`;

const Container = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${v2`
    width: 100%;
    padding-left: 16px;
    ${({ $hideOnDesktop }) =>
      $hideOnDesktop &&
      css`
        @media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
          display: none;
        }
      `}
  `}
`;

const Icon = styled(StaticIcon).attrs({
  color: 'inherit',
  type: 'menu',
  size: 18,
})``;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;

  background-color: ${props =>
    props.theme.context.inverse
      ? props.theme.colors.scrim
      : props.theme.colors.white};
  color: ${props =>
    props.theme.context.inverse
      ? props.theme.colors.white
      : props.theme.colors.default};
  font-size: 18px;
  @media (max-width: 320px) {
    max-width: 250px;
  }

  ${mobile`
    ${v1`
      padding-top: 36px;
    `}
      ${v2`
      padding-top: 0px;
    `}

  `}
`;

const StyledNavMenu = css`
  flex: 0 0 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledNewNavMenuLinks = styled(NewNavMenuLinks)`
  ${StyledNavMenu}
  padding: 20px 0 0;
`;

const StyledOffCanvasMenu = styled(OffCanvasMenu)`
  @media (min-width: 1200px) {
    display: none;
  }

  > .Overlay {
    &.ReactModal__Overlay--after-open {
      background: ${props => props.theme.colors.scrim};
    }
    &.ReactModal__Overlay--before-close {
      background: transparent;
    }
    > .Modal {
      background-color: transparent;
      color: ${props => props.theme.colors.default};

      @media (max-width: ${props => props.theme.breakpoints.xlarge - 1}px) {
        /* Use 100% instead of 100vh. */
        /* See: https://gist.github.com/claus/622a938d21d80f367251dc2eaaa1b2a9#-vs-vh */
        height: 100%;
        overflow: visible;
        ${({ allowOverflowY }) =>
          allowOverflowY && `overflow-y: scroll; overflow-x: hidden;`}
        -webkit-overflow-scrolling: touch;
      }

      @media (max-width: 320px) {
        width: 250px;
      }
    }
  }
`;

const MenuCloseButton = styled(CloseButton)`
  color: ${props => props.theme.colors.white};
  ${props =>
    !props.theme.context.inverse
      ? `
        width: 35px;
        height: 35px;
        line-height: 11px;
      `
      : ''}

  ${v1`
      right: -40px;
    `}

  ${v2`
      left: 16px;
    `}

  padding: ${({ theme }) => theme.spacing.small}px;

  ${mobile`
    color: ${props => props.theme.colors.default};
    right: ${({ theme }) => theme.spacing.small}px;
    top: ${({ theme }) => theme.spacing.small}px;
    padding: 0;

    ${v1`svg {
      height: 19px;
      width: 19px;
    }`}
  `}
`;

const BurgerMenuButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${v2`
    width: 26px;
    height: 26px;

    > svg {
      height: 24px;
      width: 24px;
    }
    `}
`;

function BurgerNav({ hideOnDesktop, showAccountModal }) {
  const selectedNav = useTopNavSelector();
  const [isOpen, setOpen] = useState(false);
  const mobileNavUpdatesFeature = useFeature('mobile_nav_updates');
  const isMobileNavUpdatesFeatureEnabled =
    !!mobileNavUpdatesFeature?.data?.value;
  const { isActive, setCloseHamburger } = useContext(SearchProvider.Context);
  const { isMobile } = useBreakpoint();
  const theme = useTheme();
  const isNewTheme = theme.themeVersion === 'v2';

  /* OffCanvasMenu */
  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleSignIn = () => {
    closeMenu();
    showAccountModal && showAccountModal();
  };

  const navFilter = useNavFilter({ isMobile: true });

  const renderedCanvasMenu = navContainer => (
    <StyledOffCanvasMenu
      data-qa-automation="navMenuModal"
      bodyOpenClassName="BurgerNavModal ReactModal__Body--open"
      closeButton={
        theme.themeVersion === 'v1' ? <MenuCloseButton iconSize={16} /> : null
      }
      isOpen={!isActive && isOpen}
      onRequestClose={closeMenu}
      allowOverflowY={isMobileNavUpdatesFeatureEnabled}
    >
      {navContainer}
    </StyledOffCanvasMenu>
  );

  useEffect(() => {
    if (isMobileNavUpdatesFeatureEnabled && isMobile) {
      setCloseHamburger(() => closeMenu);
    }
    return function cleanup() {
      setCloseHamburger(() => () => {});
    };
  }, [isMobile, isMobileNavUpdatesFeatureEnabled, setCloseHamburger]);

  return (
    <Container $hideOnDesktop={hideOnDesktop}>
      <BurgerMenuButton
        data-qa-automation="burgerNavmenu"
        type="button"
        onClick={openMenu}
        aria-label="Navigation"
      >
        {!isNewTheme ? <Icon /> : <NewThemeIcon type="burger" />}
      </BurgerMenuButton>
      {renderedCanvasMenu(
        <NavContainer handle={selectedNav} filter={navFilter}>
          {({ data }) =>
            data ? (
              <Menu>
                <StyledNewNavMenuLinks
                  closeMenu={closeMenu}
                  nav={data}
                  showAccountModal={handleSignIn}
                />
              </Menu>
            ) : null
          }
        </NavContainer>
      )}
      <BurgerNavModalStyles />
    </Container>
  );
}

BurgerNav.propTypes = {
  hideOnDesktop: PropTypes.bool,
  showAccountModal: PropTypes.func,
};

export default BurgerNav;
