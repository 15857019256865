import React from 'react';

import Link from 'next/link';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import { Component as StaticIcon } from '../StaticIcon';

const StyledLink = styled.a`
  font-weight: bold;
  color: ${props => props.theme.colors.default};
`;

const ShopNowArrow = styled(StaticIcon).attrs({
  type: 'caret',
  size: 8,
})`
  margin-top: -1px;
  transform: rotate(-90deg);
`;

type ShopNowLinkProps = {
  className?: string;
  href?: string | {};
  as?: string;
};

const ShopNowLink = ({
  className,
  href = '/',
  ...linkProps
}: ShopNowLinkProps) => {
  const { 'theme-to-enable': themeToEnable } = useLDFlags();

  return (
    <Link passHref href={href} {...linkProps}>
      <StyledLink className={className}>
        <FormattedMessage id="global_cta.shop_now" defaultMessage="Shop now" />{' '}
        {themeToEnable === 'newTheme' ? null : <ShopNowArrow />}
      </StyledLink>
    </Link>
  );
};

export default ShopNowLink;
