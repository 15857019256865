export const LOAD_ADDRESSES_REQUEST = 'LOAD_ADDRESSES_REQUEST';
export const LOAD_ADDRESSES_SUCCESS = 'LOAD_ADDRESSES_SUCCESS';
export const LOAD_ADDRESSES_FAILURE = 'LOAD_ADDRESSES_FAILURE';

export const CREATE_ADDRESS_REQUEST = 'CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

export const VALIDATE_ADDRESS_REQUEST = 'VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_FAILURE = 'VALIDATE_ADDRESS_FAILURE';

export function validateAddress({
  address1,
  address2,
  city,
  state,
  zip,
  zipExt,
  countryCode,
}) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/address/verify',
      method: 'POST',
      body: JSON.stringify({
        address1,
        address2,
        city,
        state,
        zip,
        zipExt: zipExt || '',
        countryCode,
      }),
      actions: [
        VALIDATE_ADDRESS_REQUEST,
        VALIDATE_ADDRESS_SUCCESS,
        VALIDATE_ADDRESS_FAILURE,
      ],
    },
  };
}

export function loadAddresses() {
  return (dispatch, getState) => {
    const { account } = getState();
    if (!account.addresses.networkStatus.isLoading) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/addresses',
          actions: [
            LOAD_ADDRESSES_REQUEST,
            LOAD_ADDRESSES_SUCCESS,
            LOAD_ADDRESSES_FAILURE,
          ],
        },
      });
    }
  };
}

export function createAddress(address) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/addresses',
      method: 'POST',
      body: JSON.stringify({
        type: 'shipping',
        phone: '',
        email: '',
        address2: '',
        state: address.countryCode === 'US' ? '' : '--',
        ...address,
      }),
      actions: [
        CREATE_ADDRESS_REQUEST,
        CREATE_ADDRESS_SUCCESS,
        CREATE_ADDRESS_FAILURE,
      ],
    },
  };
}

export function updateAddress(address) {
  return {
    bentoApi: {
      endpoint: `accounts/me/addresses/${address.id}`,
      method: 'POST',
      body: JSON.stringify(address),
      actions: [
        UPDATE_ADDRESS_REQUEST,
        UPDATE_ADDRESS_SUCCESS,
        UPDATE_ADDRESS_FAILURE,
      ],
    },
  };
}

export function setDefaultAddress(address) {
  const meta = { defaultUpdated: true };
  return {
    bentoApi: {
      endpoint: `accounts/me/addresses/${address.id}`,
      method: 'POST',
      body: JSON.stringify({
        type: 'shipping',
        ...address,
        isDefault: true,
        applyCartShipAddress: true,
      }),
      actions: [
        UPDATE_ADDRESS_REQUEST,
        (payload, extraMeta) => {
          payload = {
            ...address,
            ...payload,
            isDefault: true,
          };

          return {
            type: UPDATE_ADDRESS_SUCCESS,
            meta: {
              ...meta,
              ...extraMeta,
            },
            payload,
          };
        },
        UPDATE_ADDRESS_FAILURE,
      ],
    },
  };
}

export function deleteAddress(address) {
  return {
    bentoApi: {
      endpoint: `accounts/me/addresses/${address.id}`,
      method: 'DELETE',
      actions: [
        DELETE_ADDRESS_REQUEST,
        (payload, meta) => ({
          type: DELETE_ADDRESS_SUCCESS,
          payload: address,
          meta,
        }),
        DELETE_ADDRESS_FAILURE,
      ],
    },
  };
}
