import {
  findBraSizeCategory,
  checkPreorderForSingleItem,
} from './productDetail';
import { hasBraSize, isOneSize } from './sizes';

export const checkIsMatchedColor = (color, item) => {
  if (!color.linked_master_product || !color.linked_master_product.length) {
    return (
      item.masterProductId === color.related_product_id ||
      item.master_product_id === color.related_product_id
    );
  } else {
    const linkedColorProduct = Array.isArray(color.linked_master_product)
      ? color.linked_master_product[0]
      : color.linked_master_product;
    return (
      item.masterProductId === color.related_product_id ||
      item.masterProductId === linkedColorProduct.master_product_id
    );
  }
};

export const getDefaultColorForQuickView = ({
  masterProduct,
  individualItemInCart,
}) => {
  const colorList = masterProduct.related_product_id_object_list || [];
  const defaultColor = colorList.find(color =>
    checkIsMatchedColor(color, individualItemInCart)
  );

  return {
    name: defaultColor.color,
    value: defaultColor.related_product_id,
    itemNumber: defaultColor.item_number,
    permalink: defaultColor.permalink,
    productId: defaultColor.related_product_id,
    sizes: defaultColor.product_id_object_list,
    linkedProduct: defaultColor.linked_master_product,
    datePreorderExpires: defaultColor.date_preorder_expires,
  };
};

export const getProductDiff = ({
  color,
  linkedColorProduct,
  newSizeProduct,
}) => ({
  master_product_id: newSizeProduct.is_linked_size
    ? linkedColorProduct?.master_product_id
    : color.productId,
  date_preorder_expires: newSizeProduct.is_linked_size
    ? linkedColorProduct?.date_preorder_expires
    : color.datePreorderExpires,
  available_quantity_master: color.available_quantity,
  available_quantity_preorder_master: color.available_quantity_preorder,
  permalink: newSizeProduct.is_linked_size
    ? linkedColorProduct?.permalink
    : color.permalink,
  item_number: newSizeProduct.is_linked_size
    ? linkedColorProduct?.item_number
    : color.itemNumber,
  warehouse_id_object_list: null,
});

export const formatSizeListForOneSize = ({ isCurvy, sizeList }) =>
  sizeList.map(size => ({
    ...size,
    is_plus_size: size.is_self ? isCurvy : !isCurvy,
  }));

export const getCurrentSelections = (
  product,
  isProductSet,
  cartItem,
  currentDate
) => {
  const {
    master_product_id: masterProductId,
    related_product_id_object_list: colorList,
  } = product;

  const individualItemInCart = isProductSet
    ? cartItem.bundleItems.find(itemInSet => {
        return colorList.some(color => checkIsMatchedColor(color, itemInSet));
      })
    : cartItem;

  const color = getDefaultColorForQuickView({
    masterProduct: product,
    individualItemInCart,
  });

  let defaultBand = null;
  let defaultCup = null;

  if (hasBraSize(product)) {
    const { band, cup } = findBraSizeCategory(individualItemInCart.size);
    defaultBand = band;
    defaultCup = cup;
  }

  const sizeProduct = color.sizes.find(
    size =>
      size.size === individualItemInCart.size &&
      size.product_id === individualItemInCart.productId
  );

  const availableQuantity = checkPreorderForSingleItem({
    product: individualItemInCart,
    now: currentDate,
  })
    ? sizeProduct?.available_quantity_preorder
    : sizeProduct?.available_quantity;

  return {
    masterProductId,
    color,
    size: {
      band: defaultBand,
      cup: defaultCup,
      error: availableQuantity ? null : 'SIZE_UNAVAILABLE',
      value: individualItemInCart.size,
      sizeProduct,
    },
    hasMultipleOneSizes: isOneSize(product) && color.sizes.length > 1,
  };
};

export const getModalMarginLimits = newMargin => {
  if (newMargin < 0) {
    return 0;
  } else {
    return newMargin;
  }
};
