export function getIOSVersion() {
  const agent = navigator?.userAgent;
  if (agent) {
    if (/(iPhone|iPod|iPad)/i.test(agent)) {
      return agent
        .match(/OS [\d_]+/i)[0]
        .substring(3)
        .split('_')
        .map(n => parseInt(n))[0];
    }
  }

  return undefined;
}
