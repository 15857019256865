import * as React from 'react';

export const FooterContext = React.createContext({});

export const FooterProvider = ({ children }) => {
  const ref = React.useRef();

  return (
    <FooterContext.Provider value={ref}>{children}</FooterContext.Provider>
  );
};

export const useFooterContext = () => {
  const ctx = React.useContext(FooterContext);

  return ctx;
};
