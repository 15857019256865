import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';

export default function QueueItSnippet({
  clientScript = config.get('public.queueIt.clientScript'),
  configScript = config.get('public.queueIt.configScript'),
  customerId = config.get('public.queueIt.customerId'),
}) {
  return (
    <>
      <script src={clientScript} />
      <script data-queueit-c={customerId} src={configScript} />
    </>
  );
}

QueueItSnippet.propTypes = {
  /**
   * The script URL.
   */
  src: PropTypes.string,
  /**
   * The value that will populate the `data-queueit-c` attribute.
   */
  customerId: PropTypes.string,
};
