import React from 'react';

import PropTypes from 'prop-types';

export default function DebugVisitorStatus({
  authType,
  isVip,
  isVisitor,
  showVisitorStatus = false,
  visitorStatus,
}) {
  if (!showVisitorStatus) {
    return null;
  }
  return (
    <div
      style={{
        padding: 5,
        fontSize: 11,
        textAlign: 'center',
        background: 'rgb(128, 240, 215)',
        color: '#222',
      }}
    >
      visitorStatus: <strong>{visitorStatus || '?'}</strong> &mdash; isVisitor:{' '}
      <strong>{isVisitor ? 'true' : 'false'}</strong> &mdash; isVip:{' '}
      <strong>{isVip ? 'true' : 'false'}</strong> &mdash; authType:{' '}
      <strong>{authType}</strong>
    </div>
  );
}

DebugVisitorStatus.propTypes = {
  authType: PropTypes.number,
  isVisitor: PropTypes.bool,
  isVip: PropTypes.bool,
  showVisitorStatus: PropTypes.bool,
  visitorStatus: PropTypes.string,
};
