import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

const maxSelectableQuantity = config.get(
  'public.quantityRules.maxSelectableQuantity'
);

const QuantitySelector = styled.select`
  order: 1;
  min-width: 46px;
  height: 24px;
  border: 1px solid ${props => props.theme.colors.default};
  border-radius: 0;
  padding: 4px;
  margin: 8px 0 0 0;
  font-size: 12px;
  font-family: inherit;
  background-color: transparent;
  color: ${props => props.theme.colors.default};
`;

class BasketItemQuantitySelector extends React.Component {
  static propTypes = {
    isProductSet: PropTypes.bool,
    product: PropTypes.object.isRequired,
    showQuantitySelector: PropTypes.bool,
    theme: PropTypes.object,
  };

  static defaultProps = {
    showQuantitySelector: true,
  };

  handleChange = event => {
    const { onChange } = this.props;
    onChange && onChange(event);
  };

  render() {
    const {
      isProductSet,
      product,
      showQuantitySelector,
      theme: { context },
    } = this.props;
    const { availableQuantity, bundleItems, quantity } = product;

    const outOfStock =
      isProductSet && bundleItems.length
        ? bundleItems.some(item => item.availableQuantity <= 0)
        : availableQuantity <= 0;

    if (isProductSet || outOfStock || !showQuantitySelector) {
      return null;
    }

    const leastAvailable =
      isProductSet && bundleItems.length
        ? bundleItems.reduce((result, item) => {
            if (item.availableQuantity < result.availableQuantity) {
              return item;
            }
            return result;
          }).availableQuantity
        : availableQuantity;

    let quantityList = [];

    if (showQuantitySelector) {
      // Max quantity value for product selector
      const currentMaxQuantity = Math.max(
        0,
        Math.min(maxSelectableQuantity, leastAvailable),
        quantity
      );

      // Generate quantity selector values
      quantityList = Array(currentMaxQuantity)
        .fill(1)
        .map((n, index) => n + index);
    }

    return (
      <QuantitySelector
        onChange={this.handleChange}
        value={quantity}
        className={
          context.cartDrawer ? 'bfx-minicart-product-qty' : 'bfx-product-qty'
        }
      >
        {quantityList.map(number => {
          return (
            <option key={number} value={number}>
              {number}
            </option>
          );
        })}
      </QuantitySelector>
    );
  }
}

export default withTheme(BasketItemQuantitySelector);
