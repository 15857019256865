import React from 'react';

import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { BasicModal } from '../../styles/modal';

const dialogStyle = css`
  min-width: 0;
  min-height: 0;
  max-height: 100vh;
  background: transparent;
  outline: none;
`;

const overlayStyle = css`
  background-color: ${props => props.theme.colors.scrimLavender};
  font-weight: bold;
`;

const StyledModal = styled(BasicModal).attrs(props => ({
  overlayColor: props.theme.colors.scrimLavender,
  overlayStyle: overlayStyle,
  dialogStyle: dialogStyle,
}))``;

const NonModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.scrimLavender};
  font-weight: bold;
  text-align: center;
  z-index: 1;
`;

const ImageWrapper = styled.div`
  padding: ${props => props.theme.spacing.medium}px;
  width: 100%;

  background-color: ${props => props.theme.colors.scrimLavender};
  text-align: center;
`;

class Loading extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    inline: PropTypes.bool,
    isOpen: PropTypes.bool,
    fixed: PropTypes.bool,
    theme: PropTypes.object,
  };

  static defaultProps = {
    className: ``,
    inline: false,
    isOpen: true,
    fixed: true,
    theme: {},
  };

  render() {
    const { className, inline, isOpen, fixed, theme } = this.props;
    const isNewTheme = theme.themeVersion === 'v2';
    const content = (
      <img
        alt="Loading..."
        src={
          isNewTheme
            ? '/static/images/sxf_loadingv2.gif'
            : '/static/images/sxf_loading.gif'
        }
        height={isNewTheme ? '120px' : '50px'}
        id="loading-modal"
        data-qa-automation="homeLoadingIcon"
      />
    );
    if (fixed) {
      return (
        <StyledModal
          className={className}
          isOpen={isOpen}
          closeButton={null}
          ariaHideApp={false}
          titleId="loading-modal"
        >
          {content}
        </StyledModal>
      );
    }
    if (inline) {
      return <ImageWrapper className={className}>{content}</ImageWrapper>;
    }
    return <NonModalWrapper className={className}>{content}</NonModalWrapper>;
  }
}

export default withTheme(Loading);
