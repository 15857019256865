import React from 'react';

import styled from 'styled-components';

import { ImageAsset, useAsset } from '@techstyle/react-assets';

import { NavBarBGColors } from '../../constants/navBarBGColors';
import { v1 } from '../../utils/themeVersioning';

const StyledLogo = styled(ImageAsset)`
  @media (max-width: 374px) {
    padding-top: 4px;
    width: 180px;
  }

  @media (min-width: 375px) {
    ${v1`
        padding-top: 7px;
      `}

    width: 221px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    width: 248px;
  }

  img {
    max-height: 100%;
  }
`;

const BlackLogo = styled(StyledLogo).attrs({
  'data-testid': 'black-logo',
})`
  display: ${props =>
    props.backgroundState === NavBarBGColors.WHITE ? 'block' : 'none'};
`;

const WhiteLogo = styled(StyledLogo).attrs({
  'data-testid': 'white-logo',
})`
  display: ${props =>
    props.backgroundState === NavBarBGColors.WHITE ? 'none' : 'block'};
`;

export default function NavFullLogo({ backgroundState }) {
  const assetContainerFullLogo = useAsset('global_image_fulllogo', {
    filter: undefined,
    forceStoreGroup: undefined,
    multi: true,
  });
  const { data } = assetContainerFullLogo;
  const assetsFullLogo = data ? data.assets : [];

  return assetsFullLogo.map(asset => {
    if (!asset.options.customVars) {
      return null;
    }
    switch (asset.options.customVars?.color) {
      case NavBarBGColors.WHITE:
        return (
          <WhiteLogo
            asset={asset}
            backgroundState={backgroundState}
            key={`NavLogo-${asset.id}`}
            alt={asset.options.customVars.alt || 'Nav-WhiteLogo'}
          />
        );
      case 'black':
        return (
          <BlackLogo
            asset={asset}
            backgroundState={backgroundState}
            key={`NavLogo-${asset.id}`}
            alt={asset.options.customVars.alt || 'Nav-BlackLogo'}
          />
        );
      default:
        return null;
    }
  });
}
