import { basePageViewData, getPartialTrackingData } from '../utils';

const baseByoInterstitialPageView = ({ trackPageView, getContext }) => {
  const { TRACK_BYO_INTERSTITIAL_OPEN } = require('../../actions/byo');

  const byoInterstitialOpened = trackPageView(
    (action, _prevState, nextState) => {
      const context = getContext();
      const { cookies } = context;
      const { masterProductId } = action.payload;
      const additionalTrackingData = getPartialTrackingData(
        nextState,
        context,
        ['membership_status']
      );

      return {
        category: 'BYO Interstitial',
        name: 'BYO Interstitial',
        properties: {
          ...basePageViewData(nextState, { cookies }),
          ...additionalTrackingData,
          product_id: masterProductId,
        },
      };
    }
  );

  return {
    [TRACK_BYO_INTERSTITIAL_OPEN]: byoInterstitialOpened,
  };
};

export default baseByoInterstitialPageView;
