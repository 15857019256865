/**

"Payment method" objects contain fields identifying the ID, Type, Method, and
Data of the payment method.

Confusingly, different API methods call these fields different things or exclude
certain information.

- IDs: numeric identifiers for the specific payment method saved on the user's
  account. For Vantiv, this will be `creditCardId` for credit cards. For Adyen,
  this will be `paymentServiceProviderId` or `pspId` depending on the endpoint.

- Payment Type: A value that identifies the actual payment method type at a
  high level regardless of payment provider, for example:
  - Credit cards: `creditcard`
  - SEPA: `sepa`

- Payment Method: The value used by various API endpoints that often isn't very
  useful for distinguishing the actual Payment Type. For example, both Adyen
  credit cards and Adyen SEPA payment methods will have `psp`, which doesn't
  tell us much. Use this only when interacting with API endpoints, otherwise
  prefer `paymentType`.

- Payment Data: Information about the specific payment method, like the credit
  card number, expiration year, name on card, etc. To prevent mistakes where
  the payment type is assumed and we read the wrong data, this will be on a
  different field completely depending on Payment Type: `cardInfo` for credit
  cards and `sepaInfo` for SEPA.

API Endpoint Quirks:

- The objects returned from the `orderhistory` endpoint tell us the
  "Payment Method" but not its ID, so we can't determine its Payment Data nor
  Payment Type (for example SEPA vs. credit card).
- The `paymentInfo` objects returned with orders from the `orders/:id` and
  `cart/submit` endpoints for Adyen use `pspId` for the IDs, not
  `paymentServiceProviderId`.
- The `payments` endpoint for Adyen does not return `cardType` that lets us
  distinguish SEPA vs. credit card, so we instead have to guess based on the
  existence of `lastFourDigits`, which will be empty for SEPA.
- The `payments` endpoint for Adyen does not return `expMonth` or `expYear`
  for credit cards, so we can't display it.

 */

/**
 * Gets a `cardInfo` or `sepaInfo` object from the list of `paymentMethods`.
 */
export function getPaymentById(
  paymentMethods,
  paymentId,
  paymentProvider,
  paymentMethod
) {
  if (!paymentId) {
    return;
  }
  const vantivIdField =
    paymentMethod === 'psp' ? 'paymentServiceProviderId' : 'creditCardId';
  const idField = {
    adyen: 'paymentServiceProviderId',
    vantiv: vantivIdField,
  }[paymentProvider];

  if (!idField) {
    throw new Error(`Unknown payment provider: '${paymentProvider}'`);
  }
  const paymentData = paymentMethods.find(
    payment => payment[idField] === paymentId
  );
  if (paymentData) {
    return paymentData;
  } else {
    return {
      [idField]: paymentId,
      incomplete: true,
    };
  }
}

function padToString(num) {
  if (typeof num === 'number' || typeof num === 'string') {
    let str = num.toString();
    if (str.length < 2) {
      str = '0' + str;
    }
    return str;
  }
  return num;
}

export function createPaymentMethod(
  paymentId,
  paymentType,
  paymentMethod,
  paymentData
) {
  const output = { paymentId, paymentType, paymentMethod };
  const infoField = {
    creditcard: 'cardInfo',
    sepa: 'sepaInfo',
    paypal: 'paypalInfo',
    afterpay: 'afterpayInfo',
    cashapppay: 'cashAppInfo',
  }[paymentType];
  if (infoField) {
    if (paymentMethod === 'psp') {
      // Convert expiration date fields from numbers to strings
      const expMonth = padToString(paymentData.expMonth);
      const expYear = padToString(paymentData.expYear);
      output[infoField] = {
        ...paymentData,
        expMonth,
        expYear,
      };
    } else {
      output[infoField] = paymentData;
    }
  }
  return output;
}

/**
 * Often the API doesn't tell us whether a `psp` payment is credit card or SEPA,
 * so we have to determine it ourselves based on the payment data. It's possible
 * that we can't determine this info yet if the payment data hasn't loaded!
 */
export function getPspType(paymentData) {
  if (paymentData.cardType === 'sepadirectdebit') {
    return 'sepa';
  } else if (paymentData.cardType === 'braintreepaypal') {
    return 'paypal';
  } else if (paymentData.cardType === 'afterpay') {
    return 'afterpay';
  }
  if (paymentData.cardType === 'cashapppay') {
    return 'cashapppay';
  }
  if (paymentData.incomplete) {
    return null;
  }
  if (!paymentData.lastFourDigits) {
    return 'sepa';
  }
  return 'creditcard';
}

export function createPaymentMethodFromId(
  payments,
  paymentId,
  paymentMethod,
  paymentProvider
) {
  if (paymentId) {
    const paymentData = getPaymentById(
      payments,
      paymentId,
      paymentProvider,
      paymentMethod
    );
    if (paymentData) {
      return createPaymentMethodFromData(paymentData, paymentProvider);
    }
  }
  return {
    paymentId: paymentId,
    paymentType: null,
    paymentMethod: paymentMethod,
  };
}

export function createPaymentMethodFromData(paymentData, paymentProvider) {
  if (!paymentData) {
    return;
  }
  const paymentType = getPspType(paymentData);
  const id =
    paymentType !== 'creditcard'
      ? paymentData.paymentServiceProviderId
      : paymentData.creditCardId;
  const paymentMethod = paymentType !== 'creditcard' ? 'psp' : 'creditcard';

  switch (paymentProvider) {
    case 'adyen':
      return createPaymentMethod(
        paymentData.paymentServiceProviderId,
        paymentType,
        'psp',
        paymentData
      );
    case 'vantiv':
      return createPaymentMethod(id, paymentType, paymentMethod, paymentData);
    default:
      throw new Error(`Unknown payment provider: '${paymentProvider}'`);
  }
}

export function createPaymentMethodFromOrder(order, paymentProvider) {
  const { paymentMethod } = order;

  switch (paymentProvider) {
    case 'adyen':
      switch (paymentMethod) {
        case 'psp': {
          if (order.paymentInfo) {
            // Fix up `pspId` field name to match `paymentServiceProviderId`
            // field that comes back from the payments endpoint.
            const { pspId: paymentId, ...rest } = order.paymentInfo;
            const paymentData = {
              ...rest,
              paymentServiceProviderId: paymentId,
            };
            return createPaymentMethod(
              paymentId,
              getPspType(paymentData),
              paymentMethod,
              paymentData
            );
          }
        }
      }
      break;
    case 'vantiv':
      switch (paymentMethod) {
        case 'creditcard': {
          let paymentId = null;
          let cardInfo;
          if (order.paymentInfo) {
            cardInfo = order.paymentInfo;
            paymentId = cardInfo.creditCardId;
          }
          return createPaymentMethod(
            paymentId,
            'creditcard',
            paymentMethod,
            cardInfo
          );
        }
        case 'psp': {
          if (order.paymentInfo) {
            const { pspId: paymentId, ...rest } = order.paymentInfo;
            const paymentData = {
              ...rest,
              address: order.billingAddress,
              paymentServiceProviderId: paymentId,
            };
            return createPaymentMethod(
              paymentId,
              getPspType(paymentData),
              paymentMethod,
              paymentData
            );
          }
        }
      }
      break;
  }
  return {
    paymentId: null,
    paymentType: null,
    paymentMethod: order.paymentMethod || null,
  };
}
