import buildEvents from '../buildEvents';

import byoInterstitialOpened from './byoInterstitialOpened';
import checkoutStepChange from './checkoutStepChange';
import membershipSkipChange from './membershipSkipChange';
import pageView from './pageView';
import signUpModalOpened from './signUpModalOpened';

const eventWrapper = methods =>
  buildEvents(
    [
      byoInterstitialOpened,
      checkoutStepChange,
      membershipSkipChange,
      pageView,
      signUpModalOpened,
    ],
    methods
  );

export default eventWrapper;
