import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import { FormattedMessage, useIntl } from '@techstyle/react-intl';
import { useCookies } from '@techstyle/redux-core';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as StaticIcon } from '../StaticIcon';

declare global {
  interface Window {
    OneTrust: any;
  }
}

const COOKIE_BAR_KEY = 'closed_cookie_bar';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 96px;

  color: ${({ theme }) => theme.colors.white};
  padding: 20px 20px 30px;
  z-index: 9999999 !important;

  ${v1`
       background-color: ${({ theme }) => theme.colors.black700};
    `}

  ${v2`
      background-color: ${({ theme }) => theme.colors.lavender800};
      `}
`;

const Container = styled.div`
  position: relative;
`;

const ConsentHeader = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
`;

const ConsentText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  padding-right: 24px;
`;

const StyledLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
`;

const CloseIcon = styled(StaticIcon).attrs(props => {
  return {
    type: 'close',
    size: 16,
    color: props.theme.colors.white,
  };
})`
  stroke: ${props => props.theme.colors.white};
  stroke-width: 1.5px;
`;

const Button = styled.button.attrs(({ type = 'button' }) => {
  return {
    type,
  };
})`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const CookieBar = () => {
  const { 'cookie-bar': cookieBarFeatureEnabled } = useLDFlags();
  const intl = useIntl();
  const cookieHeaderText = intl.messages['cookie_bar.header'];
  const hasCookieHeaderText =
    cookieHeaderText &&
    cookieHeaderText !== '&nbsp;' &&
    cookieHeaderText.length > 0;

  const [cookies, setCookies] = useCookies([COOKIE_BAR_KEY]);
  const [isCookieBarOpen, setIsCookieBarOpen] = useState(false);

  const hasViewedCookieBar = cookies[COOKIE_BAR_KEY] === 'true';

  useEffect(() => {
    if (!hasViewedCookieBar && cookieBarFeatureEnabled) {
      setIsCookieBarOpen(true);
    }
  }, [hasViewedCookieBar, cookieBarFeatureEnabled]);

  const handleCloseCookieBar = () => {
    setCookies(COOKIE_BAR_KEY, 'true', {
      path: '/',
    });
    setIsCookieBarOpen(false);
  };

  const openOneTrustModal = event => {
    if (event) {
      event.preventDefault();
    }

    if (
      typeof window !== 'undefined' &&
      typeof window?.OneTrust?.ToggleInfoDisplay === 'function'
    ) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  return (
    <>
      {isCookieBarOpen ? (
        <Wrapper>
          <Container>
            {hasCookieHeaderText ? (
              <ConsentHeader>
                <FormattedMessage
                  id="cookie_bar.header"
                  defaultMessage="Cookie Consent"
                />
              </ConsentHeader>
            ) : null}
            <ConsentText>
              <FormattedMessage
                id="cookie_bar.consent_text"
                defaultMessage="This website uses cookies and related technologies described in our {privacyPolicyLink} and are always on. To change your consent preferences including opting out, click {cookieSettingsLink}."
                values={{
                  privacyPolicyLink: (
                    <StyledLink
                      href="/privacypolicy?nav=privacy-main"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="cookie_bar.privacy_policy_link"
                        defaultMessage="Privacy Policy"
                      />
                    </StyledLink>
                  ),
                  cookieSettingsLink: (
                    <StyledLink onClick={openOneTrustModal}>
                      <FormattedMessage
                        id="cookie_bar.cookie_settings_link"
                        defaultMessage="Cookie Settings"
                      />
                    </StyledLink>
                  ),
                }}
              />
            </ConsentText>
            <Button onClick={handleCloseCookieBar}>
              <CloseIcon />
            </Button>
          </Container>
        </Wrapper>
      ) : null}
    </>
  );
};

export default CookieBar;
