import { css } from 'styled-components';

import { Select } from '@techstyle/react-components-legacy';

import globalStyle from './global';
import { transformStylesToVariants } from './utils';

import { desktop } from './index';

// Using this themeVersion string to evaluate which theme to use
// instead of having to put the FF in almost every component
const themeVersion = `v1`;

// NOTE: Please do not add any new colors.
// If a design is using a color that is not listed here,
// please ask the designer to map it to a color from the design system.
const baseColors = {
  black: '#000000',
  black900: '#18181e', // default
  black800: '#292a33',
  black750: '#363745',
  black700: '#414254',
  gray500: '#70758c', // subdued
  gray450: '#80858C',
  gray400: '#8f92a6',
  gray300: '#c2c4d1',
  gray250: '#ededf0',
  gray200: '#dadbe3',
  gray175: '#E5E4F4',
  gray150: '#f7f7f7',
  gray100: '#fdfdfd',
  green700: '#004852',
  green600: '#0a606b',
  green500: '#1a7681',
  green400: '#3d9ca8',
  green450: '#85cad3',
  green300: '#bbe4e9',
  green200: '#e4f4f6',
  green100: '#fafcfc',
  greenCashApp: '#00D64B',
  lavender700: '#22078c',
  lavender600: '#370eb2', // promo
  lavender550: '#6e7296',
  lavender500: '#664ede', // active
  lavender450: '#8f84f0',
  lavender400: '#cabdff',
  lavender350: '#5161bd',
  lavender300: '#e6deff',
  lavender250: '#efebff',
  lavender225: '#bdbcdb24', // new checkout UI
  lavender200: '#f7f5fc',
  lavender150: '#e5e4f4',
  lavender100: '#fbfafc',
  layflatGray: '#f8f8f8',
  red700: '#380007',
  red500: '#c92c49', // error
  red400: '#da536c',
  red100: '#ffe6e9',
  white: '#ffffff',

  // NOTE: DEPRECTAED BRAND COLORS!! DO NOT USE!!
  // Still TBD by Design.
  electricPurple12: 'rgba(124, 137, 211, 0.12)',
  lavenderGradient: 'linear-gradient(90deg, #425fa1 0%, #b4479a 100%)',
  redViolet12: 'rgba(199, 41, 102, 0.12)',
};

const semanticColors = {
  active: baseColors.lavender500,
  darkShadow1: `${baseColors.black900}` + percentToHex(35),
  darkShadow2: `${baseColors.black900}` + percentToHex(40),
  darkShadow3: `${baseColors.black900}` + percentToHex(45),
  darkShadow4: `${baseColors.black900}` + percentToHex(50),
  default: baseColors.black900,
  error: baseColors.red500,
  focusShadow: `${baseColors.lavender500}` + percentToHex(50),
  lightShadow1: `${baseColors.black900}` + percentToHex(10),
  lightShadow2: `${baseColors.black900}` + percentToHex(15),
  lightShadow3: `${baseColors.black900}` + percentToHex(20),
  lightShadow4: `${baseColors.black900}` + percentToHex(25),
  scrim: `${baseColors.black900}` + percentToHex(60),
  scrimLavender: `${baseColors.lavender100}` + percentToHex(80),
  scrimWhite: `${baseColors.white}` + percentToHex(80),
  promo: baseColors.lavender600,
  subdued: baseColors.gray500,
  whiteSubdued: `${baseColors.white}` + percentToHex(50),
};

const colors = {
  ...baseColors,
  ...semanticColors,
};

function percentToHex(percent) {
  const percentages = {
    100: 'ff',
    95: 'f2',
    90: 'e6',
    85: 'd9',
    80: 'cc',
    75: 'bf',
    70: 'b3',
    65: 'a6',
    60: '99',
    55: '8c',
    50: '80',
    45: '73',
    40: '66',
    35: '59',
    30: '4d',
    25: '40',
    20: '33',
    15: '26',
    10: '1a',
    5: '0d',
    0: '00',
  };
  return percentages[percent] || 'ff';
}

const getButtonBackgroundColor = props => {
  if (props.secondary) {
    return 'transparent';
  }
  if (props.alternate) {
    return props.theme.colors.active;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.default;
};

const getButtonFontColor = props => {
  if (props.secondary) {
    return props.white ? props.theme.colors.white : props.theme.colors.default;
  }
  return props.white ? props.theme.colors.default : props.theme.colors.white;
};

const getButtonBorderColor = props => {
  if (props.alternate) {
    return props.theme.colors.active;
  }
  if (props.secondary && props.white) {
    return props.theme.colors.white;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.default;
};

const defaultDisabledButtonStyle = css`
  background-color: ${colors.gray250};
  border-color: ${colors.gray250};
  color: ${colors.subdued};
  cursor: not-allowed;
`;

const defaultButtonStyle = css`
  align-items: center;
  background-color: ${getButtonBackgroundColor};
  border: 1px solid ${getButtonBorderColor};
  border-radius: 22px;
  border-width: 2px;
  box-sizing: border-box;
  color: ${getButtonFontColor};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
  letter-spacing: 1.3px;
  line-height: 17px;
  margin: 0;
  min-width: 200px;
  outline: 0;
  padding: 0 ${props => props.theme.spacing.small}px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  width: ${props => (props.fullWidth ? '100%' : 'initial')};

  &:disabled {
    ${defaultDisabledButtonStyle};
  }
  /* Apply disabled styles for ButtonLink */
  ${props =>
    props.disabled && props.tagName !== 'button'
      ? defaultDisabledButtonStyle
      : ''};

  &:focus {
    outline: 2px solid ${colors.active};
  }

  svg {
    margin-right: 5px;
    margin-bottom: 2px;
    /* Horizontally center the content better. */
    margin-left: -5px;
  }
`;

const textInputVariants = {
  default: {
    inputStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 0px 15px 8px 0px;
      line-height: 21px;
      ${desktop`
      font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
      ${({ hasError }) =>
        !hasError &&
        css`
          &[data-label-position='inside'] {
            border-bottom: solid 10px ${colors.error};
          }
        `}
    `,
    inputBoxStyle: css`
      border-color: ${({ hasError }) =>
        hasError ? colors.error : colors.default};
      border-style: none none solid;
      border-width: 0px 0px 2px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 0px;
      background: transparent none repeat scroll 0% 0%;
      padding: 0;
      &[data-has-focus] {
        padding: 0;
        border-width: 0px 0px 2px;
        border-style: none none solid;
        box-shadow: none;
        border-color: ${colors.active};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      },
    `,
    errorStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.error};
    `,
    hintStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.default};
    `,
    labelStyle: css`
      margin: 0;
      text-transform: uppercase;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
        line-height: 21px;
      }
      &[data-label-position='inside'] {
        font-size: 10px;
        color: ${({ hasError }) => (hasError ? colors.error : colors.subdued)};
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
      }
      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
      }
    `,
  },
  fullBorder: {
    inputStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 2px 15px 0 0;
      line-height: 21px;
      ${desktop`
      font-size: 14px;
    `}
      &.focus-visible,
    &:focus-visible {
        outline: none !important;
      }
      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputBoxStyle: css`
      border-color: ${({ hasError }) =>
        hasError ? colors.error : colors.gray300};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      background: transparent none repeat scroll 0% 0%;
      padding: 6px 16px 8px 16px;
      label::first-letter {
        text-transform: capitalize;
      }

      &[data-has-focus] {
        padding: 6px 16px 8px 16px;
        border-width: 1px;
        border-radius: 4px;
        border-color: ${({ hasError }) =>
          hasError ? colors.error : colors.active};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${({ hasError }) => (hasError ? colors.error : colors.active)};
        }
      }
      [data-after-input],
      [data-before-input] {
        margin: 0;
      }

      ${({ hasError }) =>
        hasError &&
        css`
          border-color: ${colors.error};
          &[data-has-focus] {
            border-color: ${colors.error};
          }
        `}
    `,
    errorStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.error};
    `,
    hintStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${({ hasError }) => (hasError ? colors.error : colors.default)};
    `,
    labelStyle: css`
      background: transparent none repeat scroll 0% 0%;
      margin: 0;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
      }
      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${({ hasError }) => (hasError ? colors.error : colors.subdued)};
        transform: translate3d(0, 0, 0) scale(1);
        margin-left: 0px;
      }
      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
        line-height: 1;
        color: ${({ hasError }) => (hasError ? colors.error : colors.gray500)};
      }
    `,
  },
};

export default {
  // When the `fonts` are in the theme object, a `preload` link is  automatically added
  // to the head of the document by the `styledComponentsExtension`.
  // Currently when `preload` is used, the font flickering can occur, even in production.
  // so we are disabling it for now.
  // fonts,
  globalStyle,
  themeVersion,
  colors,
  focusColor: colors.active,
  breakpoints: {
    small: 0,
    medium: 414,
    mobile: { maxWidth: 767 },
    large: 768,
    xlarge: 1024,
    xxlarge: 1440,
    xxxlarge: 1728,
  },
  spacing: {
    none: 0,
    tiny: 8,
    small: 16,
    moderate: 24,
    medium: 32,
    large: 40,
    xlarge: 80,
  },

  // A place to store flags for helping with contextual styling. For example,
  // you could set a `cartDrawer` flag so that components can render differently
  // if they know they are inside the CartDrawer component, without needing to
  // use CSS selectors.
  context: {},
  desktopBreakpoint: 'large',
  assets: {
    htmlStyles: css`
      font-size: 12px;

      ${desktop`
        font-size: 14px;
      `};

      @media screen and (min-width: ${props =>
          props.theme.breakpoints.xlarge}px) {
        font-size: 16px;
      }
    `,
    imageBreakpoint: 768,
  },
  assetSizes: {
    minimumHeight: {
      mobile: '100px',
    },
  },
  header: {
    variants: transformStylesToVariants(
      {
        h1: css``,
        h2: css``,
        h3: css``,
      },
      'textStyles'
    ),
  },
  subHeader: {
    variants: transformStylesToVariants(
      {
        subHeading1: css``,
        subHeading2: css``,
        subHeading3: css``,
        subHeading4: css``,
        subHeading5: css``,
        subHeading1Uppercase: css``,
        subHeading2Uppercase: css``,
        subHeading3Uppercase: css``,
        subHeading4Uppercase: css``,
        subHeading5Uppercase: css``,
      },
      'textStyles'
    ),
  },
  paragraph: {
    variants: transformStylesToVariants(
      {
        paragraph1: css``,
        paragraph2: css``,
        paragraph3: css``,
        paragraph4: css``,
        paragraph5: css``,
        paragraph1Uppercase: css``,
        paragraph2Uppercase: css``,
        paragraph3Uppercase: css``,
        paragraph4Uppercase: css``,
        paragraph5Uppercase: css``,
      },
      'textStyles'
    ),
  },
  button: {
    defaultStyle: defaultButtonStyle,
    variants: transformStylesToVariants(
      {
        primaryDark: defaultButtonStyle,
        primaryLight: defaultButtonStyle,
        primaryLavender: defaultButtonStyle,
        secondaryDark: defaultButtonStyle,
        secondaryLight: defaultButtonStyle,
        secondaryLavender: defaultButtonStyle,
        tertiaryDark: defaultButtonStyle,
        tertiaryLight: defaultButtonStyle,
      },
      'buttonStyle'
    ),
  },
  links: {
    variants: {
      navigationPrimary: css``,
      navigationSecondary: css``,
      textLinkPrimary: css``,
      textLinkSecondary: css``,
      textLinkTertiary: css``,
      tabLink: css``,
    },
  },
  checkbox: {
    defaultStyle: css`
      background-color: transparent;
    `,
  },
  collapsibleTrigger: {
    defaultStyle: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      line-height: ${32 / 12};
      padding: 8px 0;
      cursor: pointer;
      user-select: none;
    `,
  },
  modalDialog: {
    defaultStyle: css`
      background-color: ${colors.white};
    `,
  },
  input: {
    defaultStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 0px 15px 8px 0px;
      line-height: 21px;
      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputErrorStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 0px 15px 8px 0px;
      line-height: 21px;
      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
    `,
  },
  textField: {
    variants: textInputVariants,
  },
  inputBox: {
    defaultStyle: css`
      border-color: ${colors.default};
      border-style: none none solid;
      border-width: 0px 0px 2px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 0px;
      background: transparent none repeat scroll 0% 0%;
      padding: 0;
      &[data-has-focus] {
        padding: 0;
        border-width: 0px 0px 2px;
        border-style: none none solid;
        box-shadow: none;
        border-color: ${colors.active};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      }
    `,
    inputBoxErrorStyle: css`
      border-color: ${colors.error};
      border-style: none none solid;
      border-width: 0px 0px 2px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 0px;
      background: transparent none repeat scroll 0% 0%;
      padding: 0;
      &[data-has-focus] {
        padding: 0;
        border-width: 0px 0px 2px;
        border-style: none none solid;
        box-shadow: none;
        border-color: ${colors.active};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      }
    `,
  },
  labelInput: {
    defaultStyle: css`
      margin: 0;
      text-transform: uppercase;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
        line-height: 21px;
      }
      &[data-label-position='inside'] {
        font-size: 10px;
        color: ${colors.subdued};
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
      }
      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
      }
    `,
    labelInputErrorStyle: css`
      margin: 0;
      text-transform: uppercase;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
        line-height: 21px;
      }
      &[data-label-position='inside'] {
        font-size: 10px;
        color: ${colors.error};
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
      }
      &[data-label-position='floating'] {
        color: ${colors.error};
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
      }
    `,
  },
  hintInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.default};
    `,
  },
  errorInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.error};
    `,
  },

  // TODO: Once V2 is considered ok, we should replace it with the default one
  inputV2: {
    defaultStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 2px 15px 0 0;
      line-height: 21px;
      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputErrorStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 2px 15px 0 0;
      line-height: 21px;
      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
    `,
  },
  inputBoxV2: {
    defaultStyle: css`
      border-color: ${colors.gray300};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      background: transparent none repeat scroll 0% 0%;
      padding: 6px 16px 8px 16px;
      label::first-letter {
        text-transform: capitalize;
      }

      &[data-has-focus] {
        padding: 6px 16px 8px 16px;
        border-width: 1px;
        border-radius: 4px;
        border-color: ${colors.active};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      }
      [data-after-input],
      [data-before-input] {
        margin: 0;
      }
    `,

    inputBoxErrorStyle: css`
      border-color: ${colors.error};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      background: transparent none repeat scroll 0% 0%;
      padding: 6px 16px 8px 16px;
      label::first-letter {
        text-transform: capitalize;
      }

      &[data-has-focus] {
        padding: 6px 16px 8px 16px;
        border-color: ${colors.error};
        border-width: 1px;
        border-radius: 4px;
      }
      [data-after-input],
      [data-before-input] {
        margin: 0;
      }
    `,
    adyenStyle: css`
      border-color: ${props =>
        props.hasPaymentError ? colors.error : colors.gray300};
    `,
  },
  labelInputV2: {
    defaultStyle: css`
      background: transparent none repeat scroll 0% 0%;
      margin: 0;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
      }
      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${colors.subdued};
        transform: translate3d(0, 0, 0) scale(1);
        margin-left: 0px;
      }
      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
        line-height: 1;
        color: ${colors.gray500};
      }
    `,
    labelInputErrorStyle: css`
      background: transparent none repeat scroll 0% 0%;
      margin: 0;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
      }
      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${colors.error};
        transform: translate3d(0, 0, 0) scale(1);
        margin-left: 0;
      }
      &[data-label-position='floating'] {
        color: ${colors.error};
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
        line-height: 1;
      }
    `,
  },
  hintInputV2: {
    defaultStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.default};
    `,
  },
  errorInputV2: {
    defaultStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.error};
    `,
  },
  selectV2: {
    errorStyle: css`
      margin-bottom: 18px;
      select {
        border-color: ${colors.error};
      }
      ${Select.Label} {
        color: ${colors.error};
      }
    `,
    defaultStyle: css`
      select {
        padding: 22px 16px 8px 16px;
        border-color: ${colors.gray300};
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
      }

      svg {
        right: 16px;
        bottom: 0;
        top: calc(50% - 2px);
      }

      ${Select.Label} {
        position: absolute;
        top: 4px;
        left: ${props => props.theme.spacing.small}px;
        text-transform: none;
        font-size: 12px;
      }

      ${Select.Footer} {
        margin: 2px 0;
        min-height: ${props => props.theme.spacing.small}px;
        font-size: 12px;
      }

      ${Select.Error} {
        line-height: 16px;
      }
    `,
  },
  radio: {
    defaultStyle: css`
      opacity: ${props => (props.disabled ? 0.2 : 1)};
    `,
  },
  zIndex: {
    decrement: -10,
    base: 0,
    productImageCarousel: 1,
    productBrowserHeader: 1,
    signInModalClose: 2,
    navItems: 3,
    increment: 10,
    floatingBar: 11,
    toast: 102,
    borderfree: 20,
    newsletter: 30,
    flyout: 100,
    errorOverlay: 21,
  },
  nav: {
    desktopHeight: 107,
    tabletHeight: 60,
    mobileHeight: 50, // height for new top logo nav
    mobileSubNav: 40, // height for new added sub nav for mobile
  },
};
