import { getPartialTrackingData } from '../utils';

const accountSegmentEvents = ({ identifyUser, getContext }) => {
  const { TRACK_ACCOUNT_SEGMENT_EVENTS } = require('../../actions/account');
  const accountSegmentEvents = identifyUser((action, prevState, nextState) => {
    const { zipCode, profile, birthday } = action.payload;
    const { id: userId, email } = profile;
    const context = getContext();
    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'user_id',
      'membership_status',
    ]);

    return {
      userId: userId.toString(),
      traits: {
        ...additionalTrackingData,
        email,
        zip_code: zipCode ? zipCode.toString() : null,
        birthday: birthday || null,
      },
    };
  });

  return { [TRACK_ACCOUNT_SEGMENT_EVENTS]: accountSegmentEvents };
};

export default accountSegmentEvents;
