import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobile } from '../../styles';
import { Component as BurgerNav } from '../BurgerNav';
import { Container as NavMenuDesktop } from '../NavMenuDesktop';

const Wrapper = styled.div`
  height: 100%;
  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    flex: 0 0 100%;
    order: 3;
  }
`;

const TabletsContainer = styled.nav`
  width: 44px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: none;
  }
  ${mobile`
    display: none;
  `}
`;

function NavMenu({
  className,
  hideNavLinks,
  showAccountModal,
  pageRenderDate,
}) {
  // Use `pageRenderDate` so that the menu state automatically resets when
  // a new page of the same route is rendered, since the page would not
  // unmount in that case.

  // NavMenu will be showing burger nav on tablets, and links for NEW, BRAS, UNDIES... on desktop
  // on mobile devices, the burger button will be included in NavToolBarMobile in the sub nav.
  return (
    <Wrapper className={className} key={pageRenderDate}>
      <TabletsContainer className={className}>
        <BurgerNav showAccountModal={showAccountModal} />
      </TabletsContainer>
      {hideNavLinks ? null : <NavMenuDesktop />}
    </Wrapper>
  );
}

NavMenu.propTypes = {
  className: PropTypes.string,
  hideNavLinks: PropTypes.bool,
  showAccountModal: PropTypes.func,
  pageRenderDate: PropTypes.string,
};

export default NavMenu;
