import { handleActions } from 'redux-actions';

import {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCT_SET_SUCCESS,
  LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS,
  LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_SUCCESS,
} from '../actions/products';

const defaultState = {
  masterProductIdsByFilter: {
    // key - filter_name - QS package used to generate
    // value - { pages: {page (Int): {responseIds: [master_product_ids]}}, canPaginate: Bool }
    // ex: {all: {pages: { 1: {responseIds: [6513916,6514096,6515056,6515176,6515296...]}, canPaginate: true/false }}
  },
  byMasterProductId: {
    // key - master_product_id
    // value - the object of the master_product_id from
    // ex: master_product_id: { available_quantity_master: 840, average_review: 0, color: "MILK", ...etc}
  },
  aggregations: {
    // key - categoryId
    // value - the aggregation object
    // ex: categoryId: { colors: [], sizes: [], collections: [], ...etc }
  },
  addedToBasketCount: {
    // key - master_product_id
    // value - atbCount / the total count of a product that is in cart for all customers
    // ex: 12345: 50 (master_product_id : atbCount)
  },
};

const handleRequest = (state, action) => {
  const { storeKey } = action.meta;
  const pageNumber = action.meta.requestParams.page;
  const productsData = state.masterProductIdsByFilter[storeKey] || {
    pages: {},
  };
  const page = productsData.pages[pageNumber] || {};
  return {
    ...state,
    masterProductIdsByFilter: {
      ...state.masterProductIdsByFilter,
      [storeKey]: {
        ...productsData,
        pages: {
          ...productsData.pages,
          [pageNumber]: {
            responseIds: [],
            ...page,
            isLoading: !action.error,
          },
        },
      },
    },
  };
};

const mapAggregations = aggregationArr => {
  if (!aggregationArr) {
    return [];
  }

  const newFieldAggregation = aggregationArr.map(size => ({
    ...size,
    id: size.id || size.id === 0 ? parseInt(size.id, 10) : null,
  }));
  return newFieldAggregation;
};

const normalizedProductResponse = (state = defaultState, action) => {
  const { requestParams, storeKey, aggregationKey, fromCache, categorized } =
    action.meta;
  if (fromCache) {
    return state;
  }

  let byMasterProductId = {};
  const responseIds = [];
  let filteredProducts = {};
  let products = [];
  let canPaginate = false;
  const keyedAggregations = {};
  const aggregations = {};

  if (!action.error) {
    if (
      (requestParams.aggs || requestParams.aggregations) &&
      action.payload.aggregations
    ) {
      products = action.payload.products;
      aggregations.categories = action.payload.aggregations.categories.map(
        category => ({
          ...category,
          id: parseInt(category.id, 10),
        })
      );
      aggregations.collections = action.payload.aggregations.collections.map(
        collection => ({
          ...collection,
          id: parseInt(collection.id, 10),
        })
      );
      aggregations.colorTags = action.payload.aggregations.colorTags;
      aggregations.sizes = mapAggregations(action.payload.aggregations.sizes);
      aggregations.braSizes = mapAggregations(
        action.payload.aggregations.braSizes
      );
      aggregations.braletteSizes = mapAggregations(
        action.payload.aggregations.braletteSizes
      );
      aggregations.lingerieSizes = mapAggregations(
        action.payload.aggregations.lingerieSizes
      );
      aggregations.undieSizes = mapAggregations(
        action.payload.aggregations.undieSizes
      );
      keyedAggregations[aggregationKey] = aggregations;
    } else {
      if (categorized) {
        products = action.payload.products;
      } else {
        products = action.payload;
      }
    }
  }

  // loops over products received from server
  const productsLength = products && products.length;
  for (let i = 0; i < productsLength; i++) {
    const id = products[i].master_product_id;
    byMasterProductId = {
      ...byMasterProductId,
      [id]: { ...products[i] },
    };
    // TODO: REMOVE responseIds and corresponding state
    // use a selector to generate an array of ids
    responseIds.push(id);
  } // end products loop

  // check to see if we have a store of filters
  // grab current pages with that key and drops them in
  // update filtered products to have all the items in state
  if (!action.error && categorized) {
    canPaginate = action.payload.page < action.payload.pages;
  } else {
    canPaginate = !(products.length < requestParams.size);
  }

  filteredProducts = {
    [storeKey]: {
      pages: {
        ...state.masterProductIdsByFilter[storeKey].pages,
        [requestParams.page]: {
          responseIds,
          isLoading: false,
        },
      },
      canPaginate: canPaginate,
    },
  };

  return {
    ...state,
    byMasterProductId: { ...state.byMasterProductId, ...byMasterProductId },
    masterProductIdsByFilter: {
      ...state.masterProductIdsByFilter,
      ...filteredProducts,
    },
    aggregations: {
      ...state.aggregations,
      ...keyedAggregations,
    },
  };
};

export default handleActions(
  {
    // FIXME: What we actually should do intead is normalize the product store
    // and append or update incoming products from the API.
    [LOAD_PRODUCTS_REQUEST]: (state, action) => {
      return handleRequest(state, action);
    },
    [LOAD_PRODUCTS_SUCCESS]: (state, action) => {
      return normalizedProductResponse(state, action);
    },
    [LOAD_PRODUCTS_FAILURE]: (state, action) => {
      return normalizedProductResponse(state, action);
    },
    [LOAD_PRODUCT_SUCCESS]: (state, action) => {
      if (!action.payload || action.payload.error) {
        return state;
      }
      return {
        ...state,
        byMasterProductId: {
          ...state.byMasterProductId,
          [action.payload.master_product_id]: action.payload,
        },
      };
    },
    [LOAD_PRODUCT_SET_SUCCESS]: (state, action) => {
      if (!action.payload || action.payload.error) {
        return state;
      }
      return {
        ...state,
        byMasterProductId: {
          ...state.byMasterProductId,
          [action.payload.master_product_id]: action.payload,
        },
      };
    },
    [LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS]: (state, action) => {
      if (!action.payload || action.payload.error || action.meta.fromCache) {
        return state;
      }
      let recommendedProducts = action.payload.products;
      recommendedProducts = recommendedProducts.map(product => {
        // temporary hack. API doesn't properly transform recommended product data.
        if (Object.prototype.hasOwnProperty.call(product, 'masterProductId')) {
          product.tag_id_list = product.tagIdList ? product.tagIdList : [];
          product.master_product_id = product.masterProductId;
          product.item_number = product.itemNumber;
          product.default_unit_price = product.defaultUnitPrice;
          product.label = product.preoductLabel;
        }
        return product;
      });

      return {
        ...state,
        byMasterProductId: {
          ...state.byMasterProductId,
          [action.meta.masterProductId]: {
            ...state.byMasterProductId[action.meta.masterProductId],
            recommended: recommendedProducts,
          },
        },
      };
    },

    [LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_SUCCESS]: (state, action) => {
      if (!action.payload || action.payload.error) {
        return state;
      }

      return {
        ...state,

        addedToBasketCount: {
          ...state.addedToBasketCount,
          [action.meta.masterProductId]: action.payload.atbCount,
        },
      };
    },
  },

  defaultState
);
