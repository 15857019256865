import React from 'react';

import styled from 'styled-components';

import { defineMessages, useIntl } from '@techstyle/react-intl';

import { v2 } from '../../utils/themeVersioning';
import { Component as StaticIcon } from '../StaticIcon';

const messages = defineMessages({
  remove: {
    defaultMessage: 'Remove',
    id: 'global_checkout.remove',
  },
});

const Button = styled.button.attrs(({ type = 'button', ariaLabel }) => {
  return {
    type,
    'aria-label': ariaLabel,
  };
})`
  margin-top: -18px;
  margin-right: -18px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  ${v2`
    height: 18px;
    vertical-align: middle;
  `}
`;

const CloseIcon = styled(StaticIcon).attrs(props => {
  return {
    type: 'close',
    size: 8,
    color: props.theme.colors.subdued,
  };
})`
  stroke: ${props => props.theme.colors.subdued};
  stroke-width: 1.5px;
`;

function CartRemoveButton({ ...props }) {
  const intl = useIntl();

  return (
    <Button
      data-autotag="mini-cart-remove-item"
      ariaLabel={intl.formatMessage(messages.remove)}
      {...props}
    >
      <CloseIcon />
    </Button>
  );
}

export default CartRemoveButton;
