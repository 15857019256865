import React, { useState } from 'react';

import { useRouter } from 'next/router';
import Countdown from 'react-countdown-now';
import styled from 'styled-components';

import { useFeature } from '@techstyle/react-features';
import {
  FormattedMessage,
  useIntl,
  defineMessages,
} from '@techstyle/react-intl';
import { useDomain } from '@techstyle/redux-core';

import { desktop } from '../../styles';
import { Component as StaticIcon } from '../StaticIcon';

const COUNTDOWN_MESSAGES = defineMessages({
  day: {
    id: 'global_promo.promo_countdown_day',
    defaultMessage: '{num} day',
  },
  days: {
    id: 'global_promo.promo_countdown_days',
    defaultMessage: '{num} days',
  },
  hour: {
    id: 'global_promo.promo_countdown_hour',
    defaultMessage: '{num} hour',
  },
  hours: {
    id: 'global_promo.promo_countdown_hours',
    defaultMessage: '{num} hours',
  },
  minute: {
    id: 'global_promo.promo_countdown_minute',
    defaultMessage: '{num} minute',
  },
  minutes: {
    id: 'global_promo.promo_countdown_minutes',
    defaultMessage: '{num} minutes',
  },
  second: {
    id: 'global_promo.promo_countdown_second',
    defaultMessage: '{num} second',
  },
  seconds: {
    id: 'global_promo.promo_countdown_seconds',
    defaultMessage: '{num} seconds',
  },
});

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.promo};
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: none;

  ${desktop`
    display: inline-block;
  `}
`;

const PromoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const PromoCopyMessage = styled.span`
  padding: 0 28px;
  text-align: center;
`;

const BoldText = styled.strong``;
const CountdownText = styled(BoldText)``;

const PromoCountdownSkinnyBanner = () => {
  const promoCounterFeature = useFeature('promo_countdown');
  const { tld } = useDomain();
  const [shouldHide, setShouldHide] = useState(false);
  const { pathname } = useRouter();
  const intl = useIntl();

  const isInExcludedPaths =
    ['/account', '/membership', '/checkout'].some(path =>
      pathname?.includes(path)
    ) || pathname === '/';

  const isPromoCountdownEnabled =
    promoCounterFeature?.data?.enabled &&
    promoCounterFeature?.networkStatus?.isUpToDate &&
    !promoCounterFeature?.networkStatus?.isLoading;

  const countDownEndDate = isPromoCountdownEnabled
    ? promoCounterFeature.data.value[tld]?.countDownEndDate
    : '';

  if (shouldHide || !countDownEndDate || isInExcludedPaths) {
    return null;
  }

  const format = (num, suffix) => {
    if (num === 0) {
      return '';
    }

    const pluralizedSuffix = num > 1 ? `${suffix}s` : suffix;
    return intl.formatMessage(COUNTDOWN_MESSAGES[pluralizedSuffix], {
      num: num,
    });
  };

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }

    const parsedHours = Number(hours);
    const parsedMinutes = Number(minutes);
    const parsedSeconds = Number(seconds);

    return (
      <CountdownText>
        {[
          format(days, 'day'),
          format(parsedHours, 'hour'),
          format(parsedMinutes, 'minute'),
          format(parsedSeconds, 'second'),
        ]
          .filter(Boolean)
          .join(' ')}
      </CountdownText>
    );
  };

  const handleCompleted = () => setShouldHide(true);

  return (
    <Wrapper>
      <PromoWrapper>
        <IconWrapper>
          <StaticIcon type="fire" size="14" />
        </IconWrapper>
        <PromoCopyMessage>
          <FormattedMessage
            id="global_promo.promo_countdown_copy"
            defaultMessage="{promoText} ending in {countdown}"
            values={{
              promoText: (
                <BoldText>
                  <FormattedMessage
                    id="global_promo.promo_countdown_text"
                    defaultMessage="65% off sitewide"
                  />
                </BoldText>
              ),
              countdown: (
                <Countdown
                  date={countDownEndDate}
                  onComplete={handleCompleted}
                  renderer={renderCountdown}
                />
              ),
            }}
          />
        </PromoCopyMessage>
        <IconWrapper>
          <StaticIcon type="fire" size="14" />
        </IconWrapper>
      </PromoWrapper>
    </Wrapper>
  );
};

export default PromoCountdownSkinnyBanner;
