import buildEvents from '../buildEvents';

import accountSegmentEvents from './accountSegmentEvents';
import identifyQuizTraits from './identifyQuizTraits';
import identifyWithTraits from './identifyWithTraits';

const eventWrapper = methods =>
  buildEvents(
    [accountSegmentEvents, identifyQuizTraits, identifyWithTraits],
    methods
  );

export default eventWrapper;
