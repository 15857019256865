import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { isOneSize } from '../../utils/sizes';
import { v2 } from '../../utils/themeVersioning';
import ProductSizeLabel from '../ProductSizeLabel/ProductSizeLabel';

const giftId = config.get('public.productCategories.giftId');

const Wrapper = styled.span`
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

export default class BasketItemSize extends React.PureComponent {
  static propTypes = {
    isExchange: PropTypes.bool,
    product: PropTypes.object.isRequired,
  };

  render() {
    const { className, isExchange, product } = this.props;

    const isGift = product.defaultProductCategoryId === giftId;

    const sizeLabel = (
      <>
        <FormattedMessage
          id="global_checkout.size_label"
          defaultMessage="Size"
        />
        {': '}
      </>
    );

    return (
      <Wrapper className={className}>
        {!isGift && sizeLabel}
        {isExchange ? (
          product.exchangeSizeLabel
        ) : (
          <ProductSizeLabel product={product} isOneSize={isOneSize(product)} />
        )}
      </Wrapper>
    );
  }
}
