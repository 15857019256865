export const LOAD_RETAIL_STORES_REQUEST = 'LOAD_RETAIL_STORES_REQUEST';
export const LOAD_RETAIL_STORES_SUCCESS = 'LOAD_RETAIL_STORES_SUCCESS';
export const LOAD_RETAIL_STORES_FAILURE = 'LOAD_RETAIL_STORES_FAILURE';

export function loadRetailStores() {
  return {
    bentoApi: {
      endpoint: `retailstores`,
      method: 'GET',
      actions: [
        LOAD_RETAIL_STORES_REQUEST,
        LOAD_RETAIL_STORES_SUCCESS,
        LOAD_RETAIL_STORES_FAILURE,
      ],
    },
  };
}
