import { useEffect, useState } from 'react';

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import config from 'config';

import { useDomain } from '@techstyle/redux-core';

const keys = config.get('public.constructorio.keys');

const useCioClient = () => {
  const { tld } = useDomain();

  const [cioClient, setCioClient] = useState<ConstructorIOClient>(null);

  useEffect(() => {
    setCioClient(
      new ConstructorIOClient({
        apiKey: keys[tld],
      })
    );
  }, []);

  return cioClient;
};

export default useCioClient;
