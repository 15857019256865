import { useCallback } from 'react';

import config from 'config';
import { useSelector } from 'react-redux';
import { defaultMemoize } from 'reselect';

import { getDefaultProductFilters } from '../utils/selectors';

import { mapColorsToTags } from './colors';
import { stringifyQuery } from './url';

const productData = config.get('public.productBrowser');

const emptyFilters = {
  includeOutOfStock: true,
  aggs: false,
  collection: null,
  defaultFpls: null,
  section: null,
  subsection: null,
  sections: null,
  defaultProductCategoryIds: [],
  categoryTagIds: [],
  categoryIds: [],
  defaultCollectionId: null,
  excludeFpls: [],
  fpls: null,
  collectionIds: [],
  colorTagIds: [],
  fabricTagIds: [],
  optionSignatures: [],
  sort: null,
  warehouseId: null,
  defaultTagId: null,
  excludeTagIds: [],
  productTypeIds: [],
};

const getSectionData = (section, collection) =>
  (section && productData.sections[section]) ||
  (collection === 'features/vip-sets' && productData.sections.sets) ||
  null;

const compareCategories = (categoriesData, filterCategories) => {
  if (
    categoriesData &&
    filterCategories &&
    categoriesData.length === filterCategories.length &&
    categoriesData.every(categoryTagId =>
      filterCategories.includes(categoryTagId)
    )
  ) {
    return true;
  }
  return false;
};

export const routeToFilters = defaultMemoize(function (query) {
  const collectionData =
    (query.collection && productData.collections[query.collection]) || null;
  const sectionData = getSectionData(query.section, query.collection);
  const subsectionData =
    (sectionData && sectionData.subsections[query.subsection]) || null;
  const defaultSort =
    (collectionData && collectionData.defaultSort) || productData.defaultSort;

  if (query.section && !sectionData) {
    throw new Error(`Unknown section: ${query.section}`);
  }
  if (query.subsection && !subsectionData) {
    throw new Error(`Unknown subsection: ${query.subsection}`);
  }

  const colorTagIds = query.colors
    ? mapColorsToTags(query.colors)
    : query.colorTagIds;

  const fabricTagIds = query.fabrics
    ? mapColorsToTags(query.fabrics)
    : query.fabricTagIds;

  return {
    collection: query.collection || null,
    defaultFpls:
      (collectionData && collectionData.defaultFpls) ||
      query.defaultFpls ||
      null,
    section: query.section || null,
    subsection: query.subsection || null,
    sections:
      collectionData && collectionData.sections
        ? collectionData.sections.reduce((sections, section) => {
            sections[section] = true;
            return sections;
          }, {})
        : null,
    defaultCollectionId:
      (collectionData && collectionData.defaultCollectionId) || null,
    defaultProductCategoryIds:
      (sectionData && sectionData.defaultProductCategoryIds) ||
      (subsectionData && subsectionData.defaultProductCategoryIds) ||
      (query.defaultProductCategoryIds || '')
        .split(' ')
        .map(value => parseInt(value, 10))
        .filter(value => value),
    categoryTagIds:
      (sectionData && !subsectionData && sectionData.categoryTagIds) ||
      (subsectionData && subsectionData.categoryTagIds) ||
      (query.categoryTagIds || '')
        .split(' ')
        .map(value => parseInt(value, 10))
        .filter(value => value),
    categoryIds:
      (sectionData && !subsectionData && sectionData.categoryIds) || [],
    fpls: (subsectionData && subsectionData.fpls) || null,
    collectionIds: (query.collectionIds || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    colorTagIds: (colorTagIds || '').split(/[, ]/g).filter(value => value),
    fabricTagIds: (fabricTagIds || '').split(/[, ]/g).filter(value => value),
    optionSignatures: (query.optionSignatures || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    braSizes: (query.braSizes || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    braletteSizes: (query.braletteSizes || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    lingerieSizes: (query.lingerieSizes || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    undieSizes: (query.undieSizes || '')
      .split(' ')
      .map(value => parseInt(value, 10))
      .filter(value => value),
    sort:
      (subsectionData && subsectionData.fpls) ||
      (collectionData && collectionData.defaultFpls) ||
      query.defaultFpls
        ? 'fplAsc'
        : query.sort || defaultSort,
    defaultTagId: parseInt(query.defaultTagId, 10) || null,
    categorized: true,
  };
});

export const filtersToRouteQuery = defaultMemoize(function (
  filters,
  defaultSortOverride
) {
  // we want to compare the categoryTagIds from filters and from the default.js,
  // if all categoryTagIds are the same the we want to remove it from the query
  // so no ?categoryTagIds=... will be shown in the browser.

  const sectionData = getSectionData(filters.section, filters.collection);
  const subsectionData =
    (sectionData && sectionData.subsections[filters.subsection]) || null;
  let categoryTagIds = filters.categoryTagIds.join(' ');
  if (
    !categoryTagIds ||
    (sectionData &&
      !subsectionData &&
      compareCategories(sectionData.categoryTagIds, filters.categoryTagIds)) ||
    (subsectionData &&
      compareCategories(subsectionData.categoryTagIds, filters.categoryTagIds))
  ) {
    categoryTagIds = undefined;
  }

  const defaults = {
    section: filters.section || undefined,
    subsection: filters.subsection || undefined,
    defaultTagId: filters.defaultTagId || undefined,
    collectionIds: filters.collectionIds.join(' ') || undefined,
    colorTagIds: filters.colorTagIds.join(' ') || undefined,
    optionSignatures: filters.optionSignatures.join(' ') || undefined,
    braSizes: filters.braSizes.join(' ') || undefined,
    braletteSizes: filters.braletteSizes.join(' ') || undefined,
    lingerieSizes: filters.lingerieSizes.join(' ') || undefined,
    undieSizes: filters.undieSizes.join(' ') || undefined,
    fabricTagIds: filters.fabricTagIds.join(' ') || undefined,
    categoryTagIds,
  };

  if (filters.collection) {
    const collectionData =
      (filters.collection && productData.collections[filters.collection]) ||
      null;
    const defaultSort =
      (collectionData && collectionData.defaultSort) || productData.defaultSort;

    return {
      collection: filters.collection,
      sort: filters.sort === defaultSort ? undefined : filters.sort,
      ...defaults,
    };
  } else {
    return {
      defaultFpls: filters.defaultFpls || undefined,
      sort:
        filters.fpls || filters.defaultFpls
          ? undefined
          : filters.sort === productData.defaultSort
          ? undefined
          : filters.sort,
      ...defaults,
    };
  }
});

export const filtersToRoute = defaultMemoize(function (
  filters,
  pathname = '/products',
  additionalQuery = {}
) {
  const filterQuery = filtersToRouteQuery(filters);
  const query = { ...additionalQuery, ...filterQuery };

  const { collection, defaultFpls, section, subsection, ...asQuery } = query;

  let asPath = '/products';
  if (collection) {
    asPath = `/${collection}`;
  } else if (defaultFpls) {
    asPath += `/${defaultFpls}`;
  }
  if (section) {
    asPath += `/${section}`;
  }
  if (subsection) {
    asPath += `/${subsection}`;
  }
  const queryString = stringifyQuery(asQuery);

  if (queryString) {
    asPath += `?${queryString}`;
  }
  return { pathname, query, asPath };
});

export const filtersToRequestParams = defaultMemoize(function (filters) {
  filters = { ...emptyFilters, ...filters };
  const params = filters.categorized
    ? {
        aggregations: !!filters.aggs,
        includeOutOfStock: !!filters.includeOutOfStock,
      }
    : {
        aggs: !!filters.aggs,
        includeOutOfStock: !!filters.includeOutOfStock,
      };
  if (filters.page) {
    params.page = filters.page;
  }
  if (filters.size) {
    params.size = filters.size;
  }
  if (filters.defaultProductCategoryIds.length) {
    params.defaultProductCategoryIds = filters.defaultProductCategoryIds;
  }
  if (filters.categoryIds.length) {
    params.categoryIds = filters.categoryIds;
  }
  if (filters.categoryTagIds.length) {
    params.categoryTagIds = filters.categorized
      ? filters.categoryTagIds
      : filters.categoryTagIds.join(' ');
  }
  if (filters.defaultCollectionId) {
    params.defaultCollectionId = filters.defaultCollectionId;
  }
  if (filters.collectionIds.length) {
    params.collectionIds = filters.categorized
      ? filters.collectionIds
      : filters.collectionIds.join(' ');
  }
  if (filters.colorTagIds.length) {
    if (filters.categorized) {
      params.colorTagIds = filters.colorTagIds;
    } else {
      params.colorTagIds = filters.colorTagIds.join(' ');
    }
  }
  if (filters.fabricTagIds.length) {
    if (filters.categorized) {
      params.fabricTagIds = filters.fabricTagIds;
    } else {
      params.fabricTagIds = filters.fabricTagIds.join(' ');
    }
  }
  if (filters.excludeTagIds.length) {
    params.excludeTagIds = filters.categorized
      ? filters.excludeTagIds
      : filters.excludeTagIds.join(' ');
  }
  if (filters.productTypeIds.length) {
    params.productTypeIds = filters.categorized
      ? filters.productTypeIds
      : filters.productTypeIds.join(' ');
  }
  if (filters.optionSignatures.length) {
    params.optionSignatures = filters.categorized
      ? filters.optionSignatures
      : filters.optionSignatures.join(' ');
    params.includeOutOfStock = false;
  }
  if (filters.categorized) {
    if (filters.braSizes && filters.braSizes.length) {
      params.braSizes = filters.braSizes;
      params.includeOutOfStock = false;
    }
    if (filters.braletteSizes && filters.braletteSizes.length) {
      params.braletteSizes = filters.braletteSizes;
      params.includeOutOfStock = false;
    }
    if (filters.lingerieSizes && filters.lingerieSizes.length) {
      // Adding an empty string includes "one size" products in filtered results
      params.lingerieSizes = [...filters.lingerieSizes, ''];
      params.includeOutOfStock = false;
    }
    if (filters.undieSizes && filters.undieSizes.length) {
      params.undieSizes = filters.undieSizes;
      params.includeOutOfStock = false;
    }
    if (filters.masterProductIds && filters.masterProductIds.length) {
      params.masterProductIds = filters.masterProductIds;
    }
  }
  if (filters.sort) {
    params.sort = filters.sort;
  }
  // Intentional done after `sort` so we can override.
  if (filters.fpls) {
    params.fpls = filters.categorized ? [filters.fpls] : filters.fpls;
    // Allow the option to sort by 'Top Converters'
    if (filters.sort !== 'topconverters') {
      params.sort = 'fplAsc';
    }
  }
  if (filters.excludeFpls.length) {
    params.excludeFpls = filters.categorized
      ? filters.excludeFpls
      : filters.excludeFpls.join(' ');
  }
  if (filters.defaultTagId) {
    params.defaultTagId = filters.defaultTagId;
  }
  if (filters.warehouseId) {
    params.warehouseId = filters.warehouseId;
  }
  if (filters.dateExpectedMin) {
    params.dateExpectedMin = filters.dateExpectedMin;
  }
  if (filters.dateExpectedMax) {
    params.dateExpectedMax = filters.dateExpectedMax;
  }
  if (filters.availableQtyMin) {
    params.availableQtyMin = filters.availableQtyMin;
  }
  if (filters.groupBy) {
    params.groupBy = filters.groupBy;
  }
  if (filters.shouldUseSuggestedProductsAlgo) {
    params.shouldUseSuggestedProductsAlgo = true;
  }
  if (filters.isSet) {
    params.includeOutOfStock = true;
  }
  if (filters.includeSaleItems) {
    params.excludeFpls = [];
  }
  return params;
});

export function requestParamsToRequestKey(params) {
  const key = stringifyQuery(params);
  return key || 'all';
}

export function requestParamsToStoreKey(params) {
  const { page, size, aggs, aggregations, ...rest } = params;
  const key = stringifyQuery(rest);
  return key || 'all';
}

export function requestParamsToAggregationKey(params) {
  const { defaultCollectionId, defaultProductCategoryIds, fpls } = params;
  const key = stringifyQuery({
    defaultCollectionId,
    defaultProductCategoryIds,
    fpls,
  });
  return key || 'all';
}

export function getSelectedCategories(categories, filters) {
  const selected = categories.filter(category => {
    if (category.defaultProductCategoryIds.length > 0) {
      return (
        JSON.stringify(filters.defaultProductCategoryIds) ===
        JSON.stringify(category.defaultProductCategoryIds)
      );
    } else if (category.categoryIds) {
      return filters.categoryIds === category.categoryIds;
    } else if (category.categoryTagIds === filters.categoryTagIds) {
      return true;
    } else if (category.categoryTagIds) {
      const selectedCategoryIds = new Set(filters.categoryTagIds);
      return category.categoryTagIds.every(id => selectedCategoryIds.has(id));
    } else if (category.fpls) {
      return filters.fpls === category.fpls;
    }
    return false;
  });
  return new Set(selected);
}

function areFiltersEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// This is necessary because `useDefaultProductFilters` is used in multiple
// places with different inputs, busting its cache. Without this, the selector
// memoization doesn't work correctly and results in an infinite loop.
// See: https://react-redux.js.org/next/api/hooks#using-memoizing-selectors
export function useDefaultProductFilters(baseFilters, options) {
  const selector = useCallback(
    state => getDefaultProductFilters(state, baseFilters, options),
    [baseFilters, options]
  );
  return useSelector(selector, areFiltersEqual);
}
