export function parseAssetDate(dateString) {
  if (!dateString) {
    return null;
  }
  if (dateString.indexOf(',') === -1) {
    // If there's no comma, assume it's the new ISO8601 with timezone format.
    return Date.parse(dateString);
  }
  // Old format, hardcoded to receive Pacific Daylight Time date strings.
  return Date.parse(`${dateString} GMT-7`);
}

export function toStringSet(string, normalizeCase = false) {
  return new Set(
    (string || '')
      .toString()
      .split(',')
      .map(item => item.trim())
      .map(item => (normalizeCase ? item.toLowerCase() : item))
      .filter(item => item)
  );
}

// If any asset is rendered inside this component with no `filter` prop, this
// will be the default filter.
export function defaultFilter(parentFilter, state) {
  let {
    dmgCode: memberDmgCode,
    persona: memberPersona,
    isDowngradedMember,
    isVip,
    isBorderfreeCustomer,
    getTime,
  } = state;

  // Allow targeting non-DMG and non-persona having members specifically
  // with an explicit `none` value.
  if (!memberDmgCode) {
    memberDmgCode = 'none';
  }
  if (!memberPersona) {
    memberPersona = 'none';
  }

  return (asset, index, assets) => {
    // This default filter doesn't necessarily have to be the topmost parent. It
    // depends on how the developer ends up nesting this component multiple
    // times. They could supply a custom filter in the outermost provider and
    // no filter (to use default) inside that.
    if (!parentFilter(asset, index, assets)) {
      return false;
    }

    const now = getTime();

    const { startDatetime, endDatetime } = asset;
    const startDate = parseAssetDate(startDatetime);
    if (startDate && startDate > now) {
      return false;
    }
    const endDate = parseAssetDate(endDatetime);
    if (endDate && endDate <= now) {
      return false;
    }

    const { dmgCode: assetDmgCode, persona: assetPersona } =
      asset.options.customVars;

    const assetDmgCodes = toStringSet(assetDmgCode);
    const assetPersonas = toStringSet(assetPersona);

    if (assetDmgCodes.size && !assetDmgCodes.has(memberDmgCode)) {
      return false;
    }

    if (assetPersonas.size && !assetPersonas.has(memberPersona)) {
      return false;
    }

    if (
      toStringSet(asset.options.customVars.flags, true).has('vip') &&
      !isVip
    ) {
      return false;
    }

    if (
      toStringSet(asset.options.customVars.flags, true).has('nonvip') &&
      isVip
    ) {
      return false;
    }

    if (
      toStringSet(asset.options.customVars.flags, true).has('nonvip') &&
      isDowngradedMember
    ) {
      return false;
    }

    if (
      toStringSet(asset.options.customVars.flags, true).has('noborderfree') &&
      isBorderfreeCustomer
    ) {
      return false;
    }

    return true;
  };
}

export function globalFplsFilter(name) {
  return parentFilter => {
    return (asset, index, assets) => {
      if (!parentFilter(asset, index, assets)) {
        return false;
      }
      const section = toStringSet(asset.options.customVars.section);
      return section.has(name);
    };
  };
}
