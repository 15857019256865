import React from 'react';

import styled from 'styled-components';

import { AssetContainer } from '@techstyle/react-assets';

import { BasicModal } from '../../styles/modal';

const Wrapper = styled.div`
  height: 600px;
  max-height: 100vh;
  width: 600px;
  max-width: 100%;
  overflow-y: scroll;
  padding: 56px;
`;

const PrivacyPolicyModal = props => {
  return (
    <BasicModal titleId="privacy-modal" {...props}>
      <Wrapper id="privacy-policy-modal">
        <AssetContainer name="Splash_Page_Privacy_Policy" />
      </Wrapper>
    </BasicModal>
  );
};

export default PrivacyPolicyModal;
