import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { Component as HoverMenuItem } from '../HoverMenuItem';

const Wrapper = styled.div`
  ${props => (props.fullWidth ? '' : 'position: relative')};
`;

const DefaultItems = styled.ul`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  z-index: 2;
`;

const SubmenuWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
`;

const DefaultSubmenu = styled.div`
  transition-property: transform;
  transition-duration: ${props => props.transitionDuration}ms;

  &.menu-enter {
    transform: translate3d(0, -100%, 0);
  }

  &.menu-exit,
  &.menu-exit-done {
    display: none;
  }

  &.menu-enter-active {
    transform: translate3d(0, 0, 0);
  }
`;

function HoverMenu({
  children,
  className,
  fullWidth = false,
  Items,
  onClose,
  onOpen,
  Submenu,
  transitionDuration = 200,
}) {
  const [openIndex, setOpenIndex] = useState(null);
  const [submenuContent, setSubmenuContent] = useState(null);

  const handleLeave = useCallback(() => {
    if (onClose && openIndex != null && submenuContent) {
      onClose();
    }
    setOpenIndex(null);
  }, [onClose, openIndex, submenuContent]);

  const handleItemEnter = useCallback(
    ({ index, children }) => {
      if (onOpen && (openIndex == null || !submenuContent)) {
        onOpen();
      }
      setOpenIndex(index);
      setSubmenuContent(children);
    },
    [onOpen, openIndex, submenuContent]
  );

  const ItemsComponent = Items || DefaultItems;
  const SubmenuComponent = Submenu || DefaultSubmenu;

  return (
    <Wrapper
      className={className}
      onMouseLeave={handleLeave}
      fullWidth={fullWidth}
    >
      <ItemsComponent>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            onEnter: handleItemEnter,
            index,
            isActive: index === openIndex,
          })
        )}
      </ItemsComponent>
      <SubmenuWrapper>
        <CSSTransition
          in={!!(openIndex != null && submenuContent)}
          classNames="menu"
          timeout={transitionDuration}
          mountOnEnter
        >
          <SubmenuComponent transitionDuration={transitionDuration}>
            {submenuContent}
          </SubmenuComponent>
        </CSSTransition>
      </SubmenuWrapper>
    </Wrapper>
  );
}

HoverMenu.Item = HoverMenuItem;

HoverMenu.propTypes = {
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,

  transitionDuration: PropTypes.number,
};

export default HoverMenu;
