import { handleActions } from 'redux-actions';

import {
  LOAD_REVIEWABLE_PRODUCTS_SUCCESS,
  LOAD_REVIEWABLE_PRODUCTS_DETAILS_SUCCESS,
} from '../../actions/account';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [LOAD_REVIEWABLE_PRODUCTS_SUCCESS]: (state, action) => {
      return {
        ...state,
        list: action.payload,
      };
    },
    [LOAD_REVIEWABLE_PRODUCTS_DETAILS_SUCCESS]: (state, action) => {
      return {
        ...state,
        details: action.payload,
      };
    },
  },
  defaultState
);
