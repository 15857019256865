import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Component as StaticIcon } from '../StaticIcon';

const BackButton = styled.button.attrs(
  ({
    type = 'button',
    iconSize = 15,
    children = <StaticIcon type="leftArrow" size={iconSize} />,
    'aria-label': ariaLabel = 'Back',
  }) => ({ type, iconSize, children, 'aria-label': ariaLabel })
)`
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  border: 0;
  line-height: 56px;
  text-align: center;
  background: transparent;
  cursor: pointer;
`;

BackButton.propTypes = {
  /**
   * The size of the default icon to render (if you don't pass your own
   * `children`).
   */
  iconSize: PropTypes.number,
  /**
   * The content of the Back button, usually an icon. If none is provided,
   * the default Back icon is rendered.
   */
  children: PropTypes.node,
};

export default BackButton;
