export const LOAD_ORDERS_REQUEST = 'LOAD_ORDERS_REQUEST';
export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';
export const LOAD_ORDERS_FAILURE = 'LOAD_ORDERS_FAILURE';

export const LOAD_ORDER_REQUEST = 'LOAD_ORDER_REQUEST';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE';

export const LOAD_ORDER_IF_MONDIAL_RELAY_REQUEST =
  'LOAD_ORDER_IF_MONDIAL_RELAY_REQUEST';
export const LOAD_ORDER_IF_MONDIAL_RELAY_SUCCESS =
  'LOAD_ORDER_IF_MONDIAL_RELAY_SUCCESS';
export const LOAD_ORDER_IF_MONDIAL_RELAY_FAILURE =
  'LOAD_ORDER_IF_MONDIAL_RELAY_SUCCESS';

export const GENERATE_RMA_REQUEST = 'GENERATE_RMA_REQUEST';
export const GENERATE_RMA_SUCCESS = 'GENERATE_RMA_SUCCESS';
export const GENERATE_RMA_FAILURE = 'GENERATE_RMA_FAILURE';

export function loadOrders(pageIndex = 1, recordsPerPage = 5) {
  const params = {
    pageIndex,
    recordsPerPage,
  };

  const meta = {
    params,
    fromCache: false,
  };

  return async (dispatch, getState, context) => {
    // If we're currently requesting a page of orders from the API, we should simply return that request.
    // We don't want multiple requests going on at a time...

    const inflightOrderRequests = context.inflightOrderRequests || {};
    const inflightOrderPageRequest = inflightOrderRequests[params.pageIndex];

    if (inflightOrderPageRequest) {
      return inflightOrderPageRequest;
    }

    // Otherwise, build and dispatch the API request
    const orderRequest = dispatch({
      bentoApi: {
        endpoint: `accounts/me/orders`,
        method: 'GET',
        searchParams: params,
        actions: [
          { type: LOAD_ORDERS_REQUEST, meta },
          { type: LOAD_ORDERS_SUCCESS, meta },
          { type: LOAD_ORDERS_FAILURE, meta },
        ],
      },
    });

    inflightOrderRequests[params.pageIndex] = orderRequest;
    context.inflightOrderRequests = inflightOrderRequests;
    const result = await orderRequest;
    inflightOrderRequests[params.pageIndex] = null;
    return result;
  };
}

export function loadOrder(orderId) {
  return async (dispatch, getState) => {
    const { membership } = getState();

    return dispatch({
      bentoApi: {
        endpoint: `accounts/me/orders/${orderId}`,
        method: 'GET',
        actions: [
          { type: LOAD_ORDER_REQUEST },
          (payload, meta) => {
            if (payload.customerId !== membership.customerId) {
              // Temporary. See FIS-150
              const error = new Error('unauthorized');
              error.statusCode = 403;
              throw error;
            }

            return {
              type: LOAD_ORDER_SUCCESS,
              meta,
              payload,
            };
          },
          { type: LOAD_ORDER_FAILURE },
        ],
      },
    });
  };
}

export function loadOrderIfMondialRelay(orderId) {
  // Checks whether the order is a Mondial Relay or not
  return dispatch => {
    return dispatch({
      bentoApi: {
        endpoint: `accounts/me/orders/${orderId}?includeCustomerRetailPoint=true`,
        method: 'GET',
        actions: [
          LOAD_ORDER_IF_MONDIAL_RELAY_REQUEST,
          LOAD_ORDER_IF_MONDIAL_RELAY_SUCCESS,
          LOAD_ORDER_IF_MONDIAL_RELAY_FAILURE,
        ],
      },
    });
  };
}

export function generateRMA(params) {
  const meta = { params };
  return {
    bentoApi: {
      endpoint: `accounts/me/orders/${params.orderId}/rmas`,
      method: 'POST',
      body: JSON.stringify(params),
      actions: [
        { type: GENERATE_RMA_REQUEST, meta },
        { type: GENERATE_RMA_SUCCESS, meta },
        { type: GENERATE_RMA_FAILURE, meta },
      ],
    },
  };
}
