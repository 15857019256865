import React, { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import config from 'config';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { loadProfile } from '@techstyle/react-accounts';

import { LaunchDarklyIdentifier } from './src/components/LaunchDarklyIdentifier';
import {
  getLaunchDarklyContext,
  GlobalLDSymbol,
  useLaunchDarklyContextValue,
} from './src/utils/launchDarkly';

export const withLaunchDarklyProvider = App => {
  const getInitialProps = App.getInitialProps;

  function WithLDClient(props) {
    return <App {...props} />;
  }

  // Dynamically set up `ctx.ldClient` on the server and client.
  // This is necessary because `getInitialProps` runs on both server and client.
  if (getInitialProps) {
    // On the server, this will set up the server-side SDK.
    if (!process.browser) {
      const { getLaunchDarklyClient } = require('./src/lib/ld-server');
      WithLDClient.getInitialProps = async pageCtx => {
        const ctx = 'Component' in pageCtx ? pageCtx.ctx : pageCtx;
        const { store } = ctx;
        const ldClient = await getLaunchDarklyClient();
        await Promise.all([
          store.dispatch(loadProfile()),
          store.dispatch(loadProfile()),
        ]);
        ctx.ldClient = ldClient;

        const initialProps = await getInitialProps(pageCtx);
        return initialProps;
      };
    } else {
      // On the client, this will set up the client-side SDK.
      // This relies on the `LaunchDarklyIdentifier` component to set up the
      // globally available `window[GlobalLDSymbol]` variable.
      WithLDClient.getInitialProps = async pageCtx => {
        const ctx = 'Component' in pageCtx ? pageCtx.ctx : pageCtx;
        const ldClient = window[GlobalLDSymbol];
        if (ldClient) {
          ctx.ldClient = ldClient;
        }

        const initialProps = await getInitialProps(pageCtx);
        return initialProps;
      };
    }
  }

  return WithLDClient;
};

const LaunchDarklyProvider = ({ children, flags }) => {
  const ldContextValue = useLaunchDarklyContextValue();

  useEffect(() => {
    Sentry.setContext('launchDarklyContext', ldContextValue);
  }, [ldContextValue]);

  return (
    <LDProvider
      clientSideID={config.get('public.launchDarkly')?.clientKey}
      context={ldContextValue}
      options={{
        privateAttributes: ['email'],
      }}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      flags={flags}
    >
      {children}
    </LDProvider>
  );
};

LaunchDarklyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.object,
};

export default function launchDarklyExtension() {
  return {
    id: 'launchDarkly',
    app: {
      enhance(App) {
        return withLaunchDarklyProvider(App);
      },
      getInitialProps: {
        enhance(getInitialProps) {
          return async ({ Component, ctx }) => {
            let initialProps = await getInitialProps({ Component, ctx });

            // When running on the server, prefetch all flags for the current user
            // so that they are available on the client. This will ensure that the
            // client will not have to wait for the first request to LaunchDarkly
            // to complete before it can render the page.
            if (typeof window === 'undefined') {
              const { performance } = ctx.req;
              const startTime = process.hrtime();
              const entry = {
                type: 'next',
                name: 'prefetchLaunchDarklyFlags',
                requestStart: performance.getOffset(startTime),
              };
              performance.entries.push(entry);

              const fullLDContext = getLaunchDarklyContext(
                ctx.store.getState()
              );
              const { user, ...ldContextWithoutUser } = fullLDContext;
              // Remove the user from the context if they are anonymous since we won't have a key
              const ldContextToSend =
                'anonymous' in user ? ldContextWithoutUser : fullLDContext;

              const ldFlags = (
                await ctx.ldClient.allFlagsState(ldContextToSend)
              ).allValues();

              initialProps = {
                ...initialProps,
                ldFlags,
              };

              const endTime = process.hrtime();
              entry.duration = performance.getDuration(startTime, endTime);
            }

            return initialProps;
          };
        },
      },
      render(initialProps, children) {
        const { ldFlags } = initialProps;

        return (
          <LaunchDarklyProvider flags={ldFlags}>
            <LaunchDarklyIdentifier />
            {children}
          </LaunchDarklyProvider>
        );
      },
    },
  };
}
