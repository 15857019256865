import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { Paragraph } from '../../styles/paragraph';
import { Container as BasketItem } from '../BasketItem';
import { LearnMoreLink } from '../JoinVipAnnual/shared';
import { Component as StyleContext } from '../StyleContext';
import { Component as VIPLearnMoreModal } from '../VIPLearnMoreModal';

const membershipProductImage = config.get('public.membershipProductImage');

const Details = styled.div``;

const ItemData = styled(Paragraph).attrs({ size: 12 })`
  font-weight: normal;

  > a {
    color: ${props => props.theme.colors.active};
  }
`;

export default class VipAnnualItem extends React.PureComponent {
  static propTypes = {
    isFreeTrial: PropTypes.bool,
    item: PropTypes.object.isRequired,
    error: PropTypes.string,
    location: PropTypes.string,
    trackMembershipLearnMore: PropTypes.func,
  };

  static defaultProps = {
    isFreeTrial: false,
  };

  state = {
    showModal: false,
  };

  handleOpenModal = event => {
    const { location, trackMembershipLearnMore } = this.props;
    event.preventDefault();
    trackMembershipLearnMore(location);
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  renderDetails = () => {
    const { showModal } = this.state;

    return (
      <Details data-autotag="vip-membership-item">
        <ItemData>
          <LearnMoreLink onClick={this.handleOpenModal}>
            <FormattedMessage
              defaultMessage="Learn more"
              id="global_checkout.membership_learn_more_link"
            />
          </LearnMoreLink>
        </ItemData>
        <VIPLearnMoreModal
          blur
          isOpen={showModal}
          onExit={this.handleCloseModal}
        />
      </Details>
    );
  };

  renderError = () => {
    const { error } = this.props;
    switch (error) {
      case 'ERROR_BORDERFREE_MEMBERSHIP':
        return (
          <FormattedMessage
            defaultMessage="Sorry, this membership is not available in your country"
            id="global_checkout.cart_error_membership_unavailable_region"
          />
        );
      case 'ERROR_ONLYMEMBERSHIP':
        return (
          <FormattedMessage
            defaultMessage="Sorry, you can’t checkout with this membership without adding a product."
            id="global_checkout.cart_error_membership_only"
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { item, isFreeTrial } = this.props;

    const product =
      item.lineItems && item.lineItems.length === 1
        ? item
        : {
            ...item,
            lineItems: [item],
          };

    const productWithFreeTrial = {
      ...product,
      masterLabel: (
        <>
          {product.masterLabel || product.label}
          <ItemData>
            <FormattedMessage
              defaultMessage="30-Day Free Trial"
              id="global_checkout.free_trial_item_title"
            />
          </ItemData>
        </>
      ),
    };

    return (
      <StyleContext context="vipItem">
        <BasketItem
          isLastToRender
          product={isFreeTrial ? productWithFreeTrial : product}
          thumbnail={membershipProductImage.thumbnailURL}
          showFree={isFreeTrial}
          showPrice={isFreeTrial}
          showQuantitySelector={false}
          showQuantity={false}
          showStock={false}
          renderDetails={this.renderDetails}
          renderError={this.renderError}
          noLink
          {...this.props}
        />
      </StyleContext>
    );
  }
}
