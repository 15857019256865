export const TRACKING_UPDATE_LIST = 'TRACKING_UPDATE_LIST';
export const TRACKING_REMOVE_LIST = 'TRACKING_REMOVE_LIST';
export const TRACK_FILTER_REVIEWS = 'TRACK_FILTER_REVIEWS';
export const TRACK_PROMOTION_VIEWED = 'TRACK_PROMOTION_VIEWED';
export const TRACK_PROMOTION_CLICK = 'TRACK_PROMOTION_CLICK';
export const TRACK_BUTTON_CLICK = 'TRACK_BUTTON_CLICK';

export const updateTrackingList = (key, items) => {
  return {
    type: TRACKING_UPDATE_LIST,
    payload: {
      key,
      items,
    },
  };
};

export const removeTrackingList = key => {
  return {
    type: TRACKING_REMOVE_LIST,
    payload: { key },
  };
};

export const trackFilterReviews = (sortOption = null, filterBy = null) => {
  return {
    type: TRACK_FILTER_REVIEWS,
    payload: {
      filterBy,
      sortOption,
    },
  };
};

export const trackPromotionViewed = payload => {
  return {
    type: TRACK_PROMOTION_VIEWED,
    payload,
  };
};

export const trackPromotionClick = payload => {
  return {
    type: TRACK_PROMOTION_CLICK,
    payload,
  };
};

export const trackButtonClick = payload => {
  return {
    type: TRACK_BUTTON_CLICK,
    payload,
  };
};
