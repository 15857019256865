import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import {
  updateCustomerDetail,
  useCustomerDetail,
} from '@techstyle/react-accounts';

// This is a helper that gets & saves JSON data in a customer detail. Good
// if you have a customer_detail that requires storing multiple pieces of
// data.

function useJsonCustomerDetail(customerDetailName) {
  if (!customerDetailName) {
    throw new Error(
      'Please pass a customer detail name to useJsonCustomerDetail'
    );
  }

  const dispatch = useDispatch();
  const customerDetail = useCustomerDetail(customerDetailName);
  const isLoaded =
    customerDetail.networkStatus.isUpToDate &&
    !customerDetail.networkStatus.isLoading;

  const value = useMemo(() => {
    try {
      return JSON.parse(customerDetail.value || '{}');
    } catch (e) {
      // In case the JSON breaks in the customer_detail, let's have a fallback here
      // to prevent the browser from crashing.
      return {};
    }
  }, [customerDetail]);

  const updateJsonCustomerDetail = useCallback(
    (newSettings = {}) => {
      dispatch(
        updateCustomerDetail({
          name: customerDetailName,
          value: JSON.stringify({
            ...value,
            ...newSettings,
          }),
        })
      );
    },
    [customerDetailName, dispatch, value]
  );

  return useMemo(
    () => ({ isLoaded, updateJsonCustomerDetail, value }),
    [isLoaded, updateJsonCustomerDetail, value]
  );
}

export default useJsonCustomerDetail;
