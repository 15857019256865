import React, { useEffect, useMemo, useState } from 'react';

import { useMembership } from '@techstyle/react-accounts';
import { useFeature } from '@techstyle/react-features';
import { useRenderTime, useSession } from '@techstyle/redux-core';

import { parseDate } from '../../utils/dates';

const CountdownTimerContext = React.createContext({});

const oneHour = 60 * 60 * 1000;

function CountdownTimerProvider({ children }) {
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [shouldForceBadgeInvisible, setShouldForceBadgeInvisible] =
    useState(false);

  const getTime = useRenderTime();
  const {
    dateTimeAdded,
    isVip,
    networkStatus: { isUpToDate: isMembershipLoaded },
  } = useMembership();
  const memberJoinDate = parseDate(dateTimeAdded);
  const endDate = memberJoinDate.getTime() + oneHour;
  const { isLoggedIn } = useSession();

  // Leave the `useFeature` handle undefined if feature not needed (will
  // prevent the feature from loading & hitting the API unnecessarily)
  const shouldLoadFeature =
    isLoggedIn && isMembershipLoaded && !isVip && endDate > getTime();
  const { data: countdownTimerFeature } =
    useFeature(shouldLoadFeature ? 'countdown_timer' : undefined) || {};

  useEffect(() => {
    const isCountdownFeatureEnabled = !!(
      countdownTimerFeature &&
      countdownTimerFeature.enabled &&
      countdownTimerFeature.value
    );

    if (!isCountdownFeatureEnabled) {
      setIsCountdownActive(false);
      return;
    }

    if (isCountdownFeatureEnabled && !shouldForceBadgeInvisible) {
      const isCountdownActive = endDate > getTime();
      setIsCountdownActive(isCountdownActive);
    }
  }, [
    countdownTimerFeature,
    dateTimeAdded,
    endDate,
    getTime,
    setIsCountdownActive,
    shouldForceBadgeInvisible,
  ]);

  const value = useMemo(
    () => ({
      endDate,
      isCountdownActive,
      isHeaderVisible,
      shouldForceBadgeInvisible,
      setIsCountdownActive,
      setIsHeaderVisible,
      setShouldForceBadgeInvisible,
    }),
    [endDate, isCountdownActive, isHeaderVisible, shouldForceBadgeInvisible]
  );

  return (
    <CountdownTimerContext.Provider value={value}>
      {children}
    </CountdownTimerContext.Provider>
  );
}

function useCountdownTimer() {
  const context = React.useContext(CountdownTimerContext);
  if (context === undefined) {
    throw new Error(
      'useCountdownTimer must be used within a CountdownTimerProvider'
    );
  }
  return context;
}

export { CountdownTimerProvider, CountdownTimerContext, useCountdownTimer };
