const searchFilterTracking = (
  dispatch,
  {
    filterKeys,
    prevFilters,
    currentFilters,
    prevSort,
    currentSort,
    category,
    list,
  }
) => {
  const { trackProductListFilter } = require('../../actions/products');

  const delayFireList = [];
  if (Array.isArray(filterKeys)) {
    const { clear, add } = filterKeys.reduce(
      ({ clear, add }, filterKey) => {
        const prev = prevFilters[filterKey];
        const current = currentFilters[filterKey];

        if (Array.isArray(current) && Array.isArray(prev)) {
          for (
            let i = 0;
            // Gets the size of filters removed for this key
            i < prev.filter(filter => !current.includes(filter)).length;
            i++
          ) {
            clear.push({ type: filterKey, value: 'clear' });
          }

          const difference = current.filter(filter => !prev.includes(filter));

          // Only fires when new product added
          if (difference.length) {
            add.push(
              ...difference.map(filterValue => ({
                type: filterKey,
                value: filterValue,
              }))
            );
          }
        } else if (filterKey === 'defaultTagId') {
          // Special case for sets page
          if (current !== prev) {
            current && add.push({ type: filterKey, value: current });
            prev && clear.push({ type: filterKey, value: 'clear' });
          }
        }

        return { clear, add };
      },
      { clear: [], add: [] }
    );

    [add, clear].forEach(filterList => {
      filterList.length &&
        delayFireList.push(() =>
          dispatch(
            trackProductListFilter({
              list_id: list,
              category,
              filters: filterList,
            })
          )
        );
    });
  }

  const isSortDifferent = prevSort !== currentSort;

  if (isSortDifferent) {
    delayFireList.push(() =>
      dispatch(
        trackProductListFilter({
          list_id: list,
          category,
          sort: currentSort,
        })
      )
    );
  }

  delayFireList.forEach((fn, index) => setTimeout(fn, 250 * index));
};

export default searchFilterTracking;
