import {
  filtersToRequestParams,
  requestParamsToStoreKey,
  requestParamsToRequestKey,
  requestParamsToAggregationKey,
} from '../../utils/productBrowser';
import { getStateDependentProductFilters } from '../../utils/selectors';

export const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAILURE = 'LOAD_PRODUCTS_FAILURE';

export const LOAD_PRODUCT_REQUEST = 'LOAD_PRODUCT_REQUEST';
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS';
export const LOAD_PRODUCT_FAILURE = 'LOAD_PRODUCT_FAILURE';

export const LOAD_PRODUCT_SET_REQUEST = 'LOAD_PRODUCT_SET_REQUEST';
export const LOAD_PRODUCT_SET_SUCCESS = 'LOAD_PRODUCT_SET_SUCCESS';
export const LOAD_PRODUCT_SET_FAILURE = 'LOAD_PRODUCT_SET_FAILURE';

export const LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_REQUEST =
  'LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_REQUEST';
export const LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_SUCCESS =
  'LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_SUCCESS';
export const LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_FAILURE =
  'LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_FAILURE';

export const TRACK_PRODUCT_IMPRESSION = 'TRACK_PRODUCT_IMPRESSION';

export const TRACK_PRODUCT_LIST_FILTER = 'TRACK_PRODUCT_LIST_FILTER';

export const TRACK_PRODUCT_LIST_VIEWED = 'TRACK_PRODUCT_LIST_VIEWED';

export const TRACK_PRODUCT_MODEL_NO_CHANGE = 'TRACK_PRODUCT_MODEL_NO_CHANGE';
export const TRACK_PRODUCT_MODEL_CHANGE = 'TRACK_PRODUCT_MODEL_CHANGE';
export const TRACK_PDP_MODEL_THUMBNAILS_CLICK =
  'TTRACK_PDP_MODEL_THUMBNAILS_CLICK';

export const TRACK_MODEL_COLLAPSIBLE_ACTION = 'TRACK_MODEL_COLLAPSIBLE_ACTION';

export const TRACK_PRODUCT_SEARCH_RESULT = 'TRACK_PRODUCT_SEARCH_RESULT';

export const TRACK_PRODUCT_SEARCH = 'TRACK_PRODUCT_SEARCH';

export const TRACK_MEMBERSHIP_CTA = 'TRACK_MEMBERSHIP_CTA';

export const TRACK_PDP_CROSS_SELL_CLICK = 'TRACK_PDP_CROSS_SELL_CLICK';
export const TRACK_PDP_RADIO_TOGGLE = 'TRACK_PDP_RADIO_TOGGLE';

export const TRACK_PDP_SET_SUMMARY_OPEN = 'TRACK_PDP_SET_SUMMARY_OPEN';
export const TRACK_PDP_SET_SUMMARY_CLOSE = 'TRACK_PDP_SET_SUMMARY_CLOSE';

export const TRACK_PDP_SET_DETAILS_OPEN = 'TRACK_PDP_SET_DETAILS_OPEN';
export const TRACK_PDP_SET_DETAILS_CLOSE = 'TRACK_PDP_SET_DETAILS_CLOSE';

export const TRACK_PDP_SET_REVIEWS_OPEN = 'TRACK_PDP_SET_REVIEWS_OPEN';
export const TRACK_PDP_SET_REVIEWS_CLOSE = 'TRACK_PDP_SET_REVIEWS_CLOSE';

export const TRACK_CLICK_SIZE_SELECTION = 'TRACK_CLICK_SIZE_SELECTION';

export const TRACK_PAIR_IT_WITH_CLICK = 'TRACK_PAIR_IT_WITH_CLICK';

export const TRACK_COMPLETE_THE_LOOK_CLICK = 'TRACK_COMPLETE_THE_LOOK_CLICK';

export const TRACK_PDP_SET_REVIEWS_PAGINATION =
  'TRACK_PDP_SET_REVIEWS_PAGINATION';

export const TRACK_COLOR_CHANGE = 'TRACK_COLOR_CHANGE';
export const TRACK_PDP_VIEW = 'TRACK_PDP_VIEW';
export const TRACK_PDP_VIDEO_PLAYBACK_STARTED =
  'TRACK_PDP_VIDEO_PLAYBACK_STARTED';
export const TRACK_PDP_VIDEO_PLAYBACK_COMPLETED =
  'TRACK_PDP_VIDEO_PLAYBACK_COMPLETED';
export const TRACK_GRID_PRODUCT_CELL_CLICK = 'TRACK_GRID_PRODUCT_CELL_CLICK';

export const TRACK_SIZE_CHANGE = 'TRACK_SIZE_CHANGE';

export const TRACK_VIEWED_PDP_IMAGE = 'TRACK_VIEWED_PDP_IMAGE';

export const TRACK_GRID_RECOMMENDATIONS = 'TRACK_GRID_RECOMMENDATIONS';

export const LOAD_PRODUCT_RECOMMENDATIONS_REQUEST =
  'LOAD_PRODUCT_RECOMMENDATIONS_REQUEST';
export const LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS =
  'LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS';
export const LOAD_PRODUCT_RECOMMENDATIONS_FAILURE =
  'LOAD_PRODUCT_RECOMMENDATIONS_FAILURE';

export const TRACK_PRODUCT_CLICK = 'TRACK_PRODUCT_CLICK';
export const TRACK_MARKETING_CARD_CLICK = 'TRACK_MARKETING_CARD_CLICK';

const THRESHOLD = 4;

export function loadProducts(
  filters = {},
  aggregations = false,
  options = { shouldCategorize: false, isFromFavorites: false }
) {
  return async (dispatch, getState, context) => {
    const state = getState();
    const { borderfree } = state;
    const { shouldCategorize } = options;
    const { shouldUseSuggestedProductsAlgo } = filters;
    const isFavoritesContext = options.isFromFavorites;
    const requestFilters = getStateDependentProductFilters(state, {
      filters,
      applyUserSizes: false,
      isFavoritesContext,
    });
    const requestParams = filtersToRequestParams({
      categorized: shouldCategorize,
      page: 1,
      includeOutOfStock: true,
      aggs: !!aggregations,
      ...requestFilters,
      size: 28, // Force size.
      excludePreorderProducts: borderfree.isBorderfreeCustomer ? true : null,
    });
    const requestKey = requestParamsToRequestKey(requestParams);
    const storeKey = requestParamsToStoreKey(requestParams);
    const aggregationKey = requestParamsToAggregationKey(requestParams);
    const meta = {
      categorized: shouldCategorize,
      requestParams,
      storeKey,
      requestKey,
      aggregationKey,
      fromCache: false,
    };

    let inflightProductsRequests = context.inflightProductsRequests || {};
    let request = inflightProductsRequests[requestKey];
    if (request) {
      return request;
    }

    const params = {
      ...requestParams,
    };

    const bentoApi = {};
    bentoApi.actions = [
      {
        type: LOAD_PRODUCTS_REQUEST,
        meta,
      },
      {
        type: LOAD_PRODUCTS_SUCCESS,
        meta,
      },
      {
        type: LOAD_PRODUCTS_FAILURE,
        meta,
      },
    ];

    bentoApi.endpoint =
      (shouldCategorize && `products/categorized`) ||
      (shouldUseSuggestedProductsAlgo && `accounts/me/products/suggested`) ||
      `products`;
    bentoApi.method = shouldCategorize ? 'POST' : 'GET';
    if (shouldCategorize) {
      bentoApi.body = JSON.stringify(params);
    } else if (shouldUseSuggestedProductsAlgo) {
      bentoApi.searchParams = {
        size: params.size,
        page: params.page,
      };
    } else {
      bentoApi.searchParams = params;
    }

    request = dispatch({ bentoApi });

    inflightProductsRequests = context.inflightProductsRequests = {
      ...inflightProductsRequests,
      [requestKey]: request,
    };

    const result = await request;
    inflightProductsRequests[requestKey] = undefined;

    return result;
  };
}

export function loadProduct(id) {
  return async dispatch => {
    const searchParams = {
      combineLinkedProduct: true,
      includePromo: true,
      excludeSoldOutColors: true,
    };
    const result = await dispatch({
      bentoApi: {
        endpoint: `products/${id}`,
        searchParams,
        actions: [
          LOAD_PRODUCT_REQUEST,
          LOAD_PRODUCT_SUCCESS,
          LOAD_PRODUCT_FAILURE,
        ],
      },
    });

    return result;
  };
}

export function loadProductByPermalink(permalink, headers) {
  return async dispatch => {
    const result = await dispatch({
      bentoApi: {
        endpoint: `products/permalink/${permalink}`,
        headers,
        actions: [
          LOAD_PRODUCT_REQUEST,
          LOAD_PRODUCT_SUCCESS,
          LOAD_PRODUCT_FAILURE,
        ],
      },
    });

    return result;
  };
}

export function loadProductSet(id) {
  return async dispatch => {
    const searchParams = {
      combineLinkedProduct: true,
      includePromo: true,
      excludeSoldOutColors: true,
    };
    const result = await dispatch({
      bentoApi: {
        endpoint: `products/sets/${id}`,
        searchParams,
        actions: [
          LOAD_PRODUCT_SET_REQUEST,
          LOAD_PRODUCT_SET_SUCCESS,
          LOAD_PRODUCT_SET_FAILURE,
        ],
      },
    });

    return result;
  };
}

// create a function for api request
export function loadRecommendedProducts(
  masterProductId,
  searchParams = { size: 10, includePromos: true }
) {
  return async (dispatch, getState) => {
    const { products } = getState();
    if (
      products.byMasterProductId[masterProductId] &&
      products.byMasterProductId[masterProductId].recommended
    ) {
      return dispatch({
        type: LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS,
        meta: { masterProductId, fromCache: true },
        payload: {
          products: products.byMasterProductId[masterProductId].recommended,
        },
      });
    }
    return dispatch({
      bentoApi: {
        endpoint: `products/${masterProductId}/recommended`,
        searchParams,
        actions: [
          {
            type: LOAD_PRODUCT_RECOMMENDATIONS_REQUEST,
            meta: { masterProductId, fromCache: false },
          },
          {
            type: LOAD_PRODUCT_RECOMMENDATIONS_SUCCESS,
            meta: { masterProductId, fromCache: false },
          },
          {
            type: LOAD_PRODUCT_RECOMMENDATIONS_FAILURE,
            meta: { masterProductId, fromCache: false },
          },
        ],
      },
    });
  };
}

export function loadProductAddedToBasketCount(
  masterProductId,
  searchParams = { timeframe: 24 }
) {
  return async (dispatch, getState) => {
    if (masterProductId) {
      return dispatch({
        bentoApi: {
          endpoint: `products/${masterProductId}/metrics/atb-count`,
          method: 'GET',
          searchParams,
          actions: [
            {
              type: LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_REQUEST,
              meta: { masterProductId, fromCache: false },
            },
            {
              type: LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_SUCCESS,
              meta: { masterProductId, fromCache: false },
            },
            {
              type: LOAD_PRODUCT_ADDED_TO_BASKET_COUNT_FAILURE,
              meta: { masterProductId, fromCache: false },
            },
          ],
        },
      });
    }
  };
}

export function trackProductImpression({
  list,
  position,
  product,
  forceTrack = false,
  price,
  sessionId,
}) {
  return (dispatch, getState, context) => {
    context.trackProducts = context.trackProducts || [];
    const trackProduct = {
      list,
      position,
      product,
      price,
      sessionId,
    };

    context.trackProducts.push(trackProduct);

    if (forceTrack || context.trackProducts.length === THRESHOLD) {
      return dispatch({
        type: TRACK_PRODUCT_IMPRESSION,
        payload: context.trackProducts,
      });
    } else if (context.trackProducts.length > THRESHOLD) {
      // Reset array, but make sure to track the incoming product
      context.trackProducts = [trackProduct];
    }
  };
}

export function trackProductListViewed(payload) {
  return {
    type: TRACK_PRODUCT_LIST_VIEWED,
    payload,
  };
}

export function trackProductModelChange(model) {
  return {
    type: TRACK_PRODUCT_MODEL_CHANGE,
    model,
  };
}

export function trackProductModelNoChange() {
  return {
    type: TRACK_PRODUCT_MODEL_NO_CHANGE,
  };
}

export function trackPdpModelThumbnailsClick(model) {
  return {
    type: TRACK_PDP_MODEL_THUMBNAILS_CLICK,
    model,
  };
}

export function trackProductListFilter(payload) {
  return {
    type: TRACK_PRODUCT_LIST_FILTER,
    payload,
  };
}

export function trackModelCollapsibleAction(params) {
  return {
    type: TRACK_MODEL_COLLAPSIBLE_ACTION,
    payload: params,
  };
}

export function trackProductSearchResult(eventDetails) {
  return {
    type: TRACK_PRODUCT_SEARCH_RESULT,
    eventDetails,
  };
}

// to be used when acted upon a search query (even if not successful)
export function trackProductSearch(query) {
  return {
    type: TRACK_PRODUCT_SEARCH,
    query,
  };
}

export function trackMembershipCta(eventLabel) {
  return {
    type: TRACK_MEMBERSHIP_CTA,
    eventLabel,
  };
}

export function trackProductSetDetailsOpen() {
  return {
    type: TRACK_PDP_SET_DETAILS_OPEN,
  };
}

export function trackProductSetDetailsClose() {
  return {
    type: TRACK_PDP_SET_DETAILS_CLOSE,
  };
}

export function trackProductSetReviewsOpen() {
  return {
    type: TRACK_PDP_SET_REVIEWS_OPEN,
  };
}

export function trackProductSetReviewsClose() {
  return {
    type: TRACK_PDP_SET_REVIEWS_CLOSE,
  };
}

export function trackProductSetReviewsPagination(page) {
  return {
    type: TRACK_PDP_SET_REVIEWS_PAGINATION,
    page,
  };
}

export function trackPairItWithClick(eventLabel) {
  return {
    type: TRACK_PAIR_IT_WITH_CLICK,
    eventLabel,
  };
}

export function trackProductSetSummaryOpen() {
  return {
    type: TRACK_PDP_SET_SUMMARY_OPEN,
  };
}

export function trackProductSetSummaryClose() {
  return {
    type: TRACK_PDP_SET_SUMMARY_CLOSE,
  };
}

export function trackPdpRadioToggle(eventLabel) {
  return {
    type: TRACK_PDP_RADIO_TOGGLE,
    eventLabel,
  };
}

export function trackPdpCrossSellClick(eventLabel) {
  return {
    type: TRACK_PDP_CROSS_SELL_CLICK,
    eventLabel,
  };
}

export function trackGridRecommendations(eventLabel) {
  return {
    type: TRACK_GRID_RECOMMENDATIONS,
    eventLabel,
  };
}

export function trackClickSizeSelection(eventLabel) {
  return {
    type: TRACK_CLICK_SIZE_SELECTION,
    eventLabel,
  };
}

export function trackSizeChange(params) {
  return {
    type: TRACK_SIZE_CHANGE,
    payload: params,
  };
}

export function trackColorChange(params) {
  return {
    type: TRACK_COLOR_CHANGE,
    payload: params,
  };
}

export function trackViewedPdpImage(params) {
  return {
    type: TRACK_VIEWED_PDP_IMAGE,
    payload: params,
  };
}

export function trackPdpView(params) {
  return {
    type: TRACK_PDP_VIEW,
    payload: params,
  };
}

export function trackPdpVideoPlaybackStarted(params) {
  return {
    type: TRACK_PDP_VIDEO_PLAYBACK_STARTED,
    payload: params,
  };
}

export function trackPdpVideoPlaybackCompleted(params) {
  return {
    type: TRACK_PDP_VIDEO_PLAYBACK_COMPLETED,
    payload: params,
  };
}

export function trackGridProductCellClick(params) {
  return {
    type: TRACK_GRID_PRODUCT_CELL_CLICK,
    payload: params,
  };
}

export function trackProductClick(params) {
  return {
    type: TRACK_PRODUCT_CLICK,
    payload: params,
  };
}

export function trackMarketingCardClick(params) {
  return dispatch =>
    dispatch({
      type: TRACK_MARKETING_CARD_CLICK,
      payload: params,
    });
}
