import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { useTheme } from '@techstyle/react-components';
import { defineMessages, useIntl } from '@techstyle/react-intl';

import { updateCartLineItem } from '../../actions/checkout';
import { MembershipActionLocation } from '../../constants/checkout';
import { desktop } from '../../styles';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as XLogo } from '../XLogo';

export const Context = React.createContext();

function Provider({ children }) {
  const [cartLineItemsLoading, _setCartLineItemsLoading] = useState({});
  const setCartLineItemsLoading = useCallback((cartLineId, isLoading) => {
    _setCartLineItemsLoading(cartLineItemsLoading => ({
      ...cartLineItemsLoading,
      [cartLineId]: isLoading,
    }));
  }, []);
  const value = useMemo(
    () => ({
      cartLineItemsLoading,
      setCartLineItemsLoading,
    }),
    [cartLineItemsLoading, setCartLineItemsLoading]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const NO_CREDITS_REMAINING_TIMEOUT_MS = 3000;

const defaultMessages = defineMessages({
  applied: {
    id: 'global_checkout.member_credit_applied',
    defaultMessage: 'Member Credit Applied',
  },
  applying: {
    id: 'global_checkout.applying_member_credit',
    defaultMessage: 'Applying Member Credit...',
  },
  available: {
    id: 'global_checkout.apply_member_credit',
    defaultMessage: 'Apply Member Credit',
  },
  no_credits_remaining: {
    id: 'global_checkout.no_member_credits_remaining',
    defaultMessage: 'Remove a member credit from another item',
  },
  removing: {
    id: 'global_checkout.removing_member_credit',
    defaultMessage: 'Removing Member Credit...',
  },
  unavailable: {
    id: 'global_checkout.member_credits_unavailable',
    defaultMessage: 'No Member Credits Available',
  },
});

const toggleButtonLabels = defineMessages({
  apply: {
    id: 'global_checkout.apply_member_credits_toggle_button',
    defaultMessage: 'Apply member credits to product',
  },
  remove: {
    id: 'global_checkout.remove_member_credits_toggle_button',
    defaultMessage: 'Remove member credits from product',
  },
});

const MemberCreditStatus = {
  applied: 'applied',
  applying: 'applying',
  available: 'available',
  no_credits_remaining: 'no_credits_remaining',
  removing: 'removing',
  unavailable: 'unavailable',
};

const statusAutotags = {
  applied: 'credit_applied',
  applying: 'credit_applied',
  available: 'apply_credit',
  no_credits_remaining: 'no_credits_remaining',
  removing: 'apply_credit',
  unavailable: 'no_credits',
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.spacing.small}px 0 0;

  ${desktop`
    margin: ${props => props.theme.spacing.small}px 0 0 0;
    padding: 12px ${props => props.theme.spacing.tiny}px;
    background: ${props =>
      props.isMemberCreditApplied
        ? props.theme.colors.lavender100
        : props.theme.colors.gray150};
    border-radius: 4px;
    ${v2`
      background: ${({ theme }) => theme.colors.marble};
      border-radius: 0;
      padding: 12px;
    `}
  `}
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${v2`
    gap: 4px;
  `}
`;

const LogoWrapper = styled.div`
  background-color: ${props => props.theme.colors.promo};
  border-radius: 24px;
  height: 14px;
  margin-right: ${props => props.theme.spacing.tiny}px;
  margin-top: 1px;
  overflow: hidden;
  width: 21px;

  ${props =>
    (props.memberCreditStatus === MemberCreditStatus.applied ||
      props.memberCreditStatus === MemberCreditStatus.applying) &&
    `
        background-color: ${props.theme.colors.promo};
  `}

  ${props =>
    (props.memberCreditStatus === MemberCreditStatus.unavailable ||
      props.memberCreditStatus === MemberCreditStatus.no_credits_remaining) &&
    `
        background-color: ${props.theme.colors.subdued};
  `}
`;

const Logo = styled(XLogo)`
  ${v1`
    margin-top: -7px;
    margin-left: -6px;
    width: 33px;
  `}
`;

const Text = styled.span`
  font-size: 12px;

  ${props =>
    (props.memberCreditStatus === MemberCreditStatus.applied ||
      props.memberCreditStatus === MemberCreditStatus.applying) &&
    `
        color: ${props.theme.colors.promo};
  `}

  ${props =>
    props.memberCreditStatus === MemberCreditStatus.unavailable &&
    `
        color: ${props.theme.colors.subdued};
  `}

  ${props =>
    props.memberCreditStatus === MemberCreditStatus.no_credits_remaining &&
    `
        color: ${props.theme.colors.error};
  `}

  ${v2`
    ${({ theme }) =>
      `${theme.subHeader.variants.subHeading4Uppercase.textStyles}
       color: ${theme.colors.black};
    `}
  `}
`;

const ToggleButton = styled.button`
  width: 40px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  background: ${props => props.theme.colors.stone};
  ${v2`
    background: ${props => props.theme.colors.stone};
  `}

  ${props =>
    (props.memberCreditStatus === MemberCreditStatus.applied ||
      props.memberCreditStatus === MemberCreditStatus.applying) &&
    `
        background: ${
          props.theme.themeVersion === 'v1'
            ? props.theme.colors.promo
            : props.theme.colors.lavender
        };
  `}

  ${props =>
    (props.memberCreditStatus === MemberCreditStatus.unavailable ||
      props.memberCreditStatus === MemberCreditStatus.no_credits_remaining) &&
    `
        background: ${props.theme.colors.gray400};
        cursor: default;
  `}
`;

const Toggle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  margin: 1px;
  transition: 0.1s transform ease-in-out;
  transform: ${props =>
    props.isMemberCreditApplied ? 'translateX(75%)' : 'translateX(0%)'};
  box-shadow: -1px 1px 4px ${props => props.theme.colors.darkShadow1};
  background: ${props => props.theme.colors.white};
`;

function MemberCreditsCartToggle({
  item = {},
  location = MembershipActionLocation.SHOPPING_BAG,
}) {
  const { formatMessage } = useIntl();
  const { themeVersion } = useTheme();
  const dispatch = useDispatch();

  const { availableTokenQuantity, isDowngraded } = useMembership();
  const { tokenQuantity: creditsApplied } = useSelector(
    state => state.checkout
  );
  const creditsRemaining = availableTokenQuantity - creditsApplied;
  const hasEnoughCredits = creditsRemaining >= item.tokenRedemptionQty;
  const isCreditApplied = item.tokenQtyApplied === item.tokenRedemptionQty;

  const [memberCreditStatus, setMemberCreditStatus] = useState(
    MemberCreditStatus.available
  );
  const isMemberCreditApplied =
    memberCreditStatus === MemberCreditStatus.applied ||
    memberCreditStatus === MemberCreditStatus.applying;

  const [shouldDisplayNoCreditsRemaining, setShouldDisplayNoCreditsRemaining] =
    useState(false);
  const shouldDisplayNoCreditsRemainingTimeout = useRef();

  const context = useContext(Context);
  const { setCartLineItemsLoading } = context || {};

  const handleToggle = async () => {
    const currentMemberCreditStatus = memberCreditStatus;

    if (
      memberCreditStatus === MemberCreditStatus.unavailable ||
      memberCreditStatus === MemberCreditStatus.no_credits_remaining
    ) {
      clearTimeout(shouldDisplayNoCreditsRemainingTimeout.current);
      shouldDisplayNoCreditsRemainingTimeout.current = setTimeout(
        () => setShouldDisplayNoCreditsRemaining(false),
        NO_CREDITS_REMAINING_TIMEOUT_MS
      );
      return setShouldDisplayNoCreditsRemaining(true);
    }

    // We can't cancel in-flight API requests so we ignore clicks while applying/removing
    if (
      memberCreditStatus === MemberCreditStatus.applying ||
      memberCreditStatus === MemberCreditStatus.removing
    ) {
      return;
    }

    setMemberCreditStatus(
      memberCreditStatus === MemberCreditStatus.applied
        ? MemberCreditStatus.removing
        : MemberCreditStatus.applying
    );

    setCartLineItemsLoading(item.cartLineId, true);

    try {
      await dispatch(
        updateCartLineItem(item.cartLineId, location, {
          tokenQuantity: isCreditApplied ? 0 : item.tokenRedemptionQty,
        })
      );
    } catch (e) {
      setMemberCreditStatus(currentMemberCreditStatus);
    }

    setCartLineItemsLoading(item.cartLineId, false);
  };

  useEffect(() => {
    const isUnavailable = creditsRemaining === 0;
    const hasNoCreditsRemaining =
      !hasEnoughCredits && shouldDisplayNoCreditsRemaining;

    let newMemberCreditStatus = MemberCreditStatus.available;

    if (isUnavailable) {
      newMemberCreditStatus = MemberCreditStatus.unavailable;
    }
    if (hasNoCreditsRemaining) {
      newMemberCreditStatus = MemberCreditStatus.no_credits_remaining;
    }
    if (isCreditApplied) {
      newMemberCreditStatus = MemberCreditStatus.applied;
    }

    setMemberCreditStatus(newMemberCreditStatus);
  }, [
    creditsRemaining,
    hasEnoughCredits,
    isCreditApplied,
    shouldDisplayNoCreditsRemaining,
  ]);

  const statusText = formatMessage(defaultMessages[memberCreditStatus]);

  // If item costs zero tokens, or user has zero takens available, or we're
  // not in an ECHO cart, or user is downgraded with no tokens left, then exit.
  const hasTokenRedemptionQty = item.tokenRedemptionQty > 0;
  const hasAvailableTokenQuantity = !!availableTokenQuantity;
  const isEchoCart = !!item.cartLineId;
  const isDowngradedWithNoCredits = isDowngraded && !hasAvailableTokenQuantity;
  if (
    !hasTokenRedemptionQty ||
    !hasAvailableTokenQuantity ||
    !isEchoCart ||
    isDowngradedWithNoCredits
  ) {
    return null;
  }

  // If no Context provider, throw a helpful error
  if (!context) {
    throw new Error(
      '<MemberCreditsCartToggle /> must be wrapped in <MemberCreditsCartToggle.Provider />'
    );
  }

  return (
    <Container isMemberCreditApplied={isMemberCreditApplied}>
      <StatusContainer>
        {themeVersion === 'v2' ? (
          <Logo v2 />
        ) : (
          <LogoWrapper memberCreditStatus={memberCreditStatus}>
            <Logo />
          </LogoWrapper>
        )}

        <Text
          data-autotag={statusAutotags[memberCreditStatus]}
          memberCreditStatus={memberCreditStatus}
        >
          {statusText}
        </Text>
      </StatusContainer>
      <ToggleButton
        aria-label={formatMessage(
          isMemberCreditApplied
            ? toggleButtonLabels.remove
            : toggleButtonLabels.apply
        )}
        data-autotag={
          isMemberCreditApplied ? 'credit_toggle_off' : 'credit_toggle_on'
        }
        memberCreditStatus={memberCreditStatus}
        onClick={handleToggle}
      >
        <Toggle isMemberCreditApplied={isMemberCreditApplied} />
      </ToggleButton>
    </Container>
  );
}

MemberCreditsCartToggle.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.string,
};

MemberCreditsCartToggle.Context = Context;
MemberCreditsCartToggle.Provider = Provider;

export default MemberCreditsCartToggle;
