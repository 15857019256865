export const GET_CMS_DATA_REQUEST = 'GET_CMS_DATA_REQUEST';
export const GET_CMS_DATA_SUCCESS = 'GET_CMS_DATA_SUCCESS';
export const GET_CMS_DATA_FAILURE = 'GET_CMS_DATA_FAILURE';

export function getCmsData(collection, searchParams = null) {
  return {
    bentoApi: {
      endpoint: `cms/${collection}`,
      method: 'GET',
      searchParams: searchParams,
      actions: [
        {
          type: GET_CMS_DATA_REQUEST,
          meta: { collection },
        },
        {
          type: GET_CMS_DATA_SUCCESS,
          meta: { collection },
        },
        {
          type: GET_CMS_DATA_FAILURE,
          meta: { collection },
        },
      ],
    },
  };
}
