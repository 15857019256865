import { useEffect } from 'react';

import { useMembership } from '@techstyle/react-accounts';
import { useSession } from '@techstyle/redux-core';

export default function DataLayer() {
  const { isLoggedIn } = useSession();
  const { espId } = useMembership();

  useEffect(() => {
    if (isLoggedIn && window.dataLayer && espId) {
      window.dataLayer.push({
        esp_id: espId,
      });
    }
  }, [isLoggedIn, espId]);

  return null;
}
