import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { Paragraph } from '../../styles/paragraph';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as ShopNowLink } from '../ShopNowLink';

const Wrapper = styled.div`
  margin: auto;
  text-align: center;

  > a {
    ${v1`font-weight: bold;`}
    ${v2`${props => props.theme.links.variants.textLinkSecondary}`}
    color: ${props => props.theme.colors.default};
  }
`;

const EmptyCopy = styled(Paragraph).attrs({ size: '18px' })`
  margin-bottom: 8px;
  ${v1`font-weight: bold;`}
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph2Uppercase.textStyles}
  `}
`;

type EmptyCartMessageProps = {
  className: string;
};
const EmptyCartMessage = ({ className }: EmptyCartMessageProps) => {
  return (
    <Wrapper className={className}>
      <EmptyCopy data-autotag="mini-cart-empty-message">
        <FormattedMessage
          id="global_checkout.shopping_bag_empty"
          defaultMessage="EMPTY :("
        />
      </EmptyCopy>
      <ShopNowLink />
    </Wrapper>
  );
};

export default EmptyCartMessage;
