import { handleActions } from 'redux-actions';

import {
  TRACKING_UPDATE_LIST,
  TRACKING_REMOVE_LIST,
} from '../actions/tracking';

const defaultState = {};

export default handleActions(
  {
    [TRACKING_UPDATE_LIST]: (state, action) => {
      const { key, items } = action.payload;
      return {
        ...state,
        [key]: items,
      };
    },
    [TRACKING_REMOVE_LIST]: (state, action) => {
      const { key } = action.payload;

      delete state[key];

      return state;
    },
  },
  defaultState
);
