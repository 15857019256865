import React, { useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { useCustomer, useMembership } from '@techstyle/react-accounts';
import { HTMLAsset, useAsset } from '@techstyle/react-assets';
import {
  FormattedDate,
  FormattedMessage,
  useIntl,
} from '@techstyle/react-intl';
import { useSession } from '@techstyle/redux-core';

import {
  trackEmpInfoDrawerCtas,
  trackEmpInfoDrawerDiscoverPerksOpen,
} from '../../actions/membership';
import { mobile, desktop } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { getBirthdayDetails } from '../../utils/selectors';
import useBirthdayPromo from '../../utils/useBirthdayPromo';
import useJsonCustomerDetail from '../../utils/useJsonCustomerDetail';
import { Component as CloseButton } from '../CloseButton';
import { Component as MemberCreditsDrawerModalPerks } from '../MemberCreditsDrawerModalPerks';
import { Component as MemberCreditsDrawerModalProvider } from '../MemberCreditsDrawerModalProvider';
import { Component as QuantityBadge } from '../QuantityBadge';
import { Component as StaticIcon } from '../StaticIcon';
import ToggleTabs from '../ToggleTabs/ToggleTabs';
import { Component as XtraVipIcon } from '../XtraVipIcon';

const WELCOME_BADGE_FADE_OUT_DURATION = 300;

const dialogStyle = css`
  width: 100%;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -2px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
  position: absolute;
  bottom: 0;

  ${desktop`
    width: 500px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
    position: relative;
  `}
`;

const StyledModal = styled(BasicModal).attrs({
  springConfig: {
    tension: 230,
  },
  dialogStyle,
  transitionFrom: {
    opacity: 0,
    transform: 'translate3d(0, 160px, 0)',
  },
  transitionEnter: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
  },
  transitionLeave: {
    opacity: 0,
    transform: 'translate3d(0, 160px, 0)',
  },
})`
  ${mobile`
    ${BasicModal.Wrapper} {
      display: flex;
      align-items: flex-end;
    }
  `}
`;
const ModalCloseButton = styled(CloseButton)`
  line-height: 50px;
  width: 50px;
  height: 50px;
`;

const ModalContentContainer = styled.div`
  height: 430px;
  background: ${props => props.theme.colors.lavender100};
`;

const AccountInformationContainer = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 6px ${props => props.theme.colors.lightShadow1};
  z-index: ${props => props.theme.zIndex.increment};
  padding: ${props => props.theme.spacing.small}px;
  border-radius: 4px;
  margin: ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.moderate}px;
`;

const AccountInformationHeader = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${props => props.theme.colors.default};
  margin: 0 0 ${props => props.theme.spacing.tiny}px;
`;

const AccountInformationDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.spacing.small}px;
  &:first-of-type {
    padding-bottom: 4px;
    border-bottom: 1px solid ${props => props.theme.colors.lavender250};
    margin-top: 0;
  }
`;

const welcomeAnimationEnter = keyframes`
  0% {
    margin-bottom: -76px;
  }
  20% {
    margin-bottom: -84px;
  }
  70% {
    margin-bottom: 16px;
    margin-top: 14px;
  }
  100% {
    opacity: 1;
    margin-bottom: 8px;
  }
`;

const welcomeAnimationExit = keyframes`
  0% {
    position: absolute;
    top: 53px;
    right: 0;
    left: 0;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    position: absolute;
    top: -18px;
    right: -140px;
    left: 0;
    transform: scale(0);
    opacity: 0;
  }
`;

const welcomeWrapperAnimationExit = keyframes`
  100% {
    margin-bottom: -76px;
  }
`;

const WelcomeWrapper = styled.div`
  min-height: 74px;
  width: 100%;
  opacity: 0;
  margin-bottom: -76px;
  animation: ${welcomeAnimationEnter} 0.2s linear 0.5s normal forwards,
    ${welcomeWrapperAnimationExit} 0.2s linear 5s normal forwards;
`;

const WelcomeContainer = styled(AccountInformationContainer)`
  display: flex;
  place-items: center;
  background: ${props => props.theme.colors.lavenderGradient};
  color: ${props => props.theme.colors.white};
  z-index: ${props => props.theme.zIndex.increment};
  margin-bottom: 0;
  animation: ${welcomeAnimationExit} 0.2s linear 5s normal forwards;
`;

const WelcomeTextWrapper = styled.div`
  flex: 1;
`;

const BirthdayTextWrapper = styled.div`
  flex: 1;
  --emp-notification-subheader-font-size: 12px;
`;

const WelcomeHeader = styled.h3`
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 700;
`;

const WelcomeSubheader = styled.p`
  font-size: var(--emp-notification-subheader-font-size, 14px);
  letter-spacing: 0.3px;
  font-weight: 400;
`;

const welcomeNotificationBadgeAnimation = keyframes`
  0% {
    visibility: visible;
  }
  100% {
    transform: translateY(0) translateX(0);
    visibility: visible;
  }
`;

const WelcomeNotificationBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.lavenderGradient};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 4px;
  ${props =>
    !props.hasViewedWelcomeAnimation &&
    css`
      transform: translateY(11px) translateX(6px);
      visibility: hidden;
      animation: ${welcomeNotificationBadgeAnimation} 0.05s linear 5.15s normal
        forwards;
    `}
  transition: ${WELCOME_BADGE_FADE_OUT_DURATION}ms opacity linear;

  &::after {
    content: '';
    background: ${props => props.theme.colors.white};
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  ${props =>
    props.state === 'exiting' &&
    `
      opacity: 0;
  `}
`;

const InstructionsContainer = styled.div`
  text-align: center;
  margin: 0 ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.small}px;
`;

const InstructionsHeader = styled.h3`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  color: ${props => props.theme.colors.default};
  margin: 0 0 4px;
`;

const InstructionsSubheader = styled.h4`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.subdued};
  letter-spacing: 0.3px;
  font-weight: 300;
  margin: 0;
`;

const AccountInformationDetail = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.subdued};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ViewAccountLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.active};
`;

const MemberCreditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.promo};
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.3px;
  text-transform: none;
  margin-top: 3px;
`;

const MemberCreditBadge = styled(QuantityBadge)`
  display: inline-block;
  position: static;
  margin-right: 3px;
  margin-top: -2px;
  font-size: 14px;
  min-width: 22px;
  height: 22px;
  line-height: 18px;
`;

const CTAsContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing.tiny}px;
  grid-template-columns: 1fr 1fr;
  margin: 0 ${props => props.theme.spacing.small}px;
`;

const CTAWrapper = styled.a`
  display: flex;
  align-items: center;
  order: ${props => props.order || 0};
  position: relative;
  padding: 0 12px;
  text-decoration: none;
  border-radius: 4px;
  height: 69px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.3px;
  background: ${props => props.theme.colors.lavender250};
  color: ${props => props.theme.colors.default};

  ${props =>
    props.hasCredits &&
    `
    background: linear-gradient(
      ${props.gradientColorDegrees || 90}deg,
      ${props.gradientColor1} 0%,
      ${props.gradientColor2} 100%
    );
    ${props.bgColor && `background: ${props.bgColor};`}
    box-shadow: 0 1px 3px ${props.theme.colors.darkShadow1};
    font-weight: 700;
    text-shadow: 0px 2px 4px ${props.theme.colors.darkShadow1};
    color: ${props.theme.colors.white};
    padding: 0 ${props => props.theme.spacing.tiny}px;
  `}

  ${props =>
    props.isSoldOut &&
    props.hasCredits &&
    `
    color: ${props.theme.colors.whiteSubdued};
  `}
`;

const CTASoldOutBadge = styled.div`
  position: absolute;
  right: 0;
  padding: 3px ${props => props.theme.spacing.tiny}px;
  background: ${props => props.theme.colors.default};
  color: white;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const CTAText = styled.div`
  width: 100px;
  color: inherit;
`;

const WineGlasses = styled(StaticIcon).attrs({
  type: 'wineGlasses',
  size: 30,
})`
  margin-right: ${props => props.theme.spacing.small}px;
`;

const TabNames = {
  MY_XTRA_VIP: 'my xtra vip',
  DISCOVER_PERKS: 'discover perks',
};

function MemberCreditsDrawerModal() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const {
    isLoaded: isCustomerDetailLoaded,
    updateJsonCustomerDetail,
    value: customerDetailValue,
  } = useJsonCustomerDetail('emp_welcome');
  const {
    hasViewedWelcomeAnimation,
    hasViewedPerks,
    previousSessionId,
    previousBirthdayPerksSessionId,
  } = customerDetailValue;
  const isWelcomeAnimationReady = isCustomerDetailLoaded;
  const { sessionId } = useSession();
  const isNewSession = previousSessionId !== sessionId;

  const { isModalOpen, setIsModalOpen } = useContext(
    MemberCreditsDrawerModalProvider.Context
  );

  const { firstName } = useCustomer();
  const { availableTokenQuantity, dateActivated, isVip } = useMembership();
  const hasCredits = !!availableTokenQuantity;

  const { isBirthday, isBirthMonth } = useSelector(getBirthdayDetails);
  const hasViewedBirthdayPerks = isBirthday
    ? sessionId === previousBirthdayPerksSessionId
    : true;
  const hasViewedBirthdayAnimation = sessionId === previousSessionId;

  const { isBirthdayPromoReady, hasUsedBirthdayPromo, discountPercent } =
    useBirthdayPromo();
  const shouldShowBirthdayBanner =
    isBirthdayPromoReady &&
    isBirthMonth &&
    isNewSession &&
    !hasUsedBirthdayPromo &&
    discountPercent;

  const shouldShowNavBadge =
    (!hasViewedPerks || !hasViewedBirthdayPerks) && isCustomerDetailLoaded;
  const shouldAnimateNavBadge = isBirthday
    ? hasViewedBirthdayAnimation
    : hasViewedWelcomeAnimation;

  const ctaAssetName = hasCredits
    ? 'emp_info_drawer_ctas'
    : 'emp_info_drawer_ctas_no_credits';
  const ctaAssetData = useAsset(ctaAssetName, { multi: true });
  const ctaAssets =
    (ctaAssetData && ctaAssetData.data && ctaAssetData.data.assets) || [];

  const tabs = [
    {
      name: TabNames.MY_XTRA_VIP,
      tabTitle: (
        <FormattedMessage
          id="site_navigation.emp_xtra_vip_nav"
          defaultMessage="My Xtra Vip"
        />
      ),
      automationTag: 'myXtraVipBtn',
    },
    {
      name: TabNames.DISCOVER_PERKS,
      tabTitle: (
        <>
          <FormattedMessage
            id="site_navigation.emp_perks_nav"
            defaultMessage="Discover Perks"
            data-qa-automation="discoverPerksBtn"
          />
          <Transition
            in={shouldShowNavBadge}
            appear={shouldShowNavBadge}
            timeout={WELCOME_BADGE_FADE_OUT_DURATION}
            mountOnEnter
            unmountOnExit
          >
            {state => (
              <WelcomeNotificationBadge
                hasViewedWelcomeAnimation={shouldAnimateNavBadge}
                state={state}
              />
            )}
          </Transition>
        </>
      ),
      automationTag: 'discoverPerksBtn',
    },
  ];

  const handleExit = () => {
    setIsModalOpen(false);
  };

  const handleCTAClick = e => {
    dispatch(trackEmpInfoDrawerCtas(e.target.firstChild.textContent.trim()));
    setIsModalOpen(false);
  };

  const handleTabClick = tab => {
    if (tab.name === TabNames.DISCOVER_PERKS) {
      dispatch(trackEmpInfoDrawerDiscoverPerksOpen());
    }

    if (tab.name === TabNames.DISCOVER_PERKS && !hasViewedPerks) {
      updateJsonCustomerDetail({ hasViewedPerks: true });
    }

    if (tab.name === TabNames.DISCOVER_PERKS && !hasViewedBirthdayPerks) {
      updateJsonCustomerDetail({ previousBirthdayPerksSessionId: sessionId });
    }
  };

  const handleWelcomeAnimationEnd = () => {
    updateJsonCustomerDetail({
      hasViewedWelcomeAnimation: true,
    });
  };

  const handleBirthdayAnimationEnd = () => {
    updateJsonCustomerDetail({ previousSessionId: sessionId });
  };

  const renderHeader = () => {
    let header = null;
    if (isBirthday && hasViewedBirthdayAnimation) {
      header = (
        <FormattedMessage
          id="site_navigation.happy_birthday_firstname"
          defaultMessage="Happy Birthday, {firstName}!"
          values={{ firstName }}
        />
      );
    } else {
      header = (
        <FormattedMessage
          id="site_navigation.emp_info_greeting"
          defaultMessage="Hello, {firstName}!"
          values={{ firstName }}
        />
      );
    }

    return (
      <AccountInformationHeader data-autotag="pii-membership-credit-drawer-first-name">
        {header}
      </AccountInformationHeader>
    );
  };

  const renderFirstDetailRow = () => {
    const row = (
      <>
        <AccountInformationDetail>
          <FormattedMessage
            id="site_navigation.emp_info_member_since"
            defaultMessage="Member Since {year}"
            values={{
              year: <FormattedDate value={dateActivated} year="numeric" />,
            }}
          />
        </AccountInformationDetail>
        <AccountInformationDetail>
          <Link to="/account/membership">
            <ViewAccountLink
              onClick={handleExit}
              data-qa-automation="viewAccountBtn"
            >
              <FormattedMessage
                id="site_navigation.emp_view_account"
                defaultMessage="View Account"
              />
            </ViewAccountLink>
          </Link>
        </AccountInformationDetail>
      </>
    );

    return (
      <AccountInformationDetailsContainer>
        {row}
      </AccountInformationDetailsContainer>
    );
  };

  const renderSecondDetailRow = () => {
    const row = (
      <>
        <AccountInformationDetail>
          <MemberCreditWrapper>
            <MemberCreditBadge>{availableTokenQuantity}</MemberCreditBadge>
            {availableTokenQuantity === 1 ? (
              <FormattedMessage
                id="site_navigation.member_credit_single"
                defaultMessage="Member Credit"
              />
            ) : (
              <FormattedMessage
                id="site_navigation.member_credits_plural"
                defaultMessage="Member Credits"
              />
            )}
          </MemberCreditWrapper>
        </AccountInformationDetail>
        <AccountInformationDetail>
          <XtraVipIcon
            aria-label={formatMessage({
              id: 'site_navigation.savage_xtra_member',
              defaultMessage: 'Xtra VIP Member',
            })}
          />
        </AccountInformationDetail>
      </>
    );

    return (
      <AccountInformationDetailsContainer>
        {row}
      </AccountInformationDetailsContainer>
    );
  };

  const renderMyXtraVip = () => (
    <>
      {isVip &&
      !hasViewedWelcomeAnimation &&
      isWelcomeAnimationReady &&
      !shouldShowBirthdayBanner &&
      isBirthdayPromoReady ? (
        <WelcomeWrapper>
          <WelcomeContainer onAnimationEnd={handleWelcomeAnimationEnd}>
            <WelcomeTextWrapper>
              <WelcomeHeader>
                <FormattedMessage
                  id="site_navigation.emp_first_time_notification_header"
                  defaultMessage="Welcome Xtra VIP!"
                />
              </WelcomeHeader>
              <WelcomeSubheader>
                <FormattedMessage
                  id="site_navigation.emp_first_time_text"
                  defaultMessage="Earn credits &amp; get great deals!"
                />
              </WelcomeSubheader>
            </WelcomeTextWrapper>
          </WelcomeContainer>
        </WelcomeWrapper>
      ) : null}

      {shouldShowBirthdayBanner ? (
        <WelcomeWrapper>
          <WelcomeContainer onAnimationEnd={handleBirthdayAnimationEnd}>
            <WineGlasses />
            <BirthdayTextWrapper>
              <WelcomeHeader>
                {isBirthday ? (
                  <FormattedMessage
                    id="site_navigation.happy_birthday_generic_header"
                    defaultMessage="Happy Birthday, Boo!"
                  />
                ) : (
                  <FormattedMessage
                    id="site_navigation.happy_post_birthday_generic_header"
                    defaultMessage="Keep celebrating your birthday!"
                  />
                )}
              </WelcomeHeader>
              <WelcomeSubheader>
                {isBirthday ? (
                  <FormattedMessage
                    id="site_navigation.happy_birthday_generic_text"
                    defaultMessage="Have some cake &amp; a {discountPercent}% discount too!"
                    values={{
                      discountPercent,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="site_navigation.happy_post_birthday_generic_text"
                    defaultMessage="Don't forget to enjoy your {discountPercent}% discount!"
                    values={{
                      discountPercent,
                    }}
                  />
                )}
              </WelcomeSubheader>
            </BirthdayTextWrapper>
          </WelcomeContainer>
        </WelcomeWrapper>
      ) : null}

      <AccountInformationContainer>
        {renderHeader()}
        {renderFirstDetailRow()}
        {renderSecondDetailRow()}
      </AccountInformationContainer>

      <InstructionsContainer>
        <InstructionsHeader>
          {hasCredits ? (
            <FormattedMessage
              id="site_navigation.emp_cta_header_credits"
              defaultMessage="Choose how to use your member credit!"
            />
          ) : (
            <FormattedMessage
              id="site_navigation.emp_cta_header_no_credits"
              defaultMessage="Shop your favorite categories"
            />
          )}
        </InstructionsHeader>
        <InstructionsSubheader>
          {hasCredits ? (
            <FormattedMessage
              id="site_navigation.emp_cta_subheader_credits"
              defaultMessage="Redeemable for 1 Member Credit each"
            />
          ) : (
            <FormattedMessage
              id="site_navigation.emp_cta_subheader_no_credits"
              defaultMessage="Don't miss this month's new drops!"
            />
          )}
        </InstructionsSubheader>
      </InstructionsContainer>
      <CTAsContainer>
        {ctaAssets.map(asset => (
          <Link to={asset.options.targetUrl} key={asset.id}>
            <CTAWrapper
              bgColor={asset.options.bgcolor}
              isSoldOut={asset.options.customVars.isSoldOut}
              gradientColorDegrees={
                asset.options.customVars.gradientColorDegrees
              }
              gradientColor1={asset.options.customVars.gradientColor1}
              gradientColor2={asset.options.customVars.gradientColor2}
              hasCredits={hasCredits}
              onClick={handleCTAClick}
              order={asset.options.customVars.order}
            >
              <CTAText>
                <HTMLAsset asset={asset} />
              </CTAText>
              {asset.options.customVars.isSoldOut ? (
                <CTASoldOutBadge>
                  <FormattedMessage
                    id="global_checkout.sold_out"
                    defaultMessage="Sold Out"
                  />
                </CTASoldOutBadge>
              ) : null}
            </CTAWrapper>
          </Link>
        ))}
      </CTAsContainer>
    </>
  );

  const renderTabContent = selectedTab => {
    switch (selectedTab.name) {
      case TabNames.MY_XTRA_VIP:
        return renderMyXtraVip();
      case TabNames.DISCOVER_PERKS:
        return <MemberCreditsDrawerModalPerks closeDrawer={handleExit} />;
      default:
        return null;
    }
  };

  return (
    <StyledModal
      closeButton={<ModalCloseButton data-qa-automation="closeModalBtn" />}
      isOpen={!!isModalOpen}
      onExit={handleExit}
      title={formatMessage({
        id: 'site_navigation.emp_credits_drawer_title',
        defaultMessage: 'Learn More About Your Member Credits',
      })}
    >
      <ModalContentContainer>
        <ToggleTabs tabs={tabs} onTabClick={handleTabClick}>
          {({ selectedTab }) => renderTabContent(selectedTab)}
        </ToggleTabs>
      </ModalContentContainer>
    </StyledModal>
  );
}

export default MemberCreditsDrawerModal;
