export const fabrics = [
  {
    label: 'Chiffon',
    id: '5586',
    value: '/static/images/swatches/chiffon.jpg',
  },
  { label: 'Cotton', id: '5420', value: '/static/images/swatches/cotton.jpg' },
  {
    label: 'Embroidered',
    id: '5422',
    value: '/static/images/swatches/embroidered.jpg',
  },
  { label: 'Lace', id: '5418', value: '/static/images/swatches/lace.jpg' },
  {
    label: 'Marabou',
    id: '5430',
    value: '/static/images/swatches/marabou.jpg',
  },
  { label: 'Mesh', id: '5428', value: '/static/images/swatches/mesh.jpg' },
  {
    label: 'Microfiber',
    id: '5419',
    value: '/static/images/swatches/microfiber.jpg',
  },
  { label: 'Knit', id: '5426', value: '/static/images/swatches/knit.jpg' },
  { label: 'Satin', id: '5425', value: '/static/images/swatches/satin.jpg' },
  {
    label: 'Faux Leather',
    id: '5429',
    value: '/static/images/swatches/faux-leather.jpg',
  },
  {
    label: 'Velvet',
    id: '5427',
    value: '/static/images/swatches/velvet.jpg',
  },
];
