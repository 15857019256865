import React, { useContext, useMemo, useState } from 'react';

import ReactDOM from 'react-dom';

const Context = React.createContext({});

function Provider({ children }) {
  const [container, setContainer] = useState(null);
  const value = useMemo(() => ({ container, setContainer }), [container]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

Provider.displayName = 'NavBarAttachment.Provider';

export default function NavBarAttachment({ children }) {
  const { container } = useContext(Context);

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(children, container);
}

NavBarAttachment.Context = Context;
NavBarAttachment.Consumer = Context.Consumer;
NavBarAttachment.Provider = Provider;
