import React, { useRef, useState, useEffect, ReactNode } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSpring, animated, config } from 'react-spring';
import styled, { css } from 'styled-components';

import { updateCustomerDetail, useMembership } from '@techstyle/react-accounts';
import { Checkbox } from '@techstyle/react-components';
import { FormattedMessage, useIntl } from '@techstyle/react-intl';

import { desktop } from '../../styles';
import { BasicButton } from '../../styles/button';
import { Component as Carousel } from '../Carousel';
import { Component as Price } from '../ExtendedPrice';

const Wrapper = styled.div`
  width: 100%;
  height: 418px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCarousel = styled(Carousel)`
  margin-bottom: ${props => props.theme.spacing.tiny}px;
  .slick-slider {
    height: 100%;
    padding-bottom: 12px; /* Account for dots */
  }
  .slick-dots {
    pointer-events: none;
  }

  height: 325px;
  ${desktop`
    height: 305px;
  `};
`;

const SlideWrapper = styled.div`
  position: relative;
`;

const SlideContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  height: 300px;
  ${desktop`
    height: 280px;
  `};
`;

const LogoWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
`;

const LogoImg = styled(animated.img)`
  width: 88px;
`;

const SlideHeader = styled.div`
  margin: 0px ${props => props.theme.spacing.large}px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
  padding: 0px ${props => props.theme.spacing.tiny}px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

const SlideCopy = styled.div<{
  optIn?: boolean;
  showError?: boolean;
}>`
  margin: 0px ${props => props.theme.spacing.moderate}px;
  font-weight: 300;
  font-size: ${props => (props.optIn ? 12 : 14)}px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: ${props => (props.showError ? props.theme.colors.error : 'inherit')};
  ${desktop`
    width: 450px;
    margin: 0px auto;
  `};
`;

const ContentBox = styled.div<{ optIn?: boolean }>`
  margin: ${props => props.theme.spacing.tiny}px;
  padding: ${props => (props.optIn ? props.theme.spacing.tiny : 0)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.lavender100};

  ${desktop`
    margin: ${props => props.theme.spacing.tiny}px;
  `}
`;

const VideoWrapper = styled.div`
  margin: auto ${props => props.theme.spacing.tiny}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${desktop`
    margin: auto ${props => props.theme.spacing.small}px;
  `}
`;

const Video = styled.video`
  width: 340px;
`;

const KeyItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px ${props => props.theme.spacing.small}px;
`;

const KeyItemGroup = styled(animated.div)`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: ${props => props.theme.colors.promo};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
`;

const OptInItemGroup = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  margin: ${props => props.theme.spacing.tiny}px 0px;
  color: ${props => props.theme.colors.promo};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;

  ${desktop`
    margin: ${props => props.theme.spacing.tiny}px ${props =>
    props.theme.spacing.moderate}px;
  `}
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;
`;

const OptInIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const KeyItemText = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  align-items: stretch;
  margin: 0px ${props => props.theme.spacing.small}px;
  text-align: left;
`;

const OptInItemText = styled.div`
  display: flex;
  align-items: stretch;
  margin-left: ${props => props.theme.spacing.small}px;
  text-align: left;
  color: ${props => props.theme.colors.default};
`;

const NextButton = styled(BasicButton).attrs({
  tabIndex: 0,
})<{ children?: ReactNode; allowClick: boolean; onClick: () => void }>`
  width: 134px;
  height: 40px;
  background: ${props =>
    props.allowClick ? props.theme.colors.active : props.theme.colors.subdued};
  border-radius: 20px;
  text-transform: uppercase;
  border: 0px;
`;

const checkboxStyle = css`
  width: 20px;
  height: 20px;
  margin-right: ${props => props.theme.spacing.small}px;
`;

const inputCheckedStyle = css`
  color: ${props => props.theme.colors.promo};
  background-color: transparent;
  border-color: ${props => props.theme.colors.promo};
`;

const StyledCheckbox = styled(Checkbox).attrs({
  inputStyle: checkboxStyle,
  inputCheckedStyle: inputCheckedStyle,
  tabIndex: -1,
})<{ checked: boolean; onChange: () => void; showError: boolean }>`
  border-color: ${props =>
    props.showError ? props.theme.colors.error : props.theme.colors.default};
`;

const TCLink = styled.a`
  color: ${props => props.theme.colors.active};
`;

const Label = styled.label``;

export default function EmpUserAcceptanceCarousel({
  hasModalBeenSeen,
  onCloseModal,
}) {
  const [currentSlide, setCurrentSlide] = useState(hasModalBeenSeen ? 3 : 0);
  const [isOptInChecked, setIsOptInChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const carouselRef = useRef(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { price: membershipPrice } = useMembership();

  const logoStyles = useSpring({
    from: { transform: 'translateY(75px)' },
    transform: 'translateY(-100px)',
    config: config.gentle,
    delay: 4000,
  });
  const slide1Styles = useSpring({
    from: { transform: 'translateY(25px)', opacity: 0 },
    transform: 'translateY(0px)',
    opacity: 1,
    config: config.gentle,
    delay: 4250,
  });
  const item1Styles = useSpring({
    from: { transform: 'translateY(10px)', opacity: 0 },
    transform: 'translateY(0px)',
    opacity: 1,
    config: config.gentle,
    delay: 5000,
  });
  const item2Styles = useSpring({
    from: { transform: 'translateY(10px)', opacity: 0 },
    transform: 'translateY(0px)',
    opacity: 1,
    config: config.gentle,
    delay: 6000,
  });
  const item3Styles = useSpring({
    from: { transform: 'translateY(10px)', opacity: 0 },
    transform: 'translateY(0px)',
    opacity: 1,
    config: config.stiff,
    delay: 7000,
  });

  useEffect(() => {
    carouselRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);

  // TODO: Add types to updateCustomerDetail hook in react-packages, making dateTimeAdded argument optional
  const updateCustomerDetails = () => {
    dispatch(
      updateCustomerDetail({
        name: 'nmp_opt_in_true',
        value: 'true',
        dateTimeAdded: null,
      })
    );

    dispatch(
      updateCustomerDetail({
        name: 'nmp_opt_in_location',
        value: hasModalBeenSeen ? 'post-onboarding' : 'onboarding',
        dateTimeAdded: null,
      })
    );
  };

  const handleButtonClick = () => {
    if (currentSlide === 3 && !isOptInChecked) {
      setShowError(true);
    } else if (currentSlide === 3 && isOptInChecked) {
      updateCustomerDetails();
      onCloseModal();
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handleOptInClick = () => {
    setIsOptInChecked(!isOptInChecked);
    setShowError(false);
  };

  const carouselOptions = {
    arrows: false,
    dots: currentSlide < 3,
    draggable: false,
    touchMove: false,
    initialSlide: currentSlide,
  };

  const renderSlide1 = () => {
    return (
      <SlideWrapper>
        <LogoWrapper style={logoStyles}>
          <LogoImg src="/static/images/x_rotation_2.gif" />
        </LogoWrapper>
        <SlideContent style={slide1Styles}>
          <SlideHeader>
            <FormattedMessage
              id="site_emp_onboarding.slide_1_header"
              defaultMessage="Your Xtra VIP Membership just got even better!"
            />
          </SlideHeader>
          <SlideCopy>
            <FormattedMessage
              id="site_emp_onboarding.slide_1_body_copy"
              defaultMessage="Here’s what’s new:"
            />
          </SlideCopy>
          <ContentBox>
            <KeyItemWrapper>
              <KeyItemGroup style={item1Styles}>
                <Icon
                  alt=""
                  src="/static/images/vipcredit_a_black_detailed.svg"
                />
                <KeyItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_1_bullet_1"
                    defaultMessage="NEW! Save up to 50-60% off with Member Credits"
                  />
                </KeyItemText>
              </KeyItemGroup>
              <KeyItemGroup style={item2Styles}>
                <Icon
                  alt=""
                  src="/static/images/xclusive_discounts_white_detailed.svg"
                />
                <KeyItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_1_bullet_2"
                    defaultMessage="Save up to 25% on full-priced styles every day"
                  />
                </KeyItemText>
              </KeyItemGroup>
              <KeyItemGroup style={item3Styles}>
                <Icon
                  alt=""
                  src="/static/images/birthdaysuprise_white_detailed.svg"
                />
                <KeyItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_1_bullet_3"
                    defaultMessage="NEW! Yearly Birthday surprise"
                  />
                </KeyItemText>
              </KeyItemGroup>
            </KeyItemWrapper>
          </ContentBox>
        </SlideContent>
      </SlideWrapper>
    );
  };

  const renderSlide2 = () => {
    return (
      <>
        <SlideContent>
          <SlideHeader>
            <FormattedMessage
              id="site_emp_onboarding.slide_2_header"
              defaultMessage="Unlock Even More Xclusive Perks"
            />
          </SlideHeader>
          <SlideCopy>
            <FormattedMessage
              id="site_emp_onboarding.slide_2_body_copy"
              defaultMessage='Choose not to "Skip the Month" to unlock even more Xclusive perks, including 1 Member Credit that can save you up to 50-60% on full-price styles!'
            />
          </SlideCopy>
          <VideoWrapper>
            <Video autoPlay loop playsInline muted>
              <source
                src="/static/videos/onboarding_slide2.mp4"
                type="video/mp4"
              />
            </Video>
          </VideoWrapper>
        </SlideContent>
      </>
    );
  };

  const renderSlide3 = () => {
    return (
      <SlideContent>
        <SlideHeader>
          <FormattedMessage
            id="site_emp_onboarding.slide_3_header"
            defaultMessage="Your Membership, Your Way"
          />
        </SlideHeader>
        <SlideCopy>
          <FormattedMessage
            id="site_emp_onboarding.slide_3_body_copy"
            defaultMessage='Click "Skip the Month" by the 5th of each month to avoid a charge. Skip as many times as you like; you’ll still enjoy Everyday Xtra VIP perks and pricing.'
          />
        </SlideCopy>
        <VideoWrapper>
          <Video autoPlay loop playsInline muted>
            <source
              src="/static/videos/onboarding_slide3.mp4"
              type="video/mp4"
            />
          </Video>
        </VideoWrapper>
      </SlideContent>
    );
  };

  const renderSlide4 = () => {
    return (
      <>
        <SlideContent>
          <SlideHeader>
            <FormattedMessage
              id="site_emp_onboarding.slide_4_header"
              defaultMessage="What else is new?"
            />
          </SlideHeader>
          <ContentBox optIn>
            <KeyItemWrapper>
              <OptInItemGroup>
                <OptInIcon
                  alt=""
                  src="/static/images/emp_onboarding_optin_calendar.svg"
                />
                <OptInItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_4_bullet_1"
                    defaultMessage="Shopping between the 1st and the 5th no longer automatically skips you. Remember to “Skip the Month” between the 1st and the 5th to avoid a {formattedMembershipPrice} monthly charge."
                    values={{
                      formattedMembershipPrice: (
                        <Price amount={membershipPrice} />
                      ),
                    }}
                  />
                </OptInItemText>
              </OptInItemGroup>
              <OptInItemGroup>
                <OptInIcon
                  alt=""
                  src="/static/images/emp_onboarding_optin_dollarsign.svg"
                />
                <OptInItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_4_bullet_2"
                    defaultMessage="Each Member Credit can be redeemed for ANY item, Xtra VIP box, or set regardless of price!"
                  />
                </OptInItemText>
              </OptInItemGroup>
              <OptInItemGroup>
                <OptInIcon
                  alt=""
                  src="/static/images/emp_onboarding_optin_clock.svg"
                />
                <OptInItemText>
                  <FormattedMessage
                    id="site_emp_onboarding.slide_4_bullet_3"
                    defaultMessage="Member Credits can be redeemed on future purchases and expire after 12 months. But don’t worry, we’ll remind you ahead of time!"
                  />
                </OptInItemText>
              </OptInItemGroup>
            </KeyItemWrapper>
          </ContentBox>

          <Label>
            <SlideCopy optIn showError={showError}>
              <StyledCheckbox
                checked={isOptInChecked}
                showError={showError}
                onChange={handleOptInClick}
              />
              <FormattedMessage
                id="site_emp_onboarding.slide_4_checkbox_label"
                defaultMessage="I accept the new {tcLink}"
                values={{
                  tcLink: (
                    <TCLink
                      href="/termsandconditions"
                      target="_blank"
                      tabIndex={-1}
                    >
                      <FormattedMessage
                        id="site_emp_onboarding.slide_4_terms_conditions"
                        defaultMessage="Terms & Conditions"
                      />
                    </TCLink>
                  ),
                }}
              />
            </SlideCopy>
          </Label>
        </SlideContent>
      </>
    );
  };

  return (
    <Wrapper>
      <StyledCarousel
        sliderRef={carouselRef}
        title={intl.formatMessage({
          id: 'site_emp_onboarding.carousel_title',
          defaultMessage: 'Xtra VIP Membership User Acceptance',
        })}
        options={carouselOptions}
      >
        {renderSlide1()}
        {renderSlide2()}
        {renderSlide3()}
        {renderSlide4()}
      </StyledCarousel>
      <NextButton
        allowClick={currentSlide < 3 || (currentSlide === 3 && isOptInChecked)}
        onClick={handleButtonClick}
      >
        {currentSlide < 3
          ? intl.formatMessage({
              id: 'site_emp_onboarding.next_button_label',
              defaultMessage: 'Next',
            })
          : intl.formatMessage({
              id: 'site_emp_onboarding.agree_button_label',
              defaultMessage: 'I Agree',
            })}
      </NextButton>
    </Wrapper>
  );
}

EmpUserAcceptanceCarousel.propTypes = {
  hasModalBeenSeen: PropTypes.bool,
  onCloseModal: PropTypes.func,
};
