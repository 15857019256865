export const LOAD_RETURNS_SETTINGS_REQUEST = 'LOAD_RETURNS_SETTINGS_REQUEST';
export const LOAD_RETURNS_SETTINGS_SUCCESS = 'LOAD_RETURNS_SETTINGS_SUCCESS';
export const LOAD_RETURNS_SETTINGS_FAILURE = 'LOAD_RETURNS_SETTINGS_FAILURE';

export function loadOrderReturnsSettings() {
  return {
    bentoApi: {
      endpoint: `settings/returns`,
      method: 'GET',
      actions: [
        LOAD_RETURNS_SETTINGS_REQUEST,
        LOAD_RETURNS_SETTINGS_SUCCESS,
        LOAD_RETURNS_SETTINGS_FAILURE,
      ],
    },
  };
}
