import React, { useCallback, useMemo } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { defineMessages, useIntl } from '@techstyle/react-intl';
import { urlNoQueryString } from '@techstyle/redux-core';

import { desktop, mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { Component as CloseButton } from '../CloseButton';

const { rihannasMessage } = defineMessages({
  rihannasMessage: {
    id: 'site_navigation.rihannas_message',
    defaultMessage: `Rihanna's Message`,
  },
});

const Video = dynamic(() =>
  import(/* webpackChunkName: "Video" */ '@techstyle/react-components').then(
    module => module.Video
  )
);

const Modal = styled(BasicModal)`
  ${CloseButton} {
    color: white;
  }
`;

const VideoWrapper = styled.div`
  background-color: black;
  ${desktop`
    width: 1024px;
    max-width: 100%;
  `}

  ${mobile`
    width: 100vw;
    max-width: 100%;
  `}

  @media only screen and (max-device-width: 995px) and (orientation: landscape) {
    // We want the full height to work automatically, we can do this by assuming a 16:9 aspect ratio
    // and setting the width accordingly.
    width: calc(100vh * (16 / 9));
  }
`;

export const VideoMessageModal = () => {
  const router = useRouter();
  const { query } = router;
  const { 'rebrand-launch-video': rebrandLaunchVideoUrl } = useLDFlags();
  const intl = useIntl();

  const { isEnabled, url } = useMemo(() => {
    // Check both in case we send out messaging using capitalized/camelcase query param
    const { ShowRihannasMessage, showRihannasMessage } = query;
    const showMessageParam = ShowRihannasMessage ?? showRihannasMessage;
    const hasShowMessageParam =
      showMessageParam === 'true' || showMessageParam === 'True';
    const isEnabled = hasShowMessageParam && rebrandLaunchVideoUrl;

    return {
      isEnabled,
      url: rebrandLaunchVideoUrl,
    };
  }, [query, rebrandLaunchVideoUrl]);

  const removeQueryParam = useCallback(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We want to remove these query params
      ShowRihannasMessage,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We want to remove these query params
      showRihannasMessage,
      page,
      ...restQueryParams
    } = router.query;

    router.replace(
      {
        pathname: router.pathname,
        query: {
          page,
          ...restQueryParams,
        },
      },
      {
        pathname: urlNoQueryString(router.asPath),
        query: {
          ...restQueryParams,
        },
      },
      { shallow: true }
    );
  }, [router]);

  if (!isEnabled) {
    return null;
  }

  return (
    <Modal
      isOpen={isEnabled}
      title={intl.formatMessage(rihannasMessage)}
      onExit={removeQueryParam}
    >
      <VideoWrapper>
        <Video
          // @ts-ignore -- The prop types are incorrect
          url={url}
          isLooping={false}
          onEnded={removeQueryParam}
        />
      </VideoWrapper>
    </Modal>
  );
};
