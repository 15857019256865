import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import type {
  CategorizedProductsRequestDto,
  ProductFilterRequestDto,
} from '@techstyle/react-types';

import { loadProducts } from '../actions/products';

type BaseLoadProductsOptions = {
  filters?: ProductFilterRequestDto;
  aggregations?: boolean;
  options?: {
    shouldCategorize?: boolean;
    isFromFavorites?: boolean;
  };
};

type LoadProductsFromFavoritesOptions = BaseLoadProductsOptions & {
  options: {
    isFromFavorites: true;
  };
  filters?: {
    size: number;
    page: number;
  };
};

type LoadProductsCategoryOptions = BaseLoadProductsOptions & {
  options: {
    shouldCategorize: true;
  };
  filters?: CategorizedProductsRequestDto;
};

type UseLoadProductsOptions =
  | BaseLoadProductsOptions
  | LoadProductsFromFavoritesOptions
  | LoadProductsCategoryOptions;

const defaultOptions = {
  shouldCategorize: false,
  isFromFavorites: false,
};

/**
 * A hook to load products from the API using the SXF `loadProducts` action from
 * This _does not_ use `loadProducts` from `@techstyle/react-products`.
 */
export const useLoadProducts = ({
  filters,
  aggregations = false,
  options,
  ...rest
}: UseLoadProductsOptions & UseQueryOptions) => {
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    return dispatch(
      loadProducts(filters, aggregations, { ...defaultOptions, ...options })
    );
  }, [aggregations, dispatch, filters, options]);

  const result = useQuery({
    queryKey: ['loadProducts', filters, aggregations, options],
    queryFn: loadData,
    ...rest,
  });

  return result;
};
