import React, { useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AssetFilter } from '@techstyle/react-assets';
import { useRenderTime } from '@techstyle/redux-core';

import { defaultFilter } from '../../utils/assets';

export default function DefaultAssetFilter({
  children,
  dmgCode,
  isBorderfreeCustomer,
  isDowngradedMember,
  isVip,
  persona,
}) {
  const getTime = useRenderTime();
  const state = useMemo(
    () => ({
      dmgCode,
      getTime,
      isBorderfreeCustomer,
      isDowngradedMember,
      isVip,
      persona,
    }),
    [dmgCode, getTime, isBorderfreeCustomer, isDowngradedMember, isVip, persona]
  );
  const filter = useCallback(
    parentFilter => defaultFilter(parentFilter, state),
    [state]
  );
  return <AssetFilter filter={filter}>{children}</AssetFilter>;
}

DefaultAssetFilter.propTypes = {
  children: PropTypes.node,
  dmgCode: PropTypes.string,
  isBorderfreeCustomer: PropTypes.bool,
  isDowngradedMember: PropTypes.bool,
  isVip: PropTypes.bool,
  persona: PropTypes.string,
};
