import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  forgotPassword,
  resetPassword,
  updateEmailPreferences,
  logIn,
} from '@techstyle/react-accounts';
import { updateSessionDetail } from '@techstyle/redux-core';

import { trackAccountSegmentEvents } from '../../actions/account';
import {
  signUp,
  trackSignUpModal,
  trackRegistrationFailure,
} from '../../actions/auth';
import { checkIfPasswordlessLogin } from '../../actions/auth/checkIfPasswordlessLogin';
import { loadCartItemCount } from '../../actions/checkout';
import { trackIdentifyQuizTraits } from '../../actions/quiz';

import SignIn from './SignIn';

function mapStateToProps(state, ownProps) {
  const { auth, domain, referrer, session } = state;
  const isUS = domain.tld === '.com';

  return {
    initialSubscribeToEmails: isUS,
    onCloseSignInModal: auth.onSuccess,
    profile: state.customer,
    showSubscribeToEmails: !isUS,
    showTermsConditions: !isUS,
    referrerOptions: referrer.referrerOptions,
    shouldShowPrivacyNotice: !isUS,
    showBottomTermsConditionsLink: isUS,
    showBottomPrivacyLink: isUS,
    isAdmin: session.isAdmin,
    tld: domain.tld,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      checkIfPasswordlessLogin,
      loadCartItemCount,
      onRequestAuthenticate: logIn,
      onRequestSignUp: signUp,
      onRequestResetPassword: forgotPassword,
      onRequestUpdatePassword: resetPassword,
      trackSignUpModal,
      trackIdentifyQuizTraits,
      trackAccountSegmentEvents,
      trackRegistrationFailure,
      updateEmailPreferences,
      updateSessionDetail,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
