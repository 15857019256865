import { getPartialTrackingData } from '../utils';

const { TRACK_PROMOTION_VIEWED } = require('../../actions/tracking');

/**
 * Promotion image or video pops into the user's view
 */
const trackPromotionViewedEvents = ({ getContext, trackEvent }) => {
  const trackPromotionViewed = trackEvent((action, prevState, nextState) => {
    const context = getContext();
    const { clickable, clickableUrl, creative, customVars, imageId, name } =
      action.payload;

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
      'membership_credits',
    ]);

    return {
      name: 'Promotion Viewed',
      properties: {
        ...additionalTrackingData,
        clickable: clickable || false, // Whether or not there is a targetURL setup on the asset
        clickable_url: clickableUrl || '', // The URL that someone goes to when they click
        creative: creative || '', // assets.$.label
        custom_vars: customVars || {}, // Custom properties and values attached to the asset (ONLY PASS VALUES THAT HAVE BEEN POPULATED - NO EMPTY CUSTOM VARS)
        image_id: imageId || '', // assets.$.imageFilename
        name: name || '', // container.label
      },
    };
  });

  return { [TRACK_PROMOTION_VIEWED]: trackPromotionViewed };
};

export default trackPromotionViewedEvents;
