export const APPLY_MEMBERSHIP_PROMO_CODE_REQUEST =
  'APPLY_MEMBERSHIP_PROMO_CODE_REQUEST';
export const APPLY_MEMBERSHIP_PROMO_CODE_SUCCESS =
  'APPLY_MEMBERSHIP_PROMO_CODE_SUCCESS';
export const APPLY_MEMBERSHIP_PROMO_CODE_FAILURE =
  'APPLY_MEMBERSHIP_PROMO_CODE_FAILURE';

export const CHECK_MEMBERSHIP_PROMOS_REQUEST =
  'CHECK_MEMBERSHIP_PROMOS_REQUEST';
export const CHECK_MEMBERSHIP_PROMOS_SUCCESS =
  'CHECK_MEMBERSHIP_PROMOS_SUCCESS';
export const CHECK_MEMBERSHIP_PROMOS_FAILURE =
  'CHECK_MEMBERSHIP_PROMOS_FAILURE';

export const DELETE_MEMBERSHIP_PROMO_CODE_REQUEST =
  'DELETE_MEMBERSHIP_PROMO_CODE_REQUEST';
export const DELETE_MEMBERSHIP_PROMO_CODE_SUCCESS =
  'DELETE_MEMBERSHIP_PROMO_CODE_SUCCESS';
export const DELETE_MEMBERSHIP_PROMO_CODE_FAILURE =
  'DELETE_MEMBERSHIP_PROMO_CODE_FAILURE';

export function applyMembershipPromoCode(promo) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/promo',
      method: 'POST',
      actions: [
        APPLY_MEMBERSHIP_PROMO_CODE_REQUEST,
        APPLY_MEMBERSHIP_PROMO_CODE_SUCCESS,
        APPLY_MEMBERSHIP_PROMO_CODE_FAILURE,
      ],
      body: JSON.stringify(promo),
    },
  };
}

export function checkMembershipPromos({ forceCheck = true }) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/promo/check',
      method: 'POST',
      actions: [
        CHECK_MEMBERSHIP_PROMOS_REQUEST,
        CHECK_MEMBERSHIP_PROMOS_SUCCESS,
        CHECK_MEMBERSHIP_PROMOS_FAILURE,
      ],
      body: JSON.stringify({
        forceCheck,
      }),
    },
  };
}

export function deleteMembershipPromo(promoCode) {
  return {
    bentoApi: {
      endpoint: `accounts/me/membership/promo/${promoCode}`,
      method: 'DELETE',
      actions: [
        DELETE_MEMBERSHIP_PROMO_CODE_REQUEST,
        DELETE_MEMBERSHIP_PROMO_CODE_SUCCESS,
        DELETE_MEMBERSHIP_PROMO_CODE_FAILURE,
      ],
    },
  };
}
