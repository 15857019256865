import { handleActions } from 'redux-actions';

import {
  UPDATE_BORDERFREE_STATUS,
  SET_NO_REDIRECT,
} from '../actions/borderfree';

export type BorderfreeStoreType = {
  isBorderfreeCustomer: boolean;
  country: string;
  initialized: boolean;
  noRedirect: boolean;
};

const defaultState = {
  isBorderfreeCustomer: false,
  country: null,
  initialized: false,
  noRedirect: false,
};

// TODO: Add types after redux-actions and store is updated
export default handleActions(
  {
    [UPDATE_BORDERFREE_STATUS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [SET_NO_REDIRECT]: (state, action) => ({
      ...state,
      noRedirect: action.payload,
    }),
  },
  defaultState
);
