import { MembershipActionLocation } from '../../constants/checkout';
import {
  loadCart,
  updateFulfillmentProvider,
  deleteMembershipFromCart,
} from '../checkout';

export const UPDATE_BORDERFREE_STATUS = 'UPDATE_BORDERFREE_STATUS';
export const SET_NO_REDIRECT = 'SET_NO_REDIRECT';

export function updateBorderfreeStatus({
  isBorderfreeCustomer,
  country = null,
}) {
  return async (dispatch, getState, context) => {
    let state = getState();
    const wasBorderfreeCustomer = state.borderfree.isBorderfreeCustomer;

    const result = await dispatch({
      type: UPDATE_BORDERFREE_STATUS,
      payload: { isBorderfreeCustomer, country, initialized: true },
    });

    // Borderfree events will fire on route change, so only take action if their
    // status is changing.
    if (!wasBorderfreeCustomer && isBorderfreeCustomer) {
      // Find out of their session actually has an active cart.
      await dispatch(loadCart({ ifNotFetched: true }));
      state = getState();
      if (state.checkout.cartId) {
        await dispatch(updateFulfillmentProvider('borderfree'));
        await dispatch(
          deleteMembershipFromCart({
            location: MembershipActionLocation.OTHER,
          })
        );
      }
    }

    return result;
  };
}

export function setNoRedirect(noRedirect) {
  return { type: SET_NO_REDIRECT, payload: noRedirect };
}
