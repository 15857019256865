import config from 'config';

import {
  getNewDateFunction,
  parseDate,
  loadSession,
  getSession,
} from '@techstyle/redux-core';

export const LOAD_WAITLIST_REQUEST = 'LOAD_WAITLIST_REQUEST';
export const LOAD_WAITLIST_SUCCESS = 'LOAD_WAITLIST_SUCCESS';
export const LOAD_WAITLIST_FAILURE = 'LOAD_WAITLIST_FAILURE';
export const ADD_TO_WAITLIST_REQUEST = 'ADD_TO_WAITLIST_REQUEST';
export const ADD_TO_WAITLIST_SUCCESS = 'ADD_TO_WAITLIST_SUCCESS';
export const ADD_TO_WAITLIST_FAILURE = 'ADD_TO_WAITLIST_FAILURE';
export const REMOVE_FROM_WAITLIST_REQUEST = 'REMOVE_FROM_WAITLIST_REQUEST';
export const REMOVE_FROM_WAITLIST_SUCCESS = 'REMOVE_FROM_WAITLIST_SUCCESS';
export const REMOVE_FROM_WAITLIST_FAILURE = 'REMOVE_FROM_WAITLIST_FAILURE';

const maxAge = config.get('public.waitlist.maxAge');
const NOT_SIGNED_IN = new Error('Not signed in');

export function loadWaitlist({ forceFetch = false } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    await dispatch(loadSession());
    const session = getSession(state);
    const { account } = state;
    if (!session.isLoggedIn) {
      return dispatch({
        type: LOAD_WAITLIST_FAILURE,
        error: true,
        payload: NOT_SIGNED_IN,
      });
    }
    const getNewDate = getNewDateFunction(state);
    const now = getNewDate();
    const timeElapsed = now - parseDate(account.waitlist.fetchedDate);
    if (
      !forceFetch &&
      account.waitlist.fetchedDate !== null &&
      timeElapsed < maxAge
    ) {
      return dispatch({
        type: LOAD_WAITLIST_SUCCESS,
        meta: {
          fromCache: true,
        },
      });
    }
    const fetchedDate = now;
    const meta = {
      fetchedDate: fetchedDate.toISOString(),
    };
    return dispatch({
      bentoApi: {
        endpoint: 'accounts/me/waitlist',
        method: 'GET',
        searchParams: { page: 1, pageSize: 100 },
        actions: [
          { type: LOAD_WAITLIST_REQUEST, meta },
          { type: LOAD_WAITLIST_SUCCESS, meta },
          { type: LOAD_WAITLIST_FAILURE, meta },
        ],
      },
    });
  };
}

export function addToWaitlist(params) {
  return async dispatch => {
    const request = {
      bentoApi: {
        endpoint: 'accounts/me/waitlist',
        method: 'POST',
        body: JSON.stringify(params),
        actions: [
          ADD_TO_WAITLIST_REQUEST,
          {
            type: ADD_TO_WAITLIST_SUCCESS,
            payload: async () => {
              return {};
            },
          },
          ADD_TO_WAITLIST_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);
    dispatch(loadWaitlist({ forceFetch: true }));

    return result;
  };
}

export function removeFromWaitlist({ productId }) {
  return async dispatch => {
    const request = {
      bentoApi: {
        endpoint: `accounts/me/waitlist/${productId}`,
        method: 'DELETE',
        body: JSON.stringify({
          productId,
        }),
        actions: [
          REMOVE_FROM_WAITLIST_REQUEST,
          REMOVE_FROM_WAITLIST_SUCCESS,
          REMOVE_FROM_WAITLIST_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);
    dispatch(loadWaitlist({ forceFetch: true }));

    return result;
  };
}
