import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import {
  GlobalLDSymbol,
  useLaunchDarklyContextValue,
} from '../../utils/launchDarkly';
import type { LDContextValue } from '../../utils/launchDarkly';

const LaunchDarklyIdentifier = () => {
  const LDClient = useLDClient();
  let ldContextValue = useLaunchDarklyContextValue();

  useEffect(() => {
    if (typeof window !== 'undefined' && !window[GlobalLDSymbol]) {
      window[GlobalLDSymbol] = LDClient;
    }
  }, [LDClient]);

  if (LDClient && ldContextValue) {
    const currentLDContext = LDClient.getContext() as LDContextValue;

    if ('anonymous' in ldContextValue.user) {
      const {
        // We are removing the key from the user object because it is added by the SDK and won't
        // exist when `anonymous`
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        user: { key, ...restUser },
        ...restLDContext
      } = currentLDContext;
      // @ts-ignore
      ldContextValue = { user: { ...restUser }, ...restLDContext };
    }
  }

  useQuery({
    queryKey: ['ldContext', ldContextValue],
    queryFn: () => {
      LDClient.identify(ldContextValue);
    },
  });

  return <></>;
};

export default LaunchDarklyIdentifier;
