import { handleActions } from 'redux-actions';

import {
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDERS_REQUEST,
  GENERATE_RMA_SUCCESS,
} from '../../actions/account';

const defaultState = {
  ordersByID: {},
  ordersByPage: {
    pages: {},
    totalRecords: null,
  },
  returnData: {},
};

const handleRequest = (state, action) => {
  const pageNumber = action.meta.params.pageIndex;
  const orderData = state.ordersByPage || {
    pages: {},
  };
  const page = orderData.pages[pageNumber] || {};
  return {
    ...state,
    ordersByPage: {
      ...state.ordersByPage,
      pages: {
        ...orderData.pages,
        [pageNumber]: {
          orderIDs: [],
          ...page,
          isLoading: true,
        },
      },
    },
  };
};

const normalizeOrders = (state = defaultState, action) => {
  const orderIDs = [];
  let ordersByID = {};
  const orders = action.payload.orders[0] || [];
  const totalRecords = action.payload.totalRecords;
  const { params, fromCache } = action.meta;

  if (fromCache) {
    return state;
  }

  orders.map(order => {
    const id = order.orderId;
    ordersByID = {
      ...ordersByID,
      [id]: { ...order },
    };

    // TODO: REMOVE responseIds and corresponding state
    // use a selector to generate an array of ids
    orderIDs.push(id);
  });

  // Now we have an orderIDs array that contains all orderID's that are in the state,
  // and a ordersByID dictionary where the key is our orderID and the value is the order data.
  const pagedOrder = {
    pages: {
      ...state.ordersByPage.pages,
      [params.pageIndex]: {
        orderIDs,
        isLoading: false,
      },
    },
    totalRecords: totalRecords, // We requested X orders from the API. IF we're getting less than that, then we must be done with orders.
  };

  return {
    ...state,
    ordersByID: { ...state.ordersByID, ...ordersByID },
    ordersByPage: {
      ...state.ordersByPage,
      ...pagedOrder,
    },
  };
};

export default handleActions(
  {
    [LOAD_ORDERS_REQUEST]: (state, action) => {
      return handleRequest(state, action);
    },
    [LOAD_ORDERS_SUCCESS]: (state, action) => {
      return normalizeOrders(state, action);
    },
    [LOAD_ORDER_SUCCESS]: (state, action) => {
      const { orderId } = action.payload;
      return {
        ...state,
        ordersByID: {
          ...state.ordersByID,
          [orderId]: action.payload,
        },
      };
    },
    [GENERATE_RMA_SUCCESS]: (state, action) => {
      return {
        ...state,
        returnData: { meta: action.meta, data: action.payload.data },
      };
    },
  },
  defaultState
);
