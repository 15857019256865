import config from 'config';

const getIsAnonymousServerSessionEnabled = req => {
  const {
    domain: { tld },
  } = req.context;
  const disabledAnonymousServerSessionByTLD = config.get(
    'server.bentoApi.disabledAnonymousServerSessionByTLD'
  );

  if (disabledAnonymousServerSessionByTLD[tld]) {
    return false;
  }

  return config.get('public.bentoApi.anonymousServerSession');
};

export default getIsAnonymousServerSessionEnabled;
