import { getCustomer } from '@techstyle/react-accounts';
import { getSession } from '@techstyle/redux-core';

import { TRACK_BUTTON_CLICK } from '../../actions/tracking';
import { baseTrackingData } from '../utils';

const trackButtonClicked = ({ getContext, trackEvent }) => {
  const trackButtonClicked = trackEvent((action, _prevState, nextState) => {
    const context = getContext();
    const { category, email: emailFromPayload, label, title } = action.payload;
    const { isLoggedIn } = getSession(nextState);
    const { email: emailFromState } = getCustomer(nextState);

    return {
      name: 'Button Clicked',
      properties: {
        ...baseTrackingData(nextState, context),
        category: category || '',
        email: emailFromPayload || (isLoggedIn ? emailFromState : null),
        label: label || null,
        title: title,
      },
    };
  });

  return {
    [TRACK_BUTTON_CLICK]: trackButtonClicked,
  };
};

export default trackButtonClicked;
