import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useFeature } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';

import { mobile } from '../../styles';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { ItemTypes } from '../../utils/search';
import { v1, v2 } from '../../utils/themeVersioning';
import { useCioAutoComplete } from '../../utils/useCioAutoComplete';
import { SearchProductItem } from '../SearchProductItem/SearchProductItem';

const Context = React.createContext();

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DropDown = styled.div`
  overflow: auto;
  position: absolute;
  /* this feels like it should be implemented differently */
  top: 44px;
  left: 0;
  right: 0;
  box-shadow: 0 1px 4px 0 ${({ theme }) => theme.colors.lightShadow1};
  z-index: -1;
  padding: ${props => (props.isOpen ? '14px 0' : '0')};
  background-color: ${props => props.theme.colors.white};

  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    height: auto;
    top: 60px;
    ${props =>
      props.shouldApplyMarginSearchResult ? `margin-left: 70px` : null}
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    height: auto;
    top: 50px;
    z-index: 0;
  }

  ${mobile`
    height: calc(100vh - 48px);
    margin-top: 48px;
    box-shadow: none;
    overflow-y: auto;
  `}
`;

const DropDownList = styled.div`
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: ${props => props.theme.breakpoints.small}px) {
    height: auto;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}px) {
    height: auto;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    height: auto;
    overflow-y: hidden;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: flex;
    ${props =>
      props.isNewDesktopSearchNavEnabled
        ? `flex-direction: row;`
        : `flex-direction: column;`}
    align-items: flex-start;
    font-size: 12px;
    overflow-y: hidden;
  }
`;

const SectionHeader = styled.span`
  display: block;
  padding: 0 22px;
  color: ${props => props.theme.colors.subdued};
  margin-bottom: ${props => props.theme.spacing.small}px;
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    margin: ${props => props.theme.spacing.small}px 0 0;
    line-height: 1.5;
    padding-left: 12px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xxlarge}px) {
    padding-left: 16px;
  }
`;

const ViewAllResultsText = styled.span`
  margin-right: ${props => props.theme.spacing.tiny}px;
  font-size: 12px;
  font-weight: 500;
  flex: 0 0 auto;
  position: relative;
  padding: 0 0 3px;
  background: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.active};
  text-decoration: none;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.active};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }

  ${mobile`
    padding: 0 ${props => props.theme.spacing.medium}px;
    font-size: 14px;
  `}
`;

const SuggestionWrapper = styled.div`
  display: block;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    width: 100%;
  }
`;

const SuggestionSectionHeader = styled(SectionHeader)`
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.xlarge - 1}px) {
    padding-top: ${props => props.theme.spacing.tiny}px;
  }
  text-align: left;

  ${v2`
      ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
      color: ${props => props.theme.colors.grey};
    `}
`;

const ProductSectionHeader = styled(SectionHeader)`
  margin: 12px 0 6px;
  padding-top: 12px;
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    text-align: left;
    line-height: 1.5;
    margin: 0 0 6px;
  }

  ${v2`
    ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
      color: ${props => props.theme.colors.grey};
    `}
`;

const ProductSearchResultsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    flex-direction: row;
    padding: 0 11px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    padding: 0 ${props => props.theme.spacing.tiny}px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xxlarge}px) {
    padding: 0 12px;
  }
`;

const SuggestedSearchResultsWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const SuggestionItem = styled.span`
  display: block;
  ${v1`
      color: ${props =>
        props.highlighted
          ? props.theme.colors.subdued
          : props.theme.colors.default};

      ${mobile`
        color: ${props =>
          props.highlighted
            ? props.theme.colors.subdued
            : props.theme.colors.lavender500};
      `}
    `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      `}
`;

const DropDownListItem = styled.div`
  line-height: 1.5;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    ${ProductSearchResultsWrapper} & {
      max-width: 33%;
    }
  }

  ${SuggestionItem} {
    margin-bottom: ${props => props.theme.spacing.small}px;
    padding-left: 22px;
    @media screen and (min-width: ${props =>
        props.theme.breakpoints.xlarge}px) {
      text-align: left;
      margin-bottom: 0;
      padding: 8px 12px 8px ${props => props.theme.spacing.small}px;
    }
  }

  &:last-child ${SuggestionItem} {
    @media screen and (min-width: ${props =>
        props.theme.breakpoints.xlarge}px) {
      text-align: left;
      margin-bottom: 0;
      padding: 8px 12px 8px ${props => props.theme.spacing.small}px;
    }
  }

  ${ViewAllResultsText} {
    margin-right: 4px;
  }

  cursor: pointer;
`;

const ViewAllResultsWrap = styled.div`
  color: ${props => props.theme.colors.active};
  margin-bottom: 12px;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    position: absolute;
    top: 12px;
    right: 22px;
  }

  ${mobile`
    margin-top: ${props => props.theme.spacing.tiny}px;
  `}
`;

const ChevronRightIcon = styled(FaChevronRight).attrs({
  role: 'presentation',
  size: 8,
})``;

const NoResultsText = styled.p`
  padding: 22px;
  font-weight: 500;
  line-height: 1.5;
`;

const ResultsWrap = styled.div`
  position: relative;
`;

const PaddedBottom = styled.div`
  margin-bottom: 80px;
`;

// Search input value query debounce function
function useDelayedValue(inputValue, delay = 300) {
  const [value, setValue] = useState(inputValue);
  const isChanging = inputValue !== value;

  useEffect(() => {
    if (isChanging) {
      if (delay) {
        const timeoutId = setTimeout(() => {
          setValue(inputValue);
        }, delay);

        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        setValue(inputValue);
      }
    }
  }, [delay, inputValue, isChanging]);

  return value;
}

export const SearchInput = ({
  children,
  focusOnMount,
  innerRef,
  isSearchActive,
  onBlur,
  onChange,
  onFocus,
  onHighlightedItemEnter,
  onNonHighlightedEnter,
  onTouchEnd,
  placeholder,
  shouldShowPlaceHolder,
  value,
  inputFocused,
}) => {
  const { 'constructor-search': constructorSearchEnabled } = useLDFlags();
  const { isEnabled: isNewDesktopSearchNavEnabled } =
    useFeature('desktop_nav_update');
  const { isBorderfreeCustomer } = useSelector(state => state.borderfree);
  const shouldApplyMarginSearchResult =
    shouldShowPlaceHolder &&
    isNewDesktopSearchNavEnabled &&
    !isBorderfreeCustomer;

  const [isForcedQuery, setForcedQuery] = useState(false);
  const inputQuery = useDelayedValue(
    inputFocused ? value : '',
    isForcedQuery ? 300 : 0
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { data: cioResults = {}, isInitialLoading: isAutoCompleteLoading } =
    useCioAutoComplete(inputQuery);

  const products = useMemo(() => {
    return (cioResults?.products || []).map(product => {
      return { ...product, itemType: ItemTypes.PRODUCT };
    });
  }, [cioResults.products]);

  const suggestions = useMemo(() => {
    return (cioResults?.suggestions || []).map(suggestion => {
      return {
        ...suggestion,
        itemType: ItemTypes.SUGGESTION,
      };
    });
  }, [cioResults.suggestions]);

  const handleChange = useCallback(
    incomingValue => {
      setForcedQuery(true);
      onChange(incomingValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (innerRef && innerRef.current && focusOnMount) {
      innerRef.current.focus();
    }
  }, [focusOnMount, innerRef]);

  const handleStateChange = useCallback(
    (changes, state) => {
      const { inputValue, selectedItem } = changes;
      if (
        typeof inputValue !== 'undefined' &&
        changes.type !== '__autocomplete_blur_input__' &&
        changes.type !== '__autocomplete_mouseup__' &&
        changes.type !== '__autocomplete_touchend__' &&
        changes.type !== 1 &&
        changes.type !== 16
      ) {
        handleChange(inputValue);
      }

      if (selectedItem) {
        // maybe it doesn't matter and we just send selected data?
        onHighlightedItemEnter({
          inputValue: inputValue || value,
          selectedItem,
        });
      }
    },
    [handleChange, onHighlightedItemEnter, value]
  );

  const renderSuggestedTextWrap = children =>
    children.length > 0 ? (
      <SuggestionWrapper>
        <SuggestionSectionHeader>
          <FormattedMessage
            id="search.quick_links"
            defaultMessage="Quick Links"
          />
        </SuggestionSectionHeader>
        <SuggestedSearchResultsWrapper>
          {children}
        </SuggestedSearchResultsWrapper>
      </SuggestionWrapper>
    ) : (
      <SuggestionWrapper />
    );

  const renderSuggestion = ({ highlighted, item }) => {
    return (
      <SuggestionItem highlighted={highlighted}>{item.value}</SuggestionItem>
    );
  };

  const renderProduct = ({ highlighted, item }) => {
    return (
      <SearchProductItem
        highlighted={highlighted}
        key={item.uid}
        product={item}
      />
    );
  };

  const renderProductItemWrap = children =>
    children.length > 0 ? (
      <>
        <ProductSectionHeader>
          <FormattedMessage
            id="search.featured_results"
            defaultMessage="Featured Results"
          />
        </ProductSectionHeader>
        <ProductSearchResultsWrapper>{children}</ProductSearchResultsWrapper>
      </>
    ) : null;

  const generateViewAllResultsItem = () => {
    return {
      itemType: ItemTypes.RESULTS_LINK,
      label: (
        <FormattedMessage
          id="search.view_all_results"
          defaultMessage="View all results"
        />
      ),
    };
  };

  const renderViewAllResults = ({ highlighted, item }) => {
    return (
      <>
        <ViewAllResultsText>{item.label}</ViewAllResultsText>
        <ChevronRightIcon />
      </>
    );
  };

  const renderMenuItems = useCallback(
    (getItemProps, highlightedIndex) => {
      const suggestionArray = [];
      const productArray = [];
      // suggestionsLength -> used to calculate offset for product index.
      // since they are two separate lists, their index both starts at 0
      const suggestionsLength = suggestions.length;
      // create a reference to ViewAllResults and then set it to an item if we have products
      let allProductResultsLink = null;

      if (suggestionsLength) {
        suggestions.forEach((item, index) => {
          const suggestionItem = (
            <DropDownListItem
              {...getItemProps({
                item,
              })}
              key={index}
              highlighted={index === highlightedIndex}
              {...(constructorSearchEnabled
                ? {
                    'data-cnstrc-autosuggest': true,
                    'data-cnstrc-item-section': 'Search Suggestions',
                    'data-cnstrc-item-name': item.value,
                  }
                : {})}
            >
              {renderSuggestion({
                highlighted: index === highlightedIndex,
                item,
              })}
            </DropDownListItem>
          );
          suggestionArray.push(suggestionItem);
        });
      }

      if (products.length > 0) {
        let offsetIndex;
        products.slice(0, 3).forEach((item, index) => {
          offsetIndex = suggestionsLength + index;
          const highlighted = offsetIndex === highlightedIndex;
          const productItem = (
            <DropDownListItem
              {...getItemProps({
                item,
              })}
              key={offsetIndex}
              highlighted={highlighted}
              {...(constructorSearchEnabled
                ? {
                    'data-cnstrc-autosuggest': true,
                    'data-cnstrc-item-section': 'Products',
                    'data-cnstrc-item-name': item.label,
                    'data-cnstrc-item-id': item.id,
                  }
                : {})}
            >
              {renderProduct({
                highlighted,
                item,
              })}
            </DropDownListItem>
          );
          productArray.push(productItem);
        });
        const highlighted = offsetIndex + 1 === highlightedIndex;
        const item = generateViewAllResultsItem();
        allProductResultsLink = (
          <ViewAllResultsWrap>
            <DropDownListItem
              {...getItemProps({
                item,
              })}
              key={offsetIndex + 1}
              highlighted={offsetIndex + 1 === highlightedIndex}
            >
              {renderViewAllResults({ item, highlighted })}
              <PaddedBottom />
            </DropDownListItem>
          </ViewAllResultsWrap>
        );
      }
      return (
        <>
          {renderSuggestedTextWrap(suggestionArray)}
          <ResultsWrap>
            {renderProductItemWrap(productArray)}
            {allProductResultsLink}
          </ResultsWrap>
        </>
      );
    },
    [products, suggestions]
  );

  const noResults =
    !products.length &&
    !suggestions.length &&
    value !== '' &&
    !isAutoCompleteLoading &&
    !inputQuery !== value;

  const renderNoResults = useCallback(() => {
    if (noResults) {
      return (
        <NoResultsText>
          <FormattedMessage
            id="search.no_results_query"
            defaultMessage={`No results for "{searchKey}"`}
            values={{
              searchKey: value,
            }}
          />
        </NoResultsText>
      );
    }
  }, [noResults, value]);

  function stateReducer(state, changes) {
    if (typeof changes.isOpen !== 'undefined') {
      setIsMenuOpen(changes.isOpen);
    }

    if (changes.type === Downshift.stateChangeTypes.blurInput) {
      return {};
    }
    // despite taking all changes, it knows that its `isOpen`
    // prop is controlled and is passed into the component
    // `changes` manages all others unless overwritten
    return changes;
  }

  const itemToString = item => {
    if (item) {
      if (item.itemType === ItemTypes.SUGGESTION) {
        return item.text || item.value;
      }
      if (item.itemType === ItemTypes.PRODUCT) {
        return item.name;
      }
    } else {
      return '';
    }
  };

  const handleDownshiftKeyDown = (event, highlightedIndex) => {
    if (event.key === 'Enter') {
      // Prevent Downshift's default 'Enter' behavior by uncommenting below.
      // event.nativeEvent.preventDownshiftDefault = true;
      if (highlightedIndex === null) {
        // means we're just simply sitting in the input
        // from here we can push the SRP and query the input value
        // this also is triggered when the product searched is clicked
        setIsMenuOpen(false);
        onNonHighlightedEnter({
          inputValue: value,
          selectedItem: null,
        });
      }
    }
  };

  return (
    <Downshift
      itemToString={itemToString}
      onStateChange={handleStateChange}
      stateReducer={stateReducer}
      inputValue={value}
      isOpen={isMenuOpen}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        highlightedIndex,
        isOpen,
      }) => (
        <Context.Provider
          value={{
            // Shared Downshift props
            getInputProps,
            getItemProps,
            getMenuProps,
            getRootProps,
            highlightedIndex,
            isOpen,

            // SearchInputField props
            handleDownshiftKeyDown,
            innerRef,
            isSearchActive,
            onBlur,
            onFocus,
            placeholder,
            shouldShowPlaceHolder,
          }}
        >
          <InputWrapper {...getRootProps({}, { suppressRefError: true })}>
            {typeof children === 'function'
              ? children({
                  loading: isAutoCompleteLoading,
                })
              : children}
            {isOpen ? (
              <DropDown
                shouldApplyMarginSearchResult={shouldApplyMarginSearchResult}
                onTouchEnd={onTouchEnd}
              >
                <DropDownList
                  {...getMenuProps({}, { suppressRefError: true })}
                  isOpen={isOpen}
                  isNewDesktopSearchNavEnabled={isNewDesktopSearchNavEnabled}
                  {...(constructorSearchEnabled
                    ? { 'data-cnstrc-autosuggest': true }
                    : {})}
                >
                  {renderMenuItems(getItemProps, highlightedIndex)}
                  {renderNoResults()}
                </DropDownList>
              </DropDown>
            ) : null}
          </InputWrapper>
        </Context.Provider>
      )}
    </Downshift>
  );
};

SearchInput.defaultProps = {
  focusOnMount: false,
  onTouchEnd: () => {},
};

SearchInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /*
   * Boolean for if we should focus when mounted
   */
  focusOnMount: PropTypes.bool,
  /*
   * Ref to field passed from parent to enable .focus()
   */
  innerRef: PropTypes.object,
  /*
   * Boolean that indicates if search is active
   */
  isSearchActive: PropTypes.bool,
  isVip: PropTypes.bool,
  /*
   * Function callback for when Change happens
   */
  onChange: PropTypes.func,
  /*
   * Function callback for when component is blurred
   */
  onBlur: PropTypes.func,
  /*
   * Function callback for when Focus happens
   */
  onFocus: PropTypes.func,
  /**
   * Function that calls back if enter is press on a highlighted item
   */
  onHighlightedItemEnter: PropTypes.func,
  /**
   * Function that calls back if enter is pressed without having highlighted an item
   */
  onNonHighlightedEnter: PropTypes.func,
  /**
   * Function that calls back after mobile user stops scrolling dropdown
   */
  onTouchEnd: PropTypes.func,
  /**
   * Input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Search query product results
   */
  productResults: PropTypes.object,
  /**
   * Search query suggestions
   */
  searchSuggestions: PropTypes.object,
  tld: PropTypes.string,
  /**
   * Value of the input
   */
  value: PropTypes.string.isRequired,
  inputFocused: PropTypes.bool,
  shouldShowPlaceHolder: PropTypes.bool,
};

SearchInput.Context = Context;
