import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useHeaderStatus } from '../../utils/useHeaderStatus';

const ToastWrapper = styled.div`
  line-height: 40px;
  background-color: ${props => props.theme.colors.active};
  color: ${props => props.theme.colors.white};
  position: fixed;
  width: 100%;
  text-align: center;
  top: ${props => props.$topPosition ?? 0}px;
  left: 0;
  z-index: ${props => props.theme.zIndex.toast};
  cursor: pointer;
`;

const LegacyToast = ({
  show,
  onHide,
  delay = 2000,
  children,
  className,
  positionBelowNav,
}) => {
  const { headerHeight } = useHeaderStatus();
  const [isShown, setIsShown] = useState(show);
  const timeoutRef = useRef(null);

  const delayedHide = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onHide();
    }, delay);
  }, [delay, onHide]);

  useEffect(() => {
    if (isShown) {
      delayedHide();
    }
  }, [delayedHide, isShown]);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return isShown ? (
    <ToastWrapper
      onClick={onHide}
      className={className}
      $topPosition={positionBelowNav ? headerHeight : 0}
    >
      {children}
    </ToastWrapper>
  ) : null;
};

LegacyToast.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  delay: PropTypes.number,
  onHide: PropTypes.func.isRequired,
  positionBelowNav: PropTypes.bool,
  show: PropTypes.bool.isRequired,
};

export default memo(LegacyToast);
