import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLocalStorage, useSession } from '@techstyle/redux-core';

import { trackIdentifyWithTraits } from '../../actions/account';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { StoredQueryParams } from '../StoredQueryParams';

const localStorageKey = 'clickIds';

/**
 * Tracks the click ids from the query params in local storage.
 * When the user is logged in, the clickIds will be sent to Segment. Once sent
 * to Segment, the clickIds will be cleared from local storage to prevent
 * clickIds from being associated with multiple accounts.
 */
export function TrackedClickIds() {
  const dispatch = useDispatch();
  const { 'tracked-click-ids': trackedClickIds } = useLDFlags();

  const { isLoggedIn } = useSession();
  const [queryParamsStored, setQueryParamsStored] = useState(false);
  // TODO: Add a Proxy to localStorage to help with syncing?
  // Since `useLocalStorage` doesn't sync when there are updates to `localStorage`, we need
  // to only track the `localStorage` key _after_ we know that the query params could have been
  // stored by `StoredQueryParams`.
  const [clickIds, setClickIds] = useLocalStorage(
    queryParamsStored ? localStorageKey : undefined
  );

  const setHasQueryParamsStored = useCallback(() => {
    setQueryParamsStored(true);
  }, []);

  const clearClickIds = useCallback(() => {
    // @ts-ignore - type information coming from `useLocalStorage` is not correct
    setClickIds(undefined);
  }, [setClickIds]);

  useEffect(() => {
    if (isLoggedIn && queryParamsStored && clickIds) {
      const clickIdsToSend = {};
      Object.keys(clickIds).forEach(clickIdProvider => {
        clickIdsToSend[`clickId.${clickIdProvider}`] =
          clickIds[clickIdProvider];
      });
      dispatch(trackIdentifyWithTraits({ ...clickIdsToSend }));
      clearClickIds();
    }
  }, [dispatch, isLoggedIn, queryParamsStored, clickIds, clearClickIds]);

  if (!trackedClickIds) {
    return null;
  }

  return (
    <StoredQueryParams
      storageKey={localStorageKey}
      params={trackedClickIds || []}
      initialOnly
      onStored={setHasQueryParamsStored}
    />
  );
}
