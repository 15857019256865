export const LOAD_REVIEWABLE_PRODUCTS_REQUEST =
  'LOAD_REVIEWABLE_PRODUCTS_REQUEST';
export const LOAD_REVIEWABLE_PRODUCTS_SUCCESS =
  'LOAD_REVIEWABLE_PRODUCTS_SUCCESS';
export const LOAD_REVIEWABLE_PRODUCTS_FAILURE =
  'LOAD_REVIEWABLE_PRODUCTS_FAILURE';

export const LOAD_REVIEWABLE_PRODUCTS_DETAILS_REQUEST =
  'LOAD_REVIEWABLE_PRODUCTS_DETAILS_REQUEST';
export const LOAD_REVIEWABLE_PRODUCTS_DETAILS_SUCCESS =
  'LOAD_REVIEWABLE_PRODUCTS_DETAILS_SUCCESS';
export const LOAD_REVIEWABLE_PRODUCTS_DETAILS_FAILURE =
  'LOAD_REVIEWABLE_PRODUCTS_DETAILS_FAILURE';

export const SAVE_PRODUCT_REVIEW_REQUEST = 'SAVE_PRODUCT_REVIEW_REQUEST';
export const SAVE_PRODUCT_REVIEW_SUCCESS = 'SAVE_PRODUCT_REVIEW_SUCCESS';
export const SAVE_PRODUCT_REVIEW_FAILURE = 'SAVE_PRODUCT_REVIEW_FAILURE';

export const UPLOAD_REVIEW_PHOTO_REQUEST = 'UPLOAD_REVIEW_PHOTO_REQUEST';
export const UPLOAD_REVIEW_PHOTO_SUCCESS = 'UPLOAD_REVIEW_PHOTO_SUCCESS';
export const UPLOAD_REVIEW_PHOTO_FAILURE = 'UPLOAD_REVIEW_PHOTO_FAILURE';

const NOT_SIGNED_IN = new Error('Not signed in');

export function loadReviewableProducts() {
  return async (dispatch, getState) => {
    const { membership } = getState();
    if (membership.fetchedDate && membership.isVisitor) {
      return dispatch({
        type: LOAD_REVIEWABLE_PRODUCTS_FAILURE,
        error: true,
        payload: NOT_SIGNED_IN,
      });
    }
    return dispatch({
      bentoApi: {
        endpoint: `accounts/me/reviews/products`,
        method: 'GET',
        actions: [
          LOAD_REVIEWABLE_PRODUCTS_REQUEST,
          LOAD_REVIEWABLE_PRODUCTS_SUCCESS,
          LOAD_REVIEWABLE_PRODUCTS_FAILURE,
        ],
      },
    });
  };
}

export function loadReviewsOptions(productId) {
  return async (dispatch, getState) => {
    const { membership } = getState();
    if (membership.fetchedDate && membership.isVisitor) {
      return dispatch({
        type: LOAD_REVIEWABLE_PRODUCTS_DETAILS_FAILURE,
        error: true,
        payload: NOT_SIGNED_IN,
      });
    }
    return dispatch({
      bentoApi: {
        endpoint: `products/${productId}/review/data`,
        method: 'GET',
        actions: [
          LOAD_REVIEWABLE_PRODUCTS_DETAILS_REQUEST,
          LOAD_REVIEWABLE_PRODUCTS_DETAILS_SUCCESS,
          LOAD_REVIEWABLE_PRODUCTS_DETAILS_FAILURE,
        ],
      },
    });
  };
}

export function saveReview(params) {
  return async dispatch => {
    const { photoData, ...reviewParams } = params;
    const request = {
      bentoApi: {
        endpoint: 'accounts/me/reviews',
        method: 'POST',
        body: JSON.stringify(reviewParams),
        actions: [
          SAVE_PRODUCT_REVIEW_REQUEST,
          SAVE_PRODUCT_REVIEW_SUCCESS,
          SAVE_PRODUCT_REVIEW_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);
    if (photoData) {
      dispatch(uploadPhoto(params.productId, photoData));
    }
    return result;
  };
}

export function uploadPhoto(productId, base64DataUrl) {
  return async dispatch => {
    const result = await dispatch({
      bentoApi: {
        endpoint: `accounts/me/products/${productId}/images`,
        method: 'POST',
        body: JSON.stringify({ base64DataUrl }),
        actions: [
          UPLOAD_REVIEW_PHOTO_REQUEST,
          UPLOAD_REVIEW_PHOTO_SUCCESS,
          UPLOAD_REVIEW_PHOTO_FAILURE,
        ],
      },
    });
    return result;
  };
}
