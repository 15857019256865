import React from 'react';

import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import styled from 'styled-components';

import { Component as HoverDelay } from '../HoverDelay';

const DefaultItem = styled.li``;

const CollapseArrow = styled(FaChevronDown)`
  font-size: 10px;
  margin-left: 8px;
  min-width: 10px;

  ${({ isActive }) => isActive && `transform: rotate(180deg);`};
  transition: all 0.2s ease-out;
`;

function HoverMenuItem({
  children,
  className,
  component,
  dataAutotag,
  index,
  isActive,
  onEnter,
  label,
  showChevron,
}) {
  const ItemComponent = component || DefaultItem;
  return (
    <HoverDelay onEnter={onEnter} value={{ index, children }}>
      <ItemComponent
        className={className}
        isActive={isActive}
        data-autotag={dataAutotag}
      >
        <>
          {label}
          {showChevron && <CollapseArrow isActive={isActive} />}
        </>
      </ItemComponent>
    </HoverDelay>
  );
}

HoverMenuItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.any,
  dataAutotag: PropTypes.string,
  showChevron: PropTypes.bool,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onEnter: PropTypes.func,
  label: PropTypes.node,
};

export default HoverMenuItem;
