import React from 'react';

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const SpinnerWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const SpinnerSizeWrapper = styled.span`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.span`
  animation: ${rotate} 400ms infinite linear;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.whiteSubdued};
  border-right-color: ${props =>
    props.theme.colors[props.color] || props.color};
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  height: 100%;
`;

const BackgroundFade = styled.span`
  display: ${props => (props.shouldFadeBackground ? 'block' : 'none')};
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  pointer-events: none;
`;

function SpinningLoader({
  className,
  height,
  isLoading,
  shouldFadeBackground,
  width,
  color,
}) {
  if (!isLoading) {
    return null;
  }

  return (
    <SpinnerWrapper className={className}>
      <BackgroundFade shouldFadeBackground={shouldFadeBackground} />
      <SpinnerSizeWrapper width={width} height={height}>
        <Spinner color={color} />
      </SpinnerSizeWrapper>
    </SpinnerWrapper>
  );
}

SpinningLoader.propTypes = {
  /**
   * Spinner height in px
   */
  height: PropTypes.number,
  /**
   * Display if isLoading is true, hide if false
   */
  isLoading: PropTypes.bool,
  /**
   * Apply background with reduced opacity to parent element
   */
  shouldFadeBackground: PropTypes.bool,
  /**
   * Spinner width in px
   */
  width: PropTypes.number,
  /**
   * Color of spinner
   */
  color: PropTypes.string,
  /**
   * class name of spinner
   */
  className: PropTypes.string,
};

SpinningLoader.defaultProps = {
  height: 24,
  isLoading: false,
  shouldFadeBackground: false,
  width: 24,
  color: 'active',
};

export default SpinningLoader;
