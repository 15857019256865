import { handleActions } from 'redux-actions';

import { GET_CMS_DATA_SUCCESS } from '../actions/cms';

const defaultState = {
  collection: {
    // key - collection handle
    // value - the object of the collection from
  },
};

export default handleActions(
  {
    [GET_CMS_DATA_SUCCESS]: (state, action) => {
      const collectionHandle = action.meta.collection;
      if (!action.payload || action.payload.error) {
        return state;
      }
      return {
        ...state,
        collection: {
          ...state.collection,
          [collectionHandle]: action.payload.data,
        },
      };
    },
  },

  defaultState
);
