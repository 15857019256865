import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { AssetContainer, HTMLAsset } from '@techstyle/react-assets';
import { TextLink } from '@techstyle/react-components';

import { trackNavItemClick } from '../../actions/navigation';
import { v1, v2 } from '../../utils/themeVersioning';
import { useTrackPromotionClick } from '../../utils/useTrackPromotionClick';
import { useTrackPromotionViewed } from '../../utils/useTrackPromotionViewed';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import { EnterViewport } from '../EnterViewport';

const HTMLContent = styled(HTMLAsset)`
  width: ${({ widthMultiplier }) => 212 * widthMultiplier}px;
  margin-top: 4px;
  ${v1`
    font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
    `}

  ${v2`
    ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
  `}
`;

const AssetWrapper = styled.div`
  margin-left: 20px;
  min-width: 212px;
  max-width: 424px;
`;

const Container = styled.div`
  display: flex;

  ${AssetWrapper}:first-child {
    margin-left: 68px;
  }
`;

const Img = styled.img`
  display: block;
  max-width: ${({ widthMultiplier }) => `${212 * widthMultiplier}px`};
  height: 303px;
  object-fit: 'cover';
`;

const StyledLink = styled.a`
  display: block;
  cursor: pointer;
`;

const StyledTextLinkBold = styled(TextLink)`
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  margin-top: 8px;
  display: block;

  ${v1`
    font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: ${props => props.theme.colors.default};
  background: transparent;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 14px;
    `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      `}
`;

const CtaOnlyText = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: ${props => props.theme.colors.default};
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.spacing.tiny}px;
  line-height: 14px;
`;

export default function NavAssets({ assets }) {
  const dispatch = useDispatch();
  const dispatchTrackPromotionViewed = useTrackPromotionViewed();
  const dispatchTrackPromotionClicked = useTrackPromotionClick();

  let assetSlotCount = 0;

  const renderAsset = asset => {
    // dont show asset if assetSlotCount is greater than 3
    if (!asset || !asset.data || assetSlotCount > 3) {
      return null;
    }

    const { imageFilename, options } = asset.data;
    const hasLinkText = options.targetUrl && options.customVars.ctaText;
    const { hideFromBorderfree, slotCount } = options.customVars;
    const altText = options.customVars.altText || null;
    // Treat asset with slotCount of 2 as 2 assets
    // This will prevent showing the third asset if one of the first two assets
    // has slotCount of 2
    if (slotCount >= 2) {
      assetSlotCount += 2;
    }

    const widthMultiplier = slotCount > 1 ? 2 : 1;
    const onEnterViewport = () => dispatchTrackPromotionViewed(asset.data);

    const renderImg = (
      <Img
        src={imageFilename}
        alt={altText}
        widthMultiplier={widthMultiplier}
      />
    );

    const sendGaEvent = () => {
      const gaLabel = asset.data.container.name;
      const gaAction = 'Main Nav Marketing Card';
      dispatch(trackNavItemClick(gaAction, gaLabel));
    };

    const onAssetClick = () => {
      sendGaEvent();
      dispatchTrackPromotionClicked(asset.data);
    };

    const renderedAsset = (
      <AssetWrapper>
        <EnterViewport once onEnterViewport={onEnterViewport} />
        {options.targetUrl ? (
          <Link route={options.targetUrl}>
            <StyledLink onClick={onAssetClick}>{renderImg}</StyledLink>
          </Link>
        ) : (
          renderImg
        )}
        {hasLinkText && (
          <Link route={options.targetUrl} passHref>
            <StyledTextLinkBold onClick={onAssetClick}>
              {options.customVars.ctaText}
            </StyledTextLinkBold>
          </Link>
        )}
        {!hasLinkText && options.customVars.ctaText ? (
          <CtaOnlyText>{options.customVars.ctaText}</CtaOnlyText>
        ) : null}
        <HTMLContent asset={asset.data} widthMultiplier={widthMultiplier} />
      </AssetWrapper>
    );

    if (hideFromBorderfree) {
      return <BorderfreeGuard>{renderedAsset}</BorderfreeGuard>;
    }

    return renderedAsset;
  };

  return assets.length ? (
    <Container>
      {assets.map(container => (
        <AssetContainer name={container} key={container}>
          {renderAsset}
        </AssetContainer>
      ))}
    </Container>
  ) : null;
}

NavAssets.propTypes = {
  assets: PropTypes.array,
};
