import React from 'react';

export default class ASCIILogoComment extends React.PureComponent {
  render() {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `
/*******************vjh***********dbb*******************gjl****************slm*****************as*****************************cpr***************jmc*********jg*********
 ****bmb*********************gjl*************pam********************kwb************************fgg*********jdn***************************csw********eo*****************
 ********crc***************************jnw*********jky******************************tjh*******************************************tsw*********************rjf**********
 ***                                                                                                                                                                ***
 ***                                                                                                                                                                ***
 ***        SSSSSSSSSSSSSSS              AAA   VVVVVVVV           VVVVVVVV   AAA                  GGGGGGGGGGGGGEEEEEEEEEEEEEEEEEEEEEE     XXXXXXX       XXXXXXX     ***
 ***      SS:::::::::::::::S            A:::A  V::::::V           V::::::V  A:::A              GGG::::::::::::GE::::::::::::::::::::E     X:::::X       X:::::X     ***
 ***     S:::::SSSSSS::::::S           A:::::A V::::::V           V::::::V A:::::A           GG:::::::::::::::GE::::::::::::::::::::E     X:::::X       X:::::X     ***
 ***     S:::::S     SSSSSSS          A:::::::AV::::::V           V::::::VA:::::::A         G:::::GGGGGGGG::::GEE::::::EEEEEEEEE::::E     X::::::X     X::::::X     ***
 ***     S:::::S                     A:::::::::AV:::::V           V:::::VA:::::::::A       G:::::G       GGGGGG  E:::::E       EEEEEE     XXX:::::X   X:::::XXX     ***
 ***     S:::::S                    A:::::A:::::AV:::::V         V:::::VA:::::A:::::A     G:::::G                E:::::E                     X:::::X X:::::X        ***
 ***      S::::SSSS                A:::::A A:::::AV:::::V       V:::::VA:::::A A:::::A    G:::::G                E::::::EEEEEEEEEE            X:::::X:::::X         ***
 ***       SS::::::SSSSS          A:::::A   A:::::AV:::::V     V:::::VA:::::A   A:::::A   G:::::G    GGGGGGGGGG  E:::::::::::::::E             X:::::::::X          ***
 ***         SSS::::::::SS       A:::::A     A:::::AV:::::V   V:::::VA:::::A     A:::::A  G:::::G    G::::::::G  E:::::::::::::::E             X:::::::::X          ***
 ***            SSSSSS::::S     A:::::AAAAAAAAA:::::AV:::::V V:::::VA:::::AAAAAAAAA:::::A G:::::G    GGGGG::::G  E::::::EEEEEEEEEE            X:::::X:::::X         ***
 ***                 S:::::S   A:::::::::::::::::::::AV:::::V:::::VA:::::::::::::::::::::AG:::::G        G::::G  E:::::E                     X:::::X X:::::X        ***
 ***                 S:::::S  A:::::AAAAAAAAAAAAA:::::AV:::::::::VA:::::AAAAAAAAAAAAA:::::AG:::::G       G::::G  E:::::E       EEEEEE     XXX:::::X   X:::::XXX     ***
 ***     SSSSSSS     S:::::S A:::::A             A:::::AV:::::::VA:::::A             A:::::AG:::::GGGGGGGG::::GEE::::::EEEEEEEE:::::E     X::::::X     X::::::X     ***
 ***     S::::::SSSSSS:::::SA:::::A               A:::::AV:::::VA:::::A               A:::::AGG:::::::::::::::GE::::::::::::::::::::E     X:::::X       X:::::X     ***
 ***     S:::::::::::::::SSA:::::A                 A:::::AV:::VA:::::A                 A:::::A GGG::::::GGG:::GE::::::::::::::::::::E     X:::::X       X:::::X     ***
 ***      SSSSSSSSSSSSSSS AAAAAAA                   AAAAAAAVVVAAAAAAA                   AAAAAAA   GGGGGG   GGGGEEEEEEEEEEEEEEEEEEEEEE     XXXXXXX       XXXXXXX     ***
 ***                                                                                                                                                                ***
 ***                                                                                                                                                                ***
 ***********amb**************mwb********************gr*********dcd*******************************dkp************************egp**************jnh***************bks*****
 ***hml***************************mlm********************tjs**************baj*****************************************tlc*******************************jss************
 ****jjs*******************kmm*************************mcv***************************nck***********************mln**************************pdl***********************/`,
        }}
      />
    );
  }
}
