import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  updateCustomerDetail,
  useCustomerDetails,
  useMembership,
  useMembershipDetails,
} from '@techstyle/react-accounts';
import { useIntl } from '@techstyle/react-intl';
import { updateSessionDetail, useSessionDetail } from '@techstyle/redux-core';

import { desktop } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { Component as EmpUserAcceptanceCarousel } from '../EmpUserAcceptanceCarousel';

const dialogStyle = css`
  ${desktop`
    width: 570px;
    height: 400px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
    position: relative;
  `}
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 420px;
  border-radius: 8px 8px 0 0;
  padding: ${({ theme }) => theme.spacing.moderate}px
    ${({ theme }) => theme.spacing.tiny}px;
  box-shadow: 0px -2px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
  position: fixed;
  bottom: 0;
`;

const overlayStyle = css`
  background: ${props => props.theme.colors.scrim};
`;

const StyledModal = styled(BasicModal).attrs(props => ({
  springConfig: {
    tension: 230,
  },
  dialogStyle,
  overlayColor: props.theme.colors.scrim,
  transitionFrom: {
    opacity: 0,
    transform: 'translate3d(0, 100%, 0)',
  },
  transitionEnter: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
  },
  transitionLeave: {
    opacity: 0,
    transform: 'translate3d(0, 100%, 0)',
  },
}))``;

const ModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

export default function EmpUserAcceptanceModal({ onStateChange }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const router = useRouter();
  const { isVip } = useMembership();
  const nmpRequestName = 'nmp_migration';

  const [hasModalBeenSeen, isUserOptedIn] = useCustomerDetails([
    'emp_onboarding_prompt_seen',
    'nmp_opt_in_true',
  ]);

  const nmpMigration = useMembershipDetails([nmpRequestName]);

  const empOnboardingSeenSession = useSessionDetail(
    'emp_onboarding_prompt_seen'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wasModalExited, setWasModalExited] = useState(false);

  const { 'emp-membership-onboarding': isEmpFeatureEnabled } = useLDFlags();

  const isTCPage = router.pathname === '/termsandconditions';
  const hasNmpMigration = nmpMigration[0];
  const hasModalBeenSeenThisSession =
    empOnboardingSeenSession &&
    empOnboardingSeenSession.networkStatus.isUpToDate &&
    empOnboardingSeenSession.value;

  useEffect(() => {
    const shouldSkipOnboarding =
      !isVip ||
      !isEmpFeatureEnabled ||
      !hasNmpMigration ||
      isUserOptedIn ||
      isTCPage ||
      hasModalBeenSeenThisSession ||
      wasModalExited;

    const shouldOpen = !shouldSkipOnboarding;

    if (
      hasModalBeenSeen !== undefined &&
      empOnboardingSeenSession.networkStatus.isUpToDate
    ) {
      setIsModalOpen(shouldOpen);
      onStateChange(shouldOpen);
    }
  }, [
    isVip,
    isEmpFeatureEnabled,
    hasNmpMigration,
    hasModalBeenSeen,
    hasModalBeenSeenThisSession,
    isUserOptedIn,
    isTCPage,
    wasModalExited,
    onStateChange,
    empOnboardingSeenSession,
  ]);

  const handleModalExit = () => {
    setWasModalExited(true);
    dispatch(
      updateSessionDetail({
        name: 'emp_onboarding_prompt_seen',
        value: 'true',
      })
    );
    dispatch(
      updateCustomerDetail({
        name: 'emp_onboarding_prompt_seen',
        value: 'true',
        dateTimeAdded: null,
      })
    );
    setIsModalOpen(false);
  };

  return (
    <StyledModal
      isOpen={isModalOpen}
      onExit={handleModalExit}
      title={intl.formatMessage({
        id: 'site_emp_onboarding.modal_title',
        defaultMessage: 'Xtra VIP Membership User Acceptance Modal',
      })}
      overlayClickExits={false}
      escapeExits={false}
      overlayStyle={overlayStyle}
    >
      <ModalContent>
        <EmpUserAcceptanceCarousel
          hasModalBeenSeen={hasModalBeenSeen}
          onCloseModal={handleModalExit}
        />
      </ModalContent>
    </StyledModal>
  );
}

EmpUserAcceptanceModal.propTypes = {
  onStateChange: PropTypes.func.isRequired,
};
