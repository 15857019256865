import { memo, useEffect } from 'react';

import Router from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useCookies } from '@techstyle/redux-core';

const BorderfreeGuard = ({ children, redirectBorderfreeTo }) => {
  const [cookies] = useCookies(['no_redirect']);
  const { isBorderfreeCustomer, noRedirect } = useSelector(
    state => state.borderfree
  );

  useEffect(() => {
    if (noRedirect || cookies.no_redirect === 'true') {
      return undefined;
    }

    if (isBorderfreeCustomer && redirectBorderfreeTo) {
      Router.push(redirectBorderfreeTo);
    }
  }, [cookies, isBorderfreeCustomer, noRedirect, redirectBorderfreeTo]);

  return !isBorderfreeCustomer ? children : null;
};

BorderfreeGuard.propTypes = {
  children: PropTypes.node,
  redirectBorderfreeTo: PropTypes.string,
};

export default memo(BorderfreeGuard);
