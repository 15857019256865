import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from 'config';
import { resetIdCounter } from 'downshift';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import 'lazysizes';
import 'focus-visible';

import { AssetFilter, loadAssets } from '@techstyle/react-assets';
import {
  AppElement,
  MountedProvider,
} from '@techstyle/react-components-legacy';

// Document dependencies.
import { Component as ADAWidgetProvider } from './src/components/ADAWidgetProvider';
import { Component as ASCIILogoComment } from './src/components/ASCIILogoComment';
import { Component as AutoSaveSizes } from './src/components/AutoSaveSizes';
import { Component as BorderfreeGuard } from './src/components/BorderfreeGuard';
import { Component as ByoProvider } from './src/components/ByoProvider';
import { Component as ByoRoutingGuard } from './src/components/ByoRoutingGuard';
import { Component as CancelConfirmationModalProvider } from './src/components/CancelConfirmationModalProvider';
import { Component as CashAppProvider } from './src/components/CashAppProvider';
import { CioClientProvider } from './src/components/CioClientProvider/CioClientProvider';
import { CountdownTimerProvider } from './src/components/CountdownTimerProvider/CountdownTimerProvider';
import { CpraLocation } from './src/components/CrpaLocation';
import { Component as DataLayer } from './src/components/DataLayer';
import { Container as DefaultAssetFilter } from './src/components/DefaultAssetFilter';
import { Component as DefaultHead } from './src/components/DefaultHead';
import DynamicThemeProvider from './src/components/DynamicThemeProvider/DynamicThemeProvider';
import { Component as ForceEcho } from './src/components/ForceEcho';
import { Component as ForceNoRedirect } from './src/components/ForceNoRedirect';
import { Component as GlobalHeaderStateProvider } from './src/components/GlobalHeaderStateProvider';
import { Component as LuckyOrangeSnippet } from './src/components/LuckyOrangeSnippet';
import { Component as MemberCreditsDrawerModalProvider } from './src/components/MemberCreditsDrawerModalProvider';
import { Component as MembershipGuard } from './src/components/MembershipGuard';
import { Component as MetaNavProvider } from './src/components/MetaNavProvider';
import { Component as PageLoadingIndicator } from './src/components/PageLoadingIndicator';
import { Component as PaymentMethodProvider } from './src/components/PaymentMethodProvider';
import PsourceProvider from './src/components/PsourceProvider/PsourceProvider';
import { Component as QuickViewProvider } from './src/components/QuickViewProvider';
import { Container as RenderTime } from './src/components/RenderTime';
import { Component as RouteContext } from './src/components/RouteContext';
import { Component as SpeedySignUp } from './src/components/SpeedySignUp';
import ToastContext from './src/components/ToastContext/ToastContext';
import { TrackedClickIds } from './src/components/TrackedClickIds/TrackedClickIds';
import { Container as TrackPageRenders } from './src/components/TrackPageRenders';
import { Component as UKOneHourPromosExpire } from './src/components/UKOneHourPromosExpire';
import { VideoMessageModal } from './src/components/VideoMessageModal';
import rootModule from './src/store/rootModule';
import { redirect } from './src/utils/routing';

const enableAssetPrefetch = config.get('public.assets.prefetch');

const DebugTools = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './src/components/DebugTools')
);

const ReactQueryDevTools = dynamic(() =>
  import(
    /* webpackChunkName: "ReactQueryDevTools" */ './src/components/ReactQueryDevTools/ReactQueryDevTools'
  )
);

const ConfigAdminPanel = dynamic(() =>
  import(
    /* webpackChunckName: "ConfigAdminPanel" */ './src/components/ConfigAdminPanel'
  )
);

const RootAppElement = styled(AppElement)`
  position: relative;
  z-index: 0;
`;

// FIXME: once Safari implements :focus-visible, we can remove this component entirely (along
// with the `import 'focus-visible'` up top) and simply move the CSS declarations to the
// global stylesheet (keep only the `:focus-visible` declarations and remove the `.focus-visible`
// ones). Keep up to date with Safari support here: https://caniuse.com/?search=focus-visible
const FocusVisible = styled.div`
  &.js-focus-visible :focus:not(.focus-visible),
  :focus:not(:focus-visible) {
    outline: none;
  }
  &.js-focus-visible .focus-visible,
  :focus-visible {
    outline: 2px solid ${props => props.theme.colors.active};
  }
`;

/**
 * @type {import('@tanstack/react-query').QueryClientConfig}
 */
const reactQueryConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // Retries are handled by our custom `fetch` client
      retry: false,
    },
  },
};

// Note: This will work for client side queries, if we want to use `react-query` during SSR
// to do prefetching, this will need to be rewritten into a custom extension so the same
// query client can be used across the different parts of `getInitialProps`
const withReactQueryProvider = App => {
  const getInitialProps = App.getInitialProps;

  function WithReactQueryProvider(props) {
    const [queryClient] = React.useState(
      () => new QueryClient(reactQueryConfig)
    );

    return (
      <QueryClientProvider client={queryClient}>
        <App {...props} />
      </QueryClientProvider>
    );
  }

  if (getInitialProps) {
    WithReactQueryProvider.getInitialProps = getInitialProps;
  }

  return WithReactQueryProvider;
};

export default function savagexExtension() {
  return {
    id: 'savagexExtension',
    redux: {
      modules: [rootModule],
    },
    server: process.browser
      ? undefined
      : {
          configure(server) {
            const tokenEnabledRouteMiddleware = require('./server/tokenEnabledRouteMiddleware');

            const {
              getRedirectAuthSendIframe,
              getRedirectAuthReturnIframe,
            } = require('./src/utils/adyen');

            server.use(tokenEnabledRouteMiddleware);

            server.get('/checkout/authorize3d', (req, res) => {
              res.send(getRedirectAuthSendIframe());
            });
            server.post('/checkout/authorize3d', (req, res) => {
              res.send(getRedirectAuthReturnIframe(req.body));
            });
          },
        },
    admin: {
      panels: [
        <DebugTools key="savagex" />,
        <ReactQueryDevTools key="rq" />,
        <ConfigAdminPanel key="config" />,
      ],
    },
    document: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async ctx => {
                // this is from Downshift
                // used to generate new ids
                // so the SSR and Client match
                // https://github.com/downshift-js/downshift#resetidcounter
                resetIdCounter();

                const initialProps = await getInitialProps(ctx);
                const { requestInfo } = ctx.store.getState().marketing;

                initialProps.headTop.push(
                  <>
                    <ASCIILogoComment />
                    <link
                      rel="apple-touch-icon"
                      sizes="180x180"
                      href="/static/favicon/apple-touch-icon.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="32x32"
                      href="/static/favicon/favicon-32x32.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="16x16"
                      href="/static/favicon/favicon-16x16.png"
                    />
                    <link
                      rel="icon"
                      type="image/svg+xml"
                      href="/static/favicon/favicon.svg"
                    />
                    <link
                      rel="manifest"
                      href="/static/favicon/site.webmanifest"
                    />
                    <link
                      rel="mask-icon"
                      href="/static/favicon/safari-pinned-tab.svg"
                      color="#000000"
                    />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                  </>
                );

                // Set `<body>` attributes.
                if (requestInfo.borderfreeEnabled) {
                  let className = 'bfx-price-container';
                  if (initialProps.bodyProps.className) {
                    className += ` ${initialProps.bodyProps.className}`;
                  }
                  initialProps.bodyProps.className = className;
                }

                return initialProps;
              };
            },
          },
        },
    app: {
      enhance(App) {
        return withReactQueryProvider(App);
      },
      render(_props, children) {
        return (
          // Leave these two providers, MountedProvider and RenderTime, until
          // `@techstyle/react-components` is migrated to `useHydrationStatus`
          // and `useRenderTime` from `@techstyle/redux-core`.
          <MountedProvider>
            <RenderTime>
              <DynamicThemeProvider>
                <CioClientProvider>
                  <CountdownTimerProvider>
                    <PaymentMethodProvider>
                      <CashAppProvider>
                        <ADAWidgetProvider>
                          <ByoProvider>
                            <PsourceProvider>
                              <CancelConfirmationModalProvider>
                                <MemberCreditsDrawerModalProvider>
                                  <QuickViewProvider>
                                    <MetaNavProvider>
                                      <CpraLocation>
                                        <GlobalHeaderStateProvider>
                                          <FocusVisible className="js-focus-visible focus-visible">
                                            <LuckyOrangeSnippet />
                                            <DefaultHead />
                                            <AutoSaveSizes />
                                            <ForceEcho />
                                            <ForceNoRedirect />
                                            <DataLayer />
                                            <TrackedClickIds />
                                            <VideoMessageModal />
                                            <RootAppElement>
                                              {children}
                                            </RootAppElement>
                                            <PageLoadingIndicator />
                                            <UKOneHourPromosExpire />
                                            <SpeedySignUp />
                                            <ByoRoutingGuard />
                                          </FocusVisible>
                                        </GlobalHeaderStateProvider>
                                      </CpraLocation>
                                    </MetaNavProvider>
                                  </QuickViewProvider>
                                </MemberCreditsDrawerModalProvider>
                              </CancelConfirmationModalProvider>
                            </PsourceProvider>
                          </ByoProvider>
                        </ADAWidgetProvider>
                      </CashAppProvider>
                    </PaymentMethodProvider>
                  </CountdownTimerProvider>
                </CioClientProvider>
              </DynamicThemeProvider>
            </RenderTime>
          </MountedProvider>
        );
      },
    },
    page(options) {
      const {
        assetFilter,
        defaultAssetFilter = DefaultAssetFilter,
        prefetchAssets = [],
        routeContext = () => [],
        redirectBorderfreeTo = '',
        redirectServerTo = '',
        membership,
      } = options;

      return {
        getInitialProps: {
          enhance(getInitialProps) {
            return async context => {
              const { query } = context;

              if (query?.throwServerError) {
                const errorData = {};
                throw new Error(`Server error, ${errorData.does.not.exist}`);
              }

              if (
                context.isServer &&
                context.req.method !== 'POST' &&
                // ? Not sure if we should remove this or not, it is here to
                // ? prevent getting sent back to the cart page when going to checkout
                // ? from the cart page when the page is not yet built.
                process.env.NODE_ENV !== 'development' &&
                redirectServerTo
              ) {
                // for cash app mobile,if it's coming back to order infromation page right after pre-payment,
                // don't redirect back to cart page since user just finished setting up payment
                if (
                  !context.query.isRedirected &&
                  !context.query.is_cash_app &&
                  process.env.NODE_ENV !== 'development'
                ) {
                  return redirect(context.res, redirectServerTo);
                }
              }

              const { dispatch } = context.store;

              // All the potentially async stuff we need to do to prepare the page.
              const tasks = [];

              const initialPropsTask = getInitialProps
                ? getInitialProps(context)
                : Promise.resolve({});

              tasks.push(initialPropsTask);

              if (enableAssetPrefetch) {
                const globalAssets = config.get('public.assets.globalAssets');
                if (typeof prefetchAssets === 'function') {
                  // If `prefetchAssets` is a function, it can be dynamically used to
                  // determine which assets to prefetch based on `getInitialProps`.
                  // So wait for `getInitialProps` to resolve and pass its return value
                  // to `prefetchAssets`.
                  tasks.push(
                    initialPropsTask.then(initialProps => {
                      const pageAssets = prefetchAssets(initialProps) || [];
                      const uniqueAssets = new Set([
                        ...globalAssets,
                        ...pageAssets,
                      ]);
                      if (uniqueAssets.size) {
                        return dispatch(loadAssets(Array.from(uniqueAssets)));
                      }
                    })
                  );
                } else {
                  const uniqueAssets = new Set([
                    ...globalAssets,
                    ...prefetchAssets,
                  ]);
                  if (uniqueAssets.size) {
                    tasks.push(dispatch(loadAssets(Array.from(uniqueAssets))));
                  }
                }
              }

              let taskResults;
              try {
                taskResults = await Promise.all(tasks);
              } catch (err) {
                if (context.err) {
                  // Already rendering the `_error` page and something still
                  // went wrong (API requests are still made for the error page,
                  // for example), so we can't just keep throwing more errors.
                  taskResults = [{}];
                } else {
                  throw err;
                }
              }

              // The first task was the page's `getInitialProps`, so return its
              // results.
              return taskResults[0];
            };
          },
        },
        render(props, children) {
          let page = children;

          if (membership) {
            page = <MembershipGuard {...membership}>{page}</MembershipGuard>;
          }

          if (redirectBorderfreeTo) {
            page = (
              <BorderfreeGuard redirectBorderfreeTo={redirectBorderfreeTo}>
                {page}
              </BorderfreeGuard>
            );
          }

          if (assetFilter) {
            page = (
              <AssetFilter
                filter={parentFilter => assetFilter(parentFilter, props)}
              >
                {page}
              </AssetFilter>
            );
          }

          if (defaultAssetFilter) {
            page = React.createElement(defaultAssetFilter, {}, page);
          }

          return (
            <TrackPageRenders>
              <RouteContext routeContext={routeContext}>
                <ToastContext>{page}</ToastContext>
              </RouteContext>
            </TrackPageRenders>
          );
        },
      };
    },
  };
}
