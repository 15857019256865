import { useMemo } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';

const storedProps = new Map<string, any>();

export function useLDFlags() {
  const ldClient = useLDClient();
  const allFlags = ldClient?.allFlags() ?? {};
  const flagCount = Object.keys(allFlags).length;
  const noClientReady = useMemo(() => {
    return flagCount === 0;
  }, [flagCount]);

  const flags = useMemo(() => {
    if (noClientReady) {
      return {};
    }

    return new Proxy(allFlags, {
      get(target, prop) {
        if (
          typeof prop !== 'symbol' &&
          Object.prototype.hasOwnProperty.call(target, prop)
        ) {
          if (!storedProps.has(prop)) {
            storedProps.set(prop, target[prop]);
            return ldClient.variation(prop, target[prop]);
          }
          return target[prop];
        } else {
          return undefined;
        }
      },
    });
  }, [noClientReady, allFlags, ldClient]);

  return flags;
}
