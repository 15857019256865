import { baseTrackingData } from '../utils';

const viewPDPImageEvents = ({ getContext, trackEvent }) => {
  const { TRACK_VIEWED_PDP_IMAGE } = require('../../actions/products');
  const viewPDPImage = trackEvent((action, prevState, nextState) => {
    const context = getContext();
    return {
      name: 'View PDP Image',
      properties: {
        ...baseTrackingData(nextState, context),
        category: 'pdp',
        ...action.payload,
      },
    };
  });

  return { [TRACK_VIEWED_PDP_IMAGE]: viewPDPImage };
};

export default viewPDPImageEvents;
