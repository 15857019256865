import { css } from 'styled-components';

// language=SCSS
export default css`
  /* Styles for country chooser */
  #bfx-cc-wrapper {
    /* When Borderfree gets injected into the page, its styles take a moment to
       apply, causing an annoying jump in the page for a split second. This
       forces its container to take up no height, preventing jankiness. */
    position: absolute;
    height: 0;
    overflow: visible;
    top: auto !important;
    left: 58% !important;
    right: auto !important;
    bottom: 100px !important;
    z-index: 20;

    @media (min-width: 768px) {
      left: 9em !important;
      bottom: 40px !important;
    }

    .bfx-cc-expanded {
      position: fixed;
      background-color: ${props => props.theme.colors.default};
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100vh !important;

      .bfx-cc-content {
        width: 640px;
        border: 2px solid ${props => props.theme.colors.default};
        height: 392px;
        padding-top: 88px;
        background: ${props => props.theme.colors.white}
          url(/static/images/logo.png) no-repeat;
        background-position: center 40px;
        background-size: 300px;
        align-self: center;

        .bfx-cc-text {
          font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial',
            sans-serif;
          text-align: center;
          margin: 0 auto;
          font-size: 14px;
          line-height: 1.9;

          @media (max-width: 768px) {
            max-width: 250px;
          }
        }

        form {
          margin-top: 24px;

          .bfx-cc-select-wrapper {
            max-width: 240px;
            margin: 24px auto 0;
            position: relative;

            .bfx-cc-label {
              font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial',
                sans-serif;
              text-transform: uppercase;
              color: ${props => props.theme.colors.gray500};
              font-size: 10px;
              line-height: 1.7;
            }

            select {
              width: 100%;
              border: 0;
              border-bottom: 2px solid ${props => props.theme.colors.default};
              padding: 0;
              margin: 0;
              font-size: 14px;
              font-weight: 700;
              outline: 0;
              appearance: none;
              border-radius: 0;
              position: relative;
              background: transparent
                url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>")
                no-repeat 100% 50%;
              height: 29px;
              margin: 0;
              line-height: 1.1;

              &:disabled {
                background: ${props => props.theme.colors.white}
                  url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#dadbe3' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>")
                  no-repeat 100% 50% !important;
                color: ${props => props.theme.colors.gray200}!important;
              }
            }
          }

          #bfx-cc-btn {
            font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial',
              sans-serif;
            width: 100%;
            background-color: ${props => props.theme.colors.default};
            border-radius: 22px;
            max-width: 240px;
            display: block;
            margin: 40px auto 0;
          }
        }

        @media (max-width: 678px) {
          width: 350px;
          background-size: 250px;
          height: 412px;
        }

        @media (max-width: 411px) {
          width: 312px;
        }
      }
    }
  }

  /* Styles for Welcome mat */
  div.bfx-wm-dialog {
    width: 100% !important;
    left: 0 !important;
  }

  #bfx-wm-wrapper {
    width: 640px !important;
    margin: 0 auto;
    @media (max-width: 678px) {
      width: 311px !important;
      height: auto !important;
    }
    #bfx-wm-content {
      background-color: ${props => props.theme.colors.white};
      background-image: none;
      font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
      position: relative;

      a#bfx-wm-close-button {
        background-image: url(/static/images/close.svg);
        height: 19px;
        right: 20px;
        top: -24px;
        width: 20px;

        &:hover {
          background-image: url(/static/images/close.svg);
        }
      }

      #bfx-wm-head {
        display: none;
      }

      #bfx-wm-foot {
        display: none;
      }

      #bfx-wm-body {
        background-image: none;

        #bfx-wm-title {
          height: auto;
          margin-top: 40px;
          background-image: none;

          #bfx-wm-headline {
            float: none;
            padding: 0;
            line-height: 1.9;
            font-size: 18px !important;
          }

          #bfx-wm-flag {
            display: none;
          }
        }

        #bfx-wm-subheadline {
          margin: 0;
          text-align: center;
          font-style: normal;
          font-size: 16px;
          border: 0;
          padding: 32px 0 24px;
        }

        #bfx-wm-sellpoints {
          width: 265px;
          margin: 0 auto;
          font-size: 12px;

          ul {
            li {
              margin: 0;
            }
          }
        }

        #bfx-wm-info {
          width: 300px;
          @media (max-width: 678px) {
            width: 100%;
          }
          text-align: left;
          color: ${props => props.theme.colors.gray500};
          margin: 24px auto 0;
          line-height: 1.5;
        }

        #bfx-wm-continue-button-wrapper {
          margin: 0;

          #bfx-wm-continue-button {
            font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial',
              sans-serif;
            background-image: none;
            background-color: ${props => props.theme.colors.default};
            margin-top: 24px;
            text-transform: uppercase;
            font-size: 14px;
            height: 32px;

            @media (max-width: 678px) {
              width: 100%;
            }
          }
        }

        #bfx-wm-promo {
          background-color: ${props => props.theme.colors.white};
          border: 0;

          #bfx-wm-switch-country {
            text-transform: initial;
            font-size: 12px;

            a {
              color: ${props => props.theme.colors.lavender500};
            }
          }
        }
      }
    }
  }
`;
