import React from 'react';

import PropTypes from 'prop-types';
import { FaAngleDown } from 'react-icons/fa';
import ReactSelect from 'react-select';
// There is a Babel Transform error if this is not imported into a variable
// eslint-disable-next-line no-unused-vars
import styles from 'react-select/dist/react-select.css';
import styled, { css } from 'styled-components';

import { visuallyHidden } from '@techstyle/react-components-legacy/styles';

const Container = styled.div`
  text-align: left;
`;

const Label = styled.label`
  ${props =>
    props.hideLabel
      ? visuallyHidden
      : css`
          display: block;
          font-size: 14px;
          color: ${props.error
            ? props.theme.colors.error
            : props.theme.colors.subdued};
        `};
`;

const SelectArrow = styled(FaAngleDown)`
  stroke-width: 1px;
  stroke: ${props => props.theme.colors.default};
`;

/**
 * A styled version of [react-select](https://github.com/JedWatson/react-select),
 * supporting the same props.
 */
const StyledReactSelect = styled(ReactSelect).attrs(
  ({
    // eslint-disable-next-line react/display-name
    arrowRenderer = () => <SelectArrow />,
    // Default to not being searchable.
    searchable = false,
    clearable = false,
  }) => ({ arrowRenderer, searchable, clearable })
)`
  text-align: left;

  .Select-control {
    border: 0;
    border-bottom: 2px solid ${props => props.theme.colors.default};
    border-radius: 0;
    background: transparent;

    .Select-input,
    .Select-input:focus {
      background: transparent;
    }

    .Select-value {
      padding-left: 0;
    }
  }

  &.is-open {
    > .Select-control {
      background: transparent;
    }
  }

  &.is-disabled {
    > .Select-control {
      background-color: transparent;
      border-bottom-color: ${props => props.theme.colors.subdued};
    }

    .Select-placeholder,
    .Select-value-label {
      color: ${props => props.theme.colors.subdued} !important;
    }
  }

  &.is-focused,
  &.is-focused:not(.is-open) {
    > .Select-control {
      outline: 0;
      border-color: ${props => props.theme.focusColor};
      background: transparent;
      box-shadow: none;
    }
  }

  .Select-placeholder {
    padding-left: 0;
    color: ${props => props.theme.colors.default};
  }

  .Select-menu-outer {
    max-height: 202px;
    border-radius: 0;
    border: 2px solid ${props => props.theme.colors.default};
    margin-top: -2px;
    box-shadow: none;
  }

  .Select-arrow-zone {
    padding-right: 0;
    text-align: right;
    vertical-align: baseline;
  }

  .Select-option {
    &.is-focused {
      background: ${props => props.theme.colors.gray150};
    }
  }
`;

export default class Select extends React.Component {
  static Wrapper = Container;
  static Label = Label;

  static propTypes = {
    className: PropTypes.string,
    hideLabel: PropTypes.bool,
    label: PropTypes.node,
    error: PropTypes.string,
  };

  static defaultProps = {
    hideLabel: false,
  };

  updateSelectRef = ref => {
    this.selectRef = ref;
  };

  handleLabelClick = () => {
    if (this.selectRef) {
      this.selectRef.focus();
    }
  };

  render() {
    const { className, error, label, hideLabel, inlineLabel, ...selectProps } =
      this.props;
    const errorOrLabel = error || label;
    return (
      <Container className={className}>
        {errorOrLabel ? (
          <Label
            hideLabel={hideLabel}
            error={error}
            onClick={this.handleLabelClick}
          >
            {errorOrLabel}
          </Label>
        ) : null}
        <StyledReactSelect
          ref={this.updateSelectRef}
          valueRenderer={option => {
            if (inlineLabel) {
              return (
                <>
                  {inlineLabel} {option.label}
                </>
              );
            }
            return option.label;
          }}
          {...selectProps}
        />
      </Container>
    );
  }
}
