import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { FormattedMessage } from '@techstyle/react-intl';

import NextRoutes from '../../../routes';
import { decodeHTML } from '../../utils/helpers';
import { Component as Price } from '../ExtendedPrice';

export const ROUTES = {
  '/': <FormattedMessage defaultMessage="Home" id="site_navigation.home" />,
  findLabelByPage: {
    /* eslint-disable-next-line react/display-name */
    '/products': query => {
      switch (parseInt(query.defaultTagId, 10)) {
        case config.get('public.productTags.plusSize'):
          return (
            <FormattedMessage
              defaultMessage="Curvy"
              id="site_navigation.curvy"
            />
          );
      }
      switch (query.collection) {
        case 'features/blackwidow':
          return (
            <FormattedMessage
              defaultMessage="Black Widow"
              id="site_navigation.black_widow"
            />
          );
        case 'features/damn':
          return (
            <FormattedMessage defaultMessage="Damn" id="site_navigation.damn" />
          );
        case 'features/onthereg':
          return (
            <FormattedMessage
              defaultMessage="On The Reg"
              id="site_navigation.on_the_reg"
            />
          );
        case 'features/ucute':
          return (
            <FormattedMessage
              defaultMessage="U Cute"
              id="site_navigation.u_cute"
            />
          );
        case 'xtrasavage':
          return (
            <FormattedMessage
              defaultMessage="Xtra Savage"
              id="site_navigation.savage_xtra"
            />
          );
        case 'features/candy-apple-red':
          return (
            <FormattedMessage
              defaultMessage="Candy Apple Red"
              id="site_navigation.features_candy_apple_red"
            />
          );
        // new
        case 'features/logo':
          return (
            <FormattedMessage
              defaultMessage="Logo"
              id="site_navigation.features_logo"
            />
          );
        // new
        case 'features/mesh':
          return (
            <FormattedMessage
              defaultMessage="Mesh"
              id="site_navigation.features_mesh"
            />
          );
        // new
        case 'features/jewel':
          return (
            <FormattedMessage
              defaultMessage="Jewel"
              id="site_navigation.features_jewel"
            />
          );
        // new
        case 'features/animal':
          return (
            <FormattedMessage
              defaultMessage="Animal"
              id="site_navigation.features_animal"
            />
          );
        case 'features/lace':
          return (
            <FormattedMessage
              defaultMessage="Lace"
              id="site_navigation.features_lace"
            />
          );
        case 'features/bras-bundle':
          return (
            <FormattedMessage
              defaultMessage="2 for {price} T Shirt Bras"
              id="site_navigation.features_t_shirt_bra"
              values={{
                price: <Price amount={59} />,
              }}
            />
          );
        case 'features/bra-and-undie-sets':
          return (
            <FormattedMessage
              defaultMessage="Bra + Panty Sets"
              id="site_navigation.features_bra_undie_sets"
            />
          );
        case 'features/bridal':
          return (
            <FormattedMessage
              defaultMessage="Bridal Whites"
              id="site_navigation.features_bridal"
            />
          );
        case 'features/pastels':
          return (
            <FormattedMessage
              defaultMessage="Pastels"
              id="site_navigation.features_pastels"
            />
          );
        case 'features/nude':
          return (
            <FormattedMessage
              defaultMessage="Nude"
              id="site_navigation.features_nude"
            />
          );
        case 'features/brights':
          return (
            <FormattedMessage
              defaultMessage="Bold + Bright"
              id="site_navigation.features_brights"
            />
          );
        case 'features/microfiber':
          return (
            <FormattedMessage
              defaultMessage="Microfiber"
              id="site_navigation.features_microfiber"
            />
          );
      }
      switch (query.section) {
        case 'bras':
          switch (query.subsection) {
            case 'bralettes':
              return (
                <FormattedMessage
                  defaultMessage="Bralettes"
                  id="site_navigation.bralettes"
                />
              );
            case 'strapless':
              return (
                <FormattedMessage
                  defaultMessage="Strapless"
                  id="site_navigation.strapless"
                />
              );
            case 'underwire':
              return (
                <FormattedMessage
                  defaultMessage="Underwire"
                  id="site_navigation.underwire"
                />
              );
            case 'pushup':
              return (
                <FormattedMessage
                  defaultMessage="Push-Up"
                  id="site_navigation.pushup"
                />
              );
            case 'demi-cup':
              return (
                <FormattedMessage
                  defaultMessage="Demi Cup"
                  id="site_navigation.demi_cup"
                />
              );
            case 'unlined':
              return (
                <FormattedMessage
                  defaultMessage="Unlined"
                  id="site_navigation.unlined"
                />
              );
            case 'balconette':
              return (
                <FormattedMessage
                  defaultMessage="Balconette"
                  id="site_navigation.balconette"
                />
              );
            case 'tshirt':
              return (
                <FormattedMessage
                  defaultMessage="T-Shirt Bras"
                  id="site_navigation.tshirt"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Bras"
                  id="site_navigation.bras"
                />
              );
          }
        case 'undies':
          switch (query.subsection) {
            case 'thong':
              return (
                <FormattedMessage
                  defaultMessage="Thong"
                  id="site_navigation.thong"
                />
              );
            case 'briefs':
              return (
                <FormattedMessage
                  defaultMessage="Brief"
                  id="site_navigation.brief"
                />
              );
            case 'bikinis':
              return (
                <FormattedMessage
                  defaultMessage="Bikini"
                  id="site_navigation.bikini"
                />
              );
            case 'hipster':
              return (
                <FormattedMessage
                  defaultMessage="Hipster"
                  id="site_navigation.hipster"
                />
              );
            case 'cheeky':
              return (
                <FormattedMessage
                  defaultMessage="Cheeky"
                  id="site_navigation.cheeky"
                />
              );
            case 'high-waist':
              return (
                <FormattedMessage
                  defaultMessage="High Waist"
                  id="site_navigation.high_waist"
                />
              );
            case 'crotchless':
              return (
                <FormattedMessage
                  defaultMessage="Crotchless"
                  id="site_navigation.crotchless"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Undies"
                  id="site_navigation.undies"
                />
              );
          }
        case 'sets':
          switch (query.subsection) {
            case 'bralette-undie':
              return (
                <FormattedMessage
                  defaultMessage="Bralette + Undie Sets"
                  id="site_navigation.sets_bralette_undie"
                />
              );
            case 'bra-undie':
              return (
                <FormattedMessage
                  defaultMessage="Bra + Undie Sets"
                  id="site_navigation.sets_bra_undie"
                />
              );
            case 'sport':
              return (
                <FormattedMessage
                  defaultMessage="Sport Sets"
                  id="site_navigation.sets_sports"
                />
              );
            case 'sleep':
              return (
                <FormattedMessage
                  defaultMessage="Sleep Sets"
                  id="site_navigation.sets_sleep"
                />
              );
            case 'accessories':
              return (
                <FormattedMessage
                  defaultMessage="Accessory Bundle"
                  id="site_navigation.sets_accessories"
                />
              );
            case 'lingerie':
              return (
                <FormattedMessage
                  defaultMessage="Lingerie Sets"
                  id="site_navigation.sets_lingerie"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Sets"
                  id="site_navigation.sets"
                />
              );
          }
        case 'lingerie':
          switch (query.subsection) {
            case 'bodysuits-teddies':
              return (
                <FormattedMessage
                  defaultMessage="Bodysuits & Teddies"
                  id="site_navigation.bodysuits_teddies"
                />
              );
            case 'babydoll-rompers':
              return (
                <FormattedMessage
                  defaultMessage="Babydolls & Rompers"
                  id="site_navigation.babydoll_rompers"
                />
              );
            case 'corsets-bustiers':
              return (
                <FormattedMessage
                  defaultMessage="Corsets & Bustiers"
                  id="site_navigation.corsets_bustiers"
                />
              );
            case 'sleep-sets':
              return (
                <FormattedMessage
                  defaultMessage="Sleep & Sets"
                  id="site_navigation.sleep_sets"
                />
              );
            case 'slips':
              return (
                <FormattedMessage
                  defaultMessage="Slips"
                  id="site_navigation.sleep_slips"
                />
              );
            case 'garters':
              return (
                <FormattedMessage
                  defaultMessage="Garters"
                  id="site_navigation.garters"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Lingerie"
                  id="site_navigation.lingerie"
                />
              );
          }
        case 'sleep':
          switch (query.subsection) {
            case 'pajamas':
              return (
                <FormattedMessage
                  defaultMessage="Pajamas"
                  id="site_navigation.sleep_pajamas"
                />
              );
            case 'loungewear':
              return (
                <FormattedMessage
                  defaultMessage="Loungewear"
                  id="site_navigation.sleep_loungewear"
                />
              );

            case 'robes':
              return (
                <FormattedMessage
                  defaultMessage="Robes"
                  id="site_navigation.sleep_robes"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Sleep"
                  id="site_navigation.sleep"
                />
              );
          }
        case 'men':
          switch (query.subsection) {
            case 'undies':
              return (
                <FormattedMessage
                  defaultMessage="Undies"
                  id="site_navigation.undies"
                />
              );
            case 'sleep':
              return (
                <FormattedMessage
                  defaultMessage="Sleep"
                  id="site_navigation.sleep"
                />
              );
            default:
              return (
                <FormattedMessage
                  defaultMessage="Men"
                  id="site_navigation.men"
                />
              );
          }
        case 'accessories':
          return (
            <FormattedMessage
              defaultMessage="Accessories"
              id="site_navigation.accessories"
            />
          );
      }
      switch (query.defaultFpls) {
        case 'new':
          return (
            <FormattedMessage defaultMessage="New" id="site_navigation.new" />
          );
      }
      return (
        <FormattedMessage
          defaultMessage="All"
          id="site_navigation.all_products"
        />
      );
    },
  },
  '/features/best-sellers': (
    <FormattedMessage
      defaultMessage="Best sellers"
      id="site_navigation.best_sellers"
    />
  ),
  '/firstlook': (
    <FormattedMessage
      defaultMessage="#FirstLook"
      id="site_navigation.firstlook"
    />
  ),
  '/gallery': (
    <FormattedMessage
      defaultMessage="#SavageXFenty"
      id="site_navigation.ugc_gallery"
    />
  ),
  '/xtrasavage': (
    <FormattedMessage
      defaultMessage="Xtra Savage Membership"
      id="site_navigation.savage_xtra_membership"
    />
  ),
  '/xtrashop': (
    <FormattedMessage
      defaultMessage="Xclusive Picks"
      id="site_navigation.xtrashop"
    />
  ),
  '/allthingsrihanna': (
    <FormattedMessage defaultMessage="Rihanna" id="site_navigation.rihanna" />
  ),
  '/aboutus': (
    <FormattedMessage defaultMessage="About Us" id="site_navigation.about_us" />
  ),
  '/retail-stores': (
    <FormattedMessage
      defaultMessage="Retail Stores"
      id="site_navigation.store_locations"
    />
  ),
  '/anniversary': (
    <FormattedMessage
      defaultMessage="Anniversary"
      id="site_navigation.anniversary"
    />
  ),
  '/press': (
    <FormattedMessage
      defaultMessage="In the Press"
      id="site_navigation.press"
    />
  ),
  '/sale': (
    <FormattedMessage defaultMessage="Sale" id="site_navigation.vip_sale" />
  ),
  '/savage-x-ambassadors': (
    <FormattedMessage
      defaultMessage="Ambassador Shops"
      id="site_navigation.ambassador_shops"
    />
  ),
  '/SXFThrivers': (
    <FormattedMessage
      defaultMessage="SXFThrivers"
      id="site_navigation.sxfthrivers"
    />
  ),
  '/show': <FormattedMessage defaultMessage="Show" id="site_navigation.show" />,
};

/** @component */
export default function RouteLabel({ labels, route, children }) {
  let key;
  let query;
  let findLabelByPage = false;
  if (typeof route === 'string') {
    if (route in labels) {
      key = route;
      query = {};
    } else {
      // FIXME: This `match` method is an undocumented API!
      const routeMatch = NextRoutes.match(route);
      key = routeMatch.route
        ? routeMatch.route.page
        : routeMatch.parsedUrl.pathname;
      query = routeMatch.query;
      findLabelByPage = true;
    }
  } else if (route.asPath && route.asPath in labels) {
    key = route.asPath;
    query = route.query || {};
  } else if (route.pathname) {
    key = route.pathname;
    query = route.query || {};
    findLabelByPage = true;
  } else if (route.as && route.as in labels) {
    key = route.as;
    query = (route.href && route.href.query) || {};
  } else if (route.href) {
    if (typeof route.href === 'string') {
      key = route.href;
      query = {};
    } else {
      key = route.href.pathname;
      query = route.href.query || {};
      findLabelByPage = true;
    }
  }
  let label = findLabelByPage
    ? labels.findLabelByPage[key] || null
    : labels[key] || null;
  if (typeof label === 'function') {
    label = label(query) || null;
  }
  if (typeof label === 'string') {
    label = decodeHTML(label);
  }
  return typeof children === 'function' ? children(label) : label;
}

RouteLabel.propTypes = {
  route: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
      asPath: PropTypes.string,
    }),
    PropTypes.shape({
      href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      as: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  labels: PropTypes.object,
};

RouteLabel.defaultProps = {
  labels: ROUTES,
};

RouteLabel.ROUTES = ROUTES;
