import { useCallback, useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { useAccountActions } from '@techstyle/react-accounts';

import { useLDFlags } from './LD/useLDFlags';
import { RootState } from './types';

export function useEmailPreferencesWithVars() {
  const queryClient = useQueryClient();
  const actions = useAccountActions();
  const emailPreferences = useSelector<RootState>(
    state => state.emailPreferences
  );
  const {
    'email-preferences-vars-list': emailPreferencesVarsList,
  }: {
    'email-preferences-vars-list'?: string[];
  } = useLDFlags();

  const loadEmailPreferences = useCallback(async () => {
    return await actions.loadEmailPreferences(emailPreferencesVarsList);
  }, [actions, emailPreferencesVarsList]);

  const { isLoading } = useQuery({
    queryKey: ['emailPreferences', emailPreferencesVarsList],
    queryFn: loadEmailPreferences,
    enabled: !!emailPreferencesVarsList,
    staleTime: Infinity,
  });

  useEffect(() => {
    // @ts-ignore - `emailPreferences` is not typed, but `networkStatus` does exist
    if (emailPreferences.networkStatus.isUpToDate === false && !isLoading) {
      // After an email preferences update, we need to invalidate the email preferences cache,
      // however it seems like it takes the a moment for this to be update on the BE.
      // Because of this, need a hacky timeout of 1 second to help make sure the
      // data is correct.
      setTimeout(() => {
        queryClient.invalidateQueries([
          'emailPreferences',
          emailPreferencesVarsList,
        ]);
      }, 1000);
    }
  }, [emailPreferences, emailPreferencesVarsList, isLoading, queryClient]);

  return emailPreferences;
}
