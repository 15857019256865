import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Select } from '@techstyle/react-components-legacy';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
} from '@techstyle/react-intl';

import { loadReferrer } from '../../actions/referrer';
import { mobile } from '../../styles';
import { v1, v2 } from '../../utils/themeVersioning';

const REFERRER_REQUIRED = 'REFERRER_REQUIRED';

const placeholders = defineMessages({
  referer: {
    id: 'global_checkout.quiz_referrer_placeholder',
    defaultMessage: 'Select One',
  },
});

const StyledSelect = styled(Select).attrs(({ firstWidth = true }) => ({
  firstWidth,
}))`
  ${v1`
    ${mobile`
      flex-basis: 100%;
    `};
    display: block;
    margin-top: ${props => props.theme.spacing.tiny}px;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    text-align: left;
    transition: 300ms ease-in-out opacity;

    & ${Select.Label} {
      letter-spacing: 0.6px;
    }

    & ${Select.Footer} {
      min-height: 15px;
    }
  `}

  ${v2`
    ${props => props.theme.selectV2.defaultStyle};
    ${props => !!props.error && props.theme.selectV2.errorStyle};
  `}
`;

function ReferrerSelection({
  className,
  error,
  intl,
  onChange = () => {},
  order,
  value,
  ...rest
}) {
  const dispatch = useDispatch();
  const referrerOptionsFromState = useSelector(
    state => state.referrer.referrerOptions
  );
  const [referrerOptions, setReferrerOptions] = useState(
    referrerOptionsFromState
  );

  useEffect(() => {
    const loadReferrerFunc = async () => {
      if (!referrerOptionsFromState.length) {
        try {
          await dispatch(loadReferrer());
        } catch (err) {
          throw new Error(err.message);
        }
      }
    };

    loadReferrerFunc();
  }, []);

  useEffect(() => {
    setReferrerOptions(referrerOptionsFromState);
  }, [referrerOptionsFromState]);

  const getReferrerOptions = () => {
    if (referrerOptions.length) {
      return referrerOptions.map(option => {
        return {
          name: option.label,
          value: `${option.referrerId}`,
        };
      });
    } else {
      return [];
    }
  };

  const renderError = error => {
    switch (error) {
      case REFERRER_REQUIRED:
        return (
          <FormattedMessage
            defaultMessage="Required"
            id="global_checkout.referrer_field_required"
          />
        );
      default:
        return error || null;
    }
  };

  return (
    <StyledSelect
      className={className}
      order={order}
      label={
        <FormattedMessage
          defaultMessage="How did you hear about us?"
          id="global_checkout.quiz_referrer_label"
        />
      }
      name="referrer"
      disabled={!referrerOptions.length}
      onChange={onChange}
      options={getReferrerOptions()}
      placeholder={intl.formatMessage(placeholders.referer)}
      error={renderError(error)}
      value={value}
      {...rest}
    />
  );
}

ReferrerSelection.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  // Callback with referrer value.
  onChange: PropTypes.func,
  // Controlled Input
  value: PropTypes.string,
  // Possible error.
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  order: PropTypes.number,
};

export default injectIntl(ReferrerSelection);
