import { handleActions } from 'redux-actions';

import { logInSuccess, signUpSuccess } from '@techstyle/react-accounts';

import { TOGGLE_SIGN_IN_MODAL } from '../actions/auth';

const defaultState = {
  signInModalIsOpen: false,
  preserveUserStatusInitial: false,
};

export default handleActions(
  {
    [TOGGLE_SIGN_IN_MODAL]: (state, action) => {
      return {
        ...state,
        signInModalIsOpen: action.payload,
        initialScreen: action.initialScreen,
        onSuccess: action.onSuccess,
        onCancel: action.onCancel,
        // Remember the last location even if the modal is being closed.
        location: action.payload ? action.meta.location : state.location,
        productId: action.payload ? action.meta.productId : state.productId,
        isProductSet: action.payload
          ? action.meta.isProductSet
          : state.isProductSet,
      };
    },
    [logInSuccess]: (state, action) => {
      return {
        ...state,
        signInMethod: action.meta.method,
      };
    },
    [signUpSuccess]: (state, action) => {
      return {
        ...state,
        signInMethod: action.meta.method,
        preserveUserStatusInitial: true,
      };
    },
  },
  defaultState
);
