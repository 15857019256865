import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderAttachment } from '@techstyle/react-components';

import { Container as CartDrawer } from '../CartDrawer';
import CookieBar from '../CookieBar';
import CountdownTimerBadge from '../CountdownTimerBadge/CountdownTimerBadge';
import { Component as EmpUserAcceptanceModal } from '../EmpUserAcceptanceModal';
import { Container as Footer } from '../Footer';
import { FooterProvider } from '../Footer/FooterProvider';
import { Component as Header } from '../Header';
import { Component as MobileSearchPane } from '../MobileSearchPane';
import { Component as NavBarAttachment } from '../NavBarAttachment';
import SearchProvider from '../SearchProvider/SearchProvider';
import { Component as UserSizePrompt } from '../UserSizePrompt';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  height: auto; /* IE11 will not properly calculate flexed children’s sizes with no explicit height */
  background: ${props =>
    props.theme.context.transparentNav
      ? props.theme.colors.default
      : 'transparent'};
  color: ${props =>
    props.theme.context.transparentNav ? props.theme.colors.white : 'inherit'};
`;

const Body = styled.main`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
`;

export default class MainTemplate extends React.PureComponent {
  static Body = Body;

  static Footer = Footer.Wrapper;

  static Header = Header.Wrapper;

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    hideNavLinks: PropTypes.bool,
    hideNavLinksState: PropTypes.string,
    hideRegistrationModal: PropTypes.bool,
    hideSkinnyBanner: PropTypes.bool,
    hideUserSizePrompt: PropTypes.bool,
    allowNewPricingSkinnyBanner: PropTypes.bool,
  };

  static defaultProps = {
    hideHeader: false,
    hideFooter: false,
    hideNavLinks: false,
    hideNavLinksState: '',
    hideRegistrationModal: false,
    hideSkinnyBanner: false,
    hideUserSizePrompt: false,
    allowNewPricingSkinnyBanner: false,
  };

  state = {
    shouldOverrideUseSizePrompt: true,
  };

  handleEmpModalStateChange = isOpen => {
    this.setState(state => {
      return { shouldOverrideUseSizePrompt: isOpen };
    });
  };

  render() {
    const {
      className,
      hideNavLinks,
      hideNavLinksState,
      hideRegistrationModal,
      hideSkinnyBanner,
      hideUserSizePrompt,
      allowNewPricingSkinnyBanner,
    } = this.props;

    return (
      <NavBarAttachment.Provider>
        <HeaderAttachment.Provider>
          <Wrapper className={className}>
            <CartDrawer />
            <SearchProvider>
              {this.props.hideHeader ? null : (
                <Header
                  hideNavLinks={hideNavLinks}
                  hideNavLinksState={hideNavLinksState}
                  hideRegistrationModal={hideRegistrationModal}
                  hideSkinnyBanner={hideSkinnyBanner}
                  allowNewPricingSkinnyBanner={allowNewPricingSkinnyBanner}
                />
              )}
              <MobileSearchPane />
            </SearchProvider>
            <FooterProvider>
              <Body>{this.props.children}</Body>
              <CountdownTimerBadge />
              {this.props.hideFooter ? null : <Footer />}
            </FooterProvider>
            <CookieBar />

            {!hideUserSizePrompt && !this.state.shouldOverrideUseSizePrompt && (
              <UserSizePrompt />
            )}
            <EmpUserAcceptanceModal
              onStateChange={this.handleEmpModalStateChange}
            />
          </Wrapper>
        </HeaderAttachment.Provider>
      </NavBarAttachment.Provider>
    );
  }
}
