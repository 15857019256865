import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
// //USE THIS PROVIDER TO SET PSOURCE ACROSS MULTIPLE COMPONENTS REQUIRING PSOURCE
// INITIALLY USED IN CHECKOUT

interface PsourceContext {
  psource: string;
  setPsource: Dispatch<SetStateAction<string>>;
}

const Context = React.createContext<PsourceContext>({
  psource: '',
  setPsource: () => {},
});

export const usePsourceContext = () => {
  return useContext(Context);
};

export default function PsourceProvider({ children }) {
  const [psource, setPsource] = useState('');

  const value = useMemo(() => ({ setPsource, psource }), [setPsource, psource]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

PsourceProvider.Context = Context;
PsourceProvider.Consumer = Context.Consumer;
