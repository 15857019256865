import qs from 'querystring';

import { urlNoQueryString } from '@techstyle/redux-core';

export function stringifyQuery(params) {
  const filteredParams = Object.keys(params).reduce((output, key) => {
    if (typeof params[key] !== 'undefined') {
      output[key] = params[key];
    }
    return output;
  }, {});
  return qs.stringify(filteredParams).replace(/%20/g, '+').replace(/%2C/g, ',');
}

export function getNavTrackingUrl(navItem) {
  let navItemUrl = navItem.url;
  if (navItem.gaLabel.trim().length) {
    const label = navItem.gaLabel.replace(/[^A-Z0-9_-]+/gi, '');
    navItemUrl = navItemUrl.includes('?')
      ? navItemUrl + `&nav=${label}`
      : navItemUrl + `?nav=${label}`;
  }
  return navItemUrl;
}

/**
 * Pulls product Id from Url
 */
export function getProductIdFromUrl(url = '') {
  return urlNoQueryString(url).split('-').pop();
}

export function getNavItemAssets(navItem) {
  return (navItem.navItems || [])
    .reduce((items, navItem) => {
      if (navItem.column) {
        if (navItem.navItems && navItem.navItems.length) {
          items.push(...navItem.navItems);
        }
      } else {
        items.push(navItem);
      }
      return items;
    }, [])
    .filter(item => (item.itemKey || '').startsWith('$'))
    .map(item => item.itemKey.substring(1));
}

/**
 * Returns the Builder quiz URL considering the first two path segments. e.g.: /product-quiz/1/category -> /product-quiz/1
 * @param {String} asPath
 * @returns {String|null}
 */
export function getBuilderQuizPathname(asPath) {
  const pathSegments = getPathname(asPath)?.split('/');

  if (pathSegments === undefined) {
    return null;
  }

  // We consider 1 as the first segment because index 0 always has an empty string
  return '/' + pathSegments.slice(1, 3).filter(Boolean).join('/');
}

export function getPathname(asPath) {
  return asPath?.split('?') ? asPath?.split('?')[0] : null;
}
