import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useIntl, defineMessages } from '@techstyle/react-intl';
import { useDomain } from '@techstyle/redux-core';

import { getProductCategoryTypes } from '../../utils/productDetail';
import { getHarnessSizes, getMenHarnessOneSize } from '../../utils/sizes';

const messages = defineMessages({
  plusSize: {
    id: 'global_checkout.one_size_curvy',
    defaultMessage: '1X-3X',
  },
  missySize: {
    id: 'global_checkout.one_size_missy',
    defaultMessage: 'XS-XL',
  },
  oneSize: {
    id: 'global_checkout.one_size',
    defaultMessage: 'One Size',
  },
});

// TODO: This isn't really ideal, we should manage these labels in one place so we don't have a hook solely
// for the product size labels.
// This hook is used to get the product size label for a product, this is needed to
// be able to get a string value of the product size label.
export const useGetProductSizeLabel = () => {
  const { tld } = useDomain();
  const intl = useIntl();

  const getProductSizeLabel = useCallback(
    ({
      isOneSize,
      masterProduct = {
        product_category_id_list: [],
        tag_id_list: [],
      },
      product,
      shouldDisplayMultipleOneSizes = false,
    }) => {
      const bundleLabel =
        product?.label_instance || product?.instanceLabel || null;
      if (!bundleLabel) {
        return null;
      }

      const harnessSizeList = getHarnessSizes(tld);
      const { productCategoryIdList, tagIds } = product;
      const {
        product_category_id_list: masterCategoryIdList,
        tag_id_list: masterTagIdList,
      } = masterProduct;
      const categoryIdList = productCategoryIdList || masterCategoryIdList;
      const tagIdList = tagIds || masterTagIdList;

      const { isHarness, isHarnessOneSize, isMen } = getProductCategoryTypes(
        categoryIdList,
        tagIdList
      );

      // This needs to happen first, in case the product is listed as a harness and a
      // one size product.
      if (
        isHarnessOneSize &&
        isMen &&
        product &&
        shouldDisplayMultipleOneSizes
      ) {
        return getMenHarnessOneSize(tld, product.is_plus_size);
      }

      if (isHarness) {
        const harnessSize = harnessSizeList.find(
          ({ value }) => value === bundleLabel
        );

        return harnessSize.label;
      }

      if (isOneSize && product && shouldDisplayMultipleOneSizes) {
        return product.is_plus_size
          ? intl.formatMessage(messages.plusSize)
          : intl.formatMessage(messages.missySize);
      }

      if (isOneSize) {
        return intl.formatMessage(messages.oneSize);
      }

      return bundleLabel;
    },
    [intl, tld]
  );

  return { getProductSizeLabel };
};

export default function ProductSizeLabel({
  isOneSize,
  masterProduct = {
    product_category_id_list: [],
    tag_id_list: [],
  },
  product,
  shouldDisplayMultipleOneSizes = false,
}) {
  const { getProductSizeLabel } = useGetProductSizeLabel();
  const intl = useIntl();
  const TextComponent = intl.textComponent;
  const productSizeLabel = getProductSizeLabel({
    isOneSize,
    masterProduct,
    product,
    shouldDisplayMultipleOneSizes,
  });

  if (!productSizeLabel) {
    return null;
  }

  return (
    <TextComponent whiteSpace="pre-line">{productSizeLabel}</TextComponent>
  );
}

ProductSizeLabel.propTypes = {
  isOneSize: PropTypes.bool.isRequired,
  masterProduct: PropTypes.object,
  product: PropTypes.object.isRequired,
  shouldDisplayMultipleOneSizes: PropTypes.bool,
};
