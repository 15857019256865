import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';
import { updateSessionDetail } from '@techstyle/redux-core';

import { toggleCartDrawer } from '../../actions/cart';
import {
  getItemCountExcludingMembership,
  getInfluencerItemCountExcludingMembership,
} from '../../utils/selectors';
import { RootState } from '../../utils/types';

const StyledIconButton = styled.a`
  ${props => props.theme.links.variants.navbarLink}
`;

function CartLinkNav() {
  const dispatch = useDispatch();
  const cartItemCount = useSelector<RootState>(state =>
    state.customer.isInfluencer
      ? getInfluencerItemCountExcludingMembership(state)
      : getItemCountExcludingMembership(state)
  );
  const showCartDrawer = () => {
    dispatch(toggleCartDrawer(true, { fetchCart: true }));
    dispatch(updateSessionDetail({ name: 'sxf_minicart', value: '' }));
  };
  return (
    <StyledIconButton
      onClick={showCartDrawer}
      data-autotag="nav-cart-button"
      data-qa-automation="homeShoppingBag"
    >
      <FormattedMessage defaultMessage="Cart" id="global_cta.cart_tt" />
      <span>{`(${cartItemCount})`}</span>
    </StyledIconButton>
  );
}

export default CartLinkNav;
