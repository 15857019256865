import React from 'react';

import { func, shape, string } from 'prop-types';
import styled from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { HTMLAsset } from '@techstyle/react-assets';
import { Button } from '@techstyle/react-components';
import { Countdown } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';

import { desktop } from '../../styles';

const StyledButtonLink = styled(({ bgColor, ...props }) => (
  <Button tagName="a" {...props} />
))`
  ${props => props.theme.button.defaultStyle};
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 16px;
  width: 100%;

  text-align: center;
  background-color: ${props => props.bgColor};
`;

const StyledHTMLAsset = styled(HTMLAsset)`
  font-weight: 500;
  letter-spacing: 0.2px;

  > h1 {
    font-weight: inherit;
  }
`;

const StyledCountdown = styled(Countdown)`
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};

  @media screen and (min-width: 360px) {
    font-size: 16px;
  }

  ${desktop`
    font-size: 20px;
  `};

  ${Countdown.CountdownLabel} {
    text-transform: uppercase;
  }
`;

const AssetCountdown = ({
  className,
  asset,
  onCtaClick,
  onCountdownComplete,
  countDownEndDate,
  ctaText,
  ctaUrl,
  ctaTheme,
  getNow,
}) => {
  const { options } = asset;
  const renderHtml = options.htmlText ? (
    <StyledHTMLAsset asset={asset} />
  ) : null;

  return (
    <Content className={className} bgColor={options.bgColor || 'transparent'}>
      {renderHtml}
      {countDownEndDate && (
        <StyledCountdown
          onComplete={onCountdownComplete}
          color={ctaTheme}
          date={countDownEndDate}
          now={getNow}
          labels={{
            days: (
              <FormattedMessage
                defaultMessage="Days"
                id="site_splash.countdown_days"
              />
            ),
            hours: (
              <FormattedMessage
                defaultMessage="Hours"
                id="site_splash.countdown_hours"
              />
            ),
            minutes: (
              <FormattedMessage
                defaultMessage="Minutes"
                id="site_splash.countdown_minutes"
              />
            ),
            seconds: (
              <FormattedMessage
                defaultMessage="Seconds"
                id="site_splash.countdown_seconds"
              />
            ),
          }}
        />
      )}
      {ctaUrl ? (
        <Link route={ctaUrl} passHref>
          <StyledButtonLink
            onClick={onCtaClick}
            white={ctaTheme.includes('white')}
          >
            {ctaText}
          </StyledButtonLink>
        </Link>
      ) : null}
    </Content>
  );
};

AssetCountdown.propTypes = {
  className: string,
  asset: shape({}),
  onCtaClick: func,
  onCountdownComplete: func,
  countDownEndDate: string,
  ctaText: string,
  ctaUrl: string,
  getNow: func,
  ctaTheme: string,
};

AssetCountdown.defaultProps = {
  asset: { options: {} },
};

AssetCountdown.StyledCountdown = StyledCountdown;
AssetCountdown.Content = Content;

export default AssetCountdown;
