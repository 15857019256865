import { getSession } from '@techstyle/redux-core';

const viewMemberModelEvents = ({ getContext, trackEvent }) => {
  const { TRACK_PRODUCT_MODEL_CHANGE } = require('../../actions/products');
  const viewMemberModel = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'View Member Model',
      properties: {
        feature: 'react',
        category: 'pdp',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        label: action.model.description,
        product_id: action.model.product.master_product_id.toString(),
        automated_test: Boolean(cookies.get('automated_test')),
      },
    };
  });

  return { [TRACK_PRODUCT_MODEL_CHANGE]: viewMemberModel };
};

export default viewMemberModelEvents;
