import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { v1, v2 } from '../../utils/themeVersioning';
import { Component as Price } from '../ExtendedPrice';
import { Component as PromotionProgressBarThresholdAnimation } from '../PromotionProgressBarThresholdAnimation';

const animateProgressBar = (startingWidth, endingWidth) => keyframes`
  0% {
    width: ${startingWidth}%;
  }
  100% {
    width: ${endingWidth}%;
  }
`;

const ProgressWrapper = styled.div`
  width: 260px;
  height: 8px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.promo};
  border-radius: 50px;
  margin: 0 20px 6px;
  background-color: ${props => props.theme.colors.white};
`;

const ProgressFill = styled.div`
  height: 12px;
  width: ${props => props.width}%;
  max-width: 100%;
  background: ${props => props.theme.colors.promo};
  animation: ${props => animateProgressBar(props.startingWidth, props.width)} 1s
    ease-out;
`;

const Wrapper = styled.div`
  ${v1`
    font-size: 12px;
    font-weight: bold;
    color: ${props => props.theme.colors.promo};
    padding-bottom: 16px;
  `}
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph5Uppercase.textStyles}
    background-color: ${props => props.theme.colors.lavender200};
    color: ${props => props.theme.colors.gunmetal};
    padding: ${props =>
      `4px ${props.theme.spacing.tiny}px 4px ${props.theme.spacing.tiny}px`};
    margin-bottom: ${props => props.theme.spacing.tiny}px;
  `}
  text-align: center;
`;

const StyledMessage = styled.span`
  color: ${props =>
    props.defaultBg ? props.theme.colors.default : props.theme.colors.promo};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  font-size: ${props => (props.goalMessage ? 14 : 12)}px;
  letter-spacing: 0.3px;
`;

const StyledSecondaryMessage = styled.div`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.3px;
`;

export default class PromotionProgressBar extends Component {
  static propTypes = {
    className: PropTypes.string,
    isCartLoaded: PropTypes.bool,
    isGoalReached: PropTypes.bool,
    promoThreshold: PropTypes.number.isRequired,
    promoGoalMessage: PropTypes.string,
    promoProgressMessage: PropTypes.string,
    promoType: PropTypes.oneOf(['gwp', 'freeShipping']).isRequired,
    shouldAnimateOnThreshold: PropTypes.bool,
    shouldShowProgressBar: PropTypes.bool,
    total: PropTypes.number.isRequired,
  };

  static defaultProps = {
    shouldShowProgressBar: false,
    total: 0,
  };

  state = {
    prevTotal: this.props.total,
    shouldForce100Width: this.props.isGoalReached,
    shouldShowGoalMessage: this.props.isGoalReached,
  };

  componentDidUpdate(prevProps) {
    if (this.state.shouldShowGoalMessage && !this.props.isGoalReached) {
      this.setState({ shouldShowGoalMessage: false });
    }

    if (this.state.prevTotal !== prevProps.total) {
      this.setState({ prevTotal: prevProps.total });
    }
  }

  renderGoalMessage = () => {
    const { promoGoalMessage, promoType } = this.props;
    if (promoGoalMessage) {
      return promoGoalMessage;
    } else {
      switch (promoType) {
        case 'gwp': {
          return (
            <>
              <StyledMessage goalMessage defaultBg>
                <FormattedMessage
                  id="global_checkout.gwp_congratulation"
                  defaultMessage="You've earned a {freeGift}!"
                  values={{
                    freeGift: (
                      <StyledMessage goalMessage uppercase>
                        <FormattedMessage
                          id="global_checkout.away_from_gwp_message_p2"
                          defaultMessage="free gift!"
                        />
                      </StyledMessage>
                    ),
                  }}
                />
              </StyledMessage>
              <StyledSecondaryMessage>
                <FormattedMessage
                  id="global_checkout.claim_gwp"
                  defaultMessage="Claim your gift at checkout"
                />
              </StyledSecondaryMessage>
            </>
          );
        }
        case 'freeShipping': {
          return (
            <FormattedMessage
              id="global_checkout.free_shipping_congratulation"
              defaultMessage="Congrats! You get Free Shipping."
            />
          );
        }
      }
    }
  };

  renderProgressMessage = () => {
    const { promoProgressMessage, promoType } = this.props;
    if (promoProgressMessage) {
      return promoProgressMessage;
    } else {
      const { total, promoThreshold } = this.props;
      const sumRemainingToPromo = Math.ceil(promoThreshold - total);
      const roundedSumRemainingToPromo =
        sumRemainingToPromo <= 0 ? 0 : sumRemainingToPromo;
      switch (promoType) {
        case 'gwp': {
          return (
            <FormattedMessage
              id="global_checkout.away_from_gwp_message_p1"
              defaultMessage="{price} away from a {freeGift}"
              values={{
                price: (
                  <StyledMessage>
                    <Price amount={roundedSumRemainingToPromo} />
                  </StyledMessage>
                ),
                freeGift: (
                  <StyledMessage goalMessage>
                    <FormattedMessage
                      id="global_checkout.away_from_gwp_message_p2"
                      defaultMessage="free gift!"
                    />
                  </StyledMessage>
                ),
              }}
            />
          );
        }
        case 'freeShipping': {
          return (
            <FormattedMessage
              id="global_checkout.away_from_free_shipping_message"
              defaultMessage="You are {price} away from Free Shipping!"
              values={{
                price: (
                  <strong>
                    <Price amount={sumRemainingToPromo} />
                  </strong>
                ),
              }}
            />
          );
        }
      }
    }
  };

  handleProgressFillAnimationEnd = () => {
    this.setState({ shouldShowGoalMessage: this.props.isGoalReached });
  };

  handleGoalTransitionEnd = () => {
    const { shouldShowGoalMessage } = this.state;
    this.setState({ shouldForce100Width: shouldShowGoalMessage });
  };

  render() {
    const {
      className,
      isCartLoaded,
      promoThreshold,
      shouldAnimateOnThreshold,
      shouldShowProgressBar,
      total,
    } = this.props;
    const { prevTotal, shouldShowGoalMessage, shouldForce100Width } =
      this.state;

    const sumRemainingToPromo = Math.ceil(promoThreshold - total);
    const prevSumRemainingToPromo = Math.ceil(promoThreshold - prevTotal);

    let progressBar = null;

    if (shouldShowProgressBar) {
      const progressWidth = 100 - (sumRemainingToPromo / promoThreshold) * 100;
      const startingWidth = prevSumRemainingToPromo
        ? 100 - (prevSumRemainingToPromo / promoThreshold) * 100
        : progressWidth;

      progressBar = (
        <ProgressWrapper>
          <ProgressFill
            startingWidth={startingWidth}
            width={shouldForce100Width ? 100 : progressWidth}
            onAnimationEnd={this.handleProgressFillAnimationEnd}
          />
        </ProgressWrapper>
      );
    }

    if (!isCartLoaded) {
      return null;
    }

    return shouldAnimateOnThreshold ? (
      <PromotionProgressBarThresholdAnimation
        className={className}
        progressBar={progressBar}
        progressMessage={this.renderProgressMessage()}
        goalMessage={this.renderGoalMessage()}
        onTransitionEnd={this.handleGoalTransitionEnd}
        shouldShowGoalMessage={shouldShowGoalMessage}
      />
    ) : (
      <Wrapper className={className}>
        {progressBar}
        {sumRemainingToPromo > 0
          ? this.renderProgressMessage()
          : this.renderGoalMessage()}
      </Wrapper>
    );
  }
}
