import { useDispatch } from 'react-redux';

import { trackPromotionClick } from '../actions/tracking';

import { buildPromotionTrackingPayload } from './buildPromotionTrackingPayload';

export const useTrackPromotionClick = () => {
  const dispatch = useDispatch();

  return asset => {
    // Sometimes `asset` has an `asset` inside that contains the `options` data
    const isNestedAsset = Object.prototype.hasOwnProperty.call(asset, 'asset');
    const hasClickableUrl =
      asset?.options?.targetUrl ||
      asset?.options?.customVars?.ctaUrl ||
      asset?.asset?.options?.targetUrl ||
      asset?.asset?.options?.customVars?.ctaUrl;

    if (!asset || !hasClickableUrl) {
      return;
    }

    dispatch(
      trackPromotionClick(
        buildPromotionTrackingPayload(isNestedAsset ? asset.asset : asset)
      )
    );
  };
};
