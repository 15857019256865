import { handleActions } from 'redux-actions';

import { LOAD_RETAIL_STORES_SUCCESS } from '../actions/retailStores';

const defaultState = {
  retailStoreList: [],
};

export default handleActions(
  {
    [LOAD_RETAIL_STORES_SUCCESS]: (state, action) => ({
      ...state,
      retailStoreList: action.payload,
    }),
  },
  defaultState
);
