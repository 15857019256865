import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { desktop, mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { Component as CloseButton } from '../CloseButton';
import { Container as SignIn } from '../SignIn';

const signinDialogStyle = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  ${mobile`
    overflow: auto;
  `};
`;

const SignInModal = styled(BasicModal).attrs(props => {
  return {
    overlayColor: props.theme.colors.scrim,
    dialogStyle: signinDialogStyle,
    closeButton: (
      <CloseButton data-qa-automation="signInRegistrationCloseBtn" />
    ),
  };
})`
  z-index: 2000;
`;

const ModalContent = styled.div`
  ${mobile`
    z-index: ${props => props.theme.zIndex.base};
    min-height: 0;
    height: 100%;
    width: 100%;
  `};

  overflow: auto;
  background-color: ${props => props.theme.colors.gray150};

  ${desktop`
    min-width: 768px;
  `};
`;

const DynamicRegistrationModal = ({
  initialScreen,
  isSignInModalOpen,
  location,
  onClose,
  onResetSuccess,
  onSuccess,
  openPrivacyPolicy,
}) => {
  return (
    <SignInModal
      openPrivacyPolicy={openPrivacyPolicy}
      closeButton={<CloseButton />}
      isOpen={isSignInModalOpen}
      onExit={onClose}
      titleId="signin-modal"
    >
      <ModalContent id="signin-modal">
        <SignIn
          onSuccess={onSuccess}
          onResetSuccess={onResetSuccess}
          initialScreen={initialScreen}
          showTermsConditions
          showReferrerField
          extraSignUpData={isSignInModalOpen ? { method: location } : null}
        />
      </ModalContent>
    </SignInModal>
  );
};

DynamicRegistrationModal.propTypes = {
  isSignInModalOpen: PropTypes.bool,
  initialScreen: PropTypes.string,
  location: PropTypes.string,
  onClose: PropTypes.func,
  onResetSuccess: PropTypes.func,
  onSuccess: PropTypes.func,
  openPrivacyPolicy: PropTypes.func,
};

DynamicRegistrationModal.defaultProps = {
  location: '',
};

export default DynamicRegistrationModal;
