import { connect } from 'react-redux';

import ReCAPTCHA from './ReCAPTCHA';

function mapStateToProps(state, ownProps) {
  return {
    locale: state.intl.locale,
  };
}

export default connect(mapStateToProps)(ReCAPTCHA);
