import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDomainRedirectFunction,
  useDomain,
  withCookies,
} from '@techstyle/redux-core';

import { updateBorderfreeStatus } from '../../actions/borderfree';
import { updateCartStatus } from '../../actions/cart';
import { loadCart } from '../../actions/checkout';
import { getCountryRedirect } from '../../utils/borderfree';

// eslint-disable-next-line no-unused-vars
const allEvents = [
  // Triggered when the country cookie is set. This is set only once when
  // the user comes in and the cookie is missing or when the user selects a
  // new country in the context chooser.
  `bfx-country-cookieSet`,

  // Triggered when the currency cookie is set. This is set only once when
  // the user comes in and the cookie is missing or when the user selects a
  // new currency in the context chooser.
  `bfx-currency-cookieSet`,

  // Triggered when the language cookie is set.
  `bfx-language-cookieSet`,

  // Triggered when the isInternational cookie is set. This is set only once
  // when the user comes in and the cookie is missing or when the user
  // selects a new country in the context chooser.
  `bfx-isInternational-cookieSet`,

  // Triggered when the currencyQuoteId cookie is set.
  `bfx-currencyQuoteId-cookieSet`,

  // Triggered when the lcpRuleIs cookie is set.
  `bfx-lcpRuleId-cookieSet`,

  // `Triggered when the session Id (cart Id) cookie is set.`,
  `bfx-sessionId-cookieSet`,

  // Triggered when the isWelcomes cookie is set.
  `bfx-isWelcomed-cookieSet`,

  // Triggered when the global cookie is set.
  `bfx-global-cookieSet`,

  // Triggered when the Envoy session Id cookie is set.
  `bfx-envoySessionId-cookieSet`,

  // Triggered when the Envoy order Id cookie is set.
  `bfx-envoyOrderId-cookieSet`,

  // Triggered when the coupon cookie is set.
  `bfx-coupon-cookieSet`,

  // Triggered when BFX begins to load.
  `bfx-loadBegin`,

  // Triggered when BFX finishes loading.
  `bfx-loadEnd`,

  // Triggered when the context chooser begins to load.
  `bfx-contextChooser-loadBegin`,

  // Triggered when the context chooser finishes loading.
  `bfx-contextChooser-loadEnd`,

  // Triggered when the localizer begins to run.
  `bfx-localizer-loadBegin`,

  // Triggered when the localizer finishes running.
  `bfx-localizer-loadEnd`,

  // Triggered when the welcome mat begins to load.
  `bfx-welcomeMat-loadBegin`,

  // Triggered when the welcome mat finishes loading.
  `bfx-welcomeMat-loadEnd`,
];

const BorderfreeEvents = ({ cookies }) => {
  const router = useRouter();
  const domain = useDomain();
  const noRedirect = useSelector(state => state.borderfree.noRedirect);
  const getDomainRedirect = useSelector(getDomainRedirectFunction);
  const borderfreeEnabled = !cookies.get('no_redirect') || !noRedirect;
  const dispatch = useDispatch();

  const checkForRedirect = country => {
    const redirectTo = getCountryRedirect(country, domain, getDomainRedirect);
    if (redirectTo && redirectTo !== window.location) {
      window.location = redirectTo;
    }
  };

  const handleUpdateBorderfree = () => {
    const isBorderfreeCustomer = cookies.get('bfx.isInternational') === 'true';
    const country = cookies.get('bfx.country') || null;
    // Prevent auto redirect if it has product ID
    if (!router.query.productId) {
      checkForRedirect(country);
    }

    dispatch(updateBorderfreeStatus({ isBorderfreeCustomer, country }));
  };

  const handleFrameCheck = event => {
    const target = event.srcElement || event.target;

    if (target.id === '__frame') {
      const iframe = document.getElementById('__frame');
      iframe.onload = async () => {
        /**
         * Looking for specific pending and success callbacks
         * Not sure if we'll need to change it at some point.
         * Maybe we need to add those callbacks to config
         */
        if (/\/borderfree\/status\/(success|pending)/g.test(iframe.src)) {
          await dispatch(updateCartStatus('expired'));
          await dispatch(loadCart());
        }
      };
    }
  };

  const bindEventListeners = () => {
    document.addEventListener(
      'bfx-isInternational-cookieSet',
      handleUpdateBorderfree
    );

    document.addEventListener('bfx-country-cookieSet', handleUpdateBorderfree);
  };

  const updateFrameListener = useCallback(() => {
    // Remove any existing handler.
    document.removeEventListener('DOMNodeInserted', handleFrameCheck);

    // Add handler only on certain pages.
    if (
      router.pathname === '/cart' ||
      router.pathname.startsWith('/borderfree/')
    ) {
      /**
       * Wait for <iframe id="__frame" ...> appears
       * Then add .onload() method to listen for src changing
       * BF is loading all callbacks in that iframe so we can catch it and check
       */
      document.addEventListener('DOMNodeInserted', handleFrameCheck);
    }
  }, [router.pathname]);

  const removeEventListeners = () => {
    document.removeEventListener(
      `bfx-isInternational-cookieSet`,
      handleUpdateBorderfree
    );
    document.removeEventListener(
      'bfx-country-cookieSet',
      handleUpdateBorderfree
    );
    document.removeEventListener('DOMNodeInserted', handleFrameCheck);
  };

  useEffect(() => {
    if (!borderfreeEnabled) {
      return undefined;
    }

    bindEventListeners();
    handleUpdateBorderfree();

    return () => {
      removeEventListeners();
    };
  }, []);

  useEffect(() => {
    if (!borderfreeEnabled) {
      return undefined;
    }

    updateFrameListener();
  }, [updateFrameListener]);

  return null;
};

BorderfreeEvents.propTypes = {
  cookies: object,
};

export default withCookies(BorderfreeEvents);
