import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from '@techstyle/react-components-legacy';

export const Context = React.createContext({
  toastMessage: null,
  showMessage: () => {},
  hideMessage: () => {},
});

export const useToastContext = () => React.useContext(Context);

const MessageCloseButton = styled(IconButton).attrs({
  icon: {
    size: 12,
    type: 'close',
  },
})`
  color: ${props => props.theme.colors.white};
  min-height: 22px;
  margin-left: auto;
`;

const MessageSpan = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
`;

const ToastContext = ({ children, delay: delayFromProps = 4000 }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const [persist, setPersist] = useState(true);
  const timeoutRef = useRef();

  const clearTimeoutRef = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const hideMessage = useCallback(() => {
    clearTimeoutRef();
    setToastMessage(null);
    setPersist(false);
  }, [clearTimeoutRef]);

  const showMessage = useCallback(
    (toastMessage, delay = delayFromProps) => {
      clearTimeoutRef();

      if (delay !== null) {
        setToastMessage(toastMessage);
        setPersist(false);
        timeoutRef.current = setTimeout(hideMessage, delay);
      } else {
        setToastMessage(
          <>
            <MessageSpan>{toastMessage}</MessageSpan>
            <MessageCloseButton onClick={hideMessage} />
          </>
        );
        setPersist(true);
      }
    },
    [clearTimeoutRef, delayFromProps, hideMessage]
  );

  useEffect(() => {
    return () => {
      clearTimeoutRef();
    };
  }, [clearTimeoutRef]);

  const contextValue = useMemo(
    () => ({
      toastMessage,
      showMessage,
      hideMessage,
      persist,
    }),
    [hideMessage, persist, showMessage, toastMessage]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

ToastContext.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
};
ToastContext.Consumer = Context.Consumer;
ToastContext.Context = Context;

export default ToastContext;
