import { getPartialTrackingData } from '../utils';

const productsSearchedEvents = ({ getContext, trackEvent }) => {
  const { TRACK_PRODUCT_SEARCH } = require('../../actions/products');
  const productsSearched = trackEvent((action, prevState, nextState) => {
    const query = action.query;
    const context = getContext();
    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
      'membership_credits',
    ]);

    return {
      name: 'Products Searched',
      properties: {
        ...additionalTrackingData,
        query,
      },
    };
  });
  return {
    [TRACK_PRODUCT_SEARCH]: productsSearched,
  };
};

export default productsSearchedEvents;
