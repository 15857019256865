import React from 'react';

import Head from 'next/head';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { injectIntl, defineMessages } from '@techstyle/react-intl';

const defaults = defineMessages({
  title: {
    id: 'site_seo_title.brand_title',
    defaultMessage: 'SAVAGE X FENTY | Lingerie by Rihanna',
  },
  uktitle: {
    id: 'site_seo_title.brand_title_uk',
    defaultMessage: 'SAVAGE X FENTY | Lingerie by Rihanna UK',
  },
  description: {
    id: 'site_seo_description.brand_content',
    defaultMessage: 'Intimates for women. Bras, Underwear and Accessories.',
  },
});

const googleTag = {
  '.com': '0G_6bn2RX6FSrXmfWw6BrCESx3drF24bnT6pZn8URN8',
};

function DefaultHead({ intl, title, description, keywords, noIndex }) {
  const tld = useSelector(state => state.domain.tld);
  const hasGoogleTag = tld in googleTag;

  if (title) {
    if (typeof title !== 'string') {
      title = intl.formatMessage(title);
    }
  } else {
    title = intl.formatMessage(
      tld === '.co.uk' ? defaults.uktitle : defaults.title
    );
  }

  if (description) {
    if (typeof description !== 'string') {
      description = intl.formatMessage(description);
    }
  } else {
    description = intl.formatMessage(defaults.description);
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="metadescription" />
      <meta name="keywords" content={keywords} />
      {hasGoogleTag ? (
        <meta name="google-site-verification" content={googleTag[tld]} />
      ) : null}
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
}

DefaultHead.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  intl: PropTypes.object.isRequired,
  noIndex: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keywords: PropTypes.string,
};

export default injectIntl(DefaultHead);
