import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useCustomerDetails } from '@techstyle/react-accounts';

const useProductClickTracking = () => {
  // FIXME: When `imported` instead, the app breaks :(
  const { gatherProductTrackingData } = require('../../segment-events/utils');
  const { trackGridProductCellClick } = require('../../actions/products');

  const dispatch = useDispatch();

  const [profile = {}] = useCustomerDetails(['profile']);

  const time = useSelector(state => state.time || {});

  const trackProductClick = useCallback(
    async (item, position, productSource = null, fplId = null) => {
      const productsClicked =
        (await gatherProductTrackingData(item, {
          customerDetails: { profile },
          dispatch,
          time,
        })) || [];

      productsClicked.forEach(enhancedProductData => {
        dispatch(
          trackGridProductCellClick({
            position,
            list: productSource,
            fpl_id: fplId,
            ...enhancedProductData,
          })
        );
      });
    },
    [
      dispatch,
      gatherProductTrackingData,
      profile,
      time,
      trackGridProductCellClick,
    ]
  );

  return trackProductClick;
};

export default useProductClickTracking;
