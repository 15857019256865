import config from 'config';
import decode from 'decode-html';

const membershipTypes = config.get('public.membershipTypes');

const SUPPORTS_RAF = !!(
  process.browser &&
  window.requestAnimationFrame &&
  window.performance.now
);

/**
 * A helper that uses requestAnimationFrame (if supported) to achieve a
 * `setTimeout`-like result, since the browser deprioritizes `setTimeout`
 * callbacks during scrolling, which can add seconds of delay.
 *
 * Instead of returning a handle ID, you get a function that will cancel the
 * timeout. (This is due to the fact that if `requestAnimationFrame` is used,
 * it will generate an ID for each frame.) Call the returned function with no
 * arguments to cancel.
 */
export function setAnimationTimeout(fn, timeout, ...args) {
  if (SUPPORTS_RAF) {
    let id;
    const startTime = window.performance.now();
    const tick = timestamp => {
      const elapsedTime = timestamp - startTime;
      if (elapsedTime >= timeout) {
        fn(...args);
      } else {
        id = window.requestAnimationFrame(tick);
      }
    };
    id = window.requestAnimationFrame(tick);
    return () => window.cancelAnimationFrame(id);
  } else {
    const id = window.setTimeout(fn, timeout, ...args);
    return () => window.clearTimeout(id);
  }
}

/**
 * A helper to format the incoming mustache variables {{ SPECIAL_URL }} in
 * `htmlContent` from direct marketing sites (dms).
 */
export function replaceDmgHtml(htmlContent, contentObject) {
  if (!htmlContent || !contentObject) {
    return null;
  }

  return htmlContent.replace(
    /\{\{(?:\s+|)(\w+)(?:\s+|)\}\}/g,
    (match, name) => contentObject[name]
  );
}

/**
 * localStorage doesn't work on SSR and it's annoying to
 * constantly check if it's there, so import this to avoid that.
 */
export const localStorage = process.browser
  ? window.localStorage
  : { getItem: () => null, setItem: () => null, removeItem: () => null };

export const sessionStorage = process.browser
  ? window.sessionStorage
  : {
      getItem: () => null,
      setItem: () => null,
      removeItem: () => null,
      clear: () => null,
    };

/**
 * This is a static method so others can use it easily by importing this
 * component (if they want to render a custom asset component, for example).
 */
export function decodeHTML(escapedHTML) {
  if (!escapedHTML) {
    return null;
  }

  // Let's just say assets have a very special idea about HTML escaping.
  return decode(escapedHTML.replace(/(\\r\\n|\\r|\\n|\\t)/g, ' '));
}

/**
 * This is a function that check if the user is in a mobile/tablet device
 */
export function isMobile(navigator) {
  const userAgent = navigator.userAgent.toLowerCase();
  const height = window.screen.availHeight;
  const width = window.screen.availWidth;

  // We check if the userAgent contains mobi for mobile and tablet string
  // Checking for `mobi` anywhere in userAgent is recommended.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  if (userAgent.includes('mobi') || userAgent.includes('tablet')) {
    return true;
  }

  if (userAgent.includes('android')) {
    if (height > width && width < 800) {
      // The user is in portrait mode
      return true;
    }

    if (width > height && height < 800) {
      // The user is in landscape mode
      return true;
    }
  }

  return false;
}

export function getMembershipByProductId(productId) {
  return Object.keys(membershipTypes).find(type => {
    const productIds = membershipTypes[type].productId;
    const ids = Object.keys(productIds).map(tld => productIds[tld]);
    return ids.includes(productId);
  });
}

export function hasMembershipInCart(items) {
  return items.find(({ productId }) => getMembershipByProductId(productId));
}

export function isMondialRelayLocation(tld) {
  return ['.fr', '.es'].includes(tld);
}

// returns if user is lead and has registered 24h ago or less
export function getIsDayOneLead(postRegHours, isLead) {
  return isLead && postRegHours <= 24;
}

// returns if user is lead and has registered more than 24h ago
export function getIsDayTwoPlusLead(postRegHours, isLead) {
  return isLead && postRegHours > 24;
}

/**
 * This function checks if the child object is contained in the parent object
 */
export function isDeepContained(parent, child) {
  if (
    typeof parent !== 'object' ||
    typeof child !== 'object' ||
    parent === null ||
    child === null
  ) {
    return false;
  }

  const keys = Object.keys(child);
  for (const key of keys) {
    if (key in parent === false) {
      return false;
    } else if (Array.isArray(child[key]) && Array.isArray(parent[key])) {
      for (const item of child[key]) {
        if (!parent[key].includes(item)) {
          return false;
        }
      }
    } else if (typeof child[key] === 'object' && child[key] !== null) {
      if (!isDeepContained(parent[key], child[key])) {
        return false;
      }
    } else if (parent[key] !== child[key]) {
      return false;
    }
  }
  return true;
}
