import React from 'react';

import styled from 'styled-components';

import { useTheme } from '@techstyle/react-components';

import { NavBarBGColors } from '../../constants/navBarBGColors';
import { v2 } from '../../utils/themeVersioning';
import { Component as StaticIcon } from '../StaticIcon';

const CaretV2 = props => {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.392312 5.29994L5.70006 -0.00781273L11.0078 5.29994L10.3001 6.00769L5.70006 1.40769L1.10006 6.00769L0.392312 5.29994Z"
        fill="black"
      />
    </svg>
  );
};
const CaretIcon = props => {
  const { themeVersion } = useTheme();
  return themeVersion === 'v1' ? (
    <StaticIcon {...props} />
  ) : (
    <CaretV2 {...props} />
  );
};

export const Caret = styled(CaretIcon).attrs({
  type: 'caret',
  size: 8,
})`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto !important;
  transform: translateY(-3px);
  color: ${props =>
    props.theme.context.backgroundState === NavBarBGColors.TRANSPARENT
      ? props.theme.colors.white
      : props.theme.colors.default};
  transition: all 0.2s ease-out;
  ${v2`
    position: unset;
    margin: 0 !important;
    transform: translateY(-1px) rotate(180deg);
  `}
`;
