import React, { useCallback, useEffect, useState } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  updateCustomerDetail,
  updateProfile,
  useCustomer,
  useCustomerDetail,
} from '@techstyle/react-accounts';
import { FormattedMessage, injectIntl } from '@techstyle/react-intl';
import {
  getNewDateFunction,
  parseDate,
  useSession,
} from '@techstyle/redux-core';

import {
  trackSizePromptAction,
  trackSizeUpdateSuccess,
} from '../../actions/customer/profile';
import { SignUpMethod } from '../../constants/auth';
import toastWrapper from '../../hoc/toastWrapper';
import { mobile, desktop } from '../../styles';
import { BasicButton } from '../../styles/button';
import { BasicModal } from '../../styles/modal';
import { formatProfileData } from '../../utils/formatProfileData';
import { sizeToState } from '../../utils/sizeToState';
import { Component as CloseButton } from '../CloseButton';
import SizeFields from '../SizeFields/SizeFields';

const profileSizes = config.get('public.profileSizes');
const displayInterval = config.get('public.userSizePrompt.displayInterval');
const detailName = 'update_size_prompt_seen';

const dialogStyle = css`
  display: flex;
  overflow: visible;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  ${desktop`
    width: 600px;
  `};

  ${mobile`
    margin: 0 ${props => props.theme.spacing.small}px
    56px ${props => props.theme.spacing.small}px;
  `};
`;

const UserSizePromptModal = styled(BasicModal)``;

const SizeContentBackground = styled.div`
  padding: 8px;
  background-image: url('/static/images/user_size_prompt_bg.jpg');
`;

const SizeContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.moderate}px
    ${props => props.theme.spacing.small}px;
  ${mobile`
    padding: ${props => props.theme.spacing.small}px;
  `}
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

const Separator = styled.hr`
  border: 0;
  height: 1px;
  width: 160px;
  margin: ${props => props.theme.spacing.small}px auto;
  background: ${props => props.theme.colors.lavender400};
`;

const SubTitle = styled.div`
  width: 340px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.3px;

  ${mobile`
    width: 246px;
  `}
`;

const SizeContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${desktop`
    margin-top: 10px;
    > * {
      padding-right: 10px;
      padding-left: 10px;
    }
  `};
`;

const SaveButton = styled(BasicButton).attrs({
  type: 'submit',
})`
  width: 100%;
  margin-top: ${props => props.theme.spacing.small}px;

  ${desktop`
    width: auto;
    min-width: 148px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${props => props.theme.spacing.large}px;
  `};
`;

const StyledCloseButton = styled(CloseButton)`
  width: 46px;
  height: 46px;
  line-height: 46px;
`;

const UserSizePrompt = ({ hideToastMessage, showToastMessage }) => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSizeFieldChanged, setIsSizeFieldChanged] = useState(false);
  const [wasModalOpen, setWasModalOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const getCurrentDate = useSelector(getNewDateFunction);
  const { signInMethod, location: signInLocation } = useSelector(
    state => state.auth
  );
  const sizePromptSeenDetail = useCustomerDetail(detailName);
  const customer = useCustomer();
  const { isLoggedIn } = useSession();
  const isInfluencer = !!customer?.isInfluencer;
  const dispatch = useDispatch();

  const isEligiblePromptLocation = useCallback(() => {
    // we decide to exclude influencer from seeing this prompt because
    // 1. we are asking influencer to fill in all sizes info when reset password
    // 2. they are already seeing a promo prompt when sign in for the second time
    return (
      !isInfluencer &&
      isLoggedIn &&
      signInMethod === SignUpMethod.SIGN_IN &&
      signInLocation !== SignUpMethod.CARTDRAWER &&
      signInLocation !== SignUpMethod.CART_CHECKOUT &&
      signInLocation !== SignUpMethod.WAITLIST
    );
  }, [isInfluencer, isLoggedIn, signInMethod, signInLocation]);

  const getMissingProfileSizes = useCallback(() => {
    const { optionSignatures } = customer;
    if (!optionSignatures) {
      return 'all';
    }
    const sizeMissing = category => {
      const field = profileSizes[category];
      return !optionSignatures[field] || !optionSignatures[field].length;
    };
    // display bralette prompt when ONLY bralette size is missing
    if (
      sizeMissing('bras') ||
      sizeMissing('undies') ||
      sizeMissing('lingerieSleep')
    ) {
      return 'all';
    } else if (sizeMissing('bralettes')) {
      return 'bralettes';
    } else {
      return null;
    }
  }, [customer]);

  const updateModalSeen = useCallback(() => {
    dispatch(
      updateCustomerDetail({
        name: detailName,
        value: getCurrentDate().toISOString(),
      })
    );
  }, [dispatch, getCurrentDate]);

  const checkPromptEligibility = useCallback(async () => {
    if (!isLoaded || wasModalOpen) {
      return;
    }

    if (!isEligiblePromptLocation()) {
      return;
    }

    if (sizePromptSeenDetail.value) {
      // check the timeElapsed when making sure the prompt has been seen before

      // For lastSeenDateTime:
      // 1. for existing users, if they saw the prompt once before, the value woule be true (a boolean) due to the old set up,
      // which we can't parse correct date&time from, and dateTimeAdded will be last seen date time,
      // and value will be updated to dateTime when they see the prompt again over 30days, and we can use it for next tracking.
      // 2. for new registered user, once they see the prompt once, the value will be updated a dateTime
      // and value will be the lase seen data time for tracking next 30 days
      const lastSeenDateTime =
        sizePromptSeenDetail.value === true
          ? sizePromptSeenDetail.dateTimeAdded
          : sizePromptSeenDetail.value;
      const timeElapsed = getCurrentDate() - parseDate(lastSeenDateTime);

      if (timeElapsed < displayInterval) {
        return;
      }
    }

    const missingSizes = getMissingProfileSizes();
    if (missingSizes) {
      setIsModalOpen(true);
      setWasModalOpen(true);
      dispatch(trackSizePromptAction({ eventLabel: 'Pop-up' }));
      updateModalSeen();
    }
  }, [
    dispatch,
    getCurrentDate,
    getMissingProfileSizes,
    isEligiblePromptLocation,
    isLoaded,
    sizePromptSeenDetail,
    updateModalSeen,
    wasModalOpen,
  ]);

  const closeModal = () => setIsModalOpen(false);

  const handleClickOnCloseButton = () => {
    closeModal();
    // only tracking this event when close from the X button
    dispatch(trackSizePromptAction({ eventLabel: 'Exit' }));
  };

  const handleSizeChange = field => event => {
    setProfile({
      ...profile,
      [field]: event.value,
    });
    setIsSizeFieldChanged(true);
  };

  const handleSizeSave = async event => {
    event.preventDefault();
    const originalSizes = sizeToState(profile);
    let result;
    let error;

    try {
      result = await dispatch(updateProfile(formatProfileData(profile)));
      dispatch(trackSizePromptAction({ eventLabel: 'Update' }));
      hideToastMessage();
    } catch (err) {
      error = err;
      showToastMessage(
        <FormattedMessage
          id="site_account_profile.size_prompt_error_toast_message"
          defaultMessage="Oops! We couldn't save your sizes. Please visit your Profile to try again."
        />
      );
    }

    if (result && !error) {
      // the eventLabel for trackSizeUpdateSuccess is an array of edited size names
      // for example: [braSize, braletteSize]
      const editedSizes = Object.keys(originalSizes).filter(
        key => originalSizes[key] !== profile[key]
      );

      if (editedSizes.length) {
        dispatch(trackSizeUpdateSuccess({ eventLabel: editedSizes }));
      }

      showToastMessage(
        <FormattedMessage
          id="site_account_profile.size_prompt_success_toast_message"
          defaultMessage="Your sizes were saved to your Profile successfully!"
        />
      );
    }
    closeModal();
    setIsSizeFieldChanged(false);
  };

  useEffect(() => {
    checkPromptEligibility();
  }, [checkPromptEligibility]);

  useEffect(() => {
    const profile = sizeToState(customer);

    setProfile(profile);
  }, [customer]);

  useEffect(() => {
    const sizePrompNetworkStatus = sizePromptSeenDetail.networkStatus;
    const customerNetworkStatus = customer.networkStatus;

    setIsLoaded(
      sizePrompNetworkStatus.isUpToDate &&
        !sizePrompNetworkStatus.isLoading &&
        customerNetworkStatus.isUpToDate &&
        !customerNetworkStatus.isLoading
    );
  }, [sizePromptSeenDetail.networkStatus, customer.networkStatus]);

  const isLivePage = router.asPath.includes('live');

  if (isLivePage) {
    return null;
  }

  return (
    <UserSizePromptModal
      closeButton={
        <StyledCloseButton iconSize={14} onClick={handleClickOnCloseButton} />
      }
      dialogStyle={dialogStyle}
      titleId="user-size-modal"
      isOpen={isModalOpen}
    >
      <SizeContentBackground>
        <SizeContent>
          <Title id="user-size-modal">
            <FormattedMessage
              id="site_account_profile.size_prompt_title"
              defaultMessage="hey sis, what's your size?"
            />
          </Title>
          <Separator />
          <SubTitle>
            <FormattedMessage
              id="site_account_profile.size_prompt_subtitle"
              defaultMessage="Want to see more styles you’ll love? Just update your info!"
            />
          </SubTitle>
          <SizeContainer onSubmit={handleSizeSave}>
            <SizeFields profile={profile} onChange={handleSizeChange} />
            <SaveButton disabled={!isSizeFieldChanged}>
              <FormattedMessage
                id="site_account_profile.save_button"
                defaultMessage="Save"
              />
            </SaveButton>
          </SizeContainer>
        </SizeContent>
      </SizeContentBackground>
    </UserSizePromptModal>
  );
};

UserSizePrompt.propTypes = {
  hideToastMessage: PropTypes.func,
  showToastMessage: PropTypes.func,
};

export default toastWrapper(injectIntl(UserSizePrompt));
