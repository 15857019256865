import { getSectionsAndQueryFromPath } from './routing';

function translateNavToCategoryHierarchy({
  navItem,
  isTopLevel = false,
  newSectionPath,
  isVipSalePage,
  isLeadSalePage,
}) {
  const { isResourceBundle, label, navItems, url } = navItem;

  // replace /products to /products/new or /products/new-vip for New pages
  let urlPath = url;
  if (newSectionPath) {
    urlPath = url.replace(/\/products/, newSectionPath);
  }
  if (isVipSalePage) {
    urlPath = url.replace(/\/products/, '/sale');
  }
  if (isLeadSalePage) {
    urlPath = url.replace(/\/products/, '/sale-secondary');
  }

  const subcategories =
    navItems && navItems.length
      ? navItems.map(item =>
          translateNavToCategoryHierarchy({
            navItem: item,
            newSectionPath,
            isVipSalePage,
            isLeadSalePage,
          })
        )
      : [];

  return {
    ancestors: [],
    siblings: [],
    isTopLevel,
    label,
    isResourceBundle,
    subcategories,
    urlPath,
  };
}

export function getCategories({
  navItems,
  asPath,
  newSectionPath,
  productsAsset,
  isVipSalePage,
  isLeadSalePage,
}) {
  const {
    section: asPathSection,
    subsection: asPathSubSection,
    defaultTagId: asPathDefaultTagId,
  } = getSectionsAndQueryFromPath(asPath);

  const assetLabel =
    productsAsset &&
    productsAsset.asset &&
    productsAsset.asset.options.customVars.label;

  const defaultLabel = assetLabel || asPathSection;

  const defaultActiveCategory = {
    ancestors: [],
    isTopLevel: true,
    label: defaultLabel,
    siblings: [],
    subcategories: [],
    url: asPath,
  };

  let activeCategory = defaultActiveCategory;
  let breadcrumbs = [defaultActiveCategory];
  let navLevelOne;

  if (!asPathSection) {
    if (isVipSalePage || isLeadSalePage) {
      navLevelOne = navItems.find(
        item => item.url === '/sale' || item.url === '/sale-secondary'
      );
    } else {
      navLevelOne = newSectionPath
        ? { ...navItems[0], label: 'New' }
        : navItems[0];
    }
  } else {
    navLevelOne = navItems.find(item => {
      const { section } = getSectionsAndQueryFromPath(item.url);
      return section === asPathSection;
    });
  }

  if (!navLevelOne || !navLevelOne.navItems) {
    return {
      activeCategory,
      breadcrumbs,
    };
  }

  /** when there is no subcategory, we allow curvy page to be a navLevelTwo
   *  so View All and curvy can be activeCategory separately */

  const navLevelTwo =
    asPathSubSection || asPathDefaultTagId
      ? navLevelOne.navItems.find(item => {
          const { subsection, defaultTagId } = getSectionsAndQueryFromPath(
            item.url
          );
          return (
            asPathDefaultTagId === defaultTagId &&
            subsection === asPathSubSection
          );
        })
      : null;

  // organize breadcrumbsLevelOne  with siblings
  const breadcrumbsLevelOne = translateNavToCategoryHierarchy({
    navItem: navLevelOne,
    isTopLevel: true,
    newSectionPath,
    isVipSalePage,
    isLeadSalePage,
  });

  if (breadcrumbsLevelOne) {
    activeCategory = breadcrumbsLevelOne;
    breadcrumbsLevelOne.ancestors = [];
    breadcrumbsLevelOne.siblings = navItems.map(siblingItem =>
      translateNavToCategoryHierarchy({
        navItem: siblingItem,
        isTopLevel: true,
        newSectionPath,
        isVipSalePage,
        isLeadSalePage,
      })
    );
  }

  // organize breadcrumbsLevelTwo  with siblings, ancestors
  const breadcrumbsLevelTwo = navLevelTwo
    ? translateNavToCategoryHierarchy({
        navItem: navLevelTwo,
        isTopLevel: false,
        newSectionPath,
        isVipSalePage,
        isLeadSalePage,
      })
    : null;
  if (breadcrumbsLevelTwo) {
    activeCategory = breadcrumbsLevelTwo;
    breadcrumbsLevelTwo.ancestors = [breadcrumbsLevelOne];
    breadcrumbsLevelTwo.siblings = navLevelOne.navItems.map(siblingItem =>
      translateNavToCategoryHierarchy({
        navItem: siblingItem,
        isTopLevel: false,
        newSectionPath,
        isVipSalePage,
        isLeadSalePage,
      })
    );
  }

  breadcrumbs = [breadcrumbsLevelOne];
  if (breadcrumbsLevelTwo) {
    breadcrumbs.push(breadcrumbsLevelTwo);
  }

  return {
    activeCategory,
    breadcrumbs,
  };
}
