import React, { useEffect, useRef } from 'react';

import { differenceInYears } from 'date-fns/differenceInYears';
import { useRouter } from 'next/router';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import scrollIntoView from 'scroll-into-view-if-needed';
import styled, { css } from 'styled-components';

import { TextField } from '@techstyle/react-components';
import {
  useIntl,
  defineMessages,
  FormattedMessage,
} from '@techstyle/react-intl';
import {
  getNewDateFunction,
  getDateNowFunction,
  parseDate,
} from '@techstyle/redux-core';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v1, v2 } from '../../utils/themeVersioning';

const placeholders = defineMessages({
  month: {
    id: 'global_checkout.quiz_birthday_month_placeholder',
    defaultMessage: 'MM',
  },
  day: {
    id: 'global_checkout.quiz_birthday_day_placeholder',
    defaultMessage: 'DD',
  },
  year: {
    id: 'global_checkout.quiz_birthday_year_placeholder',
    defaultMessage: 'YYYY',
  },
});

const birthdayFields = ['birthMonth', 'birthDay', 'birthYear'];

const maxMonthDay = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const getHintStyle = () => css`
  ${v2`
    ${({ theme }) => theme.hintInputV2.defaultStyle};
  `}
`;

const getInputBoxStyle = error => css`
  ${v1`
    ${({ theme }) => theme.inputBox.defaultStyle};
  `}
  ${v2`
    ${({ theme }) =>
      error
        ? theme.inputBoxV2.inputBoxErrorStyle
        : theme.inputBoxV2.defaultStyle};
    &[data-label-position='inside'] {
      padding-right:  ${({ theme }) => theme.spacing.tiny};px;
    }
  `}
`;

const getInputStyle = () => css`
  ${v1`
    ${({ theme }) => theme.input.defaultStyle};
  `}
  ${v2`
    ${({ theme }) => theme.inputV2.defaultStyle};
    padding-right: 0;
  `}
`;

const getLabelStyle = error => css`
  ${v1`
    white-space: nowrap;
  `}
  ${v2`
    ${({ theme }) =>
      error
        ? theme.labelInputV2.labelInputErrorStyle
        : theme.labelInputV2.defaultStyle};
  `}
`;

const getErrorStyle = () => css`
  ${v2`
    ${({ theme }) => theme.errorInputV2.defaultStyle};
    &[data-label-position='inside'] {
      padding-right:  ${({ theme }) => theme.spacing.tiny};px;
    }
  `}
`;

const BirthdayInputWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
`;

const BirthdayInputField = styled(TextField).attrs(
  ({ error, variant, theme }) =>
    theme.themeVersion === 'v2'
      ? {
          errorStyle: getErrorStyle(),
          inputBoxStyle: getInputBoxStyle(error),
          inputStyle: getInputStyle(),
          labelStyle: getLabelStyle(error),
          hintStyle: getHintStyle(variant || 'default'),
        }
      : {
          inputBoxStyle: getInputBoxStyle(error),
          inputStyle: getInputStyle(),
          labelStyle: getLabelStyle(error),
        }
)`
  margin-right: ${props => props.theme.spacing.small}px;
  &:last-child {
    margin-right: 0;
  }
  width: 100%;
`;

const TextHint = styled.p`
  text-align: left;
  font-size: 10px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.subdued};
`;

const FooterError = styled.div`
  min-height: 15px;
  font-size: 9px;
  color: ${props => props.theme.colors.subdued};
  font-weight: 300;
  line-height: ${15 / 10};

  ${v1`
    margin-top: 4px;
  `}
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.error};
  text-align: left;
`;

const DefaultMessage = styled.p`
  color: ${props => props.theme.colors.subdued};
  text-align: left;
`;

const BirthdayInput = ({
  birthDay,
  birthMonth,
  birthYear,
  className,
  hint,
  invalidateErrors,
  validationResult,
  onError,
  onChange,
  onBlur,
  minimumAge,
  showBirthYear,
}) => {
  const router = useRouter();
  const { query } = router;
  const intl = useIntl();
  const { 'theme-to-enable': themeToEnable } = useLDFlags();

  const birthMonthInputRef = useRef();
  const getNewDate = useSelector(state => getNewDateFunction(state));
  const getDateNow = useSelector(state => getDateNowFunction(state));

  const scrollToTarget = query['scroll-to'];
  const autoFocusFirstInput = scrollToTarget === 'birthmonth-input';

  useEffect(() => {
    if (
      autoFocusFirstInput &&
      birthMonthInputRef &&
      birthMonthInputRef.current
    ) {
      scrollIntoView(birthMonthInputRef.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      });
      birthMonthInputRef.current.focus();
    }
  }, [autoFocusFirstInput, birthMonthInputRef]);

  const calculateAge = birthdate => {
    const now = getNewDate();
    return differenceInYears(now, birthdate);
  };

  const validateMinimumAge = fieldValues => {
    const { birthMonth, birthDay, birthYear } = fieldValues;
    const value = calculateAge(new Date(birthYear, birthMonth - 1, birthDay));

    if (value < minimumAge) {
      return {
        isValid: false,
        isPotentiallyValid: false,
        error: 'ERROR_MINIMUM_AGE',
      };
    } else {
      // reset error on other birthday fields
      for (const field in validationResult) {
        if (
          typeof onError === 'function' &&
          validationResult[field] &&
          birthdayFields.includes(field)
        ) {
          onError(field)(null);
        }
      }
    }
  };

  const validateFields = (field, event) => {
    const fieldValue = event.target.value;
    let error = null;
    const currentYear = parseDate(getDateNow()).getFullYear();

    const isDecimal = !Number.isInteger(Number(fieldValue));
    if (isDecimal) {
      error = {
        isValid: false,
        isPotentiallyValid: false,
        error: 'ERROR_INVALID',
      };
    }

    if (field === 'birthMonth') {
      if (fieldValue) {
        const value = parseInt(fieldValue, 10);
        if (!value || value < 0 || value > 12) {
          error = {
            isValid: false,
            isPotentiallyValid: false,
            error: 'ERROR_INVALID',
          };
        }
      }
      if (birthDay && !fieldValue) {
        error = {
          isValid: false,
          isPotentiallyValid: false,
          error: 'ERROR_INVALID',
        };
      }
    } else if (field === 'birthDay') {
      if (fieldValue) {
        const value = parseInt(fieldValue, 10);
        const parsedMonth = parseInt(birthMonth);
        const maxBirthDay = maxMonthDay[parsedMonth];
        if (!value || value < 0 || value > maxBirthDay) {
          error = {
            isValid: false,
            isPotentiallyValid: false,
            error: 'ERROR_INVALID',
          };
        }
      }
      if (birthMonth && !fieldValue) {
        error = {
          isValid: false,
          isPotentiallyValid: false,
          error: 'ERROR_INVALID',
        };
      }
    } else {
      if (fieldValue) {
        if (!birthMonth || !birthDay) {
          error = {
            isValid: false,
            isPotentiallyValid: false,
            error: 'ERROR_INVALID',
          };
        }
      }
    }

    // if there's no error, check minimum age
    if (showBirthYear && !error && fieldValue) {
      error = validateMinimumAge({
        birthMonth: field === 'birthMonth' ? fieldValue : birthMonth,
        birthDay: field === 'birthDay' ? fieldValue : birthDay,
        birthYear: field === 'birthYear' ? fieldValue : birthYear,
      });
    }

    if (
      showBirthYear &&
      field === 'birthYear' &&
      (fieldValue < 1900 || fieldValue > currentYear)
    ) {
      error = {
        isValid: false,
        isPotentiallyValid: false,
        error: 'ERROR_INVALID',
      };
    }

    if (typeof onError === 'function') {
      onError(field)(error);
    }

    // when birthDay nor birthMonth is not required and empty then remove error
    if (!showBirthYear && !birthDay && !birthMonth) {
      invalidateErrors();
    }
  };

  const handleBlur = field => event => {
    onBlur(field)(event);
    validateFields(field, event);
  };

  const getMaxBirthDay = birthMonth => {
    // Could be NaN or 0.
    if (parseInt(birthMonth, 10)) {
      return maxMonthDay[birthMonth] || 31;
    }
    return 31;
  };

  const handleChange = field => event => {
    onChange(field)(event);
    validateFields(field, event);
  };

  return (
    <BirthdayInputWrapper className={className}>
      {themeToEnable !== 'newTheme' && (
        <TextHint>
          <FormattedMessage
            id="site_account_profile.birthday_input_label"
            defaultMessage="Birthday"
          />{' '}
          {hint}
        </TextHint>
      )}
      <Wrapper>
        <BirthdayInputField
          data-autotag="pii-birthday-input-month"
          aria-label="Birth Month Input"
          ref={birthMonthInputRef}
          type="number"
          name="birthMonth"
          value={birthMonth}
          placeholder={intl.formatMessage(placeholders.month)}
          label={
            themeToEnable === 'newTheme'
              ? intl.formatMessage(placeholders.month)
              : null
          }
          pattern="[0-9]*"
          inputMode="numeric"
          min="1"
          max="12"
          onChange={handleChange('birthMonth')}
          onBlur={handleBlur('birthMonth')}
          error={
            validationResult.birthMonth && !validationResult.birthMonth.isValid
          }
        />
        <BirthdayInputField
          data-autotag="pii-birthday-input-day"
          aria-label="Birth Day Input"
          type="number"
          name="birthDay"
          value={birthDay}
          placeholder={intl.formatMessage(placeholders.day)}
          label={
            themeToEnable === 'newTheme'
              ? intl.formatMessage(placeholders.day)
              : null
          }
          pattern="[0-9]*"
          inputMode="numeric"
          min="1"
          max={getMaxBirthDay(birthMonth)}
          onChange={handleChange('birthDay')}
          onBlur={handleBlur('birthDay')}
          error={
            validationResult.birthDay && !validationResult.birthDay.isValid
          }
        />
        {showBirthYear && (
          <BirthdayInputField
            data-autotag="pii-birthday-input-year"
            aria-label="Birth Year Input"
            type="number"
            name="birthYear"
            value={birthYear}
            placeholder={intl.formatMessage(placeholders.year)}
            label={
              themeToEnable === 'newTheme'
                ? intl.formatMessage(placeholders.year)
                : null
            }
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={handleChange('birthYear')}
            onBlur={handleBlur('birthYear')}
            error={
              validationResult.birthYear && !validationResult.birthYear.isValid
            }
          />
        )}
      </Wrapper>
      {themeToEnable === 'newTheme' && (
        <TextHint>
          <FormattedMessage
            id="site_account_profile.birthday_input_label"
            defaultMessage="Birthday"
          />{' '}
          {hint}
        </TextHint>
      )}
      <FooterError>
        {!validationResult.birthYear &&
        !validationResult.birthMonth &&
        !validationResult.birthDay ? (
          showBirthYear && (
            <DefaultMessage>
              <FormattedMessage
                id="site_account_profile.minimum_age_limit"
                defaultMessage="Must be at least {minimumAge} years of age."
                values={{ minimumAge: minimumAge }}
              />
            </DefaultMessage>
          )
        ) : validationResult.birthMonth &&
          !validationResult.birthMonth.isValid ? (
          <ErrorMessage>
            {validationResult.birthMonth.error === 'ERROR_INVALID' ? (
              <FormattedMessage
                id="site_account_profile.error_invalid_date"
                defaultMessage="Enter a valid date"
              />
            ) : (
              <FormattedMessage
                id="site_account_profile.minimum_age_limit"
                defaultMessage="Must be at least {minimumAge} years of age."
                values={{ minimumAge: minimumAge }}
              />
            )}
          </ErrorMessage>
        ) : validationResult.birthDay && !validationResult.birthDay.isValid ? (
          <ErrorMessage>
            {validationResult.birthDay.error === 'ERROR_INVALID' ? (
              <FormattedMessage
                id="site_account_profile.error_invalid_date"
                defaultMessage="Enter a valid date"
              />
            ) : (
              <FormattedMessage
                id="site_account_profile.minimum_age_limit"
                defaultMessage="Must be at least {minimumAge} years of age."
                values={{ minimumAge: minimumAge }}
              />
            )}
          </ErrorMessage>
        ) : validationResult.birthYear &&
          !validationResult.birthYear.isValid ? (
          <ErrorMessage>
            {validationResult.birthYear.error === 'ERROR_INVALID' ? (
              <FormattedMessage
                id="site_account_profile.error_enter_valid_date_birth"
                defaultMessage="Please enter valid date of birth. Must be at least {minimumAge} years of age."
                values={{ minimumAge: minimumAge }}
              />
            ) : (
              <FormattedMessage
                id="site_account_profile.minimum_age_limit"
                defaultMessage="Must be at least {minimumAge} years of age."
                values={{ minimumAge: minimumAge }}
              />
            )}
          </ErrorMessage>
        ) : null}
      </FooterError>
    </BirthdayInputWrapper>
  );
};

BirthdayInput.DefaultMessage = DefaultMessage;
BirthdayInput.ErrorMessage = ErrorMessage;
BirthdayInput.Field = BirthdayInputField;

BirthdayInput.propTypes = {
  birthDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  birthMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  birthYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  invalidateErrors: PropTypes.func,
  validationResult: PropTypes.object,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  minimumAge: PropTypes.number,
  showBirthYear: PropTypes.bool,
};

export default BirthdayInput;
