const formatProductUrl = ({
  extraQueryParams,
  permalink,
  productId,
  productType,
  searchQuery,
  pSource,
}) => {
  if (!productId) {
    throw new Error('src/utils/formatProductUrl requires a product id.');
  }

  const permalinkUrl = permalink ? permalink.toLowerCase() : '';
  const urlParameters = [];
  let as;

  if (productType === 'sets') {
    as = permalinkUrl
      ? `/shop/sets/${permalinkUrl}-${productId}`
      : `/products/sets/${productId}`;
  } else {
    as = permalinkUrl
      ? `/shop/${permalinkUrl}-${productId}`
      : `/products/${productId}`;
  }

  if (!pSource && extraQueryParams && extraQueryParams.psrc) {
    pSource = extraQueryParams.psrc;
  }

  if (searchQuery) {
    urlParameters.push(`q=${encodeURIComponent(searchQuery)}`);
  }

  if (pSource) {
    urlParameters.push(`psrc=${pSource}`);
  }

  if (extraQueryParams) {
    Object.keys(extraQueryParams).forEach(key => {
      // Don't add `psrc` or `q` in the url parameters since they are already handled above
      // `isClickFromPdp` is a special case that is used to track clicks from the PDP, but
      // it is not a query parameter that should be included in the url
      if (!['q', 'psrc', 'isClickFromPdp'].includes(key)) {
        urlParameters.push(`${key}=${extraQueryParams[key]}`);
      }
    });
  }

  if (urlParameters.length) {
    as += `?${urlParameters.join('&')}`;
  }

  return {
    href: {
      pathname: '/product',
      query: {
        productId,
        permalink: permalinkUrl,
        productType,
        q: searchQuery,
        ...extraQueryParams,
        psrc: pSource,
      },
    },
    as,
  };
};

export default formatProductUrl;
