import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';
import { createGlobalStyle } from 'styled-components';

import borderFreeStyles from '../../styles/borderfree';

const BorderFreeStyles = createGlobalStyle`
  ${borderFreeStyles}
`;

/**
 * A component that will inject Borderfree's JavaScript snippet and configure
 * it via props. The default configuration comes from `config`, so you likely
 * don't need to pass anything except for overrides. Intended to be conditionally
 * rendered in _doccument.js during SSR, depending on the needs of the request
 * being served.
 */
export default function BorderfreeSnippet({
  hostname = config.get('public.borderfree.hostname'),
  key = config.get('public.borderfree.key'),
  env = config.get('public.borderfree.env'),
  mode = config.get('public.borderfree.mode'),
  brand = config.get('public.borderfree.brand'),
  logLevel = config.get('public.borderfree.logLevel'),
  ...moreOptions
}) {
  const options = {
    key,
    env,
    mode,
    brand,
    logLevel,
    ...moreOptions,
  };
  // We could use a real script loader to load the Borderfree JS in a nice way
  // (or even put it in GTM, for example). That way we wouldn't need this ugly
  // minified JS, or manual `<script>` injection, or `dangerouslySetInnerHTML`.
  // But third-party vendors are very touchy about modifying their snippets
  // (because people often mess it up). So for now, just use it as-is.
  return (
    <>
      <BorderFreeStyles />
      <script
        id="bfx-snippet"
        dangerouslySetInnerHTML={{
          __html: `
  (function(e,n,o,t,a){function c(n){e[t]._apiKey=n.key;e[t]._env=n.env;e[t]._logLevel=n.logLevel;
  e[t]._mode=n.mode;e[t]._brand=n.brand;e[t]._cookieFlag=n.cookieFlag}e[t]=c;e[t].l=1*new Date;var i,l;
  i=n.createElement(o);l=n.getElementsByTagName(o)[0];i.async=1;i.src=a;l.parentNode.insertBefore(i,l);
  })(window,document,"script","bfx","https://${hostname}/v1/dist/bfx.js");
  bfx(${serialize(options)});
          `,
        }}
      />
    </>
  );
}

BorderfreeSnippet.propTypes = {
  /**
   * Hostname from which to load the Borderfree snippet.
   */
  hostname: PropTypes.string,
  /**
   * Borderfree API key.
   */
  key: PropTypes.string,
  /**
   * Borderfree environment, e.g. 'STAGE'.
   */
  env: PropTypes.string,
  /**
   * Borderfree mode, e.g. 'default'.
   */
  mode: PropTypes.string,
  /**
   * Borderfree brand, e.g. 'default'.
   */
  brand: PropTypes.string,
  /**
   * Log level for Borderfree messages, e.g. 'DEBUG'.
   */
  logLevel: PropTypes.string,
};
