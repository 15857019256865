import React from 'react';

import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { v2 } from '../../utils/themeVersioning';

const Container = styled.div`
  height: 100%;
  ${v2`
    height: unset;
  `}
`;

function OnClickOutside({ setIsOpen, ...rest }) {
  if (!setIsOpen) {
    throw new Error('setIsOpen prop required inside OnClickOutside');
  }
  OnClickOutside.handleClickOutside = () => setIsOpen(false);
  return <Container {...rest} />;
}

OnClickOutside.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default onClickOutside(OnClickOutside, {
  handleClickOutside: () => OnClickOutside.handleClickOutside,
});
