import config from 'config';
import { addMinutes, isAfter } from 'date-fns';
import Router from 'next/router';

import { splitTest } from '@techstyle/react-abtest';
import {
  invalidateMembership,
  invalidateMembershipPeriod,
  invalidateProfile,
  loadMembership,
  loadMembershipPeriod,
  loadProfile,
  getMembership,
  getMembershipPeriod,
  updateCustomerDetail,
  getCustomerDetail,
  updateMembershipStatusSuccess,
} from '@techstyle/react-accounts';
import { loadAssets } from '@techstyle/react-assets';
import { invalidateNavs } from '@techstyle/react-navigation';
import { loadPromos } from '@techstyle/react-promos';
import { getDateNowFunction, getSession } from '@techstyle/redux-core';

import {
  MembershipActionLocation,
  MembershipEventAction,
} from '../../constants/checkout';
import {
  getShippingInfo,
  getPaymentInfo,
  getDefaultAddress,
  getDefaultPaymentMethod,
  getFilteredShippingOptions,
  getMembershipCartItem,
  hasFreeTrialMembershipInCart,
  isUnlockedXclusiveProductVisible as getIsUnlockedXclusiveProductVisible,
  getDefaultMembershipProductId,
  hasOnlyMembershipCartItem,
  hasPromoConflict,
  isVipLiveShoppingPromoAppliedSelector,
  getGwpPromo,
} from '../../utils/selectors';
import { validateShippingOption } from '../../utils/validateShipping';
import {
  loadAddresses,
  loadAccountPaymentMethods,
  deleteMembershipPromo,
} from '../account';
import { useStoreCredit } from '../cart';
import { subscribeNewFreeTrialMember } from '../contacts';

import * as checkoutFunctions from './index';

export const GET_AFTERPAY_TOKEN = 'GET_AFTERPAY_TOKEN';
export const GET_AFTERPAY_TOKEN_SUCCESS = 'GET_AFTERPAY_TOKEN_SUCCESS';
export const GET_AFTERPAY_TOKEN_FAILURE = 'GET_AFTERPAY_TOKEN_FAILURE';

export const GET_CASHAPP_TOKEN = 'GET_CASHAPP_TOKEN';
export const GET_CASHAPP_TOKEN_SUCCESS = 'GET_CASHAPP_TOKEN_SUCCESS';
export const GET_CASHAPP_TOKEN_FAILURE = 'GET_CASHAPP_TOKEN_FAILURE';

export const CHECKOUT_AFTERPAY_REQUEST = 'CHECKOUT_AFTERPAY_REQUEST';
export const CHECKOUT_AFTERPAY_SUCCESS = 'CHECKOUT_AFTERPAY_SUCCESS';
export const CHECKOUT_AFTERPAY_FAILURE = 'CHECKOUT_AFTERPAY_FAILURE';

export const CHECKOUT_CASH_APP_REQUEST = 'CHECKOUT_CASH_APP_REQUEST';
export const CHECKOUT_CASH_APP_SUCCESS = 'CHECKOUT_CASH_APP_SUCCESS';
export const CHECKOUT_CASH_APP_FAILURE = 'CHECKOUT_CASH_APP_FAILURE';

export const SHIPPING_PROVIDER_LOCATIONS_REQUEST =
  'SHIPPING_PROVIDER_LOCATIONS_REQUEST';
export const SHIPPING_PROVIDER_LOCATIONS_SUCCESS =
  'SHIPPING_PROVIDER_LOCATIONS_SUCCESS';
export const SHIPPING_PROVIDER_LOCATIONS_FAILURE =
  'SHIPPING_PROVIDER_LOCATIONS_FAILURE';

export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST';
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const LOAD_CART_FAILURE = 'LOAD_CART_FAILURE';

export const SWEEP_CART_REQUEST = 'SWEEP_CART_REQUEST';
export const SWEEP_CART_SUCCESS = 'SWEEP_CART_SUCCESS';
export const SWEEP_CART_FAILIURE = 'SWEEP_CART_FAILURE';

export const LOAD_CART_ITEM_COUNT_REQUEST = 'LOAD_CART_ITEM_COUNT_REQUEST';
export const LOAD_CART_ITEM_COUNT_SUCCESS = 'LOAD_CART_ITEM_COUNT_SUCCESS';
export const LOAD_CART_ITEM_COUNT_FAILURE = 'LOAD_CART_ITEM_COUNT_FAILURE';

export const LOAD_BRAINTREE_REQUEST = 'LOAD_BRAINTREE_REQUEST';
export const LOAD_BRAINTREE_SUCCESS = 'LOAD_BRAINTREE_SUCCESS';
export const LOAD_BRAINTREE_FAILURE = 'LOAD_BRAINTREE_FAILURE';

export const SET_BRAINTREE_DEVICE_DATA = 'SET_BRAINTREE_DEVICE_DATA';

export const ADD_PRODUCT_SET_REQUEST = 'ADD_PRODUCT_SET_REQUEST';
export const ADD_PRODUCT_SET_SUCCESS = 'ADD_PRODUCT_SET_SUCCESS';
export const ADD_PRODUCT_SET_FAILURE = 'ADD_PRODUCT_SET_FAILURE';

export const DELETE_PRODUCT_SET_REQUEST = 'DELETE_PRODUCT_SET_REQUEST';
export const DELETE_PRODUCT_SET_SUCCESS = 'DELETE_PRODUCT_SET_SUCCESS';
export const DELETE_PRODUCT_SET_FAILURE = 'DELETE_PRODUCT_SET_FAILURE';

export const ADD_BASKET_ITEMS_REQUEST = 'ADD_BASKET_ITEMS_REQUEST';
export const ADD_BASKET_ITEMS_SUCCESS = 'ADD_BASKET_ITEMS_SUCCESS';
export const ADD_BASKET_ITEMS_FAILURE = 'ADD_BASKET_ITEMS_FAILURE';

export const ADD_FIRST_BYO_ITEM_REQUEST = 'ADD_FIRST_BYO_ITEM_REQUEST';
export const ADD_FIRST_BYO_ITEM_SUCCESS = 'ADD_FIRST_BYO_ITEM_SUCCESS';
export const ADD_FIRST_BYO_ITEM_FAILURE = 'ADD_FIRST_BYO_ITEM_FAILURE';

export const ADD_BYO_ITEM_REQUEST = 'ADD_BYO_ITEM_REQUEST';
export const ADD_BYO_ITEM_SUCCESS = 'ADD_BYO_ITEM_SUCCESS';
export const ADD_BYO_ITEM_FAILURE = 'ADD_BYO_ITEM_FAILURE';

export const DELETE_BASKET_ITEMS_REQUEST = 'DELETE_BASKET_ITEMS_REQUEST';
export const DELETE_BASKET_ITEMS_SUCCESS = 'DELETE_BASKET_ITEMS_SUCCESS';
export const DELETE_BASKET_ITEMS_FAILURE = 'DELETE_BASKET_ITEMS_FAILURE';

export const DELETE_BASKET_ITEMS_BY_PRODUCT_ID_REQUEST =
  'DELETE_BASKET_ITEMS_BY_PRODUCT_ID_REQUEST';
export const DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS =
  'DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS';
export const DELETE_BASKET_ITEMS_BY_PRODUCT_ID_FAILURE =
  'DELETE_BASKET_ITEMS_BY_PRODUCT_ID_FAILURE';

export const DELETE_MEMBERSHIP_ITEM_REQUEST = 'DELETE_MEMBERSHIP_ITEM_REQUEST';
export const DELETE_MEMBERSHIP_ITEM_SUCCESS = 'DELETE_MEMBERSHIP_ITEM_SUCCESS';
export const DELETE_MEMBERSHIP_ITEM_FAILURE = 'DELETE_MEMBERSHIP_ITEM_FAILURE';

export const UPDATE_CART_LINE_ITEM_REQUEST = 'UPDATE_CART_LINE_ITEM_REQUEST';
export const UPDATE_CART_LINE_ITEM_SUCCESS = 'UPDATE_CART_LINE_ITEM_SUCCESS';
export const UPDATE_CART_LINE_ITEM_FAILURE = 'UPDATE_CART_LINE_ITEM_FAILURE';

export const UPDATE_ITEM_QUANTITY_REQUEST = 'UPDATE_ITEM_QUANTITY_REQUEST';
export const UPDATE_ITEM_QUANTITY_SUCCESS = 'UPDATE_ITEM_QUANTITY_SUCCESS';
export const UPDATE_ITEM_QUANTITY_FAILURE = 'UPDATE_ITEM_QUANTITY_FAILURE';

export const UPDATE_CART_ITEM_REQUEST = 'UPDATE_CART_ITEM_REQUEST';
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
export const UPDATE_CART_ITEM_FAILURE = 'UPDATE_CART_ITEM_FAILURE';

export const UPDATE_SET_ITEM_QUANTITY_REQUEST =
  'UPDATE_SET_ITEM_QUANTITY_REQUEST';
export const UPDATE_SET_ITEM_QUANTITY_SUCCESS =
  'UPDATE_SET_ITEM_QUANTITY_SUCCESS';
export const UPDATE_SET_ITEM_QUANTITY_FAILURE =
  'UPDATE_SET_ITEM_QUANTITY_FAILURE';

export const UPDATE_FULFILLMENT_PROVIDER_REQUEST = `UPDATE_FULFILLMENT_PROVIDER_REQUEST`;
export const UPDATE_FULFILLMENT_PROVIDER_SUCCESS = `UPDATE_FULFILLMENT_PROVIDER_SUCCESS`;
export const UPDATE_FULFILLMENT_PROVIDER_FAILURE = `UPDATE_FULFILLMENT_PROVIDER_FAILURE`;

export const UPDATE_CART_WITH_ADDRESS_REQUEST =
  'UPDATE_CART_WITH_ADDRESS_REQUEST';
export const UPDATE_CART_WITH_ADDRESS_SUCCESS =
  'UPDATE_CART_WITH_ADDRESS_SUCCESS';
export const UPDATE_CART_WITH_ADDRESS_FAILURE =
  'UPDATE_CART_WITH_ADDRESS_FAILURE';

export const UPDATE_CART_WITH_PAYMENT_REQUEST =
  'UPDATE_CART_WITH_PAYMENT_REQUEST';
export const UPDATE_CART_WITH_PAYMENT_SUCCESS =
  'UPDATE_CART_WITH_PAYMENT_SUCCESS';
export const UPDATE_CART_WITH_PAYMENT_FAILURE =
  'UPDATE_CART_WITH_PAYMENT_FAILURE';

export const UPDATE_CART_WITH_SHIPPING_REQUEST =
  'UPDATE_CART_WITH_SHIPPING_REQUEST';
export const UPDATE_CART_WITH_SHIPPING_SUCCESS =
  'UPDATE_CART_WITH_SHIPPING_SUCCESS';
export const UPDATE_CART_WITH_SHIPPING_FAILURE =
  'UPDATE_CART_WITH_SHIPPING_FAILURE';

export const GET_SHIPPING_OPTIONS_REQUEST = 'GET_SHIPPING_OPTIONS_REQUEST';
export const GET_SHIPPING_OPTIONS_SUCCESS = 'GET_SHIPPING_OPTIONS_SUCCESS';
export const GET_SHIPPING_OPTIONS_FAILURE = 'GET_SHIPPING_OPTIONS_FAILURE';

export const SAVE_MONDIAL_ADDRESS_REQUEST = 'SAVE_MONDIAL_ADDRESS_REQUEST';
export const SAVE_MONDIAL_ADDRESS_SUCCESS = 'SAVE_MONDIAL_ADDRESS_SUCCESS';
export const SAVE_MONDIAL_ADDRESS_FAILURE = 'SAVE_MONDIAL_ADDRESS_FAILURE';

export const DELETE_MONDIAL_ADDRESS_REQUEST = 'DELETE_MONDIAL_ADDRESS_REQUEST';
export const DELETE_MONDIAL_ADDRESS_SUCCESS = 'DELETE_MONDIAL_ADDRESS_SUCCESS';
export const DELETE_MONDIAL_ADDRESS_FAILURE = 'DELETE_MONDIAL_ADDRESS_FAILURE';

export const CHANGE_DISCREET_PACKAGING_REQUEST =
  'CHANGE_DISCREET_PACKAGING_REQUEST';
export const CHANGE_DISCREET_PACKAGING_SUCCESS =
  'CHANGE_DISCREET_PACKAGING_SUCCESS';
export const CHANGE_DISCREET_PACKAGING_FAILURE =
  'CHANGE_DISCREET_PACKAGING_FAILURE';

export const SUBMIT_CART_ORDER_REQUEST = 'SUBMIT_CART_ORDER_REQUEST';
export const SUBMIT_CART_ORDER_SUCCESS = 'SUBMIT_CART_ORDER_SUCCESS';
export const SUBMIT_CART_ORDER_FAILURE = 'SUBMIT_CART_ORDER_FAILURE';

export const COMPLETE_CART_ORDER_REQUEST = 'COMPLETE_CART_ORDER_REQUEST';
export const COMPLETE_CART_ORDER_SUCCESS = 'COMPLETE_CART_ORDER_SUCCESS';
export const COMPLETE_CART_ORDER_FAILURE = 'COMPLETE_CART_ORDER_FAILURE';

export const CACHE_IN_PROGRESS_PAYMENT = 'CACHE_IN_PROGRESS_PAYMENT';
export const CLEAR_IN_PROGRESS_PAYMENT = 'CLEAR_IN_PROGRESS_PAYMENT';

export const TRACK_REGISTRATION_PROMPT_REQUEST =
  'TRACK_REGISTRATION_PROMPT_REQUEST';

export const TRACK_CHECKOUT_STEP = 'TRACK_CHECKOUT_STEP';
export const TRACK_CHECKOUT_TOGGLE_REQUEST = 'TRACK_CHECKOUT_TOGGLE_REQUEST';
export const TRACK_ADD_MEMBERSHIP_LINE = 'TRACK_ADD_MEMBERSHIP_LINE';
export const TRACK_REMOVE_MEMBERSHIP_LINE = 'TRACK_REMOVE_MEMBERSHIP_LINE';
export const TRACK_PAYMENT_METHOD_SELECTION = 'TRACK_PAYMENT_METHOD_SELECTION';
export const TRACK_EDIT_SHIPPING = 'TRACK_EDIT_SHIPPING';
export const TRACK_EDIT_PAYMENT = 'TRACK_EDIT_PAYMENT';
export const TRACK_EXPRESS_CHECKOUT_SELECTION =
  'TRACK_EXPRESS_CHECKOUT_SELECTION';
export const TRACK_AFTERPAY_SELECTED = 'TRACK_AFTERPAY_SELECTED';
export const TRACK_CLAIM_GWP_CTA = 'TRACK_CLAIM_GWP_CTA';
export const TRACK_ADD_GWP = 'TRACK_ADD_GWP';
export const TRACK_REMOVE_GWP = 'TRACK_REMOVE_GWP';
export const TRACK_UNDO_GWP = 'TRACK_UNDO_GWP';
export const TRACK_ADD_TO_CART_FAILURE = 'TRACK_ADD_TO_CART_FAILURE';
export const TRACK_ADD_SET_TO_CART_FAILURE = 'TRACK_ADD_SET_TO_CART_FAILURE';
export const TRACK_SUBMIT_ORDER_FAILURE = 'TRACK_SUBMIT_ORDER_FAILURE';

const productTypeMembershipId = config.get('public.productTypes.membershipId');
const shippingProviders = config.get('public.shippingProviders');
const unlockedXclusiveTag = config.get('public.productTags.unlockedXclusive');

function getCustomHeaders(state) {
  const headers = {};
  if (state.borderfree.isBorderfreeCustomer) {
    headers['x-tfg-borderfree'] = true;
  }
  return headers;
}

export function getAfterpayToken() {
  return async dispatch => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/checkout/afterpay/session`,
        method: 'POST',
        actions: [
          { type: GET_AFTERPAY_TOKEN },
          payload => {
            return {
              type: GET_AFTERPAY_TOKEN_SUCCESS,
              payload,
            };
          },
          { type: GET_AFTERPAY_TOKEN_FAILURE },
        ],
      },
    });
  };
}

export function checkoutAfterpay(token) {
  return async dispatch => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/checkout/afterpay`,
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
        actions: [
          { type: CHECKOUT_AFTERPAY_REQUEST },
          payload => {
            return {
              type: CHECKOUT_AFTERPAY_SUCCESS,
              payload,
            };
          },
          { type: CHECKOUT_AFTERPAY_FAILURE },
        ],
      },
    });
  };
}

export function getCashAppToken() {
  return {
    bentoApi: {
      endpoint: `cart/checkout/cashapppay/session`,
      method: 'POST',
      actions: [
        { type: GET_CASHAPP_TOKEN },
        payload => {
          return {
            type: GET_CASHAPP_TOKEN_SUCCESS,
            payload,
          };
        },
        { type: GET_CASHAPP_TOKEN_FAILURE },
      ],
    },
  };
}

export function checkoutCashApp(token) {
  return {
    bentoApi: {
      endpoint: `cart/checkout/cashapppay`,
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
      actions: [
        { type: CHECKOUT_CASH_APP_REQUEST },
        payload => {
          return {
            type: CHECKOUT_CASH_APP_SUCCESS,
            payload,
          };
        },
        { type: CHECKOUT_CASH_APP_FAILURE },
      ],
    },
  };
}

export function getShippingProviderLocations({ postalCode, countryCode }) {
  return dispatch => {
    const shippingProviderId = shippingProviders[countryCode].providerId;
    if (!shippingProviderId) {
      throw new Error('Missing providerId in getShippingProviderLocations');
    }
    return dispatch({
      bentoApi: {
        endpoint: `shipping/providers/${shippingProviderId}/locations?postalCode=${postalCode}&countryCode=${countryCode}`,
        method: 'GET',
        actions: [
          SHIPPING_PROVIDER_LOCATIONS_REQUEST,
          SHIPPING_PROVIDER_LOCATIONS_SUCCESS,
          SHIPPING_PROVIDER_LOCATIONS_FAILURE,
        ],
      },
    });
  };
}

const vipLivePromoCode = config.get(
  'public.promoCodes.vipLiveShopping.promoCode'
);
export function checkVipLivePromoIsExpired() {
  return async (dispatch, getState) => {
    const state = getState();
    const getDateNow = getDateNowFunction(state);
    const isPromoApplied = isVipLiveShoppingPromoAppliedSelector(state);

    if (!isPromoApplied) {
      return;
    }

    const promoInitTime = getCustomerDetail(
      state,
      'vip_live_shopping_promo_start'
    )?.value;

    const promoDeadline = promoInitTime
      ? addMinutes(promoInitTime, 60).toISOString()
      : null;
    const isPromoExpired = promoDeadline
      ? !isAfter(new Date(promoDeadline), getDateNow())
      : false;

    if (!isPromoExpired) {
      return;
    }

    return dispatch(deleteMembershipPromo(vipLivePromoCode));
  };
}

export function sweepCart() {
  return async (dispatch, getState) => {
    // Hardcoded Number so it tries to make as many bundles as possible.
    const tokenQuantity = 99;
    const state = getState();
    return dispatch({
      bentoApi: {
        endpoint: `cart/byo/sweep?tokenQuantity=${tokenQuantity}&autoApplyToken=false`,
        method: 'GET',
        headers: getCustomHeaders(state),
        actions: [
          SWEEP_CART_REQUEST,
          (payload, extraMeta) => {
            return {
              type: SWEEP_CART_SUCCESS,
              meta: {
                ...extraMeta,
              },
              payload,
            };
          },
          SWEEP_CART_FAILIURE,
        ],
      },
    });
  };
}

export function loadCart(options = {}) {
  return async (dispatch, getState, context) => {
    // Importing `checkoutFunctions` for jest spying
    const { deleteExpiredGwpItems } = checkoutFunctions;

    if (options.ifNotFetched) {
      const { checkout } = getState();
      if (checkout.fetchedDate) {
        return;
      }
    }
    let request = context.inflightCartRequest;
    const timestamp = Date.now();
    if (!context.inflightCartRequest) {
      request = dispatch({
        bentoApi: {
          endpoint: `cart?timestamp=${timestamp}`,
          method: 'GET',
          headers: getCustomHeaders(getState()),
          actions: [LOAD_CART_REQUEST, LOAD_CART_SUCCESS, LOAD_CART_FAILURE],
        },
      });
      context.inflightCartRequest = request;

      await Promise.all([request, dispatch(loadPromosIfVip())]);
      context.inflightCartRequest = null;
      await dispatch(checkVipLivePromoIsExpired());

      await Promise.all([
        dispatch(deleteExpiredGwpItems()),
        dispatch(deleteForbiddenUnlockedXclusiveProducts()),
      ]);
    }
    return request;
  };
}

export function loadCartItemCount(options = {}) {
  return async (dispatch, getState, context) => {
    if (options.ifNotFetched) {
      const { checkout } = getState();
      if (checkout.fetchedDate) {
        return;
      }
    }
    let request = context.inflightCartItemCountRequest;
    const timestamp = Date.now();
    if (!context.inflightCartItemCountRequest) {
      request = dispatch({
        bentoApi: {
          endpoint: `cart/items/count?timestamp=${timestamp}`,
          headers: getCustomHeaders(getState()),
          method: 'GET',
          actions: [
            LOAD_CART_ITEM_COUNT_REQUEST,
            LOAD_CART_ITEM_COUNT_SUCCESS,
            LOAD_CART_ITEM_COUNT_FAILURE,
          ],
        },
      });
      context.inflightCartItemCountRequest = request;
      await request;
      context.inflightCartItemCountRequest = null;
    }
    return request;
  };
}

export function deleteExpiredGwpItems() {
  return async (dispatch, getState) => {
    const state = getState();
    const { checkout } = state;
    const gwpPromo = getGwpPromo(state);

    // Importing `checkoutFunctions` to enable jest spying
    const { deleteBasketItem } = checkoutFunctions;

    // Delete GWP items from cart if GWP promo is no longer active.
    // We're catching multiple items here in case of cart glitch

    // TODO: check this, It looks like this logic is not correct
    // it is instead removing items from other promos
    // but when changing it cartDrawer tests fall into a stack overflow error
    const gwpItems = checkout.items.filter(
      item =>
        item.fplIds &&
        item.fplIds.includes(gwpPromo?.fplIds[state.domain.tld]) &&
        !(item.discount && item.discount.promoCode === gwpPromo?.promoCode)
    );

    if (!gwpItems.length) {
      return;
    }

    const gwpItemsDeleteRequests = gwpItems.map(gwpItem =>
      dispatch(
        deleteBasketItem(
          gwpItem.lineId || gwpItem.cartLineId,
          MembershipActionLocation.MINI_CART,
          false
        )
      )
    );
    await Promise.all(gwpItemsDeleteRequests);
  };
}

export function deleteForbiddenUnlockedXclusiveProducts() {
  return async (dispatch, getState) => {
    const state = getState();
    const { checkout } = state;
    const membershipPeriod = getMembershipPeriod(state);
    if (!membershipPeriod.networkStatus.isUpToDate) {
      await dispatch(loadMembershipPeriod());
    }

    const isUnlockedXclusiveProductVisible =
      getIsUnlockedXclusiveProductVisible(state);

    // Importing `checkoutFunctions` to enable jest spying
    const { deleteBasketItem } = checkoutFunctions;

    // Delete Unlocked Xclusive items from cart if user is an unbilled EMP VIP.
    // We only delete one item at a time because calling `deleteBasketItem` below
    // will trigger this function again & delete the next item (if any).
    const unlockedXclusiveItemToDelete = checkout.items.find(
      item =>
        !isUnlockedXclusiveProductVisible &&
        item.tagIds &&
        item.tagIds.includes(unlockedXclusiveTag)
    );

    if (!unlockedXclusiveItemToDelete) {
      return;
    }

    dispatch(
      deleteBasketItem(
        unlockedXclusiveItemToDelete.lineId ||
          unlockedXclusiveItemToDelete.cartLineId,
        MembershipActionLocation.MINI_CART,
        false
      )
    );
  };
}

export function addProductSetToCart(
  setId,
  componentProductIds,
  quantity,
  location,
  pSource,
  customText = ''
) {
  return async (dispatch, getState, context) => {
    const { isBorderfreeCustomer } = getState().borderfree;
    const hadPromoConflict = hasPromoConflict(getState());
    const { isVisitor } = getSession(getState());
    const provider = isBorderfreeCustomer ? `borderfree` : `techstyle`;
    const meta = { location };
    const defaultParams = {
      setId,
      componentProductIds,
      quantity,
      provider,
      productSource: pSource,
    };
    const request = {
      bentoApi: {
        endpoint: 'cart/sets',
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          ADD_PRODUCT_SET_REQUEST,
          (payload, extraMeta) => {
            return {
              type: ADD_PRODUCT_SET_SUCCESS,
              meta: {
                setId,
                source: pSource,
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          ADD_PRODUCT_SET_FAILURE,
        ],
        body: JSON.stringify(
          customText
            ? {
                ...defaultParams,
                personalization: {
                  text: customText,
                },
              }
            : defaultParams
        ),
      },
    };

    let result = null;
    try {
      [result] = await Promise.all([
        dispatch(request),
        dispatch(loadPromosIfVip()),
      ]);
    } catch (err) {
      const responseBody = await err.originalError.response.json();
      const metaData = { setId, componentProductIds };
      dispatch(trackAddSetToCartFailure(responseBody || {}, metaData));
      throw responseBody;
    }

    if (!isVisitor && !isBorderfreeCustomer) {
      const hasCashPromo = hasPromoConflict(getState());
      const shouldUpdateUseStoreCredit =
        !getState().checkout.useStoreCredit &&
        getMembership(getState()).storeCredits &&
        getMembership(getState()).storeCredits.length > 0 &&
        !hasCashPromo &&
        getState().checkout.items.length === 1;
      /** If adding a product with cash promo but there were none before => default usestorecredit to applypromo**/
      if (!hadPromoConflict && hasCashPromo) {
        await dispatch(useStoreCredit(false));
      } else if (shouldUpdateUseStoreCredit) {
        await dispatch(useStoreCredit(true));
      }
    }
    return result;
  };
}

export function updateSetItemQuantity(groupKey, quantity, location) {
  return async (dispatch, getState, context) => {
    const meta = { location };
    return dispatch({
      bentoApi: {
        endpoint: `cart/sets/${groupKey}/quantity`,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_SET_ITEM_QUANTITY_REQUEST,
          (payload, extraMeta) => {
            return {
              type: UPDATE_SET_ITEM_QUANTITY_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          UPDATE_SET_ITEM_QUANTITY_FAILURE,
        ],
        body: JSON.stringify({ quantity }),
      },
    });
  };
}

/** the replace endpoints are different from updateCartLineItem,  updateCartLineItem is only for applying membership token,
can't be used for updating colors and size, we are using the replace endpoint to do that */
export function updateCartItem(isProductSet, cartLineId, payload) {
  return async (dispatch, getState) =>
    dispatch({
      bentoApi: {
        endpoint: isProductSet
          ? `cart/sets/${cartLineId}/replace`
          : `cart/items/${cartLineId}/replace`,
        method: 'PUT',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_CART_ITEM_REQUEST,
          UPDATE_CART_ITEM_SUCCESS,
          UPDATE_CART_ITEM_FAILURE,
        ],
        body: JSON.stringify({ quantity: 1, ...payload }),
      },
    });
}

export function deleteProductSet(
  groupKey,
  location,
  shouldRemoveMembership = false
) {
  return async (dispatch, getState) => {
    // Importing `checkoutFunctions` for jest spying
    const { deleteExpiredGwpItems } = checkoutFunctions;

    const { isVisitor } = getSession(getState());
    const { isBorderfreeCustomer } = getState().borderfree;
    const meta = { location };
    const request = {
      bentoApi: {
        endpoint: `cart/sets/${groupKey}`,
        method: 'DELETE',
        headers: getCustomHeaders(getState()),
        actions: [
          DELETE_PRODUCT_SET_REQUEST,
          (payload, extraMeta) => {
            return {
              type: DELETE_PRODUCT_SET_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          DELETE_PRODUCT_SET_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);

    if (shouldRemoveMembership && hasOnlyMembershipCartItem(getState())) {
      await dispatch(deleteMembershipFromCart({ location }));
    } else if (
      !isVisitor &&
      !isBorderfreeCustomer &&
      !getState().checkout.useStoreCredit &&
      !hasPromoConflict(getState())
    ) {
      await dispatch(useStoreCredit(true));
    }

    await Promise.all([
      dispatch(deleteExpiredGwpItems()),
      dispatch(deleteForbiddenUnlockedXclusiveProducts()),
    ]);

    return result;
  };
}

export function addBasketItem(
  productId,
  quantity,
  meta,
  pSource,
  extraBodyParams = {}
) {
  return async (dispatch, getState) => {
    const state = getState();
    const { isBorderfreeCustomer } = state.borderfree;
    const hadPromoConflict = hasPromoConflict(state);
    const { isVisitor } = getSession(state);
    const provider = isBorderfreeCustomer ? `borderfree` : `techstyle`;
    const request = {
      bentoApi: {
        endpoint: 'cart/items',
        method: 'POST',
        headers: getCustomHeaders(state),
        actions: [
          ADD_BASKET_ITEMS_REQUEST,
          (payload, extraMeta) => {
            return {
              type: ADD_BASKET_ITEMS_SUCCESS,
              meta: {
                productId,
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          ADD_BASKET_ITEMS_FAILURE,
        ],
        body: JSON.stringify({
          productId,
          quantity,
          provider,
          productSource: pSource,
          ...extraBodyParams,
        }),
      },
    };

    let result = null;
    try {
      [result] = await Promise.all([
        dispatch(request),
        dispatch(loadPromosIfVip()),
      ]);
      await dispatch(splitTest('AAmembershipatb'));
    } catch (err) {
      const responseBody = await err.originalError.response.json();
      const metaData = { productId };
      dispatch(trackAddToCartFailure(responseBody || {}, metaData));
      throw responseBody;
    }

    if (!isVisitor && !isBorderfreeCustomer) {
      const hasCashPromo = hasPromoConflict(getState());
      /** When creating new cart for new members we want usecreditstore set to true by default if it isn't cash promo**/
      const shouldUpdateUseStoreCredit =
        !getState().checkout.useStoreCredit &&
        getMembership(getState()).storeCredits &&
        getMembership(getState()).storeCredits.length > 0 &&
        !hasCashPromo &&
        getState().checkout.items.length === 1;
      /** If adding a product with cash promo but there were none before => default usestorecredit to applypromo**/
      if (!hadPromoConflict && hasCashPromo) {
        await dispatch(useStoreCredit(false));
      } else if (shouldUpdateUseStoreCredit) {
        await dispatch(useStoreCredit(true));
      }
    }
    return result;
  };
}

export function addFirstByoItem(productId, byoProductId, quantity, meta = {}) {
  return async (dispatch, getState) => {
    const { isBorderfreeCustomer } = getState().borderfree;
    const provider = isBorderfreeCustomer ? `borderfree` : `techstyle`;
    return dispatch({
      bentoApi: {
        endpoint: 'cart/byo',
        method: 'POST',
        actions: [
          ADD_FIRST_BYO_ITEM_REQUEST,
          (payload, extraMeta) => {
            return {
              type: ADD_FIRST_BYO_ITEM_SUCCESS,
              meta: {
                productId,
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          {
            type: ADD_FIRST_BYO_ITEM_SUCCESS,
            meta,
          },
          ADD_FIRST_BYO_ITEM_FAILURE,
        ],
        body: JSON.stringify({
          productId,
          byoProductId,
          quantity,
          provider,
        }),
      },
    });
  };
}

export function addItemToByoSet(
  productId,
  byoProductId,
  byoGroupCode,
  quantity,
  meta = {}
) {
  return async (dispatch, getState) => {
    const { isBorderfreeCustomer } = getState().borderfree;
    const provider = isBorderfreeCustomer ? `borderfree` : `techstyle`;
    return dispatch({
      bentoApi: {
        endpoint: `cart/byo/${byoGroupCode}`,
        method: 'POST',
        actions: [
          ADD_BYO_ITEM_REQUEST,
          (payload, extraMeta) => {
            return {
              type: ADD_BYO_ITEM_SUCCESS,
              meta: {
                productId,
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          {
            type: ADD_BYO_ITEM_SUCCESS,
            meta,
          },
          ADD_BYO_ITEM_FAILURE,
        ],
        body: JSON.stringify({
          productId,
          byoProductId,
          quantity,
          provider,
        }),
      },
    });
  };
}

export function addMembershipToCart(meta, productSource) {
  return (dispatch, getState) => {
    const state = getState();
    const productId = getDefaultMembershipProductId(state);
    if (meta.eventAction !== MembershipEventAction.AUTO_ADD) {
      dispatch(trackAddMembershipLine(meta));
    }
    return dispatch(
      addBasketItem(
        productId,
        1,
        { isModifyingMembership: true, ...meta },
        productSource,
        {
          productTypeId: productTypeMembershipId,
        }
      )
    );
  };
}

function trackAddMembershipLine(meta) {
  return {
    type: TRACK_ADD_MEMBERSHIP_LINE,
    meta,
  };
}

function trackRemoveMembershipLine(meta) {
  return {
    type: TRACK_REMOVE_MEMBERSHIP_LINE,
    meta,
  };
}

export function trackPaymentMethodSelection(paymentType) {
  return {
    type: TRACK_PAYMENT_METHOD_SELECTION,
    paymentType,
  };
}
export function trackExpressCheckoutSelection(paymentType, category) {
  return {
    type: TRACK_EXPRESS_CHECKOUT_SELECTION,
    paymentType,
    category,
  };
}

export function trackAfterpaySelected() {
  return {
    type: TRACK_AFTERPAY_SELECTED,
    category: 'Checkout',
  };
}

export function trackEditShipping() {
  return {
    type: TRACK_EDIT_SHIPPING,
    category: 'Checkout',
  };
}

export function trackEditPayment() {
  return {
    type: TRACK_EDIT_PAYMENT,
    category: 'Checkout',
  };
}

export function deleteMembershipFromCart({ productId, location }) {
  return async (dispatch, getState) => {
    // Allow callers to pass no `productId` if they want to automatically remove
    // anything in the cart that counts as a membership.
    const removeAll = productId == null;
    const state = getState();

    let membershipItem = removeAll
      ? getMembershipCartItem(state)
      : { productId };

    // Don't blow up if consumers try to access `result.error`.
    let result = {};

    while (membershipItem) {
      const meta = {
        location,
      };
      const request = {
        bentoApi: {
          endpoint: `cart/products/${membershipItem.productId}`,
          method: 'DELETE',
          actions: [
            DELETE_MEMBERSHIP_ITEM_REQUEST,
            (payload, extraMeta) => {
              return {
                type: DELETE_MEMBERSHIP_ITEM_SUCCESS,
                meta: {
                  isModifyingMembership: true,
                  ...meta,
                  ...extraMeta,
                },
                payload,
              };
            },
            DELETE_MEMBERSHIP_ITEM_FAILURE,
          ],
        },
      };

      result = await dispatch(request);

      if (removeAll) {
        // Keep removing if there are more...
        membershipItem = getMembershipCartItem(getState());
      }
    }
    dispatch(trackRemoveMembershipLine({ location }));

    return result;
  };
}

export function updateItemQuantity(productId, quantity, location) {
  return async (dispatch, getState, context) => {
    const meta = { location };
    return dispatch({
      bentoApi: {
        endpoint: `cart/products/${productId}/quantity`,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_ITEM_QUANTITY_REQUEST,
          (payload, extraMeta) => {
            return {
              type: UPDATE_ITEM_QUANTITY_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          UPDATE_ITEM_QUANTITY_FAILURE,
        ],
        body: JSON.stringify({ quantity }),
      },
    });
  };
}

export function deleteBasketItem(
  itemId,
  location,
  shouldRemoveMembership = false,
  componentLineId
) {
  return async (dispatch, getState) => {
    // Importing `checkoutFunctions` for jest spying
    const { deleteExpiredGwpItems } = checkoutFunctions;

    // Items from ECHO use UUID for cartLineId, so we check for it below
    const isCartLineId = new RegExp(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i
    ).test(itemId);
    const { isVisitor } = getSession(getState());
    const { isBorderfreeCustomer } = getState().borderfree;
    const meta = { location };
    let endpoint = `cart/items/${itemId}`;
    if (isCartLineId && componentLineId) {
      endpoint = `cart/byo/cartline/${itemId}/${componentLineId}`;
    } else if (isCartLineId && !componentLineId) {
      endpoint = `cart/cartline/${itemId}`;
    }
    const request = {
      bentoApi: {
        endpoint: endpoint,
        method: 'DELETE',
        headers: getCustomHeaders(getState()),
        actions: [
          DELETE_BASKET_ITEMS_REQUEST,
          (payload, extraMeta) => {
            return {
              type: DELETE_BASKET_ITEMS_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          DELETE_BASKET_ITEMS_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);

    if (shouldRemoveMembership && hasOnlyMembershipCartItem(getState())) {
      await dispatch(deleteMembershipFromCart({ location }));
    } else if (
      !isVisitor &&
      !isBorderfreeCustomer &&
      !getState().checkout.useStoreCredit &&
      !hasPromoConflict(getState())
    ) {
      await dispatch(useStoreCredit(true));
    }

    await Promise.all([
      dispatch(deleteExpiredGwpItems()),
      dispatch(deleteForbiddenUnlockedXclusiveProducts()),
    ]);

    return result;
  };
}

export function deleteBasketItemsByProductId(productId, location) {
  return async (dispatch, getState) => {
    // Importing `checkoutFunctions` for jest spying
    const { deleteExpiredGwpItems } = checkoutFunctions;

    const meta = { location };
    const request = {
      bentoApi: {
        endpoint: `cart/products/${productId}`,
        method: 'DELETE',
        headers: getCustomHeaders(getState()),
        actions: [
          DELETE_BASKET_ITEMS_BY_PRODUCT_ID_REQUEST,
          (payload, extraMeta) => {
            return {
              type: DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          DELETE_BASKET_ITEMS_BY_PRODUCT_ID_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);

    await Promise.all([
      dispatch(deleteExpiredGwpItems()),
      dispatch(deleteForbiddenUnlockedXclusiveProducts()),
    ]);

    return result;
  };
}

export function updateCartLineItem(cartLineId, location, options = {}) {
  return async (dispatch, getState) => {
    const meta = { location };
    return dispatch({
      bentoApi: {
        endpoint: `cart/cartline/${cartLineId}`,
        method: 'PUT',
        headers: getCustomHeaders(getState()),
        body: JSON.stringify(options),
        actions: [
          UPDATE_CART_LINE_ITEM_REQUEST,
          (payload, extraMeta) => {
            return {
              type: UPDATE_CART_LINE_ITEM_SUCCESS,
              meta: {
                ...meta,
                ...extraMeta,
              },
              payload,
            };
          },
          UPDATE_CART_LINE_ITEM_FAILURE,
        ],
      },
    });
  };
}

export function updateFulfillmentProvider(provider) {
  return async (dispatch, getState, context) => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/fulfillment`,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_FULFILLMENT_PROVIDER_REQUEST,
          UPDATE_FULFILLMENT_PROVIDER_SUCCESS,
          UPDATE_FULFILLMENT_PROVIDER_FAILURE,
        ],
        body: JSON.stringify({ provider }),
      },
    });
  };
}

export function updateCartWithAddress(addressId, addressType = 'shipping') {
  return (dispatch, getState, context) => {
    const { borderfree, checkout } = getState();
    const { isBorderfreeCustomer } = borderfree;
    const provider = isBorderfreeCustomer ? `borderfree` : `techstyle`;
    const { items: checkoutItems = [] } = checkout;

    // Update the fullfillment as `borderfree` if we have items in the cart
    // and it's flagged as borderfree.
    if (checkoutItems.length > 0 && provider === `borderfree`) {
      dispatch(updateFulfillmentProvider(provider));
    }

    return dispatch({
      bentoApi: {
        endpoint: `cart/addresses`,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_CART_WITH_ADDRESS_REQUEST,
          UPDATE_CART_WITH_ADDRESS_SUCCESS,
          UPDATE_CART_WITH_ADDRESS_FAILURE,
        ],
        body: JSON.stringify({
          addressId: addressId,
          addressType,
        }),
      },
    });
  };
}

export function updateCartWithPayment(paymentMethod) {
  return async (dispatch, getState, context) => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/payment`,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        actions: [
          UPDATE_CART_WITH_PAYMENT_REQUEST,
          UPDATE_CART_WITH_PAYMENT_SUCCESS,
          UPDATE_CART_WITH_PAYMENT_FAILURE,
        ],
        body: JSON.stringify(paymentMethod),
      },
    });
  };
}

/**
 * An action that will remember the user's in-progress payment information just
 * for the lifetime of the checkout flow, when necessary.
 */
export function cacheInProgressPayment(paymentType, paymentInfo) {
  return { type: CACHE_IN_PROGRESS_PAYMENT, paymentInfo };
}

export function clearInProgressPayment() {
  return { type: CLEAR_IN_PROGRESS_PAYMENT };
}

export function updateCartWithShipping(shippingOptionId) {
  return {
    bentoApi: {
      endpoint: `cart/shipping`,
      method: 'POST',
      actions: [
        UPDATE_CART_WITH_SHIPPING_REQUEST,
        UPDATE_CART_WITH_SHIPPING_SUCCESS,
        UPDATE_CART_WITH_SHIPPING_FAILURE,
      ],
      body: JSON.stringify({ shippingOptionId }),
    },
  };
}

function initShippingInfo() {
  return async (dispatch, getState) => {
    const shippingInfo = getShippingInfo(getState());

    if (!shippingInfo.address.id) {
      await dispatch(loadAddresses());
      const defaultAddress = getDefaultAddress(getState());

      if (defaultAddress) {
        // Let's make sure the cart has the latest address attached to it
        await dispatch(updateCartWithAddress(defaultAddress.id, 'shipping'));
      } else {
        await dispatch(trackCheckoutStepChange('/checkout/shipping'));
        // Otherwise let's force the user to fill the address in
        return navigateToOrderSummary(1);
      }
    }

    // Apply a default shipping option, make sure they're still eligible,
    // etc.
    await dispatch(getShippingOptions());
    const shippingOptions = getFilteredShippingOptions(getState()) || [];
    const { shippingOptionId } = shippingInfo.shippingMethod;
    const savedShippingOption = shippingOptions.find(
      option => option.shippingOptionId === shippingOptionId
    );
    const { address } = getShippingInfo(getState());

    // Check if the saved shipping option still exists and it is valid for its zip
    if (!validateShippingOption(address, savedShippingOption)) {
      const defaultShippingOption = shippingOptions.length
        ? shippingOptions[0]
        : null;

      // Set a valid shipping option. Otherwise, redirect to address form
      if (validateShippingOption(address, defaultShippingOption)) {
        await dispatch(
          updateCartWithShipping(defaultShippingOption.shippingOptionId)
        );
      } else {
        return navigateToOrderSummary(2);
      }
    }
  };
}

const initPaymentInfo = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let paymentInfo = getPaymentInfo(state);

    const useSavedPayment = true;
    let defaultPayment = getDefaultPaymentMethod(state);

    // If no payment method is attached to the cart, try to attach their
    // default payment method.
    if (
      !paymentInfo.paymentMethod.paymentId ||
      paymentInfo.paymentMethod.paymentMethod === 'psp'
    ) {
      if (useSavedPayment && !defaultPayment) {
        await dispatch(loadAccountPaymentMethods());
        state = getState();
        defaultPayment =
          getDefaultPaymentMethod(state) ||
          state.checkout.inProgressPaymentInfo;
      }
      if (useSavedPayment && defaultPayment) {
        const addressId =
          defaultPayment.cardInfo && defaultPayment.cardInfo.addressId;

        if (!paymentInfo.address.id && addressId) {
          await dispatch(updateCartWithAddress(addressId, 'billing'));
          state = getState();
          paymentInfo = getPaymentInfo(state);
        }
        if (
          defaultPayment.paymentId &&
          paymentInfo.paymentMethod.paymentId !== defaultPayment.paymentId
        ) {
          await dispatch(
            updateCartWithPayment({
              paymentMethod: defaultPayment.paymentMethod,
              paymentId: defaultPayment.paymentId,
            })
          );
          state = getState();
          paymentInfo = getPaymentInfo(state);
        }
      } else {
        await dispatch(trackCheckoutStepChange('/checkout/payment'));
        return navigateToOrderSummary(2);
      }
    }

    // If there is a payment method attached, but its data hasn't been
    // fetched yet, do that now.
    if (
      (paymentInfo.paymentMethod.cardInfo &&
        paymentInfo.paymentMethod.cardInfo.incomplete) ||
      paymentInfo.paymentMethod.paymentType == null
    ) {
      await dispatch(loadAccountPaymentMethods());
      state = getState();
      paymentInfo = getPaymentInfo(state);
    }
    // If there's no payment address, and we have access to one, try to
    // attach that.
    if (!paymentInfo.address.id) {
      let addressId;
      if (
        paymentInfo.paymentMethod.cardInfo &&
        paymentInfo.paymentMethod.cardInfo.addressId
      ) {
        addressId = paymentInfo.paymentMethod.cardInfo.addressId;
      }
      if (
        paymentInfo.paymentMethod.sepaInfo &&
        paymentInfo.paymentMethod.sepaInfo.addressId
      ) {
        addressId = paymentInfo.paymentMethod.sepaInfo.addressId;
      } else if (
        paymentInfo.paymentMethod.paypalInfo &&
        paymentInfo.paymentMethod.paypalInfo.addressId
      ) {
        addressId = paymentInfo.paymentMethod.paypalInfo.addressId;
      }

      if (addressId) {
        await dispatch(updateCartWithAddress(addressId, 'billing'));
        state = getState();
        paymentInfo = getPaymentInfo(state);
      } else {
        await dispatch(trackCheckoutStepChange('/checkout/shipping'));
        return navigateToOrderSummary(1);
      }
    }
  };
};

export function initCheckout() {
  return async (dispatch, getState) => {
    const state = getState();
    const { checkout: cart, customer } = state;
    const hasCartId = cart.cartId;

    if (!hasCartId) {
      // Ensure cart is loaded.
      await dispatch(loadCart());
    }

    // Navigate to sigunup page if user is a visitor
    if (getSession(getState()).isVisitor) {
      return Router.push('/checkout/sign-up');
    }

    // Ensure shipping info is loaded
    await dispatch(initShippingInfo());

    // Navigate to ordersummary step if user is influencer
    if (customer.isInfluencer) {
      navigateToOrderSummary(3);
    }

    const shippingInfo = getShippingInfo(getState());
    const { address, shippingMethod } = shippingInfo;
    const hasShippingInfo = address.id && shippingMethod?.shippingOptionId;
    if (hasShippingInfo) {
      await dispatch(initPaymentInfo());
    }

    const paymentInfo = getPaymentInfo(getState());
    const hasPaymentInfo = !!paymentInfo.address.id;
    if (hasShippingInfo && hasPaymentInfo) {
      await dispatch(trackCheckoutStepChange('/checkout/review-order'));
      return navigateToOrderSummary(3);
    }
  };
}

export function saveMondialAddress(address) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      address1,
      address2,
      city,
      countryCode,
      firstName,
      isPickupLocation,
      lastName,
      phone,
      providerLocationId,
      zip,
    } = address;

    dispatch(invalidateProfile());
    await dispatch(loadProfile());
    const email = state.customer && state.customer.email;

    return dispatch({
      bentoApi: {
        endpoint: `cart/shipping/retailpoint`,
        method: 'POST',
        body: JSON.stringify({
          address1: address1,
          address2: address2,
          city: city,
          countryCode: countryCode,
          email: email,
          firstName: firstName,
          isPickupLocation: isPickupLocation,
          lastName: lastName,
          locationId: providerLocationId,
          longDescription: '',
          phone: phone,
          shortDescription: '',
          zip: zip,
        }),
        actions: [
          SAVE_MONDIAL_ADDRESS_REQUEST,
          SAVE_MONDIAL_ADDRESS_SUCCESS,
          SAVE_MONDIAL_ADDRESS_FAILURE,
        ],
      },
    });
  };
}

export function deleteMondialAddress() {
  return async (dispatch, getState) => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/shipping/retailpoint`,
        method: 'DELETE',
        actions: [
          DELETE_MONDIAL_ADDRESS_REQUEST,
          DELETE_MONDIAL_ADDRESS_SUCCESS,
          DELETE_MONDIAL_ADDRESS_FAILURE,
        ],
      },
    });
  };
}

export function completeCheckout() {
  return async (dispatch, getState) => {
    const state = getState();
    const { checkout: cart, customer } = state;
    let queryString = '';

    try {
      await dispatch(checkForBirthdayPromo());
    } catch (e) {
      // Do nothing. We're just making sure this doesn't mysteriously
      // throw an error during checkout since its only purpose is allowing
      // us to hide a birthday banner (aka not life or death).
    }

    await dispatch(loadMembership());
    if (customer.isInfluencer) {
      await dispatch(loadProfile());
    }
    if (cart.order && cart.order.isActivatingVip) {
      queryString = '?vipoptin=1';
    }
    await dispatch(splitTest('AAmembershipcheckout'));
    await dispatch(trackCheckoutStepChange('/checkout/order-complete'));
    return navigateToOrderConfirmation(queryString);
  };
}

export function checkForBirthdayPromo() {
  return async (dispatch, getState) => {
    const state = getState();
    const { checkout: cart } = state;

    // FIXME: remove this asset check as soon as the birthday promo AB test
    // is done and only one promo is being used (we can hard-code it then).
    const assets = await dispatch(loadAssets(['birthday_promo_codes']));
    const assetsArray =
      (assets.payload.birthday_promo_codes &&
        assets.payload.birthday_promo_codes.assets) ||
      [];
    const promoCodes = assetsArray.map(asset =>
      asset.options.customVars.promoCode.toUpperCase()
    );
    const hasBirthdayPromo =
      cart.order &&
      cart.order.discounts &&
      cart.order.discounts.some(discount =>
        promoCodes.includes(discount.promoCode.toUpperCase())
      );
    if (hasBirthdayPromo) {
      const getDateNow = getDateNowFunction(state);
      await dispatch(
        updateCustomerDetail({
          name: 'birthday_promo_used',
          value: getDateNow(),
        })
      );
    }
  };
}

export function getShippingOptions({
  // If wanting to show unavailable shipping options due to a membership
  // promotion, set this to true.
  includeNonQualifying = false,
} = {}) {
  return async (dispatch, getState, context) => {
    const { checkout: cart } = getState();
    const { shippingOptionsNetworkStatus } = cart;

    if (!shippingOptionsNetworkStatus.isLoading) {
      return dispatch({
        bentoApi: {
          endpoint: 'cart/shipoptions',
          searchParams: {
            includeNonQualifying: includeNonQualifying,
          },
          requestKey: 'getShippingOptions',
          method: 'GET',
          headers: getCustomHeaders(getState()),
          actions: [
            GET_SHIPPING_OPTIONS_REQUEST,
            GET_SHIPPING_OPTIONS_SUCCESS,
            GET_SHIPPING_OPTIONS_FAILURE,
          ],
        },
      });
    }
  };
}

export async function navigateToOrderSummary(step) {
  await Router.push(`/checkout/order-information?step=${step}`, undefined, {
    shallow: true,
  });
}

export function navigateToOrderConfirmation(queryString) {
  Router.push(`/checkout/order-confirmation${queryString}`);
}

export function checkNewFreeTrialMember() {
  return (dispatch, getState) => {
    const state = getState();
    const newFreeTrialMember = hasFreeTrialMembershipInCart(state, {
      order: state.checkout.order,
    });
    const email = state.customer && state.customer.email;
    if (newFreeTrialMember && email) {
      dispatch(subscribeNewFreeTrialMember({ email }));
    }
  };
}

export function changeDiscreetPackaging(params = {}) {
  return async (dispatch, getState) => {
    return dispatch({
      bentoApi: {
        endpoint: `cart/metadata`,
        body: JSON.stringify(params),
        method: 'PATCH',
        actions: [
          CHANGE_DISCREET_PACKAGING_REQUEST,
          CHANGE_DISCREET_PACKAGING_SUCCESS,
          CHANGE_DISCREET_PACKAGING_FAILURE,
        ],
      },
    });
  };
}

// this function is needed only to make testing easier
export function getSubmitCartOrderEndpoint(paymentProvider, paymentType) {
  const isAdyen = paymentProvider === 'adyen' && paymentType === 'creditcard';

  return isAdyen ? 'cart/purchase' : 'cart/order/submit';
}

function invalidateAfterSuccessfulCheckout({ isActivatingVip } = {}) {
  return async (dispatch, getState) => {
    dispatch(checkNewFreeTrialMember());
    // Always invalidate membership to get up to date membership credits
    dispatch(invalidateMembership());
    dispatch(invalidateMembershipPeriod());
    // When the member is activating we want to invalidate the membership status since
    // this will invalidate a bunch of other things related to segmenting the user
    if (isActivatingVip) {
      dispatch(updateMembershipStatusSuccess());
    }
    // Invalidate the profile in case we are adding profile data during checkout (e.g. first time orders
    // for new accounts that might not have a profile w/ name yet)
    dispatch(invalidateProfile());
    dispatch(invalidateNavs());
    // This is needed to have the correct payment method for the order confirmation page since
    // the checkout step might have changed the payment method
    dispatch(loadAccountPaymentMethods());
  };
}

export function submitCartOrder(params = {}) {
  return async (dispatch, getState) => {
    // this is needed to be able to use jest spy
    const { getSubmitCartOrderEndpoint } = checkoutFunctions;
    const state = getState();
    const { storeGroup, checkout } = state;
    const endpoint = getSubmitCartOrderEndpoint(
      storeGroup.paymentOptions.paymentProvider,
      params.paymentType
    );

    const request = {
      bentoApi: {
        endpoint: endpoint,
        method: 'POST',
        headers: getCustomHeaders(getState()),
        body: JSON.stringify({
          ...params,
          ...(endpoint === 'cart/order/submit'
            ? { deviceData: checkout?.deviceData }
            : {}),
        }),
        retry: { retries: 0 },
        actions: [
          SUBMIT_CART_ORDER_REQUEST,
          SUBMIT_CART_ORDER_SUCCESS,
          SUBMIT_CART_ORDER_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);

    if (
      result.payload &&
      result.payload.statusCode !== 202 &&
      !result.payload.adyenResponseType
    ) {
      // SUBMIT success above should no longer mean the order is complete.
      // Instead we dispatch this new action for consistency with payment
      // authorization flows.

      const oldMembership = getMembership(state);
      const { membershipDetail } = result.payload;
      const newMembership = getMembership({
        ...state,
        membership: membershipDetail,
      });
      const isActivatingVip = oldMembership.isLead && newMembership.isVip;
      result.payload.isActivatingVip = isActivatingVip;

      dispatch({
        ...result,
        type: COMPLETE_CART_ORDER_SUCCESS,
      });
      dispatch(invalidateAfterSuccessfulCheckout({ isActivatingVip }));
    }
    return result;
  };
}

export function completeCartOrder(params = {}, onAuthorizationStep = () => {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const { isAdyen } = params;

    const buildRequest = requestParams => {
      return {
        bentoApi: {
          endpoint: isAdyen ? 'cart/purchase/complete' : 'cart/order/complete',
          method: 'POST',
          headers: getCustomHeaders(getState()),
          body: JSON.stringify(requestParams),
          retry: { retries: 0 },
          actions: [
            COMPLETE_CART_ORDER_REQUEST,
            COMPLETE_CART_ORDER_SUCCESS,
            COMPLETE_CART_ORDER_FAILURE,
          ],
        },
      };
    };
    let result = await dispatch(buildRequest(params));

    // 3DS2 will sometimes require us to authenticate the user,
    // which we do here (and then resubmit with new authentication):
    if (
      result.payload.adyenResponseType === 'threeDS2Challenge' ||
      result.payload.adyenResponseType === 'redirect'
    ) {
      const newParams = await onAuthorizationStep(result.payload);
      result = await dispatch(buildRequest(newParams));
    }

    const oldMembership = getMembership(state);
    const { membershipDetail } = result.payload;
    const newMembership = getMembership({
      ...state,
      membership: membershipDetail,
    });
    const isActivatingVip = oldMembership.isLead && newMembership.isVip;
    result.payload.isActivatingVip = isActivatingVip;

    if (isAdyen) {
      // call GTM for vip conversion for adyen checkout
      dispatch({
        ...result,
        type: COMPLETE_CART_ORDER_SUCCESS,
      });
    }

    dispatch(invalidateAfterSuccessfulCheckout({ isActivatingVip }));

    return result;
  };
}

export function braintreePaypalCheckout(params) {
  return {
    bentoApi: {
      endpoint: 'cart/checkout/braintree/paypal/authorize',
      method: 'POST',
      body: JSON.stringify(params),
      retry: { retries: 0 },
      actions: [
        LOAD_BRAINTREE_REQUEST,
        LOAD_BRAINTREE_SUCCESS,
        LOAD_BRAINTREE_FAILURE,
      ],
    },
  };
}

export function setBraintreeDeviceData(deviceData) {
  return {
    type: SET_BRAINTREE_DEVICE_DATA,
    payload: { deviceData: deviceData },
  };
}

// Helper function to load promos only when a VIP for Gift With Purchase
function loadPromosIfVip() {
  return async (dispatch, getState) => {
    await dispatch(loadMembership());

    const state = getState();
    const { isVip } = getMembership(state);
    const promosRequest =
      isVip && !state?.promos?.promos?.length ? dispatch(loadPromos()) : null;

    await promosRequest;
  };
}

export function trackRegistrationPrompt() {
  return {
    type: TRACK_REGISTRATION_PROMPT_REQUEST,
  };
}

export function trackCheckoutStepChange(stepName) {
  return {
    type: TRACK_CHECKOUT_STEP,
    stepName,
  };
}

export function trackCheckoutToggle(eventAction) {
  return {
    type: TRACK_CHECKOUT_TOGGLE_REQUEST,
    eventAction,
  };
}

export function trackClaimGwpCta() {
  return {
    type: TRACK_CLAIM_GWP_CTA,
  };
}

export function trackAddGwp(productName) {
  return {
    type: TRACK_ADD_GWP,
    eventLabel: productName,
  };
}

export function trackRemoveGwp(productName) {
  return {
    type: TRACK_REMOVE_GWP,
    eventLabel: productName,
  };
}

export function trackUndoGwp() {
  return {
    type: TRACK_UNDO_GWP,
  };
}

export function trackSubmitOrderFailure(error) {
  return {
    type: TRACK_SUBMIT_ORDER_FAILURE,
    error,
  };
}

export function trackAddToCartFailure(error, meta) {
  return {
    type: TRACK_ADD_TO_CART_FAILURE,
    error,
    meta,
  };
}

export function trackAddSetToCartFailure(error, meta) {
  return {
    type: TRACK_ADD_SET_TO_CART_FAILURE,
    error,
    meta,
  };
}
