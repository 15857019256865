import React, { FC } from 'react';

import styled from 'styled-components';

import { HeaderAttachment, useTheme } from '@techstyle/react-components';

const HeaderAttachmentContainerComponent = styled(HeaderAttachment.Container)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0;
`;

type Origin = 'nav' | 'header';

interface HeaderAttachmentContainerProps {
  origin: Origin;

  [p: string]: any;
}

function shouldRenderComponent(
  shouldRenderToNav: boolean,
  origin: Origin,
  themeVersion: string
) {
  if (themeVersion === 'v2') {
    return true;
  }

  return shouldRenderToNav ? origin === 'nav' : origin === 'header';
}

export const HeaderAttachmentContainer: FC<HeaderAttachmentContainerProps> = ({
  origin,
  ...rest
}) => {
  const { themeVersion, context } = useTheme(null, null);
  const isMobileProductsPage = context.mobileProducts;

  if (!shouldRenderComponent(isMobileProductsPage, origin, themeVersion)) {
    return null;
  }
  return <HeaderAttachmentContainerComponent {...rest} />;
};
