import { useMemo } from 'react';

import { useCustomerDetail } from '@techstyle/react-accounts';
import { useSessionDetail } from '@techstyle/redux-core';

export const useOneTrustConsent = () => {
  const oneTrustSessionDetail = useSessionDetail('onetrust_consent');
  const oneTrustCustomerDetail = useCustomerDetail('onetrust_consent');

  return useMemo(() => {
    const oneTrustSessionDetails =
      oneTrustSessionDetail?.value?.split(',') ?? [];
    const oneTrustCustomerDetails =
      oneTrustCustomerDetail?.value?.split(',') ?? [];

    return [...oneTrustSessionDetails, ...oneTrustCustomerDetails];
  }, [oneTrustCustomerDetail.value, oneTrustSessionDetail.value]);
};
