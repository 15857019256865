import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

const Context = createContext();

export const useCashAppContext = () => useContext(Context);

export default function CashAppProvider({ children }) {
  const [cashTag, setCashTag] = useState(null);
  const [token, setToken] = useState(null);
  const [showCashAppWarning, setShouldShowCashAppWarning] = useState(false);
  const cashAppBeginRef = useRef(null);
  const cashAppDestroyRef = useRef(null);

  const resetForCashApp = () => {
    setCashTag(null);
    setToken(null);
    cashAppBeginRef.current = null;
    cashAppDestroyRef.current = null;
  };

  const value = useMemo(
    () => ({
      cashAppBeginRef,
      cashAppDestroyRef,
      cashTag,
      showCashAppWarning,
      resetForCashApp,
      setCashTag,
      setShouldShowCashAppWarning,
      setToken,
      token,
    }),
    [cashTag, showCashAppWarning, token]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

CashAppProvider.Context = Context;
CashAppProvider.Consumer = Context.Consumer;

CashAppProvider.propTypes = {
  children: PropTypes.node,
};
