import React from 'react';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
// There is a Babel Transform error if this is not imported into a variable
// eslint-disable-next-line no-unused-vars
import styles from 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

import { v1, v2 } from '../../utils/themeVersioning';
import { Component as StaticIcon } from '../StaticIcon';

const Container = styled.div`
  position: relative;
  width: 100%;

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    margin-top: -15px;
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    right: auto;
    bottom: 0;
    text-align: center;
    transform: translateX(-50%);

    li {
      display: inline-block;

      &.slick-active {
        button {
          &:before {
            background: ${props => props.theme.colors.default};
            /* Intensify the light border in case we're on top of a dark image. */
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: ${props => props.theme.zIndex.increment};

  background: transparent;
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
  /* Remove default dotted border on Firefox */
  &::-moz-focus-inner {
    border: 0;
  }
`;

const PrevButton = styled(ArrowButton).attrs({
  [`aria-label`]: 'Previous',
})`
  display: ${props => (props.hide ? 'none !important' : 'block')};
  left: 0;
  /* Position fully outside the left margin of .slick-list */
  transform: translate(-100%, 0);

  ${v1`
    cursor: w-resize;
  `}
  ${v2`
    cursor: pointer;
  `}
  text-align: left;
`;

const LeftIcon = styled(StaticIcon)`
  transform: rotate(90deg);
`;

const NextButton = styled(ArrowButton).attrs({
  [`aria-label`]: 'Next',
})`
  display: ${props => (props.hide ? 'none !important' : 'block')};
  right: 0;
  /* Position fully outside the right margin of .slick-list */
  transform: translate(100%, 0);

  ${v1`
    cursor: e-resize;
  `}
  ${v2`
    cursor: pointer;
  `}
  text-align: right;

  svg {
    margin-left: 2px;
  }
`;

const RightIcon = styled(StaticIcon)`
  transform: rotate(-90deg);
`;

const DotButton = styled.button`
  position: relative;
  width: 18px;
  height: 24px;
  background: transparent;
  color: transparent;

  &:before {
    display: block;
    box-sizing: border-box;
    position: absolute;
    bottom: 7px;
    left: 4px;
    width: 10px;
    height: 10px;
    border: 1px solid ${props => props.theme.colors.default};
    border-radius: 100%;
    /* Add a second, light border in case we're on top of a dark image. */
    box-shadow: 0 0 0 1px ${props => props.theme.colors.default};
    content: '';
  }
`;

const defaultOptions = {
  accessibility: true,
  autoplay: false,
  customPaging: i => <DotButton key={`dots-${i}`} />,
  dots: true,
  fade: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
  touchThreshold: 7,
  nextArrow: (
    <NextButton>
      <RightIcon data-testid="carousel-right-icon" type="caret" size={50} />
    </NextButton>
  ),
  prevArrow: (
    <PrevButton>
      <LeftIcon data-testid="carousel-left-icon" type="caret" size={50} />
    </PrevButton>
  ),
};

const Carousel = ({
  children,
  className,
  dataTestId,
  forwardedRef,
  options,
  sliderRef,
  ...rest
}) => {
  const sliderProps = {
    ...defaultOptions,
    ...options,
    ...rest,
  };

  return (
    <Container
      className={className}
      data-testid={dataTestId}
      ref={forwardedRef}
    >
      <Slider {...sliderProps} ref={sliderRef}>
        {children}
      </Slider>
    </Container>
  );
};

Carousel.propTypes = {
  /**
   * Class(es) to apply to the container element.
   */
  className: PropTypes.string,
  /**
   * Can be components or an array.
   */
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.node,
    }),
  ]),
  /**
   * Props to pass along to [react-slick](https://github.com/akiran/react-slick).
   * These will be merged with the defaults provided by this component.
   */
  options: PropTypes.object,
  sliderRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
};

Carousel.NextArrow = NextButton;
Carousel.PrevArrow = PrevButton;
Carousel.defaultOptions = defaultOptions;
export default Carousel;
