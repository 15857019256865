import { handleActions } from 'redux-actions';

import { PAGE_RENDERED } from '../actions/pagesRendered';

export const defaultState = 0;

export default handleActions(
  {
    [PAGE_RENDERED]: state => {
      const pageRenderCount = state + 1;
      return pageRenderCount;
    },
  },
  defaultState
);
