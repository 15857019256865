import { useEffect, useRef } from 'react';

import { useAccountActions, useCustomer } from '@techstyle/react-accounts';
import { useFeature } from '@techstyle/react-features';
import { useRenderTime, useSession } from '@techstyle/redux-core';

import { parseDate } from '../../utils/dates';
import { getSizeFromCache } from '../../utils/getSizeFromCache';
import { isMobile } from '../../utils/helpers';

const twoMinutes = 2 * 60 * 1000;

const AutoSaveSizes = () => {
  const sizeIsUpdating = useRef();
  const customer = useCustomer();
  const { isLoggedIn, expiration } = useSession();
  const { updateProfile } = useAccountActions();
  const getTime = useRenderTime();
  const { data, networkStatus } = useFeature('save-cached-sizes');

  useEffect(() => {
    const isFeatureEnabled =
      data &&
      data.enabled &&
      data.value &&
      networkStatus.isUpToDate &&
      !networkStatus.isLoading;

    if (!customer || !isLoggedIn || !isFeatureEnabled) {
      return;
    }

    const isMobileDevice = isMobile(window.navigator);
    const sessionExpiry = parseDate(expiration).getTime();
    // Added two minutes as api check's for expiration
    // if session is about to expiry within one minute.
    const time = getTime() + twoMinutes;

    const onVisibilityChange = () => {
      if (document.hidden) {
        saveSizes();
      }
    };

    const saveSizes = () => {
      if (!sizeIsUpdating.current && customer.profile) {
        sizeIsUpdating.current = true;
        const sizesNotSaved = getSizeFromCache(customer);

        if (sizesNotSaved) {
          updateProfile({
            ...customer,
            profile: {
              ...customer.profile,
              ...sizesNotSaved,
            },
          });
        }
      }
    };

    // The session is about to expiry within two minutes then
    // we save the sizes
    if (sessionExpiry <= time) {
      saveSizes();
      return;
    }

    if (isMobileDevice) {
      document.addEventListener('visibilitychange', onVisibilityChange);
    } else {
      window.addEventListener('beforeunload', saveSizes);
    }

    sizeIsUpdating.current = false;
    return () => {
      if (isMobileDevice) {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      } else {
        window.removeEventListener('beforeunload', saveSizes);
      }
    };
  }, [
    customer,
    isLoggedIn,
    expiration,
    sizeIsUpdating,
    getTime,
    data,
    networkStatus.isUpToDate,
    networkStatus.isLoading,
    updateProfile,
  ]);

  return null; // not displaying anything
};

export default AutoSaveSizes;
