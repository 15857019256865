import React from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';

import { Component as RouteContext } from '../RouteContext';

export default class ContextualLink extends React.PureComponent {
  static propTypes = {
    /**
     * Passes the following arguments ({ isActive, isBack, isTop }) to an anchor link or styled anchor link, which you can conditionally style based on the anchor link’s href
     */
    children: PropTypes.func,
  };

  render() {
    const { children, ...props } = this.props;
    return (
      <RouteContext.Consumer>
        {route => {
          const renderArg = {
            isActive: route.isActive(props),
            isBack: route.isBack(props),
            isTop: route.isTop(props),
          };
          return <Link {...props}>{children(renderArg)}</Link>;
        }}
      </RouteContext.Consumer>
    );
  }
}
