import { css } from 'styled-components';

import { Select } from '@techstyle/react-components-legacy';

import globalStyle from './global';
import {
  generateDesignTokenVariables,
  transformStylesToVariants,
} from './utils';

import { desktop } from './index';

// Using this themeVersion string to evaluate which theme to use
// instead of having to put the FF in almost every component
const themeVersion = `v2`;

// NOTE: Please do not add any new colors.
// If a design is using a color that is not listed here,
// please ask the designer to map it to a color from the design system.
const baseColors = {
  black: '#000000',
  gunmetal: '#333536',
  grey: '#808080',
  stone: '#c8c9c7',
  marble: '#f5f5f5',
  gray500: '#70758c',
  gray400: '#8f92a6',
  gray300: '#c2c4d1',
  gray250: '#ededf0',
  gray200: '#dadbe3',
  gray150: '#f7f7f7',
  green: '#0F8B8D',
  green700: '#004852',
  green500: '#1a7681',
  green200: '#e4f4f6',
  greenCashApp: '#00D64B',
  lavender: '#c5b4e3',
  lavender900: '#3e354f',
  lavender800: '#514668',
  lavender700: '#65587f',
  lavender600: '#7a6b96',
  lavender500: '#8e7fab',
  lavender400: '#a394bd',
  lavender300: '#b8abce',
  lavender200: '#e1daec',
  lavender100: '#f5f2f9',
  layflatGray: '#f8f8f8',
  red500: '#c92c49', // error
  red100: '#ffe6e9',
  white: '#ffffff',

  // NOTE: DEPRECTAED BRAND COLORS!! DO NOT USE!!
  // Still TBD by Design.
  electricPurple12: 'rgba(124, 137, 211, 0.12)',
  lavenderGradient: 'linear-gradient(90deg, #425fa1 0%, #b4479a 100%)',
  redViolet12: 'rgba(199, 41, 102, 0.12)',
};

const convertBaseColorsToNewTheme = {
  black900: baseColors.black,
  black800: baseColors.black,
  black750: baseColors.black,
  black700: baseColors.black,
  lavender450: baseColors.lavender500,
  lavender350: baseColors.lavender400,
  lavender250: baseColors.lavender300,
  lavender225: baseColors.lavender200,
  gray500: baseColors.grey,
};

const semanticColors = {
  active: baseColors.lavender500,
  darkShadow1: `${baseColors.gunmetal}` + percentToHex(35),
  darkShadow2: `${baseColors.gunmetal}` + percentToHex(40),
  darkShadow3: `${baseColors.gunmetal}` + percentToHex(45),
  darkShadow4: `${baseColors.gunmetal}` + percentToHex(50),
  default: baseColors.black,
  error: baseColors.red500,
  focusShadow: `${baseColors.lavender500}` + percentToHex(50),
  lightShadow1: `${baseColors.gunmetal}` + percentToHex(10),
  lightShadow2: `${baseColors.gunmetal0}` + percentToHex(15),
  lightShadow3: `${baseColors.gunmetal}` + percentToHex(20),
  lightShadow4: `${baseColors.gunmetal}` + percentToHex(25),
  scrim: `${baseColors.gunmetal}` + percentToHex(60),
  scrimLavender: `${baseColors.lavender100}` + percentToHex(80),
  scrimWhite: `${baseColors.white}` + percentToHex(80),
  promo: baseColors.lavender,
  subdued: baseColors.gray500,
  whiteSubdued: `${baseColors.white}` + percentToHex(50),
};

const colors = {
  ...baseColors,
  ...semanticColors,
  ...convertBaseColorsToNewTheme,
};

/**
 * These are colors defined in the Design System that will be added as CSS variables
 * to the `:root` element in the global styles. These are used with Builder.io's
 * design tokens feature to allow for a selection of values in the Style panel.
 */
export const designSystemColors = {
  brand: `var(--colors-lavender, ${colors.lavender})`,
  'text-color-default': `var(--colors-gunmetal, ${colors.gunmetal})`,
  'text-color-default-secondary': `var(--colors-white, ${colors.white})`,
  white: colors.white,
  marble: colors.marble,
  stone: colors.stone,
  grey: colors.grey,
  gunmetal: colors.gunmetal,
  black: colors.black,
  lavender: colors.lavender,
  lavender900: colors.lavender900,
  lavender800: colors.lavender800,
  lavender700: colors.lavender700,
  lavender600: colors.lavender600,
  lavender500: colors.lavender500,
  lavender400: colors.lavender400,
  lavender300: colors.lavender300,
  lavender200: colors.lavender200,
  lavender100: colors.lavender100,
  red: colors.red500,
  green: colors.green,
};

function percentToHex(percent) {
  const percentages = {
    100: 'ff',
    95: 'f2',
    90: 'e6',
    85: 'd9',
    80: 'cc',
    75: 'bf',
    70: 'b3',
    65: 'a6',
    60: '99',
    55: '8c',
    50: '80',
    45: '73',
    40: '66',
    35: '59',
    30: '4d',
    25: '40',
    20: '33',
    15: '26',
    10: '1a',
    5: '0d',
    0: '00',
  };
  return percentages[percent] || 'ff';
}

const headerVariants = {
  h1: css`
    font-family: 'Arizona';
    font-size: 90px;
    font-weight: 400;
    line-height: 108px;
    letter-spacing: 2px;
    text-transform: uppercase;
  `,
  h2: css`
    font-family: 'Arizona';
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
  h3: css`
    font-family: 'Arizona';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
  `,
};

const subHeaderSharedValues = css`
  font-family: 'Plain', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 600;
`;
const subHeaderUppercaseSharedValues = css`
  letter-spacing: 1px;
  text-transform: uppercase;
`;
const subHeaderVariants = {
  subHeading1Uppercase: css`
    ${subHeaderSharedValues}
    ${subHeaderUppercaseSharedValues}
    line-height: 24px;
    font-size: 20px;
  `,
  subHeading2Uppercase: css`
    ${subHeaderSharedValues}
    ${subHeaderUppercaseSharedValues}
    font-size: 16px;
    line-height: 20px;
  `,
  subHeading3Uppercase: css`
    ${subHeaderSharedValues}
    ${subHeaderUppercaseSharedValues}
    font-size: 14px;
    line-height: 18px;
  `,
  subHeading4Uppercase: css`
    ${subHeaderSharedValues}
    ${subHeaderUppercaseSharedValues}
    font-size: 12px;
    line-height: 16px;
  `,
  subHeading5Uppercase: css`
    ${subHeaderSharedValues}
    ${subHeaderUppercaseSharedValues}
    font-size: 10px;
    line-height: 14px;
  `,
  subHeading1: css`
    ${subHeaderSharedValues}
    font-size: 20px;
    line-height: 28px;
  `,
  subHeading2: css`
    ${subHeaderSharedValues}
    font-size: 16px;
    line-height: 24px;
  `,
  subHeading3: css`
    ${subHeaderSharedValues}
    font-size: 14px;
    line-height: 20px;
  `,
  subHeading4: css`
    ${subHeaderSharedValues}
    font-size: 12px;
    line-height: 18px;
  `,
  subHeading5: css`
    ${subHeaderSharedValues}
    font-size: 10px;
    line-height: 14px;
  `,
};

const sharedParagraphStyles = css`
  font-family: 'Plain', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 400;
`;
const sharedUppercaseParagraphStyles = css`
  text-transform: uppercase;
  letter-spacing: 1px;
`;
const lowercaseParagraphVariants = {
  paragraph1: css`
    ${sharedParagraphStyles}
    font-size: 20px;
    line-height: 28px;
  `,
  paragraph2: css`
    ${sharedParagraphStyles}
    font-size: 16px;
    line-height: 24px;
  `,
  paragraph3: css`
    ${sharedParagraphStyles}
    font-size: 14px;
    line-height: 20px;
  `,
  paragraph4: css`
    ${sharedParagraphStyles}
    font-size: 12px;
    line-height: 18px;
  `,
  paragraph5: css`
    ${sharedParagraphStyles}
    font-size: 10px;
    line-height: 14px;
  `,
};

const uppercaseParagraphVariants = {
  paragraph1Uppercase: css`
    ${sharedParagraphStyles}
    ${lowercaseParagraphVariants.paragraph1}
    ${sharedUppercaseParagraphStyles}
  `,
  paragraph2Uppercase: css`
    ${sharedParagraphStyles}
    ${lowercaseParagraphVariants.paragraph2}
    ${sharedUppercaseParagraphStyles}
  `,
  paragraph3Uppercase: css`
    ${sharedParagraphStyles}
    ${lowercaseParagraphVariants.paragraph3}
    ${sharedUppercaseParagraphStyles}
  `,
  paragraph4Uppercase: css`
    ${sharedParagraphStyles}
    ${lowercaseParagraphVariants.paragraph4}
    ${sharedUppercaseParagraphStyles}
  `,
  paragraph5Uppercase: css`
    ${sharedParagraphStyles}
    ${lowercaseParagraphVariants.paragraph5}
    ${sharedUppercaseParagraphStyles}
  `,
};

const paragraphVariants = {
  ...lowercaseParagraphVariants,
  ...uppercaseParagraphVariants,
};

const defaultDisabledButtonStyle = css`
  opacity: 50%;
  cursor: not-allowed;
`;

const getButtonBackgroundColor = props => {
  if (props.secondary) {
    return 'transparent';
  }
  if (props.alternate) {
    return props.theme.colors.lavender;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.gunmetal;
};

const getButtonHoverBackgroundColor = props => {
  if (props.secondary) {
    return 'transparent';
  }
  if (props.alternate) {
    return props.theme.colors.lavender400;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.black;
};

const getButtonFontColor = props => {
  if (props.secondary) {
    return props.white ? props.theme.colors.white : props.theme.colors.gunmetal;
  }
  return props.white ? props.theme.colors.gunmetal : props.theme.colors.white;
};

const getButtonBorderColor = props => {
  if (props.alternate) {
    return props.theme.colors.lavender;
  }
  if (props.secondary && props.white) {
    return props.theme.colors.white;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.gunmetal;
};

const sharedButtonStyles = css`
  align-items: center;
  background-color: ${colors.gunmetal};
  background-color: ${getButtonBackgroundColor};
  border: 1px solid ${getButtonBorderColor};
  box-sizing: border-box;
  color: ${colors.white};
  color: ${getButtonFontColor};
  cursor: pointer;
  display: inline-flex;
  min-height: 50px;
  justify-content: center;
  margin: 0;
  min-width: 182px;
  outline: 0;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  width: ${props => (props.fullWidth ? '100%' : 'initial')};
  transition: background-color 300ms linear, border-color 300ms linear;

  ${subHeaderVariants.subHeading3Uppercase}
  &:disabled {
    ${defaultDisabledButtonStyle};
  }

  /* Apply disabled styles for ButtonLink */
  ${props =>
    props.disabled && props.tagName !== 'button'
      ? defaultDisabledButtonStyle
      : ''};

  &:focus {
    outline: 2px solid ${colors.active};
  }

  &:hover {
    background-color: ${getButtonHoverBackgroundColor};
  }

  svg {
    margin-right: 5px;
    margin-bottom: 2px;
    /* Horizontally center the content better. */
    margin-left: -5px;
  }
`;

const sharedPrimaryButtonStyles = css`
  min-width: 186px;
  padding: 16px 24px;
  border: none;
`;

const sharedSecondaryButtonStyles = css`
  min-width: 208px;
  padding: 16px 24px;
  border: 1px solid;
  background-color: transparent;
`;

const sharedTertiaryButtonStyles = css`
  min-width: 167px;
  padding: 8px 16px;
  min-height: 32px;
  border: 0;
  ${subHeaderVariants.subHeading4Uppercase}
`;

const buttonVariants = {
  default: sharedButtonStyles,
  primaryDark: css`
    ${sharedButtonStyles}
    ${sharedPrimaryButtonStyles}
    background-color: ${colors.gunmetal};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.black};
    }
  `,
  primaryLight: css`
    ${sharedButtonStyles}
    ${sharedPrimaryButtonStyles}
    background-color: ${colors.white};
    color: ${colors.gunmetal};

    &:hover {
      background-color: ${colors.stone};
    }
  `,
  primaryLavender: css`
    ${sharedButtonStyles}
    ${sharedPrimaryButtonStyles}
    background-color: ${colors.lavender};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.lavender400};
    }
  `,
  secondaryDark: css`
    ${sharedButtonStyles}
    ${sharedSecondaryButtonStyles}
    color: ${colors.gunmetal};
    border-color: ${colors.black};

    &:hover {
      background-color: ${colors.stone}${percentToHex(50)};
    }
  `,
  secondaryLight: css`
    ${sharedButtonStyles}
    ${sharedSecondaryButtonStyles}
    color: ${colors.white};
    border-color: ${colors.white};

    &:hover {
      background-color: ${colors.gunmetal}${percentToHex(50)};
    }
  `,
  secondaryLavender: css`
    ${sharedButtonStyles}
    ${sharedSecondaryButtonStyles}
    color: ${colors.lavender600};
    border-color: ${colors.lavender600};

    &:hover {
      background-color: ${colors.lavender100};
      border-color: ${colors.lavender700};
    }
  `,
  tertiaryDark: css`
    ${sharedButtonStyles}
    ${sharedTertiaryButtonStyles}
    color: ${colors.white};
    background-color: ${colors.gunmetal};

    &:hover {
      background-color: ${colors.black};
    }
  `,
  tertiaryLight: css`
    ${sharedButtonStyles}
    ${sharedTertiaryButtonStyles}
    color: ${colors.gunmetal};
    background-color: ${colors.white};

    &:hover {
      background-color: ${colors.stone};
    }
  `,
};

const sharedLinkStyles = css`
  cursor: pointer;
  color: ${colors.gunmetal};
  transition: border-bottom linear 300ms;
  text-decoration: none;

  &:disabled {
    border: none;
    opacity: 50%;
    cursor: not-allowed;
  }
`;

const sharedNavigationLinkStyles = css`
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid ${colors.black};
    color: ${colors.black};
  }
`;

const sharedTextLinkStyles = css`
  text-decoration: underline;
  text-decoration-color: ${colors.gunmetal};

  &:hover {
    text-decoration: none;
  }
`;

const navbarLinkUnderlineStyles = css`
  border: unset;
  transition: none;

  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }

  &:hover {
    border-bottom: 1px solid transparent;

    &::after {
      background: ${({ theme }) => theme.colors.lavender};
    }
  }
`;

const linkVariants = {
  navigationPrimary: css`
    ${sharedLinkStyles}
    ${sharedNavigationLinkStyles}
    ${paragraphVariants.paragraph3Uppercase}
  `,
  navigationSecondary: css`
    ${sharedLinkStyles}
    ${sharedNavigationLinkStyles}
    ${paragraphVariants.paragraph4Uppercase}
  `,
  textLinkPrimary: css`
    ${sharedLinkStyles}
    ${sharedTextLinkStyles}
    ${paragraphVariants.paragraph3Uppercase}
    padding: 4px 0px 2px 0px;
  `,
  textLinkSecondary: css`
    ${sharedLinkStyles}
    ${sharedTextLinkStyles}
    ${paragraphVariants.paragraph4Uppercase}
    padding: 4px 0px 2px 0px;
  `,
  textLinkTertiary: css`
    ${sharedLinkStyles}
    ${sharedTextLinkStyles}
    ${paragraphVariants.paragraph4}
  `,
  tabLink: css`
    ${sharedLinkStyles}
    ${headerVariants.h3}

    ${props =>
      props.active && `border: 0px 0px 4px 0px solid ${colors.lavender};`}
  `,
  navbarLink: css`
    ${sharedLinkStyles}
    ${paragraphVariants.paragraph3Uppercase}
    ${navbarLinkUnderlineStyles}
  `,
};

const textInputVariants = {
  default: {
    inputStyle: css`
      padding: 2px 15px 0 0;
      line-height: 21px;
      transform: translate3d(0, 2px, 0);

      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }

      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }

      ${({ hasError }) =>
        hasError &&
        css`
          padding: 2px 15px 0 0;
          line-height: 21px;
          transform: translate3d(0, 2px, 0);

          ${desktop`
            font-size: 14px;
          `}
          &.focus-visible,
          &:focus-visible {
            outline: none !important;
          }
        `}
    `,
    inputQuizStyle: css`
      color: ${colors.white};

      ~ [data-after-input],
      ~ [data-before-input] {
        color: ${colors.white};
      }
    `,
    inputBoxStyle: css`
      border-color: ${colors.gunmetal};
      border-style: solid;
      border-width: 1px;
      padding: 6px 16px 8px 16px;

      label::first-letter {
        text-transform: capitalize;
      }

      label[data-label-position='inside'],
      div[data-label-position='inside'] {
        color: ${colors.gunmetal};
      }

      &[data-has-focus],
      &[data-has-value] {
        padding: 6px 16px 8px 16px;
        border-width: 1px;

        ~ p[data-field-hint] {
          color: ${colors.gunmetal};
        }
      }

      [data-after-input],
      [data-before-input] {
        margin: 0;
      }

      &[data-has-focus] {
        border-color: ${colors.black};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.black};
        }
      }

      ${({ hasError }) =>
        hasError &&
        css`
          border-color: ${colors.error};
          border-style: solid;
          border-width: 1px;
          padding: 6px 16px 8px 16px;

          label::first-letter {
            text-transform: capitalize;
          }

          &[data-has-focus] {
            padding: 6px 16px 8px 16px;
            border-color: ${colors.error};
            border-width: 1px;
          }

          [data-after-input],
          [data-before-input] {
            margin: 0;
          }
        `}
    `,
    inputBoxQuizStyle: css`
      background: transparent;
      border-color: ${colors.white};

      label[data-label-position='inside'],
      div[data-label-position='inside'] {
        color: ${colors.white};
      }

      &[data-has-focus],
      &[data-has-value] {
        ~ p[data-field-hint] {
          color: ${colors.white};
        }
      }

      &[data-has-focus] {
        border-color: ${colors.white};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.white};
        }
      }

      ${({ hasError }) =>
        hasError &&
        css`
          border-color: ${colors.error};

          &[data-has-focus] {
            border-color: ${colors.error};
          }
        `}
    `,
    errorStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.error};
    `,
    hintStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.grey};
    `,
    hintQuizStyle: css`
      color: ${colors.white};
    `,
    labelStyle: css`
      margin: 0;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0;
      }

      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${colors.subdued};
        transform: translate3d(0, 0, 0) scale(1);
      }

      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 12px, 0);
        margin: 0;
        line-height: 1;
        color: ${colors.gray500};
      }

      ${({ hasError }) =>
        hasError &&
        css`
          margin: 0;

          &[data-label-position='floating'],
          &[data-label-position='inside'] {
            margin-left: 0px;
          }

          &[data-label-position='inside'] {
            font-size: 12px;
            color: ${colors.error};
            transform: translate3d(0, 0, 0) scale(1);
            margin-left: 0;
          }

          &[data-label-position='floating'] {
            color: ${colors.gray500};
            font-size: 14px;
            transform: translate3d(0, 12px, 0);
            margin: 0;
            line-height: 1;
          }
        `}
    `,
    labelQuizStyle: css`
      &[data-label-position='inside'] {
        color: ${colors.white};
      }

      &[data-label-position='floating'] {
        color: ${colors.white};
      }

      ${({ hasError }) =>
        hasError &&
        css`
          &[data-label-position='inside'] {
            color: ${colors.error};
          }

          &[data-label-position='floating'] {
            color: ${colors.white};
          }
        `}
    `,
  },
  fullBorder: {
    inputStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 2px 15px 0 0;
      line-height: 21px;

      ${desktop`
      font-size: 14px;
    `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }

      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputBoxStyle: css`
      border-color: ${({ hasError }) =>
        hasError ? colors.error : colors.gray300};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      background: transparent none repeat scroll 0% 0%;
      padding: 6px 16px 8px 16px;

      label::first-letter {
        text-transform: capitalize;
      }

      &[data-has-focus] {
        padding: 6px 16px 8px 16px;
        border-width: 1px;
        border-radius: 4px;
        border-color: ${({ hasError }) =>
          hasError ? colors.error : colors.active};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${({ hasError }) => (hasError ? colors.error : colors.active)};
        }
      }

      [data-after-input],
      [data-before-input] {
        margin: 0;
      }

      ${({ hasError }) =>
        hasError &&
        css`
          border-color: ${colors.error};

          &[data-has-focus] {
            border-color: ${colors.error};
          }
        `}
    `,
    errorStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.error};
    `,
    hintStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${({ hasError }) => (hasError ? colors.error : colors.default)};
    `,
    labelStyle: css`
      background: transparent none repeat scroll 0% 0%;
      margin: 0;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
      }

      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${({ hasError }) => (hasError ? colors.error : colors.subdued)};
        transform: translate3d(0, 0, 0) scale(1);
        margin-left: 0px;
      }

      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
        line-height: 1;
        color: ${({ hasError }) => (hasError ? colors.error : colors.gray500)};
      }
    `,
  },
};

const globalStyleWithColorVariables = css`
  ${globalStyle}
  :root {
    ${generateDesignTokenVariables(designSystemColors)}
  }
`;

export default {
  // When the `fonts` are in the theme object, a `preload` link is  automatically added
  // to the head of the document by the `styledComponentsExtension`.
  // Currently when `preload` is used, the font flickering can occur, even in production.
  // so we are disabling it for now.
  // fonts,
  globalStyle: globalStyleWithColorVariables,
  themeVersion,
  colors,
  focusColor: colors.active,
  breakpoints: {
    small: 0,
    medium: 414,
    mobile: { maxWidth: 767 },
    large: 768,
    xlarge: 1024,
    xxlarge: 1440,
    xxxlarge: 1728,
  },
  spacing: {
    none: 0,
    tiny: 8,
    small: 16,
    moderate: 24,
    medium: 32,
    large: 40,
    xlarge: 80,
  },

  // A place to store flags for helping with contextual styling. For example,
  // you could set a `cartDrawer` flag so that components can render differently
  // if they know they are inside the CartDrawer component, without needing to
  // use CSS selectors.
  context: {},
  desktopBreakpoint: 'large',
  assets: {
    htmlStyles: css`
      font-size: 12px;

      ${desktop`
        font-size: 14px;
      `};

      @media screen and (min-width: ${props =>
          props.theme.breakpoints.xlarge}px) {
        font-size: 16px;
      }
    `,
    imageBreakpoint: 768,
  },
  assetSizes: {
    minimumHeight: {
      mobile: '100px',
    },
  },
  header: {
    variants: transformStylesToVariants(headerVariants, 'textStyles'),
  },
  subHeader: {
    variants: transformStylesToVariants(subHeaderVariants, 'textStyles'),
  },
  paragraph: {
    variants: transformStylesToVariants(paragraphVariants, 'textStyles'),
  },
  links: {
    variants: linkVariants,
  },
  button: {
    defaultStyle: sharedButtonStyles,
    variants: transformStylesToVariants(buttonVariants, 'buttonStyle'),
  },
  checkbox: {
    defaultStyle: css`
      background-color: transparent;
    `,
  },
  collapsibleTrigger: {
    defaultStyle: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      line-height: ${32 / 12};
      padding: 8px 0;
      cursor: pointer;
      user-select: none;
    `,
  },
  modalDialog: {
    defaultStyle: css`
      background-color: ${colors.white};
    `,
  },
  input: {
    defaultStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 0px 15px 8px 0px;
      line-height: 21px;

      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }

      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputErrorStyle: css`
      background: transparent none repeat scroll 0% 0%;
      padding: 0px 15px 8px 0px;
      line-height: 21px;

      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
    `,
  },
  textField: {
    variants: textInputVariants,
  },
  inputBox: {
    defaultStyle: css`
      border-color: ${colors.default};
      border-style: none none solid;
      border-width: 0px 0px 2px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 0px;
      background: transparent none repeat scroll 0% 0%;
      padding: 0;

      &[data-has-focus] {
        padding: 0;
        border-width: 0px 0px 2px;
        border-style: none none solid;
        box-shadow: none;
        border-color: ${colors.active};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      }
    `,
    inputBoxErrorStyle: css`
      border-color: ${colors.error};
      border-style: none none solid;
      border-width: 0px 0px 2px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 0px;
      background: transparent none repeat scroll 0% 0%;
      padding: 0;

      &[data-has-focus] {
        padding: 0;
        border-width: 0px 0px 2px;
        border-style: none none solid;
        box-shadow: none;
        border-color: ${colors.active};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.active};
        }
      }
    `,
  },
  labelInput: {
    defaultStyle: css`
      margin: 0;
      text-transform: uppercase;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
        line-height: 21px;
      }

      &[data-label-position='inside'] {
        font-size: 10px;
        color: ${colors.subdued};
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
      }

      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
      }
    `,
    labelInputErrorStyle: css`
      margin: 0;
      text-transform: uppercase;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
        line-height: 21px;
      }

      &[data-label-position='inside'] {
        font-size: 10px;
        color: ${colors.error};
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
      }

      &[data-label-position='floating'] {
        color: ${colors.error};
        font-size: 14px;
        transform: translate3d(0, 100%, 0);
        margin: 0;
      }
    `,
  },
  hintInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.default};
    `,
  },
  errorInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colors.error};
    `,
  },

  // TODO: Once V2 is considered ok, we should replace it with the default one
  inputV2: {
    defaultStyle: css`
      padding: 2px 15px 0 0;
      line-height: 21px;
      transform: translate3d(0, 2px, 0);

      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }

      &[data-label-position='inside'] {
        border-bottom: solid 10px ${colors.error};
      }
    `,
    inputErrorStyle: css`
      padding: 2px 15px 0 0;
      line-height: 21px;
      transform: translate3d(0, 2px, 0);

      ${desktop`
        font-size: 14px;
      `}
      &.focus-visible,
      &:focus-visible {
        outline: none !important;
      }
    `,
  },
  inputBoxV2: {
    defaultStyle: css`
      border-color: ${colors.gunmetal};
      border-style: solid;
      border-width: 1px;
      padding: 6px 16px 8px 16px;

      label::first-letter {
        text-transform: capitalize;
      }

      label[data-label-position='inside'],
      div[data-label-position='inside'] {
        color: ${colors.gunmetal};
      }

      &[data-has-focus],
      &[data-has-value] {
        padding: 6px 16px 8px 16px;
        border-width: 1px;

        ~ p[data-field-hint] {
          color: ${colors.gunmetal};
        }
      }

      [data-after-input],
      [data-before-input] {
        margin: 0;
      }

      &[data-has-focus] {
        border-color: ${colors.black};

        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colors.black};
        }
      }
    `,

    inputBoxErrorStyle: css`
      border-color: ${colors.error};
      border-style: solid;
      border-width: 1px;
      padding: 6px 16px 8px 16px;

      label::first-letter {
        text-transform: capitalize;
      }

      &[data-has-focus] {
        padding: 6px 16px 8px 16px;
        border-color: ${colors.error};
        border-width: 1px;
      }

      [data-after-input],
      [data-before-input] {
        margin: 0;
      }
    `,
    adyenStyle: css`
      border-color: ${props =>
        props.hasPaymentError ? colors.error : colors.gunmetal};
    `,
  },
  labelInputV2: {
    defaultStyle: css`
      margin: 0;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0;
      }

      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${colors.subdued};
        transform: translate3d(0, 0, 0) scale(1);
      }

      &[data-label-position='floating'] {
        font-size: 14px;
        transform: translate3d(0, 12px, 0);
        margin: 0;
        line-height: 1;
        color: ${colors.gray500};
      }
    `,
    labelInputErrorStyle: css`
      margin: 0;

      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        margin-left: 0px;
      }

      &[data-label-position='inside'] {
        font-size: 12px;
        color: ${colors.error};
        transform: translate3d(0, 0, 0) scale(1);
        margin-left: 0;
      }

      &[data-label-position='floating'] {
        color: ${colors.gray500};
        font-size: 14px;
        transform: translate3d(0, 12px, 0);
        margin: 0;
        line-height: 1;
      }
    `,
  },
  hintInputV2: {
    defaultStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.grey};
    `,
  },
  errorInputV2: {
    defaultStyle: css`
      margin: 4px 0 20px 0;
      font-size: 12px;
      line-height: ${72 / 5}px;
      color: ${colors.error};
    `,
  },
  selectV2: {
    errorStyle: css`
      margin-bottom: 18px;

      [data-button-list-wrapper] {
        border-color: ${colors.error};
        color: ${colors.error};
        border-style: solid;
        border-width: 1px;
      }

      select {
        border-color: ${colors.error};
      }

      ${Select.Label} {
        color: ${colors.error};
      }
    `,
    errorHintStyle: css`
      font-size: 12px;
      line-height: 14.4px;
      color: ${colors.error};
    `,
    listStyle: css`
      background-color: #fff;
      font-size: 14px;
      min-width: 100%;
      position: absolute;
      left: 0;
      z-index: 100;
      max-height: 252px;
      overflow-y: auto;

      ${desktop`
        font-size: 12px;
        left: auto;
        right: 0;
      `}
    `,
    itemStyle: css`
      height: 42px;
      cursor: pointer;
      padding: 16px;
      white-space: nowrap;
      color: ${colors.gunmetal};
      font-size: 14px;
      line-height: 8px;
      font-weight: 400;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: ${colors.black900};

      &:first-child {
        border-width: 1px 1px 1px 1px;
      }

      &:hover,
      &:checked,
      &[data-selected='true'] {
        background-color: ${colors.gunmetal};
        color: ${colors.white};
      }
    `,
    buttonStyle: css`
      padding: 25px 16px 8px 16px;
      color: ${colors.black};
    `,
    labelStyle: css`
      position: absolute;
      top: 4px;
      left: ${props => props.theme.spacing.small}px;
      text-transform: none;
      font-size: 12px;
      color: ${colors.gunmetal};
    `,
    wrapperStyle: css`
      flex-grow: 1;
      position: relative;
      width: 100%;
      border-color: ${colors.gunmetal};
      border-style: solid;
      border-width: 1px;
    `,
    quizStyle: css`
      select,
      select:focus {
        border-color: ${colors.white};
        color: ${colors.white};
        background-color: transparent;

        ~ svg {
          color: ${colors.white} !important;
        }

        ~ ${Select.Label} > span {
          color: ${colors.white};
        }
      }

      label {
        background-color: transparent;
      }

      ${Select.Label} {
        transition: top 0.1s ease-out 0s;
      }
    `,
    defaultStyle: css`
      ${Select.Caret} {
        right: 16px;
        bottom: 0;
        top: calc(50% - 2px);
        transition: all 0.2s ease-out 0s;
        fill: ${colors.gunmetal};
      }

      select,
      select:focus {
        padding: 22px 16px 8px 16px;
        border-color: ${colors.gunmetal};
        border-style: solid;
        border-width: 1px;
        font-size: 14px;
      }

      select:focus {
        border-color: ${colors.black};

        ~ svg {
          color: ${colors.black} !important;
        }

        ~ ${Select.Label} {
          color: ${colors.black};
        }
      }

      ${desktop`
        select:active ~ svg {
          transform: rotate(180deg);
        }
      `}
      svg {
        right: 16px;
        bottom: 0;
        top: calc(50% - 2px);
      }

      ${Select.Label} {
        position: absolute;
        top: 4px;
        left: ${props => props.theme.spacing.small}px;
        text-transform: none;
        font-size: 12px;
      }

      ${Select.Footer} {
        margin: 2px 0;
        min-height: ${props => props.theme.spacing.small}px;
        font-size: 12px;
      }

      ${Select.Error} {
        line-height: 16px;
      }

      label {
        background-color: ${colors.white};
      }
    `,
  },
  radio: {
    defaultStyle: css`
      opacity: ${props => (props.disabled ? 0.2 : 1)};
    `,
  },
  zIndex: {
    decrement: -10,
    base: 0,
    productImageCarousel: 1,
    productBrowserHeader: 1,
    signInModalClose: 2,
    navItems: 3,
    increment: 10,
    floatingBar: 11,
    toast: 102,
    borderfree: 20,
    newsletter: 30,
    flyout: 100,
    errorOverlay: 21,
    productOverlay: 4,
    productFilterBar: 3,
    productSortDropdown: 5,
  },
  nav: {
    desktopHeight: 107,
    tabletHeight: 60,
    mobileHeight: 50, // height for new top logo nav
    mobileSubNav: 40, // height for new added sub nav for mobile
  },
};
