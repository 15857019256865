import React, { useEffect, useState } from 'react';

import config from 'config';

import { useCustomer } from '@techstyle/react-accounts';
import { Script } from '@techstyle/react-marketing';

import { useOneTrustConsent } from '../../utils/useOneTrustConsent';

declare global {
  interface Window {
    cnstrcUserId: string;
  }
}

interface ConstructorIoSnippetProps {
  scriptSrc?: string;
}

export function ConstructorIoSnippet({
  scriptSrc = config.get('public.constructorio.scriptSrc'),
}: ConstructorIoSnippetProps) {
  const consentList = useOneTrustConsent();
  const [hasConsent, setHasConsent] = useState(false);
  const { customerKey } = useCustomer();

  useEffect(() => {
    setHasConsent(consentList.includes('C0003'));
  }, [consentList]);

  useEffect(() => {
    if (customerKey) {
      window.cnstrcUserId = customerKey;
    }
  }, [customerKey]);
  return (
    <>
      {hasConsent ? (
        /* @ts-ignore to-do: fix Script component types */
        <Script
          globalScriptKey="constructorio-script"
          skipServerRender
          src={scriptSrc}
          defer
        />
      ) : null}
    </>
  );
}
