import React from 'react';

import PropTypes from 'prop-types';

// utils
import ReactCanvasConfetti from 'react-canvas-confetti';
import styled from 'styled-components';

// components

const ConfettiContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SavageCanvasConfetti = styled(ReactCanvasConfetti)`
  height: 500px;
  width: 400px;
  position: absolute;
  x: 0;
  y: 0;
  pointer-events: none;
  z-index: ${({ theme }) => theme.zIndex.flyout};
`;

const confettiColors = [
  '#E6DEFF',
  '#CABDFF',
  '#8F84F0',
  '#370EB2',
  '#18181E',
  '#3D9CA8',
];

const EMPConfettiWrapper = function ({
  children,
  fireConfetti = false,
  onDecayCallback,
}) {
  return (
    <ConfettiContainer>
      <SavageCanvasConfetti
        fire={fireConfetti}
        onDecay={onDecayCallback}
        particleCount={150}
        spread={360}
        startVelocity={15}
        angle={90}
        colors={confettiColors}
      />
      {children}
    </ConfettiContainer>
  );
};

export default EMPConfettiWrapper;

EMPConfettiWrapper.propTypes = {
  children: PropTypes.node,
  fireConfetti: PropTypes.bool,
  onDecayCallback: PropTypes.func,
};
