import { getMembership } from '@techstyle/react-accounts';
import { getSession } from '@techstyle/redux-core';

/**
 * A utility to get the membership status used for tracking keys like `user_status_initial` and `sxf_membership_status`.
 */
export const getSXFMembershipStatus = nextState => {
  const {
    networkStatus: { isUpToDate },
    membershipId,
    isVip,
    isDowngraded,
    isPaygo,
    isLead,
    isClassicVip,
    isGold,
    isElite,
    isDiamond,
    isDiamondElite,
    isCancelled,
    isSoftCancel,
    isPassiveCancel,
  } = getMembership(nextState);
  const { isLoggedIn, isFirstTimeVisitor } = getSession(nextState);

  if (!isLoggedIn && isFirstTimeVisitor) {
    return 'First Time Visitor';
  }
  if (!isUpToDate && !membershipId) {
    return 'Visitor';
  }

  if (isLead) {
    if (isPaygo && !isDowngraded) {
      return 'PayGo';
    }
    if (isPaygo && isDowngraded) {
      return 'Downgraded';
    }

    return 'Lead';
  }

  if (isVip) {
    if (isClassicVip) {
      return 'VIP Classic';
    }
    if (isGold || isElite) {
      return 'Gold';
    }
    if (isDiamond || isDiamondElite) {
      return 'Diamond';
    }

    return 'VIP';
  }

  if (isCancelled) {
    return isSoftCancel || isPassiveCancel ? 'Passive Cancel' : 'Cancelled';
  }

  return 'Visitor';
};

/**
 * A utility to get the membership status for the `membership_status` tracking key.
 * This is based on code from the {@link https://git.techstyle.net/projects/ECOM/repos/react-packages/browse/packages/react-marketing/src/GoogleTagManagerVariables/GoogleTagManagerVariables.js#391-409| `GoogleTagManagerVariables` component}
 * in the `@techstyle/react-marketing` package that sets the `membership_status` key.
 */
export const getMembershipStatus = nextState => {
  const membership = getMembership(nextState);
  const { isReturningVisitor, isFirstTimeVisitor } = getSession(nextState);
  const { isElite, isLead, isHardCancel, isVip } = membership;

  let membershipStatus = '';
  if (isHardCancel) {
    membershipStatus = 'hard cancel';
  } else if (isLead) {
    membershipStatus = 'lead';
  } else if (isFirstTimeVisitor) {
    membershipStatus = 'first time visitor';
  } else if (isReturningVisitor) {
    membershipStatus = 'visitor';
  } else if (isElite) {
    membershipStatus = 'vip elite';
  } else if (isVip) {
    membershipStatus = 'vip';
  } else {
    membershipStatus = 'client';
  }

  return membershipStatus;
};
