import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { desktop } from '../../styles';

const Wrapper = styled.span`
  display: flex;
  padding: 2px ${props => props.theme.spacing.tiny}px;

  background-color: ${props => props.theme.colors.scrim};
  color: ${props => props.theme.colors.white};
  font-size: 11px;
  line-height: normal;
  letter-spacing: 0.9px;

  ${desktop`
    font-size: 12px;
    letter-spacing: 1px;
  `}
`;

export default class FWFlag extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  renderLabel = () => {
    return (
      <FormattedMessage id="global_checkout.fw_label" defaultMessage="F/W'19" />
    );
  };

  render() {
    const { className } = this.props;
    return <Wrapper className={className}>{this.renderLabel()}</Wrapper>;
  }
}
