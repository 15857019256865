import { connect } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';

import { getMemberJoinDate } from '../../utils/selectors';

import SkinnyBanner from './SkinnyBanner';

export function mapStateToProps(state, ownProps) {
  return {
    memberJoinDate: getMemberJoinDate(state),
    membershipLoaded:
      getMembership(state).networkStatus.isUpToDate &&
      !getMembership(state).networkStatus.isLoading,
    persona: getMembership(state).persona || null,
    initialized: getMembership(state).networkStatus.isUpToDate,
  };
}

export default connect(mapStateToProps)(SkinnyBanner);
