import React, { useState, useEffect, useRef, useCallback } from 'react';

import * as Sentry from '@sentry/nextjs';
import config from 'config';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import { Script } from '@techstyle/react-marketing';

const adaWidgetSitekey = config.get('public.adaWidgetSitekey');
const INDbtnWrapStyles = createGlobalStyle`
  #INDbtnWrap {
    display: none;
  }
`;

function usePolling(condition, interval, timeout) {
  const [status, setStatus] = useState();
  const timeoutRef = useRef();

  const startPolling = useCallback(() => {
    clearTimeout(timeoutRef.current);
    const endTime = Date.now() + timeout;
    const check = () => {
      const newStatus = condition();
      if (newStatus != null) {
        setStatus(newStatus);
        return;
      }
      const now = Date.now();
      if (now < endTime) {
        timeoutRef.current = setTimeout(check, interval);
      } else {
        Sentry.captureException('usePolling: Timed out.');
      }
    };
    check();
  }, [condition, interval, timeout]);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return [startPolling, status];
}

function checkForButton() {
  return document.getElementById('INDmenu-btn');
}

export default function ADAWidgetSnippet({ onReady }) {
  const [startPolling, button] = usePolling(checkForButton, 500, 20000);
  const [isReady, setReady] = useState(false);

  const setScriptLoaded = useCallback(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (isReady) {
      startPolling();
    }
  }, [isReady, startPolling]);

  useEffect(() => {
    if (button && onReady) {
      onReady(button);
    }
  }, [button, onReady]);

  return (
    <>
      <Script
        globalScriptKey="ada-widget-global-var"
        dangerouslySetInnerHTML={{
          __html: `
        window.interdeal = {
          sitekey: "${adaWidgetSitekey}",
          Position: "Left",
          Menulang: "EN",
          domains: {
            js: "https://aacdn.nagich.com/",
            acc: "https://access.nagich.com/"
          },
          btnStyle: {
            vPosition: ["80%", "20%"],
            scale: ["","0.5"],
            color: {
              main: "#122231",
              second: "#fff"
            },
          },
        };
        `,
        }}
      />

      <Script
        defer
        globalScriptKey="ada-widget"
        src="https://aacdn.nagich.com/core/4.6.11/accessibility.js"
        onReady={setScriptLoaded}
        skipServerRender
        data-cfasync
        integrity="sha512-SVffVpbO/SKg8zoE3NWu0Ct32mTRDO5b27N4qA5C+e6ctRHVwAk158RdBWMlaD2UuAtQUxeRwY71joiCkwsFVA=="
        crossOrigin="anonymous"
      />
      <INDbtnWrapStyles />
    </>
  );
}

ADAWidgetSnippet.propTypes = {
  onReady: PropTypes.func,
};
