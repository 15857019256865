import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMembership, addWishlistItem } from '@techstyle/react-accounts';
import { getSession, getNewDateFunction } from '@techstyle/redux-core';

import { trackAddToWishlistEvent } from '../../actions/account/wishlist';
import { trackRemoveGwp } from '../../actions/checkout';
import { trackQuickViewModalOpen } from '../../actions/quickView';
import {
  getGwpProduct,
  getMembershipCartItem,
  getMinimumQuantity,
  hasNonPreorderItems,
  isExclusiveProduct,
  isPreorderable,
  isClearanceItem,
  isNMP,
} from '../../utils/selectors';

import BasketItem from './BasketItem';

export function mapStateToProps(state, ownProps) {
  const { hasMinimumQuantityTag, minimumQuantity } = getMinimumQuantity(
    state,
    ownProps
  );
  return {
    availableTokenQuantity: getMembership(state).availableTokenQuantity,
    cartItems: state.checkout.items,
    gwpProduct: getGwpProduct(state),
    hasMembershipItem: !!getMembershipCartItem(state),
    hasMinimumQuantityTag,
    hasNonPreorderItems: !!hasNonPreorderItems(state, ownProps),
    isBorderfreeCustomer: state.borderfree.isBorderfreeCustomer,
    isDowngraded: getMembership(state).isDowngraded,
    isVip: getMembership(state).isVip,
    isNMP: isNMP(state),
    isExclusive: isExclusiveProduct(state, ownProps),
    isClearanceItem: isClearanceItem(state, ownProps),
    isLoggedIn: getSession(state).isLoggedIn,
    isPreorderable: isPreorderable(state, ownProps),
    getNewDate: getNewDateFunction(state),
    minimumQuantity,
    tld: state.domain.tld,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToWishlist: addWishlistItem,
      trackAddToWishlistEvent,
      trackRemoveGwp,
      trackQuickViewModalOpen,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketItem);
