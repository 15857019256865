export function validateAddress(
  address,
  savedAddress,
  isMondialRelay = false,
  invalidZipcodeRegex = []
) {
  const result = {
    isEqual: false,
    isValid: false,
    isPotentiallyValid: false,
  };

  const requiredFields = [
    'firstName',
    'lastName',
    'address1',
    'city',
    'zip',
    'countryCode',
  ];
  if (['US', 'CA'].includes(address.countryCode)) {
    requiredFields.push('state');
  }
  if (isMondialRelay) {
    requiredFields.push('phone');
  }

  requiredFields.forEach(field => {
    if (address[field]) {
      if (field === 'zip') {
        result[field] = validateZip(address, invalidZipcodeRegex);
      } else {
        result[field] = {
          isValid: true,
          isPotentiallyValid: true,
        };
      }
    } else {
      result[field] = {
        isValid: false,
        isPotentiallyValid: true,
        error: 'ERROR_REQUIRED',
      };
    }
  });

  if (savedAddress && address.id === savedAddress.id) {
    // Don't attempt a deep comparison, just determine whether the object has changed.
    result.isEqual = address === savedAddress;
  }

  result.isValid = requiredFields.every(field => result[field].isValid);
  result.isPotentiallyValid = requiredFields.every(
    field => result[field].isPotentiallyValid
  );
  if (requiredFields.every(field => result[field].error === 'ERROR_REQUIRED')) {
    result.error = 'ERROR_EMPTY';
  }

  return result;
}

// validates correct length in zip code (default return does a check for EU zip codes)
// checking for unavailable zip is only in EU domains for now
export function validateZip(address, invalidZipcodeRegex) {
  const { zip = '', countryCode } = address || {};
  // converting zip to string as it errors with 0 length if
  // a prev. input address is checked upon checkout
  const stringZip = zip ? zip.toString() : '';
  const isAvailable =
    stringZip &&
    Array.isArray(invalidZipcodeRegex) &&
    invalidZipcodeRegex.length
      ? !new RegExp(invalidZipcodeRegex.join('|'), 'i').test(
          stringZip.replace(/\s/g, '')
        )
      : true;

  if (countryCode === 'US') {
    const isValid = /^[0-9]{5}(-[0-9]{4})?$/.test(stringZip);
    return {
      isValid,
      isPotentiallyValid: isValid || /^[0-9-]{0,10}$/.test(stringZip),
      error: isValid ? null : 'ERROR_INVALID',
    };
  } else {
    // Longest country postal code found was 13, just use that as a final test.
    const isValid =
      isAvailable &&
      /^[a-zA-Z0-9][a-zA-Z0-9 -]{0,11}[a-zA-Z0-9]$/.test(stringZip);
    let error = null;

    if (!isAvailable) {
      error = 'ERROR_UNAVAILABLE';
    } else if (!isValid) {
      error = 'ERROR_INVALID';
    }

    return {
      isValid,
      isPotentiallyValid:
        isValid || (isAvailable && /^[a-zA-Z0-9 -]{0,13}$/.test(stringZip)),
      error,
    };
  }
}

export function validateShippingOption(address, shippingOption) {
  if (!address || !shippingOption) {
    return false;
  }

  const zipValidationResult = validateZip(
    address,
    shippingOption.invalidZipcodeRegex
  );

  return !(
    !zipValidationResult.isValid &&
    zipValidationResult.error === 'ERROR_UNAVAILABLE'
  );
}
