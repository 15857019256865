import { useDispatch } from 'react-redux';

import { trackPromotionViewed } from '../actions/tracking';

import { buildPromotionTrackingPayload } from './buildPromotionTrackingPayload';

export const useTrackPromotionViewed = () => {
  const dispatch = useDispatch();

  return asset => {
    if (!asset) {
      return;
    }

    dispatch(trackPromotionViewed(buildPromotionTrackingPayload(asset)));
  };
};
