import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TextField } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import {
  ACCOUNT_EXISTS,
  ACCOUNT_EXISTS_GUEST,
  AUTH_FAILED,
  EMAIL_REQUIRED,
  INVALID_EMAIL,
  GENERAL_ERROR,
} from '../../constants/signIn';
import { v2 } from '../../utils/themeVersioning';

const getInputBoxStyle = (variant = 'default', showQuizSignUp = false) => css`
  margin-top: 22px;
  ${({ theme }) => theme.textField.variants[variant].inputBoxStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].inputBoxQuizStyle || '')}
  `}
`;

const getInputStyle = (variant = 'default', showQuizSignUp = false) => css`
  border-color: ${props =>
    props.disabled ? props.theme.colors.subdued : props.theme.colors.default};

  ${({ theme }) => theme.textField.variants[variant].inputStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].inputQuizStyle || '')}
  `}
`;

const getLabelStyle = (variant = 'default', showQuizSignUp = false) => css`
  color: ${props =>
    props.disabled ? props.theme.colors.subdued : props.theme.colors.default};

  ${({ theme }) => theme.textField.variants[variant].labelStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].labelQuizStyle || '')}
    &[data-label-position='floating'],
    &[data-label-position='inside'] {
      text-transform: lowercase;
    }
  `}
`;

const getErrorStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].errorStyle}
  ${v2`
    margin: 4px 0 0 0;
  `}
`;

const StyledTextField = styled(TextField).attrs(
  ({ variant, showQuizSignUp }) => ({
    errorStyle: getErrorStyle(variant),
    inputBoxStyle: getInputBoxStyle(variant, showQuizSignUp),
    inputStyle: getInputStyle(variant, showQuizSignUp),
    labelStyle: getLabelStyle(variant, showQuizSignUp),
  })
)``;

const EmailField = ({
  className,
  dataAutotag,
  onChange = () => {},
  value,
  variant = 'default',
  error,
  disabled,
  ...props
}) => {
  const handleChange = useCallback(
    event => {
      const { value } = event.target;
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const renderError = useCallback(error => {
    switch (error) {
      case EMAIL_REQUIRED:
        return (
          <FormattedMessage
            defaultMessage="Enter a valid email"
            id="global_checkout.email_field_validation_valid_email"
          />
        );
      case INVALID_EMAIL:
        return (
          <FormattedMessage
            defaultMessage="This email address is invalid."
            id="global_checkout.error_invalid_email_address"
          />
        );
      case ACCOUNT_EXISTS:
        return (
          <FormattedMessage
            defaultMessage="This email already exists"
            id="global_checkout.email_field_validation_account_exists"
          />
        );
      case ACCOUNT_EXISTS_GUEST:
        return (
          <FormattedMessage
            defaultMessage="It seems you already have an account. Please sign in or use Forgot Password if you need help logging in."
            id="global_checkout.email_field_validation_account_exists_guest"
          />
        );
      case AUTH_FAILED:
        return (
          <FormattedMessage
            defaultMessage="There was a problem signing in"
            id="global_checkout.email_field_validation_signin_error"
          />
        );
      case GENERAL_ERROR:
        return (
          <FormattedMessage
            id="global_checkout.general_error"
            defaultMessage="Oops we’ve encountered an error. Try again"
          />
        );
      default:
        return error || null;
    }
  }, []);

  return (
    <StyledTextField
      tagName="input"
      autoComplete="username"
      disabled={disabled}
      label={
        <FormattedMessage
          defaultMessage="Email"
          id="global_checkout.email_field_label"
        />
      }
      name="email"
      type="email"
      error={renderError(error)}
      onChange={handleChange}
      data-autotag={dataAutotag}
      variant={variant}
      value={value}
      {...props}
    />
  );
};

EmailField.propTypes = {
  className: PropTypes.string,
  dataAutotag: PropTypes.string,
  // Callback with email value.
  onChange: PropTypes.func,
  // Controlled Input
  value: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'fullBorder']),
  // Possible error.
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
};

export default EmailField;
