import React, { useContext, useEffect, useState } from 'react';

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import config from 'config';
import { useDispatch, useStore } from 'react-redux';

import { useCustomer } from '@techstyle/react-accounts';
import { useDomain } from '@techstyle/redux-core';

import { getBuilderPageTargeting } from '../../utils/getBuilderPageTargeting';

const CioClientContext = React.createContext<ConstructorIOClient>(undefined);

const transformUserAttributesToCioSegments = userAttributes => {
  if (!userAttributes) {
    return [];
  }

  return Object.entries(userAttributes)
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => `${key}|${value}`);
};

export const CioClientProvider = ({ children }) => {
  const keys = config.get('public.constructorio.keys');
  const { tld } = useDomain();
  const { customerKey } = useCustomer();
  const [cioClient, setCioClient] = useState<ConstructorIOClient>(undefined);
  const { getState } = useStore();
  const dispatch = useDispatch();
  const [userAttributes, setUserAttributes] = useState({});

  useEffect(() => {
    const callBuilderPageTargeting = async () => {
      const { userAttributes } = await getBuilderPageTargeting({
        state: getState(),
        isPreview: false,
        dispatch,
      });

      setUserAttributes(userAttributes);
    };

    callBuilderPageTargeting();
  }, [dispatch, getState]);

  useEffect(() => {
    setCioClient(
      new ConstructorIOClient({
        apiKey: keys[tld],
        userId: customerKey,
        segments: transformUserAttributesToCioSegments(userAttributes),
      })
    );
  }, [customerKey, keys, tld, userAttributes]);

  return (
    <CioClientContext.Provider value={cioClient}>
      {children}
    </CioClientContext.Provider>
  );
};

export const useCioClientContext = () => {
  return useContext(CioClientContext);
};
