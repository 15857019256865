import { getCustomer } from '@techstyle/react-accounts';

const identifyWithTraitsEvents = ({ identifyUser }) => {
  const { IDENTIFY_WITH_TRAITS } = require('../../actions/account');

  const identifyWithTraits = identifyUser((action, prevState, nextState) => {
    const { traits } = action.payload;
    const { id: userId } = getCustomer(nextState);

    return {
      userId: userId.toString(),
      traits,
    };
  });

  return {
    [IDENTIFY_WITH_TRAITS]: identifyWithTraits,
  };
};

export default identifyWithTraitsEvents;
