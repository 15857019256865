import config from 'config';

import comSizeMapping from './comSizeTranslationMapping';
import { categorizeBraSize, getProductCategoryTypes } from './productDetail';

const profileSizes = config.get('public.profileSizes');
const productBrowser = config.get('public.productBrowser');
const braSizeId = config.get('public.productSizeCategory.bra');
const dualSizeId = config.get('public.productSizeCategory.dual');
const oneSizeId = config.get('public.productSizeCategory.onesize');
const shoescaleId = config.get('public.productSizeCategory.shoescale');

export function getUndieSizes(tld = '.com') {
  const isEu = tld !== '.com';

  if (isEu) {
    return {
      regular: ['XS', 'S', 'M', 'L', 'XL'],
      plus: ['2XL', '3XL', '4XL', '5XL'],
      dualReg: ['XS/S', 'M/L'],
      dualPlus: ['XL/2XL', '3XL/4XL', '5XL'],
      men: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
      menRegular: ['XS', 'S', 'M', 'L'],
      menPlus: ['XL', '2XL', '3XL', '4XL'],
      allGenderRegular: ['XXS', 'XS', 'S', 'M', 'L', 'XL'],
      allGenderPlus: ['2X', '3X', '4X'],
    };
  }

  // Otherwise return USA sizes
  return {
    regular: ['XS', 'S', 'M', 'L', 'XL'],
    plus: ['1X', '2X', '3X', '4X'],
    dualReg: ['XS/S', 'M/L'],
    dualPlus: ['XL/1X', '2X/3X', '4X'],
    men: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    menRegular: ['XS', 'S', 'M', 'L'],
    menPlus: ['XL', 'XXL', 'XXXL', 'XXXXL'],
    allGenderRegular: ['XXS', 'XS', 'S', 'M', 'L', 'XL'],
    allGenderPlus: ['2X', '3X', '4X'],
  };
}

export function getLingerieSleepSizes(tld = '.com') {
  const isEu = tld !== '.com';

  if (isEu) {
    return {
      regular: ['XS', 'S', 'M', 'L', 'XL'],
      plus: ['2XL', '3XL', '4XL', '5XL'],
      men: ['S', 'M', 'L', 'XL', '2XL', '3XL'],
    };
  }

  // Otherwise return USA sizes
  return {
    regular: ['XS', 'S', 'M', 'L', 'XL'],
    plus: ['1X', '2X', '3X', '4X'],
    men: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
  };
}

export function getBraletteSizes(tld = '.com') {
  const isEu = tld !== '.com';

  if (isEu) {
    return {
      regular: ['XS', 'S', 'M', 'L', 'XL'],
      plus: ['2XL', '3XL', '4XL', '5XL'],
    };
  }

  // Otherwise return USA sizes
  return {
    regular: ['XS', 'S', 'M', 'L', 'XL'],
    plus: ['1X', '2X', '3X', '4X'],
  };
}

export const SizeLabels = {
  11402: 'XS/S',
  11403: 'M/L',
  11404: 'XL/1X',
  11405: '2X/3X',
};

export function getSportSizes(tld = '.com') {
  const isEu = tld !== '.com';
  if (isEu) {
    return ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
  }
  // Otherwise return USA sizes
  return ['XS', 'S', 'M', 'L', 'XL', '1X', '2X', '3X', '4X'];
}

export function getSlipperSizes(tld = '.com') {
  if (tld === '.com') {
    return [
      { value: 'S (7/8)', label: '7/8' },
      { value: 'M (9/10)', label: '9/10' },
      { value: 'L (11/12)', label: '11/12' },
      { value: 'XL (13/14)', label: '13/14' },
    ];
  }

  if (tld === '.co.uk') {
    return [
      { value: 'S (6/7)', label: '6/7' },
      { value: 'M (8/9)', label: '8/9' },
      { value: 'L (10/11)', label: '10/11' },
      { value: 'XL (12/13)', label: '12/13' },
    ];
  }

  // Otherwise return EU sizes
  return [
    { value: 'S (39/40.5)', label: '39/40.5' },
    { value: 'M (42/43)', label: '42/43' },
    { value: 'L (44.5/46)', label: '44.5/46' },
    { value: 'XL (47/48)', label: '47/48' },
  ];
}

// FIXME: Some harness sizes use the label as the size value
// e.g. Some products have size `1X/2X` instead of `XL/XXL`
// To work around this, we add additional sizes to the harness size list
export function getHarnessSizes(tld = '.com') {
  if (tld === '.com') {
    return [
      { value: 'XS/S', label: 'XS/S' },
      { value: 'M/L', label: 'M/L' },
      { value: 'XL/XXL', label: '1X/2X' },
      { value: 'XXXL/XXXXL', label: '3X/4X' },
      { value: '1X/2X', label: '1X/2X' },
      { value: '3X/4X', label: '3X/4X' },
    ];
  }

  if (tld === '.es' || tld === '.co.uk' || tld === '.fr' || tld === '.de') {
    return [
      { value: 'XS/S', label: 'XS/S' },
      { value: 'M/L', label: 'M/L' },
      { value: 'XL/XXL', label: 'XL/2XL' },
      { value: 'XXXL/XXXXL', label: '3XL/4XL' },
      { value: 'XL/2XL', label: 'XL/2XL' },
      { value: '3XL/4XL', label: '3XL/4XL' },
    ];
  }

  // Otherwise return EU sizes
  return [
    { value: 'XS/S', label: 'XS/S' },
    { value: 'M/L', label: 'M/L' },
    { value: 'XL/2XL', label: 'XL/2XL' },
    { value: '3XL/4XL', label: '3XL/4XL' },
  ];
}

export function getMenHarnessOneSize(tld = '.com', isPlusSize = false) {
  if (isPlusSize && tld === '.com') {
    return '1X-4X';
  } else if (isPlusSize && tld !== '.com') {
    return '2X-5X';
  } else {
    return 'XS-XL';
  }
}

export function filterOutSlipperSizes(sizes = []) {
  return sizes.filter(size => !size.value.match(/[()]/));
}

export function getSortedRegularSizes(tld = '.com') {
  const isEu = tld !== '.com';

  if (isEu) {
    return [
      'XS',
      'XS/S',
      'XS-XL',
      'S',
      'M',
      'M/L',
      'L',
      'XL',
      'XL/2XL',
      '2XL',
      '2XL-4XL',
      '3XL',
      '3XL/4XL',
      '4XL',
      '5XL',
    ];
  }

  return [
    'XS',
    'XS/S',
    'XS-XL',
    'S',
    'M',
    'M/L',
    'L',
    'XL',
    'XL/1X',
    '1X',
    '1X-3X',
    '2X',
    '2X/3X',
    '3X',
    '4X',
  ];
}

export function getBraSizes(domain) {
  switch (domain) {
    case '.co.uk':
      return {
        regular: [
          { label: '30A', isAvailable: true },
          { label: '30B', isAvailable: true },
          { label: '30C', isAvailable: true },
          { label: '30D', isAvailable: true },
          { label: '32A', isAvailable: true },
          { label: '32B', isAvailable: true },
          { label: '32C', isAvailable: true },
          { label: '32D', isAvailable: true },
          { label: '32DD', isAvailable: true },
          { label: '32E', isAvailable: true },
          { label: '34A', isAvailable: true },
          { label: '34B', isAvailable: true },
          { label: '34C', isAvailable: true },
          { label: '34D', isAvailable: true },
          { label: '34DD', isAvailable: true },
          { label: '34E', isAvailable: true },
          { label: '36A', isAvailable: true },
          { label: '36B', isAvailable: true },
          { label: '36C', isAvailable: true },
          { label: '36D', isAvailable: true },
          { label: '36DD', isAvailable: true },
          { label: '36E', isAvailable: false },
          { label: '38B', isAvailable: true },
          { label: '38C', isAvailable: true },
          { label: '38D', isAvailable: true },
        ],
        plus: [
          { label: '38DD', isAvailable: true },
          { label: '38E', isAvailable: true },
          { label: '38F', isAvailable: false },
          { label: '38FF', isAvailable: false },
          { label: '38G', isAvailable: true },
          { label: '38H', isAvailable: true },
          { label: '40C', isAvailable: true },
          { label: '40D', isAvailable: true },
          { label: '40DD', isAvailable: true },
          { label: '40E', isAvailable: false },
          { label: '40F', isAvailable: false },
          { label: '40FF', isAvailable: false },
          { label: '40G', isAvailable: true },
          { label: '40H', isAvailable: true },
          { label: '42C', isAvailable: true },
          { label: '42D', isAvailable: true },
          { label: '42DD', isAvailable: true },
          { label: '42E', isAvailable: false },
          { label: '42F', isAvailable: false },
          { label: '42FF', isAvailable: false },
          { label: '42G', isAvailable: true },
          { label: '42H', isAvailable: true },
          { label: '44D', isAvailable: true },
          { label: '44DD', isAvailable: true },
          { label: '44E', isAvailable: true },
          { label: '44F', isAvailable: false },
          { label: '46D', isAvailable: true },
          { label: '46DD', isAvailable: true },
          { label: '46E', isAvailable: true },
          { label: 'Other', isAvailable: false },
        ],
      };
    case '.fr':
    case '.es':
      return {
        regular: [
          { label: '80A', isAvailable: true },
          { label: '80B', isAvailable: true },
          { label: '80C', isAvailable: true },
          { label: '80D', isAvailable: true },
          { label: '85A', isAvailable: true },
          { label: '85B', isAvailable: true },
          { label: '85C', isAvailable: true },
          { label: '85D', isAvailable: true },
          { label: '85E', isAvailable: true },
          { label: '85F', isAvailable: true },
          { label: '90A', isAvailable: true },
          { label: '90B', isAvailable: true },
          { label: '90C', isAvailable: true },
          { label: '90D', isAvailable: true },
          { label: '90E', isAvailable: true },
          { label: '90F', isAvailable: true },
          { label: '95A', isAvailable: true },
          { label: '95B', isAvailable: true },
          { label: '95C', isAvailable: true },
          { label: '95D', isAvailable: true },
          { label: '95E', isAvailable: true },
          { label: '95F', isAvailable: false },
          { label: '100B', isAvailable: true },
          { label: '100C', isAvailable: true },
          { label: '100D', isAvailable: true },
        ],
        plus: [
          { label: '100E', isAvailable: true },
          { label: '100F', isAvailable: true },
          { label: '100G', isAvailable: false },
          { label: '100H', isAvailable: true },
          { label: '100I', isAvailable: true },
          { label: '105C', isAvailable: true },
          { label: '105D', isAvailable: true },
          { label: '105E', isAvailable: true },
          { label: '105F', isAvailable: false },
          { label: '105G', isAvailable: false },
          { label: '105H', isAvailable: true },
          { label: '105I', isAvailable: true },
          { label: '110C', isAvailable: true },
          { label: '110D', isAvailable: true },
          { label: '110E', isAvailable: true },
          { label: '110F', isAvailable: false },
          { label: '110G', isAvailable: false },
          { label: '110H', isAvailable: true },
          { label: '110I', isAvailable: true },
          { label: '115D', isAvailable: true },
          { label: '115E', isAvailable: true },
          { label: '115F', isAvailable: true },
          { label: '115G', isAvailable: false },
          { label: '120D', isAvailable: true },
          { label: '120E', isAvailable: true },
          { label: '120F', isAvailable: true },
          { label: 'Other', isAvailable: false },
        ],
      };
    case '.eu':
    case '.de':
      return {
        regular: [
          { label: '65A', isAvailable: true },
          { label: '65B', isAvailable: true },
          { label: '65C', isAvailable: true },
          { label: '65D', isAvailable: true },
          { label: '70A', isAvailable: true },
          { label: '70B', isAvailable: true },
          { label: '70C', isAvailable: true },
          { label: '70D', isAvailable: true },
          { label: '70E', isAvailable: true },
          { label: '70F', isAvailable: true },
          { label: '75A', isAvailable: true },
          { label: '75B', isAvailable: true },
          { label: '75C', isAvailable: true },
          { label: '75D', isAvailable: true },
          { label: '75E', isAvailable: true },
          { label: '75F', isAvailable: true },
          { label: '80A', isAvailable: true },
          { label: '80B', isAvailable: true },
          { label: '80C', isAvailable: true },
          { label: '80D', isAvailable: true },
          { label: '80E', isAvailable: true },
          { label: '80F', isAvailable: false },
          { label: '85B', isAvailable: true },
          { label: '85C', isAvailable: true },
          { label: '85D', isAvailable: true },
        ],
        plus: [
          { label: '85E', isAvailable: true },
          { label: '85F', isAvailable: true },
          { label: '85G', isAvailable: false },
          { label: '85H', isAvailable: true },
          { label: '85I', isAvailable: true },
          { label: '90C', isAvailable: true },
          { label: '90D', isAvailable: true },
          { label: '90E', isAvailable: true },
          { label: '90F', isAvailable: true },
          { label: '90G', isAvailable: false },
          { label: '90H', isAvailable: true },
          { label: '90I', isAvailable: true },
          { label: '95C', isAvailable: true },
          { label: '95D', isAvailable: true },
          { label: '95E', isAvailable: true },
          { label: '95F', isAvailable: false },
          { label: '95G', isAvailable: false },
          { label: '95H', isAvailable: true },
          { label: '95I', isAvailable: true },
          { label: '100D', isAvailable: true },
          { label: '100E', isAvailable: true },
          { label: '100F', isAvailable: true },
          { label: '100G', isAvailable: false },
          { label: '105D', isAvailable: true },
          { label: '105E', isAvailable: true },
          { label: '105F', isAvailable: true },
          { label: 'Other', isAvailable: false },
        ],
      };
    case '.com':
    default:
      return {
        regular: [
          { label: '30A', isAvailable: true },
          { label: '30B', isAvailable: true },
          { label: '30C', isAvailable: true },
          { label: '30D', isAvailable: true },
          { label: '32A', isAvailable: true },
          { label: '32B', isAvailable: true },
          { label: '32C', isAvailable: true },
          { label: '32D', isAvailable: true },
          { label: '32DD', isAvailable: true },
          { label: '32DDD', isAvailable: true },
          { label: '34A', isAvailable: true },
          { label: '34B', isAvailable: true },
          { label: '34C', isAvailable: true },
          { label: '34D', isAvailable: true },
          { label: '34DD', isAvailable: true },
          { label: '34DDD', isAvailable: true },
          { label: '36A', isAvailable: true },
          { label: '36B', isAvailable: true },
          { label: '36C', isAvailable: true },
          { label: '36D', isAvailable: true },
          { label: '36DD', isAvailable: true },
          { label: '36DDD', isAvailable: true },
          { label: '38B', isAvailable: true },
          { label: '38C', isAvailable: true },
          { label: '38D', isAvailable: true },
        ],
        plus: [
          { label: '38DD', isAvailable: true },
          { label: '38DDD', isAvailable: true },
          { label: '38G', isAvailable: true },
          { label: '38H', isAvailable: true },
          { label: '40C', isAvailable: true },
          { label: '40D', isAvailable: true },
          { label: '40DD', isAvailable: true },
          { label: '40DDD', isAvailable: true },
          { label: '40G', isAvailable: true },
          { label: '40H', isAvailable: true },
          { label: '42C', isAvailable: true },
          { label: '42D', isAvailable: true },
          { label: '42DD', isAvailable: true },
          { label: '42DDD', isAvailable: false },
          { label: '42G', isAvailable: true },
          { label: '42H', isAvailable: true },
          { label: '44D', isAvailable: true },
          { label: '44DD', isAvailable: true },
          { label: '44DDD', isAvailable: true },
          { label: '44G', isAvailable: true },
          { label: '46DD', isAvailable: true },
          { label: '46DDD', isAvailable: true },
          { label: 'Other', isAvailable: false },
        ],
      };
  }
}

export function getInfoSizeList(
  tld,
  isCurvy = false,
  isSet = false,
  isCombinedSet = false
) {
  let braList = getBraSizes(tld);

  if (isSet && !isCombinedSet) {
    braList = isCurvy ? braList.plus : braList.regular;
  } else {
    braList = [...braList.regular, ...braList.plus];
  }

  const bandLabelList = braList
    .filter(size => size.label !== 'Other')
    .map(size => {
      return { id: size.label, value: size.label };
    });
  const categorySizes = categorizeBraSize(bandLabelList);

  if (!categorySizes.length) {
    return null;
  }

  return categorySizes;
}

/** * This is being used to return the sizes (checkboxes) that are going to be
 *** displayed in the PDP
 *** Men products and All gender products have only one product (unique product ID)
 *** grouping both missy and curvy sizes
 *** While any category could have a product for missy sizes and a product for curvy
 *** sizes that would be linked together (two different product ids and
 *** linked_master_product !== null)
 */
export function getGenericSizes(
  tld,
  isCurvy,
  isSet,
  isCombinedSet = false,
  isMen = false,
  isAllGender = false
) {
  const nonBraSizes = getUndieSizes(tld);
  let nonBraSizesList;

  if (isMen) {
    nonBraSizesList = nonBraSizes.men;
  } else if (isAllGender) {
    nonBraSizesList = [
      ...nonBraSizes.allGenderRegular,
      ...nonBraSizes.allGenderPlus,
    ];
  } else if (isSet && !isCombinedSet) {
    nonBraSizesList = isCurvy ? nonBraSizes.plus : nonBraSizes.regular;
  } else {
    nonBraSizesList = [...nonBraSizes.regular, ...nonBraSizes.plus];
  }

  return nonBraSizesList;
}

export function getDualSizes(tld, isCurvy, isSet) {
  const nonBraSizes = getUndieSizes(tld);
  const nonBraSizesList = [...nonBraSizes.dualReg, ...nonBraSizes.dualPlus];
  return nonBraSizesList;
}

export const isNotDualSize = size => {
  return !size.includes('/') && size !== 'OSFM' && !size.includes('-');
};

export function getCupSizeList(braSizeList) {
  const cupSizeArray = braSizeList.map(size => {
    return size.cup;
  });

  return [...new Set(cupSizeArray.sort())];
}

export function getBandSizeList(braSizeList) {
  const bandSizeArray = braSizeList.map(size => {
    return size.band;
  });

  return [...new Set(bandSizeArray.sort((a, b) => a - b))];
}

export function getShoeSizes(tld = '.com') {
  if (tld === '.com') {
    return [
      { value: 'S (6/7)', label: '6/7' },
      { value: 'M (8/9)', label: '8/9' },
      { value: 'L (10)', label: '10' },
      { value: 'XL (11/12)', label: '11/12' },
    ];
  }

  if (tld === '.co.uk') {
    return [
      { value: 'S (4/5)', label: '4/5' },
      { value: 'M (6/7)', label: '6/7' },
      { value: 'L (8)', label: '8' },
      { value: 'XL (9/10)', label: '9/10' },
    ];
  }

  // eu and other domains
  return [
    { value: 'S (37/38)', label: '37/38' },
    { value: 'M (39/40)', label: '39/40' },
    { value: 'L (41)', label: '41' },
    { value: 'XL (42/43)', label: '42/43' },
  ];
}

function isSection(filters, section) {
  return (
    JSON.stringify(filters.defaultProductCategoryIds || []) ===
    JSON.stringify(
      productBrowser.sections[section].defaultProductCategoryIds || []
    )
  );
}

function isSubsection(filters, section, subsection) {
  const sectionData = productBrowser.sections[section];
  return (
    JSON.stringify(filters.categoryTagIds || []) ===
    JSON.stringify(sectionData.subsections[subsection].categoryTagIds || [])
  );
}

export function getProfileSizesForGrid(customer, filters) {
  const { optionSignatures } = customer;
  if (optionSignatures) {
    const userSizes = {
      braSizes: optionSignatures[profileSizes.bras] || [],
      braletteSizes: optionSignatures[profileSizes.bralettes] || [],
      undieSizes: optionSignatures[profileSizes.undies] || [],
      lingerieSizes: optionSignatures[profileSizes.lingerieSleep] || [],
    };

    // Instead of using `section` and `subsection` to determine where we are,
    // use `defaultProductCategoryIds` and `categoryTagIds` instead. These will
    // work on more pages, since `section` and `subsection` are specifically
    // used only when shopping those main categories. This way, profile sizes
    // can still work even for other PLPs when the user selects a category like
    // "Bras".
    if (
      (filters.defaultProductCategoryIds &&
        filters.defaultProductCategoryIds.length > 0) ||
      (filters.categoryTagIds && filters.categoryTagIds.length > 0)
    ) {
      if (isSection(filters, 'bras')) {
        if (isSubsection(filters, 'bras', 'bralettes')) {
          return userSizes.braletteSizes.length
            ? { braletteSizes: userSizes.braletteSizes }
            : {};
        } else if (filters.categoryTagIds && filters.categoryTagIds.length) {
          // If any other subsection, apply bra filters.
          return userSizes.braSizes.length
            ? { braSizes: userSizes.braSizes }
            : {};
        } else if (
          userSizes.braSizes.length ||
          userSizes.braletteSizes.length
        ) {
          return {
            braSizes: userSizes.braSizes,
            braletteSizes: userSizes.braletteSizes,
          };
        }
      } else if (isSection(filters, 'undies')) {
        return userSizes.undieSizes.length
          ? { undieSizes: userSizes.undieSizes }
          : {};
      } else if (
        filters.categoryTagIds &&
        filters.categoryTagIds.length &&
        filters.section !== 'sets'
      ) {
        return userSizes.lingerieSizes.length
          ? { lingerieSizes: userSizes.lingerieSizes }
          : {};
      }
    } else if (filters.sections) {
      const sections = {
        braSizes: filters.sections.bras,
        braletteSizes: filters.sections.bras,
        undieSizes: filters.sections.undies,
        lingerieSizes: filters.sections.lingerie,
      };
      const filteredCategory = Object.keys(userSizes).filter(key => {
        return userSizes[key].length && sections[key];
      });
      return filteredCategory.reduce((result, item) => {
        result[item] = userSizes[item];
        return result;
      }, {});
    }
  }
  return {};
}

export function getUserSizesForGrid(filters) {
  if (filters && filters.defaultProductCategoryIds) {
    return filters.optionSignatures;
  } else if (filters && filters.sections) {
    const isBrasGrid =
      filters.sections.bras &&
      !filters.sections.undies &&
      !filters.sections.lingerie;

    const isUndiesGrid =
      !filters.sections.bras &&
      filters.sections.undies &&
      !filters.sections.lingerie;

    if (isBrasGrid || isUndiesGrid) {
      return filters.optionSignatures;
    }
  }

  return [];
}

export const getRegionWarehouseSizeQuantity = (warehouses, warehouseIds) => {
  const warehouseIdsSet = new Set(warehouseIds);
  const quantity = warehouses
    .filter(warehouse => {
      return warehouseIdsSet.has(warehouse.warehouse_id);
    })
    .reduce((total, warehouse) => {
      return warehouse.available_quantity + total;
    }, 0);
  return Math.max(0, quantity);
};

export function hasBraSize(product) {
  if (product.product_option_id_list) {
    return product.product_option_id_list.includes(braSizeId);
  }
  return false;
}

export function hasShoeSize(product) {
  if (product.product_option_id_list) {
    return product.product_option_id_list.includes(shoescaleId);
  }
  return false;
}

export function isOneSize(product) {
  if ('lineId' in product && !product.masterProductId) {
    return true;
  } else if (product.product_option_id_list) {
    return product.product_option_id_list.includes(oneSizeId);
  } else if (product.productOptionIdList) {
    return product.productOptionIdList.includes(oneSizeId);
  }
  return false;
}

export function hasDualSize(product) {
  if (product && product.product_option_id_list) {
    return product.product_option_id_list.includes(dualSizeId);
  }
  return false;
}

export function getNonBraSizes(
  tld,
  product,
  isCurvy,
  isSet,
  displayFullRangeSize
) {
  if (hasDualSize(product)) {
    return getDualSizes(tld, isCurvy, isSet);
  }

  const {
    isBraExtender,
    isAdhesiveBra,
    isAllGender,
    isHarness,
    isMen,
    isTrinket,
    isSport,
    isSlippers,
  } = getProductCategoryTypes(product ? product.product_category_id_list : []);

  if (isBraExtender) {
    return getBraExtenderSizes();
  } else if (isHarness) {
    return getHarnessSizes();
  } else if (isSlippers) {
    return getSlipperSizes();
  } else if (isTrinket) {
    return getTrinketSizes(tld);
  } else if (isSport) {
    return getSportSizes(tld);
  } else if (isAdhesiveBra) {
    return getAdhesiveBraSizes(tld);
  }

  return getGenericSizes(
    tld,
    isCurvy,
    isSet,
    displayFullRangeSize,
    isMen,
    isAllGender
  );
}

function getBraExtenderSizes() {
  return ['1', '2', '3', '4', '5'];
}

function getTrinketSizes(tld = '.com') {
  if (tld === '.com') {
    return ['XS-XL', '1X-4X'];
  }

  return ['XS-XL', '2XL-5XL'];
}

function getAdhesiveBraSizes(tld = '.com') {
  switch (tld) {
    case '.com':
      return ['A', 'B', 'C', 'D', 'DD', 'DDD'];
    case '.co.uk':
      return ['A', 'B', 'C', 'D', 'DD', 'E'];
    default:
      return ['A', 'B', 'C', 'D', 'E', 'F'];
  }
}

export function getAllGenderEquivalentSizes(tld = '.com', sizeSelected) {
  if (tld === '.com') {
    const allGenderSizeMap = {
      XXS: { mens: null, womens: 'XS' },
      XS: { mens: 'XS', womens: 'S' },
      S: { mens: 'S', womens: 'M' },
      M: { mens: 'M', womens: 'L' },
      L: { mens: 'L', womens: 'XL/1X' },
      XL: { mens: 'XL', womens: '2X' },
      '2X': { mens: 'XXL', womens: '3X' },
      '3X': { mens: 'XXXL', womens: '4X' },
      '4X': { mens: 'XXXXL', womens: null },
    };
    return allGenderSizeMap[sizeSelected];
  } else {
    const allGenderSizeMapEu = {
      XXS: { mens: null, womens: 'XS' },
      XS: { mens: 'XS', womens: 'S' },
      S: { mens: 'S', womens: 'M' },
      M: { mens: 'M', womens: 'L' },
      L: { mens: 'L', womens: 'XL/2XL' },
      XL: { mens: 'XL', womens: '3XL' },
      '2X': { mens: '2XL', womens: '4XL' },
      '3X': { mens: '3XL', womens: '5XL' },
      '4X': { mens: '4XL', womens: null },
    };
    return allGenderSizeMapEu[sizeSelected];
  }
}

/*
 * Translate US sizes to any EU domain from comSizeTranslationMapping.
 * This can cater e.g. XS | XS-XL | XS/XL | ONE SIZE.
 */
export function translateToEUSize(productCategoryIdList, tagIdList, size, tld) {
  if (tld === '.com' || !size) {
    return size;
  }

  const { isMen, isSlippers, isAdhesiveBra } = getProductCategoryTypes(
    productCategoryIdList,
    tagIdList
  );
  const { splitSize, sizeSplitter } = splitCombinedSize(size);
  let mappingKey = 'regular';

  if (isMen) {
    mappingKey = 'men';
  } else if (isSlippers) {
    mappingKey = 'slippers';
  } else if (isAdhesiveBra) {
    mappingKey = 'adhesiveBras';
  }

  return splitSize
    .reduce((sizes, size) => {
      const translatedSize =
        comSizeMapping[tld][mappingKey][size.replace(/\s+/g, '')] || size;

      if (translatedSize) {
        sizes.push(translatedSize);
      }

      return sizes;
    }, [])
    .join(sizeSplitter);
}

export function splitCombinedSize(size) {
  if (!size) {
    return {};
  }

  let sizeSplitter = null;

  if (size.includes('/')) {
    sizeSplitter = '/';
  } else if (size.includes('-')) {
    sizeSplitter = '-';
  }

  return { splitSize: size.split(sizeSplitter), sizeSplitter };
}

// Converts .com lengths to EU
export function convertToEULength(height, domain) {
  if (domain === '.com' || domain === '.co.uk' || !height) {
    return height;
  }

  const footPerInch = 0.08333;
  const centimetersPerFoot = 30.48;
  const meterPerFoot = 0.3048;
  let [feet, inches] = height.split(/’|'/).reduce((lengths, length) => {
    if (length.trim()) {
      lengths.push(length);
    }
    return lengths;
  }, []);

  inches = parseInt(inches, 10) || 0;
  feet = (parseInt(feet, 10) || 0) + inches * footPerInch;

  switch (domain) {
    // to centimeter
    case '.fr':
    case '.es':
    case '.eu':
      return feet * centimetersPerFoot;
    // to meter
    case '.de':
      return feet * meterPerFoot;
    default:
      return height;
  }
}

export function convertUndiesToBoxerSizes(sizes = []) {
  if (!sizes?.length) {
    return [];
  }

  const undieSizeIndex = getUndieSizes().plus.indexOf(sizes[0]);
  const convertedSize = getUndieSizes().menPlus[undieSizeIndex];

  return convertedSize ? [...sizes, convertedSize] : sizes;
}

export function addMenSizeUndieToFilters(filters) {
  return {
    ...filters,
    aggregationFilter: {
      ...filters.aggregationFilter,
      size_undie: convertUndiesToBoxerSizes(
        filters.aggregationFilter.size_undie
      ),
    },
  };
}

export function addAllGenderSizesToFilters(filters) {
  // all gender sizes don't have their own filter selections, so we need to add them with the same value as size_lingerie
  return {
    ...filters,
    aggregationFilter: {
      ...filters.aggregationFilter,
      size_all_gender_bottom: filters.aggregationFilter.size_lingerie,
      size_all_gender_top: filters.aggregationFilter.size_lingerie,
    },
  };
}
