import React from 'react';

function XLogo({ className, fill = 'white', v2 = false }) {
  if (v2) {
    return (
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="14" rx="7" fill="#333536" />
        <path
          d="M10.0002 6.36207C10.7541 5.31893 11.5079 4.27591 12.2618 3.23277C12.3325 3.13506 12.261 3 12.1388 3H11.4597V3.054C11.4815 3.24814 11.445 3.4677 11.3494 3.71228C11.2537 3.957 11.0995 4.22674 10.8866 4.52164C10.5911 4.92886 10.2956 5.33595 10 5.74317C9.70457 5.33595 9.4091 4.92886 9.11349 4.52164C8.90055 4.22674 8.74626 3.957 8.65063 3.71228C8.55525 3.46783 8.51853 3.24827 8.54033 3.054V3H7.86128C7.73904 3 7.66754 3.13506 7.73826 3.23277L10.0002 6.36207Z"
          fill="#C5B4E3"
        />
        <path
          d="M7.56074 3.20791C7.46653 3.07768 7.31354 3.00017 7.15056 3.00017H6.71118V3.05417C6.93139 3.24133 7.13369 3.44627 7.31705 3.66926C7.50092 3.89224 7.73969 4.20163 8.0336 4.59729L9.42012 6.47634L7.738 8.74101C7.40723 9.19423 7.13888 9.54148 6.93346 9.78238C6.72766 10.0234 6.49967 10.2447 6.25 10.446V10.5H6.74933C6.91244 10.5 7.0653 10.4225 7.15951 10.2923L9.92023 6.47253L7.56074 3.20791Z"
          fill="#C5B4E3"
        />
        <path
          d="M13.0667 9.78234C12.8612 9.54143 12.5928 9.19419 12.2621 8.74097L10.58 6.4763L11.9665 4.59724C12.2604 4.20159 12.4992 3.8922 12.6831 3.66921C12.8664 3.44623 13.0687 3.24128 13.289 3.05413V3.00013H12.8496C12.6865 3.00013 12.5336 3.07763 12.4394 3.20787L10.0798 6.47249L12.8405 10.2922C12.9347 10.4225 13.0877 10.5 13.2507 10.5H13.75V10.446C13.5003 10.2447 13.2725 10.0234 13.0667 9.78234Z"
          fill="#C5B4E3"
        />
        <path
          d="M10 6.5829C9.11233 7.81103 8.22474 9.03905 7.33703 10.2672C7.26644 10.3649 7.33781 10.5 7.46005 10.5H8.12366V10.4568C8.10887 10.2555 8.15675 10.018 8.26692 9.7446C8.47713 9.22328 8.79583 8.83817 8.79583 8.83817C8.97335 8.60476 9.15126 8.36729 9.32929 8.12563C9.55936 7.81358 9.78281 7.50406 9.99991 7.19747C10.217 7.50419 10.4406 7.8137 10.6705 8.12563C10.8487 8.36729 11.0266 8.60476 11.204 8.83817C11.204 8.83817 11.5228 9.22328 11.7329 9.7446C11.8432 10.018 11.891 10.2555 11.8762 10.4568V10.5H12.5398C12.662 10.5 12.7334 10.3649 12.6628 10.2672L9.99978 6.5829H10Z"
          fill="#C5B4E3"
        />
      </svg>
    );
  }
  return (
    <svg
      className={className}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0747 17.217L17.034 17.2583V17.316H16.0576V17.2665L16.0169 17.2252H14.2268L14.1862 17.2665V17.3243H13.5556L13.5149 17.3697V17.4275H12.8761L12.8477 17.4605V17.5018L12.8761 17.5307H13.5149V17.5967L13.5556 17.638H14.1862V17.6792L14.2268 17.7205H16.0088L16.0495 17.6792V17.6215H17.0259V17.6792L17.0666 17.7205H18.8567L18.8973 17.6792V17.6215H19.5279L19.5686 17.5802V17.5141H20.2114L20.2399 17.4853V17.444L20.2114 17.411H19.5686V17.3532L19.5279 17.3078H18.8973V17.2665L18.8567 17.2252L17.0747 17.217Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9322 16.441L16.8956 16.4823V16.5442H16.1592V16.4823L16.1226 16.441H14.4301L14.3894 16.4823V16.5442H13.7588L13.7182 16.5855V16.6474H13.0794L13.0469 16.6763V16.7175L13.0794 16.7506H13.7182V16.8125L13.7588 16.8537H14.3813V16.9157L14.422 16.9569H16.1144L16.1511 16.9157V16.8537H16.8874V16.9157L16.924 16.9569H18.6124L18.6531 16.9157V16.8537H19.2837L19.3244 16.8125V16.7506H19.9672L19.9957 16.7175V16.6763L19.9672 16.6474H19.3244V16.5855L19.2837 16.5442H18.6531V16.4823L18.6124 16.441H16.9322Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8022 15.6651L16.7615 15.7105V15.7724H16.2733V15.7105L16.2326 15.6651H14.65L14.6053 15.7105V15.7724H13.9747L13.934 15.8136V15.8714H13.2952L13.2627 15.9044V15.9457L13.2952 15.9746H13.934V16.0283L13.9747 16.0654H14.6053V16.1315L14.65 16.1727H16.2326L16.2733 16.1315V16.0654H16.7615V16.1315L16.8022 16.1727H18.3889L18.4295 16.1315V16.0654H19.0601L19.1008 16.0283V15.9622H19.7436L19.7762 15.9333V15.8921L19.7436 15.859H19.1008V15.8013L19.0601 15.76H18.4499V15.6981L18.4092 15.6527L16.8022 15.6651Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6888 14.8932L16.6481 14.9345V14.9964H16.4162V14.9345L16.3755 14.8932H14.8906L14.8499 14.9345V14.9964H14.2193L14.1786 15.0377V15.0996H13.5277L13.4951 15.1326V15.1739L13.5277 15.2028H14.1664V15.2647L14.2071 15.306H14.8377V15.3679L14.8784 15.4092H16.3633L16.404 15.3679V15.306H16.6481V15.3679L16.6888 15.4092H18.1738L18.2144 15.3679V15.306H18.8573L18.8979 15.2647V15.2028H19.5407L19.5692 15.1739V15.1326L19.5407 15.0996H18.8979V15.0377L18.8573 14.9964H18.2266V14.9345L18.186 14.8932H16.6888Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.102 14.1173L15.0654 14.1586V14.2205H14.4307L14.39 14.2659V14.3278H13.7513L13.7188 14.3567V14.398L13.7513 14.4269H14.3819V14.4888L14.4226 14.5301H15.0572V14.5961L15.0939 14.6333H17.9417L17.9784 14.5961V14.5301H18.6089L18.6496 14.4888V14.4269H19.2924L19.3209 14.398V14.3774L19.2924 14.3485H18.6496V14.2659L18.6089 14.2205H17.9784V14.1586L17.9417 14.1173H15.102Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1137 13.3455L15.0771 13.3868V13.4487H14.4424L14.4018 13.49V13.5519H13.763L13.7305 13.5849V13.6262L13.763 13.6551H14.3814V13.717L14.4221 13.7583H15.0568V13.8202L15.0934 13.8614H17.9412L17.9779 13.8202V13.7583H18.6085L18.6491 13.717V13.6551H19.292L19.3204 13.6262V13.5849L19.3042 13.5519H18.6614V13.49L18.6207 13.4487H17.9901V13.3868L17.9535 13.3455H15.1137Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6766 12.5696L16.6359 12.615V12.6728H16.4162V12.615L16.3755 12.5696H14.8906L14.8499 12.615V12.6728H14.2193L14.1786 12.7182V12.7759H13.5277L13.4951 12.809V12.8502L13.5277 12.8833H14.1664V12.9452L14.2071 12.9823H14.8377V13.0442L14.8784 13.0896H16.3633L16.404 13.0442V12.9823H16.6481V13.0442L16.6888 13.0896H18.1738L18.2144 13.0442V12.9823H18.8573L18.8979 12.9452V12.8833H19.5407L19.5692 12.8502V12.809L19.5407 12.7759H18.8979V12.7264L18.8573 12.681H18.2266V12.615L18.186 12.5696H16.6766Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8022 11.7978L16.7615 11.839V11.9009H16.2733V11.839L16.2326 11.7978H14.65L14.6053 11.839V11.9009H13.9747L13.934 11.9422V12.0041H13.2952L13.2627 12.033V12.0743L13.2952 12.1073H13.934V12.1692L13.9747 12.2105H14.6053V12.2724L14.65 12.3137H16.2326L16.2733 12.2724V12.2105H16.7615V12.2724L16.8022 12.3137H18.3889L18.4295 12.2724V12.2105H19.0601L19.1008 12.1692V12.1073H19.7436L19.7762 12.0743V12.033L19.7436 12.0041H19.1008V11.9422L19.0601 11.9009H18.4499V11.839L18.4092 11.7978H16.8022Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9322 11.0259L16.8956 11.0672V11.125H16.1592V11.0755L16.1226 11.0342H14.4301L14.3813 11.0755V11.1333H13.7507L13.71 11.1787V11.2406H13.0794L13.0469 11.2695V11.3107L13.0794 11.3396H13.7182V11.4057L13.7588 11.4469H14.3813V11.4882L14.422 11.5253H16.1144L16.1511 11.4882V11.4387H16.8874V11.4882L16.924 11.5253H18.6124L18.6531 11.4882V11.4387H19.2837L19.3244 11.3974V11.3314H19.9672L19.9957 11.3025V11.2612L19.9672 11.2323H19.3244V11.1704L19.2837 11.125H18.6531V11.0755L18.6124 11.0342L16.9322 11.0259Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0547 10.25L17.014 10.2913V10.3532H16.0376V10.2913L16.0091 10.25H14.219L14.1783 10.2913V10.3532H13.5477L13.5071 10.3945V10.4564H12.8683L12.8398 10.4894V10.5307L12.8683 10.5596H13.5071V10.6215L13.5477 10.6627H14.1783V10.7246L14.219 10.7659H16.0091L16.0498 10.7246V10.6627H17.0262V10.7246L17.0669 10.7659H18.857L18.8977 10.7246V10.6627H19.5283L19.569 10.6215V10.5596H20.2118L20.2402 10.5307V10.4894L20.1996 10.4564H19.5567V10.3945L19.5161 10.3532H18.8855V10.2913L18.857 10.25H17.0547Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.75"
          y="0.25"
          width="31.5"
          height="31.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default XLogo;
