import { basePageViewData } from '../utils';

const baseCheckoutStepChange = ({ trackPageView, getContext }) => {
  const { TRACK_CHECKOUT_STEP } = require('../../actions/checkout');

  const checkoutStepChange = trackPageView((action, _, nextState) => {
    const { cookies } = getContext();
    const queryString = window.location.search;
    const gateway = Boolean(nextState.session.dmGatewayCode);
    const referringPageModule = new URLSearchParams(queryString).get('nav');
    const customerBucketGroup = (nextState.customer.id % 20) + 1;
    const pageName = action.stepName;
    return {
      name: pageName,
      properties: {
        ...basePageViewData(nextState, { cookies }),
        customer_bucket_group: nextState.customer.id
          ? customerBucketGroup.toString()
          : '',
        dmg_code: gateway ? nextState.session.dmGatewayCode : undefined,
        gateway,
        page_name: pageName,
        referring_page_module: referringPageModule || '',
      },
    };
  });
  return {
    [TRACK_CHECKOUT_STEP]: checkoutStepChange,
  };
};

export default baseCheckoutStepChange;
