import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const ParagraphWrapper = styled.p`
  line-height: 1.5;
  font-weight: ${props => (props.light ? '300' : '500')};
  font-size: ${props =>
    typeof props.size === 'number' ? `${props.size}px` : props.size};
  color: ${props =>
    props.white ? props.theme.colors.white : props.theme.colors.default};
  text-align: ${props => props.align};
  ${props => props.theme.paragraph && props.theme.paragraph.defaultStyle};
`;

export const Paragraph = ({
  children,
  size = 'inherit',
  align = 'inherit',
  ...props
}) => (
  <ParagraphWrapper size={size} align={align} {...props}>
    {children}
  </ParagraphWrapper>
);

Paragraph.propTypes = {
  /**
   * Content of the paragraph
   */
  children: PropTypes.node.isRequired,
  /**
   * Font size in px, or a CSS value to use.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Enables light text
   */
  light: PropTypes.bool,
  /**
   * Turns text to white
   */
  white: PropTypes.bool,
  /**
   * Sets text alignment.
   */
  align: PropTypes.string,
};
