import React, { useLayoutEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import config from 'config';
import PropTypes from 'prop-types';
import ReactGoogleReCAPTCHA from 'react-google-recaptcha';
import { Transition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

import { useFeature } from '@techstyle/react-features';

const Wrapper = styled.div`
  position: relative;
  /* Since the ReCAPTCHA widget loads dynamically, it starts out with no
     height. Add a minimum here to prevent it from pushing stuff around too
     much. */
  min-width: 304px;
  min-height: 78px;
`;

const progress = keyframes`
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  margin: auto 30px;
  border-radius: 2px;
  background: rgb(106, 160, 218);
  transform: scaleX(0);
  transform-origin: 0% 50%;
  animation: ${progress} 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation-delay: 200ms;
`;

const Placeholder = styled.div.attrs(({ children = <ProgressBar /> }) => ({
  children,
}))`
  /* These styles are designed to look exactly like the real ReCAPTCHA
     container. */
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 74px;
  max-width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background: #f9f9f9;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props => props.transitionDuration}ms;
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  pointer-events: none;

  &[data-transition-state='exited'] {
    opacity: 1;
  }
`;

const Positioned = styled.div`
  position: relative;
`;

function useTimeout(delay) {
  const state = { done: delay === 0 };

  if (!state.done && delay != null && delay !== 0) {
    const timer = setTimeout(() => {
      state.done = true;
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }

  return state.done;
}

/**
 * A separate component for defining `window.recaptchaOptions` if it isn't
 * already set. This `useLayoutEffect` can't be in ReCAPTCHA itself because of
 * React's lifecycle event order: children call their effects before parents,
 * so having the effect there would be too late. The solution is to render this
 * as a sibling component before `react-google-recaptcha`, where it will be run
 * first.
 */
function ReCAPTCHAOptions(props) {
  useLayoutEffect(() => {
    // These are global options, so if something else has already set them, we
    // can't reasonably override them now.
    if (typeof window.recaptchaOptions === 'undefined') {
      window.recaptchaOptions = props;
    }
  }, [props]);

  return null;
}

/**
 * A thin wrapper around [react-google-recaptcha](https://www.npmjs.com/package/react-google-recaptcha)
 * that enhances it in the following ways:
 *
 * - For consistency with prop naming conventions, `sitekey` and `tabindex` have
 *   been renamed to `siteKey` and `tabIndex`, respectively.
 * - The `siteKey` is automatically read from the `public.recaptcha.siteKey`
 *   config property if set.
 * - The `lang` config value of the global `recaptchaOptions` is automatically
 *   set to the site's current locale
 *
 * Any additional props besides `className` and `style` are passed along to
 * `react-google-recaptcha`.
 */
export default function ReCAPTCHA({
  className,
  delay,
  placeholder: inputPlaceholder,
  siteKey = config.get('public.recaptcha.siteKey')
    ? config.get('public.recaptcha.siteKey')
    : undefined,
  style,
  tabIndex,
  transitionDuration,
  locale,
  captchaRef,
  ...rest
}) {
  const ready = useTimeout(delay);
  const { data: captchaData, networkStatus } = useFeature('captcha_key');
  const recaptchaConfig = config.get('public.recaptcha');
  const newSiteKey = recaptchaConfig.newVersionSiteKey
    ? recaptchaConfig.newVersionSiteKey
    : null;
  const shouldUseNewSiteKey =
    captchaData &&
    captchaData.enabled &&
    captchaData.value &&
    networkStatus.isUpToDate &&
    !networkStatus.isLoading;

  if (
    (!shouldUseNewSiteKey && !siteKey) ||
    (shouldUseNewSiteKey && !newSiteKey)
  ) {
    Sentry.captureMessage(
      'Missing siteKey prop. This can be populated automatically by defining the public.recaptcha.siteKey config property.',
      'warning'
    );
    return null;
  }

  return (
    // Add an additional div wrapper, otherwise content after the ReCAPTCHA will
    // be placed alongside content before it, since it initially renders as an
    // inline element.
    <Wrapper className={className} style={style} data-autotag="g-recaptcha">
      <Transition in={ready} timeout={transitionDuration}>
        {state => {
          // Render the placeholder until `ready` is true + the `timeout` prop
          // above. For `timeout` milliseconds, both the placeholder and the
          // real widget will be mounted. This gives the widget time to actually
          // download and render.
          const placeholder =
            state !== 'entered' && inputPlaceholder
              ? React.cloneElement(inputPlaceholder, {
                  'data-recaptcha-placeholder': '',
                  'data-transition-state': state,
                  transitionDuration,
                  key: 'placeholder',
                })
              : null;

          // Delay rendering the actual widget until `ready` is true.
          const widget =
            state !== 'exited' ? (
              <Positioned key="widget">
                <ReCAPTCHAOptions lang={locale} />
                <ReactGoogleReCAPTCHA
                  sitekey={shouldUseNewSiteKey ? newSiteKey : siteKey}
                  tabindex={tabIndex}
                  ref={captchaRef}
                  {...rest}
                />
              </Positioned>
            ) : null;

          return [placeholder, widget];
        }}
      </Transition>
    </Wrapper>
  );
}

ReCAPTCHA.propTypes = {
  ...ReactGoogleReCAPTCHA.propTypes,
  /**
   * The class to apply to the wrapper element.
   */
  className: PropTypes.string,
  /**
   * Number of milliseconds to wait until actually loading the ReCAPTCHA widget.
   * Since it is often not critical to render right away, this can cut down on
   * stuttering caused by all the JavaScript and DOM elements that the widget
   * adds.
   */
  delay: PropTypes.number,
  /**
   * Content to render while the ReCAPTCHA widget is loading. By default itâ€™s an
   * empty box that looks very much like the ReCAPTCHA container.
   */
  placeholder: PropTypes.element,
  /**
   * The public site key value obtained from registering the site with the
   * reCAPTCHA service. By default this will be read from the
   * `public.recaptcha.siteKey` config property if set.
   */
  siteKey: PropTypes.string,
  /**
   * Inline styles to apply to the wrapper element.
   */
  style: PropTypes.object,
  /**
   * See: [react-google-recaptcha](https://www.npmjs.com/package/react-google-recaptcha)
   */
  tabIndex: PropTypes.number,
  /**
   * Once the ReCAPTCHA widget starts to load, this controls the transition
   * duration of the placeholder.
   */
  transitionDuration: PropTypes.number,
  /**
   *Locale
   **/
  locale: PropTypes.string,
};

ReCAPTCHA.defaultProps = {
  delay: 500,
  placeholder: <Placeholder />,
  transitionDuration: 5000,
};

ReCAPTCHA.Placeholder = Placeholder;
