import qs from 'querystring';
import { format } from 'url';

import config from 'config';
import Router from 'next/router';

/**
 * Turn a route object (supplied by `next/router`) or Link props (given to
 * `next/link`) into a route URL.
 */
export function toURL(linkOrRoute) {
  // FIXME Query should have ordered keys, for example { a, b } should match { b, a }
  if (!linkOrRoute) {
    return '';
  }
  if (linkOrRoute.href) {
    return format(linkOrRoute.href);
  }
  return format(linkOrRoute);
}

/**
 * Determine if the given `route` is equal to `activeRoute` by serializing them
 * both into URL strings.
 */
export function areRoutesEqual(route, activeRoute) {
  return toURL(route) === toURL(activeRoute);
}

export function redirect(res, url, as, statusCode = 302, options) {
  if (res) {
    res.redirect(statusCode, as || format(url));
    res.end();
  } else {
    Router.replace(url, as, options);
  }
}

/**
 * Navigate to the homepage. Goodbye!
 */
export function navigateToHomepage() {
  window.location.href = '/';
}

/**
 * remove the junk (queries/params/hash) from the url my dudes
 */

export function urlNoQueryString(url) {
  const regex = /([?#])/g;
  return url.split(regex)[0];
}

/**
 * return parsed pathnames (as array) and query (as object) from URL string
 */

export function getParsedPathnamesAndQuery(pathname) {
  const [baseUrl, , queryString] = pathname.split(/([?#])/g);
  const pathnames = baseUrl.replace(/^\/|\/$/g, '').split('/');
  const queryObj = qs.parse(queryString);

  return {
    pathnames,
    queryObj,
  };
}

export const getSectionsAndQueryFromPath = asPath => {
  const { queryObj, pathnames } = getParsedPathnamesAndQuery(asPath);
  let [root, section, subsection, extraSubsection] = pathnames;
  let parentSection;

  if (section === 'new' || section === 'new-vip') {
    parentSection = section;
    section = subsection;
    subsection = extraSubsection;
  }
  return {
    root,
    parentSection,
    section,
    subsection,
    ...queryObj,
  };
};

/**
 * Convert router path to an underscored url divider instead of slashes
 */

export function getNormalizedUrl(router) {
  let path = '';
  if (router) {
    if (router.asPath !== undefined) {
      path = router.asPath
        .split(/[?#]/)[0]
        .split('/')
        .filter(str => str !== '')
        .join('/')
        .replace(/\//g, '_');
    }
  }
  return path;
}

export function redirectOldSalePages({ asPath, query, res }) {
  const { pathnames } = getParsedPathnamesAndQuery(asPath);
  const [root] = pathnames;
  const isOldSalePage =
    root === 'sale' &&
    (Object.prototype.hasOwnProperty.call(query, 'defaultProductCategoryIds') ||
      Object.prototype.hasOwnProperty.call(query, 'categoryTagIds'));

  if (isOldSalePage) {
    const productBrowser = config.get('public.productBrowser');
    const { defaultProductCategoryIds, categoryTagIds, ...properQuery } = query;
    const saleSections = productBrowser.sections.sale.subsections;
    const idsFromQuery = categoryTagIds || defaultProductCategoryIds;

    const section = Object.keys(saleSections).find(key => {
      const idsForSection =
        saleSections[key].categoryTagIds ||
        saleSections[key].defaultProductCategoryIds;
      if (idsForSection && idsForSection.join(' ') === idsFromQuery) {
        return key;
      }
    });

    const redirectHref = {
      pathname: '/products',
      query: properQuery,
    };
    const redirectAs = format({
      pathname: section ? `/sale/${section}` : '/sale',
      query: properQuery,
    });

    return redirect(res, redirectHref, redirectAs, 301, {
      shallow: true,
    });
  }
}

export const assetContainerRewriteMap = {
  products_image_banner: container => {
    return container.assets
      .map(asset => {
        const { pageUrl, fpl: fplId } = asset.options.customVars;
        if (pageUrl && fplId) {
          // Normalize `pageUrl`: get only `pathname`, with leading `/`.
          const canonicalPath = new URL(pageUrl, 'https://example.tld')
            .pathname;
          // Only `/features` pages are supported.
          if (canonicalPath.startsWith('/features/')) {
            return {
              canonicalPath,
              routeType: 'PRODUCT_LISTING',
              isAlias: false,
              isRedirect: false,
              productJson: {
                backgroundFplIds: [fplId],
                aggregations: [
                  { field: 'size_bra', size: 50 },
                  { field: 'size_bralette', size: 50 },
                  { field: 'size_undie', size: 50 },
                  { field: 'size_lingerie', size: 50 },
                  { field: 'color', size: 50 },
                  { field: 'fabric', size: 50 },
                ],
              },
              routeParamsJson: {},
            };
          }
        }
      })
      .filter(Boolean);
  },
};
