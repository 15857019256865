import { getSession } from '@techstyle/redux-core';

const pdpVideoPlaybackCompletedEvents = ({ getContext, trackEvent }) => {
  const {
    TRACK_PDP_VIDEO_PLAYBACK_COMPLETED,
  } = require('../../actions/products');
  const pdpVideoPlaybackCompleted = trackEvent(
    (action, prevState, nextState) => {
      const { cookies } = getContext();
      return {
        name: 'Video Playback Completed',
        properties: {
          feature: 'react',
          category: 'pdp',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: getSession(nextState).isLoggedIn,
          automated_test: Boolean(cookies.get('automated_test')),
          ...action.payload,
        },
      };
    }
  );

  return { [TRACK_PDP_VIDEO_PLAYBACK_COMPLETED]: pdpVideoPlaybackCompleted };
};

export default pdpVideoPlaybackCompletedEvents;
