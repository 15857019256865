import { handleActions } from 'redux-actions';

import { LOAD_REFERRER_SUCCESS } from '../actions/referrer';

const defaultState = {
  referrerOptions: [],
};
export default handleActions(
  {
    [LOAD_REFERRER_SUCCESS]: (state, action) => ({
      ...state,
      referrerOptions: action.payload,
    }),
  },
  defaultState
);
