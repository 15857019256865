import { getPartialTrackingData } from '../utils';

const trackProductListFilterEvents = ({ getContext, trackEvent }) => {
  const { TRACK_PRODUCT_LIST_FILTER } = require('../../actions/products');

  const trackProductListFilter = trackEvent((action, prevState, nextState) => {
    const context = getContext();
    const trackingData = getPartialTrackingData(nextState, context, [
      'feature',
      'session_id',
      'store_group_id',
      'loggedin_status',
      'automated_test',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
      'membership_credits',
    ]);

    return {
      name: 'Product List Filtered',
      properties: {
        ...trackingData,
        ...action.payload,
      },
    };
  });

  return { [TRACK_PRODUCT_LIST_FILTER]: trackProductListFilter };
};

export default trackProductListFilterEvents;
