import { handleActions } from 'redux-actions';

import {
  LOAD_REVIEWS_SUCCESS,
  LOAD_SET_REVIEWS_SUCCESS,
} from '../actions/reviews';
import { ReviewSortCategories } from '../constants/reviews';

const defaultState = {
  groupCode: null,
  selectedProductSize: null,
  reviewSortCategory: ReviewSortCategories.HIGHEST_RATED,
  byMasterProductId: {},
  // other values - the object of the master_product_id
  // ex: master_product_id: { counts: { reviewCount: 5, ... }, reviews: [], summary: [] }
};

const normalizedReviewResponse = (state = defaultState, action) => {
  const {
    fetchedCount,
    fromCache,
    groupCode,
    page,
    masterProductId,
    selectedProductSize,
    reviewSortCategory,
  } = action.meta;

  if (fromCache) {
    return state;
  }

  const isExistingProduct =
    (groupCode && state.groupCode === groupCode) ||
    (masterProductId && state.masterProductId === masterProductId);
  const existingReviews =
    isExistingProduct &&
    state.selectedProductSize === selectedProductSize &&
    state.reviewSortCategory === reviewSortCategory &&
    state.page < page
      ? state.reviews
      : [];

  return {
    groupCode,
    masterProductId,
    selectedProductSize,
    reviewSortCategory,
    summary: action.payload.summary,
    counts: action.payload.counts,
    sizes: action.payload.sizes,
    fits: action.payload.fits,
    reviews: [...existingReviews, ...action.payload.reviews],
    fetchedCount,
    page,
  };
};

export default handleActions(
  {
    [LOAD_REVIEWS_SUCCESS]: (state, action) => {
      return normalizedReviewResponse(state, action);
    },
    [LOAD_SET_REVIEWS_SUCCESS]: (state, action) => {
      return {
        ...state,
        byMasterProductId: {
          ...state.byMasterProductId,
          [action.meta.masterProductId]: normalizedReviewResponse(
            state,
            action
          ),
        },
      };
    },
  },
  defaultState
);
