import React, { useEffect } from 'react';

import config from 'config';

import { Script } from '@techstyle/react-marketing';
import { useDomain } from '@techstyle/redux-core';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import useGenesysWebChatAction from '../../utils/useGenesysWebChatAction';

const cognigyScript = config.get('public.cognigyWebChat.script');
const cognigyRegionConfigs = config.get('public.cognigyWebChat.regionConfigs');

export const CognigyScript = function () {
  const { 'cognigy-widget': cognigyWidget } = useLDFlags();

  const { hideGenesys } = useGenesysWebChatAction();

  const { tld } = useDomain();

  useEffect(() => {
    if (cognigyWidget) {
      hideGenesys();
    }
  }, [cognigyWidget, hideGenesys]);
  const config = cognigyRegionConfigs[tld];

  // Window Event Listener needs to be removed incase one already exists
  return (
    <>
      <Script
        src={cognigyScript}
        async
        globalScriptKey="cognigy"
        skipServerRender
        charset="utf-8"
      />
      <Script
        async
        globalScriptKey="cognigy-init"
        skipServerRender
        charset="utf-8"
        dangerouslySetInnerHTML={{
          __html: `
          window.removeEventListener("cognigy-open",function() {});
          window.addEventListener("cognigy-open", function(event) {
                initWebchat(
                    "https://endpoint-app-us.cognigy.ai/${config.key}",
                    {
                    settings: {
                        colors:{
                          primaryColor: "rgb(55, 14, 178);"
                        },
                        startBehavior: {
                        startBehavior: "injection",
                        getStartedText: "Get Started",
                        getStartedData: {
                            name: event.detail?.customerName,
                            customerId: event.detail?.customerId,
                            buCode: "SX",
                            cogRegion: "${config.region}",
                        },
                        },
                    },
                    }
                );
          });
      `,
        }}
      />
    </>
  );
};
