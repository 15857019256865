import { handleActions } from 'redux-actions';

import {
  outOfDateStatus,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '@techstyle/redux-core';

import {
  LOAD_ACCOUNT_PAYMENT_METHODS_SUCCESS,
  ADD_ACCOUNT_PAYMENT_METHOD_SUCCESS,
  LOAD_ACCOUNT_PAYMENT_METHODS_REQUEST,
  LOAD_ACCOUNT_PAYMENT_METHODS_FAILURE,
} from '../../actions/account';

export const defaultState = {
  paymentMethods: [],
  networkStatus: outOfDateStatus(),
};

export default handleActions(
  {
    [LOAD_ACCOUNT_PAYMENT_METHODS_REQUEST]: startLoading,
    [LOAD_ACCOUNT_PAYMENT_METHODS_SUCCESS]: (state, action) => {
      return {
        paymentMethods: action.payload,
        networkStatus: upToDateStatus(),
      };
    },
    [LOAD_ACCOUNT_PAYMENT_METHODS_FAILURE]: stopLoading,
    [ADD_ACCOUNT_PAYMENT_METHOD_SUCCESS]: (state, action) => {
      // Beware: "adding" can also update an existing payment method.
      let paymentFound = false;
      const payments = state.paymentMethods.map(payment => {
        if (payment.creditCardId === action.payload.creditCardId) {
          paymentFound = true;
          return action.payload;
        }
        // Reset others to non-defaults if necessary.
        return action.payload.isDefault && payment.isDefault
          ? { ...payment, isDefault: false }
          : payment;
      });
      if (!paymentFound) {
        payments.push(action.payload);
      }
      return {
        paymentMethods: payments,
        networkStatus: upToDateStatus(),
      };
    },
  },
  defaultState
);
