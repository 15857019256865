import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaPinterest,
} from 'react-icons/fa';
import scrollIntoView from 'scroll-into-view-if-needed';
import styled, { css } from 'styled-components';

import {
  Collapsible,
  TextLink,
  useCollapsibleState,
  useTheme,
} from '@techstyle/react-components';
import { Flyout, Mobile } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';
import { NavContainer } from '@techstyle/react-navigation';

import GenesysWebChatScript from '../../components/GenesysWebChatScript';
import { mobile } from '../../styles';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { useNavFilter } from '../../utils/navigation';
import { v1, v2 } from '../../utils/themeVersioning';
import { getNavTrackingUrl } from '../../utils/url';
import { Component as ADAWidgetProvider } from '../ADAWidgetProvider';
import { CognigyScript } from '../CognigyScript/CognigyScript';
import { Component as ModalNewsletter } from '../ModalNewsletter';
import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';
import { Component as StaticIcon } from '../StaticIcon';

import { FooterProvider, useFooterContext } from './FooterProvider';

const Wrapper = styled.footer`
  position: relative;
  z-index: 3;
  ${v1`
      background-color: ${props => props.theme.colors.default};
      color: ${props => props.theme.colors.white};
    `}

  ${v2`
      background-color: ${props => props.theme.colors.marble};
      `}
`;

// Collapsible Styles
const CollapsibleInformation = styled(Collapsible)``;

const CollapsibleContent = styled(Collapsible.Content)`
  border-bottom: 1px solid ${props => props.theme.colors.gray250};
  &[data-transition-state='exited'] {
    visibility: visible;
  }

  ${props =>
    !props.isOpen &&
    css`
      padding: 0;
    `}
`;

const CollapsibleButton = styled(Collapsible.Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 ${props => props.theme.spacing.medium}px;
  border-bottom: none;

  &:focus {
    outline: none;
  }
`;

const CollapsibleTitle = styled.span`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  font-size: 16px;
  font-weight: 500;
  line-height: 3.75;
  text-align: left;
`;

const CollapsibleOptionsWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.theme.spacing.tiny}px 0px;
`;

const ListItem = styled.li`
  margin-bottom: ${props => props.theme.spacing.small}px;
  padding-bottom: 4px;
`;

const CollapsibleLink = styled(TextLink)`
  margin-left: ${props => props.theme.spacing.medium}px;
  text-align: left;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
`;

// Bottom Styles
const BottomWrapper = styled.div`
  ${v1`
    padding: ${props => props.theme.spacing.moderate}px 20px;
  `}

  ${mobile`
    padding: ${props => props.theme.spacing.medium}px ${props =>
    props.theme.spacing.medium}px ${props => props.theme.spacing.large}px;

    ${v2`
      padding: 0;
    `}
  `};
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FooterMiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center;

  ${mobile`
      flex-direction: column;
      border-bottom: 1px solid ${props => props.theme.colors.grey};
    `}
`;

const BottomOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  ${v1`
      align-items: baseline;
      padding-top: 4px; /* Align baseline of text with baseline of icons */
      margin-bottom: ${props => props.theme.spacing.moderate}px;
    `}

  ${v2`
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.colors.grey};
      padding: 20px 40px;
      width: 100%;
      justify-content: flex-start;
    `}

  > * + * {
    margin-left: ${props => props.theme.spacing.moderate}px;
  }

  ${mobile`
    ${v1`
      display: none;
    `}

    ${v2`
      justify-content: center;
      width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      `}

  `};
`;

// The `desktopOnly` prop should not appear in the DOM
// FIXME: Use prop filtering featured of styled-components v5 when upgrading.
const FilteredTextLink = React.forwardRef(({ desktopOnly, ...props }, ref) => (
  <TextLink ref={ref} {...props} />
));

FilteredTextLink.displayName = 'FilteredTextLink';

const BottomLink = styled(FilteredTextLink)`
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-out;

  ${v1`
      font-size: 14px;
      color: ${props => props.theme.colors.white};
    `}

  ${v2`
        ${props => props.theme.links.variants.navigationPrimary}
      `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.white};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }

  ${mobile`
      margin: 0;
      ${props => (props.desktopOnly ? 'display: none;' : '')}

      ${v2`
        margin-bottom: 18px;
      `}
  `};
`;

// TermsWrapper Links
const TermsLinks = styled(BottomLink)`
  margin-left: ${props => props.theme.spacing.moderate}px;
  ${v2`
      color: ${props => props.theme.colors.grey};
       ${mobile`
        margin-left: 0px;
      `}
    `}
`;

// International Links Flyout
const Popup = styled.div`
  padding: ${props => props.theme.spacing.tiny}px;
  width: 140px;

  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.default};
  color: ${props => props.theme.colors.default};
  line-height: 1;
  text-align: center;
`;

const InternationalLink = styled.a`
  ${v1`
    font-size: 12px;
  line-height: 1.5;
  color: ${props =>
    props.isActive ? props.theme.colors.active : props.theme.colors.default};
  `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
    `}
`;

// Icon Styles
const IconWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;

  padding: 0;
  ${v1`
      margin-bottom: ${props => props.theme.spacing.moderate}px;
    `}

  ${mobile`
    flex: 0 0 100%;
    justify-content: center;

    ${v2`
      margin-left: 0px;
      min-width: 190px;
      justify-content: space-between;
    `}
  `};
`;

const IconLink = styled.a`
  cursor: pointer;
  margin: 0 0 0 15px;

  ${v1`
      color: ${props => props.theme.colors.white};

    `}

  ${v2`
        color: ${props => props.theme.colors.black};
        height: 24px;
        width: 24px;

        > svg {
        color: ${props => props.theme.colors.black};
        height: 24px;
        width: 24px;
      }

      ${mobile`
        margin: 0;
      `}
      `}
`;

const PrivacyIconLink = styled(IconLink)`
  margin-left: ${props => props.theme.spacing.tiny}px;
  padding-top: 3px;
  ${mobile`
      ${props => (props.desktopOnly ? 'display: none;' : null)}
  `};
`;

const PrivacyLinkWrapper = styled.div`
  display: flex;
`;

// Shipping to Borderfree Flag & Visit our EU/UK sites Wrapper
const ShippingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  ${v1`
      margin-bottom: ${props => props.theme.spacing.moderate}px;
    `}

  ${mobile`
    flex: 0 0 100%;
    justify-content: center;
  `};
`;

// (c), Terms of Service, Privacy Policy Wrapper
const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: auto;

  ${v1`
    justify-content: flex-end;
    margin-bottom: ${props => props.theme.spacing.moderate}px;
    `}
  ${v2`
    justify-content: flex-start;
    margin-bottom: 32px;
    margin-left: 0;
    padding-left: 16px;
    `}

  ${mobile`
    justify-content: center;
    ${v1`
      flex: 0 0 100%;
    `}
    ${v2`
      padding: 20px 16px 0;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      margin-left: 0;
    `}
  `};
`;

const BackIcon = styled(StaticIcon).attrs({
  type: 'caret',
  size: 10,
  color: 'white',
})``;

const UpBackIcon = styled(BackIcon)`
  transform: rotate(180deg);
`;

const emptyNav = {
  navItems: [],
  navSettings: {},
};

export const NavContainers = ({ children, isMobile, navFilter }) => {
  return (
    <NavContainer handle="site_footer" filter={navFilter}>
      {({ data: primaryNav }) => (
        <NavContainer handle="site_footer_secondary" filter={navFilter}>
          {({ data: secondaryNav }) => {
            return children({
              primaryNav: primaryNav || emptyNav,
              secondaryNav: secondaryNav || emptyNav,
            });
          }}
        </NavContainer>
      )}
    </NavContainer>
  );
};

function ToggleButton() {
  const { isOpen } = useCollapsibleState();

  return (
    <CollapsibleButton isOpen={isOpen}>
      <CollapsibleTitle>
        <FormattedMessage
          defaultMessage="Information"
          id="site_footer.information"
        />
      </CollapsibleTitle>
      {isOpen ? <UpBackIcon /> : <BackIcon />}
    </CollapsibleButton>
  );
}

export function FooterSocialIcons({ className, isNewTheme }) {
  return (
    <IconWrapper className={className}>
      <IconLink href="https://www.instagram.com/savagexfenty/" target="_blank">
        {!isNewTheme ? (
          <FaInstagram size={20} title="Instagram" />
        ) : (
          <NewThemeIcon type="instagram" />
        )}
      </IconLink>
      <IconLink href="https://twitter.com/savagexfenty" target="_blank">
        {!isNewTheme ? (
          <FaTwitter size={20} title="Twitter" />
        ) : (
          <NewThemeIcon type="twitter" />
        )}
      </IconLink>
      <IconLink href="https://www.facebook.com/SavageXFenty/" target="_blank">
        {!isNewTheme ? (
          <FaFacebook size={20} title="Facebook" />
        ) : (
          <NewThemeIcon type="facebook" />
        )}
      </IconLink>
      <IconLink href="https://www.pinterest.com/savagexfenty/" target="_blank">
        {!isNewTheme ? (
          <FaPinterest size={20} title="Pinterest" />
        ) : (
          <NewThemeIcon type="pinterest" />
        )}
      </IconLink>
      <IconLink href="https://www.youtube.com/savagexfenty" target="_blank">
        {!isNewTheme ? (
          <FaYoutube size={20} title="YouTube" />
        ) : (
          <NewThemeIcon type="youtube" />
        )}
      </IconLink>
      <IconLink href="https://www.tiktok.com/@savagexfenty" target="_blank">
        {!isNewTheme ? (
          <img
            alt="TikTok"
            src="/static/images/tiktok.svg"
            width="20"
            height="20"
          />
        ) : (
          <NewThemeIcon type="tiktok" />
        )}
      </IconLink>
    </IconWrapper>
  );
}

export function ShippingFlyout({ isInternational, tld }) {
  return (
    <ShippingWrapper>
      <Flyout
        direction="top"
        toggleButton={
          <BottomLink tagName="button">
            <FormattedMessage
              defaultMessage="Visit Our Other Sites"
              id="site_footer.international_visit_our_sites"
            />
          </BottomLink>
        }
      >
        <Popup>
          <ul>
            <li>
              <InternationalLink
                href="https://savagex.com"
                isActive={tld === '.com'}
              >
                <FormattedMessage
                  defaultMessage="United States"
                  id="site_footer.united_states"
                />
              </InternationalLink>
            </li>
            <li>
              <InternationalLink
                href="https://savagex.co.uk"
                isActive={tld === '.co.uk'}
              >
                <FormattedMessage
                  defaultMessage="United Kingdom"
                  id="site_footer.united_kingdom"
                />
              </InternationalLink>
            </li>
            <li>
              <InternationalLink
                href="https://savagex.de"
                isActive={tld === '.de'}
              >
                <FormattedMessage
                  defaultMessage="Germany"
                  id="site_footer.germany"
                />
              </InternationalLink>
            </li>
            <li>
              <InternationalLink
                href="https://savagex.fr"
                isActive={tld === '.fr'}
              >
                <FormattedMessage
                  defaultMessage="France"
                  id="site_footer.france"
                />
              </InternationalLink>
            </li>
            <li>
              <InternationalLink
                href="https://savagex.es"
                isActive={tld === '.es'}
              >
                <FormattedMessage
                  defaultMessage="Spain"
                  id="site_footer.spain"
                />
              </InternationalLink>
            </li>
            <li>
              <InternationalLink
                href="https://savagex.eu"
                isActive={tld === '.eu'}
              >
                <FormattedMessage
                  defaultMessage="Europe"
                  id="site_footer.europe"
                />
              </InternationalLink>
            </li>
          </ul>
        </Popup>
      </Flyout>
    </ShippingWrapper>
  );
}

function Footer({ className, intl, isInternational, tld, trackNavItemClick }) {
  const navFilterDesktop = useNavFilter({ isMobile: false });
  const navFilterMobile = useNavFilter({ isMobile: true });
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
  const [isADAWidgetLoading, setIsADAWidgetLoading] = useState(true);
  const adaWidgetButton = useContext(ADAWidgetProvider.Context);
  const footerContentRef = useRef();
  const footerContext = useFooterContext();
  const theme = useTheme();
  const isNewTheme = theme.themeVersion === 'v2';

  const { 'genesys-web-chat': genesysWebChat } = useLDFlags();

  const onOpen = useCallback(() => {
    const node = footerContentRef.current;
    if (node) {
      scrollIntoView(node, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'end',
      });
    }
  }, [footerContentRef]);

  const handleOpenNewsletter = useCallback(event => {
    if (event) {
      event.preventDefault();
    }
    setIsNewsletterOpen(true);
  }, []);

  const handleCloseNewsletter = useCallback(() => {
    setIsNewsletterOpen(false);
  }, []);

  const handleADAClick = () => {
    if (adaWidgetButton) {
      adaWidgetButton.click();
    }
  };

  const openOneTrustModal = event => {
    if (event) {
      event.preventDefault();
    }

    if (
      typeof window !== 'undefined' &&
      window.OneTrust &&
      window.OneTrust.ToggleInfoDisplay
    ) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  useEffect(() => {
    if (adaWidgetButton) {
      setIsADAWidgetLoading(false);
    }
  }, [adaWidgetButton]);

  const handleSendGaEvent = (gaAction, navItem) => () => {
    if (!navItem.gaLabel) {
      return;
    }
    trackNavItemClick(gaAction, navItem.gaLabel);
  };

  const renderLinkMainDesktop = (navItem, navSettings) => {
    const sendGaEvent = handleSendGaEvent(navSettings.gaAction, navItem);

    if (navItem.itemKey === '_footerOpenNewsletter') {
      const handleNewsletterClick = event => {
        event.preventDefault();
        sendGaEvent();
        handleOpenNewsletter();
      };
      return (
        <BottomLink key={navItem.id} onClick={handleNewsletterClick}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </BottomLink>
      );
    }

    if (navItem.itemKey === '_footerOpenCookieSettings') {
      return (
        <BottomLink key={navItem.id} onClick={openOneTrustModal}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </BottomLink>
      );
    }

    const navItemLink = getNavTrackingUrl(navItem);

    return (
      <Link key={navItem.id} href={navItemLink} passHref>
        <BottomLink onClick={sendGaEvent}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </BottomLink>
      </Link>
    );
  };

  const renderLinkSecondary = (navItem, navSettings) => {
    const sendGaEvent = handleSendGaEvent(navSettings.gaAction, navItem);
    let desktopOnly = !isNewTheme;
    const navItemLink = getNavTrackingUrl(navItem);

    if (navItem.itemKey === '_footerEnableAccessbility') {
      const navItemLabel = navItem.isResourceBundle
        ? intl.formatMessage({
            id: navItem.label,
            defaultMessage: navItem.label,
          })
        : navItem.label;

      const handleClick = () => {
        handleADAClick();
        sendGaEvent();
      };

      return (
        <TermsLinks
          key={navItem.id}
          tagName="button"
          type="button"
          onClick={handleClick}
        >
          {isADAWidgetLoading ? (
            <FormattedMessage
              id="site_footer.loading_widget_text"
              defaultMessage="Loading Widget..."
            />
          ) : (
            navItemLabel
          )}
        </TermsLinks>
      );
    }

    if (navItem.itemKey === '_footerShowCpra') {
      return (
        <React.Fragment key={navItem.id}>
          <Link href={navItemLink} passHref>
            <TermsLinks desktopOnly={desktopOnly} onClick={sendGaEvent}>
              {navItem.isResourceBundle
                ? intl.formatMessage({
                    id: navItem.label,
                    defaultMessage: navItem.label,
                  })
                : navItem.label}
            </TermsLinks>
          </Link>
          <PrivacyIconLink desktopOnly={desktopOnly} href="/cpra">
            <img
              alt="CcpaOptOutIcon"
              src="/static/images/privacyoptions.png"
              height="16"
            />
          </PrivacyIconLink>
        </React.Fragment>
      );
    }

    if (navItem.itemKey === '_footerCopyright') {
      desktopOnly = false;
    }

    return (
      <Link key={navItem.id} href={navItemLink} passHref>
        <TermsLinks desktopOnly={desktopOnly} onClick={sendGaEvent}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </TermsLinks>
      </Link>
    );
  };

  const renderLinkCollapsible = (navItem, navSettings) => {
    const sendGaEvent = handleSendGaEvent(navSettings.gaAction, navItem);

    if (navItem.itemKey === '_footerShowCpra') {
      return (
        <PrivacyLinkWrapper>
          <ListItem key={navItem.id}>
            <Link href={navItem.url} passHref>
              <CollapsibleLink onClick={sendGaEvent}>
                {navItem.isResourceBundle
                  ? intl.formatMessage({
                      id: navItem.label,
                      defaultMessage: navItem.label,
                    })
                  : navItem.label}
              </CollapsibleLink>
            </Link>
          </ListItem>
          <PrivacyIconLink href="/cpra">
            <img
              alt="CcpaOptOutIcon"
              src="/static/images/privacyoptions.png"
              height="16"
            />
          </PrivacyIconLink>
        </PrivacyLinkWrapper>
      );
    }

    if (navItem.itemKey === '_footerCopyright') {
      return null;
    }

    if (navItem.itemKey === '_footerOpenCookieSettings') {
      return (
        <ListItem key={navItem.id}>
          <Link href={navItem.url} passHref>
            <CollapsibleLink onClick={openOneTrustModal}>
              {navItem.isResourceBundle
                ? intl.formatMessage({
                    id: navItem.label,
                    defaultMessage: navItem.label,
                  })
                : navItem.label}
            </CollapsibleLink>
          </Link>
        </ListItem>
      );
    }

    if (navItem.itemKey === '_footerOpenNewsletter') {
      const handleNewsletterClick = event => {
        event.preventDefault();
        sendGaEvent();
        handleOpenNewsletter();
      };

      return (
        <ListItem key={navItem.id}>
          <Link href={navItem.url} passHref>
            <CollapsibleLink onClick={handleNewsletterClick}>
              {navItem.isResourceBundle
                ? intl.formatMessage({
                    id: navItem.label,
                    defaultMessage: navItem.label,
                  })
                : navItem.label}
            </CollapsibleLink>
          </Link>
        </ListItem>
      );
    }

    return (
      <ListItem key={navItem.id}>
        <Link href={navItem.url} passHref>
          <CollapsibleLink onClick={sendGaEvent}>
            {navItem.isResourceBundle
              ? intl.formatMessage({
                  id: navItem.label,
                  defaultMessage: navItem.label,
                })
              : navItem.label}
          </CollapsibleLink>
        </Link>
      </ListItem>
    );
  };

  return (
    <FooterProvider>
      <Wrapper
        className={className}
        ref={footerContext}
        data-qa-automation="footer"
      >
        {/* Mobile nav menus */}
        {!isNewTheme ? (
          <Mobile>
            <CollapsibleInformation
              className="footer"
              onOpen={onOpen}
              tabIndex={0}
              defaultOpen={isNewTheme}
            >
              {!isNewTheme ? <ToggleButton /> : null}
              <CollapsibleContent>
                <CollapsibleOptionsWrapper ref={footerContentRef}>
                  <NavContainers navFilter={navFilterMobile}>
                    {({
                      primaryNav: primaryNavMobile,
                      secondaryNav: secondaryNavMobile,
                    }) => {
                      const primary = primaryNavMobile.navItems.map(navItem =>
                        renderLinkCollapsible(
                          navItem,
                          primaryNavMobile.navSettings
                        )
                      );
                      const secondary = secondaryNavMobile.navItems.map(
                        navItem =>
                          renderLinkCollapsible(
                            navItem,
                            secondaryNavMobile.navSettings
                          )
                      );
                      return [primary, secondary];
                    }}
                  </NavContainers>
                </CollapsibleOptionsWrapper>
              </CollapsibleContent>
            </CollapsibleInformation>
          </Mobile>
        ) : null}
        {/* Desktop nav menus */}
        <NavContainers navFilter={navFilterDesktop}>
          {({ primaryNav, secondaryNav }) => (
            <>
              <BottomWrapper>
                <FooterRow>
                  <BottomOptionsWrapper>
                    {primaryNav.navItems.map(navItem =>
                      renderLinkMainDesktop(navItem, primaryNav.navSettings)
                    )}
                  </BottomOptionsWrapper>

                  {!isNewTheme ? <FooterSocialIcons /> : null}
                </FooterRow>
                {isNewTheme ? (
                  <FooterMiddleRow>
                    <ShippingFlyout
                      tld={tld}
                      isInternational={isInternational}
                    />
                    <FooterSocialIcons isNewTheme={isNewTheme} />
                  </FooterMiddleRow>
                ) : null}

                <FooterRow>
                  {!isNewTheme ? (
                    <ShippingFlyout
                      tld={tld}
                      isInternational={isInternational}
                    />
                  ) : null}

                  <TermsWrapper>
                    {secondaryNav.navItems.map(navItem =>
                      renderLinkSecondary(navItem, secondaryNav.navSettings)
                    )}
                  </TermsWrapper>
                </FooterRow>
              </BottomWrapper>
            </>
          )}
        </NavContainers>

        <ModalNewsletter
          isOpen={isNewsletterOpen}
          onRequestOpen={handleOpenNewsletter}
          onRequestClose={handleCloseNewsletter}
        />
        {genesysWebChat ? <GenesysWebChatScript /> : null}
        <CognigyScript />
      </Wrapper>
    </FooterProvider>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  isInternational: PropTypes.bool.isRequired,
  tld: PropTypes.string,
  trackNavItemClick: PropTypes.func,
};

Footer.Wrapper = Wrapper;

export default Footer;
