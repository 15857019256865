import React, { useCallback } from 'react';

import { useRouter } from 'next/router';

import { useAsset } from '@techstyle/react-assets';

import { toStringSet } from '../../utils/assets';
import { Component as MessageCarousel } from '../MessageCarousel';

function SkinnyBanner({ memberJoinDate, initialized, persona }) {
  const now = Date.now();
  const router = useRouter();

  const filter = useCallback(
    parentFilter => {
      return (asset, index, assets) => {
        if (!initialized) {
          return false;
        }

        if (!parentFilter(asset, index, assets)) {
          return false;
        }

        if (
          toStringSet(asset.options.customVars.flags, true).has('pdp') !==
          (router.pathname === '/product')
        ) {
          return false;
        }

        // If the asset is the post-quiz banner, only accept it if we're in
        // the post-quiz state and not already on the post-quiz page.
        if (asset.options.targetUrl === '/quiz-complete') {
          if (!persona) {
            return false;
          }
          // Don't show quiz banner if already on quiz page.
          if (router.pathname === '/quiz-complete') {
            return false;
          }
          const oneWeek = 7 * 24 * 60 * 60 * 1000;
          const expiryDate = memberJoinDate.getTime() + oneWeek;
          return now < expiryDate;
        }
        return true;
      };
    },
    [initialized, memberJoinDate, now, persona, router]
  );

  const assetContainerSkinnyBanner = useAsset('skinny_banner', {
    multi: true,
    filter: filter,
  });
  const { data } = assetContainerSkinnyBanner;
  const assetsSkinnyBanner = data ? data.assets : [];

  return <MessageCarousel assets={assetsSkinnyBanner} />;
}

export default SkinnyBanner;
