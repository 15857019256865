/**
 * styled-components mixins for helping write styles.
 */
import { css } from 'styled-components';

/**
 * Media query template that renders only on mobile sizes.
 * Use like:
 *   ${mobile`color: black;`}
 */
export function mobile(...args) {
  return css`
    ${props => {
      if (props.theme.context.desktop) {
        return '';
      }
      if (props.theme.context.mobile) {
        return css(...args);
      }
      return css`
        @media (max-width: ${props =>
            props.theme.breakpoints[props.theme.desktopBreakpoint] - 1}px) {
          ${css(...args)};
        }
      `;
    }};
  `;
}

/**
 * Media query template that renders only on desktop sizes.
 * Use like:
 *   ${desktop`color: black;`}
 */
export function desktop(...args) {
  return css`
    ${props => {
      if (props.theme.context.mobile) {
        return '';
      }
      if (props.theme.context.desktop) {
        return css(...args);
      }
      return css`
        @media (min-width: ${props =>
            props.theme.breakpoints[props.theme.desktopBreakpoint]}px) {
          ${css(...args)};
        }
      `;
    }};
  `;
}

/**
 * Media query template that renders only on Mobile Safari 13 and 14
 * Reference: https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
 * Use like:
 *   ${oldMobileSafari`color: black;`}
 */
export function oldMobileSafari(...args) {
  return css`
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      ${css(...args)};
    }
  `;
}

/**
 * Grid
 */

// A helper to find the column width for an item that can span one or more columns
export const getColWidth = (size, colSpan) => {
  const colNum = gridConfig[size].columns;
  const gap = gridConfig[size].gap;

  return `calc(${(colSpan / colNum) * 100}% - ${gap}px)`;
};

const gridConfig = {
  small: {
    columns: 2,
    gap: 4,
    viewport: props => `max-width: ${props.theme.breakpoints.large - 1}px`,
  },
  medium: {
    columns: 2,
    gap: 8,
    viewport: props =>
      `min-width: ${props.theme.breakpoints.large}px) and (max-width: ${
        props.theme.breakpoints.xlarge - 1
      }px`,
  },
  large: {
    columns: 3,
    gap: 20,
    viewport: props =>
      `min-width: ${props.theme.breakpoints.xlarge}px) and (max-width: ${
        props.theme.breakpoints.xxlarge - 1
      }px`,
  },
  xlarge: {
    columns: 4,
    gap: 20,
    viewport: props => `min-width: ${props.theme.breakpoints.xxlarge}px`,
  },
};

/**
 * Grid media template with grid config options
 * Use like:
 *    ${grid.small.css`margin-left: ${grid.small.gap}px;`}
 */
export const grid = Object.keys(gridConfig).reduce((result, size) => {
  const currentSize = gridConfig[size];
  result[size] = { ...currentSize };
  result[size].columnWidth = getColWidth(size, 1);
  result[size].css = (...args) => {
    return css`
      @media (${currentSize.viewport}) {
        ${css(...args)};
      }
    `;
  };

  return result;
}, {});
