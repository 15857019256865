import { connect } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';
import { getSession } from '@techstyle/redux-core';

import DefaultAssetFilter from './DefaultAssetFilter';

export function mapStateToProps(state, ownProps) {
  return {
    isDowngradedMember: getMembership(state).isDowngraded,
    isVip: getMembership(state).isVip,
    dmgCode: getSession(state).dmGatewayCode,
    isBorderfreeCustomer: state.borderfree.isBorderfreeCustomer,
    persona: ownProps.persona || getMembership(state).persona || null,
  };
}

export default connect(mapStateToProps)(DefaultAssetFilter);
