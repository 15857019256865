import { getPartialTrackingData } from '../utils';

const gridProductCellClickEvents = ({ getContext, trackEvent }) => {
  const { TRACK_GRID_PRODUCT_CELL_CLICK } = require('../../actions/products');
  const gridProductCellClick = trackEvent((action, prevState, nextState) => {
    const context = getContext();
    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
    ]);

    return {
      name: 'Product Clicked',
      properties: {
        ...additionalTrackingData,
        brand: 'Savage X',
        ...action.payload,
      },
    };
  });
  return { [TRACK_GRID_PRODUCT_CELL_CLICK]: gridProductCellClick };
};

export default gridProductCellClickEvents;
