import React, { useCallback, useContext } from 'react';

import styled from 'styled-components';

import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import { v1, v2 } from '../../utils/themeVersioning';
import SearchProvider from '../SearchProvider/SearchProvider';
import { Component as StaticIcon } from '../StaticIcon';

const Wrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;

  ${v1`
      /* Keyboard focus produces outline consistent with theme */
    &:focus-visible {
      outline: 2px solid ${props => props.theme.focusColor};
    }
  `}

  ${v2`
    ${props => props.theme.links.variants.navbarLink}
    height: 30px;

    > img {
       height: 24px;
       margin-right: 5px;
    }
  `}
`;

type SearchToggleProps = {
  className?: string;
  /**
   * Disabled the onClick func and set disabled
   */
  disabled?: boolean;
  /**
   * The search icon to render
   */
  searchIcon?: React.ReactNode;
  /**
   * The search icon size
   */
  size?: number;
  extended?: boolean;
};

const SearchToggle = ({
  className,
  disabled = false,
  searchIcon,
  size = 24,
  extended,
}: SearchToggleProps) => {
  const { toggleActive } = useContext(SearchProvider.Context);
  const theme = useTheme(null, null);
  const { isMobile } = useBreakpoint();

  const handleClick = useCallback(() => {
    toggleActive();
  }, [toggleActive]);

  return (
    <Wrapper className={className} disabled={disabled} onClick={handleClick}>
      {searchIcon || <StaticIcon type="search" size={size} />}
      {theme.themeVersion === 'v2' && !extended && !isMobile ? (
        <FormattedMessage id="search.search_label" defaultMessage="Search" />
      ) : null}
    </Wrapper>
  );
};

export default SearchToggle;
