import * as Sentry from '@sentry/nextjs';
import config from 'config';

const productImageQuery = config.get('public.cdn.productImageQuery');
const cdnUrl = config.get('public.cdn.baseUrl');

function generateSkuWithoutSize({ alias, itemNumber }) {
  // Some products like the membership will not have an item number!
  // (/account/wishlist) Product Sets need to use `product.alias` since they don’t have `bundleItems`
  if (!itemNumber && !alias) {
    return null;
  }

  // Parse off the last dash stuff...
  const itemArray = itemNumber && (itemNumber + '').split('-');
  const skuWithoutSize = itemNumber ? itemArray.slice(0, -1).join('-') : alias;

  return skuWithoutSize;
}

const generateCdnImageUrl = ({
  product,
  modelIndex = 0,
  angle = 1, // 1-5 or laydown
  imageSize = 2,
}: {
  product: any;
  modelIndex?: number;
  angle?: number | string;
  imageSize?: number;
}) => {
  const imageSizes = {
    1: `200x200`,
    2: `400x400`,
    3: `800x800`,
    4: `1600x1600`,
    5: `3000x3000`,
    6: `150x200`,
    7: `300x400`,
    8: `600x800`,
    9: `1200x1600`,
    10: `2250x3000`,
  };
  const queryString = productImageQuery || '';

  const itemNumber = product.item_number || product.itemNumber;
  const skuWithoutSize = generateSkuWithoutSize({
    alias: product.alias,
    itemNumber,
  });

  if (!skuWithoutSize) {
    Sentry.captureException(
      `Error: 'generateCdnImageUrl' could not find the sku for the following product: ${product}`
    );
    return null;
  }

  let permalink = product.permalink;

  if (!permalink) {
    const useMasterLabel =
      product.masterLabel && product.masterLabel.toUpperCase();
    const useLabel = product.label && product.label.toUpperCase();
    let label = useMasterLabel || useLabel;

    if (label) {
      label = label.replace(/ /g, '-');
      permalink = `${label}-${skuWithoutSize}`;
    } else {
      Sentry.captureMessage(
        `Error: 'generateCdnImageUrl' could not find permalink for the following product: ${product}`,
        'warning'
      );
      return null;
    }
  }

  return `${cdnUrl}/media/images/products/${skuWithoutSize.toUpperCase()}/${permalink.toUpperCase()}${
    modelIndex ? `-alt${modelIndex}` : ''
  }-${angle.toString().toUpperCase()}-${
    imageSizes[imageSize]
  }.jpg${queryString}`;
};

export default generateCdnImageUrl;

type ImageSize = {
  angle: string;
  sizes: Array<{
    height: number;
    width: number;
    url: string;
  }>;
};

type ProductImages = {
  default: ImageSize[];
  plus: ImageSize[];
};

// this is pulled straight from tier-1 TS generation of product_images array
export const generateCdnImagesBmig = (
  product,
  modelIndex = 0,
  includedSizes = [],
  angleArray = ['1', '2', '3', '4', 'LAYDOWN'] // angle: 1-5 or laydown. Also serves as sort order
) => {
  const defaultSizeImages: ImageSize[] = [];
  const plusSizeImages: ImageSize[] = [];
  const compiledSkus = [];
  const imageSizes = [
    { width: 150, height: 200, imageSize: 6 },
    { width: 300, height: 400, imageSize: 7 },
    { width: 600, height: 800, imageSize: 8 }, // AKA: 3
    { width: 1200, height: 1600, imageSize: 9 },
  ];

  if (
    product.alias ||
    product.item_number ||
    product.itemNumber ||
    product.master_label
  ) {
    const { alias } = product;
    const itemNumber = product.item_number || product.itemNumber;
    // The code below is almost exactly copied out of SavageX code for grids
    if (itemNumber) {
      // Some products like the membership will not have an item number!
      // (/account/wishlist) Product Sets need to use `product.alias` since they don’t have `bundleItems`
      const skuWithoutSize = generateSkuWithoutSize({
        alias,
        itemNumber,
      });
      // Products must have a SKU in order to be mapped to their images. Bail if no SKU is found.
      if (!skuWithoutSize) {
        return;
      }
      if (compiledSkus.includes(skuWithoutSize)) {
        return;
      }

      angleArray.forEach(angle => {
        const sizes = [];
        imageSizes
          .filter(
            size =>
              !includedSizes.length || includedSizes.includes(size.imageSize)
          )
          .forEach(productImagesSize => {
            const { width, height, imageSize } = productImagesSize;
            const url = generateCdnImageUrl({
              product,
              modelIndex,
              angle,
              imageSize,
            });
            const imageObject = { height, width, url };

            sizes.push(imageObject);
          });
        // SX does not currently have separate plus-size images
        const angleObject = { angle, sizes } as ImageSize;
        defaultSizeImages.push(angleObject);
      });

      compiledSkus.push(skuWithoutSize);
    }
  }

  const productImages: ProductImages = {
    default: defaultSizeImages,
    plus: plusSizeImages,
  };

  return productImages;
};
