import React from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { updateProfile } from '@techstyle/react-accounts';
import { useFeature } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';

import { getSizeFromCache } from '../../utils/getSizeFromCache';

const LogOutButton = ({ className, onClick, trackAccountSegmentEvents }) => {
  const { data, networkStatus } = useFeature('save-cached-sizes');
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);

  const handleClick = async () => {
    await trackAccountSegmentEvents();
    const isFeatureEnabled =
      data &&
      data.enabled &&
      data.value &&
      networkStatus.isUpToDate &&
      !networkStatus.isLoading;

    if (isFeatureEnabled) {
      const profileToUpdate = { ...customer };
      const sizeNotSaved = getSizeFromCache(profileToUpdate);

      if (sizeNotSaved) {
        profileToUpdate.profile = {
          ...profileToUpdate.profile,
          ...sizeNotSaved,
        };

        try {
          await dispatch(updateProfile(profileToUpdate));
        } catch (err) {
          // nothing to do here
        }
      }
    }

    onClick();
  };

  return (
    <button
      className={className}
      onClick={handleClick}
      data-autotag="logout-btn"
    >
      <FormattedMessage defaultMessage="Log out" id="global_cta.log_out" />
    </button>
  );
};

LogOutButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  trackAccountSegmentEvents: PropTypes.func,
};

export default LogOutButton;
