import React, { useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useCustomer, useMembership } from '@techstyle/react-accounts';
import { useTheme } from '@techstyle/react-components';
import { FormattedMessage, useIntl } from '@techstyle/react-intl';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import { getBirthdayDetails, isNMP } from '../../utils/selectors';
import { v2 } from '../../utils/themeVersioning';
import useBirthdayPromo from '../../utils/useBirthdayPromo';
import { Component as MemberCreditsDesktopBirthdayModal } from '../MemberCreditsDesktopBirthdayModal';
import { Component as StaticIcon } from '../StaticIcon';
import { Component as XtraVipIcon } from '../XtraVipIcon';

const empMembershipTypeId = config.get('public.membershipTypeIds.emp');

const MemberCreditsDesktopNavItemsWrapper = styled.div`
  text-align: left;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 4px solid ${props => props.theme.colors.gray150};

  border-radius: 8px 8px 0px 0px;
  ${v2`
    border-bottom: none;
    padding: 20px ${props => props.theme.spacing.tiny}px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `}
`;

const MembershipWrapper = styled.div<{ isEmpVip: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.small}px 0;
  margin: 0px ${props => props.theme.spacing.small}px 0;
  ${props =>
    props.isEmpVip &&
    `
      border-bottom: 1px solid ${props.theme.colors.lavender250};
      padding-bottom: 4px;
  `}
  ${v2`
      border-bottom: 1px solid ${props => props.theme.colors.stone};
      padding: 0 0 16px;
      margin: 0;
  `}
`;

const NameDetail = styled.div`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 19.2px;
  margin-top: 2px;
  color: ${props => props.theme.colors.default};
  ${v2`
    ${({ theme }) => theme.subHeader.variants.subHeading1.textStyles}
  `}
`;

const MembershipDetail = styled.div`
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 4px;
  color: ${props => props.theme.colors.subdued};
  ${v2`
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
    color: ${({ theme }) => theme.colors.gunmetal};
  `}
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const MemberCreditDetails = styled.div<{ hasCredit: boolean }>`
  letter-spacing: 0.3px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin: ${props => props.theme.spacing.small}px;
  margin: ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.small}px 0px;
  padding-bottom: ${props => props.theme.spacing.small}px;

  ${props =>
    props.hasCredit &&
    `
      font-weight: bold;
      color: ${props.theme.colors.promo};
    `};

  ${v2`
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
    color:  ${({ theme }) => theme.colors.lavender700};
    margin: 0;
    border-bottom: 1px solid ${props => props.theme.colors.stone};
  `}
`;

const MoreInfoLinkWrapper = styled.div<{ isVip?: boolean }>`
  background-color: ${props => props.theme.colors.white};
  text-align: left;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 16px;
  border-bottom: 4px solid ${props => props.theme.colors.gray150};
  padding: 12px ${props => props.theme.spacing.small}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-transform: uppercase;

  ${props =>
    props.isVip &&
    `
    letter-spacing: 0.3px;
    text-transform: none;
    font-size: 12px;
  `}

  ${v2`
    padding: 12px ${props => props.theme.spacing.tiny}px;
    border: none;
  `}
`;

const BirthdayWrapper = styled(MoreInfoLinkWrapper)`
  letter-spacing: 0.3px;
  text-transform: none;
  font-size: 12px;
  --desktop-nav-text-color: ${props => props.theme.colors.white};
  color: var(--desktop-nav-text-color);
  background: ${props => props.theme.colors.lavenderGradient};
  ${v2`
    background: ${props => props.theme.colors.lavender};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.subHeader.variants.subHeading3.textStyles}
  `}
`;

const AccountLinkText = styled.div`
  color: var(--desktop-nav-text-color, ${props => props.theme.colors.subdued});
  font-weight: normal;
  ${v2`
    color: ${({ theme }) => theme.colors.gunmetal};
    ${({ theme }) => theme.paragraph.variants.paragraph4.textStyles}
  `}
`;

const StyledLink = styled.a`
  cursor: pointer;
  color: ${props => props.theme.colors.default};
`;

const LeadTextHighlight = styled.div`
  color: ${props => props.theme.colors.promo};
  ${v2`
    color: ${props => props.theme.colors.gunmetal};
  `}
`;

const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
`;

const StyledRightChevron = styled(StaticIcon as any).attrs({
  type: 'caret',
  size: 8,
})`
  transform: rotate(270deg);
  margin: auto 0;
  ${v2`
    > path {
      stroke-width: ${({ isBirthdaySection }) =>
        isBirthdaySection ? 0.6 : 0.3};
    `}
`;

const Wrapper = styled.div``;

const StyledXtraVipIcon = styled(XtraVipIcon)`
  width: 38px;
`;

const WineGlasses = styled(StaticIcon as any).attrs({
  type: 'wineGlasses',
  size: 26,
})`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const LinksSectionWrapper = styled.div`
  ${v2`
    background: ${props => props.theme.colors.white};
    padding: 0 ${props => props.theme.spacing.tiny}px;
  `}
`;

const TitleWrapper = styled.div`
  ${v2`
    span {
      text-transform: uppercase;
    }
  `}
`;

function MemberCreditsDesktopCreditDetails({
  creditsRemaining,
  hasCredit,
  isEmpVip,
}) {
  if (!isEmpVip) {
    return null;
  }

  return (
    <MemberCreditDetails hasCredit={hasCredit}>
      {creditsRemaining}{' '}
      {creditsRemaining === 1 ? (
        <FormattedMessage
          id="site_navigation.member_credit_single"
          defaultMessage="Member Credit"
        />
      ) : (
        <FormattedMessage
          id="site_navigation.member_credits_plural"
          defaultMessage="Member Credits"
        />
      )}
    </MemberCreditDetails>
  );
}

function MemberCreditsDesktopNavItems({ closeNavDrawer = () => {} }) {
  const { formatMessage, formatDate } = useIntl();
  const { firstName } = useCustomer();
  const { 'token-to-cash': tokenToCashEnabled } = useLDFlags();
  const userIsNMP = useSelector(isNMP);
  const {
    isVip,
    membershipTypeId,
    isDowngraded,
    availableTokenQuantity: creditsRemaining,
    dateActivated,
  } = useMembership();
  const hasCredit = creditsRemaining >= 1;
  const isEmp = membershipTypeId === empMembershipTypeId;
  const isEmpVip = isEmp && isVip && !isDowngraded;
  const { isBirthday, isBirthMonth, hasBirthday } =
    useSelector(getBirthdayDetails);
  const {
    isBirthdayPromoReady,
    hasUsedBirthdayPromo,
    promoCode,
    discountPercent,
  } = useBirthdayPromo();

  // @ts-ignore
  const { themeVersion } = useTheme();

  const shouldShowBirthdayBanner =
    isBirthMonth &&
    isBirthdayPromoReady &&
    !hasUsedBirthdayPromo &&
    promoCode &&
    discountPercent;

  const [isBirthdayModalOpen, setIsBirthdayModalOpen] = useState(false);

  const handleBirthdayModalOpen = () => {
    closeNavDrawer();
    setIsBirthdayModalOpen(true);
  };

  const handleBirthdayModalExit = () => {
    setIsBirthdayModalOpen(false);
  };

  const renderEmpVipInfoLink = () => {
    return (
      <Wrapper>
        <TitleWrapper>
          {hasCredit ? (
            <FormattedMessage
              id="site_navigation.info_link_header_vip_has_credits"
              defaultMessage="Redeem Credits"
            />
          ) : (
            <FormattedMessage
              id="site_navigation.info_link_header_vip_no_credits"
              defaultMessage="Discover Perks"
            />
          )}
        </TitleWrapper>

        <AccountLinkText>
          {hasCredit ? (
            <FormattedMessage
              id="site_navigation.info_link_subheader_vip_has_credits"
              defaultMessage="On VIP Boxes and more"
            />
          ) : (
            <FormattedMessage
              id="site_navigation.info_link_subheader_vip_no_credits"
              defaultMessage="Xplore your Xtra VIP Membership"
            />
          )}
        </AccountLinkText>
      </Wrapper>
    );
  };

  const renderEmpLeadInfoLink = () => {
    return (
      <Wrapper>
        <LeadTextHighlight>
          <FormattedMessage
            id="site_navigation.info_link_header_lead"
            defaultMessage="Want to be a VIP?"
          />
        </LeadTextHighlight>
        <AccountLinkText>
          <FormattedMessage
            id="site_navigation.info_link_subheader_lead"
            defaultMessage="Discover the perks and more"
          />
        </AccountLinkText>
      </Wrapper>
    );
  };

  return (
    <>
      {firstName || isEmpVip ? (
        <MemberCreditsDesktopNavItemsWrapper>
          {firstName ? (
            <MembershipWrapper isEmpVip={isEmpVip}>
              <NameDetail data-autotag="pii-member-credit-desktop-nav-first-name">
                {isBirthday ? (
                  <FormattedMessage
                    id="site_navigation.happy_birthday_firstname"
                    defaultMessage="Happy Birthday, {firstName}!"
                    values={{ firstName }}
                  />
                ) : (
                  <FormattedMessage
                    id="site_navigation.emp_info_greeting"
                    defaultMessage="Hello, {firstName}!"
                    values={{ firstName }}
                  />
                )}
                {isEmpVip ? (
                  <MembershipDetail>
                    <FormattedMessage
                      id="site_navigation.emp_info_member_since"
                      defaultMessage="Member Since {year}"
                      values={{
                        year: formatDate(dateActivated, { year: 'numeric' }),
                      }}
                    />
                  </MembershipDetail>
                ) : null}
              </NameDetail>
              {isEmpVip ? (
                <IconWrapper>
                  <StyledXtraVipIcon
                    v2mini={themeVersion === 'v2'}
                    aria-label={formatMessage({
                      id: 'site_navigation.savage_xtra_member',
                      defaultMessage: 'Xtra VIP Member',
                    })}
                  />
                </IconWrapper>
              ) : null}
            </MembershipWrapper>
          ) : null}
          {userIsNMP && tokenToCashEnabled ? null : (
            <MemberCreditsDesktopCreditDetails
              hasCredit={hasCredit}
              creditsRemaining={creditsRemaining}
              isEmpVip={isEmpVip}
            />
          )}
        </MemberCreditsDesktopNavItemsWrapper>
      ) : null}
      <LinksSectionWrapper>
        {userIsNMP && tokenToCashEnabled ? null : (
          <StyledLink href="/features/build-your-own">
            {isEmp ? (
              <MoreInfoLinkWrapper isVip={isVip}>
                {isEmpVip ? renderEmpVipInfoLink() : renderEmpLeadInfoLink()}
                <StyledRightChevron />
              </MoreInfoLinkWrapper>
            ) : null}
          </StyledLink>
        )}
        {isEmp && !hasBirthday ? (
          <StyledLink href={isVip ? '/account/membership' : '/account/profile'}>
            <BirthdayWrapper>
              <Wrapper>
                <TitleWrapper>
                  <FormattedMessage
                    id="site_navigation.desktop_emp_birthday_missing_header"
                    defaultMessage="When's your birthday, Boo?"
                  />
                </TitleWrapper>
                <AccountLinkText>
                  <FormattedMessage
                    id="site_navigation.desktop_emp_birthday_missing_text"
                    defaultMessage="Enjoy a yearly birthday surprise!"
                  />
                </AccountLinkText>
              </Wrapper>
              <StyledRightChevron isBirthdaySection />
            </BirthdayWrapper>
          </StyledLink>
        ) : null}

        {shouldShowBirthdayBanner ? (
          <ButtonWrapper onClick={handleBirthdayModalOpen}>
            <BirthdayWrapper>
              <WineGlasses />
              <Wrapper>
                <TitleWrapper>
                  <FormattedMessage
                    id="site_navigation.desktop_emp_birthday_code_header"
                    defaultMessage="For your birthday! {discountPercent}% off!"
                    values={{
                      discountPercent,
                    }}
                  />
                </TitleWrapper>
                <AccountLinkText>
                  <FormattedMessage
                    id="site_navigation.desktop_emp_birthday_code_text"
                    defaultMessage="Use code {promoCode}"
                    values={{
                      promoCode: <Bold>{promoCode}</Bold>,
                    }}
                  />
                </AccountLinkText>
              </Wrapper>
              <StyledRightChevron />
            </BirthdayWrapper>
          </ButtonWrapper>
        ) : null}

        <MemberCreditsDesktopBirthdayModal
          isOpen={isBirthdayModalOpen}
          onExit={handleBirthdayModalExit}
        />
      </LinksSectionWrapper>
    </>
  );
}

MemberCreditsDesktopNavItems.propTypes = {
  closeNavDrawer: PropTypes.func,
};

export default MemberCreditsDesktopNavItems;
