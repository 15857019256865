import { connect } from 'react-redux';

import { trackMembershipLearnMore } from '../../actions/membership';
import { getFreeTrialMembershipProductId } from '../../utils/selectors';

import VipAnnualItem from './VipAnnualItem';

const mapStateToProps = (state, ownProps) => {
  return {
    isFreeTrial:
      ownProps.item.productId === getFreeTrialMembershipProductId(state),
    order: ownProps.order || state.checkout,
  };
};

const mapDispatchToProps = {
  trackMembershipLearnMore,
};

export default connect(mapStateToProps, mapDispatchToProps)(VipAnnualItem);
