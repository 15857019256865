import { loadMembership } from '@techstyle/react-accounts';
import { trackProductPriceViewed } from '@techstyle/react-products';

const productPriceViewed = ({ createEvent, dispatch }) => {
  const productsMap = new Map();
  let productsPending = false;
  const wasSeenMap = new Map();

  const productPriceViewed = async (action, _, nextState) => {
    await dispatch(loadMembership());
    const payloadKey = action.payload.payloadKey || '{}';
    const currentGridKey = `${action.payload.fplId}_${action.payload.productSource}_${payloadKey}`;
    let products = productsMap.get(currentGridKey);
    let seenProducts = wasSeenMap.get(currentGridKey);

    if (!products) {
      products = [];
      productsMap.set(currentGridKey, products);
    }
    if (!wasSeenMap.has(currentGridKey)) {
      wasSeenMap.clear();
    }
    if (!seenProducts) {
      seenProducts = new Set();
      wasSeenMap.set(currentGridKey, seenProducts);
    }
    if (!seenProducts.has(action.payload.product.masterProductId)) {
      products.push(action.payload);
      seenProducts.add(action.payload.product.masterProductId);
    }
    if (productsPending || !products.length) {
      return [];
    } else {
      productsPending = true;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          productsPending = false;
          const events = [];
          productsMap.forEach(productInfos => {
            const trackProductPricesViewed = productInfos.map(productInfo => {
              return parseTrackProductDetail(productInfo);
            });

            events.push(
              createEvent({
                name: 'Product Price Viewed',
                properties: {
                  products: trackProductPricesViewed.flat(),
                },
              })
            );
          });
          productsMap.clear();
          resolve(events);
        }, 800);
      });
    }
  };

  return { [trackProductPriceViewed]: productPriceViewed };
};

const parseTrackProductDetail = productInfo => {
  const { product } = productInfo;

  return {
    master_product_id: product.masterProductId,
  };
};

export default productPriceViewed;
