import { useCallback, useMemo } from 'react';

import type { LDClient as LDClientNode } from '@launchdarkly/node-server-sdk';
import type { LDClient as LDClientReact } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import type { Store } from 'redux';

import { getCustomer, getMembership } from '@techstyle/react-accounts';
import { useAdminTools } from '@techstyle/react-admin';
import { useBreakpoint } from '@techstyle/react-components';
import {
  createSelector,
  getSession,
  getSessionVisitorId,
} from '@techstyle/redux-core';

import { getSXFMembershipStatus } from '../segment-events/utils';

// TODO: Can we update this to use types from SDK?
export type LDContextValue = {
  kind: 'multi';
  locale: {
    kind: 'locale';
    key: string;
    domain: string;
  };
  user:
    | {
        kind: 'user';
        key: string;
        email: string;
        isLead: boolean;
        isVip: boolean;
        isDowngraded: boolean;
        statusLabel: string;
        membershipTypeId: number;
        isAdmin: boolean;
        sessionId: string;
        isFirstTimeVisitor: boolean;
        membershipStatus: string;
      }
    | {
        kind: 'user';
        anonymous: true;
        key?: string;
        isAdmin: boolean;
        sessionId: string;
        isFirstTimeVisitor: boolean;
        membershipStatus: string;
      };
  device: {
    kind: 'device';
    key: 'Tablet' | 'Mobile' | 'Desktop';
  };
};

export const getLaunchDarklyContext = createSelector(
  [
    state => state.domain,
    getSession,
    getCustomer,
    getMembership,
    getSessionVisitorId,
    getSXFMembershipStatus,
    state => state.intl.geoCountry,
  ],
  (
    domain,
    session,
    customer,
    membership,
    sessionVisitorId,
    membershipStatus,
    geoCountry
  ) => {
    const { tld } = domain;
    const { isLoggedIn, isAdmin, sessionId, isFirstTimeVisitor } = session;
    const { email, id: customerId } = customer;
    const { isVip, isDowngraded, isLead, statusLabel, membershipTypeId } =
      membership;

    const localeContext = {
      kind: 'locale',
      domain: tld,
      key: `savagex${tld}`,
    };

    const userContext = {
      kind: 'user',
      sessionId: sessionId,
      sessionVisitor: sessionVisitorId,
      isFirstTimeVisitor: isFirstTimeVisitor,
      isAdmin: isAdmin,
      membershipStatus: membershipStatus,
      geoCountry,
      ...(isLoggedIn
        ? {
            key: `${customerId}`,
            email: email,
            isLead: isLead,
            isVip: isVip,
            isDowngraded: isDowngraded,
            statusLabel: statusLabel,
            membershipTypeId: membershipTypeId,
          }
        : {
            anonymous: true,
          }),
    };

    const guestContext = {
      kind: 'guest',
      key: `${sessionVisitorId}`,
    };

    const deviceContext = {
      kind: 'device',
      key: 'Mobile',
    };

    return {
      kind: 'multi',
      locale: localeContext,
      user: userContext,
      guest: guestContext,
      device: deviceContext,
    } as LDContextValue;
  }
);

export const useLaunchDarklyContextValue = () => {
  const { enabled } = useAdminTools();
  const { isMobile } = useBreakpoint(null);
  const { matches: isTablet } = useBreakpoint(
    `(min-width: 768px) and (max-width: 1023px)`
  );
  const getDeviceContext = useCallback(() => {
    if (isTablet) {
      return 'Tablet';
    } else if (isMobile) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  }, [isTablet, isMobile]);
  const launchDarklyContextValues = useSelector(getLaunchDarklyContext);
  const fullContextValues = useMemo(
    () => ({
      ...launchDarklyContextValues,
      user: {
        ...launchDarklyContextValues.user,
        isAdmin: launchDarklyContextValues?.user?.isAdmin || enabled,
      },
      device: {
        ...launchDarklyContextValues.device,
        key: getDeviceContext(),
      },
    }),
    [enabled, getDeviceContext, launchDarklyContextValues]
  );
  return fullContextValues;
};

/**
 * A helper function to retrieve LD flags inside of `getInitialProps`.
 * This is needed because the LD client works differently on the server vs the client.
 */
export const getLDFlagWithCtx = async ({
  flag,
  ctx,
}: {
  /** The LD Flag to retrieve */
  flag: string;
  /** The context to use when retrieving the flag, this is the parameter for `getInitialProps`  */
  ctx: { ldClient?: LDClientNode | LDClientReact; store: Store };
}) => {
  if (!ctx.ldClient) {
    return null;
  }

  if (typeof window !== 'undefined') {
    // @ts-ignore -- We know this will be the React client at this point
    const ldClient: LDClientReact = ctx.ldClient;
    return await ldClient.variation(flag);
  }

  // @ts-ignore -- We know this will be the Node client at this point
  const ldClient: LDClientNode = ctx.ldClient;
  const fullLDContext = getLaunchDarklyContext(ctx.store.getState());
  const { user, ...ldContextWithoutUser } = fullLDContext;
  // Remove the user from the context if they are anonymous since we won't have a key
  const ldContextToSend =
    'anonymous' in user ? ldContextWithoutUser : fullLDContext;

  return await ldClient.variation(flag, ldContextToSend, null);
};

export const GlobalLDSymbol = Symbol('GlobalLD');
