import React, { useMemo, useState } from 'react';

const Context = React.createContext({
  isModalOpen: false,
  setIsModalOpen: () => {},
});

const CancelConfirmationModalProvider = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
    }),
    [isModalOpen, setIsModalOpen]
  );

  return <Context.Provider {...props} value={value} />;
};

CancelConfirmationModalProvider.Context = Context;

export default CancelConfirmationModalProvider;
