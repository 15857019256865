import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  updateCustomerDetail as saveMemberDetails,
  useCustomerDetail,
} from '@techstyle/react-accounts';
import { useCookies, useDomain, useSession } from '@techstyle/redux-core';

import { EmailPreferences } from '../../constants/email';
import { mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { v1, v2 } from '../../utils/themeVersioning';
import { useEmailPreferencesWithVars } from '../../utils/useEmailPreferencesWithVars';
import usePrevious from '../../utils/usePrevious';
import { Component as Toast } from '../LegacyToast';

import NewsletterContent from './NewsletterContent';

// Errors.
const NEWSLETTER_KEY = 'viewed_newsletter_modal';

const dialogStyle = css`
  ${mobile`
      width: 90%;
  `};
`;

const NewsletterModal = styled(BasicModal)``;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  border: 8px solid;
  min-height: 411px;
  background-color: ${props => props.theme.colors.white};

  ${v1`
      border-image: linear-gradient(
    to bottom,
    rgba(192, 179, 196, 1),
    rgba(181, 212, 227, 1) 36%,
    rgba(212, 177, 194, 1) 69%,
    rgba(168, 202, 186, 1)
  );
  border-image-slice: 1;
  padding: 30px 20px;
  width: 640px;
    `}

  ${v2`
      border-color: ${props => props.theme.colors.lavender};
      padding: 24px 56px 40px;
      `}

  ${mobile`
    width: auto;
    padding: 30px 15px;
  `};
`;

/**
 * ModalNewsletter component
 *
 * @param {Object} props
 *
 * @return {jsx} jsx
 */
const ModalNewsletter = ({
  isAutoOpen = false,
  isOpen = false,
  onRequestClose,
  onRequestOpen,
}) => {
  const [cookies, setCookies] = useCookies([NEWSLETTER_KEY]);
  const [shouldShowNextModal, setShouldShowNextModal] = useState(false);
  const [shouldShowBackButton, setShouldShowBackButton] = useState(false);
  const [hasViewedModal, setHasViewedModal] = useState(true);
  const [toastMessage, setToastMessage] = useState(null);
  const { isVisitor } = useSession();
  const {
    value: viewedNewsLetterValue,
    networkStatus: viewedNewsLetterNetworkStatus,
  } = useCustomerDetail(NEWSLETTER_KEY);
  const { networkStatus: emailNetworkStatus, optOutStatus } =
    useEmailPreferencesWithVars();
  const { tld } = useDomain();
  const wasAutoSubscribed = tld === '.com';
  const timeout = useRef(null);
  const dispatch = useDispatch();
  const previousIsVisitor = usePrevious(isVisitor);

  const readNewsletterCookies = useCallback(() => {
    let hasViewedModal = cookies[NEWSLETTER_KEY] === 'true';

    if (
      !isVisitor &&
      viewedNewsLetterNetworkStatus.isUpToDate &&
      !viewedNewsLetterNetworkStatus.isLoading
    ) {
      hasViewedModal = !!viewedNewsLetterValue;
    }

    setHasViewedModal(hasViewedModal);
  }, [
    cookies,
    isVisitor,
    viewedNewsLetterNetworkStatus,
    viewedNewsLetterValue,
  ]);

  const getCookieExpirationDate = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 180); // 180 days in the future.
    return expires;
  };

  const recordModalView = useCallback(() => {
    if (!hasViewedModal) {
      if (!isVisitor) {
        dispatch(
          saveMemberDetails({
            name: NEWSLETTER_KEY,
            value: 'true',
          })
        );
      }

      setCookies(NEWSLETTER_KEY, 'true', {
        expires: getCookieExpirationDate(),
        path: '/',
      });
      setHasViewedModal(true);
    }
  }, [dispatch, hasViewedModal, isVisitor, setCookies]);

  const checkShouldOpen = useCallback(() => {
    // Have they seen the modal? Then don't show.
    if (hasViewedModal) {
      return false;
    }
    // Are they logged out? Then show.
    if (isVisitor) {
      return true;
    }
    // Are they on a domain that automatically subscribed them to emails upon
    // signup? Then don't ask.
    if (wasAutoSubscribed) {
      return false;
    }
    // Do we already have their email status and it's opted out? Then ask.
    if (optOutStatus != null) {
      return optOutStatus === EmailPreferences.NEVER;
    }
  }, [hasViewedModal, isVisitor, optOutStatus, wasAutoSubscribed]);

  const delayOpen = useCallback(() => {
    /* Do not show immediately on load, because users have "pop up" blindness and will close anything they see pop up onload.
     * Instead, add a 5sec delay after the page loads, then transition newsletter into view from bottom of viewport.
     */
    timeout.current = setTimeout(() => {
      const shouldOpen = checkShouldOpen();

      if (shouldOpen) {
        onRequestOpen();
      }
    }, 5000);
  }, [checkShouldOpen, onRequestOpen]);

  const showMessage = toastMessage => setToastMessage(toastMessage);
  const hideMessage = () => setToastMessage(null);

  const handleReturnToPreviousModal = () => {
    setShouldShowNextModal(false);
    setShouldShowBackButton(false);
  };

  useEffect(() => {
    if (!isOpen && isAutoOpen) {
      delayOpen();
    }
  }, [isAutoOpen, isOpen, delayOpen]);

  useEffect(() => {
    readNewsletterCookies();
  }, [readNewsletterCookies]);

  useEffect(() => {
    if (isOpen) {
      recordModalView();

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    }
  }, [isOpen, recordModalView]);

  useEffect(() => {
    if (previousIsVisitor !== isVisitor) {
      readNewsletterCookies();
    }
  }, [isVisitor, previousIsVisitor, readNewsletterCookies]);

  useEffect(() => {
    if (
      emailNetworkStatus.isUpToDate &&
      !emailNetworkStatus.isLoading &&
      optOutStatus === EmailPreferences.NEVER
    ) {
      onRequestOpen();
    }
  }, [emailNetworkStatus, onRequestOpen, optOutStatus]);

  useEffect(() => {
    if (!isOpen) {
      setShouldShowBackButton(false);
      setShouldShowNextModal(false);
    }
  }, [isOpen]);

  return (
    <NewsletterModal
      dialogStyle={dialogStyle}
      isOpen={isOpen}
      onBack={handleReturnToPreviousModal}
      onExit={onRequestClose}
      overlayColor="rgba(24, 24, 30, 0.60)"
      shouldShowBackButton={shouldShowBackButton}
      titleId="newsletter-modal"
    >
      <ModalContainer id="newsletter-modal">
        <NewsletterContent
          isOpen={isOpen}
          isVisitor={isVisitor}
          onRequestClose={onRequestClose}
          setShouldShowBackButton={setShouldShowBackButton}
          setShouldShowNextModal={setShouldShowNextModal}
          shouldShowNextModal={shouldShowNextModal}
          showMessage={showMessage}
        />
        <Toast show={!!toastMessage} onHide={hideMessage}>
          {toastMessage}
        </Toast>
      </ModalContainer>
    </NewsletterModal>
  );
};

ModalNewsletter.propTypes = {
  isAutoOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  onRequestOpen: PropTypes.func.isRequired,
};

export default ModalNewsletter;
