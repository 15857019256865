import React, { useCallback, useContext } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css, withTheme } from 'styled-components';

import { Link, Router } from '@techstyle/next-routes';
import { AssetContainer } from '@techstyle/react-assets';
import { useFeatures } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';
import { NavContainer } from '@techstyle/react-navigation';

import { MetaNavState } from '../../constants/metaNavState';
import { NavBarBGColors } from '../../constants/navBarBGColors';
import { BasicButton } from '../../styles/button';
import { useNavFilter } from '../../utils/navigation';
import { v1, v2 } from '../../utils/themeVersioning';
import { getNavTrackingUrl, getNavItemAssets } from '../../utils/url';
import { useTopNavSelector } from '../../utils/useTopNavSelector';
import { Component as HoverMenu } from '../HoverMenu';
import { Component as MetaNavProvider } from '../MetaNavProvider';
import { Component as NavAssets } from '../NavAssets';
import { Container as NavMenuSubCategories } from '../NavMenuSubCategories';
import { Container as RouteLabel } from '../RouteLabel';
import { Component as StyleContext } from '../StyleContext';

export const Wrapper = styled.nav`
  display: none;

  ${props =>
    props.theme.context.showNewNavBorder &&
    css`
      border-top: 1px solid ${props => props.theme.colors.gray300};
    `}

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 350px;
  background: ${props => props.theme.colors.white};
  padding: ${({ theme }) => theme.spacing.medium}px 20px;
  border-bottom: 2px solid ${props => props.theme.colors.default};
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 ${({ theme }) => theme.spacing.moderate}px;
  margin: 0 auto;

  ${({ hasAssets }) =>
    hasAssets
      ? css`
          justify-content: center;
        `
      : css`
          width: 1024px;
        `}
`;

const CustomColoredLabel = styled.span`
  color: ${({ colorName, theme }) => {
    if (theme.themeVersion === 'v2') {
      return theme.colors.black;
    }
    if (theme.context.backgroundState === NavBarBGColors.TRANSPARENT) {
      return theme.colors.white;
    }
    return theme.colors[colorName] || theme.colors.default;
  }};
`;

const Label = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: ${({ theme }) => theme.spacing.tiny}px;
  margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
  line-height: ${20 / 12};

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    font-size: 12px;
    padding: 0 ${({ theme }) => theme.spacing.tiny}px;
    ${v2`
        font-size: 14px;
        padding: 0 8px;
      `}
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlarge}px) {
    font-size: 14px;
    padding: 0 ${({ theme }) => theme.spacing.small}px;
    line-height: ${20 / 14};

    ${v2`
        padding: 0 12px;
      `}
  }

  ${v1`
      font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  user-select: none;
    `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      user-select: none;

      &:hover {
        border-bottom: 1px solid transparent;
      }
      `}

  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0;
    ${v1`
      left: ${({ theme }) => theme.spacing.small}px;
      right: ${({ theme }) => theme.spacing.small}px;
      background: ${({ isActive, theme }) =>
        isActive ? theme.colors.lavender400 : 'transparent'};
      `}
    ${v2`
      left: ${({ theme }) => theme.spacing.tiny}px;
      right: ${({ theme }) => theme.spacing.tiny}px;
      background: ${({ isActive, theme }) =>
        isActive ? theme.colors.lavender : 'transparent'};
      `}
  }

  > a {
    display: flex;
    height: 100%;
    align-items: center;
  }

  & a {
    ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      &:hover {
        border-bottom: 1px solid transparent;
      }
      `}
  }
`;

const StyledLink = styled.a`
  ${v1`
    color: ${props => (props.isRed ? props.theme.colors.error : 'inherit')};
  `}
`;

const StyledNavMenuSubCategories = styled(NavMenuSubCategories)`
  & ${NavMenuSubCategories.StyledSection} {
    margin-left: 25px;
  }

  & ${NavMenuSubCategories.Title} {
    margin-bottom: 28px;

    ${v1`
      font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.subdued};
    letter-spacing: 0.3px;
    `}

    ${v2`
      & a {
        ${props => props.theme.links.variants.navigationPrimary}
      color: ${({ theme }) => theme.colors.grey};
      }

      `}
  }
`;

const TakeOurQuizBtn = styled(BasicButton)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: ${({ theme }) => theme.colors.active};
    border: none;
    font-weight: 600;
    height: ${({ theme }) => theme.spacing.medium}px;
    justify-content: center;
    letter-spacing: 1.75px;
    outline: none;
    text-transform: uppercase;
    min-width: 125px;
    color: ${({ theme }) => theme.colors.white};
    line-height: ${20 / 12};
  }

  @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    font-size: 12px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlarge}px) {
    line-height: ${20 / 14};
    font-size: 14px;
  }
`;

const SpecialFormattingWrapper = ({ children, navItem }) => {
  const { itemKey } = navItem;
  if (itemKey?.includes('__color_')) {
    const colorName = itemKey.replace('__color_', '');
    return (
      <CustomColoredLabel colorName={colorName}>{children}</CustomColoredLabel>
    );
  }

  return children;
};

function DesktopMenuItem({
  children,
  hasAssets = false,
  label,
  navSubCategories,
  panel,
  ...rest
}) {
  return (
    <HoverMenu.Item
      component={Label}
      label={label}
      showChevron={!!navSubCategories}
      {...rest}
    >
      {navSubCategories ? (
        <ContentWrapper>
          <Content hasAssets={hasAssets}>{children}</Content>
        </ContentWrapper>
      ) : null}
    </HoverMenu.Item>
  );
}

DesktopMenuItem.propTypes = {
  children: PropTypes.node,
  hasAssets: PropTypes.bool,
  label: PropTypes.node,
  navSubCategories: PropTypes.array,
  panel: PropTypes.string,
};

function NavMenuDesktop({
  additionalDiscounts,
  className,
  intl,
  isVip,
  trackNavItemClick,
  theme,
}) {
  const [
    { isEnabled: isNewDesktopMenuEnabled },
    { isEnabled: isNavQuizFeatureEnabled },
  ] = useFeatures(['desktop_nav_update', 'navigation_quiz_cta']);
  const { metaNavState } = useContext(MetaNavProvider.Context);
  const { isBorderfreeCustomer } = useSelector(state => state.borderfree);
  const { query } = useRouter();
  const selectedNav = useTopNavSelector();
  const navFilter = useNavFilter({ isMobile: false });
  const isNewThemeEnabled = theme.themeVersion === 'v2';

  const handleTakeQuizBtn = useCallback(() => {
    const fullQuery = {
      fromNavCta: 1,
      ...(query.mpid ? { mpid: query.mpid } : {}),
    };

    Router.push({
      pathname: '/product-quiz',
      query: fullQuery,
    });
  }, [query.mpid]);

  const renderLink = useCallback(
    (navItem, navSettings) => {
      const isRed =
        navItem.url === '/sale' && additionalDiscounts !== undefined && isVip;

      const navItemLabel = navItem.isResourceBundle ? (
        intl.formatMessage({
          id: navItem.label,
          defaultMessage: navItem.label,
        })
      ) : (
        <span dangerouslySetInnerHTML={{ __html: navItem.label }} />
      );

      const sendGaEvent = () => {
        if (!navItem.gaLabel) {
          return;
        }
        trackNavItemClick(navSettings.gaAction, navItem.gaLabel);
      };

      const navItemLink = getNavTrackingUrl(navItem);

      const label = navItem.url ? (
        <SpecialFormattingWrapper navItem={navItem}>
          <Link route={navItemLink} passHref>
            <StyledLink
              data-autotag={navItem.gaLabel}
              isRed={isRed}
              onClick={sendGaEvent}
            >
              {navItemLabel || <RouteLabel route={navItem.url} />}
            </StyledLink>
          </Link>
        </SpecialFormattingWrapper>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: navItem.label }} />
      );

      const assets = getNavItemAssets(navItem);

      return (
        <DesktopMenuItem
          key={navItem.id}
          label={label}
          panel={navItem.label}
          navSubCategories={navItem.navItems}
          hasAssets={!!assets.length}
        >
          <StyledNavMenuSubCategories
            gaAction={navSettings.gaAction}
            navItem={navItem}
          />
          <NavAssets assets={assets} />
        </DesktopMenuItem>
      );
    },
    [additionalDiscounts, intl, isVip, trackNavItemClick]
  );

  const renderQuiz = useCallback(() => {
    if (
      metaNavState === MetaNavState.LINGERIE &&
      isNewDesktopMenuEnabled &&
      isNavQuizFeatureEnabled &&
      !isBorderfreeCustomer &&
      !isNewThemeEnabled
    ) {
      return (
        <TakeOurQuizBtn onClick={handleTakeQuizBtn}>
          <FormattedMessage
            id="site_navigation.take_our_quiz_desktop"
            defaultMessage="Take our quiz"
          />
        </TakeOurQuizBtn>
      );
    }
    return <></>;
  }, [
    handleTakeQuizBtn,
    isBorderfreeCustomer,
    isNavQuizFeatureEnabled,
    isNewDesktopMenuEnabled,
    metaNavState,
  ]);

  const renderNav = useCallback(
    ({ data }) => {
      if (!data || !data.navItems) {
        return null;
      }
      return (
        <StyleContext context="hoverMenu">
          <HoverMenu fullWidth>
            {data.navItems.map(navItem =>
              renderLink(navItem, data.navSettings)
            )}
            {renderQuiz()}
          </HoverMenu>
        </StyleContext>
      );
    },
    [renderLink, renderQuiz]
  );

  return (
    // Although we aren't using this `AssetContainer` to render the asset
    // directly, we still use it because by mounting this component it will
    // cause the asset to be refetched when necessary (since only rendered
    // assets are refetched).
    <AssetContainer name="products_image_banner">
      {() => (
        <Wrapper className={className}>
          <NavContainer handle={selectedNav} filter={navFilter}>
            {renderNav}
          </NavContainer>
        </Wrapper>
      )}
    </AssetContainer>
  );
}

NavMenuDesktop.propTypes = {
  additionalDiscounts: PropTypes.object,
  className: PropTypes.object,
  intl: PropTypes.object,
  isVip: PropTypes.bool,
  trackNavItemClick: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(NavMenuDesktop);
