import React, {
  useMemo,
  useCallback,
  useReducer,
  useContext,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useMembership } from '@techstyle/react-accounts';

import { getByoSetsConfig } from '../../actions/byo';

type BYOState = {
  isByoOptionsModalOpen: boolean;
  isProgressHeaderVisible: boolean;
  isProgressBarModalOpen: boolean;
  isExitModalOpen: boolean;
  isFlowInitiated: boolean;
  isByoCtaEnabled: boolean;
  isLastStep: boolean;
  isLoading: boolean;
  isSetCompleted: boolean;
  selectedByoSet?: { config?: Array<any> };
  byoGroupCode?: string;
  currentStep: number;
  clickedFromByoGrids: boolean;
  lastAddedItem?: {};
  addedItems: Array<any>;
  isInterstitialFlow: boolean;
  firstProductMpid?: string;
  totalItemsInSet?: number;
};

type BYOContext = {
  closeByoOptionsModal: () => void;
  exitByoFlow: () => void;
  initiateByoFlow: ({
    byoSet,
    isInterstitialFlow,
    firstProductMpid,
  }: any) => void;
  isUserByoEligible: boolean;
  isUserEmpWithoutCredits: boolean;
  increaseByoStep: () => void;
  setByoGroupCode: (groupCode: number) => void;
  setByoSetCompleted: () => void;
  setIsByoCtaEnabled: (value: boolean) => void;
  setIsProgressHeaderVisible: (value: boolean) => void;
  setIsProgressBarModalOpen: (value: boolean) => void;
  setIsExitModalOpen: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
  showByoOptionsModal: (value: boolean) => void;
  trackAddedItems: (product: any) => void;
  trackClickFromByoGrids: () => void;
};

type BYOContextWithState = BYOContext & BYOState;

const initialState: BYOState = {
  isByoOptionsModalOpen: false,
  isProgressHeaderVisible: true,
  isProgressBarModalOpen: false,
  isExitModalOpen: false,
  isFlowInitiated: false,
  isByoCtaEnabled: true,
  isLastStep: false,
  isLoading: false,
  isSetCompleted: false,
  selectedByoSet: {},
  byoGroupCode: null,
  currentStep: 1,
  clickedFromByoGrids: false,
  lastAddedItem: {},
  addedItems: [],
  isInterstitialFlow: false,
  firstProductMpid: null,
};

const Context = React.createContext<BYOContextWithState>({
  closeByoOptionsModal: () => {},
  exitByoFlow: () => {},
  initiateByoFlow: () => {},
  isUserByoEligible: false,
  isUserEmpWithoutCredits: false,
  increaseByoStep: () => {},
  setByoGroupCode: () => {},
  setByoSetCompleted: () => {},
  setIsByoCtaEnabled: () => {},
  setIsProgressHeaderVisible: () => {},
  setIsProgressBarModalOpen: () => {},
  setIsExitModalOpen: () => {},
  setIsLoading: () => {},
  showByoOptionsModal: () => {},
  trackAddedItems: () => {},
  trackClickFromByoGrids: () => {},
  ...initialState,
});

const reducer = (state: BYOState, action): BYOState => {
  switch (action.type) {
    case 'INITIATE_BYO_FLOW':
      return {
        ...state,
        isFlowInitiated: true,
        selectedByoSet: action.byoSet,
        totalItemsInSet: action.byoSet.config.length,
        isInterstitialFlow: action.isInterstitialFlow,
        firstProductMpid: action.firstProductMpid,
      };
    case 'SET_BYO_GROUP_CODE':
      return {
        ...state,
        byoGroupCode: action.groupCode,
      };
    case 'SHOW_BYO_OPTION_MODAL':
      return {
        ...state,
        isByoOptionsModalOpen: true,
      };
    case 'CLOSE_BYO_OPTION_MODAL':
      return {
        ...state,
        isByoOptionsModalOpen: false,
      };
    case 'TRACK_ADDED_ITEM':
      return {
        ...state,
        lastAddedItem: action.product,
        addedItems: [...state.addedItems, action.product],
      };
    case 'INCREASE_BYO_STEP': {
      const nextStep = state.currentStep + 1;
      return {
        ...state,
        currentStep: nextStep,
        isLastStep: state.selectedByoSet.config
          ? nextStep === state.selectedByoSet.config.length
          : false,
      };
    }
    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    case 'SET_PROGRESS_BAR_HEADER_VISIBILITY':
      return {
        ...state,
        isProgressHeaderVisible: action.value,
      };
    case 'TRACK_CLICK_FROM_BYO_GRIDS':
      return {
        ...state,
        clickedFromByoGrids: true,
      };
    case 'SET_IS_PROGRESS_BAR_MODAL_OPEN':
      return {
        ...state,
        isProgressBarModalOpen: action.value,
      };
    case 'SET_IS_EXIT_MODAL_OPEN':
      return {
        ...state,
        isExitModalOpen: action.value,
      };
    case 'SET_IS_BYO_CTA_ENABLED':
      return {
        ...state,
        isByoCtaEnabled: action.value,
      };
    case 'SET_BYO_SET_COMPLETED':
      return {
        ...state,
        isSetCompleted: true,
      };
    case 'EXIT_BYO_FLOW':
      return initialState;
    default:
      throw new Error('Unrecognized action passed to BYOProvider');
  }
};

export const useByoContext = () => {
  return useContext(Context);
};

export default function ByoProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { availableTokenQuantity: creditsRemaining, membershipTypeId } =
    useMembership();
  const dispatchAction = useDispatch();

  const isUserByoEligible = membershipTypeId === 3 && creditsRemaining > 0;
  const isUserEmpWithoutCredits =
    membershipTypeId === 3 && creditsRemaining === 0;

  useEffect(() => {
    if (isUserByoEligible) {
      dispatchAction(getByoSetsConfig());
    }
  }, [dispatchAction, isUserByoEligible]);

  const showByoOptionsModal = useCallback(() => {
    dispatch({ type: 'SHOW_BYO_OPTION_MODAL' });
  }, []);

  const closeByoOptionsModal = useCallback(() => {
    dispatch({ type: 'CLOSE_BYO_OPTION_MODAL' });
  }, []);

  const initiateByoFlow = useCallback(
    ({ byoSet, isInterstitialFlow, firstProductMpid }) => {
      dispatch({
        type: 'INITIATE_BYO_FLOW',
        byoSet,
        isInterstitialFlow,
        firstProductMpid,
      });
    },
    []
  );

  const setByoGroupCode = useCallback(groupCode => {
    dispatch({ type: 'SET_BYO_GROUP_CODE', groupCode });
  }, []);

  const trackAddedItems = useCallback(product => {
    dispatch({ type: 'TRACK_ADDED_ITEM', product });
  }, []);

  const increaseByoStep = useCallback(() => {
    dispatch({ type: 'INCREASE_BYO_STEP' });
  }, []);

  const setIsProgressHeaderVisible = useCallback((value: boolean) => {
    dispatch({ type: 'SET_PROGRESS_BAR_HEADER_VISIBILITY', value });
  }, []);

  const trackClickFromByoGrids = useCallback(() => {
    dispatch({ type: 'TRACK_CLICK_FROM_BYO_GRIDS' });
  }, []);

  const setIsProgressBarModalOpen = useCallback((value: boolean) => {
    dispatch({ type: 'SET_IS_PROGRESS_BAR_MODAL_OPEN', value });
  }, []);

  const setIsExitModalOpen = useCallback((value: boolean) => {
    dispatch({ type: 'SET_IS_EXIT_MODAL_OPEN', value });
  }, []);

  const setIsByoCtaEnabled = useCallback((value: boolean) => {
    dispatch({ type: 'SET_IS_BYO_CTA_ENABLED', value });
  }, []);

  const setIsLoading = useCallback((value: boolean) => {
    dispatch({ type: 'SET_IS_LOADING', value });
  }, []);

  const setByoSetCompleted = useCallback(() => {
    dispatch({ type: 'SET_BYO_SET_COMPLETED' });
  }, []);

  const exitByoFlow = useCallback(() => {
    dispatch({ type: 'EXIT_BYO_FLOW' });
  }, []);

  const value = useMemo(
    (): BYOContextWithState => ({
      closeByoOptionsModal,
      exitByoFlow,
      initiateByoFlow,
      isUserByoEligible,
      isUserEmpWithoutCredits,
      increaseByoStep,
      setByoGroupCode,
      setByoSetCompleted,
      setIsByoCtaEnabled,
      setIsProgressHeaderVisible,
      setIsProgressBarModalOpen,
      setIsExitModalOpen,
      setIsLoading,
      showByoOptionsModal,
      trackAddedItems,
      trackClickFromByoGrids,
      ...state,
    }),
    [
      closeByoOptionsModal,
      exitByoFlow,
      initiateByoFlow,
      isUserByoEligible,
      isUserEmpWithoutCredits,
      increaseByoStep,
      setByoGroupCode,
      setByoSetCompleted,
      setIsByoCtaEnabled,
      setIsProgressHeaderVisible,
      setIsProgressBarModalOpen,
      setIsExitModalOpen,
      setIsLoading,
      showByoOptionsModal,
      trackAddedItems,
      trackClickFromByoGrids,
      state,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ByoProvider.Context = Context;
ByoProvider.Consumer = Context.Consumer;

ByoProvider.propTypes = {
  children: PropTypes.node,
};
