import { getPartialTrackingData } from '../utils';

const { TRACK_BYO_INTERSTITIAL_CLOSE } = require('../../actions/byo');

const trackByoInterstitialCloseEvents = ({ getContext, trackEvent }) => {
  const trackByoInterstitialClose = trackEvent(
    (action, _prevState, nextState) => {
      const context = getContext();
      const { masterProductId } = action.payload;
      const additionalTrackingData = getPartialTrackingData(
        nextState,
        context,
        [
          'automated_test',
          'loggedin_status',
          'feature',
          'membership_status',
          'session_id',
          'store_group_id',
          'user_id',
        ]
      );

      return {
        category: 'BYO Interstitial',
        name: 'Button Clicked',
        properties: {
          ...additionalTrackingData,
          label: 'Close BYO Interstitial',
          product_id: masterProductId,
        },
      };
    }
  );

  return {
    [TRACK_BYO_INTERSTITIAL_CLOSE]: trackByoInterstitialClose,
  };
};

export default trackByoInterstitialCloseEvents;
