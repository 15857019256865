import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FiCreditCard } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { useAsset } from '@techstyle/react-assets';
import { FormattedMessage } from '@techstyle/react-intl';

import { mobile } from '../../styles';
import { Component as BouncebackTermsModal } from '../BouncebackTermsModal';
import { Component as Price } from '../ExtendedPrice';
import { Container as PromotionProgressBar } from '../PromotionProgressBar';

const DynamicFormattedMessage = FormattedMessage;

const Message = styled.span`
  color: ${props => props.theme.colors.default};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '12px')};
  letter-spacing: 0.3px;
  margin-bottom: ${props =>
    props.marginBottom ? `${props.marginBottom}px` : ''};
`;

const RedeemMessage = styled.span`
  display: inline-block;
  font-size: 11px;
  letter-spacing: 0.3px;
  color: ${props => props.theme.colors.subdued};
`;

const Promo = styled.span`
  display: inline-block;
  color: ${props => props.theme.colors.promo};
`;

const Wrapper = styled.div``;

const BouncebackIcon = styled(FiCreditCard)`
  color: ${props => props.theme.colors.promo};
  margin-right: 6px;
  margin-bottom: -3px;
`;

const Button = styled.button`
  cursor: pointer;
  text-decoration: underline;
`;

const OrderConfirmationBounceBackWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 146px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-image: url('${props => props.imageUrl}');

  ${mobile`
      width: 100%;
      height: 163px;
      background-image: url('${props => props.mobileImgUrl}');
     `}
`;

const StyledTitle = styled.span`
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
`;

const StyledHeading = styled.span`
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.078em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
`;

const StyledContent = styled.span`
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.white};
  font-weight: 100;
  text-align: center;
  margin-top: 10px;
`;

const Bounceback = ({
  shouldHideProgress,
  prevCartTotal,
  className,
  shouldShowTerms,
  cartSubTotal,
  theme,
}) => {
  const { context } = theme;
  const { isVip } = useMembership();
  const { subtotalMinusDiscount } = useSelector(state => state.checkout);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const assetContainerBounceback = useAsset('bounceback_main_container');
  const { data } = assetContainerBounceback;
  const assetBounceback = data ? data.assets[0] : null;

  const toggleTerms = () => setIsTermsOpen(isTermsOpen => !isTermsOpen);

  if (!isVip || !assetBounceback) {
    return null;
  }

  const { imageFilename, mobileImageFilename, options } = assetBounceback;
  const { customVars } = options;
  const subTotal = subtotalMinusDiscount || cartSubTotal;

  const {
    threshold,
    successMessage,
    goalMessage,
    reward,
    rewardText,
    redeemMessage,
    maxThreshold,
    orderConfirmationHeadingText,
    orderConfirmationTitleText,
    orderConfirmationContent,
  } = customVars;

  let totalReward = reward;
  let remaining = Math.ceil(threshold - subTotal);
  let currentThreshold = threshold;

  const maxPossiblethresholdsReached = maxThreshold / threshold;
  const thresholdReached =
    subTotal < maxThreshold
      ? Math.floor(subTotal / threshold)
      : maxPossiblethresholdsReached;

  if (thresholdReached <= 0 && (shouldHideProgress || context.checkout)) {
    return null;
  }

  if (thresholdReached > 0) {
    totalReward *= thresholdReached;
    currentThreshold = threshold * thresholdReached;
    remaining = Math.ceil(currentThreshold - subTotal);
  }

  const isGoalReached = remaining <= 0;

  /** The id properties supplied in FormattedMessage are fake id's.
   * We are getting back the text from an asset
   * but want to format it like a FormattedMessage, so we rely on
   * the defaultMessage to give us the correct value
   * (and need to supply a fake id to make this work)
   */
  const ProgressMessage = () => (
    <Message>
      <DynamicFormattedMessage
        id="__progress_message__"
        defaultMessage={goalMessage}
        values={{
          amount: (
            <Promo>
              <Price amount={remaining > 0 ? remaining : 0} />
            </Promo>
          ),
          reward: (
            <Promo>
              <Price amount={totalReward} />
            </Promo>
          ),
          rewardText: <Promo>{rewardText}</Promo>,
        }}
      />
    </Message>
  );

  const SuccessMessage = props => (
    <>
      <Message {...props}>
        <DynamicFormattedMessage
          id="__success_message__"
          defaultMessage={successMessage}
          values={{
            reward: (
              <Promo>
                <Price amount={totalReward} />
              </Promo>
            ),
            rewardText: <Promo>{rewardText}</Promo>,
          }}
        />
      </Message>
      <RedeemMessage>
        <DynamicFormattedMessage
          id="__redeem_message__"
          defaultMessage={redeemMessage}
          values={{
            reward: <Price amount={totalReward} />,
            terms: shouldShowTerms ? (
              <Button onClick={toggleTerms} type="button">
                <FormattedMessage
                  id="global_checkout.bounceback_terms_button"
                  defaultMessage="Terms"
                />
              </Button>
            ) : null,
          }}
        />
      </RedeemMessage>
      <BouncebackTermsModal isOpen={isTermsOpen} closeModal={toggleTerms} />
    </>
  );

  if (shouldHideProgress) {
    return thresholdReached > 0 ? (
      <Wrapper className={className}>
        <SuccessMessage fontSize="14" marginBottom="4" />
      </Wrapper>
    ) : null;
  }

  if (context.checkout) {
    return (
      <>
        <OrderConfirmationBounceBackWrapper
          imageUrl={imageFilename}
          mobileImgUrl={mobileImageFilename}
        >
          <StyledTitle>
            <DynamicFormattedMessage
              id="__bounceback_order_confirmation_title__"
              defaultMessage={orderConfirmationTitleText}
            />
          </StyledTitle>
          <StyledHeading>
            <DynamicFormattedMessage
              id="__bounceback_order_confirmation_heading__"
              defaultMessage={orderConfirmationHeadingText}
              values={{
                reward: <Price amount={totalReward} />,
              }}
            />
          </StyledHeading>
          <StyledContent>
            <DynamicFormattedMessage
              id="__bounceback_order_confirmation_content__"
              defaultMessage={orderConfirmationContent}
              values={{
                terms: (
                  <Button onClick={toggleTerms} type="button">
                    <FormattedMessage
                      id="global_checkout.bounceback_terms_button"
                      defaultMessage="Terms"
                    />
                  </Button>
                ),
              }}
            />
          </StyledContent>
        </OrderConfirmationBounceBackWrapper>
        <BouncebackTermsModal isOpen={isTermsOpen} closeModal={toggleTerms} />
      </>
    );
  }

  return (
    <Wrapper className={className}>
      <PromotionProgressBar
        promoType="bounceback"
        isGoalReached={isGoalReached}
        prevTotal={prevCartTotal}
        promoThreshold={currentThreshold}
        total={subTotal}
        promoProgressMessage={<ProgressMessage />}
        promoGoalMessage={
          <>
            <BouncebackIcon />
            <SuccessMessage />
          </>
        }
        shouldAnimateOnThreshold
        shouldShowProgressBar
      />
    </Wrapper>
  );
};

Bounceback.propTypes = {
  shouldHideProgress: PropTypes.bool,
  prevCartTotal: PropTypes.number,
  className: PropTypes.string,
  shouldShowTerms: PropTypes.bool,
  cartSubTotal: PropTypes.number,
  context: PropTypes.object,
};

Bounceback.defaultProps = {
  shouldHideProgress: false,
  shouldShowTerms: false,
  prevCartTotal: 0,
  cartSubTotal: 0,
};

Bounceback.Message = Message;
Bounceback.RedeemMessage = RedeemMessage;

export default withTheme(Bounceback);
