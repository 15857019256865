import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { useAccountActions } from '@techstyle/react-accounts';
import { useDomain, useSession } from '@techstyle/redux-core';

const CpraLocationContext = createContext();

export const useCpraLocation = () => useContext(CpraLocationContext);

const getInitialLoadingState = statesToCheck => {
  const loadingState = {};

  statesToCheck.forEach(state => {
    loadingState[state] = {
      isUpToDate: false,
      isLoading: false,
    };
  });

  return loadingState;
};

const defaultStatesToCheck = ['CA', 'CO', 'CT'];
const emptyStatesToCheck = [];

// TODO: Replace this once we have a better way to determine what state a user is from.
// This is a temporary workaround for determining if a user should be allowed
// to view CPRA content (e.g. footer link and CPRA page).
// Currently we only have a single endpoint to check if a user is from a specific state,
// this will call that endpoint for each state in the `statesToCheck` prop and store
// the result in Context. Because our current data fetching implementation does not cache
// results for these types of requests, this is required so we don't call the endpoint
// too many times and so we can display a loading state while we wait for the results.
const CpraLocation = ({
  children,
  statesToCheck: statesToCheckFromProps = defaultStatesToCheck,
  isCpraCheckEnabled: isCpraCheckEnabledFromProps = false,
}) => {
  const { tld } = useDomain();
  const isUS = tld === '.com';
  const statesToCheck = isUS ? statesToCheckFromProps : emptyStatesToCheck;
  const [isCpraCheckEnabled, setCpraCheckEnabled] = useState(
    isCpraCheckEnabledFromProps
  );
  const [statesChecked, setStatesChecked] = useState({});
  const loadingStates = useRef(getInitialLoadingState(statesToCheck));
  const [loading, setLoading] = useState(isUS);
  const { getIsMemberFromState } = useAccountActions();
  const { isLoggedIn } = useSession();
  const prevIsLoggedIn = useRef(isLoggedIn);
  const router = useRouter();

  // we need to use asPath since pathname could be from builder (/builder/[builder])
  const pathname = router.asPath?.split('?')[0] || null;
  useEffect(() => {
    if (pathname === '/cpra') {
      setCpraCheckEnabled(true);
    }
  }, [router.pathname]);

  const enableCpraCheck = useCallback(() => {
    setCpraCheckEnabled(true);
  }, []);

  useEffect(() => {
    if (isCpraCheckEnabledFromProps) {
      enableCpraCheck();
    }
  }, [enableCpraCheck, isCpraCheckEnabledFromProps]);

  const checkState = useCallback(
    async state => {
      if (
        loadingStates.current[state]?.isLoading ||
        loadingStates.current[state]?.isUpToDate
      ) {
        return Promise.resolve();
      }

      loadingStates.current[state] = {
        ...loadingStates.current[state],
        isLoading: true,
      };

      const { payload } = await getIsMemberFromState(state);

      setStatesChecked(prevStatesChecked => {
        return {
          ...prevStatesChecked,
          [state]: payload,
        };
      });

      loadingStates.current[state] = {
        isLoading: false,
        isUpToDate: true,
      };

      return Promise.resolve();
    },
    [getIsMemberFromState]
  );

  useEffect(() => {
    if (prevIsLoggedIn.current !== isLoggedIn) {
      prevIsLoggedIn.current = isLoggedIn;
      loadingStates.current = getInitialLoadingState(statesToCheck);
    }

    const checkCurrentStates = async () => {
      setLoading(true);
      await Promise.all(statesToCheck.map(state => checkState(state)));
      setLoading(false);
    };

    if (isCpraCheckEnabled) {
      checkCurrentStates();
    }
  }, [statesToCheck, checkState, isLoggedIn, isCpraCheckEnabled]);

  const shouldShowCpra = useMemo(() => {
    return statesToCheck.some(state => statesChecked[state]);
  }, [statesChecked, statesToCheck]);

  const contextValue = useMemo(() => {
    return {
      enableCpraCheck,
      shouldShowCpra,
      statesChecked,
      loading,
    };
  }, [enableCpraCheck, loading, shouldShowCpra, statesChecked]);

  return (
    <CpraLocationContext.Provider value={contextValue}>
      {children}
    </CpraLocationContext.Provider>
  );
};

CpraLocation.propTypes = {
  children: PropTypes.node.isRequired,
  statesToCheck: PropTypes.arrayOf(PropTypes.string),
  isCpraCheckEnabled: PropTypes.bool,
};

export default CpraLocation;
