import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import styled, { withTheme } from 'styled-components';

import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { useFeature } from '@techstyle/react-features';
import { FormattedMessage, useIntl } from '@techstyle/react-intl';
import { useSession } from '@techstyle/redux-core';

import { toggleSignInModal } from '../../actions/auth';
import { loadCartItemCount } from '../../actions/checkout';
import { NavBarBGColors } from '../../constants/navBarBGColors';
import { FORGOT_PASSWORD } from '../../constants/signIn';
import { desktop } from '../../styles';
import {
  handleNonHighlightedEnter,
  handleHighlightedItemEnter,
} from '../../utils/search';
import { v1, v2 } from '../../utils/themeVersioning';
import { useCheckIfPasswordlessLogin } from '../../utils/useCheckIfPasswordlessLogin';
import { Component as AccountLinks } from '../AccountLinks';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import CartLinkNav from '../CartLinkNav/CartLinkNav';
import { Component as MemberCreditsDesktopNavItems } from '../MemberCreditsDesktopNavItems';
import { Component as MemberCreditsNav } from '../MemberCreditsNav';
import { Component as OnClickOutside } from '../OnClickOutside';
import SearchField from '../SearchField/SearchField';
import SearchProvider from '../SearchProvider/SearchProvider';
import SearchToggle from '../SearchToggle/SearchToggle';
import ShoppingBagNav from '../ShoppingBagNav/ShoppingBagNav';

import { MemberCreditsDesktopButton } from './MemberCreditsDesktopButton';

const Wrapper = styled.nav`
  z-index: ${props => props.theme.zIndex.navItems};
  flex: 1;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  height: 70px;
  color: ${({ theme }) =>
    theme.context.backgroundState === NavBarBGColors.TRANSPARENT
      ? theme.colors.white
      : theme.colors.default};
  ${v2`gap: 20px`}
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    margin-left: 2px;
  }

  ${v2`
    height: 30px;
    align-items: center;
    justify-content: flex-start;
  `}
`;

const StyledItem = styled(Item)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: initial;
  ${desktop`
    flex: 1;
    margin-left: 0;
  `}
`;

const SearchWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  ${v1`
      @media screen and (min-width: ${props =>
        props.theme.breakpoints.xlarge}px) {
    ${props =>
      props.showNewNav
        ? `
        width: ${props.extended ? '100%' : '70px'};
        `
        : `width: ${props.extended ? '100%' : '145px'};`}
  }
    `}

  ${v2`
        @media screen and (min-width: ${props =>
          props.theme.breakpoints.xlarge}px) {
    ${props => `
        width: ${props.extended ? '100%' : ''};
        `}
  }
      `}
`;

const StyledSearchToggle = styled(SearchToggle)`
  ${v1`
    width: 44px;
    `}
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: none;
  }
`;

const SearchFieldContainer = styled.div`
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.xlarge - 1}px) {
    display: none;
  }
  ${v1`
      margin: ${({ theme }) => theme.spacing.tiny}px 0 0
    ${({ theme }) => theme.spacing.small}px;
    `}

  ${v2`
      padding-left: 40px;
      `}
`;

const NewSearchFieldWrapper = styled(SearchFieldContainer)`
  ${v1`
    margin-right: ${({ theme }) => theme.spacing.small}px;
  `}
`;

const PopupContentContainer = styled.div`
  max-height: calc(100vh - 66px);
  overflow-y: auto;
`;

const PopupContainerWrapper = styled.div`
  filter: drop-shadow(0px 0px 3px ${props => props.theme.colors.lightShadow3});
  position: absolute;
  right: 0;
  top: 0;
  ${v2`
    position: fixed;
  `}
`;

const PopupContainer = styled.div`
  background: ${props => props.theme.colors.gray150};
  min-width: 250px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.lavender250};
  line-height: 1;
  cursor: auto;
  overflow: hidden;
  transform-origin: top right;
  opacity: 0;
  transition: 0.2s all ease-in-out;

  position: absolute;
  top: 66px;
  right: 0;

  clip-path: inset(0 0 100% 100%);

  ${props =>
    props.transitionState === 'entering' ||
    (props.transitionState === 'entered' &&
      `
    opacity: 1;
    clip-path: inset(0 0 0 0);
  `)}

  ${props =>
    props.transitionState === 'exiting' ||
    (props.transitionState === 'exited' &&
      `
    opacity: 0;
  `)}

  ${v2`
      min-width: 375px;
      border-radius: 0;
      border: 1px solid ${props => props.theme.colors.black};
      top: 60px;
  `}
`;

const HowItWorksLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  text-align: left;
  color: ${({ theme }) => theme.colors.promo};
  width: 200px;
`;

const XLogoIcon = styled.img.attrs({
  src: '/static/images/x-logo-lavender.svg',
})`
  width: 23px;
  margin-right: 4px;
`;

const HowItWorksItem = styled(StyledItem)`
  flex: unset;
`;

const SignInButton = styled.button`
  ${v1`
    display: block;
    padding: 0 ${props => props.theme.spacing.small}px;
    height: 100%;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 78px;
  `}

  ${v2`
    ${props => props.theme.links.variants.navbarLink}
  `}
`;

const StyledAccountLinks = styled(AccountLinks)`
  ${AccountLinks.List} {
    background: ${props => props.theme.colors.gray150};
    border-radius: 0px 0px 8px 8px;
    ${v2`
      background: ${props => props.theme.colors.white};
      border-radius: 0;
      padding: 0px ${props => props.theme.spacing.tiny}px;
    `}
  }

  ${AccountLinks.ListItem} {
    text-align: left;
    background: ${props => props.theme.colors.white};
    margin-bottom: 2px;
    letter-spacing: 0.3px;
    ${v2`
      margin-bottom: 0;
      border-bottom: 1px solid ${props => props.theme.colors.marble};
      ${({ theme }) => theme.paragraph.variants.paragraph4.textStyles}
    `}
  }

  ${AccountLinks.StyledLink},
  ${AccountLinks.StyledLogOutButton} {
    display: block;
    width: 100%;
    height: 100%;
    padding: 12px ${props => props.theme.spacing.small}px;
    text-align: left;
    ${v2`
      height: 40px;
      padding: 12px ${props => props.theme.spacing.tiny}px;
    `}
  }
`;

function NavToolBarDesktop({ className, showAccountModal, theme }) {
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const { matches: isTablet } = useBreakpoint(
    `(min-width: ${theme.breakpoints.large}px) and (max-width: ${
      theme.breakpoints.xlarge - 1
    }px)`
  );
  const intl = useIntl();
  const { themeVersion } = useTheme();
  const dispatch = useDispatch();
  const { isVisitor } = useSession();
  const { isBorderfreeCustomer } = useSelector(state => state.borderfree);
  const { isEnabled: isNewDesktopSearchNavEnabled } =
    useFeature('desktop_nav_update');
  const { isActive: isSearchActive } = useContext(SearchProvider.Context);
  const [isSearchBarExpanded, setIsSearchBarExpanded] =
    useState(isSearchActive);
  const [memberCredits, setMemberCredits] = useState(0);
  const handleSetMemberCredits = useCallback(
    value => setMemberCredits(value),
    []
  );

  const { isPasswordlessLogin } = useCheckIfPasswordlessLogin();

  const showNewNav =
    isNewDesktopSearchNavEnabled && !isMobile && !isBorderfreeCustomer;
  const [isOpen, setIsOpen] = useState(false);

  const isNewTheme = themeVersion === 'v2';

  const handleTransitionEnd = useCallback(() => {
    setIsSearchBarExpanded(isSearchActive);
  }, [isSearchActive]);

  const handleUserMenuButtonClick = useCallback(() => {
    if (isPasswordlessLogin) {
      dispatch(toggleSignInModal(true, FORGOT_PASSWORD));
    } else {
      setIsOpen(isOpen => !isOpen);
    }
  }, [dispatch, isPasswordlessLogin]);

  useEffect(() => {
    if (loadCartItemCount) {
      dispatch(loadCartItemCount({ ifNotFetched: true }));
    }
  }, [dispatch]);

  const pushUserToHIWPage = useCallback(
    event => {
      event.preventDefault();
      router.push('/xtrasavage');
    },
    [router]
  );

  const renderAccountItem = useCallback(() => {
    if (!isVisitor && !isMobile) {
      return (
        <Item accountItem data-autotag="my-account">
          <OnClickOutside setIsOpen={setIsOpen}>
            <MemberCreditsDesktopButton
              aria-label={
                memberCredits > 0
                  ? intl.formatMessage(
                      {
                        id: 'site_navigation.account_membership_emp_credits',
                        defaultMessage: `Account Links, {memberCredits} member credits available`,
                      },
                      { memberCredits }
                    )
                  : intl.formatMessage({
                      id: 'site_navigation.account_information_button',
                      defaultMessage: `Account Links`,
                    })
              }
              isOpen={isOpen}
              onClick={handleUserMenuButtonClick}
              showCaret={!isPasswordlessLogin}
            >
              <MemberCreditsNav setMemberCredits={handleSetMemberCredits} />
            </MemberCreditsDesktopButton>
            <Transition in={isOpen} appear={isOpen} mountOnEnter>
              {state => (
                <PopupContainerWrapper>
                  <PopupContainer transitionState={state}>
                    <PopupContentContainer>
                      <MemberCreditsDesktopNavItems />
                      <StyledAccountLinks small isNavToolbar />
                    </PopupContentContainer>
                  </PopupContainer>
                </PopupContainerWrapper>
              )}
            </Transition>
          </OnClickOutside>
        </Item>
      );
    }

    return (
      <Item>
        <SignInButton onClick={showAccountModal} data-autotag="nav-sign-in">
          {isNewTheme ? (
            <FormattedMessage
              defaultMessage="Log In"
              id="site_login.rebrand_sign_in"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Sign in"
              id="site_login.sign_in"
            />
          )}
        </SignInButton>
      </Item>
    );
  }, [
    handleSetMemberCredits,
    handleUserMenuButtonClick,
    intl,
    isMobile,
    isNewTheme,
    isOpen,
    isPasswordlessLogin,
    isVisitor,
    memberCredits,
    showAccountModal,
  ]);

  const renderSearchField = useCallback(() => {
    return (
      <SearchField
        data-qa-automation="srchInputTxb"
        isNewDesktopSearchNavEnabled={isNewDesktopSearchNavEnabled}
        isSearchBarExpanded={isSearchBarExpanded}
        onNonHighlightedEnter={handleNonHighlightedEnter}
        onHighlightedItemEnter={handleHighlightedItemEnter}
      />
    );
  }, [isNewDesktopSearchNavEnabled, isSearchBarExpanded]);

  return (
    <Wrapper className={className}>
      <List>
        <StyledItem>
          <SearchWrapper
            extended={isSearchActive}
            onTransitionEnd={handleTransitionEnd}
            showNewNav={showNewNav}
          >
            <StyledSearchToggle
              extended={isSearchBarExpanded}
              data-qa-automation="srchHomeSearchButton"
            />
            {showNewNav ? (
              <NewSearchFieldWrapper>
                {renderSearchField()}
              </NewSearchFieldWrapper>
            ) : (
              <SearchFieldContainer>{renderSearchField()}</SearchFieldContainer>
            )}
          </SearchWrapper>
        </StyledItem>
        {showNewNav && !isTablet && !isNewTheme ? (
          <HowItWorksItem>
            <HowItWorksLink
              data-qa-automation="howItWorksBtn"
              onClick={pushUserToHIWPage}
            >
              <XLogoIcon />
              <FormattedMessage
                defaultMessage="How Membership Works"
                id="site_navigation.savage_xtra_membership_works"
              />
            </HowItWorksLink>
          </HowItWorksItem>
        ) : null}
        <BorderfreeGuard>{renderAccountItem()}</BorderfreeGuard>
        <Item data-autotag="my-cart" data-qa-automation="myCartText">
          {isNewTheme ? <CartLinkNav /> : <ShoppingBagNav />}
        </Item>
      </List>
    </Wrapper>
  );
}

NavToolBarDesktop.propTypes = {
  className: PropTypes.string,
  showAccountModal: PropTypes.func,
};

export default withTheme(NavToolBarDesktop);
