import React from 'react';

import styled from 'styled-components';

import { mobile } from '../../styles';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v1, v2 } from '../../utils/themeVersioning';
import { SearchInput as SearchInputConstructor } from '../SearchInput/SearchInput';
import { SearchInputLegacy } from '../SearchInput/SearchInputLegacy';

const StyledInput = styled.input.attrs(({ size = 1 }) => ({ size }))`
  display: flex;
  flex: 1;
  width: 100%;
  border: none;

  &:focus {
    outline: none;
  }

  ${v1`
      font-size: 16px;
      line-height: 2.14;

      padding: 0 4px;
      font-family: inherit;

      &::placeholder {
        color: ${props => props.theme.colors.subdued};
      }
    `}

  ${v2`
        ${props => props.theme.paragraph.variants.paragraph2.textStyles}
          color: ${props => props.theme.colors.grey};
          visibility: ${props => (props.isSearchActive ? 'visible' : 'hidden')};
          position: ${props =>
            props.isSearchActive ? 'relative' : 'absolute'};
      `}


  -webkit-appearance: textfield;
  /*
    see ya later webkit cancel button
    we want our own
  */

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    ${v1`
        font-size: 14px;

        &::placeholder {
          color: ${props =>
            props.isSearchActive
              ? props => props.theme.colors.subdued
              : props.theme.colors.default};
        font-weight: ${props => (props.isSearchActive ? 400 : 500)};
      }

      font-size: 12px;
      color: ${props => props.theme.colors.subdued};
      background-color: ${props =>
        props.shouldShowPlaceHolder ? props.theme.colors.lavender100 : 'unset'};
    `}

    ${v2`
      ${props => props.theme.paragraph.variants.paragraph3.textStyles}
      color: ${props => props.theme.colors.grey}

      &::placeholder {
        ${props => props.theme.paragraph.variants.paragraph3.textStyles}
        color: ${props => props.theme.colors.grey}
      }
    `}

    margin-right: 0;
  }

  /* Removes focus-visible outline since we're using a custom focus indicator for Search Field */

  &:focus-visible {
    outline: none !important;
  }

  ${mobile`

    ${v1`
      background-color: ${props => props.theme.colors.lavender100};
    `}
  `}
`;

const SearchInputField = () => {
  const { 'constructor-search': constructorSearchEnabled } = useLDFlags();

  const SearchInput = constructorSearchEnabled
    ? SearchInputConstructor
    : SearchInputLegacy;

  return (
    <SearchInput.Context.Consumer>
      {({
        isSearchActive,
        getInputProps,
        handleDownshiftKeyDown,
        highlightedIndex,
        innerRef,
        onBlur,
        onFocus,
        placeholder,
        shouldShowPlaceHolder,
      }) => (
        <StyledInput
          isSearchActive={isSearchActive}
          {...(constructorSearchEnabled
            ? { 'data-cnstrc-search-input': true }
            : {})}
          {...getInputProps({
            onKeyDown: event => handleDownshiftKeyDown(event, highlightedIndex),
            type: 'search',
            ref: innerRef,
            onBlur,
            onFocus,
            placeholder,
            shouldShowPlaceHolder,
          })}
        />
      )}
    </SearchInput.Context.Consumer>
  );
};

export default SearchInputField;
