import { useSelector } from 'react-redux';

import { useLDFlags } from './LD/useLDFlags';
import { isNMP } from './selectors';

/**
 * This function will return true or false depending if the autobundle2 feature is allowed
 * @returns {boolean}
 */
export function useAutoBundle2() {
  const { 'auto-bundle-2': autoBundle2, 'token-to-cash': tokenToCashEnabled } =
    useLDFlags();

  const userIsNMP = useSelector(isNMP);

  return tokenToCashEnabled ? autoBundle2 && !userIsNMP : autoBundle2;
}
