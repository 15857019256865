import React from 'react';

import { string, object } from 'prop-types';
import styled from 'styled-components';

import { v1, v2 } from '../../utils/themeVersioning';
import { Component as StaticIcon } from '../StaticIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  ${v1`
    background-color: ${props => props.theme.colors.green200};
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.green700};
    font-size: 12px;
    padding: 4px ${({ theme }) => theme.spacing.tiny}px;
  `}
  ${v2`
    padding: 2px 8px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

const TextWrapper = styled.span`
  font-size: 12px;
`;

const StyledIcon = styled(StaticIcon)`
  height: 10px;
  margin-right: 6px;
  width: 10px;
`;

const ProductFlagIconMap = {
  shopItEarly: 'shoppingBag',
  unlockedExclusive: 'unlock',
};

const ProductFlag = ({ className, children, productTag }) => {
  const iconType = ProductFlagIconMap[productTag];

  return (
    <Wrapper className={className}>
      {iconType ? <StyledIcon type={iconType} size={40} /> : null}
      <TextWrapper>{children}</TextWrapper>
    </Wrapper>
  );
};

ProductFlag.propTypes = {
  className: string,
  children: object,
  productTag: string,
};

ProductFlag.Wrapper = Wrapper;

export default ProductFlag;
