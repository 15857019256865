import React, { useState } from 'react';

import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { useMembership } from '@techstyle/react-accounts';
import { HTMLAsset, useAsset } from '@techstyle/react-assets';
import { FormattedMessage } from '@techstyle/react-intl';

import { trackEmpInfoDrawerDiscoverPerksMore } from '../../actions/membership';
import { getBirthdayDetails } from '../../utils/selectors';
import useBirthdayPromo from '../../utils/useBirthdayPromo';
import { Component as StaticIcon } from '../StaticIcon';

const PerksNotificationBanner = styled.div`
  display: flex;
  place-items: center;
  position: relative;
  background: ${props => props.theme.colors.lavenderGradient};
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 2px 6px ${props => props.theme.colors.lightShadow3};
  padding: ${props => props.theme.spacing.tiny}px
    ${props => props.theme.spacing.small}px;
  margin: ${props => props.theme.spacing.small}px 0px;
`;

const PerksNotificationSubheader = styled.p`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  margin-bottom: 2px;

  ${props =>
    props.isCaps &&
    `
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 12px;
      text-transform: uppercase;
      margin-top: 2px;
      margin-bottom: 3px;
  `}
`;

const PerksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: ${props => props.theme.spacing.moderate}px;
  ${props =>
    props.isBannerDisplayed &&
    `
    padding: ${props.theme.spacing.small}px ${props.theme.spacing.moderate}px;
  `}
`;

const PerksCopy = styled.div`
  display: flex;
  flex-direction: column;
`;

const PerkContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
  ${props => props.order && `order: ${props.order};`}
`;

const PerkIconWrapper = styled.div`
  width: 58px;
  height: 58px;
  padding-right: ${props => props.theme.spacing.small}px;
  text-align: right;
`;

const PerkIcon = styled.img.attrs(props => ({ alt: '' }))`
  max-width: 42px;
`;

const PerkTextContainer = styled.div`
  flex: 1;
`;

const PerkHeadline = styled.h6`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.3px;
`;

const PerkSubheadline = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.3px;
`;

const PerksCTAButton = styled.a`
  border-radius: 24px;
  border: 2px solid ${props => props.theme.colors.default};
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  padding: 12px 24px;
  text-align: center;
  align-self: flex-start;
  margin: 0 auto;
  color: ${props => props.theme.colors.default};
`;

const PerksNotificationTextWrapper = styled.div`
  flex: 1;
`;

const PerksNotificationHeader = styled.h3`
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 700;
`;

const CopyIcon = styled(StaticIcon).attrs({
  type: 'copy',
  size: 17,
})``;

const CheckIcon = styled(StaticIcon).attrs({
  type: 'check',
  size: 10,
})`
  margin: -2px 3px 0;
`;

const copyAnimated = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-80px);
  }
  15% {
    transform: translateX(-75px);
  }
  85% {
    transform: translateX(-75px);
  }
  90% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0);
  }
`;

const CopiedBadgeWrapper = styled.div`
  overflow: hidden;
  position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const CopiedBadge = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  width: 75px;
  height: 17px;
  background: ${props => props.theme.colors.default};
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  position: absolute;
  right: -75px;
  top: 50%;
  bottom: 50%;
  margin: auto 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  ${props =>
    props.isCopied &&
    css`
      animation: ${copyAnimated} 1.4s linear normal forwards;
    `}
`;

const CopyButton = styled.button`
  text-align: left;
`;

const CopiedTextWrapper = styled.span`
  margin-top: -2px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const LinkWrapper = styled.a``;

const StyledRightChevron = styled(StaticIcon).attrs({
  type: 'caret',
  size: 8,
})`
  transform: rotate(270deg);
  margin: auto 0;
`;

const WineGlasses = styled(StaticIcon).attrs({
  type: 'wineGlasses',
  size: 30,
})`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

function MemberCreditsDrawerModalPerks({ closeDrawer }) {
  const dispatch = useDispatch();
  const { isVip } = useMembership();
  const { isBirthMonth, hasBirthday } = useSelector(getBirthdayDetails);
  const [isCopied, setIsCopied] = useState(false);

  const perksAssetData = useAsset('emp_info_drawer_perks', { multi: true });
  const perksAssets =
    (perksAssetData && perksAssetData.data && perksAssetData.data.assets) || [];

  const {
    isBirthdayPromoReady,
    hasUsedBirthdayPromo,
    promoCode,
    discountPercent,
  } = useBirthdayPromo();

  const shouldShowBirthdayBanner =
    isBirthMonth &&
    isBirthdayPromoReady &&
    !hasUsedBirthdayPromo &&
    discountPercent &&
    promoCode;

  const handleCopyBirthdayPromoCode = () => {
    copy(promoCode);
    setIsCopied(true);
  };

  const handleTellMeMoreClick = () => {
    dispatch(trackEmpInfoDrawerDiscoverPerksMore());
  };

  return (
    <PerksContainer
      isBannerDisplayed={shouldShowBirthdayBanner || !hasBirthday}
    >
      {shouldShowBirthdayBanner ? (
        <CopyButton onClick={handleCopyBirthdayPromoCode}>
          <PerksNotificationBanner>
            <PerksNotificationTextWrapper>
              <PerksNotificationHeader>
                <FormattedMessage
                  id="site_navigation.emp_discover_perks_birthday_header"
                  defaultMessage="For Your Birthday! {discountPercent}% Off!"
                  values={{
                    discountPercent,
                  }}
                />
              </PerksNotificationHeader>
              <PerksNotificationSubheader isCaps>
                <FormattedMessage
                  id="site_navigation.emp_discover_perks_birthday_subheader"
                  defaultMessage="Use code {promoCode} at checkout"
                  values={{
                    promoCode: <Bold>{promoCode}</Bold>,
                  }}
                />
              </PerksNotificationSubheader>
            </PerksNotificationTextWrapper>
            <CopyIcon />
            <CopiedBadgeWrapper>
              <CopiedBadge
                isCopied={isCopied}
                onAnimationEnd={() => setIsCopied(false)}
              >
                <CheckIcon />
                <CopiedTextWrapper>
                  <FormattedMessage
                    defaultMessage="Copied"
                    id="site_home.email_opt_copied_promo"
                  />
                </CopiedTextWrapper>
              </CopiedBadge>
            </CopiedBadgeWrapper>
          </PerksNotificationBanner>
        </CopyButton>
      ) : null}

      {!hasBirthday ? (
        <Link
          to={`${
            isVip
              ? '/account/membership?scroll-to=birthmonth-input'
              : '/account/profile?scroll-to=birthmonth-input'
          }`}
          scroll={false}
        >
          <LinkWrapper onClick={closeDrawer}>
            <PerksNotificationBanner>
              <WineGlasses />
              <PerksNotificationTextWrapper>
                <PerksNotificationHeader>
                  <FormattedMessage
                    id="site_navigation.emp_discover_perks_when_birthday_header"
                    defaultMessage="When's your birthday, Boo?"
                    values={{
                      discountPercent: 20,
                    }}
                  />
                </PerksNotificationHeader>
                <PerksNotificationSubheader>
                  <FormattedMessage
                    id="site_navigation.emp_discover_perks_when_birthday_text"
                    defaultMessage="Xtra VIPs enjoy a yearly birthday surprise!"
                  />
                </PerksNotificationSubheader>
              </PerksNotificationTextWrapper>
              <StyledRightChevron />
            </PerksNotificationBanner>
          </LinkWrapper>
        </Link>
      ) : null}

      <PerksCopy>
        {perksAssets.map(asset => (
          <PerkContainer key={asset.id} order={asset.options.customVars.order}>
            <PerkIconWrapper>
              <PerkIcon src={asset.imageFilename} />
            </PerkIconWrapper>
            <PerkTextContainer>
              <PerkHeadline>
                <HTMLAsset asset={asset} />
              </PerkHeadline>
              <PerkSubheadline
                dangerouslySetInnerHTML={{ __html: asset.options.hoverText }}
              />
            </PerkTextContainer>
          </PerkContainer>
        ))}
      </PerksCopy>
      <Link to="/xtrasavage">
        <PerksCTAButton
          onClick={handleTellMeMoreClick}
          data-qa-automation="tellMeMoreBtn"
        >
          <FormattedMessage
            id="site_navigation.emp_discover_perks_cta"
            defaultMessage="Tell me more!"
          />
        </PerksCTAButton>
      </Link>
    </PerksContainer>
  );
}

MemberCreditsDrawerModalPerks.propTypes = {
  closeDrawer: PropTypes.func,
};

export default MemberCreditsDrawerModalPerks;
