export default {
  '.co.uk': {
    men: {
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      XXL: '2XL',
      XXXL: '3XL',
    },
    slippers: {
      S: '4/5',
      M: '6/7',
      L: '8',
      XL: '9/10',
    },
    regular: {
      '30A': '30A',
      '30B': '30B',
      '30C': '30C',
      '30D': '30D',
      '32A': '32A',
      '32B': '32B',
      '32C': '32C',
      '32D': '32D',
      '32DD': '32DD',
      '32DDD': '32E',
      '34A': '34A',
      '34B': '34B',
      '34C': '34C',
      '34D': '34D',
      '34DD': '34DD',
      '34DDD': '34E',
      '36A': '36A',
      '36B': '36B',
      '36C': '36C',
      '36D': '36D',
      '36DD': '36DD',
      '36DDD': '36E',
      '38B': '38B',
      '38C': '38C',
      '38D': '38D',
      '38DD': '38DD',
      '38DDD': '38E',
      '38G': '38G',
      '38H': '38H',
      '40C': '40C',
      '40D': '40D',
      '40DD': '40DD',
      '40DDD': '40E',
      '40G': '40F',
      '40H': '40G',
      '42C': '42C',
      '42D': '42D',
      '42DD': '42DD',
      '42DDD': '42E',
      '42G': '42F',
      '42H': '42G',
      '44D': '44D',
      '44DD': '44DD',
      '44DDD': '44E',
      '44G': '44G',
      '46D': '46D',
      '46DD': '46DD',
      '46DDD': '46E',
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      '1X': '2XL',
      '2X': '3XL',
      '3X': '4XL',
      '4X': '5XL',
    },
    adhesiveBras: {
      DDD: 'E',
    },
  },
  '.de': {
    men: {
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      XXL: '2XL',
      XXXL: '3XL',
    },
    slippers: {
      S: '37/38',
      M: '39/40',
      L: '41',
      XL: '42/43',
    },
    regular: {
      '30A': '65A',
      '30B': '65B',
      '30C': '65C',
      '30D': '65D',
      '32A': '70A',
      '32B': '70B',
      '32C': '70C',
      '32D': '70D',
      '32DD': '70E',
      '32DDD': '70F',
      '34A': '75A',
      '34B': '75B',
      '34C': '75C',
      '34D': '75D',
      '34DD': '75E',
      '34DDD': '75F',
      '36A': '80A',
      '36B': '80B',
      '36C': '80C',
      '36D': '80D',
      '36DD': '80E',
      '36DDD': '80F',
      '38B': '85B',
      '38C': '85C',
      '38D': '85D',
      '38DD': '85E',
      '38DDD': '85F',
      '38G': '85G',
      '38H': '85H',
      '40C': '90C',
      '40D': '90D',
      '40DD': '90E',
      '40DDD': '90F',
      '40G': '90G',
      '40H': '90H',
      '42C': '95C',
      '42D': '95D',
      '42DD': '95E',
      '42DDD': '95F',
      '42G': '95G',
      '42H': '95H',
      '44D': '100D',
      '44DD': '100E',
      '44DDD': '100F',
      '44G': '100H',
      '46D': '105D',
      '46DD': '105E',
      '46DDD': '105F',
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      '1X': '2XL',
      '2X': '3XL',
      '3X': '4XL',
      '4X': '5XL',
    },
    adhesiveBras: {
      DD: 'E',
      DDD: 'F',
    },
  },
  '.eu': {
    men: {
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      XXL: '2XL',
      XXXL: '3XL',
    },
    slippers: {
      S: '37/38',
      M: '39/40',
      L: '41',
      XL: '42/43',
    },
    regular: {
      '30A': '65A',
      '30B': '65B',
      '30C': '65C',
      '30D': '65D',
      '32A': '70A',
      '32B': '70B',
      '32C': '70C',
      '32D': '70D',
      '32DD': '70E',
      '32DDD': '70F',
      '34A': '75A',
      '34B': '75B',
      '34C': '75C',
      '34D': '75D',
      '34DD': '75E',
      '34DDD': '75F',
      '36A': '80A',
      '36B': '80B',
      '36C': '80C',
      '36D': '80D',
      '36DD': '80E',
      '36DDD': '80F',
      '38B': '85B',
      '38C': '85C',
      '38D': '85D',
      '38DD': '85E',
      '38DDD': '85F',
      '38G': '85G',
      '38H': '85H',
      '40C': '90C',
      '40D': '90D',
      '40DD': '90E',
      '40DDD': '90F',
      '40G': '90G',
      '40H': '90H',
      '42C': '95C',
      '42D': '95D',
      '42DD': '95E',
      '42DDD': '95F',
      '42G': '95G',
      '42H': '95H',
      '44D': '100D',
      '44DD': '100E',
      '44DDD': '100F',
      '44G': '100H',
      '46D': '105D',
      '46DD': '105E',
      '46DDD': '105F',
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      '1X': '2XL',
      '2X': '3XL',
      '3X': '4XL',
      '4X': '5XL',
    },
    adhesiveBras: {
      DD: 'E',
      DDD: 'F',
    },
  },
  '.fr': {
    men: {
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      XXL: '2XL',
      XXXL: '3XL',
    },
    slippers: {
      S: '37/38',
      M: '39/40',
      L: '41',
      XL: '42/43',
    },
    regular: {
      '30A': '80A',
      '30B': '80B',
      '30C': '80C',
      '30D': '80D',
      '32A': '85A',
      '32B': '85B',
      '32C': '85C',
      '32D': '85D',
      '32DD': '85E',
      '32DDD': '85F',
      '34A': '90A',
      '34B': '90B',
      '34C': '90C',
      '34D': '90D',
      '34DD': '90E',
      '34DDD': '90F',
      '36A': '95A',
      '36B': '95B',
      '36C': '95C',
      '36D': '95D',
      '36DD': '95E',
      '36DDD': '95F',
      '38B': '100B',
      '38C': '100C',
      '38D': '100D',
      '38DD': '100E',
      '38DDD': '100F',
      '38G': '100G',
      '38H': '100H',
      '40C': '105C',
      '40D': '105D',
      '40DD': '105E',
      '40DDD': '105F',
      '40G': '105G',
      '40H': '105H',
      '42C': '110C',
      '42D': '110D',
      '42DD': '110E',
      '42DDD': '110F',
      '42G': '110G',
      '42H': '110H',
      '44D': '115D',
      '44DD': '115E',
      '44DDD': '115F',
      '44G': '115H',
      '46D': '120D',
      '46DD': '120E',
      '46DDD': '120F',
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      '1X': '2XL',
      '2X': '3XL',
      '3X': '4XL',
      '4X': '5XL',
    },
    adhesiveBras: {
      DD: 'E',
      DDD: 'F',
    },
  },
  '.es': {
    men: {
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      XXL: '2XL',
      XXXL: '3XL',
    },
    slippers: {
      S: '37/38',
      M: '39/40',
      L: '41',
      XL: '42/43',
    },
    regular: {
      '30A': '80A',
      '30B': '80B',
      '30C': '80C',
      '30D': '80D',
      '32A': '85A',
      '32B': '85B',
      '32C': '85C',
      '32D': '85D',
      '32DD': '85E',
      '32DDD': '85F',
      '34A': '90A',
      '34B': '90B',
      '34C': '90C',
      '34D': '90D',
      '34DD': '90E',
      '34DDD': '90F',
      '36A': '95A',
      '36B': '95B',
      '36C': '95C',
      '36D': '95D',
      '36DD': '95E',
      '36DDD': '95F',
      '38B': '100B',
      '38C': '100C',
      '38D': '100D',
      '38DD': '100E',
      '38DDD': '100F',
      '38G': '100G',
      '38H': '100H',
      '40C': '105C',
      '40D': '105D',
      '40DD': '105E',
      '40DDD': '105F',
      '40G': '105G',
      '40H': '105H',
      '42C': '110C',
      '42D': '110D',
      '42DD': '110E',
      '42DDD': '110F',
      '42G': '110G',
      '42H': '110H',
      '44D': '115D',
      '44DD': '115E',
      '44DDD': '115F',
      '44G': '115H',
      '46D': '120D',
      '46DD': '120E',
      '46DDD': '120F',
      XS: 'XS',
      S: 'S',
      M: 'M',
      L: 'L',
      XL: 'XL',
      '1X': '2XL',
      '2X': '3XL',
      '3X': '4XL',
      '4X': '5XL',
    },
    adhesiveBras: {
      DD: 'E',
      DDD: 'F',
    },
  },
};
