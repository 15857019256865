import React from 'react';

import styled from 'styled-components';

import { mobile } from '../../styles';
import { Component as CloseButton } from '../CloseButton';

const StyledCloseButton = styled(CloseButton)`
  /**
    1. When Modal is scrolling, the CloseButton should stay in place.
   */
  ${mobile`
    position: sticky; /* 1 */
    top: auto;
    left: 0;
    right: 0;
    bottom: ${props => props.theme.spacing.moderate}px;

    height: 70px;
    margin: 0 auto;
    width: 70px;

    background: ${props => props.theme.colors.lavender400};
    border-radius: 100%;
    color: ${props => props.theme.colors.default};
    outline: none;
  `}
`;

export default class ModalCloseButton extends React.PureComponent {
  static propTypes = {
    ...CloseButton.propTypes,
  };

  static defaultProps = {
    iconSize: 24,
  };

  render() {
    return <StyledCloseButton {...this.props} />;
  }
}
