export const CHECK_IS_PASSWORDLESS_LOGIN_REQUEST =
  'CHECK_IS_PASSWORDLESS_LOGIN_REQUEST';
export const CHECK_IS_PASSWORDLESS_LOGIN_SUCCESS =
  'CHECK_IS_PASSWORDLESS_LOGIN_SUCCESS';
export const CHECK_IS_PASSWORDLESS_LOGIN_FAILURE =
  'CHECK_IS_PASSWORDLESS_LOGIN_FAILURE';

export type CheckIfPasswordlessOptions = {
  email: string;
  alKey?: string;
  alToken?: string;
};

export function checkIfPasswordlessLogin(params: CheckIfPasswordlessOptions) {
  const { email, alKey, alToken } = params ?? {};

  return {
    bentoApi: {
      endpoint: 'members/is-passwordless',
      searchParams: { email, alKey, alToken },
      actions: [
        CHECK_IS_PASSWORDLESS_LOGIN_REQUEST,
        CHECK_IS_PASSWORDLESS_LOGIN_SUCCESS,
        CHECK_IS_PASSWORDLESS_LOGIN_FAILURE,
      ],
    },
  };
}
