import React, { useState } from 'react';

import { Transition } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import { useTheme } from '@techstyle/react-components';
import { Countdown } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';

import { Container as AssetCountdown } from '../AssetCountdown';
import { useCountdownTimer } from '../CountdownTimerProvider/CountdownTimerProvider';

const bounceInLeft = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scaleX(1);
  }
  75% {
    transform: translate3d(-35px, 0, 0) scaleX(0.98);
  }
  90% {
    transform: translate3d(-20px, 0, 0) scaleX(0.995);
  }
  to {
    transform: translate3d(-25px, 0, 0);
  }
`;

const bounceOutLeft = keyframes`
  0% {
    transform: translate3d(-25px, 0, 0);
  }
  20% {
    transform: translate3d(-10px, 0, 0) scaleX(0.9);
  }
  to {
    transform: translate3d(-2000px, 0, 0) scaleX(2);
  }
`;

const CountdownTimerBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed; // fallback if browser doesn't support sticky
  position: sticky;
  width: 170px;
  height: 72px;
  left: 0px;
  bottom: ${props => props.theme.spacing.small}px;
  background: ${props => props.theme.colors.default};
  padding: 12px 26px 12px 45px;
  color: ${props => props.theme.colors.white};
  text-align: center;
  border-radius: 0 30px 30px 0;
  box-shadow: 0 4px 10px ${props => props.theme.colors.lightShadow3};
  z-index: 1;
  transform: translate3d(-3000px, 0, 0);

  margin-bottom: 20px;
  ${props =>
    props.theme.context.postreg &&
    `
    margin-bottom: 180px;
    margin-top: -252px;  // height + margin-bottom
  `}

  ${props =>
    (props.state === 'entering' || props.state === 'entered') &&
    css`
      animation: ${bounceInLeft} ${props => props.transitionDuration}ms
        ease-in-out;
      transform: translate3d(-25px, 0, 0);
    `}

  ${props =>
    (props.state === 'exiting' || props.state === 'exited') &&
    css`
      animation: ${bounceOutLeft} ${props => props.transitionDuration}ms
        ease-in-out;
      transform: translate3d(-3000px, 0, 0);
    `}
`;

const CountdownMessage = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2px;
`;

const StyledAssetCountdown = styled(AssetCountdown)`
  padding: 0;
  height: auto;
  & ${AssetCountdown.StyledCountdown} {
    font-size: 6px;
    margin-bottom: 0;
  }

  & ${Countdown.Separator} {
    font-size: 16px;
    padding: 0 0.3em;
  }

  & ${Countdown.CountdownNumber} {
    font-size: 16px;
  }

  & ${Countdown.CountdownLabel} {
    display: none;
  }
`;

const transitionDuration = 1000;

const CountdownTimerBadge = () => {
  const { context } = useTheme();

  const {
    endDate,
    isCountdownActive,
    isHeaderVisible,
    setIsCountdownActive,
    setShouldForceBadgeInvisible,
  } = useCountdownTimer();

  const [touchX, setTouchX] = useState(0); // allow the user to swipe the countdown closed on mobile

  const handleCountdownComplete = () => {
    setShouldForceBadgeInvisible(true);
    setIsCountdownActive(false);
  };

  const handleTouchStart = e => {
    setTouchX(e.touches[0].clientX);
  };

  const handleTouchMove = e => {
    if (touchX > e.touches[0].clientX) {
      handleCountdownComplete();
    }
  };

  if (!context.shopping || context.pdp) {
    return null;
  }
  const isBuilderPreview = context?.isBuilderPreview;
  const builderPreviewTime = context?.builderPreviewTime;

  return (
    <Transition
      in={
        (context?.isBuilderPreview && !isHeaderVisible) ||
        (isCountdownActive && !isHeaderVisible)
      }
      appear={isCountdownActive && !isHeaderVisible}
      timeout={transitionDuration}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <CountdownTimerBadgeWrapper
          transitionDuration={transitionDuration}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          state={state}
        >
          <CountdownMessage>
            <FormattedMessage
              defaultMessage="Offer Ends In"
              id="global_checkout.offer_ends_in"
            />
          </CountdownMessage>
          <StyledAssetCountdown
            countDownEndDate={isBuilderPreview ? builderPreviewTime : endDate}
            onCountdownComplete={handleCountdownComplete}
          />
        </CountdownTimerBadgeWrapper>
      )}
    </Transition>
  );
};

export default CountdownTimerBadge;
