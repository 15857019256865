import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDomain } from '@techstyle/redux-core';

import { Component as AfterpaySnippet } from '../AfterpaySnippet';
import { Component as ClearpaySnippet } from '../ClearpaySnippet';

const Context = React.createContext(false);

// ? This is used inside of the `savagexExtension`, does this need to be on all pages?
function PaymentMethodProvider({ children }) {
  const [isAfterPayReady, setIsAfterpayReady] = useState(false);

  const { tld } = useDomain();

  const handleReady = useCallback(() => {
    setIsAfterpayReady(true);
  }, []);

  const PaymentMethod = useMemo(() => {
    if (tld === '.com') {
      return <AfterpaySnippet onReady={handleReady} />;
    }
    if (tld === '.co.uk') {
      return <ClearpaySnippet onReady={handleReady} />;
    }
    return null;
  }, [handleReady, tld]);

  return (
    <Context.Provider value={isAfterPayReady}>
      {PaymentMethod}
      {children}
    </Context.Provider>
  );
}

PaymentMethodProvider.propTypes = {
  children: PropTypes.node,
};

PaymentMethodProvider.Context = Context;

export default PaymentMethodProvider;
