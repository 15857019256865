const placeOrderFailEvents = ({ trackEvent }) => {
  const { TRACK_SUBMIT_ORDER_FAILURE } = require('../../actions/checkout');
  const placeOrderFail = trackEvent((action, prevState, nextState) => {
    let errorDetails = null;
    if (action.error && action.error.errors && action.error.errors.length) {
      errorDetails = JSON.stringify(action.error.errors[0]);
    }
    return {
      name: 'Checkout Error',
      properties: {
        session_id: nextState.session.sessionId.toString(),
        customer_id: nextState.customer.id
          ? nextState.customer.id.toString()
          : null,
        cart_id: nextState.checkout
          ? nextState.checkout.cartId.toString()
          : null,
        error_status_code: action.error.statusCode
          ? action.error.statusCode.toString()
          : '',
        error_message: action.error.message ? action.error.message : '',
        error_details: errorDetails,
      },
    };
  });

  return {
    [TRACK_SUBMIT_ORDER_FAILURE]: placeOrderFail,
  };
};

export default placeOrderFailEvents;
