import { loadMembership } from '@techstyle/react-accounts';
import { getDirectMarketingDisposition } from '@techstyle/react-marketing';
import { getSession } from '@techstyle/redux-core';

import { getExperienceId } from './getExperienceId';
import { getBuilderMembershipStatus } from './selectors';

export const localeFromTld = (tld, locale, isPreview) => {
  if (locale === 'en-US' && isPreview) {
    return 'Default';
  }

  // In Builder we have en-EU locale for .eu domain. However, by default the locale for .eu domain is en-GB.
  // We need to override it to en-EU to match Builder targeting.
  if (tld === '.eu') {
    return 'en-EU';
  }
  return locale;
};

export const getBuilderPageTargeting = async ({
  state,
  isPreview,
  query = null,
  dispatch,
}) => {
  const { dmGatewayCode, isLoggedIn } = getSession(state);
  const { locale } = state.intl;
  const { tld } = state.domain;

  const queryLocale = localeFromTld(tld, locale, isPreview);

  const disposition = getDirectMarketingDisposition(state);

  await dispatch(loadMembership());

  const { membershipStatus } = getBuilderMembershipStatus(state);

  const experienceId = await getExperienceId({
    dispatch: dispatch,
    disposition,
    dmGatewayCode,
    isLoggedIn,
    query: query,
  });

  return mapBuilderPageOptions({
    experienceId,
    isLoggedIn,
    isPreview,
    locale: queryLocale,
    membershipStatus,
    tld,
  });
};

export const mapBuilderPageOptions = (pageOptions = {}) => {
  const { experienceId, isLoggedIn, isPreview, locale, membershipStatus, tld } =
    pageOptions;

  return {
    userAttributes: {
      experienceId,
      locale,
      loggedIn: isLoggedIn,
      membershipStatus,
      tld,
    },
    options: {
      includeUnpublished: isPreview,
      locale,
    },
  };
};
