import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Mobile, OffCanvasMenu } from '@techstyle/react-components-legacy';

import {
  handleNonHighlightedEnter,
  handleHighlightedItemEnter,
} from '../../utils/search';
import SearchField from '../SearchField/SearchField';
import SearchProvider from '../SearchProvider/SearchProvider';

const Wrapper = styled.div``;

const StyledOffCanvasMenu = styled(OffCanvasMenu)`
  > .Overlay {
    > .Modal {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.colors.white};
    }
  }
`;

const MobileSearchPane = ({ className }) => {
  const { isActive, toggleActive, closeHamburger } = useContext(
    SearchProvider.Context
  );

  const handleHighlightedSelection = useCallback(
    selectedItem => {
      toggleActive(false);
      handleHighlightedItemEnter(selectedItem);
      if (typeof closeHamburger === 'function') {
        closeHamburger();
      }
    },
    [closeHamburger, toggleActive]
  );

  const handleNonHighlightedSelection = useCallback(
    selectedItem => {
      toggleActive(false);
      handleNonHighlightedEnter(selectedItem);
      if (typeof closeHamburger === 'function') {
        closeHamburger();
      }
    },
    [closeHamburger, toggleActive]
  );

  // Scroll to top of page to force search bar to appear
  // and indicate when modal is fully open (transition ended)
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const overlayRef = useRef();

  const handleTransitionEnd = useCallback(() => {
    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    setIsSearchModalOpen(true);
  }, []);

  const setOverlayRef = useCallback(
    e => {
      overlayRef.current = e;

      if (!overlayRef.current) {
        return setIsSearchModalOpen(false);
      }

      if (!overlayRef.current.ontransitionend) {
        overlayRef.current.ontransitionend = handleTransitionEnd;
        setScrollOffset(window.pageYOffset);
      }
    },
    [handleTransitionEnd]
  );

  useEffect(() => {
    if (!isActive && overlayRef.current) {
      window.scrollTo({ left: 0, top: scrollOffset, behavior: 'auto' });
    }
  }, [isActive, scrollOffset]);

  return (
    <Wrapper className={className}>
      <Mobile>
        <StyledOffCanvasMenu
          isOpen={isActive}
          closeButton={null}
          right
          overlayRef={setOverlayRef}
        >
          <SearchField
            focusOnMount
            isSearchModalOpen={isSearchModalOpen}
            mobilePane
            onNonHighlightedEnter={handleNonHighlightedSelection}
            onHighlightedItemEnter={handleHighlightedSelection}
          />
        </StyledOffCanvasMenu>
      </Mobile>
    </Wrapper>
  );
};

MobileSearchPane.propTypes = {
  /**
   * Class(es) to apply to the container element.
   */
  className: PropTypes.string,
};

export default MobileSearchPane;
