import { handleActions } from 'redux-actions';

import { setPageRenderDate } from '@techstyle/redux-core';

import { TOGGLE_CART_DRAWER } from '../actions/cart';

const defaultState = {
  cartDrawerIsOpen: false,
};

export default handleActions(
  {
    [TOGGLE_CART_DRAWER]: (state, action) => ({
      ...state,
      cartDrawerIsOpen: action.payload,
    }),
    // Close the cart when arriving on a new page.
    [setPageRenderDate]: (state, action) => ({
      ...state,
      cartDrawerIsOpen: false,
    }),
  },
  defaultState
);
