import { useSelector } from 'react-redux';

import { useMembership } from '@techstyle/react-accounts';

import { getIsVipCart } from './selectors';

export const useVipCTAStyling = () => {
  const isVipCart = useSelector(getIsVipCart);
  const { isVip } = useMembership();
  return isVip || isVipCart;
};
