import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { useCustomer, useMembership } from '@techstyle/react-accounts';
import { FormattedMessage } from '@techstyle/react-intl';
import { useSession } from '@techstyle/redux-core';

import { toggleSignInModal } from '../../actions/auth';
import { FORGOT_PASSWORD } from '../../constants/signIn';
import { useCheckIfPasswordlessLogin } from '../../utils/useCheckIfPasswordlessLogin';

const StyledLink = styled.a`
  ${props => props.theme.links.variants.navigationPrimary}
  ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const BurgerNavLoginLink = ({ closeMenu, onLogin }) => {
  const { isVip } = useMembership();
  const { isLoggedIn } = useSession();
  const dispatch = useDispatch();
  const { isPasswordlessLogin } = useCheckIfPasswordlessLogin();
  const { firstName } = useCustomer();

  const handlePasswordlessAccountClick = useCallback(() => {
    if (isPasswordlessLogin) {
      dispatch(toggleSignInModal(true, FORGOT_PASSWORD));
      closeMenu();
    }
  }, [closeMenu, dispatch, isPasswordlessLogin]);

  return (
    <Wrapper>
      {!isLoggedIn ? (
        <>
          <StyledLink onClick={onLogin} data-qa-automation="homePageSigninLink">
            <FormattedMessage
              defaultMessage="Log In"
              id="site_navigation.sign_in_rebrand"
            />
          </StyledLink>
        </>
      ) : (
        <>
          {isPasswordlessLogin ? (
            <StyledLink
              data-qa-automation="passwordlessAccountLink"
              onClick={handlePasswordlessAccountClick}
            >
              <StyledLink
                data-autotag="xtra-savage-main-menu"
                data-qa-automation="homeXtraSavageLink"
              >
                <FormattedMessage
                  defaultMessage="Your Account"
                  id="site_navigation.savage_guest"
                />
              </StyledLink>
            </StyledLink>
          ) : (
            <Link
              route={`/account/${isVip ? 'membership' : 'profile'}`}
              passHref
              as={undefined}
              href={undefined}
              to={undefined}
            >
              <StyledLink
                data-autotag="xtra-savage-main-menu"
                data-qa-automation="homeXtraSavageLink"
              >
                {firstName ? (
                  `${firstName}`
                ) : (
                  <FormattedMessage
                    defaultMessage="Your Account"
                    id="site_navigation.savage_guest"
                  />
                )}
              </StyledLink>
            </Link>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default BurgerNavLoginLink;
