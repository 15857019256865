import { ReviewSortCategories } from '../../constants/reviews';

export const LOAD_REVIEWS_REQUEST = 'LOAD_REVIEWS_REQUEST';
export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS';
export const LOAD_REVIEWS_FAILURE = 'LOAD_REVIEWS_FAILURE';

export const LOAD_SET_REVIEWS_REQUEST = 'LOAD_SET_REVIEWS_REQUEST';
export const LOAD_SET_REVIEWS_SUCCESS = 'LOAD_SET_REVIEWS_SUCCESS';
export const LOAD_SET_REVIEWS_FAILURE = 'LOAD_SET_REVIEWS_FAILURE';

export function loadReviews({
  groupCode,
  masterProductId,
  shouldUseCache,
  count = 5,
  page = 1,
  selectedProductSize,
  reviewSortCategory = ReviewSortCategories.HIGHEST_RATED,
  isSet = false,
  combineLinkedProduct = false,
}) {
  if (groupCode) {
    groupCode = String(groupCode).toUpperCase();
  }

  if (masterProductId) {
    masterProductId = Number(masterProductId);
  }

  const meta = {
    fetchedCount: count,
    fromCache: false,
    masterProductId,
    page,
    selectedProductSize,
    groupCode,
    reviewSortCategory,
  };

  const types = isSet
    ? [
        { type: LOAD_SET_REVIEWS_REQUEST, meta },
        { type: LOAD_SET_REVIEWS_SUCCESS, meta },
        { type: LOAD_SET_REVIEWS_FAILURE, meta },
      ]
    : [
        { type: LOAD_REVIEWS_REQUEST, meta },
        { type: LOAD_REVIEWS_SUCCESS, meta },
        { type: LOAD_REVIEWS_FAILURE, meta },
      ];

  return (dispatch, getState) => {
    const { reviews } = getState();

    // We are only returning cached data if the click is inside a PDP (i.e.
    // clicking between colorways). Individual products with the same reviews
    // will share the same `groupCode` while sets will share `masterProductId`.
    const isExistingProduct =
      (groupCode && reviews.groupCode === groupCode) ||
      (masterProductId && reviews.masterProductId === masterProductId);

    if (shouldUseCache && isExistingProduct) {
      return dispatch({
        type: LOAD_REVIEWS_SUCCESS,
        meta: { ...meta, fromCache: true },
      });
    }

    return dispatch({
      bentoApi: {
        endpoint: `products/${masterProductId}/reviews`,
        method: 'GET',
        searchParams: {
          count: count,
          productSize: selectedProductSize,
          sort:
            (reviewSortCategory === ReviewSortCategories.HIGHEST_RATED &&
              '-review_rating') ||
            (reviewSortCategory === ReviewSortCategories.LOWEST_RATED &&
              '+review_rating') ||
            (reviewSortCategory === ReviewSortCategories.MOST_RECENT &&
              '-review_timestamp,-review_rating'),
          page,
          combineLinkedProduct,
        },
        actions: types,
      },
    });
  };
}
