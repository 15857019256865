import { getSession } from '@techstyle/redux-core';

const pdpVideoPlaybackStartedEvents = ({ getContext, trackEvent }) => {
  const {
    TRACK_PDP_VIDEO_PLAYBACK_STARTED,
  } = require('../../actions/products');
  const pdpVideoPlaybackStarted = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Video Playback Started',
      properties: {
        feature: 'react',
        category: 'pdp',
        session_id: nextState.session.sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        ...action.payload,
      },
    };
  });

  return { [TRACK_PDP_VIDEO_PLAYBACK_STARTED]: pdpVideoPlaybackStarted };
};

export default pdpVideoPlaybackStartedEvents;
