import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { v2 } from '../../utils/themeVersioning';
import { Component as ProductImage } from '../ProductImage';

const SearchItem = styled.div`
  padding: 10px 22px;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    padding: 10px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    padding: 4px 4px 10px;
  }

  a {
    text-decoration: none;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  margin-left: ${props => props.theme.spacing.small}px;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    margin: 8px 0 0 0;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    margin-top: 3px 0 0 0;
  }
`;
const StyledText = styled.p`
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    font-size: 12px;
  }
  ${v2`
    ${props => props.theme.subHeader.variants.subHeading4.textStyles}
    `}
`;

const StyledProductImage = styled(ProductImage)`
  width: 33%;
  @media screen and (min-width: ${props => props.theme.breakpoints.large}px) {
    width: 100%;
  }
`;

export const SearchProductItem = ({ className, highlighted, product }) => {
  return (
    <SearchItem highlighted={highlighted} className={className}>
      <ProductWrapper>
        <StyledProductImage size={8} product={product} />
        <TextContainer>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: product.label,
            }}
          />
        </TextContainer>
      </ProductWrapper>
    </SearchItem>
  );
};

SearchProductItem.propTypes = {
  /**
   * styled-components className
   */
  className: PropTypes.string,
  /**
   * Boolean whether or not the item is highlighted
   */
  highlighted: PropTypes.bool,
  /**
   * TODO: Enhance this shape once the details are worked out
   * Product shape from SearchSpring/Tier1
   */
  product: PropTypes.object,
};
