const registrationFailEvents = ({ getContext, trackEvent }) => {
  const { TRACK_REGISTRATION_FAILURE } = require('../../actions/auth');

  const registrationFail = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    return {
      name: 'Registration Error',
      properties: {
        session_id: nextState.session.sessionId.toString(),
        error_status_code: action.error.statusCode
          ? action.error.statusCode.toString()
          : '',
        error_message: action.error.message ? action.error.message : '',
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        automated_test: Boolean(cookies.get('automated_test')),
        source: action.source,
      },
    };
  });

  return { [TRACK_REGISTRATION_FAILURE]: registrationFail };
};

export default registrationFailEvents;
