import React, { useEffect, useRef, useState } from 'react';

import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { BasicButton } from '../../styles/button';
import { BasicModal } from '../../styles/modal';
import useBirthdayPromo from '../../utils/useBirthdayPromo';
import { Component as StaticIcon } from '../StaticIcon';

const dialogStyle = css`
  padding: ${props => props.theme.spacing.large}px 35px
    ${props => props.theme.spacing.moderate}px;
  text-align: center;
  border-radius: 2px;
  width: 323px;
  height: 305px;
  text-align: center;
  align-items: center;

  & > * {
    flex-grow: unset;
  }
`;

const overlayStyle = css`
  background: ${props => props.theme.colors.scrim};
`;

const StyledModal = styled(BasicModal).attrs(props => ({
  dialogStyle: dialogStyle,
  overlayColor: props.theme.colors.scrim,
  overlayStyle: overlayStyle,
  ...props,
}))``;

const WineGlasses = styled(StaticIcon).attrs({
  type: 'wineGlasses',
  size: 40,
})`
  margin-bottom: 12px;
`;

const CheckIcon = styled(StaticIcon).attrs({
  type: 'check',
  size: 10,
})`
  margin: -2px 3px 0;
`;

const Header = styled.h2`
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 0.3px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const Text = styled.p`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const Button = styled.button`
  background: ${props => props.theme.colors.lavenderGradient};
  border-radius: 22px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  height: 44px;
  line-height: 17px;
  letter-spacing: 1.3px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
  padding: 0 ${props => props.theme.spacing.small}px;
`;

const ButtonSubtext = styled.p`
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  position: relative;
  width: 100%;
`;

const CopyTextBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity 0.3s linear;
`;

const CopiedText = styled(CopyTextBase)`
  opacity: 0;
  font-weight: bold;
  color: ${props => props.theme.colors.default};

  ${props =>
    props.isCopied &&
    `
    opacity: 1;
  `}
`;

const ClickToCopyText = styled(CopyTextBase)`
  color: ${props => props.theme.colors.subdued};

  ${props =>
    props.isCopied &&
    `
    opacity: 0;
  `}
`;

const ContinueButton = styled(BasicButton)`
  width: 100%;
  font-weight: 500;
  margin-bottom: ${props => props.theme.spacing.moderate}px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.default};
  border: 2px solid ${props => props.theme.colors.default};
  margin: auto 0 0;
`;

function MemberCreditsDesktopBirthdayModal({
  isOpen = false,
  onExit = () => {},
}) {
  const [isCopied, setIsCopied] = useState(false);
  const copiedTimeout = useRef();

  const { promoCode, discountPercent } = useBirthdayPromo();

  const handleClick = () => {
    copy(promoCode);
    setIsCopied(true);
    clearTimeout(copiedTimeout.current);
    copiedTimeout.current = setTimeout(() => setIsCopied(false), 2000);
  };

  useEffect(() => {
    return () => clearTimeout(copiedTimeout.current);
  }, []);

  return (
    <StyledModal isOpen={isOpen} onExit={onExit} titleId="birthday-modal-title">
      <WineGlasses />
      <Header id="birthday-modal-title">
        <FormattedMessage
          id="site_navigation.desktop_emp_birthday_code_header"
          defaultMessage="For your birthday! {discountPercent}% off!"
          values={{
            discountPercent,
          }}
        />
      </Header>
      <Text>
        <FormattedMessage
          id="site_navigation.desktop_emp_birthday_code_text_long"
          defaultMessage="Use the code below at checkout."
          values={{
            discountPercent,
          }}
        />
      </Text>
      <Button onClick={handleClick}>{promoCode}</Button>
      <ButtonSubtext isCopied={isCopied}>
        <CopiedText isCopied={isCopied}>
          <CheckIcon />
          <FormattedMessage
            id="site_navigation.button_code_copied"
            defaultMessage="Code Copied"
          />
        </CopiedText>
        <ClickToCopyText isCopied={isCopied}>
          <FormattedMessage
            id="site_navigation.button_click_to_copy"
            defaultMessage="Click code to copy"
          />
        </ClickToCopyText>
      </ButtonSubtext>
      <ContinueButton onClick={onExit}>
        <FormattedMessage
          id="site_favorites.favorites_page_empty_cta"
          defaultMessage="Continue shopping"
        />
      </ContinueButton>
    </StyledModal>
  );
}

MemberCreditsDesktopBirthdayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default MemberCreditsDesktopBirthdayModal;
