import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useCookies } from '@techstyle/redux-core';

function ForceNoRedirect() {
  const [, setCookie] = useCookies(['no_redirect']);
  const { noRedirect } = useSelector(state => state.borderfree);

  const getCookieExpirationDate = () => {
    // 3hours in the future.
    return new Date(new Date().setHours(new Date().getHours() + 3));
  };

  useEffect(() => {
    if (noRedirect) {
      setCookie('no_redirect', 'true', {
        expires: getCookieExpirationDate(),
        path: '/',
      });
    }
  }, [noRedirect, setCookie]);

  return null;
}

export default ForceNoRedirect;
