import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { useBreakpoint } from '@techstyle/react-components';
import { Mobile } from '@techstyle/react-components-legacy';

import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import { Container as DebugVisitorStatus } from '../DebugVisitorStatus';
import NavBar from '../NavBar/NavBar';
import { Component as NewPricingSkinnyBanner } from '../NewPricingSkinnyBanner';
import { Component as PrivacyPolicyModal } from '../PrivacyPolicyModal';
import ProgressLoader from '../ProgressLoader/ProgressLoader';
import { ProgressLoaderConsumer } from '../ProgressLoaderContext/ProgressLoaderContext';
import { Component as PromoCountdownSkinnyBanner } from '../PromoCountdownSkinnyBanner';
import { useQuickViewContext } from '../QuickViewProvider';
import { Container as SkinnyBanner } from '../SkinnyBanner';
import ToastMessages from '../ToastMessages/ToastMessages';

import { StickyNavBarWrapper } from './components/StickyNavBar';
import { HeaderAttachmentContainer } from './HeaderAttachmentContainer';

const Wrapper = styled.header`
  position: relative;
  z-index: ${props => props.theme.zIndex.flyout + 1};
`;

const Header = ({
  authRequired,
  className,
  hideNavigation,
  hideNavLinks,
  hideNavLinksState,
  hideSkinnyBanner,
  shouldDisplayFullLogo,
  title,
  allowNewPricingSkinnyBanner,
}) => {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const { cartDrawerIsOpen } = useSelector(state => state.cart);
  const { isMobile } = useBreakpoint();

  const { isOpen: isQuickViewModalOpen } = useQuickViewContext();

  const openPrivacyPolicy = useCallback(
    event => {
      event.preventDefault();
      setPrivacyPolicyOpen(true);
    },
    [setPrivacyPolicyOpen]
  );

  const closePrivacyPolicy = useCallback(
    event => {
      setPrivacyPolicyOpen(false);
    },
    [setPrivacyPolicyOpen]
  );

  return (
    <Wrapper className={className}>
      <DebugVisitorStatus />
      {hideSkinnyBanner ? null : (
        <BorderfreeGuard>
          <SkinnyBanner />
        </BorderfreeGuard>
      )}

      <StickyNavBarWrapper>
        <NavBar
          authRequired={authRequired}
          hideNavigation={hideNavigation}
          hideNavLinks={hideNavLinks}
          hideNavLinksState={hideNavLinksState}
          openPrivacyPolicy={openPrivacyPolicy}
          shouldDisplayFullLogo={shouldDisplayFullLogo}
          title={title}
          allowNewPricingSkinnyBanner={allowNewPricingSkinnyBanner}
        />
        {allowNewPricingSkinnyBanner && !isMobile ? (
          <NewPricingSkinnyBanner />
        ) : (
          <></>
        )}
        {/* when cart drawer is open, hide the toast message here and show the one in cart drawer */}
        {cartDrawerIsOpen || isQuickViewModalOpen ? (
          <div />
        ) : (
          <ToastMessages hideBehind />
        )}
        <Mobile>
          <ProgressLoaderConsumer>
            {({ isLoading }) => (
              <ProgressLoader
                loading={isLoading}
                position="bottom"
                fixed={false}
              />
            )}
          </ProgressLoaderConsumer>
          <HeaderAttachmentContainer origin="header" />
        </Mobile>
        <PromoCountdownSkinnyBanner />
      </StickyNavBarWrapper>

      <PrivacyPolicyModal
        isOpen={privacyPolicyOpen}
        onExit={closePrivacyPolicy}
        titleId="privacy-policy-modal"
      />
    </Wrapper>
  );
};

Header.propTypes = {
  authRequired: PropTypes.bool,
  className: PropTypes.string,
  hideNavigation: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
  hideNavLinksState: PropTypes.string,
  hideRegistrationModal: PropTypes.bool,
  hideSkinnyBanner: PropTypes.bool,
  shouldDisplayFullLogo: PropTypes.bool,
  theme: PropTypes.shape({
    context: PropTypes.shape({
      autoHideNav: PropTypes.bool,
      mobileProducts: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  allowNewPricingSkinnyBanner: PropTypes.bool,
};

Header.defaultProps = {
  authRequired: false,
  hideNavigation: false,
  hideNavLinks: false,
  hideNavLinksState: '',
  hideSkinnyBanner: false,
  hideRegistrationModal: false,
  shouldDisplayFullLogo: true,
  allowNewPricingSkinnyBanner: false,
};

Header.Wrapper = Wrapper;

export default withTheme(Header);
