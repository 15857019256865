import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NavMenu from './NavMenu';

function mapStateToProps(state) {
  return {
    pageRenderDate: state.time.pageRenderDate,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatch,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
