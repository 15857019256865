import { getPartialTrackingData } from './getTrackingData';

export default function basePageViewData(
  nextState, // nextState from trackPageView callback
  { cookies } // context from getContext (segment event function)
) {
  const trackingData = getPartialTrackingData(nextState, { cookies }, [
    'automated_test',
    'feature',
    'loggedin_status',
    'session_id',
    'store_group_id',
    'store_id',
    'user_id',
    'user_status_initial',
  ]);

  return trackingData;
}
