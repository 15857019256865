import { loadCart } from '../checkout';

export const TOGGLE_CART_DRAWER = 'TOGGLE_CART_DRAWER';

export const USE_STORE_CREDIT_REQUEST = 'USE_STORE_CREDIT_REQUEST';
export const USE_STORE_CREDIT_SUCCESS = 'USE_STORE_CREDIT_SUCCESS';
export const USE_STORE_CREDIT_FAILURE = 'USE_STORE_CREDIT_FAILURE';

export const APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST';
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE';

export const REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST';
export const REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS';
export const REMOVE_PROMO_CODE_FAILURE = 'REMOVE_PROMO_CODE_FAILURE';

export const EXPIRE_CART_REQUEST = 'EXPIRE_CART_REQUEST';
export const EXPIRE_CART_SUCCESS = 'EXPIRE_CART_SUCCESS';
export const EXPIRE_CART_FAILURE = 'EXPIRE_CART_FAILURE';

export const TRACK_PDP_LEARN_MORE_REQUEST = 'TRACK_PDP_LEARN_MORE_REQUEST';
export const TRACK_CLICK_PROMO_OFFER_REQUEST =
  'TRACK_CLICK_PROMO_OFFER_REQUEST';

export function toggleCartDrawer(isOpen, options = {}) {
  return dispatch => {
    if (options.fetchCart) {
      dispatch(loadCart());
    }

    return dispatch({
      type: TOGGLE_CART_DRAWER,
      payload: isOpen,
    });
  };
}

export function useStoreCredit(useStoreCredit) {
  return {
    bentoApi: {
      endpoint: 'cart/storecredit',
      method: 'POST',
      actions: [
        USE_STORE_CREDIT_REQUEST,
        USE_STORE_CREDIT_SUCCESS,
        USE_STORE_CREDIT_FAILURE,
      ],
      body: JSON.stringify({ useStoreCredit }),
    },
  };
}

export function applyPromoCode(promoCode) {
  return {
    bentoApi: {
      endpoint: 'cart/promocode',
      method: 'POST',
      actions: [
        APPLY_PROMO_CODE_REQUEST,
        APPLY_PROMO_CODE_SUCCESS,
        APPLY_PROMO_CODE_FAILURE,
      ],
      body: JSON.stringify({ promoCode }),
    },
  };
}

export function removePromoCode(promoCode) {
  return {
    bentoApi: {
      endpoint: `cart/promocode/${promoCode}`,
      method: 'DELETE',
      actions: [
        REMOVE_PROMO_CODE_REQUEST,
        REMOVE_PROMO_CODE_SUCCESS,
        REMOVE_PROMO_CODE_FAILURE,
      ],
    },
  };
}

export function updateCartStatus(status) {
  return {
    bentoApi: {
      endpoint: 'cart/status',
      method: 'POST',
      actions: [EXPIRE_CART_REQUEST, EXPIRE_CART_SUCCESS, EXPIRE_CART_FAILURE],
      body: JSON.stringify({ status }),
    },
  };
}

export function trackPDPLearnMoreEvent() {
  return {
    type: TRACK_PDP_LEARN_MORE_REQUEST,
  };
}

export function trackClickPromoOfferEvent(eventLabel) {
  return {
    type: TRACK_CLICK_PROMO_OFFER_REQUEST,
    eventLabel,
  };
}
