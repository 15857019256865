import { useEffect, useMemo, useRef } from 'react';

import { useRouter } from 'next/router';

import { useLocalStorage } from '@techstyle/redux-core';

type StoredQueryParamsProps = {
  /**
   * The key to store the query params under in local storage
   */
  storageKey: string;
  /**
   * The query params to store
   */
  params: string[];
  /**
   * Enable to only store the params on the initial page load.
   */
  initialOnly?: boolean;
  /**
   * Called when the params are stored in local storage
   */
  onStored?: () => void;
};

function StoredQueryParams({
  storageKey,
  params,
  initialOnly,
  onStored,
}: StoredQueryParamsProps) {
  const { query } = useRouter();
  const [, setStoredParams, localStorageInitialized] =
    useLocalStorage(storageKey);
  const isInitialized = useRef(false);
  const shouldStoreParams = initialOnly ? !isInitialized.current : true;

  const paramsToStore = useMemo(() => {
    const queryParams = query;
    const storedParams = {};
    params.forEach(param => {
      if (queryParams[param]) {
        storedParams[param] = queryParams[param];
      }
    });
    return storedParams as Partial<Record<string, string>>;
  }, [params, query]);

  useEffect(() => {
    if (localStorageInitialized && shouldStoreParams) {
      // @ts-ignore - type information coming from `useLocalStorage` is not correct
      setStoredParams(currentParams => {
        const filteredParams = {};

        // Filter out any params that are not in the list of params to store
        // This is to prevent any outdated params from being stored
        if (typeof currentParams === 'object') {
          Object.keys(currentParams).forEach(param => {
            if (params.includes(param)) {
              filteredParams[param] = currentParams[param];
            }
          });
        }

        const newParams = {
          ...filteredParams,
          ...paramsToStore,
        };

        return Object.keys(newParams).length > 0 ? newParams : undefined;
      });

      if (!isInitialized.current) {
        isInitialized.current = true;
      }

      if (onStored) {
        onStored();
      }
    }
  }, [
    localStorageInitialized,
    params,
    paramsToStore,
    setStoredParams,
    shouldStoreParams,
    onStored,
  ]);

  return null;
}

export default StoredQueryParams;
