import buildEvents from '../buildEvents';

import addSetToCartFail from './addSetToCartFail';
import addToCartFail from './addToCartFail';
import colorChange from './colorChange';
import gridProductCellClick from './gridProductCellClick';
import pdpVideoPlaybackCompleted from './pdpVideoPlaybackCompleted';
import pdpVideoPlaybackStarted from './pdpVideoPlaybackStarted';
import pdpView from './pdpView';
import placeOrderFail from './placeOrderFail';
import productClickedBmig from './productClickedBmig';
import productListViewed from './productListViewed';
import productPriceViewed from './productPriceViewed';
import productsSearched from './productsSearched';
import productViewed from './productViewed';
import registrationFail from './registrationFail';
import sizeChange from './sizeChange';
import trackBuilderTestEntered from './trackBuilderTestEntered';
import trackButtonClicked from './trackButtonClicked';
import trackByoInterstitialClose from './trackByoInterstitialClose';
import trackFilterReviews from './trackFilterReviews';
import trackProductListFilter from './trackProductListFilter';
import trackPromotionClick from './trackPromotionClick';
import trackPromotionViewed from './trackPromotionViewed';
import trackQuickView from './trackQuickView';
import trackQuizScreen from './trackQuizScreen';
import viewMemberModel from './viewMemberModel';
import viewPDPImage from './viewPDPImage';

const eventWrapper = methods =>
  buildEvents(
    [
      addSetToCartFail,
      addToCartFail,
      colorChange,
      gridProductCellClick,
      pdpVideoPlaybackCompleted,
      pdpVideoPlaybackStarted,
      pdpView,
      placeOrderFail,
      productClickedBmig,
      productListViewed,
      productViewed,
      productPriceViewed,
      productsSearched,
      registrationFail,
      sizeChange,
      trackBuilderTestEntered,
      trackButtonClicked,
      trackByoInterstitialClose,
      trackFilterReviews,
      trackProductListFilter,
      trackPromotionClick,
      trackPromotionViewed,
      trackQuickView,
      trackQuizScreen,
      viewMemberModel,
      viewPDPImage,
    ],
    methods
  );

export default eventWrapper;
