import React from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useWishlistIds } from '@techstyle/react-accounts';
import { useTheme } from '@techstyle/react-components';
import { useIntl, FormattedMessage } from '@techstyle/react-intl';
import { updateSessionDetail, useSession } from '@techstyle/redux-core';

import { v1, v2 } from '../../utils/themeVersioning';
import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';
import { Component as QuantityBadge } from '../QuantityBadge';
import { Component as StaticIcon } from '../StaticIcon';

const StyledLink = styled.a`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${v1`
      width: 100%;
    `}

  ${v2`
    > svg {
    color: ${props => props.theme.colors.black};
    height: 24px;
    width: 24px;
    }
    `}
`;

const FavoriteIconLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;

  ${v1`
    color: ${props => props.theme.colors.default};
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    @media (max-width: ${props => props.theme.breakpoints.xlarge - 1}px) {
    display: none;
  }
    `}
  ${v2`
      color: ${props => props.theme.colors.gunmetal} !important;
      ${props => props.theme.subHeader.variants.subHeading4Uppercase.textStyles}

      font-size: 12px;
      letter-spacing: 1px;
    `}
`;

const StyledStaticIcon = styled(StaticIcon)`
  color: ${props => props.theme.colors.default};
`;

export default function FavoritesNav({ size, shouldShowCountBadge }) {
  const { total: totalWishlist } = useWishlistIds();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isVisitor } = useSession();

  const handleClickFavoritesNav = () => {
    if (isVisitor) {
      dispatch(
        updateSessionDetail({ name: 'signup_favorites_nav', value: '' })
      );
    }
  };

  return (
    <Link
      href={{
        pathname: '/favorites',
        query: {
          isShoppingPage: (!!theme.context.shopping).toString(),
        },
      }}
      as="/favorites"
      passHref
    >
      <StyledLink
        onClick={handleClickFavoritesNav}
        data-autotag="favorites-nav-btn"
        data-qa-automation="homePageFavoritesIcon"
        aria-label={intl.formatMessage({
          id: 'site_navigation.favorites',
          defaultMessage: 'Favorites',
        })}
      >
        {theme.themeVersion === 'v2' ? (
          <NewThemeIcon type="heart" />
        ) : (
          <StyledStaticIcon type="heartUnfilled" size={size} />
        )}
        {totalWishlist > 0 && shouldShowCountBadge && (
          <QuantityBadge>{totalWishlist}</QuantityBadge>
        )}
        <FavoriteIconLabel>
          <FormattedMessage
            defaultMessage="Favorites"
            id="site_navigation.favorites"
          />
        </FavoriteIconLabel>
      </StyledLink>
    </Link>
  );
}

FavoritesNav.propTypes = {
  size: PropTypes.number,
  shouldShowCountBadge: PropTypes.bool,
};

FavoritesNav.defaultProps = {
  shouldShowCountBadge: false,
};
