/* eslint no-prototype-builtins: "warn" */
import config from 'config';

import {
  getMembership,
  getMembershipPeriod,
  SkipTheMonthStatus,
} from '@techstyle/react-accounts';
import { getNavContainer } from '@techstyle/react-navigation';
import {
  getNewDateFunction,
  getSession,
  getDateNowFunction,
  parseDate,
  toPacificDateString,
  createSelector,
  urlNoQueryString,
} from '@techstyle/redux-core';

import { OrderTaxTypes } from '../constants/checkout';

import { getCategories } from './categoryNav';
import { countryList as allCountries } from './countries';
import { domainMatchesList } from './domain';
import {
  createPaymentMethodFromId,
  createPaymentMethodFromData,
  createPaymentMethodFromOrder,
} from './paymentMethods';
import {
  filtersToRequestParams,
  requestParamsToStoreKey,
  requestParamsToAggregationKey,
} from './productBrowser';
import {
  checkPreorderForSingleItem,
  checkPreorderForSets,
} from './productDetail';
import { getPromo, shouldShowPromoPrice } from './promos';
import {
  getPotentialSegmentsSelector,
  getSegmentedValue,
} from './segmentation';
import {
  getProfileSizesForGrid,
  getUserSizesForGrid,
  getRegionWarehouseSizeQuantity,
} from './sizes';

const autoSellOutDate = config.get('public.vipAutoSellOut.date');
const autoSellOutEnabled = config.get('public.vipAutoSellOut.enabled');
const borderfreeWarehouse = config.get('public.borderfree.warehouseId');
const byoSetProductTypeId = config.get('public.productTypes.byoSetId');
const cancellationNoticeStates = config.get('public.cancellationNoticeStates');
const collectibleTag = config.get('public.productTags.collectible');
const customizableTag = config.get('public.productTags.customizable');
const customTag = config.get('public.productTags.customTag');
const rihannasPickTag = config.get('public.productTags.rihannasPick');
const newTag = config.get('public.productTags.new');
const bestSellerTag = config.get('public.productTags.bestSeller');
const backInStockTag = config.get('public.productTags.backInStock');
const topRatedTag = config.get('public.productTags.topRated');
const trendingTag = config.get('public.productTags.trending');
const clfTag = config.get('public.productTags.clf');
const unlockedXclusiveTag = config.get('public.productTags.unlockedXclusive');
const youniversalTag = config.get('public.productTags.youniversal');
const currency = config.get('public.intl.currencies');
const curvySizeTag = config.get('public.productTags.plusSize');
const defaultCountries = config.get('public.domain.defaultCountries');
const defaultMembershipType = config.get('public.defaultMembershipType');
const excludeFromByoTag = config.get('public.productTags.excludeFromByo');
const additionalSaleDiscount = config.get('public.additionalSaleDiscount');
const dmGatewayCodes = config.get('public.dmGatewayCodes');
const dmGatewayExpiration = config.get('public.dmGatewayExpiration');
const empMembershipTypeId = config.get('public.membershipTypeIds.emp');
const nmpMembershipTypeId = config.get('public.membershipTypeIds.nmp');
const fakeSoldOutTagId = config.get('public.productTags.fakeSoldOut');
const faqLinks = config.get('public.faqLinks');
const fplOnlyTagId = config.get('public.productTags.fplOnly');
const featuredProductLists = config.get('public.featuredProductLists');
const fwFPLs = config.get('public.featuredProductLists.fw');
const fashionShow = config.get('public.fashionShow');
const hideFromGridTag = config.get('public.productTags.hideFromGrid');
const hideRelatedTagId = config.get('public.productTags.hideRelated');
const leadOnlyTagId = config.get('public.productTags.leadOnly');
const membershipTypes = config.get('public.membershipTypes');
const membershipPrice = membershipTypes[defaultMembershipType].price;
const minimum3Qty = config.get('public.productTags.minimum3Qty');
const productTypeMembershipId = config.get('public.productTypes.membershipId');
const productTypeSetId = config.get('public.productTypes.setId');
const promos = config.get('public.promos');
const regularSizeTag = config.get('public.productTags.regularSize');
const comingSoonTag = config.get('public.productTags.comingSoon');
const unbreakableSetTag = config.get('public.productTags.unbreakableSet');
const vipOnlyTagId = config.get('public.productTags.vipOnly');
const warehouseIdsByRegion = config.get(
  'public.quantityRules.warehouseIdsByRegion'
);
const saleFpls = config.get('public.saleFpls');
const hideProduct = config.get('public.featuredProductLists.hideProduct');
const xclusiveTag = config.get('public.productTags.xclusive');
const xtraVipBoxTag = config.get('public.productTags.xtraVipBox');
const soldOutLeadTag = config.get('public.productTags.soldOutLead');
const soldOutVipTag = config.get('public.productTags.soldOutVip');
const paypalActivated = config.get('public.paypal.activated');
const gwpPromos = config.get('public.promoCodes.giftWithPurchase');
const twoItemBundleByoProductId = config.get(
  'public.byo.twoItemBundleByoProductId'
);
const vipLiveShoppingPromo = config.get('public.promoCodes.vipLiveShopping');

const ONE_HOUR = 60 * 60 * 1000;

/**
 * Return the address object indicated by `addressId`. If there is no
 * `addressId`, an empty object will be returned. If the address is not
 * contained in the given `addresses` array, return an object with only the `id`
 * and `incomplete: true`. Otherwise, return the found address.
 */
function getAddressById(addresses, addressId, defaultCountry) {
  if (!addressId) {
    return { countryCode: defaultCountry };
  }
  const address = addresses.find(address => address.id === addressId);
  return address || { id: addressId, incomplete: true };
}

export const getDefaultCountry = createSelector(
  state => state.domain.tld,
  (state, props = {}) => props.defaultCountry,
  (tld, defaultCountry) => defaultCountry || defaultCountries[tld]
);

export const getDefaultAddress = createSelector(
  state => state.account.addresses.data,
  (state, props = {}) => props.allowEmptyAddress !== false,
  getDefaultCountry,
  (addresses, allowEmptyAddress, defaultCountry) => {
    let address = addresses.find(address => address.isDefault) || addresses[0];
    if (!address && !allowEmptyAddress) {
      address = { countryCode: defaultCountry };
    }
    return address;
  }
);

function createAddressFromCard(cardInfo, defaultCountry) {
  return {
    id: cardInfo.addressId,
    firstName: cardInfo.firstName,
    lastName: cardInfo.lastName,
    company: cardInfo.company,
    address1: cardInfo.address1,
    address2: cardInfo.address2,
    city: cardInfo.city,
    state: cardInfo.state,
    countryCode: cardInfo.countryCode || defaultCountry,
    zip: cardInfo.zip,
    phone: cardInfo.phone,
  };
}

export const getComponentAddress = createSelector(
  state => state.account.addresses.data,
  (state, props) =>
    props.addressId || (props.address ? props.address.id : undefined),
  getDefaultCountry,
  getAddressById
);

/**
 * If rendering a component that's only been given a `paymentId`, determing the
 * full payment method (id, type, method, and data).
 */
export const getComponentPaymentMethod = createSelector(
  state => state.account.payment.paymentMethods,
  (state, props) => props.paymentId || null,
  state => {
    const { paymentProvider } = state.storeGroup.paymentOptions;
    if (paymentProvider === 'adyen') {
      return 'psp';
    } else if (paymentProvider === 'vantiv') {
      return 'creditcard';
    }
    return null;
  },
  state => state.storeGroup.paymentOptions.paymentProvider,
  createPaymentMethodFromId
);

export const getComponentPaymentInfo = createSelector(
  getComponentPaymentMethod,
  getDefaultCountry,
  (paymentMethod, defaultCountry) => {
    let address = {};
    if (
      paymentMethod.cardInfo ||
      paymentMethod.sepaInfo ||
      paymentMethod.paypalInfo
    ) {
      address = createAddressFromCard(
        paymentMethod.cardInfo ||
          paymentMethod.sepaInfo ||
          paymentMethod.paypalInfo,
        defaultCountry
      );
    }

    return {
      paymentMethod,
      address,
    };
  }
);

export const getDefaultPaymentData = createSelector(
  state => state.account.payment.paymentMethods,
  payments => payments.find(payment => payment.isDefault) || payments[0]
);

export const getDefaultPaymentMethod = createSelector(
  getDefaultPaymentData,
  state => state.storeGroup.paymentOptions.paymentProvider,
  createPaymentMethodFromData
);

export const getDefaultPaymentInfo = createSelector(
  getDefaultPaymentMethod,
  getDefaultCountry,
  (paymentMethod, defaultCountry) => {
    return {
      paymentMethod,
      address: paymentMethod
        ? createAddressFromCard(paymentMethod.cardInfo, defaultCountry)
        : { countryCode: defaultCountry },
    };
  }
);

export const getCartShippingAddress = createSelector(
  state => state.checkout.shippingAddress,
  getDefaultCountry,
  (address, defaultCountry) => {
    return address && address.countryCode
      ? address
      : { countryCode: defaultCountry, ...address };
  }
);

export const getStoreShippingOptions = createSelector(
  state => state.storeGroup.shippingOptions,
  shippingOptions => shippingOptions.options || []
);

export const getCartShippingOptions = createSelector(
  state => state.checkout.shippingOptions,
  shippingOptions => shippingOptions
);

export const filterShippingOptionsByAddress = (shippingOptions, address) => {
  return shippingOptions.filter(option => {
    if (
      option.shippingCountries &&
      option.shippingCountries.length &&
      !option.shippingCountries.includes(address.countryCode)
    ) {
      return false;
    }
    if (
      option.excludedCountries &&
      option.excludedCountries.includes(address.countryCode)
    ) {
      return false;
    }
    if (
      option.excludedStates &&
      option.excludedStates.includes(address.state)
    ) {
      return false;
    }
    return true;
  });
};

export const getStorePaymentTypes = createSelector(
  state => state.storeGroup.paymentOptions.paymentTypes,
  state => state.domain.tld,
  state => getMembership(state).isVip,
  state => state.borderfree.isBorderfreeCustomer,
  state => getMembership(state).networkStatus.isUpToDate,
  (_, { isAfterPayEnabled }) => isAfterPayEnabled,
  (_, { isCashAppEnabled }) => isCashAppEnabled,
  (
    paymentTypes,
    domain,
    isVip,
    isBorderfreeCustomer,
    isMembershipInitialized,
    isAfterPayEnabled,
    isCashAppEnabled
  ) => {
    let filteredPaymentTypes = paymentTypes.map(
      // Rename `cc` type.
      paymentType => (paymentType === 'cc' ? 'creditcard' : paymentType)
    );

    const shouldDisplayAfterpay =
      isMembershipInitialized && isAfterPayEnabled && !isBorderfreeCustomer;

    const shouldDisplayCashApp = isCashAppEnabled && !isBorderfreeCustomer;

    if (!paypalActivated[domain]) {
      filteredPaymentTypes = filteredPaymentTypes.filter(
        value => value !== 'paypal'
      );
    }
    if (!shouldDisplayAfterpay) {
      filteredPaymentTypes = filteredPaymentTypes.filter(
        value => value !== 'afterpay'
      );
    }
    if (!shouldDisplayCashApp) {
      filteredPaymentTypes = filteredPaymentTypes.filter(
        value => value !== 'cashapppay'
      );
    }
    return filteredPaymentTypes || [];
  }
);

// Whole number based on how many credits were used
export const getCreditsApplied = createSelector(
  state => state.checkout.memberCreditAmount,
  state => state.membership.price,
  (memberCreditAmount, membershipPrice) =>
    Math.round(memberCreditAmount / membershipPrice)
);

export const getStorePaymentSettings = createSelector(
  state => state.storeGroup.paymentOptions.paymentSettings,
  paymentSettings => {
    if (!paymentSettings) {
      return {};
    }
    // Rename `cc` field.
    const { cc, ...rest } = paymentSettings;
    return { creditcard: cc, ...rest };
  }
);

export const filterPaymentTypesByAddress = (
  paymentTypes,
  paymentSettings,
  address
) => {
  return paymentTypes.filter(paymentType => {
    const settings = paymentSettings[paymentType];
    switch (paymentType) {
      case 'sepa':
        if (!settings) {
          return false;
        }
        return (
          settings.countries.includes(address.countryCode) &&
          !settings.excludedCountries.includes(address.countryCode)
        );
      default:
        return true;
    }
  });
};

export const getPaymentTypes = createSelector(
  (state, { isAfterPayEnabled, isCashAppEnabled }) =>
    getStorePaymentTypes(state, { isAfterPayEnabled, isCashAppEnabled }),
  getStorePaymentSettings,
  (state, props) => props.address || {},
  filterPaymentTypesByAddress
);

export const getCartPaymentTypes = createSelector(
  (state, { isAfterPayEnabled, isCashAppEnabled }) =>
    getStorePaymentTypes(state, { isAfterPayEnabled, isCashAppEnabled }),
  getStorePaymentSettings,
  getCartShippingAddress,
  filterPaymentTypesByAddress
);

export const getFilteredShippingOptions = createSelector(
  getCartShippingOptions,
  (state, props = {}) => props.address || getCartShippingAddress(state, props),
  filterShippingOptionsByAddress
);

export const hasMultipleOrders = createSelector(
  (state, props) =>
    props.order &&
    props.order.splitOrders &&
    props.order.splitOrders.length > 1,
  multipleOrders => {
    return multipleOrders;
  }
);

export const getLineItems = createSelector(
  (state, props = {}) =>
    props.order
      ? props.order.items || props.order.orderLines || []
      : state.checkout.items,
  lineItems => lineItems
);

function normalizeOrderLineItems(items) {
  return items.map(item => ({
    ...item,
    lineId: item.lineId || item.orderLineId,
    lineItems: [item],
  }));
}

export const getOrderItems = createSelector(getLineItems, lineItems =>
  normalizeOrderLineItems(lineItems)
);

export const getOrder = createSelector(
  (state, props = {}) => {
    if (props.order) {
      return props.order;
    } else if (props.orderId) {
      return state.account.orders.ordersByID[props.orderId];
    }
  },
  order => {
    if (order) {
      if (order.items) {
        order = {
          ...order,
          items: normalizeOrderLineItems(order.items),
        };
      }
      if (order.orderLines) {
        order = {
          ...order,
          orderLines: normalizeOrderLineItems(order.orderLines),
        };
      }
    }
    return order;
  }
);

/**
 * Return the current shipping address associated with the cart, using
 * `getAddress` to determine the result.
 */
export const getShippingAddress = createSelector(
  getOrder,
  getCartShippingAddress,
  (order, cartShippingAddress) =>
    order ? order.shippingAddress : cartShippingAddress
);

export const getCartShippingOptionId = createSelector(
  state => state.checkout,
  cart => cart.shippingOptionId
);

export const getShippingOptionId = createSelector(
  getOrder,
  getCartShippingOptionId,
  (order, id) => (order ? order.shippingOptionId : id)
);

export const getShippingOption = createSelector(
  getOrder,
  getShippingOptionId,
  getCartShippingOptions,
  getStoreShippingOptions,
  (order, id, shippingOptions, storeShippingOptions) => {
    if (!id) {
      return {};
    }

    let shippingOption;

    if (order && order.shippingAddress) {
      shippingOptions = storeShippingOptions;

      shippingOption = shippingOptions.find(
        option =>
          option.shippingOptionId === id &&
          !option.excludedCountries.includes(order.shippingAddress.countryCode)
      );
    } else {
      shippingOption = shippingOptions.find(
        option => option.shippingOptionId === id
      );
    }

    if (!shippingOption) {
      shippingOption = { shippingOptionId: id, incomplete: true };
    }

    return shippingOption;
  }
);

export const getOrderShippingMethod = createSelector(
  (state, props) => props.order,
  getStoreShippingOptions,
  (order, shippingOptions) => {
    if (order) {
      return shippingOptions.find(
        option => option.shippingOptionId === order.shippingOptionId
      );
    }
  }
);

/**
 * Return an object with `address` and `shippingMethod` properties.
 */
export const getShippingInfo = createSelector(
  getOrder,
  getShippingAddress,
  getShippingOption,
  state =>
    state.customerDetails.shipping_city &&
    state.customerDetails.shipping_city.value,
  state =>
    state.customerDetails.shipping_state &&
    state.customerDetails.shipping_state.value,
  state =>
    state.customerDetails.shipping_zip &&
    state.customerDetails.shipping_zip.value,
  (
    order,
    address,
    shippingMethod,
    customerDetailCity,
    customerDetailState,
    customerDetailZip
  ) => {
    if (order) {
      return {
        address,
        shippingMethod,
      };
    }
    return {
      address: {
        ...address,
        city: address.city || customerDetailCity,
        state: address.state || customerDetailState,
        zip: address.zip || customerDetailZip,
      },
      shippingMethod,
    };
  }
);

export const getDomainCurrency = createSelector(
  state => state.domain.tld,
  tld => currency[tld]
);

export const getCartPaymentMethod = createSelector(
  state => state.account.payment.paymentMethods,
  state =>
    state.checkout.creditCardId ||
    state.checkout.paymentServiceProviderId ||
    null,
  state => state.checkout.paymentMethod || null,
  state => state.storeGroup.paymentOptions.paymentProvider,
  createPaymentMethodFromId
);

export const getMembershipPaymentMethod = createSelector(
  state => state.account.payment.paymentMethods,
  state => getMembership(state).paymentObjectId || null,
  state => getMembership(state).paymentMethod || null,
  state => state.storeGroup.paymentOptions.paymentProvider,
  createPaymentMethodFromId
);

export const getPaymentMethod = createSelector(
  getOrder,
  getCartPaymentMethod,
  state => state.checkout.inProgressPaymentInfo,
  state => state.storeGroup.paymentOptions.paymentProvider,
  (order, cartPaymentMethod, inProgressPaymentInfo, paymentProvider) => {
    if (order) {
      return createPaymentMethodFromOrder(order, paymentProvider);
    }
    return inProgressPaymentInfo || cartPaymentMethod;
  }
);

/**
 * Return the current billing address associated with the cart.
 */
export const getCartBillingAddress = createSelector(
  state => state.checkout.billingAddress,
  getDefaultCountry,
  (address, defaultCountry) =>
    address && address.countryCode
      ? address
      : { countryCode: defaultCountry, ...address }
);

export const getBillingAddress = createSelector(
  getOrder,
  getCartBillingAddress,
  (order, cartBillingAddress) =>
    order ? order.billingAddress : cartBillingAddress
);

/**
 * Return an object with `address` and `paymentMethod` properties.
 */
export const getPaymentInfo = createSelector(
  getBillingAddress,
  getPaymentMethod,
  (address, paymentMethod) => ({ address, paymentMethod })
);

export const shouldShowCancellationNoticeForm = createSelector(
  getShippingAddress,
  address => {
    return (
      address.countryCode === 'US' &&
      cancellationNoticeStates.includes(address.state)
    );
  }
);

export const getStoreCredits = createSelector(
  getOrder,
  state => state.checkout,
  (order, cart) => {
    if (!order) {
      order = cart;
    }
    const storeCreditTotal = order.storeCreditTotal;
    const membershipStoreCredit = order.memberCreditAmount;
    const useMembershipStoreCredit = membershipStoreCredit > 0;
    const storeCreditsOnly = order.storeCreditAmount;
    const useStoreCredit = storeCreditsOnly > 0;

    return {
      useStoreCredit,
      useMembershipStoreCredit,
      storeCreditTotal,
      membershipStoreCredit,
      storeCreditsOnly,
    };
  }
);

export const getFaqLink = createSelector(
  state => state.domain.tld,
  domain => faqLinks[domain]
);

export function isProductSet(masterProduct = {}) {
  // product type id comes back in two different shapes
  const productTypeId =
    masterProduct.productTypeId || masterProduct.product_type_id;
  return productTypeId === productTypeSetId;
}

export const isProductCustomizable = createSelector(
  (state, props) => props.product,
  product =>
    product && product.tag_id_list
      ? product.tag_id_list.includes(customizableTag) ||
        product.tag_id_list.includes(customizableTag.toString()) // for search result
      : false
);

export const shouldProductUseCustomTag = createSelector(
  (state, props) => props.product,
  product => {
    const tagIdList = product?.tagIdList || product?.tag_id_list;
    return tagIdList
      ? tagIdList.includes(customTag) ||
          tagIdList.includes(customTag.toString())
      : false;
  }
);

export const isLinkedProductCustomizable = createSelector(
  (state, props) => props.product,
  product =>
    product &&
    product.linked_personalized_product &&
    product.linked_personalized_product.tag_id_list
      ? product.linked_personalized_product.tag_id_list.includes(
          customizableTag
        )
      : false
);

/* We determine if something is Preorderable by the Pre Order expiration date,
 * not looking at inventory, since it's regular inventory being ghosted.
 */
export const isPreorderable = createSelector(
  (state, props) => props.product || props.masterProduct || {},
  getNewDateFunction,
  (product, now) => {
    if (isProductSet(product)) {
      return checkPreorderForSets({ product, now });
    }

    return checkPreorderForSingleItem({ product, now });
  }
);

export function groupLineItems(items) {
  const productMap = items.reduce((result, item) => {
    let product;
    if (item.productTypeId === productTypeSetId) {
      product = result.get(item.groupKey);
      product = result.get(item.groupKey);
    } else {
      product = result.get(item.productId);
      product = result.get(item.productId);
    }
    if (product) {
      product.lineItems.push(item);
      product.quantity += 1;
    } else {
      // TODO: Figure out which other properties are line item specific.
      const { lineId, orderLineId, dateAdded, ...rest } = item;
      product = {
        ...rest,
        lineItems: [item],
        quantity: 1,
      };
      if (item.productTypeId === productTypeSetId) {
        result.set(item.groupKey, product);
      } else {
        result.set(item.productId, product);
      }
    }
    return result;
  }, new Map());

  // Map.values does not work in IE11, polyfill does not work for some reason.
  const values = [];
  productMap.forEach(value => values.push(value));
  return values;
}

/**
 * Get an array of cart products with `lineItems` and `quantity` properties.
 */
export const getGroupedCartItems = createSelector(getLineItems, groupLineItems);

export const isOutOfStock = (product, now) => {
  if (product.productTypeId === productTypeMembershipId) {
    return false;
  }
  // for sets
  if (
    (product.productTypeId === productTypeSetId ||
      product.productTypeId === byoSetProductTypeId) &&
    product.bundleItems.length
  ) {
    return product.bundleItems.some(item => {
      const quantityToCheck = checkPreorderForSingleItem({ product: item, now })
        ? item.availableQuantityPreorder
        : item.availableQuantity;
      return quantityToCheck < product.quantity;
    });
  }
  // for single product
  const quantityToCheck = checkPreorderForSingleItem({ product, now })
    ? product.availableQuantityPreorder
    : product.availableQuantity;
  return quantityToCheck < product.quantity;
};

export const cartHasOutOfStockItems = createSelector(
  getLineItems,
  getNewDateFunction,
  (products, now) => products.some(product => isOutOfStock(product, now))
);

export const getShippingCountries = createSelector(
  state => state.storeGroup.shippingOptions,
  shippingOptions => shippingOptions.shippingCountries || []
);

export const getCountryList = createSelector(
  (state, props) => props.addressType,
  (state, props) => props.countryList,
  getShippingCountries,
  (addressType, countryList, shippingCountries) => {
    if (countryList) {
      return countryList;
    }
    if (addressType === 'shipping') {
      const shippingCountriesSet = new Set(shippingCountries);
      // We could just return `shippingCountries`, but this will filter out
      // anything bogus and preserve the ordering we want.
      return allCountries.filter(country => shippingCountriesSet.has(country));
    }
    return allCountries;
  }
);

export const hasInvalidCountry = createSelector(
  (state, props) => props.address,
  getCountryList,
  (address, countryList) => {
    if (countryList && address.countryCode) {
      return !countryList.includes(address.countryCode);
    }
    return !address.countryCode;
  }
);

export const getOrderIDs = createSelector(
  state => state.account.orders.ordersByPage,
  ordersByPage =>
    Object.keys(ordersByPage.pages).reduce((orderIDs, pageNumber) => {
      return [...orderIDs, ...ordersByPage.pages[pageNumber].orderIDs];
    }, [])
);

export const getOrderList = createSelector(
  getOrderIDs,
  state => state.account.orders.ordersByID,
  (orderIDs, ordersByID) => orderIDs.map(id => ordersByID[id])
);

export const getReturnItemProducts = createSelector(
  state => state.products.byMasterProductId,
  (state, props) => props.selectedItems,
  (productMap, items) => {
    return items.map(item => ({
      item,
      product: productMap[item.masterProductId],
    }));
  }
);

export const createGetAssetData = () => {
  return createSelector(
    (state, props) =>
      props.data ||
      (state.assets.containers[props.name] &&
        state.assets.containers[props.name].data),
    (state, props) => props.name,
    (assetData, name) => {
      if (assetData) {
        /* TEMPORARY remove duplicated asset ids */
        const assetIDs = new Set();

        // Annoyingly, the returned data does not include the actual key it
        // was fetched by, both the `label` and `key` properties can differ from
        // the key used to fetch. So add a `name` property.
        const container = { ...assetData.container, name };

        const assets = assetData.assets
          .filter(asset => {
            if (asset.filtered) {
              return false;
            }
            const isDuplicate = assetIDs.has(asset.id);
            assetIDs.add(asset.id);
            return !isDuplicate;
          })
          .map(asset => {
            /* Process flags */
            const { customVars } = asset.options;

            // Include flags from legacy `categoryFilters` var.
            const legacyFlags = customVars.categoryFilters
              ? customVars.categoryFilters
                  .split(',')
                  .map(item => item.trim().toLowerCase())
                  .filter(item => item)
              : [];

            const flags = customVars.flags
              ? customVars.flags
                  .split(',')
                  .map(item => item.trim().toLowerCase())
                  .filter(item => item)
              : [];

            return {
              ...asset,
              flags: new Set(legacyFlags.concat(flags)),
              // Add container info to individual assets for easier filtering.
              container,
            };
          });

        assetData = {
          ...assetData,
          container,
          assets,
        };
      }
      return assetData;
    }
  );
};

export const getAssetData = createGetAssetData();

/**
 * Return an array with countries for sepa payment method
 */
export const getSepaCountryList = createSelector(
  getStorePaymentSettings,
  paymentSettings => {
    if (paymentSettings && paymentSettings.sepa) {
      const countries = new Set(paymentSettings.sepa.countries);
      // We could just return `paymentSettings.sepa.countries`, but this will
      // filter out anything bogus and preserve the ordering we want.
      return allCountries.filter(country => countries.has(country));
    }
    return [];
  }
);

/**
 * Filter the waitlist with the matching product.
 */
export const filterWaitlist = createSelector(
  state => state.account.waitlist.items || [],
  (state, product) => product || {},
  (items, product) => {
    if (product.master_product_id == null) {
      // MembershipItem does not have a master product id, so use product id instead.
      return items.some(item => item.productId === product.product_id);
    } else {
      return items.some(
        item => item.masterProductId === product.master_product_id
      );
    }
  }
);

export const getPromocode = createSelector(
  state => state.checkout.discounts,
  discounts => (discounts && discounts[0] ? discounts[0].promoCode : undefined)
);

const EMPTY_REFINEMENTS = {
  categories: [],
  collections: [],
  colorTags: [],
  colors: [],
  sizes: [],
  braSizes: [],
  braletteSizes: [],
  lingerieSizes: [],
  undieSizes: [],
};

function buildProductList(storeKey, products, startPage = 1) {
  const allProducts = [];
  const filterProducts = products.masterProductIdsByFilter[storeKey];
  if (!filterProducts) {
    return [];
  }
  let pageNumber = startPage || 1;
  while (true) {
    const page = filterProducts.pages[pageNumber];
    if (!page || !page.responseIds.length) {
      break;
    }
    allProducts.push(...page.responseIds);
    pageNumber += 1;
  }
  // need to investigate this some more
  // randomly the server will respond with a product we've already received on another page
  // but we will dedupe to make sure we don't display it twice
  const removeDups = new Set([...allProducts]);
  const dedupedArray = Array.from(removeDups);
  return dedupedArray.map(productId => products.byMasterProductId[productId]);
}

export const getUserProfileProductFilters = createSelector(
  (state, props) => props.filters,
  (state, props) => props.applyUserSizes,
  state => state.customer,
  (filters, applyUserSizes, profile) => {
    const {
      defaultTagId,
      optionSignatures = [],
      braSizes = [],
      braletteSizes = [],
      lingerieSizes = [],
      undieSizes = [],
    } = filters;
    // Only add user sizes if `applyUserSizes` prop is true and the filters do
    // not already include size selections (including `defaultTagId`).
    if (
      applyUserSizes &&
      !optionSignatures.length &&
      !braSizes.length &&
      !braletteSizes.length &&
      !lingerieSizes.length &&
      !undieSizes.length &&
      !defaultTagId
    ) {
      const userSizes = getProfileSizesForGrid(profile, filters);

      if (Object.keys(userSizes).length) {
        if (filters.categorized) {
          return {
            ...filters,
            ...userSizes,
          };
        }
        return {
          ...filters,
          optionSignatures: Object.values(userSizes),
        };
      }
    }
    return filters;
  }
);

export const getClearanceFplId = createSelector(
  // Defined later in this file - for now, wrap in function instead of moving
  // for easier diff/review purposes...
  (state, props) => getProductsLinkAssetsByUrl(state, props),
  state => getMembership(state).isVip,
  (productsLinkAssetsByUrl, isVip) => {
    const saleAsset =
      productsLinkAssetsByUrl[isVip ? '/sale' : '/sale-secondary'];
    if (saleAsset && saleAsset.fpl) {
      return saleAsset.fpl;
    }
  }
);

export const getByoSaleFplIds = createSelector(
  state =>
    state.dynamicRoutes?.routeInfoByPath?.['/byo/sale']?.data?.productJson
      ?.backgroundFplIds,
  byoSaleFpls => {
    return byoSaleFpls || [];
  }
);

export const getExcludedTagIds = ({
  fpls = null,
  isVip,
  includeFplOnly = Boolean(fpls),
  includeCollectibles = false,
  isUnlockedXclusiveProductVisible = false,
}) => {
  const excludeTagIds = [];
  if (!includeFplOnly) {
    excludeTagIds.push(fplOnlyTagId);
  }

  if (!includeCollectibles) {
    excludeTagIds.push(collectibleTag);
  }

  if (isVip) {
    excludeTagIds.push(leadOnlyTagId);
  } else {
    excludeTagIds.push(vipOnlyTagId);
  }

  if (!isUnlockedXclusiveProductVisible) {
    excludeTagIds.push(unlockedXclusiveTag);
  }

  return excludeTagIds;
};

export const isPageSale = asPath => {
  return !!(asPath.match(/^(\/sale-secondary)/) || asPath.match(/^(\/sale)/));
};

export const getFilteredExcludedFplsByAccount = ({
  isVip,
  tld,
  excludedFpls,
}) => {
  const vipSaleFpl = saleFpls.vip[tld];
  const leadSaleFpl = saleFpls.lead[tld];

  if (isVip) {
    return {
      excludeFpls: [hideProduct[tld]],
      fpls: [vipSaleFpl],
    };
  } else {
    return {
      excludeFpls: [hideProduct[tld]],
      fpls: [leadSaleFpl],
    };
  }
};

export const isNMP = createSelector(getMembership, membership => {
  return membership.membershipTypeId === nmpMembershipTypeId;
});

export const isEmp = createSelector(getMembership, membership => {
  return membership.membershipTypeId === empMembershipTypeId;
});

export const isEmpVip = createSelector(
  isEmp,
  getMembership,
  (isEmp, membership) => {
    return isEmp && membership.isVip && !membership.isDowngraded;
  }
);

export const isBilledEmpVip = createSelector(
  isEmpVip,
  getMembershipPeriod,
  (isEmpVip, membershipPeriod) => {
    return (
      isEmpVip &&
      membershipPeriod.statusCode === SkipTheMonthStatus.EMP_BILLED &&
      membershipPeriod.networkStatus.isUpToDate
    );
  }
);

export const isUnlockedXclusiveProductVisible = createSelector(
  isBilledEmpVip,
  isEmp,
  getMembershipPeriod,
  (isBilledEmpVip, isEmp, membershipPeriod) => {
    if (!membershipPeriod.networkStatus.isUpToDate) {
      return true;
    }
    return isBilledEmpVip || !isEmp;
  }
);

export const getCreditsRemaining = createSelector(
  isEmp,
  getMembership,
  (isEmp, membership) => {
    return isEmp
      ? membership.availableTokenQuantity
      : membership.membershipCredits;
  }
);

export const getStateDependentProductFilters = createSelector(
  getUserProfileProductFilters,
  getClearanceFplId,
  state => state.domain.tld,
  state => state.borderfree.isBorderfreeCustomer,
  state => getMembership(state).isLead,
  state => getMembership(state).isVip,
  (state, props) => !!props.isFavoritesContext,
  isUnlockedXclusiveProductVisible,
  (
    filters,
    clearanceFplId,
    tld,
    isBorderfreeCustomer,
    isLead,
    isVip,
    isFavoritesContext,
    isUnlockedXclusiveProductVisible
  ) => {
    // set excludeTagIds to be empty array for favorites page
    // since all the products the user favorited should be seen in favorites page
    // for example vips can see lead only products, and they can favorite them
    const excludeTagIds = isFavoritesContext
      ? []
      : getExcludedTagIds({
          fpls: filters.fpls,
          isVip,
          includeFplOnly: filters.includeFplOnly, // override the default includeFplOnly if we pass one
          includeXclusive: filters.includeXclusive,
          isUnlockedXclusiveProductVisible,
        });

    const excludeFpls = [];
    if (filters.excludeFpls) {
      filters.excludeFpls.forEach(fpl => {
        const idsByDomain = featuredProductLists[fpl];
        if (idsByDomain) {
          // If found, the fpl was a named alias for which we'll resolve the ID
          // via the config based on the current TLD.
          if (idsByDomain[tld]) {
            excludeFpls.push(idsByDomain[tld]);
          }
        } else {
          // Otherwise, assume the fpl was already an ID.
          excludeFpls.push(fpl);
        }
      });
    }

    if (
      clearanceFplId &&
      filters.fpls !== clearanceFplId &&
      !isFavoritesContext
    ) {
      excludeFpls.push(clearanceFplId);
    }

    return {
      ...filters,
      excludeFpls,
      excludeTagIds,
      warehouseId: isBorderfreeCustomer ? borderfreeWarehouse : null,
    };
  }
);

/* Warehouse
 * warehouse ids map to a specific region. regions determine low stock thresholds.
 */
export const getRegion = createSelector(
  state => state.borderfree.isBorderfreeCustomer,
  state => state.domain.tld,
  state => state.intl.geoCountry,
  (isBorderfreeCustomer, tld, geoCountry) => {
    if (isBorderfreeCustomer) {
      return 'BF';
    } else if (tld === '.com') {
      return geoCountry === 'CA' ? 'CA' : 'US';
    } else if (tld === '.co.uk') {
      return 'UK';
    }
    return 'EU';
  }
);

export const getWarehouseIds = createSelector(
  state => getRegion(state),
  region => {
    return warehouseIdsByRegion[region];
  }
);

/**
 * @import
 * @type {import('./types').getDefaultProductFilters}
 */
export const getDefaultProductFilters = createSelector(
  getClearanceFplId,
  getByoSaleFplIds,
  state => getMembership(state).membershipLevelGroup,
  state => state.borderfree.isBorderfreeCustomer,
  state => getMembership(state).isLead,
  state => getMembership(state).isVip,
  state => state.domain.tld,
  (state, baseFilters) => baseFilters,
  (state, baseFilters, options) => options,
  isUnlockedXclusiveProductVisible,
  state => state.intl.geoCountry,
  getWarehouseIds,
  (
    clearanceFplId,
    byoSaleFplIds,
    membershipLevelGroup,
    isBorderfreeCustomer,
    isLead,
    isVip,
    tld,
    baseFilters,
    options = {},
    isUnlockedXclusiveProductVisible,
    geoCountry,
    warehouseIds
  ) => {
    let {
      filterByMembershipLevelGroup,
      includeOutOfStock = true,
      excludeFplList = ['hideProduct'],
      includeFplOnly = false,
      autoExcludeClearanceFplIds = true,
    } = options;
    let {
      fpls,
      excludeFpls = [],
      excludeTagIds = [],
      aggregationFilter = {},
      excludePreorderProducts,
    } = baseFilters;
    const hasSizeFilters = Object.entries(aggregationFilter).some(
      ([key, value]) => {
        return key.startsWith('size') && value.length;
      }
    );

    if (hasSizeFilters) {
      includeOutOfStock = false;
    }
    getExcludedTagIds({
      fpls,
      isLead,
      isVip,
      includeFplOnly, // override the default includeFplOnly if we pass one
      isUnlockedXclusiveProductVisible,
    }).forEach(tagId => {
      if (!excludeTagIds.includes(tagId)) {
        excludeTagIds = [...excludeTagIds, tagId];
      }
    });

    if (isBorderfreeCustomer) {
      excludePreorderProducts = true;
    }

    if (excludeFplList) {
      excludeFplList.forEach(fpl => {
        const idsByDomain = featuredProductLists[fpl];
        if (idsByDomain) {
          // If found, the fpl was a named alias for which we'll resolve the ID
          // via the config based on the current TLD.
          if (idsByDomain[tld] && !excludeFpls.includes(idsByDomain[tld])) {
            excludeFpls.push(idsByDomain[tld]);
          }
        } else {
          // Otherwise, assume the fpl was already an ID.
          excludeFpls.push(fpl);
        }
      });
    }
    // Exclude clearanceFplId from grid results, unless it is a sale or BYO sale grid
    if (
      autoExcludeClearanceFplIds &&
      clearanceFplId &&
      !(
        (baseFilters.backgroundFplIds &&
          baseFilters.backgroundFplIds.includes(clearanceFplId)) ||
        (baseFilters.fpls && baseFilters.fpls.includes(clearanceFplId)) ||
        (baseFilters.backgroundFplIds &&
          baseFilters.backgroundFplIds.some(fpl =>
            byoSaleFplIds.includes(fpl)
          )) ||
        (baseFilters.fpls &&
          baseFilters.fpls.some(fpl => byoSaleFplIds.includes(fpl)))
      )
    ) {
      excludeFpls.push(clearanceFplId);
    }

    return {
      ...baseFilters,
      excludeFpls,
      excludeTagIds,
      ...(filterByMembershipLevelGroup && { membershipLevelGroup }),
      excludePreorderProducts,
      includeOutOfStock,
      warehouseId: geoCountry === 'CA' ? warehouseIds : null,
    };
  }
);

/**
 * We are attempting to limit the amount of data returned from products/v2.
 * This list was composed by setting up a proxy object in reactui
 * ProductContext to asses which properties are needed for grids.
 *
 * Properties of NOTE:
 * - Lots of fields added due to tracking. See `getTrackProductDetails`.
 * - related_product_id_object_list: we only check if length > 1 so we are
 *   only requesting the first 2 if available
 *   - reactui/MoreColorsLabel
 */
const DEFAULT_PRODUCT_FIELDS = [
  'alias',
  'available_quantity_any_profile',
  'available_quantity_master',
  'available_quantity_preorder_master',
  'average_overall_review_filtered_stores',
  'average_review_all_stores',
  'average_review',
  'color',
  'component_count',
  'component_product_id_object_list',
  'date_expected',
  'date_preorder_expires',
  'default_product_category_id',
  'default_product_category_label',
  'default_unit_price',
  'featured_product_location_id_list',
  'item_number',
  'label',
  'master_product_id',
  'meta_data[0]',
  'permalink',
  'product_category_id_list',
  'product_images',
  'product_option_profile_id_object_list.[0]',
  'product_type_id',
  'promos',
  'related_product_id_object_list.[0]',
  'related_product_id_object_list.[1]',
  'retail_unit_price',
  'review_count',
  'sale_unit_price',
  'short_description',
  'tag_id_list',
  'warehouse_id_object_list',
];

export const getDefaultProductFields = () => DEFAULT_PRODUCT_FIELDS;

export const getProductFilterState = createSelector(
  (state, ownProps) => ownProps.filters,
  getUserProfileProductFilters,
  getStateDependentProductFilters,
  state => state.products,
  (state, ownProps) => ownProps.startPage || 1,
  (inputFilters, filters, requestFilters, products, startPage) => {
    const requestParams = filtersToRequestParams(requestFilters);
    const storeKey = requestParamsToStoreKey(requestParams);
    const aggregationKey = requestParamsToAggregationKey(requestParams);
    const productList = buildProductList(storeKey, products, startPage);
    const filterProducts = products.masterProductIdsByFilter[storeKey];
    return {
      inputFilters,
      filters,
      didTransformFilters: filters !== inputFilters,
      requestParams,
      storeKey,
      canPaginate: filterProducts ? filterProducts.canPaginate : false,
      refinements: products.aggregations[aggregationKey] || EMPTY_REFINEMENTS,
      products: productList,
    };
  }
);

export const isExclusiveProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.isExtraExclusive) {
      // Flag available in cart product data
      return true;
    } else if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(xclusiveTag);
    }
    return false;
  }
);

export const hasExclusiveProducts = createSelector(getLineItems, products =>
  products.some(product => product.isExtraExclusive)
);

export const isXtraVipBox = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(xtraVipBoxTag);
    }
    return false;
  }
);

export const isXtraVipBoxInCart = createSelector(
  state => state.checkout.items,
  items => {
    return items.some(
      item =>
        item.tagIds && item.tagIds.includes(xtraVipBoxTag) && isProductSet(item)
    );
  }
);

export const isXtraVipBoxInOrder = createSelector(
  state => state.checkout.order,
  (state, props) => props.order,
  (orderFromState, orderFromProps) => {
    const order = orderFromState || orderFromProps;
    return (
      order &&
      order.orderLines.some(
        item =>
          item.tagIds &&
          item.tagIds.includes(xtraVipBoxTag) &&
          isProductSet(item)
      )
    );
  }
);

export const isDiscreetPackagingInOrder = createSelector(
  state => state.checkout.order,
  (state, props) => props.order,
  (orderFromState, orderFromProps) => {
    const order = orderFromState || orderFromProps;
    return !!(order && order.isDiscreetPackaging);
  }
);

export const isUnbreakableSet = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(unbreakableSetTag);
    }
    return false;
  }
);

export const isHiddenFromGrid = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      const hiddenTags = [hideFromGridTag, collectibleTag];
      return hiddenTags.some(tagId => product.tag_id_list.includes(tagId));
    }
    return false;
  }
);

export const isRihannasPickProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(rihannasPickTag);
    }
    return false;
  }
);

export const isNewProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(newTag);
    }
    return false;
  }
);

export const isBestSellerProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(bestSellerTag);
    }
    return false;
  }
);

export const isBackInStockProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(backInStockTag);
    }
    return false;
  }
);

export const isTopRatedProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(topRatedTag);
    }
    return false;
  }
);

export const isTrendingProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(trendingTag);
    }
    return false;
  }
);

export const isClfProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(clfTag);
    }
    return false;
  }
);

export const isFWProduct = createSelector(
  (state, props) => props.product,
  state => state.domain.tld,
  (product, tld) => {
    if (product.featured_product_location_id_list) {
      // List available in product API data
      return product.featured_product_location_id_list.includes(fwFPLs[tld]);
    }
    return false;
  }
);

export const isUnlockedXclusiveProduct = createSelector(
  (state, props) => props.product,
  isBilledEmpVip,
  (product, isBilledEmpVip) => {
    return (
      isBilledEmpVip &&
      product.tag_id_list &&
      product.tag_id_list.includes(unlockedXclusiveTag)
    );
  }
);

export const isYouniversalProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      // List available in product API data
      return product.tag_id_list.includes(youniversalTag);
    }
    return false;
  }
);

export const isClearanceItem = createSelector(
  getClearanceFplId,
  (state, props) => props.product,
  state => state.domain.tld,
  (clearanceFplId, product = {}, tld) => {
    const fplIds =
      product.featured_product_location_id_list ||
      product.featuredProductLocationIdList
        ? product.featured_product_location_id_list ||
          product.featuredProductLocationIdList
        : product.fplIds;
    if (fplIds) {
      // List available in product API data
      return fplIds.includes(clearanceFplId);
    }
    return false;
  }
);

export const isByoSaleItem = createSelector(
  getByoSaleFplIds,
  (state, props) => props.product,
  (byoSaleFplIds, product) => {
    const fplIds =
      product.featured_product_location_id_list ||
      product.featuredProductLocationIdList
        ? product.featured_product_location_id_list ||
          product.featuredProductLocationIdList
        : product.fplIds;
    if (fplIds) {
      return fplIds.some(fplId => byoSaleFplIds.includes(fplId));
    }
  }
);

export const isCountdownActive = createSelector(
  state => state.time.pageRenderDate,
  pageRenderDate => {
    pageRenderDate = +parseDate(pageRenderDate);
    const endDate = +parseDate(fashionShow.countDownEndDate);
    return pageRenderDate < endDate;
  }
);

function isPromoActive(promo, date) {
  const startDate = +parseDate(promo.startDate);
  const endDate = +parseDate(promo.endDate);
  return date >= startDate && date < endDate;
}

export const getActivePromos = createSelector(
  state => state.domain,
  state => state.time.pageRenderDate,
  (domain, pageRenderDate) => {
    pageRenderDate = +parseDate(pageRenderDate);
    return Object.keys(promos)
      .map(key => promos[key])
      .filter(promo => {
        if (
          !domainMatchesList(domain, promo.includeDomains, promo.excludeDomains)
        ) {
          return false;
        }
        return isPromoActive(promo, pageRenderDate);
      });
  }
);

export const allowExclusiveCheckout = createSelector(getActivePromos, promos =>
  promos.some(promo => promo.context.allowExclusiveCheckout)
);

export const isExclusiveSoldOut = createSelector(getActivePromos, promos =>
  promos.some(promo => promo.context.isExclusiveSoldOut)
);

// check for fake sold out tag, then auto sell out VIP box product
export const isAutoSoldOut = createSelector(
  (state, props) => props.product,
  isXtraVipBox,
  getNewDateFunction,
  (masterProduct, xtraVipBox, newDate) => {
    // only apply auto sell out on xtraVipBox
    if (
      xtraVipBox &&
      autoSellOutEnabled &&
      autoSellOutDate != null &&
      isProductTaggedGenericSoldOut(masterProduct.tag_id_list)
    ) {
      const date = newDate();
      const pacificDayString = toPacificDateString(date, 'D');
      const pacificDay = parseInt(pacificDayString, 10);

      return pacificDay >= autoSellOutDate;
    }
    // do normal fake soldout of product
    return isProductTaggedGenericSoldOut(masterProduct.tag_id_list);
  }
);

export const isFakeSoldOut = createSelector(
  (state, props) => props.product,
  isAutoSoldOut,
  (masterProduct, autoSoldOut) => autoSoldOut
);

export const isProductTaggedGenericSoldOut = tagIdList =>
  tagIdList && tagIdList.includes(fakeSoldOutTagId);

/**
 * Identifies is a product is tagged as sold out
 * for either ['Sold Out - VIP', or 'Sold Out - Lead']
 *
 * @param {Object} product - product object
 * @return {boolean} returns true if a product is tagged as
 *                   either 'Sold Out - VIP' or 'Sold Out - Lead'
 *                   false otherwise
 *
 * */

export const isProductTaggedLeadOrVipSoldOut = createSelector(
  (state, props) => getMembership(state),
  (state, props) => props.product,
  (membership, product) => {
    const { tag_id_list: tagIdList } = product;

    if (membership.isLead && product.tag_id_list) {
      return tagIdList.includes(soldOutLeadTag);
    } else if (membership.isVip && product.tag_id_list) {
      return tagIdList.includes(soldOutVipTag);
    }

    return false;
  }
);

export const shouldHideRelatedProducts = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      return product.tag_id_list.includes(hideRelatedTagId);
    } else if (product.tagIdList) {
      return product.tagIdList.includes(hideRelatedTagId);
    }
  }
);

export function getLinkedProduct(masterProduct) {
  let linkedProduct = masterProduct.linked_master_product
    ? masterProduct.linked_master_product
    : null;
  if (Array.isArray(linkedProduct) && linkedProduct.length > 0) {
    linkedProduct = linkedProduct[0];
  }
  return linkedProduct;
}

export const isSoldOut = createSelector(
  (state, props) => props.product,
  (state, props) => (props.isOnGrid ? props.isOnGrid : false),
  isExclusiveProduct,
  isExclusiveSoldOut,
  isFakeSoldOut,
  isUnbreakableSet,
  isXtraVipBox,
  isPreorderable,
  isProductTaggedLeadOrVipSoldOut,
  getNewDateFunction,
  (
    product,
    isOnGrid,
    isExclusive,
    isExclusiveSoldOut,
    isFakeSoldOut,
    isUnbreakableSet,
    isXtraVipBox,
    isPreorderable,
    isProductTaggedLeadOrVipSoldOut,
    now
  ) => {
    const linkedProduct = getLinkedProduct(product);
    if (linkedProduct && !isOnGrid) {
      if (
        isFakeSoldOut &&
        isProductTaggedGenericSoldOut(linkedProduct.tag_id_list)
      ) {
        return true;
      }

      const actualQuantity = isPreorderable
        ? product.available_quantity_preorder_master
        : product.available_quantity_master;
      const actualLinkedProductQuantity = isPreorderable
        ? product.available_quantity_preorder_master
        : linkedProduct.available_quantity_master;
      return actualQuantity <= 0 && actualLinkedProductQuantity <= 0;
    }

    if (
      isProductTaggedLeadOrVipSoldOut &&
      isProductTaggedGenericSoldOut(product.tag_id_list)
    ) {
      return isFakeSoldOut;
    }
    // check if it is tagged as sold out on either vip/lead
    if (isProductTaggedLeadOrVipSoldOut) {
      return true;
    }

    if (isFakeSoldOut) {
      return true;
    }

    if (isExclusive && isExclusiveSoldOut) {
      return true;
    }
    if (isProductSet(product)) {
      if (
        product.available_quantity_any_profile != null &&
        !product.products_in_set
      ) {
        return false;
      } else if (product.available_quantity_any_profile != null) {
        return product.available_quantity_any_profile <= 0;
      } else if (product.products_in_set) {
        return product.products_in_set.some(
          /* FIXME: Unfortunately `available_quantity_master` does not take into
           account the inventory of all colors for a single set item, which is
           what we actually want here. */
          setItem => {
            const isItemInSetPreorderable = checkPreorderForSingleItem({
              product: setItem,
              now,
            });
            const colors = setItem.related_product_id_object_list;
            const hasColors = colors && colors.length;
            if (hasColors) {
              // Every color part of the set is sold out
              return colors.every(color => {
                let isColorPartOfSet = true;
                if (color.tag_id_list) {
                  const isPartOfXtraVipBox =
                    color.tag_id_list.includes(xtraVipBoxTag);
                  /* since we no longer check the hideFromGrid tag on individual items for xtra VIP box
                   * we will use the xtraVipBoxTag instead
                   */
                  if (isUnbreakableSet && isXtraVipBox) {
                    isColorPartOfSet = isPartOfXtraVipBox;
                  }
                }
                const sizes = color.product_id_object_list;
                // Every size in this color is sold out, only check if the color part of the set
                if (isColorPartOfSet) {
                  return sizes.every(size =>
                    isItemInSetPreorderable
                      ? size.available_quantity_preorder <= 0
                      : size.available_quantity <= 0
                  );
                }
                return true;
              });
            } else {
              return isItemInSetPreorderable
                ? setItem.available_quantity_preorder_master <= 0
                : setItem.available_quantity_master <= 0;
            }
          }
        );
      }
    }
    const actualQuantity = isPreorderable
      ? product.available_quantity_preorder_master
      : product.available_quantity_master;
    return actualQuantity <= 0;
  }
);

// TODO: 'membership_price' is a placeholder, update with real API change
export const getMembershipPrice = state =>
  state.storeGroup.membership_price || membershipPrice;

export function getMembershipProduct() {
  // Was only used in an old promo component.
  throw new Error('getMembershipProduct is deprecated');
}

export function getProductWarehouseQuantity(
  product,
  warehouseIds,
  isSoldOut,
  isPreorderable
) {
  if (isSoldOut) {
    return 0;
  }

  if (isPreorderable) {
    return product.available_quantity_preorder_master;
  }

  const warehouses = product.warehouse_id_object_list;
  if (warehouses == null) {
    return product.available_quantity_master;
  }

  const quantity = warehouses
    .filter(warehouse => {
      return warehouseIds.find(id => warehouse.warehouse_id === id);
    })
    .reduce((total, warehouse) => {
      return warehouse.available_quantity + total;
    }, 0);
  return Math.max(0, quantity);
}

export const getWarehouseQuantity = createSelector(
  (state, props) => props.product,
  state => getWarehouseIds(state),
  isSoldOut,
  isPreorderable,
  (product, warehouseIds, isSoldOut, isPreorderable) => {
    return getProductWarehouseQuantity(
      product,
      warehouseIds,
      isSoldOut,
      isPreorderable
    );
  }
);

export const getLinkedProductWarehouseQuantity = createSelector(
  (state, props) => props.product,
  state => getWarehouseIds(state),
  isSoldOut,
  (product, warehouseIds, isSoldOut) => {
    const linkedProduct = getLinkedProduct(product);

    const quantity = linkedProduct
      ? getProductWarehouseQuantity(linkedProduct, warehouseIds, isSoldOut)
      : null;
    return quantity;
  }
);

export const getSizeQuantity = createSelector(
  (state, props) => props.product,
  (state, props) => props.filters,
  state => getWarehouseIds(state),
  isPreorderable,
  (product, filters, warehouseIds, isPreorderable) => {
    const sizeQuantity = [];
    const userSizes = getUserSizesForGrid(filters);

    if (userSizes && userSizes.length === 1) {
      if (product && product.product_id_object_list) {
        product.product_id_object_list.forEach(productItem => {
          const foundItem = userSizes.find(
            item => item === productItem.option_signature
          );
          if (foundItem) {
            const warehouses = productItem.warehouse_id_object_list;
            if (isPreorderable) {
              sizeQuantity.push(
                Math.max(0, productItem.available_quantity_preorder)
              );
            }
            if (warehouses) {
              const warehouseSizeQuantity = getRegionWarehouseSizeQuantity(
                warehouses,
                warehouseIds
              );
              sizeQuantity.push(warehouseSizeQuantity);
            } else {
              sizeQuantity.push(Math.max(0, productItem.available_quantity));
            }
          }
        });
      }
      if (sizeQuantity.length > 0) {
        return Math.min(...sizeQuantity);
      }
    }

    return null;
  }
);

export const getMemberJoinDate = createSelector(
  state => getMembership(state).dateTimeAdded,
  dateTimeAdded => (dateTimeAdded ? parseDate(dateTimeAdded) : null)
);

/**
 *  Get the DM Variant Codes
 *  make sure we're in the right domain
 * verify that we have what we need and the promo hasnt expired
 */
const hasDmPromoExpired = createSelector(
  state => getMembership(state).membershipLevelGroupId,
  state => getMembership(state).dateTimeAdded,
  getDateNowFunction,
  (membershipLevel, dateTimeAdded, now) => {
    // if no dateTimeAdded the user should be anonymous
    if (!dateTimeAdded) {
      return false;
    }
    const currentDate = now();
    const expiryDate = parseDate(dateTimeAdded).getTime() + dmGatewayExpiration;
    const hasExpired = currentDate > expiryDate;
    if (membershipLevel === 100 && !hasExpired) {
      return false;
    } else {
      return true;
    }
  }
);

export const currentDmGateway = createSelector(
  state => state.domain,
  state => getSession(state).dmGatewayCode,
  state => state.borderfree.isBorderfreeCustomer,
  hasDmPromoExpired,
  (domain, dmgCode, isBorderfreeCustomer, dmPromoExpired) => {
    if (isBorderfreeCustomer || !dmgCode || dmPromoExpired) {
      return undefined;
    }
    const dmgCodeObject = dmGatewayCodes[dmgCode];
    if (
      dmgCodeObject &&
      domainMatchesList(
        domain,
        dmgCodeObject.includeDomains,
        dmgCodeObject.excludeDomains
      )
    ) {
      return dmgCodeObject;
    } else {
      return undefined;
    }
  }
);

export const getPageRenderDate = createSelector(
  state => state.time.pageRenderDate,
  pageRenderDate => parseDate(pageRenderDate)
);

export const getServerRenderDate = createSelector(
  state => state.time.serverRenderDate,
  serverRenderDate => parseDate(serverRenderDate)
);

export const isSweepstakesActive = createSelector(
  getDateNowFunction,
  dateNow => {
    const currentDate = dateNow();
    const startDate = parseDate(fashionShow.sweepstakesStartDate);
    const endDate = parseDate(fashionShow.sweepstakesEndDate);
    return startDate < currentDate && currentDate < endDate;
  }
);

export const getNewVipMembershipInfo = createSelector(
  state => getMembership(state),
  getDateNowFunction,
  (membership, getDateNow) => {
    const day = 1000 * 60 * 60 * 24;
    const dateActivated = parseDate(membership.dateActivated);
    const getIsVipNew = () => {
      return dateActivated > parseDate(getDateNow()).setDate(1);
    };
    const vipGracePeriodEndDate = parseDate(
      new Date(dateActivated.getFullYear(), dateActivated.getMonth() + 1, 1)
    );
    const vipGracePeriodStartDate = vipGracePeriodEndDate - day * 14;
    const getIsVipInGracePeriod = () => {
      return getIsVipNew() && dateActivated > vipGracePeriodStartDate;
    };

    return {
      getIsVipNew,
      getIsVipInGracePeriod,
    };
  }
);

export const isVipInBillingPeriod = createSelector(
  getMembershipPeriod,
  getDateNowFunction,
  (membershipPeriod, getDateNow) => {
    const now = getDateNow();
    const billingDateDue = parseDate(membershipPeriod.dateDue);

    return now < billingDateDue;
  }
);

export const isMembershipDuringCycle = createSelector(
  getMembership,
  getNewVipMembershipInfo,
  getDateNowFunction,
  getNewDateFunction,
  (membership, newVipMembershipInfo, getDateNow, getNewDate) => {
    const isVipInGracePeriod = newVipMembershipInfo.getIsVipInGracePeriod();
    const now = getDateNow();

    const cycleDate = isVipInGracePeriod
      ? getNewDate().setDate(11)
      : getNewDate().setDate(6);

    return now < parseDate(cycleDate).setHours(0, 0, 0, 0);
  }
);

export const isDateFirstToFifthOfTheMonth = createSelector(
  getDateNowFunction,
  getNewDateFunction,
  (getDateNow, getNewDate) => {
    const now = parseDate(getDateNow());
    const firstToFifthOfTheMonth = getNewDate().setDate(6);
    return now <= parseDate(firstToFifthOfTheMonth).setHours(0, 0, 0, 0);
  }
);

export function normalizeAssetLink(asset) {
  let { pageUrl: link = '' } = asset.options.customVars;

  if (link.indexOf('/') !== 0) {
    link = `/${link}`;
  }

  return link;
}

export const getCustomLinkAssets = createSelector(
  state => getAssetData(state, { name: 'global_template' }),
  (state, props = {}) => props.assets,
  (assetContainer, assets) => {
    if (!assets) {
      assets = assetContainer ? assetContainer.assets : [];
    }
    return assets.map(asset => {
      const link = normalizeAssetLink(asset);
      const { containerName, id } = asset.options.customVars;

      return {
        link,
        containerName,
        id,
        asset,
        isTemplate: true,
      };
    });
  }
);

export const getCustomLinkAssetsByUrl = createSelector(
  getCustomLinkAssets,
  links => {
    return links.reduce((linksByUrl, link) => {
      if (link.link) {
        linksByUrl[link.link] = link;
      }
      return linksByUrl;
    }, {});
  }
);

export const getPreorderShipDate = createSelector(
  (state, props) => props.product,
  getNewDateFunction,
  (product, now) => {
    // for sets, preorder expire date will be calculated on back end
    // and it will return the latest date among all items in the set
    const preorderExpires =
      product?.date_preorder_expires || product?.datePreorderExpires;
    return preorderExpires && now() <= parseDate(preorderExpires)
      ? preorderExpires
      : null;
  }
);

// Create an instance for memoizing this particular asset.
const getProductsLinkAssetData = createGetAssetData();

export const getProductsLinkAssets = createSelector(
  state => getProductsLinkAssetData(state, { name: 'products_image_banner' }),
  (state, props = {}) => props.assets,
  (assetContainer, assets) => {
    if (!assets) {
      assets = assetContainer ? assetContainer.assets : [];
    }

    return assets.map(asset => {
      const {
        label,
        categoryFilters: categoryFiltersString = '',
        fpl,
        dmgCode,
        persona,
      } = asset.options.customVars;

      const link = normalizeAssetLink(asset);
      const parsedSet = new Set(
        categoryFiltersString
          .split(',')
          .map(item => item.trim().toLowerCase())
          .filter(item => item)
      );

      let vipStatus;

      if (parsedSet.has('vip')) {
        vipStatus = true;
        parsedSet.delete('vip');
      }
      if (parsedSet.has('nonvip')) {
        vipStatus = false;
        parsedSet.delete('nonvip');
      }

      const hideFromBorderfree = parsedSet.has('noborderfree');
      if (hideFromBorderfree) {
        parsedSet.delete('noborderfree');
      }

      const isFeaturedCategory = parsedSet.has('featuredcategory');
      if (isFeaturedCategory) {
        parsedSet.delete('featuredcategory');
      }

      const isPromo = parsedSet.has('promo');
      if (isPromo) {
        parsedSet.delete('promo');
      }

      const isNew = parsedSet.has('newlink');
      if (isNew) {
        parsedSet.delete('newlink');
      }

      const isSpecialOffer = parsedSet.has('specialoffer');
      if (isSpecialOffer) {
        parsedSet.delete('specialOffer');
      }

      let categoryFilters = ['bras', 'undies', 'lingerie'];
      if (parsedSet.has('none')) {
        categoryFilters = [];
      } else if (parsedSet.size) {
        categoryFilters = Array.from(parsedSet);
      }

      const dmgCodes = new Set(
        (dmgCode || '')
          .toString()
          .split(',')
          .map(item => item.trim())
          .filter(item => item)
      );

      const personas = new Set(
        (persona || '')
          .split(',')
          .map(item => item.trim())
          .filter(item => item)
      );

      return {
        label: label || undefined, // Prefer undefined over empty string labels
        link,
        categoryFilters,
        dmgCodes,
        personas,
        fpl,
        isFeaturedCategory,
        vipStatus,
        hideFromBorderfree,
        isNew,
        isPromo,
        isSpecialOffer,
        isTemplate: false,
        asset,
      };
    });
  }
);

export const getProductsLinkAssetsByUrl = createSelector(
  getProductsLinkAssets,
  links => {
    return links.reduce((linksByUrl, link) => {
      if (link.link) {
        linksByUrl[link.link] = link;
      }
      return linksByUrl;
    }, {});
  }
);

export const getRouteLabels = createSelector(
  (state, props) => props.defaultLabels,
  getProductsLinkAssets,
  state => getMembership(state).isVip,
  (defaultLabels, links, isVip) => {
    const labels = links.reduce((labels, link) => {
      if (link.link && link.label) {
        if (
          (link.vipStatus != null && link.vipStatus === isVip) ||
          link.vipStatus == null
        ) {
          labels[link.link] = link.label;
        }
      }
      return labels;
    }, {});

    return {
      ...defaultLabels,
      ...labels,
    };
  }
);

export const getFeaturedLinks = createSelector(
  getProductsLinkAssets,
  state => state.borderfree.isBorderfreeCustomer,
  state => getSession(state).dmGatewayCode,
  state => getMembership(state).persona,
  state => getMembership(state).isVip,
  (links, isBorderfreeCustomer, dmgCode, persona, isVip) => {
    links = links.filter(link => {
      if (link.hideFromBorderfree && isBorderfreeCustomer) {
        return false;
      }

      if (link.vipStatus != null && link.vipStatus !== isVip) {
        return false;
      }

      if (!dmgCode) {
        // Use this value for 'no dmgCode' matching.
        dmgCode = 'none';
      }

      if (!persona) {
        // Use this value for 'no persona' matching.
        persona = 'none';
      }

      // Disqualify asset if it specifies a dmgCode and the user's does not match.
      if (link.dmgCodes.size && !link.dmgCodes.has(dmgCode)) {
        return false;
      }

      // Disqualify asset if it specifies a persona and the user's does not match.
      if (link.personas.size && !link.personas.has(persona)) {
        return false;
      }

      return true;
    });

    return {
      featuredLinks: links.filter(link => link.isFeaturedCategory),
      promoLinks: links.filter(link => link.isPromo),
      specialOffers: links.filter(link => link.isSpecialOffer),
    };
  }
);

export function getDiscountsByPromo(discounts) {
  // Find the same promo to get the total discount amount applied per promo
  // Find the same promo to get the total discount amount applied per promo
  const discountMap = discounts.reduce((result, discount) => {
    // create a copy of the discount so that we're not directly manipulating any of it
    const orderDiscount = { ...discount };
    const sameDiscount = result.get(orderDiscount.promoId);
    if (sameDiscount) {
      sameDiscount.amount += orderDiscount.amount;
    } else {
      result.set(orderDiscount.promoId, orderDiscount);
    }
    return result;
  }, new Map());

  // Map.values does not work in IE11, polyfill does not work for some reason.
  const values = [];
  discountMap.forEach(value => values.push(value));
  return values;
}

export const getDiscounts = createSelector((state, ownProps) => {
  const activeDiscounts = state.checkout.discounts.filter(
    discount => discount.amount > 0
  );
  const orderDiscounts =
    ownProps.order && ownProps.order.orderLineDiscounts
      ? ownProps.order.orderLineDiscounts
      : [];

  return activeDiscounts.length ? activeDiscounts : orderDiscounts;
}, getDiscountsByPromo);

export const getOrderDiscounts = createSelector(
  (state, ownProps) => ownProps?.order,
  order => (order && order.discounts ? order.discounts : [])
);

export const getAdditionalSaleDiscount = createSelector(
  (state, props) => state.checkout.discounts,
  discounts =>
    discounts.find(
      discount => discount.promoCode === additionalSaleDiscount.promoCode
    )
);

export const getFreeTrialDetails = createSelector(
  state =>
    getMembership(state).membershipTrials &&
    getMembership(state).membershipTrials.length
      ? getMembership(state).membershipTrials
      : [],
  membershipTrials => {
    const freeTrialStatusCodes = new Set(
      config.get('public.freeTrialStatusCodes')
    );
    const freeTrialDetails = membershipTrials.find(freeTrial => {
      return freeTrialStatusCodes.has(freeTrial.statusCode);
    });

    return freeTrialDetails;
  }
);

/**
 * Returns a *function* that will return the membership product info from
 * `membershipTypes` for the given type, but with the `productId` field
 * resolved to the numeric ID for the appropriate domain. A `type` field will
 * also be added for easy checking of which type was returned (for when this
 * object inevitably gets passed around, or looked up indirectly).
 */
export const getMembershipProductInfoByType = createSelector(
  state => state.domain.tld,
  tld => {
    return type => {
      const info = membershipTypes[type];
      if (info == null) {
        throw new Error(`Unknown membership type: '${type}'`);
      }
      const productId = info.productId[tld];
      if (productId == null) {
        throw new Error(`No productId is configured for domain: '${tld}'`);
      }
      return { type, ...info, productId };
    };
  }
);

export const getVipMembershipWeekOld = createSelector(
  state => getMembership(state),
  getDateNowFunction,
  (membership, getDateNow) => {
    const day = 1000 * 60 * 60 * 24;
    const dateActivated = parseDate(membership.dateActivated);
    const getIsVipMembershipWeekOld = () => {
      return dateActivated.getTime() + day * 7 < getDateNow();
    };

    return { getIsVipMembershipWeekOld };
  }
);
/**
 * Returns a *function* that will return the product ID of the given membership
 * type. This is just a convenience function for retrieving only the ID, use
 * `getMembershipProductInfoByType` to get everything.
 * `getMembershipProductInfoByType` to get everything.
 */
export const getMembershipProductIdByType = createSelector(
  getMembershipProductInfoByType,
  getInfo => {
    return type => getInfo(type).productId;
  }
);

/**
 * This just exists in case this is controlled dynamically (by domain, API,
 * etc.) in the future instead of hardcoded into the config.
 */
export function getDefaultMembershipType() {
  return defaultMembershipType;
}

/**
 * Returns an object containing info about the default membership product.
 */
export const getDefaultMembershipProductInfo = createSelector(
  getMembershipProductInfoByType,
  getDefaultMembershipType,
  (getInfo, type) => getInfo(type)
);

/**
 * A convenience function for returning just the product ID of the default
 * membership product. Use `getDefaultMembershipProductInfo` to get everything.
 */
export const getDefaultMembershipProductId = createSelector(
  getDefaultMembershipProductInfo,
  info => info.productId
);

/**
 * Returns a *function* that will determine which type a membership item is
 * given its product ID. Returns undefined if it's none of the known types.
 */
export const getMembershipProductTypeById = createSelector(
  state => state.domain.tld,
  tld => {
    return productId => {
      return Object.keys(membershipTypes).find(type => {
        return membershipTypes[type].productId[tld] === productId;
      });
    };
  }
);

/**
 * Returns a *function* that will return membership product info for a
 * membership item given its product ID.
 */
export const getMembershipProductInfoById = createSelector(
  getMembershipProductTypeById,
  getMembershipProductInfoByType,
  (getType, getInfo) => {
    return productId => {
      const type = getType(productId);
      if (type) {
        return getInfo(type);
      }
    };
  }
);

/**
 * Get the cart line item that matches the VIP membership product.
 */
export const getMembershipCartItem = createSelector(
  getLineItems,
  getMembershipProductTypeById,
  (items, getType) => {
    return items.find(item => getType(item.productId) != null);
  }
);

export const hasMembershipInCart = createSelector(
  getLineItems,
  getMembershipProductTypeById,
  (items, getType) => {
    return items.some(item => getType(item.productId) != null);
  }
);

export const isUSDomain = createSelector(
  state => state?.domain?.tld,
  tld => tld === '.com'
);

export const getFreeShippingThreshold = createSelector(
  state => state?.domain?.tld === '.com',
  state => getMembership(state).isVip,
  hasMembershipInCart,
  state => state.storeGroup.shippingOptions,
  (isUS, isVip, hasMembershipInCart, shippingOptions) => {
    if (isUS) {
      if (isVip) {
        return 59.0;
      } else if (hasMembershipInCart) {
        return 30.0;
      }
    }
    return shippingOptions.shippingThreshold;
  }
);

export const isMembershipItem = createSelector(
  getMembershipCartItem,
  (state, props) => props.product,
  (membershipItem, product) => membershipItem?.productId === product?.productId
);

export const getMembershipCartItemInfo = createSelector(
  getMembershipCartItem,
  getMembershipProductInfoById,
  (membershipItem, getInfoById) => {
    return membershipItem && getInfoById(membershipItem.productId);
  }
);

export const hasFreeTrialMembershipInCart = createSelector(
  getLineItems,
  getMembershipProductTypeById,
  (items, getType) => {
    return items.some(item => getType(item.productId) === 'freeTrial');
  }
);

/**
 * Convenience function for compatibility with older selectors/components.
 */
export const getFreeTrialMembershipProductId = createSelector(
  getMembershipProductIdByType,
  getId => getId('freeTrial')
);

export const hasOnlyMembershipCartItem = createSelector(
  getLineItems,
  getMembershipProductTypeById,
  (items, getType) => {
    // Find potentially multiple membership items, in case multiple types get
    // added by mistake or happenstance.
    return (
      items.length > 0 && items.every(item => getType(item.productId) != null)
    );
  }
);

/* NavBar Shopping Bag Icon should not count the membership */
export const getItemCountExcludingMembership = createSelector(
  state => state.checkout.cartProviderType,
  state => state.checkout.itemCount,
  getMembershipCartItem,
  (cartProviderType, itemCount, membershipItem) => {
    // ECHO carts do not include membership items in itemCount, so
    // just return it right away.
    if (cartProviderType === 'ECHO') {
      return itemCount;
    }
    return membershipItem ? itemCount - 1 : itemCount;
  }
);

/* for influencer we need set items to be counted as 1 each */
export const getInfluencerItemCountExcludingMembership = createSelector(
  state => state.checkout.items,
  getMembershipCartItem,
  (items, membershipItem) => {
    let counter = 0;
    if (!items) {
      return 0;
    }
    items.forEach(item => {
      counter += item.bundleItems ? item.bundleItems.length : 1;
    });
    return membershipItem ? counter - 1 : counter;
  }
);

export const hasPreorderItems = createSelector(
  getLineItems,
  getNewDateFunction,
  (cartItems, now) => {
    return cartItems.some(item =>
      isProductSet(item)
        ? checkPreorderForSets({ product: item, now })
        : checkPreorderForSingleItem({ product: item, now })
    );
  }
);

export const hasCustomizableItems = createSelector(getLineItems, cartItems => {
  return cartItems.some(item =>
    item && item.tagIds ? item.tagIds.includes(customizableTag) : false
  );
});

export const getExcludedProductTypesForAfterpay = createSelector(
  hasPreorderItems,
  hasCustomizableItems,
  (hasPreorder, hasCustomizable) => {
    if (hasPreorder) {
      return 'pre-order items';
    }
    if (hasCustomizable) {
      return 'customizable items';
    }
    return null;
  }
);

export const getNonPreorderItems = createSelector(
  getLineItems,
  getNewDateFunction,
  (cartItems, now) => {
    return cartItems.filter(item =>
      isProductSet(item)
        ? !checkPreorderForSets({ product: item, now })
        : !checkPreorderForSingleItem({ product: item, now })
    );
  }
);

// Returns if there's any in-stock items, excluding membership
export const hasNonPreorderItems = createSelector(
  getNonPreorderItems,
  getMembershipCartItem,
  (cartItems, membershipItem) => {
    const hasMembershipItem = !!membershipItem;
    const cartItemsCount = hasMembershipItem
      ? cartItems.length - 1
      : cartItems.length;
    return cartItemsCount > 0;
  }
);

export const onlyHasPreorderItems = createSelector(
  getLineItems,
  getNewDateFunction,
  (cartItems, now) => {
    return cartItems.every(item => {
      // parseDate converts null values to 1969.
      return item.datetimePreorderExpires &&
        item.datetimePreorderExpires !== null
        ? now() <= parseDate(item.datetimePreorderExpires)
        : false;
    });
  }
);

/* Preorders are only available to VIPs or leads checking
 * out as VIP, and have an instock item
 */
export const isPreorderEligible = createSelector(
  state => getMembership(state).isVip,
  getMembershipCartItem,
  hasNonPreorderItems,
  (isVIP, membershipItem, hasItems) => {
    return isVIP || (membershipItem && hasItems);
  }
);

export const getAccountMembershipProductType = createSelector(
  state => getMembership(state).isVip,
  state => getMembership(state).membershipTypeLabel,
  getFreeTrialDetails,
  (isVip, membershipTypeLabel, freeTrialDetails) => {
    if (isVip) {
      switch (membershipTypeLabel) {
        case 'annual':
          return freeTrialDetails ? 'freeTrial' : 'annual';
        case 'monthly':
          return 'monthly';
      }
    }
  }
);

export const getAccountMembershipProductInfo = createSelector(
  getAccountMembershipProductType,
  getMembershipProductInfoByType,
  (type, getInfo) => {
    if (type) {
      return getInfo(type);
    }
  }
);

export const getProductReviews = createSelector(
  (state, props) => props.product,
  (state, props) => !!props.isSet,
  state => state.reviews,
  (product, isSet, reviews) => {
    if (!product || !reviews || (isSet && !reviews.byMasterProductId)) {
      return null;
    }

    if (isSet || reviews?.byMasterProductId) {
      reviews = reviews.byMasterProductId[product.master_product_id];
    }
    const groupCode = (product.group_code || '').toUpperCase();
    const masterProductId = Number(product.master_product_id);

    const shouldReturnReviews =
      (groupCode && reviews?.groupCode === groupCode) ||
      (masterProductId && reviews?.masterProductId === masterProductId) ||
      (reviews?.masterProductId &&
        product.bundle_product_id_list &&
        product.bundle_product_id_list.includes(reviews?.masterProductId));
    return shouldReturnReviews ? reviews : null;
  }
);

export const getProductRecommendations = createSelector(
  (state, props) =>
    state.products.byMasterProductId[props.product.master_product_id],
  product => {
    if (!product || !product.hasOwnProperty('recommended')) {
      return [];
    }
    if (product.recommended && product.recommended.length) {
      return product.recommended;
    }
    return [];
  }
);

export const isCurvySize = createSelector(
  (state, props) => props.product,
  product => {
    if (product && product.tag_id_list) {
      return product.tag_id_list.includes(curvySizeTag);
    }
    return false;
  }
);

export const isRegularSize = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      return product.tag_id_list.includes(regularSizeTag);
    }
    return false;
  }
);

export const isComingSoonProduct = createSelector(
  (state, props) => props.product,
  product => {
    if (product.tag_id_list) {
      return product.tag_id_list.includes(comingSoonTag);
    } else if (product.tagIdList) {
      return product.tagIdList.includes(comingSoonTag);
    }
    return false;
  }
);

export const hasPromoConflict = createSelector(
  state => state.checkout.promoConflicts,
  promoConflicts => {
    return promoConflicts && !!promoConflicts.length;
  }
);

export const getBirthdayDetails = createSelector(
  state => state.customer.profile,
  getNewDateFunction,
  (profile = [], getDateNow) => {
    const todayDate = parseDate(getDateNow());
    const THIRTY_DAYS = 60 * 60 * 24 * 1000 * 30;
    const todayDay = todayDate.getDate();
    const todayMonth = todayDate.getMonth();
    const todayYear = todayDate.getFullYear();
    const birthMonth = Number(profile['birth-month']) - 1;
    const birthDay = Number(profile['birth-day']);
    const year =
      birthMonth === 12 && todayMonth === 1 && birthDay >= todayDay
        ? todayYear - 1
        : todayYear;
    const isBirthday = birthDay === todayDay && birthMonth === todayMonth;
    const isBirthMonth =
      parseDate(new Date(year, birthMonth, birthDay)).getTime() + THIRTY_DAYS >
      todayDate;
    const hasBirthday = profile['birth-day'] && profile['birth-month'];
    return { isBirthday, isBirthMonth, hasBirthday };
  }
);

export const getMinimumQuantity = createSelector(
  (state, ownProps) => ownProps.product,
  product => {
    const tagIds = product.tag_id_list || product.tagIds || [];
    const hasMinimumQuantityTag = tagIds.includes(minimum3Qty);
    const minimumQuantity = hasMinimumQuantityTag ? 3 : 1;
    return { hasMinimumQuantityTag, minimumQuantity };
  }
);

export const isByoAvailableItem = createSelector(
  (state, ownProps) => ownProps.product,
  isPreorderable,
  isComingSoonProduct,
  (product, isPreorderable, isComingSoonProduct) => {
    if (product.tag_id_list) {
      const hasExcludeByoTagId =
        product.tag_id_list.includes(excludeFromByoTag);

      return !isPreorderable && !isComingSoonProduct && !hasExcludeByoTagId;
    }
    return false;
  }
);

// this selector will find available byo sets based on the product we are passing in
export const getByoSetsByProduct = createSelector(
  (state, ownProps) => state.byoSxf?.config,
  (state, ownProps) => ownProps.product,
  (byoConfig, product) => {
    if (
      !product ||
      (!!(
        !product.product_category_id_list ||
        !product.tag_id_list ||
        !product.featured_product_location_id_list
      ) &&
        !!(
          !product.fplIds ||
          !product.tagIds ||
          !product.productCategoryIdList
        )) ||
      !(byoConfig && byoConfig.length)
    ) {
      return [];
    }

    const categoryIdList =
      product.product_category_id_list || product.productCategoryIdList;
    const fplIdList =
      product.featured_product_location_id_list || product.fplIds;

    const byoSetsForProduct = byoConfig.reduce((result, byoSet) => {
      const startCategory = byoSet.config.find(item => {
        const isCorrectCategory =
          !item.categoryIds ||
          item.categoryIds.some(categoryId =>
            categoryIdList.includes(categoryId)
          );
        const hasExcludedCategoryIds =
          item.excludeCategoryIds &&
          item.excludeCategoryIds.some(categoryId =>
            categoryIdList.includes(categoryId)
          );
        const hasCorrectFplIds =
          !item.fplIds || item.fplIds.some(fplId => fplIdList.includes(fplId));
        const hasExcludedFplIds =
          item.excludeFplIds &&
          item.excludeFplIds.some(fplId => fplIdList.includes(fplId));

        return (
          isCorrectCategory &&
          hasCorrectFplIds &&
          !hasExcludedCategoryIds &&
          !hasExcludedFplIds
        );
      });

      if (startCategory) {
        const newConfigArr = [...byoSet.config];
        const index = newConfigArr.indexOf(startCategory);
        newConfigArr.splice(index, 1);
        result.push({
          startCategory,
          combinationOptions: newConfigArr,
          ...byoSet,
        });
      }
      return result;
    }, []);
    return byoSetsForProduct;
  }
);

export const getCategoryNav = createSelector(
  [
    getProductsLinkAssetsByUrl,
    (state, asPath, navContainerName) =>
      getNavContainer(state, navContainerName),
    (state, asPath) => asPath,
    state => getMembership(state).isVip,
  ],
  (productsLinkAssetsByUrl, navContainer = {}, asPath, isVip) => {
    const pathname = urlNoQueryString(asPath);
    const productsAsset =
      productsLinkAssetsByUrl && productsLinkAssetsByUrl[pathname];

    const newSectionMatch = asPath.match(/^\/(products)(\/new-vip|\/new)/);
    const newSectionPath =
      newSectionMatch && newSectionMatch.length ? newSectionMatch[0] : null;
    const isVipSalePage = isVip ? asPath.match(/^(\/sale)/) : null;
    const isLeadSalePage = asPath.match(/^(\/sale-secondary)/);
    const { data = {} } = navContainer;

    const { navItems = [] } = data;

    return getCategories({
      navItems,
      asPath,
      newSectionPath,
      productsAsset,
      isVipSalePage,
      isLeadSalePage,
    });
  }
);

export const getProductPromos = createSelector(
  (state, ownProps) => ownProps.product,
  product => {
    let { promos } = product;
    if (Array.isArray(promos)) {
      promos = promos[0];
    }
    if (!promos) {
      promos = {};
    }
    return promos;
  }
);

export const getPriceDetails = createSelector(
  [
    (state, { product }) => product,
    (state, { product }) => getProductPromos(state, { product: product ?? {} }),
    (state, { product }) =>
      getShouldShowPromoPrice(state, { product: product ?? {} }),
    getMembership,
  ],
  (product, promos, shouldShowPromoPrice, membership) => {
    const { isVip } = membership;

    const { default_unit_price: defaultPrice, retail_unit_price: retailPrice } =
      product ?? {};
    let displayPrice;

    if (shouldShowPromoPrice) {
      displayPrice = promos.promo_price;
    } else if (isVip) {
      displayPrice = defaultPrice;
    } else {
      displayPrice = retailPrice;
    }

    return {
      displayPrice,
      shouldShowPromoPrice,
    };
  }
);

export const getShouldShowPromoPrice = createSelector(
  getProductPromos,
  state => state.borderfree.isBorderfreeCustomer,
  (promos, isBorderfreeCustomer) => {
    return (
      !isBorderfreeCustomer &&
      (!!promos.display_on_pdp || !!promos.displayOnPdp) &&
      shouldShowPromoPrice(promos)
    );
  }
);

export const getOneHourLead = createSelector(
  getMemberJoinDate,
  getDateNowFunction,
  (memberJoinDate, getDateNow) => {
    const now = getDateNow();
    const endDate = memberJoinDate ? memberJoinDate.getTime() + ONE_HOUR : null;

    return {
      endDate,
      now,
      isExpired: now > endDate,
    };
  }
);

export const getBlankProfileSizeByCategory = createSelector(
  (state, _ownProps) => state.customer?.profile || {},
  (_state, ownProps) => ownProps.category,
  (state, _ownProps) => getMembership(state).isVisitor,
  (profile, sizeCategory, isVisitor) => {
    const categories = {
      Bras: ['bra-size', 'bralette-size'],
      Bralettes: ['bralette-size'],
      Undies: ['bottom-size'],
      Lingerie: ['lingerie-sleep-size'],
      Sleep: ['lingerie-sleep-size'],
      All: ['bra-size', 'bralette-size', 'lingerie-sleep-size', 'bottom-size'],
    };

    return !isVisitor &&
      Object.keys(profile).length &&
      Object.keys(categories).includes(sizeCategory)
      ? categories[sizeCategory].filter(
          size =>
            profile[size]?.length === 0 ||
            profile[size]?.includes('not_interested')
        )
      : [];
  }
);

export const getMostExpensiveByoEligibleCheckoutItems = createSelector(
  (state, _ownProps) => state.checkout?.items,
  (state, _ownProps) => state,
  (checkoutItems = [], state) => {
    if (!checkoutItems.length) {
      return checkoutItems;
    }

    const filteredCheckoutItems = checkoutItems.filter(item =>
      getByoSetsByProduct(state, { product: item }).some(
        byoSet =>
          byoSet.byoProductId === twoItemBundleByoProductId[state.domain.tld]
      )
    );

    const sortedCheckoutItems = filteredCheckoutItems.sort(
      (itemA, itemB) => itemB.vipUnitPrice - itemA.vipUnitPrice
    );

    return sortedCheckoutItems.filter(
      item => item.vipUnitPrice === sortedCheckoutItems[0].vipUnitPrice
    );
  }
);

export const containsBundle = createSelector(
  (state, _ownProps) => state.checkout?.items,
  (checkoutItems = []) =>
    checkoutItems.some(item => item.bundleItems?.length > 0)
);

export const getFirstAddedCheckoutItem = createSelector(
  (state, ownProps) => ownProps?.items || state.checkout?.items,
  (checkoutItems = []) => {
    if (!checkoutItems.length) {
      return null;
    }

    return checkoutItems.reduce((prevItem, currentItem) => {
      const prevItemAddedDate = +parseDate(prevItem.cartDateTimeAdded);
      const currentItemAddedDate = +parseDate(currentItem.cartDateTimeAdded);

      if (prevItemAddedDate > currentItemAddedDate) {
        return currentItem;
      }

      return prevItem;
    });
  }
);

export const getMostExpensiveFirstAddedVipCheckoutItem = createSelector(
  (state, ownProps) =>
    getMostExpensiveByoEligibleCheckoutItems(state, ownProps),
  mostExpensiveItems => {
    return getFirstAddedCheckoutItem({}, { items: mostExpensiveItems }) || null;
  }
);

export const getAddedToBasketCount = createSelector(
  state => state.products.addedToBasketCount,
  (_state, productId) => productId,
  (addedToBasketCount, productId) => {
    return addedToBasketCount[productId];
  }
);

// taxType of 'delivery' will only be available for US with state of Colorado
export const getOrderRetailDeliveryFee = createSelector(
  state => state.checkout,
  (_, ownProps) => ownProps?.order,
  (checkout, orderFromProps) => {
    if (orderFromProps) {
      return orderFromProps.deliveryTax || null;
    }

    return (
      checkout?.cartTax?.find(tax => tax.taxType === OrderTaxTypes.DELIVERY)
        ?.taxableAmount || null
    );
  }
);

export const isVipLiveShoppingPromoAppliedSelector = createSelector(
  state => state.checkout.cartLineDiscounts,
  cartLineDiscounts => {
    return !!getPromo(cartLineDiscounts, null, vipLiveShoppingPromo.promoCode);
  }
);

export const isMembershipLoadedSelector = createSelector(
  state => getSession(state).isServerOnly,
  state => getMembership(state).networkStatus.isUpToDate,
  (isServerOnly, isUpToDate) => {
    return !isServerOnly && isUpToDate;
  }
);

export const getIsVipCart = createSelector(
  state => state.checkout.isVipCart || false,
  isVipCart => isVipCart
);

export const getGwpPromo = createSelector(
  [state => getPotentialSegmentsSelector(state), getOrderDiscounts],
  (segments, orderDiscounts) => {
    // messy lookup that can be improved
    if (orderDiscounts.length) {
      const gwpPromosByCode = {};
      Object.values(gwpPromos).forEach(promo => {
        gwpPromosByCode[promo.promoCode] = promo;
      });
      const gwpPromoCodes = Object.keys(gwpPromosByCode);

      const orderGwpPromo = orderDiscounts.find(discount => {
        return gwpPromoCodes.includes(discount.promoCode);
      });

      if (orderGwpPromo) {
        return gwpPromosByCode[orderGwpPromo.promoCode];
      }
    }

    return getSegmentedValue({ options: gwpPromos, segments });
  }
);

export const isGwpApplied = createSelector(
  (state, ownProps) =>
    ownProps?.order?.orderLineDiscounts || state.checkout.cartLineDiscounts,
  getGwpPromo,
  (orderLineDiscounts, gwpPromoSegment) => {
    return !!getPromo(orderLineDiscounts, null, gwpPromoSegment?.promoCode);
  }
);

export const isGwpAvailable = createSelector(
  state => state.checkout.discounts,
  getGwpPromo,
  (discounts, gwpPromoSegment) => {
    return !!getPromo(discounts, null, gwpPromoSegment?.promoCode);
  }
);

export const getGwpProduct = createSelector(
  getLineItems,
  getGwpPromo,
  (products, gwpPromoSegment) => {
    if (!products) {
      return;
    }

    return products.find(
      product =>
        product.discount &&
        product.discount.promoCode === gwpPromoSegment?.promoCode
    );
  }
);

export const getBuilderMembershipStatus = createSelector(
  getMembership,
  membership => {
    const { isDowngraded, isVip, isLead, networkStatus } = membership;
    let membershipStatus = 'visitor';
    if (isDowngraded) {
      membershipStatus = 'downgraded';
    } else if (isVip) {
      membershipStatus = 'vip';
    } else if (isLead) {
      membershipStatus = 'lead';
    }

    return {
      membershipStatus,
      networkStatus,
    };
  }
);
