import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  defineMessages,
  injectIntl,
  FormattedMessage,
} from '@techstyle/react-intl';

import { useLDFlags } from '../../utils/LD/useLDFlags';
import { isNMP } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as Price } from '../ExtendedPrice';
import { Component as MemberCreditsCartToggle } from '../MemberCreditsCartToggle';
import { Component as SpinningLoader } from '../SpinningLoader';

const creditMessages = defineMessages({
  singular: {
    id: 'global_checkout.credit_singular',
    defaultMessage: 'Credit',
  },
  plural: {
    id: 'global_checkout.credits_plural',
    defaultMessage: 'Credits',
  },
});

/**
 * The promo label (if present) needs to be ordered first and take up the full width
 * Otherwise, only the price is shown (at the far right)
 */

const BasketPriceWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-end;
`;

const PriceWrapper = styled.div`
  font-size: 14px;
  line-height: 1.29;

  color: ${({ old, special, theme }) =>
    special && theme.themeVersion === 'v1'
      ? theme.colors.promo
      : old
      ? theme.colors.subdued
      : 'inherit'};
  font-weight: 400;
  ${props =>
    props.theme.context.cartDrawer &&
    `
    display: none;
  `};
  ${v2`
    ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
    ${props => props.old && `padding-top: 4px;`}
  `}
`;

const FreePriceWrapper = styled(PriceWrapper)`
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.3px;
  ${v2`
    ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
  `}
`;

const PriceLabel = styled.span`
  font-size: 12px;
  display: ${props =>
    props.theme.context.cartDrawer || props.hidden ? 'none' : 'inline'};
  ${v2`
    ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
  `}
`;

const BasketPrice = styled(Price)`
  ${v1`
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.isClearanceItem
      ? props.theme.colors.error
      : props.special
      ? props.theme.colors.promo
      : 'inherit'};
  `}
  ${v2`
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.isClearanceItem
      ? props.theme.colors.error
      : props.special
      ? props.theme.colors.gunmetal
      : 'inherit'};
  `}
  font-weight: 400;
  ${v2`
    ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
  `}
`;
const StyledSpinningLoader = styled(SpinningLoader)`
  position: relative;
`;

class BasketItemPrice extends React.PureComponent {
  static propTypes = {
    discounts: PropTypes.array,
    isClearanceItem: PropTypes.bool.isRequired,
    isNMP: PropTypes.bool,
    isVip: PropTypes.bool,
    intl: PropTypes.object,
    membershipItem: PropTypes.object,
    product: PropTypes.object.isRequired,
    tokenToCashEnabled: PropTypes.bool,
  };

  static defaultProps = {
    isVip: false,
  };

  static PriceWrapper = PriceWrapper;

  static contextType = MemberCreditsCartToggle.Context;

  // Renders the purple sale/promo price if applicable
  renderPromoPrice(hasPromo, promoPrice, purchaseUnitPrice) {
    const { isClearanceItem } = this.props;

    if (!hasPromo) {
      return null;
    }

    if (promoPrice === 0) {
      return (
        <FreePriceWrapper>
          <FormattedMessage id="global_checkout.free" defaultMessage="Free" />
        </FreePriceWrapper>
      );
    }

    return (
      <BasketPrice
        data-qa-automation="itemSalePrice"
        isClearanceItem={isClearanceItem}
        special={promoPrice !== purchaseUnitPrice}
        amount={promoPrice}
        className="bfx-sale-price"
      />
    );
  }

  // This is the items list price always, VIP or not, not the sale price if a promo is applied
  renderListPrice(amount, hasPromo, promoPrice) {
    const { membershipItem } = this.props;

    if (promoPrice === 0 || amount === promoPrice) {
      return null;
    }

    return (
      <PriceWrapper old={hasPromo} bold={!hasPromo}>
        <PriceLabel hidden={!membershipItem}>
          <FormattedMessage
            id="global_checkout.vip_price_label"
            defaultMessage="VIP:"
          />
          &nbsp;
        </PriceLabel>
        <BasketPrice
          data-qa-automation={`item${hasPromo ? 'Old' : 'List'}Price`}
          old={hasPromo}
          amount={amount}
          className={hasPromo ? 'bfx-old-price' : 'bfx-list-price'}
        />
      </PriceWrapper>
    );
  }

  // This always renders the retail price crossed out and only displays if user has a monthly membership
  // product in their cart
  renderOldPrice(oldPrice, purchasePrice) {
    const { membershipItem } = this.props;

    if (!membershipItem || oldPrice <= purchasePrice) {
      return null;
    }

    return (
      <PriceWrapper old hideInDrawer>
        <BasketPrice
          data-qa-automation="itemOldPrice"
          old
          amount={oldPrice}
          className="bfx-old-price" // this is always an old price
        />
      </PriceWrapper>
    );
  }

  renderMemberCredits() {
    const { intl, product, tokenToCashEnabled } = this.props;

    if (tokenToCashEnabled && this.props.isNMP) {
      return null;
    }

    const { cartLineItemsLoading = {} } = this.context || {};
    const isMemberCreditLoading = cartLineItemsLoading[product.cartLineId];
    const tokenQtyApplied = product.tokenQtyApplied || product.tokensApplied;
    const creditText = intl.formatMessage(
      tokenQtyApplied > 1 ? creditMessages.plural : creditMessages.singular
    );

    return (
      <PriceWrapper special data-autotag="ordersummary_credit_applied">
        {isMemberCreditLoading ? (
          <StyledSpinningLoader isLoading />
        ) : (
          `${tokenQtyApplied} ${creditText}`
        )}
      </PriceWrapper>
    );
  }

  render() {
    const {
      className,
      product,
      discounts,
      tokenToCashEnabled,
      isVip,
      shouldDisplayNewProductPricing,
    } = this.props;
    const { cartLineItemsLoading = {} } = this.context || {};
    const isMemberCreditLoading = cartLineItemsLoading[product.cartLineId];
    const isCreditApplied = !!(
      product.tokenQtyApplied || product.tokensApplied
    );

    /** Price Options:
     * retailUnitPrice: non-VIP member price
     * purchaseUnitPrice: price user will pay factoring in membership type
     */
    const { purchaseUnitPrice, retailUnitPrice } = product;
    const hasPromo = discounts && discounts.length > 0;
    // TODO: Could multiple discounts apply to a single item?
    // Ideally we'd never have to be adding up prices as there's guaranteed to be an imprecise result.
    let promoPrice = null;
    if (hasPromo) {
      const totalDiscount = discounts.reduce((total, discount) => {
        if ('displayItemPrice' in discount) {
          promoPrice = discount.displayItemPrice;
        } else if ('displayItemPrice' in product) {
          promoPrice = product.displayItemPrice;
        }
        return discount.amount + total;
      }, 0);
      if (promoPrice === null) {
        promoPrice = purchaseUnitPrice - totalDiscount;
      }
    }

    const showOldMemberCredits = tokenToCashEnabled
      ? (isMemberCreditLoading || isCreditApplied) && !this.props.isNMP
      : isMemberCreditLoading || isCreditApplied;

    // If new-product-pricing FF is ON, hide the strikethrough price only for non-Vip users.
    const hideRegPrice = isVip || !shouldDisplayNewProductPricing || !hasPromo;
    return (
      <BasketPriceWrapper className={className}>
        {showOldMemberCredits ? (
          this.renderMemberCredits()
        ) : (
          <>
            {this.renderPromoPrice(hasPromo, promoPrice, purchaseUnitPrice)}
            {hideRegPrice
              ? this.renderListPrice(purchaseUnitPrice, hasPromo, promoPrice)
              : null}
            {this.renderOldPrice(retailUnitPrice, purchaseUnitPrice)}
          </>
        )}
      </BasketPriceWrapper>
    );
  }
}

function BasketItemPriceWithHooks(props) {
  const {
    'new-product-pricing': shouldDisplayNewProductPricing,
    'token-to-cash': tokenToCashEnabled,
  } = useLDFlags();

  const userIsNMP = useSelector(isNMP);
  return (
    <BasketItemPrice
      {...props}
      isNMP={userIsNMP}
      tokenToCashEnabled={tokenToCashEnabled}
      shouldDisplayNewProductPricing={shouldDisplayNewProductPricing}
    />
  );
}

export default injectIntl(BasketItemPriceWithHooks);
