import React, { Component } from 'react';

import config from 'config';
import orderBy from 'lodash.orderby';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { CheckoutFeatures } from '../../constants/checkout';
import { mobile } from '../../styles';
import { BasicButton } from '../../styles/button';
import { Paragraph } from '../../styles/paragraph';
import { getIsCartFeatureEnabled } from '../../utils/cart';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v2 } from '../../utils/themeVersioning';
import { Container as BasketItem } from '../BasketItem';
import { TrackPromotionEnterViewport } from '../EnterViewport';
import { Component as Price } from '../ExtendedPrice';
import { MembershipDetails } from '../JoinVipAnnual/shared';
import { Container as VipAnnualItem } from '../VipAnnualItem';

const setId = config.get('public.productTypes.setId');

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${mobile`
  border-top: 1px solid ${props => props.theme.colors.lavender250};
  `}
`;

const ListWrapper = styled.div``;

const SavingParagraph = styled(Paragraph)`
  color: ${props => props.theme.colors.promo};
  margin-bottom: 12px;
`;

const PreorderPerkCallout = styled.div`
  margin: 0 20px 0;
  padding: 15px 0;
  text-align: center;
`;

const PreorderPerkTitle = styled.div`
  color: ${props => props.theme.colors.promo};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const PreorderPerkBody = styled.div`
  font-size: 12px;
`;

const PreorderPerkContinueButton = styled(BasicButton)`
  margin-top: ${props => props.theme.spacing.small}px;
  max-width: 250px;
`;

const JoinVipContainer = styled.div`
  ${BasketItem.Wrapper} {
    ${props =>
      props.theme.context.cart &&
      `
        border-bottom: 0;
    `}
    border-top: 0;
  }
`;

const JoinVipInfo = styled.div`
  padding: ${({ theme }) => `${theme.spacing.small}px`};
  ${v2`
    padding: ${({ theme }) => `0px ${theme.spacing.medium}px`};
  `}
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

class BasketItemList extends Component {
  static propTypes = {
    allowExclusiveCheckout: PropTypes.bool,
    lineDiscounts: PropTypes.array,
    className: PropTypes.string,
    products: PropTypes.array.isRequired,
    updateItemQuantity: PropTypes.func,
    deleteBasketItem: PropTypes.func,
    gwpProduct: PropTypes.object,
    isOrderItems: PropTypes.bool,
    removeMembership: PropTypes.func,
    showExclusiveProductMessage: PropTypes.bool,
    showPrice: PropTypes.bool,
    showRemoveIcon: PropTypes.bool,
    showQuantitySelector: PropTypes.bool,
    showStock: PropTypes.bool,
    isBorderfreeCustomer: PropTypes.bool.isRequired,
    isVip: PropTypes.bool.isRequired,
    isInfluencer: PropTypes.bool,
    location: PropTypes.string,
    membershipItem: PropTypes.object,
    theme: PropTypes.object,
    // Intentionally `undefined` by default. Only draw top border if we know
    // for a fact that it's true.
    isTopItem: PropTypes.bool,
    showQuantity: PropTypes.bool,
    shouldShowVipInfo: PropTypes.bool,
    order: PropTypes.object,
    upsellProduct: PropTypes.object,
    pSource: PropTypes.string,
    hasNonPreorderItems: PropTypes.bool,
    isEmpFeatureEnabled: PropTypes.bool,
    isOrderSummary: PropTypes.bool,
    membershipPrice: PropTypes.number,
    upsellAssetToTrack: PropTypes.object,
  };

  static defaultProps = {
    isOrderItems: false,
    showQuantity: true,
    isInfluencer: false,
    upsellProduct: null,
  };

  getVipAnnualItemError() {
    const { products, membershipItem, isBorderfreeCustomer } = this.props;

    const hasOnlyMembershipItem =
      products.length === 1 &&
      membershipItem &&
      membershipItem.productId === products[0].productId;

    if (isBorderfreeCustomer && membershipItem) {
      return 'ERROR_BORDERFREE_MEMBERSHIP';
    } else if (hasOnlyMembershipItem) {
      return 'ERROR_ONLYMEMBERSHIP';
    } else {
      return null;
    }
  }

  renderPreorderPerkCallout = () => {
    return (
      <PreorderPerkCallout>
        <PreorderPerkTitle>
          <FormattedMessage
            id="global_checkout.preorder_perk_title"
            defaultMessage="Pre-order is an Xtra VIP Perk"
          />
        </PreorderPerkTitle>
        <PreorderPerkBody>
          <FormattedMessage
            id="global_checkout.preorder_perk_body"
            defaultMessage="Add an in-stock item to continue to checkout"
          />
        </PreorderPerkBody>
        <PreorderPerkContinueButton href="/products/new" fullWidth secondary>
          <FormattedMessage
            id="global_checkout.continue_shopping_cta"
            defaultMessage="Continue Shopping"
          />
        </PreorderPerkContinueButton>
      </PreorderPerkCallout>
    );
  };

  reorderProducts = () => {
    const { upsellProduct, gwpProduct } = this.props;
    let products = [];

    // Group together products by `discountId` if they have one. This is to
    // ensure that the products with the same discount are grouped together.
    const discountIndexMap = new Map();
    this.props.products.forEach(product => {
      if (product.discount) {
        const { discountId } = product.discount;
        const discountIndex = discountIndexMap.get(discountId);
        if (discountIndex !== undefined) {
          products[discountIndex].push(product);
        } else {
          discountIndexMap.set(discountId, products.length);
          products.push([product]);
        }
      } else {
        products.push(product);
      }
    });

    // When there are products with discounts, sort them by the discount price in descending order
    // and flatten the array.
    if (discountIndexMap.size > 0) {
      discountIndexMap.forEach(index => {
        products[index] = orderBy(
          products[index],
          ['discount.displayItemPrice', 'displayItemPrice', 'discount.amount'],
          ['desc', 'desc', 'asc']
        );
      });

      products = products.flat();
    }

    [gwpProduct, upsellProduct].forEach(product => {
      if (!product) {
        return;
      }
      const idx = products.indexOf(product);
      products.splice(idx, 1);
      products.unshift(product);
    });

    return products;
  };

  render() {
    const {
      allowExclusiveCheckout,
      className,
      deleteBasketItem,
      hasNonPreorderItems,
      isBorderfreeCustomer,
      isOrderItems,
      isOrderSummary,
      isTopItem,
      isVip,
      isInfluencer,
      lineDiscounts,
      location,
      membershipItem,
      order,
      pSource,
      removeMembership,
      shouldShowVipInfo,
      showExclusiveProductMessage,
      showPrice,
      showQuantity,
      showQuantitySelector,
      showRemoveIcon,
      showStock,
      updateItemQuantity,
      upsellAssetToTrack,
      upsellProduct,
      theme: { context },
    } = this.props;

    const reorderedProducts = this.reorderProducts();

    const { features = [] } = order ?? {};

    const isRetailPriceTestEnabled = getIsCartFeatureEnabled(
      { features },
      CheckoutFeatures.RETAIL_PRICE_TEST
    );

    return (
      <Wrapper className={className} isTopItem={isTopItem}>
        <ListWrapper>
          {reorderedProducts.map((product, index) => {
            const productLineId = product.cartLineId || product.lineId;
            const upsellProductLineId =
              upsellProduct &&
              (upsellProduct.cartLineId || upsellProduct.lineId);
            let productDiscounts = [];

            if (lineDiscounts) {
              productDiscounts = lineDiscounts.filter(discount => {
                if (discount.cartLineId) {
                  return discount.cartLineId === productLineId;
                }
                if (discount.orderLineId) {
                  return discount.orderLineId === product.orderLineId;
                }
              });
            }

            const isLastProduct =
              index === reorderedProducts.length - 1 && !membershipItem;

            // NOTE: This adds the `cart.items.discount.displayItemPrice` to the
            // discount array. This is needed because the `cart.discounts` does not have the
            // `displayItemPrice` property. Not sure if this is a bug or not.
            // The `displayItemPrice` is needed to display the correct price for BOGO promos
            const { discount: productDiscount } = product;
            if (productDiscount) {
              productDiscounts.forEach((discount, index) => {
                if (
                  discount.cartLineDiscountId ===
                  productDiscount.cartLineDiscountId
                ) {
                  productDiscounts[index] = {
                    ...productDiscount,
                    ...discount,
                  };
                }
              });
            }

            const isProductSet = product.productTypeId === setId;
            const shouldDisplayUpsellMessage =
              productLineId === upsellProductLineId;

            return membershipItem &&
              membershipItem.productId === product.productId ? null : (
              <React.Fragment key={productLineId}>
                {shouldDisplayUpsellMessage && upsellAssetToTrack ? (
                  <TrackPromotionEnterViewport asset={upsellAssetToTrack} />
                ) : null}
                <BasketItem
                  key={productLineId}
                  allowExclusiveCheckout={allowExclusiveCheckout}
                  deleteBasketItem={deleteBasketItem}
                  discounts={productDiscounts}
                  hidePreorderMessaging={isOrderItems}
                  isLastToRender={isLastProduct}
                  location={location}
                  membershipItem={membershipItem}
                  product={product}
                  shouldDisplayUpsellMessage={shouldDisplayUpsellMessage}
                  showExclusiveProductMessage={showExclusiveProductMessage}
                  showQuantity={isProductSet ? false : showQuantity}
                  showQuantitySelector={showQuantitySelector}
                  showRemoveIcon={showRemoveIcon}
                  showStock={showStock}
                  showPrice={showPrice}
                  updateItemQuantity={updateItemQuantity}
                  pSource={pSource}
                />
              </React.Fragment>
            );
          })}
          {!isVip &&
            !isBorderfreeCustomer &&
            !hasNonPreorderItems &&
            !isOrderItems &&
            this.renderPreorderPerkCallout()}
          {(!isInfluencer && context.cartDrawer) ||
            (membershipItem && (hasNonPreorderItems || isOrderSummary) && (
              <JoinVipContainer>
                <VipAnnualItem
                  item={membershipItem}
                  deleteBasketItem={removeMembership}
                  location={location}
                  showRemoveIcon={showRemoveIcon}
                  error={this.getVipAnnualItemError()}
                />
                {shouldShowVipInfo && (
                  <JoinVipInfo>
                    {!isRetailPriceTestEnabled ? (
                      <SavingParagraph>
                        <FormattedMessage
                          id="global_checkout.save_vip_toggle"
                          defaultMessage="You’re saving {price} on this order!"
                          values={{
                            price: <Price amount={order.vipSavings} />,
                          }}
                        />
                      </SavingParagraph>
                    ) : null}
                    <MembershipDetails />
                  </JoinVipInfo>
                )}
              </JoinVipContainer>
            ))}
        </ListWrapper>
      </Wrapper>
    );
  }
}

function BasketItemListWithHooks(props) {
  const { 'emp-membership-onboarding': isEmpFeatureEnabled } = useLDFlags();

  return (
    <BasketItemList {...props} isEmpFeatureEnabled={isEmpFeatureEnabled} />
  );
}

export default withTheme(BasketItemListWithHooks);
