import { handleActions } from 'redux-actions';

import {
  LOAD_CANCEL_REASONS_SUCCESS,
  PAUSE_MEMBERSHIP_SUCCESS,
} from '../../actions/account';

const defaultState = {
  cancelReasons: [],
};

export default handleActions(
  {
    [LOAD_CANCEL_REASONS_SUCCESS]: (state, action) => {
      return {
        ...state,
        cancelReasons: action.payload,
      };
    },
    [PAUSE_MEMBERSHIP_SUCCESS]: (state, action) => {
      return state;
    },
  },
  defaultState
);
