import { css } from 'styled-components';

export const normalize = css`
  html,
  body {
    font-size: 100%;
  }

  body {
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-smooth: antialiased;
    -moz-font-smoothing: antialiased;
    font-kerning: none;
    text-rendering: optimizeLegibility;
  }

  button {
    border: 0;
    border-radius: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  a,
  a:link {
    text-decoration: none;
  }
`;

export default css`
  ${normalize};

  body {
    margin: 0;
    color: ${props => props.theme.colors.default};
    font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    line-height: ${20 / 14};
    scroll-behavior: smooth;

    button {
      font-family: 'Plain', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
  }
`;
