export const UPSELL_LEARN_MORE_REQUEST = 'UPSELL_LEARN_MORE_REQUEST' as const;
export const UPSELL_NO_THANKS_REQUEST = 'UPSELL_NO_THANKS_REQUEST' as const;
export const UPSELL_ADD_FREE_TRIAL_REQUEST =
  'UPSELL_ADD_FREE_TRIAL_REQUEST' as const;

export function trackUpsellLearnMoreClick() {
  return {
    type: UPSELL_LEARN_MORE_REQUEST,
  };
}

export function trackUpsellNoThanksClick() {
  return {
    type: UPSELL_NO_THANKS_REQUEST,
  };
}

export function trackUpsellAddFreeTrialClick() {
  return {
    type: UPSELL_ADD_FREE_TRIAL_REQUEST,
  };
}
