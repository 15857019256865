import React from 'react';

import styled, { css } from 'styled-components';

import { useTheme } from '@techstyle/react-components';

import { NavBarBGColors } from '../../constants/navBarBGColors';
import { BasicButton } from '../../styles/button';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as QuantityBadge } from '../QuantityBadge';

import { Caret } from './MemberCreditsDesktopButtonCaret';

const expandedStyling = css`
  &[aria-expanded='true'] {
    ${v1`
      color: ${props => props.theme.colors.active};
    `}
    ${QuantityBadge} {
      background: ${props => props.theme.colors.active};
    }

    & ${Caret} {
      transform: translateY(-1px) rotate(0deg);
    }

    ${v2`
      &::after {
        background: ${({ theme }) => theme.colors.lavender};
      }
    `}
  }
`;

const MemberCreditsDesktopButtonV1 = styled(BasicButton)`
  align-items: center;
  background: none;
  border: none;
  border-radius: 0;
  color: ${props =>
    props.theme.context.backgroundState === NavBarBGColors.TRANSPARENT
      ? props.theme.colors.white
      : props.theme.colors.default};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 0;
  margin-top: 2px;
  min-width: auto;
  position: relative;

  & > span {
    display: flex;
  }

  ${expandedStyling}
`;
const MemberCreditsDesktopButtonV2 = styled.div`
  ${v2`
    ${({ theme }) => theme.links.variants.navbarLink}
    display: flex;
    align-items: baseline;
    gap: 8px;
    ${expandedStyling}
  `}
`;

export function MemberCreditsDesktopButton({
  showCaret,
  children,
  isOpen,
  ...props
}) {
  const { themeVersion } = useTheme();
  const isOpenDerivativeProps = {
    isOpen,
    'aria-expanded': isOpen ? 'true' : undefined,
  };
  if (themeVersion === 'v1') {
    return (
      <MemberCreditsDesktopButtonV1 {...props} {...isOpenDerivativeProps}>
        {children}
        {showCaret && <Caret />}
      </MemberCreditsDesktopButtonV1>
    );
  } else if (themeVersion === 'v2') {
    return (
      <MemberCreditsDesktopButtonV2 {...props} {...isOpenDerivativeProps}>
        {children}
        {showCaret && <Caret />}
      </MemberCreditsDesktopButtonV2>
    );
  }
}
