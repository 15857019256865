import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HTMLAsset } from '@techstyle/react-assets';

import { v1, v2 } from '../../utils/themeVersioning';

const SkinnyBannerAsset = styled(HTMLAsset)`
  padding: 6px;
  width: 100%;
  text-align: center;

  ${v1`
    font-size: 12px;
    line-height: ${18 / 12};
    background-color: ${props =>
      props.bgColor ? props.bgColor : props.theme.colors.lavender250};
    padding: 6px;
  `}

  ${v2`
    background-color: ${props => props.theme.colors.gunmetal};
    ${props => props.theme.paragraph.variants.paragraph5Uppercase.textStyles}
    color: ${props => props.theme.colors.lavender};
    padding: 9px 0;

    & a {
      color: ${props => props.theme.colors.lavender};
      border-bottom: 1px solid ${props => props.theme.colors.lavender};
    }
    `}
`;

function MessageCarousel({ assets }) {
  const [counter, setCounter] = useState(0);

  const messageDelay = useCallback(() => {
    setTimeout(() => {
      // Reset counter after the last asset index then loop the assets again
      // Compares the prev count ahead before getting
      // the current state to avoid 5 seconds delay after the reset
      setCounter(count => (count + 1 === assets.length ? 0 : count + 1));
      messageDelay();
    }, 5000);
  }, [assets.length]);

  useEffect(() => {
    if (assets.length) {
      messageDelay();
    }
  }, [assets.length, messageDelay]);

  const renderMessages = (asset, index) => {
    const { bgColor } = asset.options;

    return index === counter ? (
      <SkinnyBannerAsset key={asset.id} asset={asset} bgColor={bgColor} />
    ) : null;
  };

  return assets.length
    ? assets.map((asset, index) => renderMessages(asset, index))
    : null;
}

MessageCarousel.propTypes = {
  assets: PropTypes.array,
};

export default MessageCarousel;
