import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div``;

const TabButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.lavender250};
  background: ${props => props.theme.colors.white};
  width: 100%;
`;

const TabButton = styled.button`
  --active-color: ${({ theme }) => theme.colors.default};
  --active-border-color: ${({ theme }) => theme.colors.default};
  --default-color: ${({ theme }) => theme.colors.subdued};
  --default-border-color: tranparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 14px ${({ theme }) => theme.spacing.small}px 10px
    ${({ theme }) => theme.spacing.small}px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ isSelected }) =>
    isSelected ? 'var(--active-color)' : 'var(--default-color)'};
  border-bottom: 4px solid
    ${({ isSelected }) =>
      isSelected
        ? 'var(--active-border-color)'
        : 'var(--default-border-color)'};
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 'normal')};
`;

const ContentContainer = styled.div.attrs(props => {
  return {
    'data-scroll-lock-scrollable': '',
    ...props,
  };
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const ToggleTabs = ({
  className,
  children,
  tabs = [],
  onTabClick = () => {},
  defaultTabIndex = 0,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[defaultTabIndex]);

  const handleTabClick = useCallback(
    tab => {
      setSelectedTab(tab);
      onTabClick(tab); // handle the additional tab clicking functions
    },
    [onTabClick]
  );

  return (
    <Wrapper className={className}>
      <TabButtonsContainer>
        {tabs.map(tab => (
          <TabButton
            key={tab.name}
            onClick={() => handleTabClick(tab)}
            isSelected={selectedTab.name === tab.name}
            data-text={tab.name}
            data-qa-automation={tab.automationTag}
          >
            {tab.tabTitle}
          </TabButton>
        ))}
      </TabButtonsContainer>
      <ContentContainer>{children({ selectedTab })}</ContentContainer>
    </Wrapper>
  );
};

ToggleTabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func,
  defaultTabIndex: PropTypes.number,
  tabs: PropTypes.array,
  onTabClick: PropTypes.func,
};

ToggleTabs.TabButton = TabButton;
ToggleTabs.TabButtonsContainer = TabButtonsContainer;
ToggleTabs.ContentContainer = ContentContainer;

export default ToggleTabs;
