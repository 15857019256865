import { Dispatch } from 'redux';

import account from '../reducers/account';
import auth from '../reducers/auth';
import borderfree from '../reducers/borderfree';
import byoSxf from '../reducers/byo';
import cart from '../reducers/cart';
import checkout from '../reducers/checkout';
import cms from '../reducers/cms';
import debug from '../reducers/debug';
import pagesRendered from '../reducers/pagesRendered';
import products from '../reducers/products';
import quiz from '../reducers/quiz';
import referrer from '../reducers/referrer';
import retailStoreList from '../reducers/retailStoreList';
import reviews from '../reducers/reviews';
import tracking from '../reducers/tracking';

import bootstrapStore from './bootstrapStore';

export default {
  id: 'savagex',
  reducerMap: {
    account,
    auth,
    borderfree,
    byoSxf,
    cart,
    checkout,
    cms,
    debug,
    pagesRendered,
    products,
    quiz,
    referrer,
    retailStoreList,
    reviews,
    tracking,
  },
  initialActions: [
    (dispatch: Dispatch, getState, context) =>
      bootstrapStore(dispatch, context),
  ],
};
