import { logIn } from '@techstyle/react-accounts';

import { SignUpMethod } from '../../constants/auth';

export const GET_REQUIRE_RESET_PASSWORD_REQUEST =
  'GET_REQUIRE_RESET_PASSWORD_REQUEST';
export const GET_REQUIRE_RESET_PASSWORD_SUCCESS =
  'GET_REQUIRE_RESET_PASSWORD_SUCCESS';
export const GET_REQUIRE_RESET_PASSWORD_FAILURE =
  'GET_REQUIRE_RESET_PASSWORD_FAILURE';
export const RESET_INFLUENCER_PASSWORD_REQUEST =
  'RESET_INFLUENCER_PASSWORD_REQUEST';
export const RESET_INFLUENCER_PASSWORD_SUCCESS =
  'RESET_INFLUENCER_PASSWORD_SUCCESS';
export const RESET_INFLUENCER_PASSWORD_FAILURE =
  'RESET_INFLUENCER_PASSWORD_FAILURE';

export function requireResetPassword(prkey) {
  return {
    bentoApi: {
      endpoint: 'influencers/reset/required',
      method: 'GET',
      searchParams: { prkey: prkey },
      actions: [
        GET_REQUIRE_RESET_PASSWORD_REQUEST,
        GET_REQUIRE_RESET_PASSWORD_SUCCESS,
        GET_REQUIRE_RESET_PASSWORD_FAILURE,
      ],
    },
  };
}

export function resetInfluencerPassword(data) {
  return async dispatch => {
    const params = {
      prkey: data.prkey,
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    const request = {
      bentoApi: {
        endpoint: 'influencers/reset',
        method: 'POST',
        body: JSON.stringify(params),
        actions: [
          RESET_INFLUENCER_PASSWORD_REQUEST,
          RESET_INFLUENCER_PASSWORD_SUCCESS,
          RESET_INFLUENCER_PASSWORD_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);
    await dispatch(
      logIn(
        { username: data.email, password: data.password },
        { method: SignUpMethod.SIGN_IN }
      )
    );

    return result;
  };
}
