import config from 'config';

import { getNewDateFunction } from '@techstyle/redux-core';

import { isSweepstakesActive } from '../../utils/selectors';

export const JOIN_EMAIL_LIST_REQUEST = 'JOIN_EMAIL_LIST_REQUEST';
export const JOIN_EMAIL_LIST_SUCCESS = 'JOIN_EMAIL_LIST_SUCCESS';
export const JOIN_EMAIL_LIST_FAILURE = 'JOIN_EMAIL_LIST_FAILURE';

export const UNSUBSCRIBE_EMAIL_LIST_REQUEST = 'UNSUBSCRIBE_EMAIL_LIST_REQUEST';
export const UNSUBSCRIBE_EMAIL_LIST_SUCCESS = 'UNSUBSCRIBE_EMAIL_LIST_SUCCESS';
export const UNSUBSCRIBE_EMAIL_LIST_FAILURE = 'UNSUBSCRIBE_EMAIL_LIST_FAILURE';

export const FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_REQUEST =
  'FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_REQUEST';
export const FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_SUCCESS =
  'FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_SUCCESS';
export const FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_FAILURE =
  'FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_FAILURE';

export const ENTER_SWEEPSTAKES_REQUEST = 'ENTER_SWEEPSTAKES_REQUEST';
export const ENTER_SWEEPSTAKES_SUCCESS = 'ENTER_SWEEPSTAKES_SUCCESS';
export const ENTER_SWEEPSTAKES_FAILURE = 'ENTER_SWEEPSTAKES_FAILURE';

export const VIP_BOX_SUBSCRIPTION_REQUEST = 'VIP_BOX_SUBSCRIPTION_REQUEST';
export const VIP_BOX_SUBSCRIPTION_SUCCESS = 'VIP_BOX_SUBSCRIPTION_SUCCESS';
export const VIP_BOX_SUBSCRIPTION_FAILURE = 'VIP_BOX_SUBSCRIPTION_FAILURE';

const defaultMarketingList = config.get('public.sailthru.marketingLists');

export function subscribe({ email, optOutStatus }) {
  return async (dispatch, getState) => {
    const { domain } = getState();

    const marketingList = defaultMarketingList[domain.tld];
    const marketingLists = marketingList ? [marketingList] : [];
    /** optOutStatus values:
     * "Always" must be sent as 'none' with an email_cadence: 0
     * "Sometimes" must be sent as 'none' with an email_cadence: 1
     * "Never" must be sent as 'basic' with an email_cadence: 0
     */
    let status;
    let emailCadence;
    switch (optOutStatus) {
      case 'never':
        status = 'basic';
        emailCadence = 0;
        break;
      case 'sometimes':
        status = 'none';
        emailCadence = 1;
        break;
      case 'always':
        status = 'none';
        emailCadence = 0;
        break;
    }
    const result = await dispatch({
      bentoApi: {
        endpoint: 'contacts',
        method: 'POST',
        body: JSON.stringify({
          email,
          optOutStatus: status,
          marketingLists,
          attributes: {
            email_cadence: emailCadence,
          },
        }),
        actions: [
          JOIN_EMAIL_LIST_REQUEST,
          JOIN_EMAIL_LIST_SUCCESS,
          JOIN_EMAIL_LIST_FAILURE,
        ],
      },
    });
    return result;
  };
}

export function subscribeNewFreeTrialMember({ email }) {
  return async (dispatch, getState) => {
    const { domain } = getState();
    const newMemberList = config.get('public.sailthru.newFreeTrialMember');
    const marketingList = config.get('public.sailthru.marketingLists')[
      domain.tld
    ];

    const marketingLists = marketingList
      ? [marketingList, newMemberList]
      : [newMemberList];

    const result = await dispatch({
      bentoApi: {
        endpoint: 'contacts',
        method: 'POST',
        body: JSON.stringify({
          email,
          marketingLists,
        }),
        actions: [
          FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_REQUEST,
          FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_SUCCESS,
          FREE_TRIAL_MEMBER_JOIN_EMAIL_LIST_FAILURE,
        ],
      },
    });
    return result;
  };
}

export function enterSweepstakes({ email, phone, zip }) {
  return async (dispatch, getState) => {
    const state = getState();
    let marketingLists;

    if (isSweepstakesActive) {
      marketingLists = config.get('public.sailthru.sweepstakes');
    } else {
      const fashionShowList = config.get('public.sailthru.fashionShow')[
        state.domain.tld
      ];
      marketingLists = fashionShowList ? [fashionShowList] : [];
    }

    const request = {
      bentoApi: {
        endpoint: 'contacts',
        method: 'POST',
        body: JSON.stringify({
          email,
          marketingLists,
          attributes: {
            phone,
            zip,
          },
        }),
        actions: [
          ENTER_SWEEPSTAKES_REQUEST,
          ENTER_SWEEPSTAKES_SUCCESS,
          ENTER_SWEEPSTAKES_FAILURE,
        ],
      },
    };

    const result = await dispatch(request);
    if (!result.payload.userExists) {
      await dispatch(subscribe({ email, optOutStatus: 'always' }));
    }

    return result;
  };
}

export function subscribeVipBoxNotification({ email, attributes }) {
  return async (dispatch, getState) => {
    const state = getState();
    const getNewDate = getNewDateFunction(state);
    // Each month create a new VIP box marketing list
    const now = getNewDate();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const vipBoxList = `${year}_${month}_Xtra_VIP_Box`;
    const masterMarketingList = config.get('public.sailthru.marketingLists')[
      state.domain.tld
    ];
    const marketingLists = [masterMarketingList, vipBoxList];

    const result = await dispatch({
      bentoApi: {
        endpoint: 'contacts',
        method: 'POST',
        body: JSON.stringify({
          email,
          marketingLists,
          attributes,
        }),
        actions: [
          VIP_BOX_SUBSCRIPTION_REQUEST,
          VIP_BOX_SUBSCRIPTION_SUCCESS,
          VIP_BOX_SUBSCRIPTION_FAILURE,
        ],
      },
    });
    return result;
  };
}

export function unsubscribeFromEmarsys(payload) {
  return async dispatch => {
    const result = await dispatch({
      bentoApi: {
        endpoint: 'marketing/email/unsubscribe',
        method: 'POST',
        body: JSON.stringify(payload),
        actions: [
          UNSUBSCRIBE_EMAIL_LIST_REQUEST,
          UNSUBSCRIBE_EMAIL_LIST_SUCCESS,
          UNSUBSCRIBE_EMAIL_LIST_FAILURE,
        ],
      },
    });
    return result;
  };
}
