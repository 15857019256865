import React, { useMemo } from 'react';

import { useAsset } from '@techstyle/react-assets';

import { toStringSet } from '../../utils/assets';
import { useShouldShowNewPricingSkinnyBanner } from '../../utils/useShouldShowNewPricingSkinnyBanner';
import MessageCarousel from '../MessageCarousel/MessageCarousel';

export const NEW_PRODUCT_PRICING_FLAG = 'newProductPricing'.toLowerCase();

const NewPricingSkinnyBanner = () => {
  const shouldShowNewPricingSkinnyBanner =
    useShouldShowNewPricingSkinnyBanner();

  const { data } = useAsset(
    'products_html_skinnybanner',
    // useAsset is not properly typed, so ignore typing
    // @ts-ignore
    {
      multi: true,
    }
  );

  const assets = useMemo(() => (data ? data.assets : []), [data]);
  const assetsToShow = useMemo(
    () =>
      assets.filter(asset => {
        const flagsSet = toStringSet(asset.options.customVars.ABtest, true);

        return flagsSet.has(NEW_PRODUCT_PRICING_FLAG);
      }),
    [assets]
  );

  if (!shouldShowNewPricingSkinnyBanner || assetsToShow.length === 0) {
    return null;
  }

  return <MessageCarousel assets={assetsToShow} />;
};

export default NewPricingSkinnyBanner;
