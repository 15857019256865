import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useDomain, useSession } from '@techstyle/redux-core';

import { checkMembershipPromos } from '../../actions/account/promos';
import { getOneHourLead } from '../../utils/selectors';

function UKOneHourPromosExpire() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSession();
  const { tld } = useDomain();
  const oneHourLead = useSelector(getOneHourLead);

  // Refresh everything promo-related after user is registered for 1 hour
  useEffect(() => {
    // This feature is for UK logged in users only
    if (tld !== '.co.uk' || !isLoggedIn) {
      return;
    }

    // Ideally we'd just get `products` fresh from the API to update promos, but if the user
    // has been browsing for 1 hour there is a good chance they have 100s of products
    // in redux, as well as many products that came from the `recommended` endpoint,
    // and we have no way of knowing which products they're currently viewing.
    // Requesting ALL of these products from the API requires a huge payload so
    // ultimately it is more efficient to just reload the current page.
    const { now, endDate, isExpired } = oneHourLead;

    if (endDate && !isExpired) {
      const oneHourAfterRegistrationTimer = setTimeout(async () => {
        await dispatch(checkMembershipPromos({ forceCheck: true }));
        window.location.reload();
      }, endDate - now);

      return () => clearTimeout(oneHourAfterRegistrationTimer);
    }
  }, [isLoggedIn, tld, dispatch, oneHourLead]);

  return null;
}

export default UKOneHourPromosExpire;
