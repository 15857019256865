import {
  loadProfile,
  getMembership,
  loadMembership,
} from '@techstyle/react-accounts';

import { loadAddresses } from '../../actions/account';

export const TRACK_ACCOUNT_SEGMENT_EVENTS = 'TRACK_ACCOUNT_SEGMENT_EVENTS';
export const IDENTIFY_WITH_TRAITS = 'IDENTIFY_WITH_TRAITS';

export function trackAccountSegmentEvents() {
  return async (dispatch, getState, context) => {
    await Promise.all([
      dispatch(loadAddresses()),
      dispatch(loadMembership()),
      dispatch(loadProfile()),
    ]);
    const state = getState();

    const { customer: profile, account } = state;
    const { profile: profileBirthday } = profile;

    const { addresses } = account;
    const zipCode =
      addresses?.data && addresses.data.length > 0 ? addresses.data[0].zip : '';

    const { isPaygo, membershipLevelGroup } = getMembership(state);
    const membershipStatus = isPaygo ? 'Payg' : membershipLevelGroup;

    const {
      'birth-day': birthDay,
      'birth-month': birthMonth,
      'birth-year': birthYear,
    } = profileBirthday || {};

    const birthday =
      birthDay && birthMonth && birthYear
        ? `${birthMonth}-${birthDay}-${birthYear}`
        : '';

    return dispatch({
      type: TRACK_ACCOUNT_SEGMENT_EVENTS,
      payload: { profile, zipCode, birthday, membershipStatus },
    });
  };
}

export function trackIdentifyWithTraits(params) {
  return {
    type: IDENTIFY_WITH_TRAITS,
    payload: {
      traits: params,
    },
  };
}
