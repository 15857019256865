import React from 'react';

import config from 'config';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getMembership } from '@techstyle/react-accounts';
import { TextLink } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import { desktop } from '../../styles';
import { BasicButton } from '../../styles/button';
import { Paragraph } from '../../styles/paragraph';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as Price } from '../ExtendedPrice';
import { Component as XtraSavageIcon } from '../XtraSavageIcon';

const hoverLinkStyling = css`
  text-decoration: none;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.active};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }
`;
export const LearnMoreLink = styled(TextLink).attrs({
  tagName: 'button',
})`
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  ${v2`
    ${({ theme }) => theme.links.variants.textLinkSecondary}
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    color: ${props => props.theme.colors.lavender700};
  `}
  ${v1`
    transition: all 0.4s ease-out;
    flex: 0 0 auto;
    position: relative;
    padding: 0px 0px 3px;
    background: transparent;
    color: ${props => props.theme.colors.active};
    ${hoverLinkStyling}
  `}
`;

export const PromoParagraph = styled(Paragraph)`
  ${v1`
    color: ${({ theme }) => theme.colors.promo};
  `}
  ${v2`
    font-size: 12px;
    line-height: 15px;
  `}
`;

export const AddMembershipTitleParagraph = styled(Paragraph)`
  ${v2`
      line-height: 15px
    `}
`;

const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  ${desktop`
    width: 80px;
    height: 80px;
  `}
`;
const Picture = styled.div<{ bg: string }>`
  width: 100%;
  height: 100%;
  background-image: ${props => `url("${props.bg}")`};
  background-position: center center;
  background-size: cover;
`;

const FallbackXtraSavageIcon = styled(XtraSavageIcon as any)`
  height: 32px;
  width: 32px;
  color: ${props => props.theme.colors.active};
`;

const membershipProductImage = config.get('public.membershipProductImage');
export const MembershipProductImage = () => {
  return membershipProductImage.thumbnailURL ? (
    <ImageWrapper>
      <Picture bg={membershipProductImage.thumbnailURL} />
    </ImageWrapper>
  ) : (
    <FallbackXtraSavageIcon />
  );
};

export const TextContent = styled.div`
  flex: 1;
  font-weight: 500;

  padding: 0 12px;
  ${v2`
    text-transform: uppercase;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1px;
  `}
`;

export const AddMembershipButton = styled(BasicButton as any).attrs(
  ({ theme }) => ({
    white: theme.themeVersion === 'v1',
    buttonStyle:
      theme.themeVersion === 'v2' &&
      css`
        ${theme.button.variants.tertiaryLight.buttonStyle}
        border: none;
        padding: ${({ theme }) =>
          `${theme.spacing.tiny}px ${theme.spacing.moderate}px`};
      `,
  })
)`
  min-width: 50px;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;

  ${({ theme }) => theme.context.cartDrawer && `font-size: 12px;`};
  ${({ theme }) =>
    theme.context.cart &&
    `
      text-transform: uppercase;
      min-width: 76px;
      padding: 6px 16px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 1.75px;
      height: unset;
      line-height: 16px;
  `};
`;

const MembershipDetailsParagraph = styled(Paragraph)`
  font-size: 12px;
  ${v1`
    margin-bottom: 12px;
    line-height: 1.5;
    font-weight: 300;
  `}
  ${v2`
      font-weight: 400;
      color: ${props => props.theme.colors.gunmetal};
      line-height: 15px;
      min-height: 0;
      :not(:last-child) {
        margin-bottom: 16px;
      }
  `}
`;

const StyledText = styled.span<{ allCaps?: boolean }>`
  ${v1`
      color: ${props => props.theme.colors.active};
  `}
  ${v2`
    color: ${props => props.theme.colors.lavender700};
    ${({ allCaps, theme }) =>
      allCaps
        ? theme.subHeader.variants.subHeading4Uppercase.textStyles
        : theme.subHeader.variants.subHeading4.textStyles}
  `}
`;

export const MembershipDetails = () => {
  const { 'emp-membership-onboarding': isEmpFeatureEnabled } = useLDFlags();
  const membershipPrice = useSelector(getMembership)?.price;

  return (
    <>
      <MembershipDetailsParagraph>
        {isEmpFeatureEnabled ? (
          <FormattedMessage
            id="global_checkout.shop_emp_tip_p1"
            defaultMessage="{membership} allows you to shop or “Skip the Month” by the 5th of each month or your payment method will be charged {formattedMembershipPrice} on the 6th until you cancel. Cancel anytime by calling 866-337-0906."
            values={{
              membership: (
                <StyledText>
                  <FormattedMessage
                    id="global_checkout.emp_flexible_vip"
                    defaultMessage="Flexible VIP Membership"
                  />
                </StyledText>
              ),
              formattedMembershipPrice: <Price amount={membershipPrice} />,
            }}
          />
        ) : (
          <FormattedMessage
            id="global_checkout.shop_vip_tip_p1"
            defaultMessage="{membership} allows you to shop or “Skip the Month” by the 5th of each month or your payment method will be charged {formattedMembershipPrice} on the 6th until you cancel. Cancel anytime by calling 866-337-0906."
            values={{
              membership: (
                <StyledText allCaps>
                  <FormattedMessage
                    id="global_checkout.flexible_vip"
                    defaultMessage="Flexible VIP Membership"
                  />
                </StyledText>
              ),
              formattedMembershipPrice: <Price amount={membershipPrice} />,
            }}
          />
        )}
      </MembershipDetailsParagraph>
      <MembershipDetailsParagraph>
        {isEmpFeatureEnabled ? (
          <FormattedMessage
            id="global_checkout.shop_emp_tip_p2"
            defaultMessage="{skip} Your {formattedMembershipPrice} is actually accumulating member credit that can be applied towards your future purchases."
            values={{
              skip: (
                <StyledText>
                  <FormattedMessage
                    id="global_checkout.emp_forgot_skip"
                    defaultMessage="Forget to skip the month? No worries!"
                  />
                </StyledText>
              ),
              formattedMembershipPrice: <Price amount={membershipPrice} />,
            }}
          />
        ) : (
          <FormattedMessage
            id="global_checkout.shop_vip_tip_p2"
            defaultMessage="{skip} Your {formattedMembershipPrice} is actually accumulating member credit that can be applied towards your future purchases."
            values={{
              skip: (
                <StyledText>
                  <FormattedMessage
                    id="global_checkout.forgot_skip"
                    defaultMessage="Forget to skip the month? No worries!"
                  />
                </StyledText>
              ),
              formattedMembershipPrice: <Price amount={membershipPrice} />,
            }}
          />
        )}
      </MembershipDetailsParagraph>
    </>
  );
};
