import { loadDirectMarketingDisposition } from '@techstyle/react-marketing';

export async function getExperienceId({
  dispatch,
  disposition,
  dmGatewayCode,
  isLoggedIn,
  query,
}) {
  let experienceId;
  let code;
  const options = {};

  // allow a dmg parameter to overwrite your dmg
  if (!isLoggedIn && query?.dmg) {
    code = query.dmg;
  } else if (dmGatewayCode) {
    code = dmGatewayCode;
  }

  // allow a gender parameter to overwrite your gender
  if (!isLoggedIn && query?.gender) {
    options.gender = query.gender;
  }

  // only load it if we don't already have the disposition or if we are supposed to change the dmg or gender
  if (
    disposition?.experience &&
    (!code || code === disposition?.gateway) &&
    (!options?.gender || disposition?.gender === options?.gender)
  ) {
    return disposition.experience;
  }

  try {
    disposition = await dispatch(loadDirectMarketingDisposition(code, options));
    experienceId = disposition?.payload.experience;
  } catch (e) {
    experienceId = '';
  }

  return experienceId || '';
}
