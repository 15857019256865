import config from 'config';
import qs from 'qs';

import { Router } from '@techstyle/next-routes';

import formatProductUrl from './formatProductUrl';

const productTypeSetId = config.get('public.productTypes.setId');

export const noResultMaxItemCount = 16;

export const searchProductSource = 'search_results';

export const pageSize = 10;

export const fplSortOption = {
  value: 'fplasc',
  sort: 'fplasc',
  defaultMessage: 'Default',
  isDefault: true,
};

export const defaultSortOptions = [
  {
    value: '',
    isDefault: true,
    sort: '',
    defaultMessage: 'Default',
  },
  {
    value: 'newest',
    sort: 'newest',
    defaultMessage: 'New Arrivals',
  },
  {
    value: 'bestsellers',
    sort: 'bestsellers',
    defaultMessage: 'Best Sellers',
  },
  {
    value: 'pricel2h',
    sort: 'pricel2h',
    defaultMessage: 'Price: Low to High',
  },
  {
    value: 'priceh2l',
    sort: 'priceh2l',
    defaultMessage: 'Price: High to Low',
  },
];

export const defaultCioSortOptions = [
  {
    value: 'relevance',
    isDefault: true,
    sort: 'relevance',
    sortOrder: 'descending',
    defaultMessage: 'Default',
  },
  {
    value: 'newest',
    sort: 'newest',
    sortOrder: 'descending',
    defaultMessage: 'New Arrivals',
  },
  {
    value: 'bestsellers',
    sort: 'best_sellers',
    sortOrder: 'descending',
    defaultMessage: 'Best Sellers',
  },
  {
    value: 'pricel2h',
    sort: 'pricel2h',
    sortOrder: 'ascending',
    defaultMessage: 'Price: Low to High',
  },
  {
    value: 'priceh2l',
    sort: 'priceh2l',
    sortOrder: 'descending',
    defaultMessage: 'Price: High to Low',
  },
];

export const ActionTypes = {
  RESET_STATE: 'RESET_STATE',
  PRODUCT_RESPONSE: 'PRODUCT_RESPONSE',
  SUGGESTIONS_RESPONSE: 'SUGGESTIONS_RESPONSE',
};

export const ItemTypes = {
  SUGGESTION: 'suggestion',
  PRODUCT: 'product',
  RESULTS_LINK: 'results_link',
};

export const initialState = {
  byKey: {},
  suggestions: {
    alternatives: [],
  },
};

export const normalizedProductResponse = (state = initialState, action) => {
  const {
    fromCorrectedQuery = false,
    originalQuery = null,
    storeKey,
  } = action.meta;
  const { merchandisingContent, page, products, size, total } = action.payload;
  const productsWithItemTypes = products.map(product => {
    return {
      ...product,
      itemType: ItemTypes.PRODUCT,
    };
  });
  const storeKeyData = state.byKey[storeKey] || {
    originalQuery,
    fromCorrectedQuery,
  };
  const stateSize = storeKeyData.size || 0;
  const currentSize = stateSize + size;
  const stateProducts = storeKeyData.items || [];

  const keyedProductData = {
    [storeKey]: {
      ...storeKeyData,
      merchandisingContent,
      page,
      size: currentSize,
      total,
      items: [...stateProducts, ...productsWithItemTypes],
      hasMore: currentSize <= total,
      correctedQuery: fromCorrectedQuery ? storeKey : '',
      originalQuery,
      fromCorrectedQuery,
      storeKey,
    },
  };

  return {
    ...state,
    byKey: { productError: false, ...state.byKey, ...keyedProductData },
  };
};

export const handleSuggestionsResponse = (state = initialState, action) => {
  const alternatives = action.payload.alternatives.map(alternative => {
    return {
      ...alternative,
      itemType: ItemTypes.SUGGESTION,
    };
  });
  return {
    ...state,
    suggestionError: false,
    suggestions: {
      ...state.suggestions,
      ...action.payload,
      alternatives,
    },
  };
};

export function reducer(state, action) {
  // obvi a test, this needs to be more robust
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }
    case ActionTypes.PRODUCT_RESPONSE: {
      if (action.error) {
        return {
          ...state,
          productError: true,
        };
      }
      return normalizedProductResponse(state, action);
    }
    case ActionTypes.SUGGESTIONS_RESPONSE: {
      if (action.error) {
        return {
          ...state,
          suggestionError: true,
        };
      }
      return handleSuggestionsResponse(state, action);
    }
  }
}

export async function handleNonHighlightedEnter({ inputValue }) {
  // selectedItem should be null as no item is highlighted
  // but we should have an `inputValue` and push that as query to srp
  if (inputValue) {
    await Router.push({ pathname: '/search', query: { q: inputValue } });
  }
}

export async function handleHighlightedItemEnter({ inputValue, selectedItem }) {
  // selectedItem can have an `itemType` of `suggestion`, `product`, `results_link`
  // with different object shapes
  if (selectedItem) {
    if (
      selectedItem.itemType === ItemTypes.SUGGESTION ||
      selectedItem.itemType === ItemTypes.RESULTS_LINK
    ) {
      // push to search page with query in url
      await Router.push({ pathname: '/search', query: { q: inputValue } });
    } else if (selectedItem.itemType === ItemTypes.PRODUCT) {
      const productId =
        selectedItem.master_product_id || selectedItem.masterProductId;
      const { permalink } = selectedItem;
      const productTypeId =
        selectedItem.product_type_id || selectedItem.productTypeId;
      const productType =
        productTypeId === productTypeSetId ? 'sets' : undefined;
      // push to pdp
      await Router.push(
        formatProductUrl({
          extraQueryParams: { psrc: 'featured_results' },
          permalink,
          productId,
          productType,
          searchQuery: inputValue,
        }).as
      );
    }
  }
}

export function stringifySearchQuery(params) {
  return qs.stringify(params);
}
