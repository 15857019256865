export const PAGE_RENDERED = 'PAGE_RENDERED';
export const ERROR_PAGE_RENDERED = 'ERROR_PAGE_RENDERED';

export const TRACK_FEATURED_COLLECTION_TILES =
  'TRACK_FEATURED_COLLECTION_TILES';
export const TRACK_FIT_GUARANTEE = 'TRACK_FIT_GUARANTEE';
export const TRACK_HOMEPAGE_CAROUSEL = 'TRACK_HOMEPAGE_CAROUSEL';

export function errorPageRendered(url, statusCode) {
  return {
    type: ERROR_PAGE_RENDERED,
    url,
    statusCode,
  };
}

export function pageRendered(url) {
  return { type: PAGE_RENDERED, url };
}

export function trackFeaturedCollectionTiles(eventLabel) {
  return {
    type: TRACK_FEATURED_COLLECTION_TILES,
    eventLabel: eventLabel,
  };
}

export function trackFitGuarantee(eventLabel) {
  return {
    type: TRACK_FIT_GUARANTEE,
    eventLabel: eventLabel,
  };
}

export function trackHomepageCarousel(eventLabel) {
  return {
    type: TRACK_HOMEPAGE_CAROUSEL,
    eventLabel: eventLabel,
  };
}
