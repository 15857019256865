export const MembershipActionLocation = {
  PDP: 'PDP', // Action from PDP
  SHOPPING_BAG: 'SHOPPING_BAG', // Action  from SHOPPING_BAG
  XTRA_SAVAGE_PAGE: 'XTRA_SAVAGE_PAGE', // Action  from XTRA_SAVAGE_PAGE
  MINI_CART: 'MINI_CART', // Action from MINI_CART.
  UPSELLS: 'UPSELLS', // Action from UPSELLS.
  OTHER: 'OTHER', // Action from OTHER.
} as const;

export const MembershipEventAction = {
  AUTO_ADD: 'add_membership_auto', // Membership item automatically added to cart
  MANUAL_ADD: 'add_membership', // Membership item manually added back to cart
  REMOVE: 'remove_membership', // Remove membership
  LEARN_MORE: 'membership_learn_more',
} as const;

export const MondialShippingType = {
  HOME: 'home',
  PICKUP: 'pickup',
} as const;

export const AdyenMessageTypes = {
  REDIRECT_READY: 'REDIRECT_READY',
  REDIRECT_SUBMIT: 'REDIRECT_SUBMIT',
  REDIRECT_COMPLETE: 'REDIRECT_COMPLETE',
} as const;

export const AddToBagErrorCode = {
  INAPPROPRIATE_CUSTOM_TEXT: 10009,
  OUT_OF_STOCK: 10006,
} as const;

export const ActiveSection = {
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  COMPLETE: 'complete',
} as const;

export const OrderTaxTypes = {
  DELIVERY: 'delivery',
  HANDLING: 'handling',
  MERCHANDISE: 'merchandise',
} as const;

export const CheckoutFeatures = {
  RETAIL_PRICE_TEST: 'RETAILPRICETEST',
} as const;
