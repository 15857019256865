export const TRACK_SIZE_PROMPT_ACTION = 'TRACK_SIZE_PROMPT_ACTION';
export const TRACK_SIZE_UPDATE_SUCCESS = 'TRACK_SIZE_UPDATE_SUCCESS';

export function trackSizePromptAction({ eventLabel }) {
  return {
    type: TRACK_SIZE_PROMPT_ACTION,
    eventLabel,
  };
}

export function trackSizeUpdateSuccess({ eventLabel }) {
  return {
    type: TRACK_SIZE_UPDATE_SUCCESS,
    eventLabel,
  };
}
