import React from 'react';

import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Button } from '@techstyle/react-components';

import { v1, v2 } from '../utils/themeVersioning';

const getCombinedButtonStyle = (buttonStyle, variant) => css`
  ${v1`
    ${props => props.theme.button.defaultStyle}
    ${buttonStyle}
  `}

  ${v2`
    ${props =>
      variant
        ? props.theme.button.variants[variant].buttonStyle
        : props.theme.button.defaultStyle}
    ${buttonStyle}
    ${({ theme, alternate }) => alternate && `color: ${theme.colors.gunmetal};`}
  `}
`;

export const BasicButton = React.forwardRef(function BasicButtonBase(
  { buttonStyle = [], variant, ...rest },
  ref
) {
  return (
    <Button
      buttonStyle={getCombinedButtonStyle(buttonStyle, variant)}
      variant={variant}
      {...rest}
      ref={ref}
    />
  );
});

BasicButton.propTypes = {
  /**
   * Styles to pass to the button element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  buttonStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};
