import { getRequestedTests } from '@techstyle/react-abtest';
import { getCustomer, getMembership } from '@techstyle/react-accounts';
import { createSelector, getSession } from '@techstyle/redux-core';

import {
  getSXFMembershipStatus,
  getMembershipStatus,
} from './getMembershipStatus';

// TODO: We should probably move this to a reducer somewhere, but
// this will work for now.
let userStatusInitial = null;
let statusSetAfterLogin = false;

/* eslint-disable camelcase */
type FullTrackingData = {
  /** `true` when the `automated_test` cookie is found. */
  automated_test: boolean;
  availableTokenQuantity: number;
  brand: 'Savage X';
  dmg_code?: string;
  /** Always `'react'`. */
  feature: 'react';
  gateway: boolean;
  is_vip: boolean;
  loggedin_status: boolean;
  membership_status: string | null;
  session_id: string;
  store_group_id: string;
  store_id: string;
  sxf_membership_status: string;
  tests_active: string;
  user_id?: string;
  user_status_initial: string;
  membership_credits: number;
};
/* eslint-enable camelcase */

type TrackingDataKeys = keyof FullTrackingData;

const getTestsActive = createSelector([getRequestedTests], requestedTests => {
  const testsActive = [];

  if (requestedTests) {
    requestedTests.forEach(singleTest => {
      if (singleTest?.data?.cohortNumber) {
        const { campaignCode, campaignCodeKey, cohortNumber } = singleTest.data;
        testsActive.push(`${campaignCodeKey ?? campaignCode}:${cohortNumber}`);
      }
    });
  }

  return testsActive.join(',');
});

// A utility function to get the tracking data for a segment event.
export function getFullTrackingData(nextState, context): FullTrackingData {
  const { cookies } = context;
  const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
  const testsActive = getTestsActive(nextState);
  const { availableTokenQuantity, isVip, membershipCredits } =
    getMembership(nextState);
  const { id: userId } = getCustomer(nextState);
  const { preserveUserStatusInitial } = nextState.auth;
  const { storeGroupId, storeId } = nextState.storeGroup;
  const sxfMembershipStatus = getSXFMembershipStatus(nextState);
  const membershipStatus = getMembershipStatus(nextState);

  // After logging in, we should use that as the new user status initial,
  // but if they sign up, we should preserve the user status initial.
  if (!userStatusInitial) {
    userStatusInitial = getSXFMembershipStatus(nextState);
  } else if (isLoggedIn && !preserveUserStatusInitial && !statusSetAfterLogin) {
    userStatusInitial = getSXFMembershipStatus(nextState);
  }

  if (isLoggedIn && !statusSetAfterLogin) {
    statusSetAfterLogin = true;
  }

  return {
    automated_test: Boolean(cookies.get('automated_test')),
    availableTokenQuantity: availableTokenQuantity ?? 0,
    brand: 'Savage X',
    dmg_code: dmGatewayCode ?? undefined,
    feature: 'react',
    gateway: Boolean(dmGatewayCode),
    is_vip: isVip,
    loggedin_status: isLoggedIn,
    membership_status: membershipStatus,
    session_id: sessionId.toString(),
    store_group_id: storeGroupId.toString(),
    store_id: storeId.toString(),
    sxf_membership_status: sxfMembershipStatus,
    tests_active: testsActive,
    user_id: userId ?? undefined,
    user_status_initial: userStatusInitial,
    membership_credits: membershipCredits ?? 0,
  };
}

export function getPartialTrackingData<TKey extends TrackingDataKeys>(
  nextState,
  context,
  keys: TKey[]
) {
  const fullTrackingData = getFullTrackingData(nextState, context);
  const partialTrackingData = {} as Partial<FullTrackingData>;

  keys.forEach(key => {
    partialTrackingData[key] = fullTrackingData[key];
  });

  return partialTrackingData as Pick<FullTrackingData, TKey>;
}
