import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextLink } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';
import { withCookies } from '@techstyle/redux-core';

import { SignUpMethod } from '../../constants/auth';
import {
  LoginErrors,
  SIGN_IN,
  SIGN_UP,
  RESET_PASSWORD,
  ACCOUNT_EXISTS,
  AUTH_FAILED,
  EMAIL_REQUIRED,
  INVALID_EMAIL,
  REFERRER_REQUIRED,
  PASSWORD_INVALID,
  PASSWORD_REQUIRED,
  PASSWORD_VERIFY_MATCH,
  PASSWORD_VERIFY_REQUIRED,
  TERMS_CONDITIONS_REQUIRED,
  GENERAL_ERROR,
  GUEST_SIGN_UP,
  GUEST_SIGN_IN,
  CHECKOUT_SIGN_UP,
  CHECKOUT_SIGN_IN,
  ACCOUNT_EXISTS_GUEST,
  FORGOT_PASSWORD,
} from '../../constants/signIn';
import { validateEmail } from '../../utils/email';
import { formatEmailPreferencesPayload } from '../../utils/formatEmailPreferences';
import { validateZip } from '../../utils/validateShipping';
import { Component as Loading } from '../Loading';
import { Component as SignInContent, linkStyles } from '../SignInContent';

const SetupPasswordButton = styled(TextLink).attrs({
  as: 'button',
})`
  ${linkStyles}
`;

const defaultCountries = config.get('public.domain.defaultCountries');
const isTestSecretRecaptchaNeeded = config.get(
  'public.recaptcha.automatedTestSecretsNeeded'
);
const isRecaptchaEnabled = config.get('public.recaptcha.enabled');

// State to reset when the screen changes.
const resetState = {
  nameError: null,
  // Error on the email field, if any.
  emailError: null,
  // Error on the password field, if any.
  passwordError: null,
  // Error on the verify password field, if any.
  passwordVerifyError: null,
  // Error on the referrer field, if any.
  referrerError: null,
  // Error on the terms and conditions field, if any.
  termsConditionsError: null,
  // error on login recaptcha validation
  loginReCaptchaError: null,
  // error on zipcode validation
  zipCodeError: null,
  // error on forgot password recaptcha validation
  forgotPasswordReCaptchaError: null,
  // Sign Up status, including errors.
  signUpStatus: {},
  // Sign In status, including errors.
  signInStatus: {},
  // Forgot Password status, including errors.
  forgotPasswordStatus: {},
  // Reset Password status, including errors.
  resetPasswordStatus: {},
  // Error message after failed submit
  toastMessage: null,
  // Guest Sign Up status, including errors.
};

const SIGN_IN_SCREEN_MAP = {
  [SIGN_UP]: SIGN_IN,
  [GUEST_SIGN_UP]: GUEST_SIGN_IN,
  [CHECKOUT_SIGN_UP]: CHECKOUT_SIGN_IN,
};

class SignIn extends React.PureComponent {
  static propTypes = {
    cookies: PropTypes.any,
    disabled: PropTypes.bool,
    extraSignUpData: PropTypes.object,
    formRef: PropTypes.object,
    handleModalClose: PropTypes.func,
    initialEmail: PropTypes.string,
    initialName: PropTypes.string,
    initialScreen: PropTypes.string,
    initialSubscribeToEmails: PropTypes.bool, // Whether to default the subscribe to emails option to checked or not
    inputVariant: PropTypes.oneOf(['default', 'fullBorder']),
    isAdmin: PropTypes.bool,
    isCheckoutSignUp: PropTypes.bool,
    loadCartItemCount: PropTypes.func,
    onCloseSignInModal: PropTypes.func,
    onError: PropTypes.func,
    onExistingUserError: PropTypes.func,
    onFormErrors: PropTypes.func,
    onRequestAuthenticate: PropTypes.func,
    onRequestResetPassword: PropTypes.func,
    onRequestSignUp: PropTypes.func,
    onRequestUpdatePassword: PropTypes.func,
    onResetSuccess: PropTypes.func,
    onSignInClick: PropTypes.func,
    onSignIn: PropTypes.func,
    onSignUp: PropTypes.func,
    onSuccess: PropTypes.func,
    passwordResetKey: PropTypes.string,
    profile: PropTypes.object,
    referrerOptions: PropTypes.array,
    region: PropTypes.string,
    setToggledState: PropTypes.func,
    shouldFitSpace: PropTypes.bool,
    shouldShowPrivacyNotice: PropTypes.bool,
    showBirthdateField: PropTypes.bool,
    showBottomPrivacyLink: PropTypes.bool,
    showBottomTermsConditionsLink: PropTypes.bool,
    showEmailField: PropTypes.bool,
    showNameField: PropTypes.bool,
    showPasswordField: PropTypes.bool,
    showReferrerField: PropTypes.bool,
    showSignUpFields: PropTypes.bool,
    showSubscribeToEmails: PropTypes.bool, // Whether to display email subscription
    showSwitcher: PropTypes.bool,
    showTermsConditions: PropTypes.bool,
    showZipCodeField: PropTypes.bool,
    tld: PropTypes.string,
    trackAccountSegmentEvents: PropTypes.func,
    trackIdentifyQuizTraits: PropTypes.func,
    trackRegistrationFailure: PropTypes.func,
    trackSignUpModal: PropTypes.func,
    updateEmailPreferences: PropTypes.func,
    updateSessionDetail: PropTypes.func,
    privacyNotice: PropTypes.string,
    haveAnAccountMessage: PropTypes.string,
    terms: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    initialEmail: '',
    initialName: '',
    initialScreen: SIGN_IN,
    initialSubscribeToEmails: false,
    isAdmin: false,
    onError: () => {},
    onExistingUserError: () => {},
    onFormErrors: () => {},
    onResetSuccess: () => {},
    onRequestSignUp: () => {},
    onSuccess: () => {},
    referrerOptions: [],
    setToggledState: () => {},
    shouldTrackSignUpModalOnMount: true,
    showEmailField: true,
    showNameField: false,
    showPasswordField: true,
    showSignUpFields: true,
    showSubscribeToEmails: true,
    showSwitcher: true,
    showTermsConditions: true,
    showZipCodeField: false,
    trackIdentifyQuizTraits: () => {},
  };

  constructor(props) {
    super(props);
    this.requestingSignUp = false;
    this.state = {
      ...resetState,
      displayGuestSignUpPassword: false,
      // Value and handler for email field.
      emailField: {
        value: props.initialEmail,
        onChange: this.changeEmail,
      },
      // Value and handler for password field.
      passwordField: {
        value: '',
        onChange: this.changePassword,
      },
      // Value and handler for name field.
      firstNameField: {
        value: props.initialName,
        onChange: this.changeFirstName,
      },
      // Value and handler for verify password field.
      passwordVerifyField: {
        value: '',
        onChange: this.changeVerifyPassword,
      },
      // Value and handler for referrer field
      referrerField: {
        value: '',
        onChange: this.changeReferrer,
      },
      // values for terms and conditions input.
      termsConditionsField: {
        checked: false,
        onChange: this.changeTermsConditions,
        show: this.props.showTermsConditions,
      },
      // Whether subscribing to emails has been accepted.
      subscribeToEmailsField: {
        checked: props.initialSubscribeToEmails,
        onChange: this.changeSubscribeToEmails,
        show: this.props.showSubscribeToEmails,
      },
      loginReCaptchaField: {
        value: null,
        onChange: this.handleLoginReCaptchaField,
      },
      forgotPasswordReCaptchaField: {
        value: null,
        onChange: this.handleForgotPasswordReCaptchaField,
      },
      zipCodeField: {
        value: '',
        onChange: this.handleZipCodeField,
        onBlur: this.handleZipCodeBlur,
        show: this.props.showZipCodeField,
      },
      birthdateField: {
        birthMonth: '',
        birthDay: '',
        birthYear: '',
        validationResult: {},
        onChange: this.handleChangeBirthdate,
        onBlur: this.handleChangeBirthdate,
        onError: this.handleBirthdateError,
        show: this.props.showBirthdateField,
      },
      // Which screen the user is on.
      toggleState: props.initialScreen,
      isLoading: false,
    };
  }

  componentDidMount() {
    const {
      extraSignUpData,
      initialScreen,
      shouldTrackSignUpModalOnMount,
      trackSignUpModal,
      updateSessionDetail,
    } = this.props;

    if (
      extraSignUpData &&
      (initialScreen === SIGN_UP ||
        initialScreen === GUEST_SIGN_UP ||
        (initialScreen === SIGN_IN &&
          extraSignUpData.method === SignUpMethod.NAVBAR))
    ) {
      if (
        shouldTrackSignUpModalOnMount &&
        typeof trackSignUpModal === 'function'
      ) {
        trackSignUpModal(extraSignUpData.method);
      }

      if (extraSignUpData.method === SignUpMethod.SPEEDY) {
        updateSessionDetail({
          name: 'speedy_signup_pop-up',
          value: '',
        });
      }
    }
  }

  showMessage = toastMessage => {
    this.setState({
      toastMessage,
    });
  };

  hideMessage = () => {
    this.setState({
      toastMessage: null,
    });
  };

  renderErrorMessage = error => {
    switch (error) {
      case LoginErrors.LOGIN_FAILED:
        return (
          <FormattedMessage
            defaultMessage="Your email or password is incorrect."
            id="global_checkout.email_password_incorrect"
          />
        );
      case LoginErrors.INVALID_RECAPTCHA:
        return (
          <FormattedMessage
            defaultMessage="The ReCAPTCHA was invalid. Please try again."
            id="global_checkout.login_recaptcha_invalid"
          />
        );
      case LoginErrors.ZIPCODE_REQUIRED:
        return (
          <FormattedMessage
            id="global_checkout.quiz_error_required_zip"
            defaultMessage="Enter a zip code"
          />
        );
      case LoginErrors.ZIPCODE_INVALID:
        return (
          <FormattedMessage
            id="global_checkout.quiz_error_invalid_zip"
            defaultMessage="Enter a valid zip code"
          />
        );
      case LoginErrors.RECAPTCHA_REQUIRED:
        return (
          <FormattedMessage
            defaultMessage="ReCaptcha is required."
            id="global_checkout.login_recaptcha_required"
          />
        );
      case LoginErrors.GENERAL_ERROR:
        return (
          <FormattedMessage
            id="global_checkout.general_error"
            defaultMessage="Oops we’ve encountered an error. Try again"
          />
        );
      case LoginErrors.SERVER_ERROR:
      case LoginErrors.UNKNOWN_ERROR:
        return (
          <FormattedMessage
            id="global_checkout.404_error_message"
            defaultMessage="Something went wrong. We are working to fix it ASAP. Please try again!"
          />
        );
      case LoginErrors.PASSWORDLESS_LOGIN_ATTEMPT:
        return (
          <FormattedMessage
            id="global_checkout.passwordless_login_attempt_request_setup"
            defaultMessage="There is no password associated with this email address. Please {setupPasswordText} a password to sign in."
            values={{
              setupPasswordText: (
                <SetupPasswordButton
                  onClick={() => this.toggleFormState(FORGOT_PASSWORD)}
                >
                  <FormattedMessage
                    id="global_checkout.passwordless_login_attempt_request_setup_link"
                    defaultMessage="Set Up"
                  />
                </SetupPasswordButton>
              ),
            }}
          />
        );
      case LoginErrors.PASSWORDLESS_SIGN_UP_ATTEMPT:
        return (
          <FormattedMessage
            id="global_checkout.passwordless_sign_up_attempt_already_used"
            defaultMessage="This email has already been used to checkout. Please use {forgotPasswordText} to set up your password and sign in."
            values={{
              forgotPasswordText: (
                <SetupPasswordButton
                  onClick={() => this.toggleFormState(FORGOT_PASSWORD)}
                >
                  <FormattedMessage
                    id="global_checkout.passwordless_sign_up_attempt_forgot_password_link"
                    defaultMessage="Forgot Password"
                  />
                </SetupPasswordButton>
              ),
            }}
          />
        );
      default:
        return null;
    }
  };

  handleSignUp = async event => {
    event.preventDefault();
    if (this.requestingSignUp) {
      return;
    }
    this.requestingSignUp = true;

    // Reset all errors, we're about to populate only the relevant ones.
    this.setState(resetState);

    const {
      subscribeToEmailsField,
      firstNameField,
      emailField,
      passwordField,
      referrerField,
      birthdateField,
      zipCodeField,
    } = this.state;
    const {
      showNameField,
      showPasswordField,
      showReferrerField,
      referrerOptions,
      onExistingUserError,
      onFormErrors,
    } = this.props;
    const referrerDetails =
      showReferrerField && referrerOptions.length
        ? { customer_referrer_id: referrerField.value }
        : {};

    const profile = {
      birth_month: birthdateField.birthMonth,
      birth_day: birthdateField.birthDay,
      birth_year: birthdateField.birthYear,
      customer_referrer_id: referrerDetails.customer_referrer_id,
      shipping_zip: zipCodeField.value,
    };

    const nameError = showNameField && !firstNameField.value;

    const errors = {
      nameError,
      emailError: this.checkEmail(),
      passwordError: showPasswordField && this.checkPassword(this.state, true),
      referrerError: this.checkReferrerField(),
      termsConditionsError: this.checkTermsConditions(),
      zipCodeError: this.validateZipCode(),
    };

    let error;
    if (
      errors.nameError ||
      errors.emailError ||
      errors.passwordError ||
      errors.referrerError ||
      errors.termsConditionsError ||
      errors.zipCodeError
    ) {
      this.setState(errors);
      this.requestingSignUp = false;
      onFormErrors && onFormErrors();
    } else {
      this.setState({
        isLoading: true,
      });
      try {
        await this.props.onRequestSignUp({
          firstName: firstNameField.value,
          email: emailField.value,
          password: passwordField.value,
          optOutStatus: subscribeToEmailsField.checked ? `none` : 'basic',
          details: referrerDetails,
          ...this.props.extraSignUpData,
          profile: {
            ...profile,
            ...this.props.extraSignUpData.profile,
            customer_referrer_id: showReferrerField
              ? referrerDetails.customer_referrer_id
              : this.props.extraSignUpData?.profile?.customer_referrer_id,
          },
        });
        this.requestingSignUp = false;
      } catch (err) {
        this.setState({
          isLoading: false,
        });
        error = err;
        this.props.onError(error);
        const responseBody = await err?.originalError?.response?.json();
        const errorMessage = this.renderErrorMessage(LoginErrors.GENERAL_ERROR);
        this.props.trackRegistrationFailure &&
          this.props.trackRegistrationFailure(
            responseBody || {},
            this.props.extraSignUpData?.method
          );
        this.requestingSignUp = false;
        if (responseBody?.statusCode === 400) {
          if (
            responseBody.errorData &&
            responseBody.errorData[0] &&
            responseBody.errorData[0].code === -32606
          ) {
            if (responseBody.errorData[0].data) {
              onExistingUserError();
              this.toggleFormState(SIGN_IN);
              this.setState({
                signUpStatus: { error: ACCOUNT_EXISTS },
              });
            }
          } else {
            this.setState({
              signUpStatus: { error: `${responseBody.message}` },
            });
          }
        }
        this.showMessage(errorMessage);
      }
      if (!error) {
        this.setState({
          signUpStatus: { success: true },
        });
        if (this.props.onSignUp) {
          this.props.onSignUp();
        }
        this.props.onSuccess &&
          this.props.onSuccess({ email: this.state.emailField.value });
        this.props.onCloseSignInModal &&
          this.props.onCloseSignInModal({ signedIn: true });

        if (subscribeToEmailsField.checked) {
          this.props.updateEmailPreferences(
            formatEmailPreferencesPayload('always')
          );
        }
        if (
          this.props.extraSignUpData &&
          this.props.extraSignUpData.quizDetails
        ) {
          this.props.trackIdentifyQuizTraits({
            quizDetails: this.props.extraSignUpData.quizDetails,
            profile: { ...profile, ...this.props.extraSignUpData.profile },
          });
        }

        this.props.trackAccountSegmentEvents();
      }
    }
  };

  handleSignIn = async event => {
    event.preventDefault();
    let statusCode;
    let errorCode;
    // Reset all errors, we're about to populate only the relevant ones.
    this.setState(resetState);

    const errors = {
      emailError: this.checkEmail(),
      passwordError: this.checkPassword(),
      loginReCaptchaError: this.checkLoginReCaptcha(),
    };
    let error;

    if (
      errors.emailError ||
      errors.passwordError ||
      errors.loginReCaptchaError
    ) {
      this.setState(errors);
    } else {
      this.setState({
        isLoading: true,
      });
      try {
        await this.props.onRequestAuthenticate(
          {
            username: this.state.emailField.value,
            password: this.state.passwordField.value,
            reCaptchaResponse: this.state.loginReCaptchaField.value,
          },
          { method: SignUpMethod.SIGN_IN }
        );
      } catch (err) {
        error = err;
        statusCode = err.statusCode;
        let errorMessage = this.renderErrorMessage(LoginErrors.UNKNOWN_ERROR);
        let isPasswordlessLogin;

        if (err.originalError && err.originalError.response) {
          const responseBody = await err.originalError.response.json();
          if (responseBody) {
            if (responseBody.errorCode) {
              errorCode = responseBody.errorCode;
            } else if (responseBody.statusCode) {
              statusCode = responseBody.statusCode;
            }
          }
        }

        if (statusCode >= 400 && statusCode < 500) {
          if (errorCode === 40003) {
            errorMessage = this.renderErrorMessage(
              LoginErrors.RECAPTCHA_REQUIRED
            );
            this.setState({
              signInStatus: {
                error: errorMessage,
              },
            });
          } else if (errorCode === 40004) {
            errorMessage = this.renderErrorMessage(
              LoginErrors.INVALID_RECAPTCHA
            );
            this.setState({
              signInStatus: {
                error: errorMessage,
              },
            });
          } else {
            const { checkIfPasswordlessLogin } = this.props;
            try {
              const passwordlessCheckResponse = await checkIfPasswordlessLogin({
                email: this.state.emailField.value,
              });

              const { isPasswordlessLead } =
                passwordlessCheckResponse?.payload || {};
              isPasswordlessLogin = isPasswordlessLead;

              if (isPasswordlessLead) {
                errorMessage = this.renderErrorMessage(
                  LoginErrors.PASSWORDLESS_LOGIN_ATTEMPT
                );

                this.setState({
                  signInStatus: {
                    error: errorMessage,
                  },
                });
              }
            } finally {
              if (!isPasswordlessLogin) {
                errorMessage = this.renderErrorMessage(
                  LoginErrors.LOGIN_FAILED
                );
                this.setState({
                  signInStatus: {
                    error: errorMessage,
                  },
                });
              }
            }
          }
        } else if (statusCode >= 500) {
          errorMessage = this.renderErrorMessage(LoginErrors.SERVER_ERROR);
          this.setState({
            signInStatus: {
              error: this.renderErrorMessage(LoginErrors.SERVER_ERROR),
            },
          });
        } else {
          this.setState({
            signInStatus: {
              error: errorMessage,
            },
          });
        }
        this.setState({
          isLoading: false,
        });
        if (!isPasswordlessLogin) {
          this.showMessage(errorMessage);
        }
      }
      if (!error) {
        this.props.loadCartItemCount();
        if (this.props.onSignIn) {
          this.props.onSignIn();
        }
        this.props.onSuccess &&
          this.props.onSuccess({ email: this.state.emailField.value });
        this.props.onCloseSignInModal &&
          this.props.onCloseSignInModal({ signedIn: true });

        this.props.trackAccountSegmentEvents();
      }
    }
  };

  handleResetPassword = async event => {
    event.preventDefault();

    const errors = {
      passwordError: this.checkPassword(),
      passwordVerifyError: this.checkVerifyPassword(),
    };
    let error;

    const { passwordResetKey } = this.props;
    const { passwordField, passwordVerifyField } = this.state;

    if (errors.passwordError || errors.passwordVerifyError) {
      this.setState(errors);
    } else {
      try {
        await this.props.onRequestUpdatePassword({
          prkey: passwordResetKey,
          password: passwordField.value,
          confirmPassword: passwordVerifyField.value,
        });
      } catch (err) {
        error = err;
        this.showMessage(err.message);
        this.setState({ resetPasswordStatus: { error: err } });
      }
      if (!error) {
        this.setState({ resetPasswordStatus: { success: true } }, () => {
          this.props.onResetSuccess && this.props.onResetSuccess();
        });
      }
    }
  };

  updateForgotPasswordStatus = error => {
    if (!error) {
      this.setState({ forgotPasswordStatus: { success: true } });
    } else {
      const errorMessage = this.renderErrorMessage(LoginErrors.GENERAL_ERROR);
      this.showMessage(errorMessage);
      this.setState({ forgotPasswordStatus: { error: true } });
    }
  };

  handleForgotPassword = async event => {
    event.preventDefault();

    // Reset all errors, we're about to populate only the relevant ones.
    this.setState(resetState);

    const errors = {
      emailError: this.checkEmail(),
      forgotPasswordReCaptchaError: this.checkForgotPasswordReCaptcha(),
    };
    let error;

    if (errors.emailError || errors.forgotPasswordReCaptchaError) {
      this.setState(errors);
    } else {
      try {
        await this.props.onRequestResetPassword({
          email: this.state.emailField.value,
          reCaptchaResponse: this.state.forgotPasswordReCaptchaField.value,
        });
      } catch (err) {
        error = err;
        const errorMessage = this.renderErrorMessage(LoginErrors.GENERAL_ERROR);
        this.showMessage(errorMessage);
        this.setState({ forgotPasswordStatus: { error: true } });
      }
      if (!error) {
        this.setState({ forgotPasswordStatus: { success: true } });
        this.props.setToggledState('PASSWORD_RESET');
      }
    }
  };

  handleBackToResetForm = () => {
    this.setState({
      resetPasswordStatus: {},
    });
  };

  handleChangeBirthdate = field => event => {
    const { value } = event.target;
    this.setState(prevState => ({
      birthdateField: {
        ...prevState.birthdateField,
        [field]: value,
      },
    }));
  };

  handleBirthdateError = field => event => {
    const { birthdateField } = this.state;
    const validationResult = {
      ...birthdateField.validationResult,
      [field]: event,
    };

    this.setState(prevState => ({
      birthdateField: {
        ...prevState.birthdateField,
        validationResult,
      },
    }));
  };

  changeReferrer = event => {
    const { value } = event.target;
    this.setState(prevState => ({
      referrerField: {
        ...prevState.referrerField,
        value,
      },
    }));

    if (this.state.referrerError) {
      this.setState({
        referrerError: null,
      });
    }
  };

  changeFirstName = event => {
    const { value } = event.target;
    this.setState(prevState => ({
      firstNameField: {
        ...prevState.firstNameField,
        value,
      },
    }));

    if (this.state.nameError) {
      this.setState({
        nameError: null,
      });
    }
  };

  changeEmail = value => {
    const nextState = {
      emailField: {
        ...this.state.emailField,
        value,
      },
    };
    this.setState(nextState);
    // If there's an error, we want it to go away if the input validates now.
    if (this.state.emailError) {
      const error = this.checkEmail(nextState);
      this.setState({ emailError: error });
    }
  };

  changePassword = event => {
    const { value } = event.target;
    const nextState = {
      passwordField: {
        ...this.state.passwordField,
        value,
      },
    };
    this.setState(nextState, () => {
      if (this.state.toggleState === RESET_PASSWORD) {
        const error = this.checkVerifyPassword(undefined, true);
        this.setState({ passwordVerifyError: error });
      }
    });
    // If there's an error, we want it to go away if the input validates now.
    if (this.state.passwordError) {
      const error = this.checkPassword(nextState);
      this.setState({ passwordError: error });
    }
  };

  changeVerifyPassword = event => {
    const { value } = event.target;
    const nextState = {
      passwordVerifyField: {
        ...this.state.passwordVerifyField,
        value,
      },
    };
    this.setState(nextState);

    // If there's an error, we want it to go away if the input validates now.
    if (this.state.passwordVerifyError) {
      const error = this.checkVerifyPassword(nextState);
      this.setState({ passwordVerifyError: error });
    }
  };

  handleLoginReCaptchaField = event => {
    const value =
      isTestSecretRecaptchaNeeded && !(typeof event === 'string')
        ? event.target.value
        : event;
    const nextState = {
      loginReCaptchaField: {
        ...this.state.loginReCaptchaField,
        value,
      },
    };
    this.setState(nextState);

    if (this.state.loginReCaptchaError) {
      const loginReCaptchaError = this.checkLoginReCaptcha(nextState);
      this.setState({ loginReCaptchaError });
    }
  };

  handleZipCodeField = event => {
    const { value } = event.target;
    this.setState(prevState => ({
      zipCodeField: {
        ...prevState.zipCodeField,
        value,
      },
      zipCodeError: null,
    }));
  };

  handleZipCodeBlur = () => {
    const zipCodeError = this.validateZipCode();
    this.setState({ zipCodeError });
  };

  handleForgotPasswordReCaptchaField = event => {
    const value =
      isTestSecretRecaptchaNeeded && !(typeof event === 'string')
        ? event.target.value
        : event;
    const nextState = {
      forgotPasswordReCaptchaField: {
        ...this.state.forgotPasswordReCaptchaField,
        ...this.state.forgotPasswordReCaptchaError,
        value,
      },
    };
    this.setState(nextState);

    if (this.state.forgotPasswordReCaptchaError) {
      const forgotPasswordReCaptchaError =
        this.checkForgotPasswordReCaptcha(nextState);
      this.setState({ forgotPasswordReCaptchaError });
    }
  };

  changeTermsConditions = checked => {
    const nextState = {
      termsConditionsField: {
        ...this.state.termsConditionsField,
        checked,
      },
    };

    this.setState(nextState);
    // If there's an error, we want it to go away if the input validates now.
    if (this.state.termsConditionsError) {
      const error = this.checkTermsConditions(nextState);
      this.setState({ termsConditionsError: error });
    }
  };

  changeSubscribeToEmails = checked => {
    this.setState(prevState => ({
      subscribeToEmailsField: {
        ...prevState.subscribeToEmailsField,
        checked,
      },
    }));
  };

  checkEmail = (state = this.state) => {
    const {
      emailField: { value },
    } = state;
    if (!validateEmail(value)) {
      return INVALID_EMAIL;
    }
    return value ? null : EMAIL_REQUIRED;
  };

  setEmailError = emailError => {
    switch (emailError) {
      case EMAIL_REQUIRED:
      case INVALID_EMAIL:
      case ACCOUNT_EXISTS:
      case AUTH_FAILED:
      case GENERAL_ERROR:
        this.setState({ emailError });
        break;
      default:
        break;
    }
  };

  checkReferrerField = (state = this.state) => {
    const { showReferrerField, referrerOptions } = this.props;
    return state.referrerField.value ||
      !showReferrerField ||
      !referrerOptions.length
      ? null
      : REFERRER_REQUIRED;
  };

  checkPassword = (state = this.state, isPasswordLengthRestricted) => {
    if (!state.passwordField.value) {
      return PASSWORD_REQUIRED;
    } else if (
      isPasswordLengthRestricted &&
      state.passwordField.value?.length < 6
    ) {
      return PASSWORD_INVALID;
    } else {
      return null;
    }
  };

  checkVerifyPassword = (state = this.state, onlyIfModified) => {
    if (
      onlyIfModified &&
      !state.passwordVerifyField.value &&
      !state.passwordVerifyError
    ) {
      return null;
    }
    if (!state.passwordVerifyField.value) {
      return PASSWORD_VERIFY_REQUIRED;
    }
    if (state.passwordVerifyField.value !== state.passwordField.value) {
      return PASSWORD_VERIFY_MATCH;
    }
    return null;
  };

  checkTermsConditions = (state = this.state) => {
    const { showTermsConditions, shouldShowPrivacyNotice } = this.props;
    if (
      showTermsConditions &&
      !state.termsConditionsField.checked &&
      !shouldShowPrivacyNotice
    ) {
      return TERMS_CONDITIONS_REQUIRED;
    }
    return null;
  };

  validateZipCode = () => {
    const { zipCodeField } = this.state;
    const countryCode = defaultCountries[this.props.tld];
    const { isValid } = validateZip({
      zip: zipCodeField.value,
      countryCode,
    });

    if (!zipCodeField.show) {
      return null;
    }

    if (!zipCodeField.value) {
      return LoginErrors.ZIPCODE_REQUIRED;
    }

    if (!isValid) {
      return LoginErrors.ZIPCODE_INVALID;
    }

    return null;
  };

  checkLoginReCaptcha = (state = this.state) => {
    const { cookies, isAdmin } = this.props;
    const disableRecaptcha =
      !isRecaptchaEnabled ||
      (!!cookies.get('automated_test') && !isTestSecretRecaptchaNeeded);

    if (isAdmin || disableRecaptcha) {
      return null;
    }

    return state.loginReCaptchaField.value
      ? null
      : LoginErrors.RECAPTCHA_REQUIRED;
  };

  checkForgotPasswordReCaptcha = (state = this.state) => {
    const { cookies, isAdmin } = this.props;
    const disableRecaptcha =
      !isRecaptchaEnabled ||
      (!!cookies.get('automated_test') && !isTestSecretRecaptchaNeeded);

    if (isAdmin || disableRecaptcha) {
      return null;
    }

    return state.forgotPasswordReCaptchaField.value
      ? null
      : LoginErrors.RECAPTCHA_REQUIRED;
  };

  toggleFormState = (toggleState, event) => {
    if (event) {
      event.preventDefault();
    }

    const { extraSignUpData, trackSignUpModal } = this.props;

    if (extraSignUpData && toggleState === SIGN_UP) {
      trackSignUpModal(extraSignUpData?.method);
    }

    // Used to Keep up with state strictly for Speedy Signup and Product Quiz Signup
    this.props.setToggledState(toggleState);

    this.setState({
      ...resetState,
      toggleState,
    });
  };

  openPrivacyPolicy = event => {
    event.preventDefault();
    const win = window.open('/privacypolicy', '_blank');
    win.focus();
  };

  openTermsAndConditions = event => {
    event.preventDefault();
    const win = window.open('/termsandconditions', '_blank');
    win.focus();
  };

  closePrivacyPolicy = () => {
    this.setState({ privacyPolicyOpen: false });
  };

  handleCheckoutSignUp = async event => {
    event.preventDefault();
    if (this.requestingSignUp) {
      return;
    }
    this.requestingSignUp = true;

    // Reset all errors, we're about to populate only the relevant ones.
    this.setState(resetState);

    const {
      emailField,
      passwordField,
      subscribeToEmailsField,
      displayGuestSignUpPassword,
      toggleState,
    } = this.state;
    const { onExistingUserError, onFormErrors } = this.props;

    const hasEnteredPassword =
      displayGuestSignUpPassword && Boolean(passwordField.value);
    const passwordRequired =
      toggleState === CHECKOUT_SIGN_UP ? true : hasEnteredPassword;

    const errors = {
      emailError: this.checkEmail(),
      passwordError: passwordRequired
        ? this.checkPassword(this.state, true)
        : null,
    };

    let error;
    if (errors.emailError || errors.passwordError) {
      this.setState(errors);
      this.requestingSignUp = false;
      onFormErrors && onFormErrors();
    } else {
      this.setState({
        isLoading: true,
      });
      try {
        const additionalData = passwordRequired
          ? { password: passwordField.value }
          : {};

        await this.props.onRequestSignUp({
          email: emailField.value,
          optOutStatus: subscribeToEmailsField.checked ? 'none' : 'basic',
          ...additionalData,
          ...this.props.extraSignUpData,
        });
        this.requestingSignUp = false;
      } catch (err) {
        this.setState({
          isLoading: false,
        });
        error = err;
        this.props.onError(error);
        const responseBody = await err?.originalError?.response?.json();
        const errorMessage = this.renderErrorMessage(LoginErrors.GENERAL_ERROR);
        this.props.trackRegistrationFailure &&
          this.props.trackRegistrationFailure(
            responseBody || {},
            this.props.extraSignUpData?.method
          );
        this.requestingSignUp = false;
        if (responseBody?.statusCode === 400) {
          if (
            responseBody.errorData &&
            responseBody.errorData[0] &&
            responseBody.errorData[0].code === -32606
          ) {
            if (responseBody.errorData[0].data) {
              const { checkIfPasswordlessLogin } = this.props;
              let isPasswordlessLogin;

              try {
                const passwordlessCheckResponse =
                  await checkIfPasswordlessLogin({
                    email: this.state.emailField.value,
                  });

                const { isPasswordlessLead } =
                  passwordlessCheckResponse?.payload || {};
                isPasswordlessLogin = isPasswordlessLead;
                if (isPasswordlessLead) {
                  const errorMessage = this.renderErrorMessage(
                    LoginErrors.PASSWORDLESS_SIGN_UP_ATTEMPT
                  );

                  this.setState({
                    signInStatus: {
                      error: errorMessage,
                    },
                  });
                }
              } finally {
                if (!isPasswordlessLogin) {
                  onExistingUserError();
                  this.toggleFormState(GUEST_SIGN_IN);
                  this.setState({
                    signUpStatus: { error: ACCOUNT_EXISTS_GUEST },
                  });

                  this.showMessage(
                    <FormattedMessage
                      id="global_quiz.guest_signup_form_existing_error_message"
                      defaultMessage="Looks like you already have an account. Please sign in."
                    />
                  );
                }
              }
            }
          } else {
            this.setState({
              signUpStatus: { error: `${responseBody.message}` },
            });

            this.showMessage(errorMessage);
          }
        }
      }
      if (!error) {
        this.setState({
          signUpStatus: { success: true },
        });
        if (this.props.onSignUp) {
          this.props.onSignUp();
        }
        this.props.onSuccess &&
          this.props.onSuccess({ email: this.state.emailField.value });
        this.props.onCloseSignInModal &&
          this.props.onCloseSignInModal({ signedIn: true });

        if (subscribeToEmailsField.checked) {
          this.props.updateEmailPreferences(
            formatEmailPreferencesPayload('always')
          );
        }

        this.props.trackAccountSegmentEvents();
      }
    }
  };

  toggleDisplayGuestSignUpPassword = () => {
    this.setState(prevState => ({
      displayGuestSignUpPassword: !prevState.displayGuestSignUpPassword,
    }));
  };

  render() {
    const {
      isLoading,
      toastMessage,
      signUpStatus,
      signInStatus,
      toggleState,
      forgotPasswordStatus,
      resetPasswordStatus,
      subscribeToEmailsField,
    } = this.state;

    const {
      showReferrerField,
      referrerOptions,
      shouldShowPrivacyNotice,
      showBottomTermsConditionsLink,
      showBottomPrivacyLink,
      showSignUpFields,
      tld,
      initialScreen,
      shouldFitSpace,
      isCheckoutSignUp,
    } = this.props;

    const firstNameField = {
      ...this.state.firstNameField,
      error: this.state.nameError,
    };

    const emailField = {
      ...this.state.emailField,
      error: this.state.emailError,
    };

    const passwordField = {
      ...this.state.passwordField,
      error: this.state.passwordError,
    };

    const passwordVerifyField = {
      ...this.state.passwordVerifyField,
      error: this.state.passwordVerifyError,
    };

    const referrerField = {
      ...this.state.referrerField,
      error: this.state.referrerError,
    };

    const loginReCaptchaField = {
      ...this.state.loginReCaptchaField,
      error: this.state.loginReCaptchaError,
    };

    const forgotPasswordReCaptchaField = {
      ...this.state.forgotPasswordReCaptchaField,
      error: this.state.forgotPasswordReCaptchaError,
    };

    const zipCodeField = {
      ...this.state.zipCodeField,
      error: this.renderErrorMessage(this.state.zipCodeError),
    };

    const birthdateField = {
      ...this.state.birthdateField,
      showBirthYear: tld === '.com',
    };

    const termsAndConditionsField = {
      ...this.state.termsConditionsField,
      error: this.state.termsConditionsError,
      openTermsAndConditions: this.openTermsAndConditions,
      openPrivacyPolicy: this.openPrivacyPolicy,
    };

    const signInScreen = SIGN_IN_SCREEN_MAP[initialScreen] || SIGN_IN;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <SignInContent
        {...this.props}
        ref={this.props.formRef}
        checkEmail={this.checkEmail}
        checkPassword={this.checkPassword}
        emailField={emailField}
        firstNameField={firstNameField}
        referrerField={referrerField}
        forgotPasswordStatus={forgotPasswordStatus}
        handleBackToResetForm={this.handleBackToResetForm}
        handleForgotPassword={this.handleForgotPassword}
        handleResetPassword={this.handleResetPassword}
        handleSignIn={this.handleSignIn}
        handleSignUp={this.handleSignUp}
        hideMessage={this.hideMessage}
        onSignInClick={this.props.onSignInClick}
        passwordField={passwordField}
        passwordVerifyField={passwordVerifyField}
        resetPasswordStatus={resetPasswordStatus}
        setEmailError={this.setEmailError}
        signInStatus={signInStatus}
        signInScreen={signInScreen}
        signUpStatus={signUpStatus}
        shouldFitSpace={shouldFitSpace}
        subscribeToEmailsField={showSignUpFields ? subscribeToEmailsField : {}}
        termsAndConditionsField={
          showSignUpFields ? termsAndConditionsField : {}
        }
        toastMessage={toastMessage}
        toggleFormState={this.toggleFormState}
        toggleState={toggleState}
        showReferrerField={showReferrerField}
        referrerOptions={referrerOptions}
        shouldShowPrivacyNotice={showSignUpFields && shouldShowPrivacyNotice}
        showBottomPrivacyLink={showBottomPrivacyLink}
        showBottomTermsConditionsLink={showBottomTermsConditionsLink}
        loginReCaptchaField={loginReCaptchaField}
        forgotPasswordReCaptchaField={forgotPasswordReCaptchaField}
        zipCodeField={zipCodeField}
        birthdateField={birthdateField}
        isSpeedy={Boolean(this.props.extraSignUpData?.method === 'SPEEDY')}
        updateForgotPasswordStatus={this.updateForgotPasswordStatus}
        handleCheckoutSignUp={this.handleCheckoutSignUp}
        toggleDisplayGuestSignUpPassword={this.toggleDisplayGuestSignUpPassword}
        displayGuestSignUpPassword={this.state.displayGuestSignUpPassword}
        isCheckoutSignUp={isCheckoutSignUp}
      />
    );
  }
}

export default withCookies(SignIn);
