import { TRACK_PROMOTION_CLICK } from '../../actions/tracking';
import { getPartialTrackingData } from '../utils';

/**
 * Promotion asset is clicked.
 */
const trackPromotionClickEvents = ({ getContext, trackEvent }) => {
  const trackPromotionClick = trackEvent((action, prevState, nextState) => {
    const context = getContext();
    const { clickableUrl, creative, customVars, imageId, name } =
      action.payload;

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
      'membership_credits',
    ]);

    return {
      name: 'Promotion Clicked',
      properties: {
        ...additionalTrackingData,
        clickable_url: clickableUrl || '', // The URL that someone goes to when they click
        creative: creative || '', // assets.$.label
        custom_vars: customVars || {}, // Custom properties and values attached to the asset (ONLY PASS VALUES THAT HAVE BEEN POPULATED - NO EMPTY CUSTOM VARS)
        image_id: imageId || '', // assets.$.imageFilename
        name: name || '', // container.label
      },
    };
  });

  return { [TRACK_PROMOTION_CLICK]: trackPromotionClick };
};

export default trackPromotionClickEvents;
