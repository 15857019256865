import { useEffect, useMemo } from 'react';

import { useTheme } from 'styled-components';

import { useBreakpoint } from '@techstyle/react-components';

import {
  useIntersectionObserver,
  useMeasure,
  useMutationObserver,
} from './use-hooks';

const intersectionObserverOptions: IntersectionObserverInit = {
  threshold: 0.01,
};

const mutationOptions: MutationObserverInit = {
  attributes: true,
  childList: false,
  characterData: false,
  attributeFilter: ['class'],
};

/**
 * A hook to get the current status of the Header/Navbar including its height and
 * whether it has the `sticky-hide-top` or `sticky-auto-hide` class.
 */
export const useHeaderStatus = () => {
  const { isMobile } = useBreakpoint();
  const [setMeasureRef, dimensions] = useMeasure();
  const { themeVersion } = useTheme();
  const isNewTheme = themeVersion === 'v2';

  const [setIntersectionRef, entry] = useIntersectionObserver(
    intersectionObserverOptions
  );
  const [setMutationRef, mutationEntry] = useMutationObserver(mutationOptions);

  useEffect(() => {
    const navBarElement = document.querySelector('#navbar');

    if (navBarElement) {
      setIntersectionRef(navBarElement);

      const stickyWrapperElement = navBarElement.closest(
        '.sticky-outer-wrapper'
      );
      if (stickyWrapperElement) {
        // Sticky container also holds skinny banner so if it is present we consider its height
        setMeasureRef(stickyWrapperElement);
        setMutationRef(stickyWrapperElement);
      } else {
        setMeasureRef(navBarElement);
      }
    }
    return () => {
      setMeasureRef(null);
      setIntersectionRef(null);
      setMutationRef(null);
    };
  }, [setMeasureRef, setIntersectionRef, setMutationRef, isMobile, isNewTheme]);

  const hasHideTop = useMemo(() => {
    if (!isMobile) {
      return false;
    }

    if (mutationEntry?.target) {
      // @ts-ignore -- This should be an Element when since we're using "attributes"
      return mutationEntry.target.classList.contains('sticky-hide-top');
    }

    return false;
  }, [isMobile, mutationEntry]);

  const hasAutoHide = useMemo(() => {
    if (!isMobile) {
      return false;
    }

    if (mutationEntry?.target) {
      // @ts-ignore -- This should be an Element when since we're using "attributes"
      return mutationEntry.target.classList.contains('sticky-auto-hide');
    }

    return false;
  }, [isMobile, mutationEntry]);

  const headerHeight = useMemo(() => {
    let headerHeight = dimensions?.height || 0;

    if (isMobile) {
      if (entry && !entry.isIntersecting) {
        headerHeight = 0;
      }

      if (hasAutoHide) {
        headerHeight = 0;
      }

      if (headerHeight > 0) {
        if (hasHideTop) {
          headerHeight = headerHeight - 40;
        }
      }
    }

    return headerHeight;
  }, [dimensions, entry, hasAutoHide, hasHideTop, isMobile]);

  return {
    /**
     * The current height of the header.
     */
    headerHeight,
    /**
     * Whether the header has the `sticky-auto-hide` class, this is active when the header is
     * being hidden when the user scrolls down. This is useful to know since a transition-delay
     * is added to the header when this class is active.
     */
    hasAutoHide,
    /**
     * Whether the header has the `sticky-hide-top` class, this is active when a top portion
     * of the header is hidden when the user scrolls down.
     */
    hasHideTop,
  };
};
