import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ImageAsset, useAsset } from '@techstyle/react-assets';
import { useSession } from '@techstyle/redux-core';

import { Link } from '../../../routes';
import { loadProduct, loadProductSet } from '../../actions/products';
import { desktop, mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';
import generateCdnImageUrl from '../../utils/generateCdnImageUrl';
import { isXtraVipBox } from '../../utils/selectors';
import { Component as CloseButton } from '../CloseButton';
import { Component as ProductInfoRegistrationModal } from '../ProductInfoRegistrationModal';

const StyledCloseButton = styled(CloseButton)`
  color: ${props => props.theme.colors.subdued};
  height: 45px;
  width: 45px;
  line-height: 45px;

  & > svg {
    width: 12px;
    height: 12px;
  }
`;

const dialogStyle = css`
  @media (max-width: ${props => props.theme.breakpoints.medium}px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: scroll;
  }
`;

const overlayStyle = css`
  @media (max-width: ${props => props.theme.breakpoints.medium}px) {
    background-color: transparent;
  }
`;

const StyledModal = styled(BasicModal)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px ${props => props.theme.spacing.moderate}px;
  height: 100%;

  ${desktop`
    flex-direction: row;
    height: 500px;
    padding: 0;
    width: 900px;
    max-width: 100%;
  `};
`;

const AltWrapper = styled(Wrapper)`
  padding: 0;
  ${desktop`height: 550px; width: 950px;`}
`;

const StyledImageAssetContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;

  ${desktop`
    flex: 0 0 50%;
    height: 100%;
    min-height: 155px;
  `};
`;

const AltStyledImageAssetContainer = styled(StyledImageAssetContainer)`
  min-height: 200px;
`;

const StyledImageAsset = styled(ImageAsset)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.small}px;
  width: 100%;

  ${desktop`
    height: 100%;
  `}
`;

const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url('${props => props.imgSrc}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${props => props.theme.colors.gray150};

  ${desktop`
    height: 100%;
  `}

  ${mobile`
    height: 180px;
    width: 100%;
  `}
`;

const AltStyledImageContainer = styled(StyledImageAssetContainer)`
  min-height: 180px;
`;

const AltStyledImage = styled(ImageAsset)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.colors.gray150};
  height: 100%;

  ${mobile`
    height: 200px;
    width: 100%;
  `}
`;

const RightContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.medium}px
    ${props => props.theme.spacing.small}px;

  ${desktop`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.small}px 28px;
  `};
`;

const StyledRightContainer = styled(RightContainer)`
  padding: ${props => props.theme.spacing.small}px
    ${props => props.theme.spacing.large}px;
`;

const ImageAssetLink = styled.a`
  flex: 0 0 100%;
`;

const RegistrationModal = ({
  assetContainer,
  bodyOpenClassName,
  children,
  className,
  isDefaultCloseFunctionalityDisabled = false,
  isAltRegistration = false,
  isOpen,
  onRequestClose,
  registrationModalDialogStyle = null,
  shouldShowLaydownImage = false,
  shouldShowProductInfo = false,
  springConfig,
  trackRegistrationSignUp,
  transitionEnter,
  transitionFrom,
  transitionLeave,
}) => {
  let assetRegistration = null;
  let assetComponent = null;
  let targetUrl = null;
  const { dmGatewayCode } = useSession();
  const assetContainerRegistration = useAsset(assetContainer, { multi: true });
  const { productId, isProductSet } = useSelector(state => state.auth);
  const [laydownImage, setLaydownImage] = useState('');
  const dispatch = useDispatch();
  const WrapperComponent = isAltRegistration ? AltWrapper : Wrapper;
  const ImageAssetContainer = isAltRegistration
    ? AltStyledImageAssetContainer
    : StyledImageAssetContainer;
  const RightContainerComponent = isAltRegistration
    ? StyledRightContainer
    : RightContainer;

  useEffect(() => {
    if (!productId) {
      return;
    }

    const fetchProduct = async () => {
      let product;
      let angle = 'laydown';
      if (!isProductSet) {
        const { payload } = await dispatch(loadProduct(productId));
        product = payload;
      } else {
        const { payload } = await dispatch(loadProductSet(productId));
        product = payload;
        angle = isXtraVipBox({}, { product }) ? 'laydown' : '1';
      }

      setLaydownImage(
        generateCdnImageUrl({
          product,
          angle,
          imageSize: 8,
        })
      );
    };

    fetchProduct();
  }, [dispatch, isProductSet, productId]);

  if (assetContainer) {
    const { data } = assetContainerRegistration;
    const assets = data?.assets || [];
    let testId = null;

    if (dmGatewayCode) {
      assetRegistration = assets.find(
        asset =>
          asset.options.customVars.dmgCode === dmGatewayCode ||
          !asset.options.customVars.dmgCode
      );
      testId = 'asset-for-users-with-dmg-code';
    } else {
      assetRegistration = assets.find(
        asset => !asset.options.customVars.dmgCode
      );
      testId = 'asset-for-regular-users';
    }

    if (assetRegistration) {
      targetUrl = assetRegistration.options.targetUrl;
      assetComponent = isAltRegistration ? (
        <AltStyledImage
          background
          asset={assetRegistration}
          data-testid={testId}
        />
      ) : (
        <StyledImageAsset
          background
          onClick={() => trackRegistrationSignUp('Promo Assets')}
          asset={assetRegistration}
          data-testid={testId}
        />
      );
    }
  }
  const dialogStyles = [dialogStyle, registrationModalDialogStyle];

  const closeButtonComponent = () => {
    if (shouldShowLaydownImage) {
      return <StyledCloseButton data-autotag="loggedout-popup-close-icon" />;
    }
    if (isDefaultCloseFunctionalityDisabled) {
      return null;
    }
    return <CloseButton data-autotag="loggedout-popup-close-icon" />;
  };

  return (
    <StyledModal
      bodyOpenClassName={bodyOpenClassName}
      className={className}
      closeButton={closeButtonComponent()}
      dialogStyle={dialogStyles}
      isOpen={isOpen}
      onExit={onRequestClose}
      overlayStyle={overlayStyle}
      springConfig={springConfig}
      titleId="registration-modal"
      transitionEnter={transitionEnter}
      transitionFrom={transitionFrom}
      transitionLeave={transitionLeave}
      overlayClickExits={!isDefaultCloseFunctionalityDisabled}
    >
      <WrapperComponent data-autotag="loggedout-popup" id="registration-modal">
        {assetRegistration ? (
          <ImageAssetContainer data-autotag="alt-reg-flow-image-asset">
            {targetUrl ? (
              <Link route={targetUrl} passHref>
                <ImageAssetLink data-autotag="loggedout-popup-signup-link">
                  {assetComponent}
                </ImageAssetLink>
              </Link>
            ) : (
              assetComponent
            )}
          </ImageAssetContainer>
        ) : null}

        {shouldShowLaydownImage ? (
          <AltStyledImageContainer data-autotag="alt-reg-flow-image-asset">
            <StyledImage imgSrc={laydownImage} />
          </AltStyledImageContainer>
        ) : null}
        {shouldShowProductInfo ? <ProductInfoRegistrationModal /> : null}
        <RightContainerComponent>{children}</RightContainerComponent>
      </WrapperComponent>
    </StyledModal>
  );
};

RegistrationModal.propTypes = {
  assetContainer: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isDefaultCloseFunctionalityDisabled: PropTypes.bool,
  isAltRegistration: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  registrationModalDialogStyle: PropTypes.array,
  shouldShowLaydownImage: PropTypes.bool,
  shouldShowProductInfo: PropTypes.bool,
  springConfig: PropTypes.object,
  trackRegistrationSignUp: PropTypes.func,
  transitionEnter: PropTypes.object,
  transitionFrom: PropTypes.object,
  transitionLeave: PropTypes.object,
};

RegistrationModal.CloseButton = CloseButton;
RegistrationModal.RightContainer = RightContainer;
RegistrationModal.StyledImageAsset = StyledImageAsset;
RegistrationModal.StyledImageAssetContainer = StyledImageAssetContainer;
RegistrationModal.Wrapper = Wrapper;

export default RegistrationModal;
