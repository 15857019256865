export function formatProfileData(profile) {
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email,
    profile: {
      'birth-year': profile.birthYear,
      'birth-month': profile.birthMonth,
      'birth-day': profile.birthDay,
      'bra-size': profile.braSize,
      'bralette-size': profile.braletteSize,
      'bottom-size': profile.undieSize,
      'lingerie-sleep-size': profile.lingerieSleepSize,
    },
  };
}
