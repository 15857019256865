import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { Script } from '@techstyle/react-marketing';

import { AfterpayStyles } from '../../styles/afterpay';

export default function ClearpaySnippet({ onReady }) {
  const clearpayScript = config.get('public.clearpay.script');
  return (
    <>
      <AfterpayStyles />
      <Script
        skipServerRender
        src="https://static-us.afterpay.com/javascript/present-clearpay.js"
        globalScriptKey="clearpay-widget"
        onReady={onReady}
      />
      <Script src={clearpayScript} />
    </>
  );
}

ClearpaySnippet.propTypes = {
  onReady: PropTypes.func.isRequired,
};
