import { getMembership, loadMembership } from '@techstyle/react-accounts';
import {
  ProductType,
  getTrackProductDetails,
  trackProductClicked,
} from '@techstyle/react-products';

import { getPartialTrackingData } from '../utils';

const productClickedBmigEvents = ({
  createEvent,
  dispatch,
  getContext,
  getState,
}) => {
  const productClickedBmig = async (action, prevState, nextState) => {
    await dispatch(loadMembership());
    const context = getContext();

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'availableTokenQuantity',
      'dmg_code',
      'membership_credits',
    ]);
    const { isVip } = getMembership(getState());
    const productInfo = { ...action.payload, isVip };
    const { product } = productInfo;
    const isBundle = product.productTypeId === ProductType.BUNDLE;
    const productsData = isBundle
      ? product.componentProductIdObjectList.map((componentItem, index) =>
          getTrackProductDetails({
            ...productInfo,
            currentComponentIndex: index,
          })
        )
      : [getTrackProductDetails(productInfo)];

    return productsData.map(productData =>
      createEvent({
        name: 'Product Clicked',
        properties: {
          ...additionalTrackingData,
          fpl_id: productInfo.fplId || null,
          list: productInfo.productSource || null,
          ...productData,
        },
      })
    );
  };

  return {
    [trackProductClicked]: productClickedBmig,
  };
};

export default productClickedBmigEvents;
