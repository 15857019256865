import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { loadProduct } from '../../actions/products';
import { desktop } from '../../styles';
import { Paragraph } from '../../styles/paragraph';
import { getGwpPromo } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as BasketItemSize } from '../BasketItemSize';

const ItemDetails = styled.div`
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph4Uppercase.textStyles}
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.tiny / 2}px;
    padding-top: ${props => props.theme.spacing.tiny / 2}px;
  `}
`;

const ItemData = styled(Paragraph).attrs({ size: 12 })`
  font-weight: normal;
  ${props =>
    props.theme.context.cartDrawer &&
    props.hideInDrawer &&
    `
    display: none;
  `};
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

const BasketPricePromo = styled(Paragraph)`
  flex: 1 1 auto;
  ${props =>
    props.theme.context.cartDrawer &&
    props.hideInDrawer &&
    `
    display: none;
  `};
  ${v1`
    color: ${props =>
      props.isClearanceItem
        ? props.theme.colors.error
        : props.theme.colors.promo};
  `}
  ${v2`
    color: ${props =>
      props.isClearanceItem
        ? props.theme.colors.error
        : props.theme.colors.lavender700};
  `}
  font-size: 12px;
  ${desktop`
    font-weight: 500;
  `}
  ${v2`
    ${props => props.theme.subHeader.variants.subHeading4Uppercase.textStyles}
  `}
`;

const CustomTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: ${props => props.theme.spacing.small}px;
`;

const CustomTextTitle = styled.div``;

const CustomTextContent = styled.div``;

const IneligibleForTokenRedemptionMessage = styled.div`
  font-size: 12px;
  font-weight: 375;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.green500};
  padding-top: 2px;
  padding-bottom: 4px;
`;

const BundleProductName = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
`;

export default function BasketItemDetails({
  autoBundleEnabled,
  className,
  checkoutMaterialsEnabled,
  discounts,
  extraDetail,
  hideColor,
  hideMaterials,
  hideDetails,
  isByoSet,
  isClearanceItem,
  isExchange,
  isHeader,
  product,
  renderDetails,
  shouldShowTokenIneligibleMessage = false,
  ...props
}) {
  const gwpPromo = useSelector(getGwpPromo);
  const dispatch = useDispatch();
  const hasPromo = discounts && discounts.length > 0;
  const promoLabel = hasPromo
    ? discounts[0].promoLabel || discounts[0].label
    : null;
  const isGwpProduct =
    product.discount && product.discount.promoCode === gwpPromo?.promoCode;

  const { masterProductId } = product;
  const getProductDetails = useCallback(
    masterProductId => () => {
      return dispatch(loadProduct(masterProductId));
    },
    [dispatch]
  );

  const { data: productDetailData } = useQuery({
    enabled: !!checkoutMaterialsEnabled,
    queryKey: ['loadProduct', masterProductId],
    queryFn: getProductDetails(masterProductId),
  });

  const productMaterials = useMemo(() => {
    if (
      Array.isArray(productDetailData?.payload?.product_detail_id_object_list)
    ) {
      const productMaterialsObject =
        productDetailData.payload.product_detail_id_object_list.find(
          item => item.label === 'Fabric_content'
        );
      return productMaterialsObject?.description;
    } else {
      return undefined;
    }
  }, [productDetailData]);

  const renderColor = product => {
    if (hideColor) {
      return null;
    }

    // FIXME Temporary fix for order confirmation
    const colorMatch = product.alias && product.alias.match(/\(([^)]+)\)/);
    const color = product.color || (colorMatch && colorMatch[1]);
    return (
      <ItemData hideInDrawer>
        <FormattedMessage
          id="global_checkout.color_label"
          defaultMessage="Color"
        />
        {': '}
        {color}
      </ItemData>
    );
  };

  const renderCustomText = product => {
    const customText = product.customText || product.customMessage;
    return customText ? (
      <CustomTextContainer>
        <CustomTextTitle>
          <FormattedMessage
            id="global_checkout.custom_text_title"
            defaultMessage="Your Customization:"
          />
        </CustomTextTitle>
        &nbsp;
        <CustomTextContent>Savage x {customText}</CustomTextContent>
      </CustomTextContainer>
    ) : null;
  };

  if (hideDetails) {
    return null;
  }

  if (renderDetails) {
    return renderDetails(props);
  }

  return (
    <ItemDetails className={className}>
      {hasPromo && isHeader && !isGwpProduct ? (
        <BasketPricePromo hideInDrawer isClearanceItem={isClearanceItem}>
          {promoLabel}
        </BasketPricePromo>
      ) : null}
      {autoBundleEnabled && isByoSet ? (
        <ItemData>
          <BundleProductName>{product.masterLabel}</BundleProductName>
        </ItemData>
      ) : null}
      <ItemData>
        <BasketItemSize isExchange={isExchange} product={product} />
      </ItemData>
      {renderColor(product)}
      {renderCustomText(product)}
      {productMaterials && !hideMaterials ? (
        <ItemData>{productMaterials}</ItemData>
      ) : null}
      {shouldShowTokenIneligibleMessage ? (
        <IneligibleForTokenRedemptionMessage>
          <FormattedMessage
            id="global_checkout.ineligible_for_token_redemption"
            defaultMessage="Cannot be redeemed with Member Credits"
          />
        </IneligibleForTokenRedemptionMessage>
      ) : null}
    </ItemDetails>
  );
}

BasketItemDetails.propTypes = {
  autoBundleEnabled: PropTypes.bool,
  isByoSet: PropTypes.bool,
  checkoutMaterialsEnabled: PropTypes.bool,
  className: PropTypes.string,
  discounts: PropTypes.array,
  extraDetail: PropTypes.node,
  hideColor: PropTypes.bool,
  hideDetails: PropTypes.bool,
  hideMaterials: PropTypes.bool,
  isClearanceItem: PropTypes.bool.isRequired,
  isExchange: PropTypes.bool,
  isHeader: PropTypes.bool,
  product: PropTypes.object.isRequired,
  renderDetails: PropTypes.func,
  shouldShowTokenIneligibleMessage: PropTypes.bool,
};
