import gatherProductTrackingData from './gatherProductTrackingData';

const gatherProductListTrackingData = async (products = [], options) => {
  const enhancedData = await Promise.all(
    products.map(product => gatherProductTrackingData(product, options))
  );

  return enhancedData.reduce((list, enhancedList) => {
    if (Array.isArray(enhancedList)) {
      list.push(...enhancedList);
    }
    return list;
  }, []);
};
export default gatherProductListTrackingData;
