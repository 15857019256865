import React, { useCallback, useEffect, useState } from 'react';

import { useMembership } from '@techstyle/react-accounts';
import { useDomain, useSession } from '@techstyle/redux-core';

import { Component as ADAWidgetSnippet } from '../ADAWidgetSnippet';

const Context = React.createContext();

function ADAWidgetProvider({ children }) {
  const [adaWidgetButton, setAdaWidgetButton] = useState();
  const [shouldDisplayADA, setShouldDisplayADA] = useState(false);
  const { isServerOnly } = useSession();
  const {
    networkStatus: { isUpToDate: isMembershipUpToDate },
  } = useMembership();
  const isMembershipLoaded = !isServerOnly && isMembershipUpToDate;
  const { tld } = useDomain();

  useEffect(() => {
    // If we already know it should display, then keep displaying it!
    if (!shouldDisplayADA) {
      setShouldDisplayADA(tld === '.com' && isMembershipLoaded);
    }
  }, [tld, isMembershipLoaded, setShouldDisplayADA, shouldDisplayADA]);

  const handleReady = useCallback(adaWidgetButton => {
    setAdaWidgetButton(adaWidgetButton);
  }, []);

  return (
    <Context.Provider value={adaWidgetButton}>
      {shouldDisplayADA ? <ADAWidgetSnippet onReady={handleReady} /> : null}
      {children}
    </Context.Provider>
  );
}

ADAWidgetProvider.Context = Context;

export default ADAWidgetProvider;
