import React, { useState } from 'react';

import PropTypes from 'prop-types';

// Hooks
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { useCustomer } from '@techstyle/react-accounts';
import { useAsset } from '@techstyle/react-assets';
import { FormattedMessage, useIntl } from '@techstyle/react-intl';

// Utils
import { trackEmpNewTokenModalCTAClick } from '../../actions/membership';
import { mobile, desktop } from '../../styles';

// Components
import { BasicModal } from '../../styles/modal';
import { Component as CloseButton } from '../CloseButton';

import EMPConfettiWrapper from './EMPConfettiWrapper';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  place-content: center center;
  padding: 0 ${({ theme }) => theme.spacing.small}px;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
  padding-bottom: 14px;
`;

const Subtitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 12px;
`;

const dialogStyle = css`
  position: absolute;
  bottom: 0;
  display: flex;
  place-content: center center;
  padding: 30px ${({ theme }) => theme.spacing.moderate}px 45px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-color: linear-gradient(180deg, ${({ theme }) =>
    theme.colors.white}, 27.6%, ${({ theme }) => theme.colors.lavender250} 100%)
  box-shadow: 0px -2px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
  text-align: center;

  ${desktop`
    width: 500px;
    border-radius: ${({ theme }) => theme.spacing.tiny}px;
    box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.colors.lightShadow3};
    position: relative;
  `}
`;

const DrawerModal = styled(BasicModal).attrs({
  springConfig: {
    tension: 230,
  },
  dialogStyle,
  transitionFrom: {
    opacity: 0,
    transform: 'translate3d(0, 160px, 0)',
  },
  transitionEnter: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
  },
  transitionLeave: {
    opacity: 0,
    transform: 'translate3d(0, 160px, 0)',
  },
})`
  ${mobile`
      ${BasicModal.Wrapper} {
        display: flex;
        align-items: flex-end;
      }
    `}
`;

const ModalCloseButton = styled(CloseButton)`
  line-height: 50px;
  width: 50px;
  height: 50px;
`;

const PurpleText = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.promo};
`;

const CircleWrapper = styled.span`
  display: inline-flex;
  place-content: center center;
  height: 23px;
  width: 23px;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.promo};
`;

const StyledHR = styled.hr`
  display: block;
  margin: 0;
  height: 1px;
  width: 120px;
  border: 1px solid ${({ theme }) => theme.colors.lavender250};
`;

const ConfettiIcon = styled.img`
  height: 32px;
  width: 32px;
  line-height: 0;
  margin: 0 ${({ theme }) => theme.spacing.small}px;
`;

const ConfettiSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 28px 0;
`;

const StyledCTAWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  border: 1px solid;

  border-image-source: linear-gradient(
    136.47deg,
    ${({ theme }) => `${theme.colors.lavender450} 0%,
    ${theme.colors.lavender600} 15.75%,
    ${theme.colors.green500} 49.79%,
    ${theme.colors.lavender600} 84.85%,
    ${theme.colors.lavender450} 97.56%`}
  );
  border-image-slice: 1;

  width: 327px;
  align-self: center;
`;

const TopCTASection = styled(StyledCTAWrapper)`
  padding: ${({ theme }) => theme.spacing.small}px 47px 29px;
`;

const BottomCTASection = styled(StyledCTAWrapper)`
  padding: ${({ theme }) => theme.spacing.tiny}px 17.5px 29px;
`;

const CTASectionTitle = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
`;

const StyledP = styled.p`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
`;

const MemberCreditsText = styled(StyledP)`
  padding: 0 0 11px;
`;

const TwoItemPromoText = styled(StyledP)`
  padding: ${({ theme }) => theme.spacing.tiny}px 0 0;
`;

const RedeemItemText = styled(StyledP)`
  padding: 0 0 7px;
`;

const PricingText = styled(StyledP)`
  padding: 6px 0 0;
`;

const TransitionalText = styled(StyledP)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  padding: 27px 0 10px;
`;

const CTALink = styled.a`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -16.5px;
  left: 81.5px;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.tiny}px
    ${({ theme }) => theme.spacing.small}px;
  width: 176px;
  height: ${({ theme }) => theme.spacing.medium}px;
  background: ${({ theme }) => theme.colors.promo};
  box-shadow: 0px 2px 6px ${({ theme }) => theme.colors.lightShadow3};
  border-radius: 22px;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  flex-align: space-between;
  gap: ${({ theme }) => theme.spacing.tiny}px;
`;

const StyledChevronRight = styled(FaChevronRight)`
  font-weight: 900;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.white};
`;

const ClickMask = styled.div``;

export default function EMPNewTokenInformationDrawerModal(props) {
  const dispatch = useDispatch();
  const { firstName } = useCustomer();
  const { formatMessage } = useIntl();
  const [fireConfetti, setFireConfetti] = useState(false);
  const { data } = useAsset('member_credits_modal_shop_now_link');
  const shopNowLink = data
    ? data.assets[0]?.options.customVars.link
    : '/products/new?nav=new-main';

  const trackBYOCTAClick = function () {
    dispatch(trackEmpNewTokenModalCTAClick('Build Your Own Bundle'));
  };

  const trackShopNowCTAClick = function () {
    dispatch(trackEmpNewTokenModalCTAClick('Shop Now'));
  };

  // Uses async setState callback to avoid race condition
  const onRestHandler = function () {
    setFireConfetti(oldFireConfettiValue => !oldFireConfettiValue);
  };

  const disableConfetti = function () {
    setFireConfetti(false);
  };
  const handleClick = e => e.stopPropagation();

  return (
    <DrawerModal
      aria-label={formatMessage({
        id: 'emp_token_onboarding.dialog_label',
        defaultMessage:
          'Enhanced Membership Program New Token Information Modal',
      })}
      title={formatMessage({
        id: 'emp_token_onboarding.dialog_title',
        defaultMessage: 'EMP New Token Information Modal',
      })}
      closeButton={<ModalCloseButton aria-label="close-button" />}
      springEvents={{
        // event handler that fires when Modal is done animating
        onRest: onRestHandler,
      }}
      {...props}
    >
      <ClickMask onClick={handleClick}>
        <StyledHeader>
          <Title data-autotag="pii-emp-new-token-given-name">
            <FormattedMessage
              id="emp_token_onboarding.greeting"
              defaultMessage="Hi"
            />
            , {firstName}!
          </Title>
          <Subtitle>
            <FormattedMessage
              id="emp_token_onboarding.you_have_earned_member_credit_message"
              defaultMessage="You've earned {quantity} {memberCreditRb}"
              values={{
                memberCreditRb: (
                  <PurpleText>
                    <FormattedMessage
                      id="emp_token_onboarding.headline_text_two"
                      defaultMessage="Member Credit"
                    />
                  </PurpleText>
                ),
                quantity: (
                  <PurpleText>
                    <CircleWrapper>1</CircleWrapper>
                  </PurpleText>
                ),
              }}
            />
          </Subtitle>
        </StyledHeader>
        <ConfettiSection>
          <StyledHR />
          <EMPConfettiWrapper
            fireConfetti={fireConfetti}
            onDecayCallback={disableConfetti}
          >
            <ConfettiIcon
              src="/static/images/confetti-icon.png"
              alt="Confetti Icon"
            />
          </EMPConfettiWrapper>

          <StyledHR />
        </ConfettiSection>
        <MemberCreditsText>
          <FormattedMessage
            id="emp_token_onboarding.ways_to_use"
            defaultMessage="Ways to use your Member Credit:"
          />
        </MemberCreditsText>
        <TopCTASection>
          <CTASectionTitle>
            <FormattedMessage
              id="emp_token_onboarding.cta_wrapper_one_headline"
              defaultMessage="Build Xclusive bundles"
            />
          </CTASectionTitle>
          <TwoItemPromoText>
            <FormattedMessage
              id="emp_token_onboarding.cta_wrapper_one_subtext"
              defaultMessage="Any two items up to $39.95 each"
            />
          </TwoItemPromoText>
          {/** This shouldn't show for NMP users bc of that we won't render EMPNewTokenInformationDrawerModal */}
          <Link href="/features/build-your-own" data-testid="byo-cta">
            <CTALink onClick={trackBYOCTAClick}>
              <FormattedMessage
                id="emp_token_onboarding.cta_link_one_text"
                defaultMessage="Build Now"
              />
              <StyledChevronRight />
            </CTALink>
          </Link>
        </TopCTASection>
        <TransitionalText>
          <FormattedMessage
            id="emp_token_onboarding.transitional_text"
            defaultMessage="OR"
          />
        </TransitionalText>
        <BottomCTASection>
          <RedeemItemText>
            <FormattedMessage
              id="emp_token_onboarding.cta_wrapper_two_subtext_one"
              defaultMessage="Redeem for"
            />
          </RedeemItemText>
          <CTASectionTitle>
            <FormattedMessage
              id="emp_token_onboarding.cta_wrapper_two_headline"
              defaultMessage="Any Item, xtra VIP Box, or Set"
            />
          </CTASectionTitle>
          <PricingText>
            <FormattedMessage
              id="emp_token_onboarding.cta_wrapper_two_subtext_two"
              defaultMessage="Regardless of price"
            />
          </PricingText>
          <Link href={shopNowLink} data-testid="shop-now-cta">
            <CTALink onClick={trackShopNowCTAClick}>
              <FormattedMessage
                id="emp_token_onboarding.cta_link_two_text"
                defaultMessage="Shop Now"
              />
              <StyledChevronRight />
            </CTALink>
          </Link>
        </BottomCTASection>
      </ClickMask>
    </DrawerModal>
  );
}

EMPNewTokenInformationDrawerModal.propTypes = {
  isOpen: PropTypes.bool,
};
