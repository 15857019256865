import { handleActions } from 'redux-actions';

import { UPDATE_DEBUG_FLAGS } from '../actions/debug';

export const defaultState = {
  // Whether to allow checking out with out of stock items in the cart.
  allowOutOfStockCheckout: false,
  // Whether to force show preorder.
  forceShowPreorder: false,
  // Whether to accept test credit cards for Adyen (EU) orders.
  testOrder: false,
  // Override initiate return page to return items
  forceReturnItems: false,
  // Show visitor status on the page where it's easy to see
  showVisitorStatus: false,
};

export default handleActions(
  {
    [UPDATE_DEBUG_FLAGS]: (state, action) => ({ ...state, ...action.flags }),
  },
  defaultState
);
