export function formatEmailPreferencesPayload(choice) {
  let emailCadence;
  let emailLevel;
  switch (choice) {
    case 'never':
      emailLevel = 'transactional';
      emailCadence = 0;
      break;
    case 'sometimes':
      emailLevel = 'all';
      emailCadence = 1;
      break;
    case 'always':
      emailLevel = 'all';
      emailCadence = 0;
      break;
  }
  return {
    emailLevel,
    vars: {
      email_cadence: emailCadence,
    },
  };
}

export function parseEmailPreferencesPayload(emailPreferences) {
  if (emailPreferences.emailLevel) {
    switch (emailPreferences.emailLevel) {
      case 'all':
        if (emailPreferences.vars) {
          switch (emailPreferences.vars.email_cadence) {
            case 1:
            case '1':
              return 'sometimes';
            case 0:
            case '0':
            default:
              return 'always';
          }
        }
        break;
      case 'transactional':
        return 'never';
    }
  } else {
    // If the emailLevel is not at the top level, we need to check the vars.
    if (emailPreferences.vars?.emailLevel) {
      switch (emailPreferences.vars.emailLevel) {
        case 'all':
          if (
            emailPreferences.vars.email_cadence === 1 ||
            emailPreferences.vars.email_cadence === '1'
          ) {
            return 'sometimes';
          }
          return 'always';
        case 'transactional':
          return 'never';
      }
    }
  }
  // Any other case: the user has none of always/sometimes/never.
  return null;
}
