const identifyQuizTraitsEvents = ({ identifyUser }) => {
  const { TRACK_IDENTIFY_QUIZ_TRAITS } = require('../../actions/quiz');
  const identifyQuizTraits = identifyUser((action, prevState, nextState) => {
    const {
      payload: { id, traits },
    } = action;

    return { id, traits };
  });
  return { [TRACK_IDENTIFY_QUIZ_TRAITS]: identifyQuizTraits };
};

export default identifyQuizTraitsEvents;
