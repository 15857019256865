import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Select as SelectV2 } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';

import { desktop, mobile } from '../../styles';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import {
  getBraSizes,
  getUndieSizes,
  getBraletteSizes,
  getLingerieSleepSizes,
} from '../../utils/sizes';
import { v2 } from '../../utils/themeVersioning';
import { Component as Select } from '../Select';

const Preference = styled(Select)`
  margin-top: ${props => props.theme.spacing.moderate}px;

  ${desktop`
    margin-top: ${props => props.theme.spacing.small}px;
  `};

  ${Select.Label} {
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.subdued};
  }

  .Select-control {
    border: none;
    border-bottom: 2px solid ${props => props.theme.colors.default};
  }
`;

const PreferenceV2 = styled(SelectV2)`
  ${({ theme }) => theme.selectV2.defaultStyle};
  ${({ theme, error }) => !!error && theme.selectV2.errorStyle};

  ${mobile`
    min-width: unset;
  `}
  ${SelectV2.Footer} {
    display: none;
  }
`;

const SizeFieldContainer = styled.div`
  flex-basis: 100%;
  ${desktop`
    flex-basis: ${100 / 2}%;
  `};

  ${v2`
    display: flex;
    flex-direction: column;
    ${desktop`
      margin-bottom: ${props => props.theme.spacing.small}px;
    `}
    margin-top: 4px;
  `}
`;

const Hint = styled.span`
  font-weight: 300;
  color: ${props => props.theme.colors.subdued};
  font-size: 9px;

  ${v2`
    margin-top: 4px;
  `}
`;

const RowWrapper = styled.div`
  ${v2`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    gap: 20px;
  `}
`;

function SizeFields({ profile, onChange, validationResult }) {
  const { 'theme-to-enable': themeToEnable } = useLDFlags();
  const PreferenceComponent = useMemo(
    () => (themeToEnable === 'newTheme' ? PreferenceV2 : Preference),
    [themeToEnable]
  );

  const braSizes = useSelector(state => getBraSizes(state.domain.tld));

  const braletteSizes = useSelector(state =>
    getBraletteSizes(state.domain.tld)
  );

  const lingerieSleepSizes = useSelector(state =>
    getLingerieSleepSizes(state.domain.tld)
  );

  const undieSizes = useSelector(state => getUndieSizes(state.domain.tld));

  const renderBraSizes = () => {
    const allSizes = [...braSizes.regular, ...braSizes.plus];
    return allSizes.map(({ label, isAvailable }) => {
      let newLabel = label;
      if (!isAvailable && label !== 'Other') {
        newLabel = `*${newLabel}`;
      }
      return themeToEnable === 'newTheme'
        ? { value: label, name: newLabel }
        : { value: label, label: newLabel };
    });
  };

  const renderSizes = sizeConfig => {
    const allSizes = [...sizeConfig.regular, ...sizeConfig.plus];
    return themeToEnable === 'newTheme'
      ? allSizes.map(label => ({ value: label, name: label }))
      : allSizes.map(label => ({ value: label, label }));
  };

  const braSizeComponent = (
    <SizeFieldContainer>
      <PreferenceComponent
        data-qa-automation="profileBraSize"
        label={
          <FormattedMessage
            id="site_account_profile.bra_size_label"
            defaultMessage="Bra Size"
          />
        }
        name="braSize"
        value={profile.braSize}
        onChange={onChange('braSize')}
        options={renderBraSizes()}
        error={
          validationResult.braSize && !validationResult.braSize.isValid ? (
            <FormattedMessage
              id="site_account_profile.select_bra_size"
              defaultMessage="Select a bra size"
            />
          ) : null
        }
      />
      <Hint>
        <FormattedMessage
          id="site_account_profile.extended_sizes_coming_soon"
          defaultMessage="*Extended sizes coming soon"
        />
      </Hint>
    </SizeFieldContainer>
  );

  const braletteSizeComponent = (
    <SizeFieldContainer>
      <PreferenceComponent
        data-qa-automation="profileBralleteSize"
        label={
          <FormattedMessage
            id="site_account_profile.bralette_size_label"
            defaultMessage="Bralette Size"
          />
        }
        name="braletteSize"
        value={profile.braletteSize}
        onChange={onChange('braletteSize')}
        options={renderSizes(braletteSizes)}
        error={
          validationResult.braletteSize &&
          !validationResult.braletteSize.isValid ? (
            <FormattedMessage
              id="site_account_profile.select_bralette_size"
              defaultMessage="Select a bralette size"
            />
          ) : null
        }
      />
    </SizeFieldContainer>
  );
  return (
    <>
      {themeToEnable === 'newTheme' ? (
        <RowWrapper>
          {braSizeComponent}
          {braletteSizeComponent}
        </RowWrapper>
      ) : (
        <>
          {braSizeComponent}
          {braletteSizeComponent}
        </>
      )}
      <SizeFieldContainer>
        <PreferenceComponent
          label={
            <FormattedMessage
              id="site_account_profile.undie_size_label"
              defaultMessage="Undie Size"
            />
          }
          name="undieSize"
          value={profile.undieSize}
          onChange={onChange('undieSize')}
          options={renderSizes(undieSizes)}
          error={
            validationResult.undieSize &&
            !validationResult.undieSize.isValid ? (
              <FormattedMessage
                id="site_account_profile.select_undie_size"
                defaultMessage="Select an undie size"
              />
            ) : null
          }
        />
      </SizeFieldContainer>
      <SizeFieldContainer>
        <PreferenceComponent
          label={
            <FormattedMessage
              id="site_account_profile.lingerie_and_sleep_size_label"
              defaultMessage="Lingerie and Sleep Size"
            />
          }
          data-qa-automation="myacctProfileLingerie"
          name="Lingerie and Sleep Size"
          value={profile.lingerieSleepSize}
          onChange={onChange('lingerieSleepSize')}
          options={renderSizes(lingerieSleepSizes)}
          error={
            validationResult.undieSize &&
            !validationResult.undieSize.isValid ? (
              <FormattedMessage
                id="site_account_profile.select_lingerie_and_sleep_size"
                defaultMessage="Select a lingerie and sleep size"
              />
            ) : null
          }
        />
      </SizeFieldContainer>
    </>
  );
}

SizeFields.defaultProps = {
  validationResult: {},
};

SizeFields.propTypes = {
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validationResult: PropTypes.object,
};

export default SizeFields;
