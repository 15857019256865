import React from 'react';

import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { useAsset } from '@techstyle/react-assets';

import { v1, v2 } from '../../utils/themeVersioning';

const Badge = styled.div`
  display: flex;
  align-items: center;

  ${v1`
    margin-left: ${({ theme }) => theme.spacing.tiny}px;
    padding: 2px ${({ theme }) => theme.spacing.tiny}px 3px
    ${({ theme }) => theme.spacing.tiny}px;
    height: 20px;
    background: ${props => props.theme.colors.promo};
    border-radius: 2px;
    color: ${props => props.theme.colors.white};
  `}

  ${v2`
    display: flex;
    padding: 2px 20px;
    margin: 0px ${({ theme }) => theme.spacing.tiny}px; 0px 0px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}

  @media (min-width: ${({ theme }) => theme.breakpoints.large + 1}px) {
    display: none;
  }
`;

const Text = styled.span`
  ${v1`
    color: ${props => props.theme.colors.white};
  font-size: 12px;
  `}
`;

const NavAssetBadge = ({ itemKey }) => {
  const { data, networkStatus } = useAsset(itemKey.replace('$', ''));

  if (!networkStatus.isUpToDate && networkStatus.isLoading) {
    return null;
  }

  const { options } = data ? data.assets[0] : {};
  if (!options) {
    return null;
  }

  return (
    <Badge>
      <Text>{options.customVars.promoText}</Text>
    </Badge>
  );
};

NavAssetBadge.propTypes = {
  itemKey: PropTypes.string.isRequired,
};

export default withTheme(NavAssetBadge);
