import { css, DefaultTheme, ThemeProps } from 'styled-components';

export function v1(...args) {
  return css`
    ${(props: ThemeProps<DefaultTheme>) => {
      if (props.theme.themeVersion === 'v1') {
        // @ts-ignore infer later from styled-components
        return css(...args);
      }
      return null;
    }}
  `;
}

export function v2(...args) {
  return css`
    ${(props: ThemeProps<DefaultTheme>) => {
      if (props.theme.themeVersion === 'v2') {
        // @ts-ignore infer later from styled-components
        return css(...args);
      }
      return null;
    }}
  `;
}
