// TODO: Switch to local types when available (i.e. @/types alias)
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type { CartDto } from '@techstyle/react-types';

export const getIsCartFeatureEnabled = (
  cart: Partial<CartDto>,
  handle: string
) => {
  const { features } = cart;

  if (!features || !features.length) {
    return false;
  }

  return features.some(singleFeature => {
    if (singleFeature.handle === handle) {
      return singleFeature.enabled && singleFeature.value;
    }

    return false;
  });
};

const getCart = (state: any) => state.checkout;

// We don't have a type for products yet, the generated types from
// `/products/v2` list the product type as `any`
type PartialProduct = {
  masterProductId: number;
  relatedProductIdList?: number[];
};

type UseProductsFilteredByCartOptions = {
  /**
   * A list of products to filter, must include `masterProductId` and `relatedProductIdList`
   */
  products: PartialProduct[];
  /**
   * The filter level determines how the products are filtered,
   * `exact` will ony check for matches for `masterProductId`, while
   * `related` will check for matches in `masterProductId` and `relatedProductIdList`
   * Defaults to `exact`
   */
  filterLevel?: 'exact' | 'related';
  /**
   * The maximum length of the returned product list.
   */
  maxLength?: number;
};

/**
 * Returns a filtered list of products based on the current cart.
 * The products response passed in must include `related_product_id_list`, which
 * is not included in `getDefaultProductFields()` by default.
 */
export const useProductsFilteredByCart = ({
  products,
  filterLevel = 'exact',
  maxLength,
}: UseProductsFilteredByCartOptions) => {
  const { items: cartItems } = useSelector(getCart);

  const cartItemIdsSet = useMemo(() => {
    return new Set<number>(cartItems.map(item => item.masterProductId));
  }, [cartItems]);

  const filteredProducts = useMemo(() => {
    let filteredProducts;
    if (filterLevel) {
      const enableRelatedProductFilter = filterLevel === 'related';

      filteredProducts = products.filter(product => {
        if (cartItemIdsSet.has(product.masterProductId)) {
          return false;
        }

        if (enableRelatedProductFilter && product?.relatedProductIdList) {
          // check if relatedProductIdList contains any cart item ids
          // relatedProductIdList is an array of numbers
          const hasRelatedProduct = product.relatedProductIdList.some(
            singleProductId => cartItemIdsSet.has(singleProductId)
          );
          if (hasRelatedProduct) {
            return false;
          }
        }

        return true;
      });
    } else {
      filteredProducts = products;
    }

    if (maxLength) {
      return filteredProducts.slice(0, maxLength);
    }

    return filteredProducts;
  }, [cartItemIdsSet, filterLevel, maxLength, products]);

  return filteredProducts;
};
