export function getAvailableQuantity(
  sizeProduct,
  masterProduct,
  isPreorderable = false
) {
  // Always use an array for consistency.
  if (!Array.isArray(sizeProduct)) {
    sizeProduct = sizeProduct ? [sizeProduct] : [];
  }
  if (sizeProduct.length) {
    // If given multiple products, the resulting quantity is the minimum of all
    // quantities.
    return Math.min(
      ...sizeProduct.map(product =>
        isPreorderable
          ? product.available_quantity_preorder
          : product.available_quantity
      )
    );
  }
  return isPreorderable
    ? masterProduct.available_quantity_master_preorder
    : masterProduct.available_quantity_master;
}

export function getAvailableQuantitySize(
  sizeList,
  sizeValue,
  isPreorderable = false
) {
  if (sizeList) {
    const selectedSizeInfo = sizeList.find(
      sizeItem => sizeItem.size === sizeValue.value
    );
    let availableQuantity = 0;

    if (selectedSizeInfo) {
      availableQuantity = isPreorderable
        ? selectedSizeInfo.available_quantity_preorder
        : selectedSizeInfo.available_quantity;
    }

    return availableQuantity;
  }
  return 0;
}
