import React from 'react';

import { GenesysWebChatSnippet } from '@techstyle/react-marketing';

import useGenesysWebChatAction from '../../utils/useGenesysWebChatAction';

const GenesysWebChatScript = () => {
  const { deploymentId } = useGenesysWebChatAction();

  return (
    <>
      <GenesysWebChatSnippet deploymentId={deploymentId} />
    </>
  );
};

export default GenesysWebChatScript;
