import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { TextLink, TextField } from '@techstyle/react-components';
import { Checkbox } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';

import { trackRegistrationFailure } from '../../actions/auth';
import { subscribe as onRequestSubscribe } from '../../actions/contacts';
import {
  INVALID_EMAIL,
  EMAIL_REQUIRED,
  EmailPreferences,
} from '../../constants/email';
import { mobile } from '../../styles';
import { BasicButton } from '../../styles/button';
import { validateEmail } from '../../utils/email';
import { v1, v2 } from '../../utils/themeVersioning';

import NewsletterSignupContent from './NewsletterSignupContent';
import NewsletterSuccessContent from './NewsletterSuccessContent';

const ModalSubContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  max-width: 400px;
  min-height: 300px;
`;

const NextModalSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  min-height: 200px;

  ${v1`
      margin: auto;
    `}

  ${v2`
      margin-top: 24px;
    `}
`;

const ModalParagraph = styled.p`
  text-align: center;
  padding: 0px;

  ${v1`
    font-size: 14px;
    font-weight: normal;
    margin: 0px 48px;
    `}

  ${v2`
    ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
    `}

  ${mobile`
    margin: 0px;
    margin-right: 10%;
    margin-left: 10%;
  `};
`;

const BottomModalParagraph = styled(ModalParagraph)`
  ${v2`
      text-transform: none;
      `}
`;

const ModalBoldParagraph = styled.p`
  padding: 0px;
  text-align: center;

  ${v1`
      margin: 0 0 20px 0;
      font-size: 32px;
      font-weight: bold;
    `}

  ${v2`
      ${props => props.theme.header.variants.h2.textStyles}
      font-size: 32px;
      margin: 0 0 24px 0;
      `}

  ${mobile`
    font-size: 24px;
    font-weight: 500;
  `};
`;

const getInputBoxStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].inputBoxStyle}
`;

const getInputStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].inputStyle}
`;

const getLabelStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].labelStyle}
`;

const getErrorStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].errorStyle}
`;

const EmailInput = styled(TextField).attrs(({ variant, theme }) =>
  theme.themeVersion === 'v2'
    ? {
        errorStyle: getErrorStyle(variant),
        inputBoxStyle: getInputBoxStyle(variant),
        inputStyle: getInputStyle(variant),
        labelStyle: getLabelStyle(variant),
      }
    : {}
)`
  ${v1`
      margin: ${props => props.theme.spacing.small}px 0px;
    `}
  ${v2`
      margin: 24px 0px;
      width: 100%;
    `}
  min-width: 300px;

  ${mobile`
    min-width: 90%;
  `};
`;

const PrivacyPolicyCheckbox = styled(Checkbox)`
  ${v1`
     max-width: 300px;
  `}
`;

const PrivacyPolicyLink = styled(TextLink)`
  font-weight: bold;
  font-size: inherit;
  color: inherit;
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.default};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }
`;

const UnlockButton = styled(BasicButton).attrs({
  type: 'submit',
})`
  min-width: 300px;
  background-color: ${props => props.theme.colors.default};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  margin: 20px 0px 10px;

  ${mobile`
    min-width: 90%;
  `};

  ${v2`
    width: 100%;
    margin: 24px 0px 10px;
    `}
`;

const inputBoxStyle = css`
  border-color: ${props => props.theme.colors.default};
  border-style: none none solid;
  border-width: 0px 0px 2px;
  border-image: none 100% / 1 / 0 stretch;
  border-radius: 0px;
  &[data-has-focus] {
    border-width: 0px 0px 2px;
    border-style: none none solid;
    box-shadow: none;
    border-color: ${props =>
      props.theme.focusColor || props.theme.colors.active};
    label[data-label-position='inside'] {
      color: ${props => props.theme.focusColor || props.theme.colors.active};
    }
  }
`;

const inputStyle = css`
  padding-left: 0px;
`;

const labelStyle = css`
  text-transform: uppercase;
  &[data-label-position='floating'],
  &[data-label-position='inside'] {
    margin-left: 0px;
  }
  &[data-label-position='floating'] {
    font-size: 14px;
  }
  &[data-label-position='inside'] {
    transform: scale(1);
    line-height: 21px;
    font-size: 10px;
  }
`;

function NewsletterContent({
  isVisitor,
  onRequestClose,
  setShouldShowBackButton,
  setShouldShowNextModal,
  shouldShowNextModal,
  showMessage,
}) {
  const [emailError, setEmailError] = useState(null);
  const [emailText, setEmailText] = useState('');
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState({}); // Subscribe status, including errors.

  const { isBorderfreeCustomer: isBorderFree, country } = useSelector(
    state => state.borderfree
  );
  const dispatch = useDispatch();

  const isEmailValid = Boolean(emailText) && validateEmail(emailText);
  const disabled =
    !isEmailValid || (!isPrivacyPolicyChecked && country !== 'US');

  const checkEmailValidation = () => {
    if (!emailText) {
      setEmailError(EMAIL_REQUIRED);
    } else if (!validateEmail(emailText)) {
      setEmailError(INVALID_EMAIL);
    } else {
      setEmailError(null);
    }
  };

  const togglePrivacyPolicyChecked = () => {
    setIsPrivacyPolicyChecked(prev => !prev);
  };

  const openPrivacyPolicy = event => {
    event.preventDefault();
    const win = window.open('/privacypolicy', '_blank');
    win.focus();
  };

  const handleEmailChange = event => {
    const { value: emailText } = event.target;
    setEmailText(emailText);
  };

  const handleSubscribe = async event => {
    event.preventDefault();
    // Reset errors
    setEmailError(null);
    setSubscribeStatus({});

    let error;
    let result;

    if (isEmailValid) {
      try {
        result = await dispatch(
          onRequestSubscribe({
            email: emailText,
            optOutStatus: EmailPreferences.ALWAYS,
          })
        );
      } catch (err) {
        error = err;
        dispatch(trackRegistrationFailure(err || {}));
        if (err.statusCode === 400) {
          setSubscribeStatus({ error: `${err.message}` });
        } else {
          showMessage('We’ve encountered an error. Try again');
          setSubscribeStatus({ error: `${err}` });
        }
      }

      if (!result) {
        throw new Error('Received empty subscribe response.');
      }
      if (!error && result.payload.userExists) {
        setShouldShowNextModal(true);
        setSubscribeStatus({ userExists: true });
      } else if (!error) {
        setShouldShowNextModal(true);
        setSubscribeStatus({ success: true });
      }
    }
  };

  const renderEmailInput = () => {
    let errorMessage;

    if (
      emailError === EMAIL_REQUIRED ||
      emailError === INVALID_EMAIL ||
      subscribeStatus.error === INVALID_EMAIL
    ) {
      errorMessage = (
        <FormattedMessage
          id="global_cta.enter_valid_email"
          defaultMessage="Enter a valid email address"
        />
      );
    } else {
      errorMessage = null;
    }

    return (
      <EmailInput
        autocomplete="email"
        error={errorMessage}
        inputBoxStyle={inputBoxStyle}
        inputStyle={inputStyle}
        label="Email"
        labelStyle={labelStyle}
        onBlur={checkEmailValidation}
        onChange={handleEmailChange}
        name="email"
        type="email"
        value={emailText}
      />
    );
  };

  if (shouldShowNextModal) {
    return (
      <NextModalSubContainer>
        <ModalBoldParagraph>
          <FormattedMessage
            id="global_cta.newsletter_next_modal_header"
            defaultMessage="You’re In!"
          />
        </ModalBoldParagraph>
        {isBorderFree || !isVisitor || subscribeStatus.userExists ? (
          <NewsletterSuccessContent
            onRequestClose={onRequestClose}
            setShouldShowBackButton={setShouldShowBackButton}
          />
        ) : (
          <NewsletterSignupContent
            emailText={emailText}
            onRequestClose={onRequestClose}
            setShouldShowBackButton={setShouldShowBackButton}
          />
        )}
      </NextModalSubContainer>
    );
  }

  return (
    <ModalSubContainer onSubmit={handleSubscribe}>
      <ModalParagraph>
        <FormattedMessage
          id="global_cta.newsletter_modal_heading_before"
          defaultMessage="Sign up for emails and get"
        />
      </ModalParagraph>
      <ModalBoldParagraph>
        <FormattedMessage
          id="global_cta.newsletter_modal_heading"
          defaultMessage="10% Off Your Next Order!"
        />
      </ModalBoldParagraph>
      <BottomModalParagraph>
        <FormattedMessage
          id="global_cta.newsletter_modal_description"
          defaultMessage="Stay in the loop on all things Savage: Hand-picked favorites by Rihanna, styling tips, exclusive deals & promos, plus so much more."
        />
      </BottomModalParagraph>
      {renderEmailInput()}
      {country !== 'US' ? (
        <PrivacyPolicyCheckbox
          checked={isPrivacyPolicyChecked}
          name="privacy_policy"
          onChange={togglePrivacyPolicyChecked}
          size="large"
          verticalAlign="flex-start"
          label={
            <FormattedMessage
              defaultMessage="I confirm that I have read and accept the {privacy}."
              id="global_cta.privacy_policy_confirm"
              values={{
                privacy: (
                  <PrivacyPolicyLink
                    onClick={openPrivacyPolicy}
                    href="/privacypolicy"
                  >
                    <FormattedMessage
                      defaultMessage="Privacy Policy"
                      id="global_cta.privacy_policy"
                    />
                  </PrivacyPolicyLink>
                ),
              }}
            />
          }
        />
      ) : null}
      <UnlockButton disabled={disabled}>
        <FormattedMessage
          id="global_cta.newsletter_modal_unlock_button"
          defaultMessage="UNLOCK MY OFFER"
        />
      </UnlockButton>
    </ModalSubContainer>
  );
}

NewsletterContent.propTypes = {
  isOpen: PropTypes.bool,
  isVisitor: PropTypes.bool,
  onRequestClose: PropTypes.func,
  setShouldShowBackButton: PropTypes.func,
  setShouldShowNextModal: PropTypes.func,
  shouldShowNextModal: PropTypes.bool,
  showMessage: PropTypes.func,
};

export default NewsletterContent;
