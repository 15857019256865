import React, { useCallback, useContext, useEffect, useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Router } from '@techstyle/next-routes';
import { loadProfile, logIn, useCustomer } from '@techstyle/react-accounts';
import { TextField, TextLink } from '@techstyle/react-components';
import { PasswordInput } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';
import {
  AuthType,
  getSession,
  useCookies,
  useSession,
} from '@techstyle/redux-core';

import { toggleSignInModal } from '../../actions/auth';
import { loadCartItemCount } from '../../actions/checkout';
import { SignUpMethod } from '../../constants/auth';
import toastWrapper from '../../hoc/toastWrapper';
import { mobile, desktop } from '../../styles';
import { BasicButton } from '../../styles/button';
import { BasicModal } from '../../styles/modal';
import { Paragraph } from '../../styles/paragraph';
import { useCheckIfPasswordlessLogin } from '../../utils/useCheckIfPasswordlessLogin';
import Loading from '../Loading/Loading';
import { Container as ReCAPTCHA } from '../ReCAPTCHA';
import ToastContext from '../ToastContext/ToastContext';

const isTestSecretRecaptchaNeeded = config.get(
  'public.recaptcha.automatedTestSecretsNeeded'
);

const isRecaptchaEnabled = config.get('public.recaptcha.enabled');

const SIGN_IN = 'SIGN_IN';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const PASSWORD_INVALID = 'PASSWORD_INVALID';
const PASSWORD_REQUIRED = 'PASSWORD_REQUIRED';

const dialogStyle = css`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  ${desktop`
    width: 370px; // for Captcha size
    min-height: 395px;
  `};

  ${mobile`
    margin: 0 16px;
  `};
`;

const AutoLoginPromptModal = styled(BasicModal)``;

const TextParagraph = styled(Paragraph)`
  text-align: center;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}px;
  color: ${props => props.theme.colors.default};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

const FormLink = styled(TextLink)`
  color: ${props => props.theme.colors.active};
`;

const FormField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

const SubmitButton = styled(BasicButton).attrs({
  fullWidth: true,
  type: 'submit',
})`
  margin: ${props => props.theme.spacing.small}px 0;
`;

const Form = styled.form`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 29px 34px;

  background-color: ${props => props.theme.colors.white};
  ${desktop`
    flex-wrap: nowrap;
    ${props => props.showSwitcher && `min-height: 620px;`}
  `};
`;

const ProductionTestCaptcha = styled(TextField)``;

const AutoLoginPrompt = ({
  inCheckout = false,
  method = SignUpMethod.SIGN_IN,
}) => {
  const cart = useSelector(state => state.checkout);
  const { isAdmin } = useSession();
  const {
    authType = AuthType.ANONYMOUS,
    isAutoLoggedIn: isAutoLoggedInUser,
    isLoggedInWithCredentials,
  } = useSelector(state => getSession(state));
  const productCount = cart.quantity;
  const profile = useCustomer();
  const dispatch = useDispatch();
  const { isPasswordlessLogin } = useCheckIfPasswordlessLogin(!!inCheckout);

  const { showMessage: showToastMessage } = useContext(ToastContext.Context);

  const [hasShownModal, setHasShownModal] = useState(false);
  const [isOpen, setIsOpen] = useState(isAutoLoggedInUser);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');
  const [reCaptchaResponse, setReCaptchaResponse] = useState('');
  const [, setSignInStatus] = useState({ error: '' });

  const [cookies] = useCookies(['automated_test']);
  const firstName = profile && profile.firstName ? `${profile.firstName}` : '';
  const email = profile && profile.email ? `${profile.email}` : '';
  const disableRecaptcha = !!cookies.automated_test;
  const disableButton =
    !passwordValue ||
    (isRecaptchaEnabled && !isAdmin && !reCaptchaResponse && !disableRecaptcha);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!profile.id) {
        await dispatch(loadProfile());
      }
    };

    fetchProfile();
  }, [dispatch, profile.id]);

  const showLoginPrompt = useCallback(() => {
    if (!hasShownModal) {
      // hitting account page || hitting cart with at least 1 product = DO show the AutoLogin modal
      // hitting cart with no products - DONT show the AutoLogin modal
      if ((inCheckout && productCount) || !inCheckout) {
        setIsOpen(true);
        setHasShownModal(true);
      }
    }
  }, [hasShownModal, inCheckout, productCount]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    dispatch(toggleSignInModal(false));
  }, [dispatch]);

  const redirectHome = useCallback(() => {
    Router.push('/');
  }, []);

  const handleSignInModal = useCallback(
    async (formState = 'SIGN_IN') => {
      const data = await dispatch(toggleSignInModal(true, formState, method));

      if ((data && !data.signedIn) || formState === FORGOT_PASSWORD) {
        closeModal();
        redirectHome();
      }
    },
    [closeModal, dispatch, method, redirectHome]
  );

  const showSignInModal = useCallback(() => {
    if (authType === AuthType.ANONYMOUS && !inCheckout) {
      handleSignInModal();
    } else if (isPasswordlessLogin && !inCheckout) {
      // Currently passwordless logins should see the forgot password modal, this will be
      // updated in the future to show updated messaging.
      handleSignInModal(FORGOT_PASSWORD);
    }
  }, [authType, handleSignInModal, inCheckout, isPasswordlessLogin]);

  useEffect(() => {
    if (!isAutoLoggedInUser) {
      // not autologin (ANONYMOUS||CREDENTIALS)
      // visiting account and anonymous we show the full sign in modal
      // if credentials do nothing and render this component as `null`
      showSignInModal();
    } else {
      showLoginPrompt();
    }
  }, [isAutoLoggedInUser, showLoginPrompt, showSignInModal]);

  const handleReCaptchaChange = useCallback(reCaptchaResponseVal => {
    setReCaptchaResponse(reCaptchaResponseVal);
  }, []);

  const checkPassword = useCallback(
    isPasswordLengthRestricted => {
      if (!passwordValue) {
        return PASSWORD_REQUIRED;
      } else if (isPasswordLengthRestricted && passwordValue < 6) {
        return PASSWORD_INVALID;
      } else {
        return null;
      }
    },
    [passwordValue]
  );

  const changePassword = useCallback(
    event => {
      const { value } = event.target;
      setPasswordValue(value);
      // If there's an error, we want it to go away if the input validates now.
      if (passwordError) {
        const error = checkPassword();
        setPasswordError(error);
      }
    },
    [checkPassword, passwordError]
  );

  const renderPasswordField = useCallback(
    ({ dataAutotag, isPasswordLengthRestricted }) => {
      let errorMessage;
      if (passwordError === 'PASSWORD_REQUIRED') {
        errorMessage = (
          <FormattedMessage
            defaultMessage="Enter a password"
            id="global_checkout.password_validation_enter_a_password"
          />
        );
      } else if (passwordError === 'PASSWORD_INVALID') {
        errorMessage = (
          <FormattedMessage
            defaultMessage="Invalid. Please correct and re-submit."
            id="global_checkout.password_validation_invalid_password"
          />
        );
      }

      return (
        <>
          <FormField>
            <PasswordInput
              autocomplete="current-password"
              label={
                <FormattedMessage
                  defaultMessage="Password"
                  id="global_checkout.password_field_labels"
                />
              }
              name="password"
              value={passwordValue}
              onChange={changePassword}
              error={errorMessage}
            />
          </FormField>
        </>
      );
    },
    [changePassword, passwordError, passwordValue]
  );

  const handleRequestClose = useCallback(
    event => {
      closeModal();
      redirectHome();
    },
    [closeModal, redirectHome]
  );

  const handleSignIn = useCallback(
    async event => {
      event.preventDefault();

      // Reset password error to repopulate new one
      setPasswordError(null);

      const error = checkPassword();

      if (error) {
        setPasswordError(error);
      } else {
        let error;
        try {
          await dispatch(
            logIn(
              {
                username: profile.email,
                password: passwordValue,
                reCaptchaResponse: reCaptchaResponse,
              },
              { method: SignUpMethod.SIGN_IN }
            )
          );
        } catch (err) {
          error = err;
          showToastMessage(
            <FormattedMessage
              defaultMessage="Your email or password is incorrect."
              id="global_checkout.email_password_incorrect"
            />
          );
          setSignInStatus({ error, reCaptchaResponse: '' });
        }
        if (!error) {
          if (!cart.cartDatetimeActivated) {
            dispatch(loadCartItemCount());
          }
          setSignInStatus({ success: true });
          closeModal();
        }
      }
    },
    [
      cart,
      checkPassword,
      closeModal,
      dispatch,
      passwordValue,
      profile.email,
      reCaptchaResponse,
      showToastMessage,
    ]
  );

  const toggleFormState = useCallback(
    (formState, event) => {
      event.preventDefault();
      closeModal();
      handleSignInModal(formState);
    },
    [closeModal, handleSignInModal]
  );

  if (profile.networkStatus.isLoading) {
    return <Loading />;
  }

  if (isLoggedInWithCredentials) {
    return null;
  }

  return (
    <AutoLoginPromptModal
      isOpen={isOpen}
      onExit={handleRequestClose}
      dialogStyle={dialogStyle}
      titleId="autologin-modal"
    >
      <Form onSubmit={handleSignIn} data-autotag="sign-in-form">
        <TextParagraph
          size={18}
          align="center"
          bold
          data-autotag="pii-auto-login-first-name"
        >
          <FormattedMessage
            defaultMessage="Hi {name}!"
            id="global_checkout.firstname_text"
            values={{
              name: firstName,
            }}
          />
        </TextParagraph>
        <TextParagraph
          size={14}
          align="center"
          marginTop={8}
          id="autologin-modal"
        >
          <FormattedMessage
            defaultMessage="Please re-enter your password so we can verify it's really you."
            id="global_checkout.reenter_password"
          />
        </TextParagraph>
        <TextParagraph size={10} align="center" marginTop={19} uppercase>
          <FormattedMessage
            defaultMessage="EMAIL"
            id="global_checkout.email_label"
          />
        </TextParagraph>
        <TextParagraph
          size={14}
          align="center"
          marginTop={2}
          bold
          data-autotag="pii-auto-login-email"
        >
          <FormattedMessage
            defaultMessage="{email}"
            id="global_checkout.email_text"
            values={{
              email,
            }}
          />
        </TextParagraph>
        {renderPasswordField({
          dataAutotag: 'reg-password-fld',
          isPasswordLengthRestricted: true,
        })}
        {isRecaptchaEnabled && !isAdmin && !disableRecaptcha && (
          <ReCAPTCHA render="explicit" onChange={handleReCaptchaChange} />
        )}
        {isRecaptchaEnabled &&
          !!cookies.automated_test &&
          isTestSecretRecaptchaNeeded && (
            <ProductionTestCaptcha
              onChange={handleReCaptchaChange}
              data-autotag="production-test-captcha"
            />
          )}
        <SubmitButton data-autotag="loginmodal-btn" disabled={!!disableButton}>
          <FormattedMessage
            defaultMessage="SIGN IN"
            id="global_checkout.not_you_sign_in_button"
          />
        </SubmitButton>
        <TextParagraph align="center">
          <FormLink onClick={event => toggleFormState(FORGOT_PASSWORD, event)}>
            <FormattedMessage
              defaultMessage="Forgot password?"
              id="global_checkout.not_you_forgot_password_link"
            />
          </FormLink>
        </TextParagraph>
        <TextParagraph align="center" marginTop={28}>
          <FormattedMessage
            defaultMessage="Not You?"
            id="global_checkout.not_you"
          />{' '}
          <FormLink onClick={event => toggleFormState(SIGN_IN, event)}>
            <FormattedMessage
              defaultMessage="Sign In"
              id="global_checkout.not_you_sign_in"
            />
          </FormLink>
        </TextParagraph>
      </Form>
    </AutoLoginPromptModal>
  );
};

AutoLoginPrompt.propTypes = {
  inCheckout: PropTypes.bool.isRequired,
  method: PropTypes.string,
};

export default toastWrapper(AutoLoginPrompt);
