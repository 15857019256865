import React, { useMemo, useState } from 'react';

const Context = React.createContext({
  isModalOpen: false,
  setIsModalOpen: () => {},
});

const MemberCreditsDrawerModalProvider = props => {
  const [hasUnlockedPerks, setHasUnlockedPerks] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldForceNewCreditDialog, setShouldForceNewCreditDialog] =
    useState(false);

  // We are spreading `propsValue` so we can mock `value` in unit tests
  const propsValue = useMemo(() => props.value || {}, [props.value]);

  const value = useMemo(
    () => ({
      hasUnlockedPerks,
      isModalOpen,
      setHasUnlockedPerks,
      setIsModalOpen,
      setShouldForceNewCreditDialog,
      shouldForceNewCreditDialog,
      ...propsValue,
    }),
    [
      hasUnlockedPerks,
      isModalOpen,
      propsValue,
      setHasUnlockedPerks,
      setIsModalOpen,
      setShouldForceNewCreditDialog,
      shouldForceNewCreditDialog,
    ]
  );

  return <Context.Provider {...props} value={value} />;
};

MemberCreditsDrawerModalProvider.Context = Context;

export default MemberCreditsDrawerModalProvider;
