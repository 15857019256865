import React, { useCallback, useState, useContext } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import { Link, Router } from '@techstyle/next-routes';
import { useMembership } from '@techstyle/react-accounts';
import { AssetContainer } from '@techstyle/react-assets';
import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { useFeature } from '@techstyle/react-features';
import { FormattedMessage, injectIntl } from '@techstyle/react-intl';
import { updateSessionDetail, useSession } from '@techstyle/redux-core';

import { trackNavItemClick } from '../../actions/navigation';
import { NavigationizerFeatureFlags } from '../../constants/navigationizer';
import { mobile } from '../../styles';
import { BasicButton } from '../../styles/button';
import { getAdditionalSaleDiscount } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import { getNavTrackingUrl } from '../../utils/url';
import { Component as Badge } from '../Badge';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import BurgerNavLoginLink from '../BurgerNavLoginLink/BurgerNavLoginLink';
import CloseButton from '../CloseButton/CloseButton';
import FavoritesNav from '../FavoritesNav/FavoritesNav';
import { Component as MemberInfoCard } from '../MemberInfoCard';
import { Component as NavAssetBadge } from '../NavAssetBadge';
import { Container as NavMenuSubCategories } from '../NavMenuSubCategories';
import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';
import RebrandHamburgerNavFooter from '../RebrandHamburgerNavFooter/RebrandHamburgerNavFooter';
import SearchField from '../SearchField/SearchField';
import SearchProvider from '../SearchProvider/SearchProvider';

const NavContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;

  ${v2`
    padding: 12px 0px;
    border-top: 1px solid ${props => props.theme.colors.stone};
    `}

  @media (max-width: 320px) {
    width: 250px;
  }
`;

const NewVipOfferLabel = styled.span`
  ${v1`
    color: ${props => props.theme.colors.promo};
  `}
`;

const CustomColoredLabel = styled.span`
  ${v1`
    color: ${({ colorName, theme }) =>
      theme.colors[colorName] || theme.colors.default};
  `}
`;

const List = styled.ul`
  justify-content: center;
  width: 100%;
`;

const StyledBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${v1`
    color: ${({ theme }) => theme.colors.active};
    background-color: ${({ theme }) => theme.colors.lavender250};
    font-size: 10px;
    min-height: 20px;
    line-height: 0;
    padding: 0 4px;
    border-radius: 2px;
    margin-top: 0;
    height: 22px;
  `}

  ${v2`
    padding: 2px 20px;
    margin: 0px ${({ theme }) => theme.spacing.tiny}px; 0px 0px;
    margin-left: 0px;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

const MenuIcon = styled(FaChevronRight).attrs(props => {
  return {
    size: 18,
  };
})`
  margin-left: auto;
`;

const ReverseMenuIcon = styled(FaChevronLeft).attrs(props => {
  return {
    size: 18,
  };
})`
  margin-right: 6px;
  margin-left: 19px;
`;

const NewMenuIcon = styled(NewThemeIcon).attrs(props => {
  return { type: 'caretRight', height: '24px', width: '24px' };
})`
  margin-left: auto;
  color: ${props => props.theme.colors.gunmetal};
`;

const NewReverseMenuIcon = styled(NewThemeIcon).attrs(props => {
  return { type: 'caretLeft', height: '24px', width: '24px' };
})``;
const StyledToggle = styled.button`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing.small}px;
  width: 100%;
  align-items: center;

  ${v2`
    & > span {
    display: flex;
    }

    `}
`;

function ToggleButton({ label, onClick, isOpen = false, themeVersion }) {
  return (
    <StyledToggle isOpen={isOpen} onClick={onClick} type="button">
      {label}
      {themeVersion === 'v1' ? <MenuIcon size={22} /> : <NewMenuIcon />}
    </StyledToggle>
  );
}

ToggleButton.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

const ListItem = styled.li`
  ${v1`margin-bottom: ${({ theme }) => theme.spacing.moderate}px;`}
  ${v2`margin-bottom: ${({ theme }) => theme.spacing.small}px;`}

  &:last-child {
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
  }
  ${v1`
    color: ${props => {
      const inverseColor = props.theme.context.inverse
        ? props.theme.colors.white
        : props.theme.colors.default;
      return props.isRed ? props.theme.colors.error : inverseColor;
    }};
  `}
`;

const Wrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > ul:nth-child(2) {
    margin-top: auto;
  }
`;

const ListItemLink = styled.a`
  cursor: pointer;
  text-align: left;
  color: inherit;
  display: block;
  padding: 0 ${({ theme }) => theme.spacing.small}px;
`;

const StyledListItemLink = styled.div``;

const ListItemLabel = styled.span`
  ${v1`
    font-weight: 400;
  line-height: 22px;
  font-size: 18px;
  display: flex;
  `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      padding: 5px 0px;
    `}


  align-items: center;
  height: 22px;

  ${StyledBadge.Wrapper} {
    margin-top: 2px;
  }
`;

const SpecialFormattingWrapper = ({ children, navItem }) => {
  const { itemKey } = navItem;

  if (itemKey === '_mainNavNewVipOffers') {
    return <NewVipOfferLabel>{children}</NewVipOfferLabel>;
  }

  if (itemKey.includes('__color_')) {
    const colorName = itemKey.replace('__color_', '');
    return (
      <CustomColoredLabel colorName={colorName}>{children}</CustomColoredLabel>
    );
  }

  return children;
};

const AnimatedMainNav = styled.div`
  transition: all 0.3s ease-in-out;
  width: 100%;
  flex: 0 0 100%;

  &.entering {
    transform: translateX(-100%);
    opacity: 0;
  }

  &.entered {
    transform: translateX(0);
    opacity: 1;
  }

  &.exiting {
    opacity: 1;
    transform: translateX(-100%);
  }

  &.exited {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const AnimatedSecondaryNav = styled(AnimatedMainNav)`
  &.entering {
    transform: translateX(100%);
    opacity: 0;
  }

  &.entered {
    transform: translateX(0);
    opacity: 1;
  }

  &.exiting {
    transform: translateX(0);
    opacity: 1;
  }

  &.exited {
    transform: translateX(0);
    opacity: 0;
  }
`;

const StyledNavSubCategoryTitleButton = styled.button`
  ${v1`
    font-weight: 500;
  font-size: 18px;
  `}

  display: flex;
  align-items: center;
`;

const StyledNavMenuSubCategories = styled(NavMenuSubCategories)`
  & ${NavMenuSubCategories.StyledSection} {
    margin-left: 25px;
  }

  & ${NavMenuSubCategories.Title} {
    margin-left: -8px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.subdued};
    letter-spacing: 0.3px;
  }
`;

const TakeOurQuizBtn = styled(BasicButton)`
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  margin: 0 auto 48px;
  outline: none;
  text-transform: uppercase;
  width: calc(100% - ${({ theme }) => theme.spacing.medium}px);
  height: ${({ theme }) => theme.spacing.large}px;
  ${v1`
    background-color: ${({ theme }) => theme.colors.active};
  `}
  ${v2`
    background-color: ${({ theme }) => theme.colors.lavender};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.subHeader.variants.subHeading3Uppercase.textStyles}
  `}
`;

const SearchFieldWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.spacing.moderate}px;

  * {
    box-shadow: none;
  }

  ${v1`
    padding: 0 ${({ theme }) => theme.spacing.moderate}px

      > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.default};
  }
    `}

  ${v2`
    padding: 0 16px;
    ${mobile`
      margin-bottom: 20px;
    `}

      `}
`;

const StyledSearchField = styled(SearchField)`
  ${v2`
        ${mobile`
          border: 1px solid ${props => props.theme.colors.gunmetal};
        `}
      `}

  ${SearchField.SearchContainer} {
    ${v2`

          ${mobile`
            margin-top: 0px;
            padding: 0px;
          `}
          `}
  }
`;
const TopHamburgerNavContainer = styled.div`
  display: flex;
  padding: 0 16px;
  color: ${props => props.theme.colors.black};
  width: 100%;
  height: 40px;
  align-items: center;
`;
const BottomHamburgerNavContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 20px;
`;

const StyledCloseButton = styled(CloseButton)`
  height: 24px;
  width: 24px;
  position: relative;
  line-height: 24px;
`;

function NavMenuLinks({ className, closeMenu, nav, intl, showAccountModal }) {
  const mobileNavUpdatesFeature = useFeature('mobile_nav_updates');
  const isMobileNavUpdatesFeatureEnabled =
    !!mobileNavUpdatesFeature?.data?.value;
  const { isMobile } = useBreakpoint();
  const { isVip } = useMembership();
  const [activeNavIndex, setActiveNavIndex] = useState(0);
  const [showMainNav, setShowMainNav] = useState(true);
  const { query } = useRouter();
  const { isLoggedIn } = useSession();
  const theme = useTheme();
  const { toggleActive } = useContext(SearchProvider.Context);

  const isNewTheme = theme.themeVersion === 'v2';

  const dispatch = useDispatch();

  const additionalDiscounts = useSelector(state =>
    getAdditionalSaleDiscount(state)
  );

  const openSubNav = index => {
    setActiveNavIndex(activeNavIndex => (activeNavIndex = index));
    setShowMainNav(showMainNav => !showMainNav);
  };

  const handleSubNavClose = () => {
    setShowMainNav(showMainNav => !showMainNav);
  };

  const handleSessionClick = () => {
    dispatch(updateSessionDetail({ name: 'sxf_mobile_nav', value: '' }));
    closeMenu();
  };

  const handleMobileSearchFieldClick = useCallback(() => {
    closeMenu();
    toggleActive();
  }, [toggleActive, closeMenu]);

  const renderLink = (navItem, navIndex) => {
    const isNew = navItem[NavigationizerFeatureFlags.isNew];
    const isSale = navItem[NavigationizerFeatureFlags.isSale];
    const isRed =
      navItem.url === '/sale' && additionalDiscounts !== undefined && isVip;

    const sendGaEvent = () => {
      if (!navItem.gaLabel) {
        return;
      }
      dispatch(trackNavItemClick(nav.navSettings.gaAction, navItem.gaLabel));
    };

    const renderTags = () => {
      return (
        <>
          {isNew && (
            <StyledBadge>
              <FormattedMessage
                id="site_navigation.new_badge"
                defaultMessage="NEW"
              />
            </StyledBadge>
          )}
          {isSale && (
            <StyledBadge>
              <FormattedMessage
                id="site_navigation.sale_badge"
                defaultMessage="SALE"
              />
            </StyledBadge>
          )}
        </>
      );
    };

    const itemLabel = (
      <SpecialFormattingWrapper navItem={navItem}>
        <ListItemLabel>
          {isNewTheme && renderTags()}
          {navItem.itemKey && isNewTheme ? (
            <NavAssetBadge itemKey={navItem.itemKey} />
          ) : null}
          {navItem.isResourceBundle ? (
            intl.formatMessage({
              id: navItem.label,
              defaultMessage: navItem.label,
            })
          ) : (
            <span dangerouslySetInnerHTML={{ __html: navItem.label }} />
          )}
          {theme.themeVersion === 'v1' && renderTags()}
          {navItem.itemKey && theme.themeVersion === 'v1' ? (
            <NavAssetBadge itemKey={navItem.itemKey} />
          ) : null}
        </ListItemLabel>
      </SpecialFormattingWrapper>
    );

    const navItemLink = getNavTrackingUrl(navItem);

    if (!navItemLink) {
      return null;
    }

    return (
      <ListItem
        key={navItem.id}
        data-autotag={navItem.gaLabel}
        isRed={isRed}
        onClick={sendGaEvent}
      >
        {navItem.navItems && navItem.navItems.length > 0 ? (
          <ToggleButton
            label={itemLabel}
            onClick={() => openSubNav(navIndex)}
            themeVersion={theme.themeVersion}
          />
        ) : (
          <StyledListItemLink onClick={handleSessionClick}>
            <Link route={navItemLink} passHref>
              <ListItemLink>{itemLabel}</ListItemLink>
            </Link>
          </StyledListItemLink>
        )}
      </ListItem>
    );
  };

  const renderNavCategoryTitle = navItem => {
    const isNew = navItem[NavigationizerFeatureFlags.isNew];
    const isSale = navItem[NavigationizerFeatureFlags.isSale];

    return (
      <StyledNavSubCategoryTitleButton onClick={handleSubNavClose}>
        {theme.themeVersion === 'v1' ? (
          <ReverseMenuIcon />
        ) : (
          <NewReverseMenuIcon />
        )}
        {navItem.isResourceBundle ? (
          intl.formatMessage({
            id: navItem.label,
            defaultMessage: navItem.label,
          })
        ) : (
          <span dangerouslySetInnerHTML={{ __html: navItem.label }} />
        )}
        {isNew && (
          <StyledBadge>
            <FormattedMessage
              id="site_navigation.new_badge"
              defaultMessage="NEW"
            />
          </StyledBadge>
        )}
        {isSale && (
          <StyledBadge>
            <FormattedMessage
              id="site_navigation.sale_badge"
              defaultMessage="SALE"
            />
          </StyledBadge>
        )}
        {navItem.itemKey ? <NavAssetBadge itemKey={navItem.itemKey} /> : null}
      </StyledNavSubCategoryTitleButton>
    );
  };

  const showQuizModal = useCallback(() => {
    const fullQuery = {
      fromNavCta: 1,
      ...(query.mpid ? { mpid: query.mpid } : {}),
    };

    Router.push({
      pathname: '/product-quiz',
      query: fullQuery,
    });
    return closeMenu();
  }, [closeMenu, query.mpid]);

  const renderPanel = () => {
    return (
      <ListWrapper>
        {/*
          Although we aren't using this `AssetContainer` to render the asset
          directly, we still use it because by mounting this component it will
          cause the asset to be refetched when necessary (since only rendered
          assets are refetched).
        */}
        {isMobile && isNewTheme ? (
          <TopHamburgerNavContainer>
            <StyledCloseButton onClick={closeMenu} />
          </TopHamburgerNavContainer>
        ) : null}
        {(isMobileNavUpdatesFeatureEnabled || isNewTheme) && isMobile ? (
          <SearchFieldWrapper
            onClick={isNewTheme ? handleMobileSearchFieldClick : null}
          >
            <StyledSearchField />
          </SearchFieldWrapper>
        ) : null}
        {isMobile && isNewTheme ? (
          <BottomHamburgerNavContainer>
            <FavoritesNav size={24} shouldShowCountBadge />
            <BurgerNavLoginLink
              closeMenu={closeMenu}
              onLogin={showAccountModal}
            />
          </BottomHamburgerNavContainer>
        ) : null}
        <AssetContainer name="products_image_banner">
          {() => {
            return (
              <NavContainer>
                <Transition in={showMainNav} timeout={300} unmountOnExit>
                  {state => (
                    <AnimatedMainNav className={state}>
                      <List>{nav.navItems.map(renderLink)}</List>
                    </AnimatedMainNav>
                  )}
                </Transition>
                <Transition in={!showMainNav} timeout={300} unmountOnExit>
                  {state => (
                    <AnimatedSecondaryNav className={state}>
                      {renderNavCategoryTitle(nav.navItems[activeNavIndex])}
                      <StyledNavMenuSubCategories
                        gaAction={nav.navSettings.gaAction}
                        navItem={nav.navItems[activeNavIndex]}
                        onClickSubCategories={handleSessionClick}
                      />
                    </AnimatedSecondaryNav>
                  )}
                </Transition>
              </NavContainer>
            );
          }}
        </AssetContainer>
        <BorderfreeGuard>
          <List>
            <Transition in={showMainNav} timeout={300} unmountOnExit>
              {state => (
                <AnimatedMainNav className={state}>
                  {!isLoggedIn ? (
                    <TakeOurQuizBtn onClick={showQuizModal}>
                      <FormattedMessage
                        id="site_navigation.take_our_quiz_mobile"
                        defaultMessage="Take our quiz!"
                      />
                    </TakeOurQuizBtn>
                  ) : null}
                  {!isNewTheme ? (
                    <MemberInfoCard
                      onLogin={showAccountModal}
                      closeMenu={closeMenu}
                    />
                  ) : (
                    <RebrandHamburgerNavFooter closeMenu={closeMenu} />
                  )}
                </AnimatedMainNav>
              )}
            </Transition>
          </List>
        </BorderfreeGuard>
      </ListWrapper>
    );
  };

  return <Wrapper className={className}>{renderPanel()}</Wrapper>;
}

NavMenuLinks.propTypes = {
  className: PropTypes.string,
  closeMenu: PropTypes.func.isRequired,
  nav: PropTypes.object,
  intl: PropTypes.object,
  showAccountModal: PropTypes.func.isRequired,
};

export default injectIntl(NavMenuLinks);
