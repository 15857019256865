export function buildPromotionTrackingPayload(asset) {
  const { imageFilename, label, options } = asset;

  const customVars =
    options?.customVars &&
    Object.keys(options.customVars)
      .filter(varKey => {
        const optionValue = options.customVars[varKey];

        if (typeof optionValue !== 'number') {
          return Boolean(optionValue);
        }

        return true;
      })
      .reduce(
        (acc, curr) => ({ ...acc, [curr]: options.customVars[curr] }),
        {}
      );

  const trackingOptions = {
    clickable: Boolean(options?.targetUrl),
    clickableUrl: options?.targetUrl,
    creative: label,
    customVars,
    imageId: imageFilename,
    name: options.name || label,
  };

  return trackingOptions;
}
