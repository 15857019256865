import React from 'react';

import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { HTMLAsset, useAsset } from '@techstyle/react-assets';

import { trackClickPromoOfferEvent } from '../../actions/cart';
import { BasicButton } from '../../styles/button';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { v2 } from '../../utils/themeVersioning';
import { useShouldShowNewPricingSkinnyBanner } from '../../utils/useShouldShowNewPricingSkinnyBanner';
import { useTrackPromotionClick } from '../../utils/useTrackPromotionClick';
import { Component as StaticIcon } from '../StaticIcon';

const UpsellWrapper = styled.div`
  padding: ${props => props.theme.spacing.tiny}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PlusIcon = styled(StaticIcon).attrs({
  type: 'plus',
  size: 12,
})``;

const PlusButton = styled(BasicButton)`
  background: transparent;
  border: solid 1px ${props => props.theme.colors.promo};
  color: ${props => props.theme.colors.promo};
  font-weight: 500;
  letter-spacing: normal;
  text-transform: none;
  width: 100%;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > span svg {
    margin-right: ${props => props.theme.spacing.tiny}px;
    margin-top: 4px;
  }
`;

const StyledPromoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${v2`text-transform: uppercase`}
`;

const StyledPromoText = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.tiny}px;

  :before {
    margin-right: ${props => props.theme.spacing.tiny}px;
    right: 100%;
  }

  :after {
    margin-left: ${props => props.theme.spacing.tiny}px;
    left: 100%;
  }

  :before,
  :after {
    border-bottom: 4px dotted ${props => props.theme.colors.lavender400};
    content: '';
    flex: 1 1;
    height: 10px;
    letter-spacing: 3px;
  }
`;
const CtaAsset = styled(HTMLAsset)`
  font-size: 14px;
`;

const StyledPromoActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: underline;
  button {
    text-transform: uppercase;
    cursor: pointer;
  }
`;

// set the max width to keep as least 2 dots when promoText expands
const PromoTextAsset = styled(CtaAsset)`
  text-align: center;
  max-width: calc(100% - 2 * 20px);
`;

const UpsellMessage = ({ pSource }) => {
  const dispatch = useDispatch();
  const dispatchTrackPromotionClicked = useTrackPromotionClick();
  const { 'theme-to-enable': themeToEnable } = useLDFlags();

  const shouldShowNewPricingSkinnyBanner =
    useShouldShowNewPricingSkinnyBanner();

  const assetContainerMiniCart = useAsset('upsell_html_minicart');
  const { data } = assetContainerMiniCart;
  const assetMiniCart = data ? data.assets[0] : null;

  if (!assetMiniCart) {
    return null;
  }

  const handlePromoCtaClick = promoText => {
    dispatch(trackClickPromoOfferEvent(promoText));

    if (pSource === 'mini_cart') {
      dispatchTrackPromotionClicked(assetMiniCart);
    }
  };
  const { promoText, ctaText } = assetMiniCart.options.customVars;
  const { targetUrl } = assetMiniCart.options;
  if (!promoText || !ctaText) {
    return null;
  }

  if (themeToEnable === 'newTheme') {
    return (
      <UpsellWrapper>
        <Row>
          <StyledPromoWrapper>
            <PromoTextAsset
              asset={{
                ...assetMiniCart,
                options: { ...assetMiniCart.options, htmlText: promoText },
              }}
            />
          </StyledPromoWrapper>
        </Row>
        <Row>
          <StyledPromoActionWrapper>
            <button
              type="button"
              onClick={() => {
                window.location = targetUrl;
                handlePromoCtaClick(promoText);
              }}
            >
              <CtaAsset
                asset={{
                  ...assetMiniCart,
                  options: { ...assetMiniCart.options, htmlText: ctaText },
                }}
              />
            </button>
          </StyledPromoActionWrapper>
        </Row>
      </UpsellWrapper>
    );
  }
  return (
    <UpsellWrapper>
      <Row>
        <StyledPromoWrapper>
          {!shouldShowNewPricingSkinnyBanner && (
            <StyledPromoText>
              <PromoTextAsset
                asset={{
                  ...assetMiniCart,
                  options: { ...assetMiniCart.options, htmlText: promoText },
                }}
              />
            </StyledPromoText>
          )}
        </StyledPromoWrapper>
      </Row>
      <Row>
        <Link route={targetUrl} passHref>
          <PlusButton onClick={() => handlePromoCtaClick(promoText)}>
            <PlusIcon />
            <CtaAsset
              asset={{
                ...assetMiniCart,
                options: { ...assetMiniCart.options, htmlText: ctaText },
              }}
            />
          </PlusButton>
        </Link>
      </Row>
    </UpsellWrapper>
  );
};

UpsellMessage.propTypes = {
  pSource: string,
};

export default UpsellMessage;
