import React from 'react';

import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

export default class StyleContext extends React.PureComponent {
  static propTypes = {
    context: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]).isRequired,
    desktopBreakpoint: PropTypes.string,
    children: PropTypes.node,
  };

  createTheme = parentTheme => {
    const { context, desktopBreakpoint } = this.props;
    if (!context && !desktopBreakpoint) {
      return parentTheme;
    }
    let themeContext = { ...parentTheme.context };

    if (context) {
      if (Array.isArray(context)) {
        context.forEach(name => {
          themeContext[name] = true;
        });
      } else if (typeof context === 'object') {
        themeContext = { ...themeContext, ...context };
      } else {
        themeContext[context] = true;
      }
    }

    const retTheme = {
      ...parentTheme,
      desktopBreakpoint: desktopBreakpoint || parentTheme.desktopBreakpoint,
      context: themeContext,
    };
    return retTheme;
  };

  render() {
    const { children } = this.props;
    return (
      <ThemeProvider theme={parentTheme => this.createTheme(parentTheme)}>
        {children}
      </ThemeProvider>
    );
  }
}
