import React, { useEffect, useRef, useImperativeHandle } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import {
  Heading,
  PasswordField,
  TextField,
  TextLink,
  useTheme,
} from '@techstyle/react-components';
import { Checkbox } from '@techstyle/react-components-legacy';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from '@techstyle/react-intl';
import { useCookies, useSession } from '@techstyle/redux-core';

import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  GUEST_SIGN_UP,
  GUEST_SIGN_IN,
  CHECKOUT_SIGN_IN,
  CHECKOUT_SIGN_UP,
} from '../../constants/signIn';
import { desktop, mobile } from '../../styles';
import { BasicButton } from '../../styles/button';
import { Paragraph } from '../../styles/paragraph';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as BirthdayInput } from '../BirthdayInput';
import { Component as EmailField } from '../EmailField';
import { Component as Toast } from '../LegacyToast';
import { Container as ReCAPTCHA } from '../ReCAPTCHA';
import { Component as ReferrerSelection } from '../ReferrerSelection';
import { Container as SignInContentHeadline } from '../SignInContentHeadline';
import { Component as StaticIcon } from '../StaticIcon';

const { backToCart, checkoutAsGuest, signUpAndCheckout } = defineMessages({
  backToCart: {
    id: 'global_checkout.back_to_cart',
    defaultMessage: 'Back to Cart',
  },
  checkoutAsGuest: {
    id: 'global_checkout.checkout_as_guest',
    defaultMessage: 'Checkout as Guest',
  },
  signUpAndCheckout: {
    id: 'global_checkout.sign_up_and_checkout',
    defaultMessage: 'Sign Up & Checkout',
  },
});

const isTestSecretRecaptchaNeeded = config.get(
  'public.recaptcha.automatedTestSecretsNeeded'
);

const isRecaptchaEnabled = config.get('public.recaptcha.enabled');

const signUpTag = config.get('public.googleTagManager.tags.signUpTag');
const guestSignUpTag = config.get(
  'public.googleTagManager.tags.guestSignUpTag'
);

/* =========================================== STYLES =========================================== */

const getHintStyle = (
  variant = 'default',
  showQuizSignUp = false,
  isPasswordField = false
) => css`
  ${({ theme }) => theme.textField.variants[variant].hintStyle}
  ${v1`
    min-height: 15px;
    margin-top: 5px;
  `}

  ${v2`
    margin-bottom: 12px;
    ${({ theme }) =>
      showQuizSignUp && (theme.textField.variants[variant].hintQuizStyle || '')}
    ${isPasswordField && 'text-transform: lowercase;'}
  `}
`;

const getInputBoxStyle = (variant = 'default', showQuizSignUp = false) => css`
  ${({ theme }) => theme.textField.variants[variant].inputBoxStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].inputBoxQuizStyle || '')}
  `}
  margin-top: 22px;
`;

const getInputStyle = (variant = 'default', showQuizSignUp = false) => css`
  ${({ theme }) => theme.textField.variants[variant].inputStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].inputQuizStyle || '')}
  `}
`;

const getLabelStyle = (variant = 'default', showQuizSignUp = false) => css`
  ${({ theme }) => theme.textField.variants[variant].labelStyle}
  ${v2`
    ${({ theme }) =>
      showQuizSignUp &&
      (theme.textField.variants[variant].labelQuizStyle || '')}
    &[data-label-position='floating'],
    &[data-label-position='inside'] {
      text-transform: lowercase;
    }
  `}
`;

const getErrorStyle = (variant = 'default') => css`
  ${({ theme }) => theme.textField.variants[variant].errorStyle}
  margin: 4px 0 0 0;
`;

const StyledTextField = styled(TextField).attrs(
  ({ variant, theme, showQuizSignUp }) =>
    theme?.themeVersion === 'v2'
      ? {
          errorStyle: getErrorStyle(variant),
          inputBoxStyle: getInputBoxStyle(variant, showQuizSignUp),
          inputStyle: getInputStyle(variant, showQuizSignUp),
          labelStyle: getLabelStyle(variant, showQuizSignUp),
          hintStyle: getHintStyle(variant, showQuizSignUp),
        }
      : {}
)``;

const StyledPasswordField = styled(PasswordField).attrs(
  ({ variant, theme, showQuizSignUp }) =>
    theme?.themeVersion === 'v2'
      ? {
          errorStyle: getErrorStyle(variant),
          inputBoxStyle: getInputBoxStyle(variant, showQuizSignUp),
          inputStyle: getInputStyle(variant, showQuizSignUp),
          labelStyle: getLabelStyle(variant, showQuizSignUp),
          hintStyle: getHintStyle(variant, showQuizSignUp, true),
        }
      : {
          hintStyle: getHintStyle(variant || 'default'),
        }
)`
  ${v1`
    margin-top: 22px;
    margin-bottom: 6px;
  `}
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: ${props => props.theme.colors.white};
  ${desktop`
    flex-wrap: nowrap;
    ${props => props.showSwitcher && `min-height: 620px;`}
  `};
`;

const Cell = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  padding: ${props =>
    props.inactive || props.showSwitcher ? props.theme.spacing.large : 0}px;
  padding-bottom: 16px;

  background-color: ${props =>
    props.inactive ? props.theme.colors.gray150 : props.theme.colors.white};

  ${desktop`
    order: ${props => props.order || 'initial'};
  `};
`;

const CenteredCell = styled(Cell)`
  align-self: center;
  flex: 0 1 400px;
  margin: auto;

  ${mobile`
    padding: ${props => props.theme.spacing.large}px
  `};

  ${v2`
    ${mobile`
      ${({ showQuizSignUp }) => showQuizSignUp && `padding-top: 0;`}
    `}
  `}
`;

const CellContent = styled.div`
  display: flex;
  flex-direction: column;

  ${desktop`
    width: 100%;
    max-width: 420px;
    ${props =>
      props.$hasExtraPadding &&
      css`
        padding-top: 74px;
      `}
  `}
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  ${props =>
    !props.$shouldFitSpace
      ? 'max-width: 800px;'
      : css`
          flex: 1;
          display: flex;
          flex-direction: column;

          ${Form} {
            flex: 1;
          }

          ${desktop`
            ${Cell} {
              align-items: center;
              padding-top: ${props => props.theme.spacing.moderate}px;
            }

            ${CenteredCell} {
              display: block;
              padding-top: 0;
            }
          `}
        `};
`;

const ToastContainer = styled(Container)``;

const FormField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CellFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledReCAPTCHA = styled(ReCAPTCHA)`
  margin: ${props => props.theme.spacing.medium}px auto 0;
`;

const RegistrationModalHeadline = styled(Paragraph)`
  text-align: center;
  ${v1`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
  `}
  ${v2`
    ${({ theme }) => theme.header.variants.h3.textStyles}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 12px;
  font-weight: 500;
`;

const StyledParagraphWithMarginBottom = styled(StyledParagraph)`
  margin-bottom: 12px;
  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp && `margin-top: ${theme.spacing.tiny}px;`}
  `}
`;

const FormTitle = styled(Heading).attrs(props => {
  return {
    level: 3,
  };
})`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: ${props =>
    props.marginBottom
      ? `${props.theme.spacing[props.marginBottom]}px`
      : '24px'};

  ${v2`
    ${props => props.theme.header.variants.h3.textStyles}
  `}
`;

const FormSubtitle = styled(Heading).attrs(props => {
  return {
    level: 4,
  };
})`
  font-size: 18px;
  margin-top: ${props => props.theme.spacing.large}px;
  ${v2`
        ${props =>
          props.theme.subHeader.variants.subHeading2Uppercase.textStyles}
  `}
`;

const AddPasswordInstructions = styled.div`
  margin-top: ${props => props.theme.spacing.tiny}px;
`;

const Instructions = styled.div`
  color: ${props => props.theme.colors.subdued};
  margin-top: ${props => props.theme.spacing.tiny}px;
`;

const NoPasswordResetInstructions = styled(Instructions)`
  text-align: center;
`;

const FormButton = styled(BasicButton).attrs(props => {
  return {
    fullWidth: true,
  };
})`
  margin: ${props =>
      props.shouldShowPrivacyNotice
        ? props.theme.spacing.tiny
        : props.theme.spacing.medium}px
    0;
`;

const SubmitButton = styled(FormButton).attrs(props => {
  return {
    type: 'submit',
  };
})`
  ${v2`
    ${({ theme, showQuizSignUp }) => {
      if (showQuizSignUp) {
        return css`
          ${theme.button.variants.primaryLavender.buttonStyle}
        `;
      }
    }}
    ${mobile`
      ${({ showQuizSignUp }) =>
        showQuizSignUp &&
        `
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100vw;
      `};
    `}
  `}
`;

const DoneButton = styled(SubmitButton)`
  margin-top: 24px;
`;

const SecondaryButton = styled(BasicButton).attrs(props => {
  return {
    fullWidth: true,
    secondary: true,
  };
})`
  margin-top: ${props => props.theme.spacing.large}px;
`;

const SubscribeToEmailWrapper = styled.div`
  ${props =>
    props.$includeTopMargin &&
    css`
      margin-top: ${props.theme.spacing.moderate}px;
    `}
`;

const CheckboxFormField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.spacing.tiny}px;

  ${Checkbox.Label} {
    // line-height adds padding to the top of the label and this resets it
    margin-top: -6px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 12px;
`;

const StyledCheckbox = styled(Checkbox)`
  & ${Checkbox.CustomCheckbox} {
    border-radius: 2px;
  }

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        input + ${Checkbox.CustomCheckbox} {
          border-radius: 0;
          border-width: 1px;
        }
        input:active + ${Checkbox.CustomCheckbox},
        input:focus + ${Checkbox.CustomCheckbox} {
          border-color: ${theme.colors.white};
        }
        input:checked + ${Checkbox.CustomCheckbox} {
          background-color: transparent;
          svg {
            fill: ${theme.colors.white};
            opacity: 1;
            transform: scale(1.6);
          }
        }
      `}
  `}
`;

const SubscribeCheckboxLabel = styled.span`
  font-size: 12px;
  position: relative;
  top: -5px;
`;

const AcceptPrivacyPolicy = styled(TextLink)`
  font-size: inherit;
  color: inherit;
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-out;
  color: ${props => props.theme.colors.active};
  display: inline-block;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.active};
    white-space: normal;
  }

  &:hover {
    &::after {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        color: ${theme.colors.white};
        font-weight: 400;
        &::after {
          background-color: ${theme.colors.white};
          width: 100%;
          height: 1px;
        }
    `}
  `}
`;

const PrivacyPolicyLink = styled(TextLink)`
  align-self: flex-end;
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.active};
  text-decoration: none;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.active};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }
  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        color: ${theme.colors.white};
        font-weight: 400;
        &::before {
          background-color: ${theme.colors.white};
          width: 100%;
          height: 1px;
        }
    `}
  `}
`;

const TermsConditionsLink = styled(PrivacyPolicyLink)``;

const LinkStyleButton = styled(PrivacyPolicyLink).attrs({ as: 'button' })``;

const PrivacyTermsConditionsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;

  @media (min-width: 321px) {
    font-size: 12px;
  }
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 14px;
  }
`;

const StyledPrivacyTermsConditionsBox = styled(PrivacyTermsConditionsBox)`
  font-weight: 500;

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp && `color: ${theme.colors.white};`}
  `}
`;

const InstructionsBottom = styled(Instructions)`
  display: flex;
  width: max-content;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'unset')};
  color: ${props => (props.fontColor ? props.fontColor : 'unset')};
`;

const InstructionsTop = styled(Instructions)``;

const StyledInstructionsBottom = styled(InstructionsBottom)`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.default};

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        color: ${theme.colors.white};
        width: 100%;
        justify-content: center;
      `}
  `}
`;

const PrivacyNoticeMessage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: ${props => props.theme.spacing.tiny}px;

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        color: ${theme.colors.white};
        width: 100%;
        text-align: center;
    `}
  `}
`;

export const linkStyles = css`
  ${v1`color: ${props => props.theme.colors.active};`}
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.active};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }
`;

const FormLink = styled(TextLink)`
  ${linkStyles}

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        color: ${theme.colors.white};
        font-weight: 400;
        &::before {
          background-color: ${theme.colors.white};
          width: 100%;
          height: 1px;
        }
    `};
      ${({ theme, showQuizSignUp }) =>
        !showQuizSignUp &&
        `
          color: ${theme.colors.lavender700};
          padding-bottom: 0;
          border-bottom: 1px solid ${theme.colors.lavender700};
        `}
    `}
`;

const ForgotPasswordLink = styled(FormLink)`
  float: right;
`;

const StyledAnchorsDiv = styled.div`
  a {
    ${linkStyles}
  }

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        p {
          text-align: center;
          font-weight: 400;
        }
        a {
          color: ${theme.colors.white};
          &::before {
            background-color: ${theme.colors.white};
            width: 100%;
            height: 1px;
          }
          strong {
            font-weight: 400;
          }
        }
      `}
  `}
`;

const BackToCartLink = styled.a`
  color: ${props => props.theme.colors.default};
  margin-bottom: 24px;
  ${desktop`
    margin-bottom: 44px;
  `}
`;

const BottomForgotPassword = styled(StyledParagraph)`
  font-weight: 600;

  ${ForgotPasswordLink} {
    float: unset;
  }
`;

const ProductionTestCaptcha = styled(TextField)``;

const StyledReferrerSelection = styled(ReferrerSelection)`
  margin-top: 15px;
`;

const BackToSignInLeftCaret = styled(StaticIcon).attrs({
  type: 'caret',
  size: 10,
})`
  transform: rotate(90deg);
  vertical-align: unset !important;
`;

const BackToSignIn = styled(Paragraph)`
  vertical-align: unset;

  ${v2`
    ${({ theme, showQuizSignUp }) =>
      showQuizSignUp &&
      `
        margin-top: ${theme.spacing.tiny}px;
      `}
    ${mobile`
      ${({ showQuizSignUp }) => showQuizSignUp && `margin-top: 32px;`}
    `}
  `}
`;

/* ======================================== END of STYLES ======================================== */

const scrollToForm = ref => {
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

const SignInContent = React.forwardRef((props, ref) => {
  const { inputVariant, showQuizSignUp } = props;
  const formRef = useRef();
  const nameRef = useRef();
  const intl = useIntl();
  const { themeVersion } = useTheme();

  useImperativeHandle(ref, () => {
    return {
      scrollAndFocus: () => {
        scrollToForm(formRef);
        nameRef.current.focus();
      },
    };
  });

  const captchaRef = useRef();
  const { isAdmin } = useSession();
  const [cookies] = useCookies(['automated_test']);
  const disableRecaptcha = !!cookies.automated_test;

  useEffect(() => {
    if (props.signInStatus.error && captchaRef.current) {
      captchaRef.current.reset();
    }
  }, [props.signInStatus]);

  const renderForgotPasswordLink = () => {
    const { onForgotPasswordLinkClick, toggleFormState } = props;
    const toggleForgotPassword = event => {
      if (onForgotPasswordLinkClick) {
        onForgotPasswordLinkClick();
      }
      toggleFormState(FORGOT_PASSWORD, event);
    };

    return (
      <ForgotPasswordLink onClick={toggleForgotPassword}>
        <FormattedMessage
          defaultMessage="Forgot Password?"
          id="global_checkout.forgot_password_hint"
        />
      </ForgotPasswordLink>
    );
  };

  const renderPasswordHint = (isPasswordLengthRestricted, errorMessage) => {
    const isSignInToggleState = [
      SIGN_IN,
      GUEST_SIGN_IN,
      CHECKOUT_SIGN_IN,
    ].includes(props.toggleState);
    const isSignUpToggleState = [
      SIGN_UP,
      GUEST_SIGN_UP,
      CHECKOUT_SIGN_UP,
    ].includes(props.toggleState);

    if (props.showForgotPasswordHint && isSignInToggleState) {
      return renderForgotPasswordLink();
    } else if (
      isSignUpToggleState &&
      !(!isPasswordLengthRestricted || errorMessage)
    ) {
      return (
        <FormattedMessage
          defaultMessage="6 Character Minimum"
          id="global_checkout.password_field_hint"
        />
      );
    }

    return null;
  };

  const renderPasswordField = ({ isPasswordLengthRestricted }) => {
    const { error, ...passwordFieldProps } = props.passwordField;

    let errorMessage;
    if (error === 'PASSWORD_REQUIRED') {
      errorMessage = (
        <FormattedMessage
          defaultMessage="Enter a password"
          id="global_checkout.password_validation_enter_a_password"
        />
      );
    } else if (error === 'PASSWORD_INVALID') {
      errorMessage = (
        <FormattedMessage
          defaultMessage="Invalid. Please correct and re-submit."
          id="global_checkout.password_validation_invalid_password"
        />
      );
    }

    return (
      <FormField paddingBottom="moderate">
        <StyledPasswordField
          autocomplete="current-password"
          label={
            <FormattedMessage
              defaultMessage="Password"
              id="global_checkout.password_field_label"
            />
          }
          name="password"
          error={errorMessage}
          {...passwordFieldProps}
          hint={renderPasswordHint(isPasswordLengthRestricted, errorMessage)}
          variant={inputVariant}
          showQuizSignUp={showQuizSignUp}
        />
      </FormField>
    );
  };

  const renderReferrerField = () => {
    const { referrerField } = props;
    return (
      <FormField
        data-autotag="referrer-field"
        data-qa-automation="howDidUHearAboutUsDD"
      >
        <StyledReferrerSelection order={2} {...referrerField} />
      </FormField>
    );
  };

  const renderZipCodeField = () => {
    const { zipCodeField } = props;
    const { error, ...zipCodeProps } = zipCodeField;

    return (
      <FormField>
        <StyledTextField
          {...zipCodeProps}
          name="zip"
          autoComplete="postal-code"
          label={
            <FormattedMessage
              defaultMessage="Zip code"
              id="global_checkout.quiz_zip_code_label"
            />
          }
          error={error}
          variant={inputVariant}
          showQuizSignUp={showQuizSignUp}
        />
      </FormField>
    );
  };

  const renderBirthdateField = () => {
    const { birthdateField } = props;
    const { error, ...birthdateProps } = birthdateField;
    return (
      <FormField>
        <BirthdayInput
          {...birthdateProps}
          minimumAge={18}
          hint={
            <FormattedMessage
              defaultMessage="(optional)"
              id="global_checkout.quiz_optional_label"
            />
          }
        />
      </FormField>
    );
  };

  const renderEmailField = () => {
    const { emailField, signUpStatus, signInStatus } = props;
    const { error, ...emailFieldProps } = emailField;

    let errorMessage;
    if (error) {
      errorMessage = error;
    } else {
      errorMessage = signInStatus.error || signUpStatus.error;
    }

    return (
      <FormField>
        <EmailField
          error={errorMessage}
          dataAutotag="pii-reg-email-fld"
          variant={inputVariant}
          showQuizSignUp={showQuizSignUp}
          {...emailFieldProps}
        />
      </FormField>
    );
  };

  const renderNameField = ({ dataAutotag }) => {
    const { error, ...firstNameFieldProps } = props.firstNameField;
    let errorMessage;

    if (error) {
      errorMessage = (
        <FormattedMessage
          id="site_account_billship.error_required_first_name"
          defaultMessage="Enter a first name"
        />
      );
    }
    return (
      <FormField>
        <StyledTextField
          name="name"
          ref={nameRef}
          autoComplete="given-name"
          fullWidth
          label={
            <FormattedMessage
              defaultMessage="First name"
              id="site_account_billship.ship_form_first_name_label"
            />
          }
          error={errorMessage}
          dataAutotag={dataAutotag}
          variant={inputVariant}
          showQuizSignUp={showQuizSignUp}
          {...firstNameFieldProps}
        />
      </FormField>
    );
  };

  const renderTermsAndConditions = () => {
    const { shouldShowPrivacyNotice, termsAndConditionsField } = props;
    const {
      show,
      error,
      checked,
      openTermsAndConditions,
      openPrivacyPolicy,
      onChange,
    } = termsAndConditionsField;

    if (show && !shouldShowPrivacyNotice) {
      const termsConditionsError = error != null;
      return (
        <CheckboxFormField>
          <Checkbox
            name="terms"
            size="large"
            verticalAlign="flex-start"
            data-autotag="terms-and-conditions"
            label={
              <CheckboxLabel>
                <FormattedMessage
                  defaultMessage="I agree to the {terms} and {privacy}."
                  id="the_savages.terms_conditions_i_agree"
                  values={{
                    terms: (
                      <AcceptPrivacyPolicy
                        onClick={openTermsAndConditions}
                        href="/termsandconditions"
                        showQuizSignUp={showQuizSignUp}
                      >
                        <FormattedMessage
                          defaultMessage="Terms and Conditions"
                          id="global_checkout.terms_conditions"
                        />
                      </AcceptPrivacyPolicy>
                    ),
                    privacy: (
                      <AcceptPrivacyPolicy
                        onClick={openPrivacyPolicy}
                        href="/privacypolicy"
                        showQuizSignUp={showQuizSignUp}
                      >
                        <FormattedMessage
                          defaultMessage="Privacy Policy"
                          id="global_checkout.privacy_policy"
                        />
                      </AcceptPrivacyPolicy>
                    ),
                  }}
                />
              </CheckboxLabel>
            }
            checked={checked}
            error={termsConditionsError}
            onChange={onChange}
          />
        </CheckboxFormField>
      );
    }

    return null;
  };

  const renderPrivacyPolicyLink = () => {
    const content = (
      <FormattedMessage
        defaultMessage="View our {privacy}"
        id="the_savages.view_privacy_notice_message"
        values={{
          privacy: (
            <PrivacyPolicyLink
              href="/privacypolicy"
              target="_blank"
              data-qa-automation="regPrivacyPolicyLink"
              showQuizSignUp={showQuizSignUp}
            >
              <FormattedMessage
                defaultMessage="Privacy Policy"
                id="global_checkout.privacy_policy"
              />
            </PrivacyPolicyLink>
          ),
        }}
      />
    );

    return props.shouldShowAlternateModal ? (
      <StyledInstructionsBottom>{content}</StyledInstructionsBottom>
    ) : (
      <InstructionsBottom>{content}</InstructionsBottom>
    );
  };

  const renderTermsConditionsLink = shouldUseAnd => {
    const content = (
      <>
        {shouldUseAnd ? 'and' : '&'}&nbsp;
        <TermsConditionsLink
          href="/termsandconditions"
          target="_blank"
          data-qa-automation="regTermsLink"
          showQuizSignUp={showQuizSignUp}
        >
          <FormattedMessage
            defaultMessage="Terms and Conditions"
            id="global_checkout.terms_conditions"
          />
        </TermsConditionsLink>
      </>
    );

    return props.shouldShowAlternateModal ? (
      <StyledInstructionsBottom>{content}</StyledInstructionsBottom>
    ) : (
      <InstructionsBottom>{content}</InstructionsBottom>
    );
  };
  // ABTest SXFRR-359 remove shouldShowPrivacyNotice after abtest
  const renderPrivacyNoticeMessage = () => {
    const { openPrivacyPolicy } = props.termsAndConditionsField;
    const privacyNotice = props.privacyNotice;

    return (
      <PrivacyNoticeMessage showQuizSignUp={showQuizSignUp}>
        {privacyNotice ? (
          <StyledAnchorsDiv
            dangerouslySetInnerHTML={{ __html: privacyNotice }}
            showQuizSignUp={showQuizSignUp}
          />
        ) : (
          <FormattedMessage
            defaultMessage="I confirm that I have read and accept the {privacy}."
            id="the_savages.privacy_notice_message"
            values={{
              privacy: (
                <AcceptPrivacyPolicy
                  onClick={openPrivacyPolicy}
                  href="/privacypolicy"
                  showQuizSignUp={showQuizSignUp}
                >
                  <FormattedMessage
                    defaultMessage="Privacy Notice"
                    id="global_checkout.privacy_notice"
                  />
                </AcceptPrivacyPolicy>
              ),
            }}
          />
        )}
      </PrivacyNoticeMessage>
    );
  };

  const renderSubscribeToEmails = (includeTopMargin = false) => {
    const { show, onChange, checked } = props.subscribeToEmailsField;

    if (!show) {
      return null;
    }

    const showWhiteLabel = themeVersion === 'v2' && showQuizSignUp;
    return (
      <SubscribeToEmailWrapper $includeTopMargin={includeTopMargin}>
        <CheckboxFormField>
          <StyledCheckbox
            name="subscribeToEmails"
            showQuizSignUp={showQuizSignUp}
            size="large"
            verticalAlign="flex-start"
            white={showWhiteLabel}
            label={
              <SubscribeCheckboxLabel>
                {props.subscribeToEmailsLabel || (
                  <FormattedMessage
                    id="global_login.subscribe_to_emails"
                    defaultMessage="Yes, I want to receive emails from Savage X (optional)"
                  />
                )}
              </SubscribeCheckboxLabel>
            }
            checked={checked}
            onChange={onChange}
          />
        </CheckboxFormField>
      </SubscribeToEmailWrapper>
    );
  };

  const renderSignUp = () => {
    const {
      emailField,
      exitIdleModal,
      shouldEnableSubmitBtn,
      showNameField,
      passwordField,
      referrerField,
      termsAndConditionsField,
      handleSignUp,
      showSwitcher,
      toggleFormState,
      signUpLabel,
      registrationModal,
      onSignInClick,
      showReferrerField,
      referrerOptions,
      shouldShowPrivacyNotice,
      shouldShowPrivacyTermsBeforeCTA,
      showBottomTermsConditionsLink,
      showBottomPrivacyLink,
      showQuizSignUp,
      showSwitchToSignInLink,
      shouldShowAlternateModal,
      location,
      zipCodeField,
      birthdateField,
    } = props;

    const hasReferrerOptionsLength = referrerOptions.length;

    const toggleSignIn = event => {
      if (onSignInClick) {
        onSignInClick();
      }
      toggleFormState(SIGN_IN, event);
    };

    const referrerError = !!(!referrerField.value || referrerField.error);
    const emailError = !!(!emailField.value || emailField.error);
    const zipCodeError = !!(!zipCodeField.value || zipCodeField.error);
    const passwordValueError = !!(
      !passwordField.value || passwordField.value.length < 6
    );
    const passwordError = !!(passwordValueError || passwordField.error);

    const termsConditionsError = !!(
      !termsAndConditionsField.checked || termsAndConditionsField.error
    );
    const {
      validationResult: { birthDay, birthMonth, birthYear },
    } = birthdateField;
    const birthdateError = !!(
      (birthDay && birthDay.error) ||
      (birthMonth && birthMonth.error) ||
      (birthYear && birthYear.error)
    );

    const shouldDisableReferrer =
      showReferrerField && hasReferrerOptionsLength && referrerError;
    const shouldDisableTermsConditions =
      termsAndConditionsField.show &&
      !shouldShowPrivacyNotice &&
      termsConditionsError;
    const shouldDisableZipCode = zipCodeField.show && zipCodeError;
    const shouldDisableBirthDate = birthdateField.show && birthdateError;
    const shouldShowSignInAndPrivacyNoticeBeforeSubmit =
      themeVersion === 'v2' && showQuizSignUp;

    const disableButton =
      emailError ||
      passwordError ||
      shouldDisableReferrer ||
      shouldDisableTermsConditions ||
      shouldDisableZipCode ||
      shouldDisableBirthDate;

    // If QwapQuizUpdates was enabled and quiz was not skipped,
    // use the recommendations RB. Otherwise, use the default RB.
    const completeQuizText = intl.formatMessage({
      defaultMessage: 'Confirm & Complete',
      id: 'global_checkout.confirm_and_complete_button',
    });

    return (
      <Form
        onSubmit={handleSignUp}
        ref={formRef}
        showSwitcher={showSwitcher}
        data-autotag="sign-up-form"
        data-qa-automation="signUpForm"
      >
        <Cell order={2} showSwitcher={showSwitcher}>
          {location ? <SignInContentHeadline location={location} /> : null}
          {registrationModal ? (
            <RegistrationModalHeadline>
              <FormattedMessage
                defaultMessage="Create an account to access your new Xclusive Member Offer!"
                id="global_checkout.registration_sign_in_header"
              />
            </RegistrationModalHeadline>
          ) : null}
          {exitIdleModal ? (
            <RegistrationModalHeadline>
              <FormattedMessage
                defaultMessage="Dont go, Create an account now!"
                id="global_checkout.exit_sign_in_headr"
              />
            </RegistrationModalHeadline>
          ) : null}
          {showSwitcher ? (
            <>
              <FormTitle data-qa-automation="newHereText">
                <FormattedMessage
                  defaultMessage="New Here?"
                  id="global_checkout.new_here_title"
                />
              </FormTitle>
              <FormSubtitle data-qa-automation="createAccountText">
                <FormattedMessage
                  defaultMessage="Create an account"
                  id="global_checkout.create_an_account"
                />
              </FormSubtitle>
              <Instructions>
                <FormattedMessage
                  defaultMessage="Sign up for secure checkout and easy order tracking"
                  id="global_checkout.create_account_instructions"
                />
              </Instructions>
            </>
          ) : null}
          {showNameField
            ? renderNameField({ dataAutotag: 'pii-reg-first-name-fld' })
            : null}
          {renderEmailField()}
          {props.showPasswordField &&
            renderPasswordField({
              dataAutotag: 'reg-password-fld',
              isPasswordLengthRestricted: true,
            })}
          {zipCodeField.show ? renderZipCodeField() : null}
          {showReferrerField ? renderReferrerField() : null}
          {birthdateField.show ? renderBirthdateField() : null}
          {renderSubscribeToEmails()}
          {renderTermsAndConditions()}
          {!shouldShowSignInAndPrivacyNoticeBeforeSubmit &&
          shouldShowPrivacyNotice
            ? renderPrivacyNoticeMessage()
            : null}
          {shouldShowPrivacyTermsBeforeCTA && !shouldShowPrivacyNotice ? (
            <PrivacyTermsConditionsBox>
              {showBottomPrivacyLink ? renderPrivacyPolicyLink() : null}
              {showBottomTermsConditionsLink
                ? renderTermsConditionsLink()
                : null}
            </PrivacyTermsConditionsBox>
          ) : null}
          {/* show sign in link before complete button on new theme */}
          {shouldShowSignInAndPrivacyNoticeBeforeSubmit &&
          (registrationModal ||
            shouldShowAlternateModal ||
            showQuizSignUp ||
            showSwitchToSignInLink) ? (
            <StyledInstructionsBottom showQuizSignUp={showQuizSignUp}>
              {props.haveAnAccountMessage ? (
                <StyledAnchorsDiv
                  showQuizSignUp={showQuizSignUp}
                  dangerouslySetInnerHTML={{
                    __html: props.haveAnAccountMessage,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Already have an account?"
                  id="global_checkout.registration_modal_already_have_account"
                />
              )}
              &nbsp;
              <FormLink
                showQuizSignUp={showQuizSignUp}
                onClick={toggleSignIn}
                data-autotag="loggedout-popup-signin-link"
                data-qa-automation="signInCreateAccountLink"
              >
                <FormattedMessage
                  defaultMessage="Sign In"
                  id="global_checkout.registration_modal_sign_in_title"
                />
              </FormLink>
              .
            </StyledInstructionsBottom>
          ) : null}
          <SubmitButton
            data-gtm={signUpTag}
            data-autotag="reg-continue-btn"
            aria-label="Create an account"
            disabled={shouldEnableSubmitBtn ? false : disableButton}
            shouldShowPrivacyNotice={shouldShowPrivacyNotice}
            showQuizSignUp={showQuizSignUp}
          >
            {shouldShowPrivacyNotice
              ? completeQuizText
              : signUpLabel || (
                  <FormattedMessage
                    defaultMessage="Create Account"
                    id="global_checkout.create_account_buttons"
                  />
                )}
          </SubmitButton>
          {shouldShowSignInAndPrivacyNoticeBeforeSubmit &&
          shouldShowPrivacyNotice
            ? renderPrivacyNoticeMessage()
            : null}
          <CellFooter>
            {!shouldShowAlternateModal &&
            !shouldShowPrivacyTermsBeforeCTA &&
            !showQuizSignUp ? (
              <PrivacyTermsConditionsBox>
                {showBottomPrivacyLink ? renderPrivacyPolicyLink() : null}
                {showBottomTermsConditionsLink
                  ? renderTermsConditionsLink()
                  : null}
              </PrivacyTermsConditionsBox>
            ) : null}
            {!shouldShowSignInAndPrivacyNoticeBeforeSubmit &&
            (registrationModal ||
              shouldShowAlternateModal ||
              showQuizSignUp ||
              showSwitchToSignInLink) ? (
              <StyledInstructionsBottom showQuizSignUp={showQuizSignUp}>
                {props.haveAnAccountMessage ? (
                  <StyledAnchorsDiv
                    dangerouslySetInnerHTML={{
                      __html: props.haveAnAccountMessage,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Already have an account?"
                    id="global_checkout.registration_modal_already_have_account"
                  />
                )}
                &nbsp;
                <FormLink
                  onClick={toggleSignIn}
                  data-autotag="loggedout-popup-signin-link"
                  data-qa-automation="signInCreateAccountLink"
                >
                  <FormattedMessage
                    defaultMessage="Sign In"
                    id="global_checkout.registration_modal_sign_in_title"
                  />
                </FormLink>
                .
              </StyledInstructionsBottom>
            ) : null}
            {shouldShowAlternateModal || showQuizSignUp ? (
              <StyledPrivacyTermsConditionsBox showQuizSignUp={showQuizSignUp}>
                {props.terms && (
                  <StyledAnchorsDiv
                    dangerouslySetInnerHTML={{ __html: props.terms }}
                    showQuizSignUp={showQuizSignUp}
                  />
                )}
                {!props.terms && showBottomPrivacyLink
                  ? renderPrivacyPolicyLink()
                  : null}
                {!props.terms && showBottomTermsConditionsLink
                  ? renderTermsConditionsLink(true)
                  : null}
              </StyledPrivacyTermsConditionsBox>
            ) : null}
          </CellFooter>
        </Cell>
        {showSwitcher ? (
          <Cell inactive>
            <FormTitle>
              <FormattedMessage
                defaultMessage="Already have an account?"
                id="global_checkout.already_have_account"
              />
            </FormTitle>
            <FormSubtitle>
              <FormattedMessage
                defaultMessage="Sign In"
                id="global_checkout.sign_in_title"
              />
            </FormSubtitle>
            <Instructions>
              <FormattedMessage
                defaultMessage="Sign into your account for easy checkout"
                id="global_checkout.sign_in_instructions"
              />
            </Instructions>
            <Paragraph>
              <SecondaryButton
                onClick={toggleSignIn}
                data-autotag="toggle-signin-account-button"
              >
                <FormattedMessage
                  defaultMessage="SIGN IN"
                  id="global_checkout.sign_in_button"
                />
              </SecondaryButton>
            </Paragraph>
          </Cell>
        ) : null}
      </Form>
    );
  };

  function signInPasswordHasError(
    passwordField,
    loginReCaptchaField,
    notAdminNotDisableRecaptcha
  ) {
    return (
      !passwordField.value ||
      passwordField.error ||
      (!loginReCaptchaField.value && notAdminNotDisableRecaptcha) ||
      (loginReCaptchaField.error && notAdminNotDisableRecaptcha)
    );
  }

  function determineSignInDisableButton() {
    const { checkEmail, emailField, passwordField, loginReCaptchaField } =
      props;

    const notAdminNotDisableRecaptcha =
      isRecaptchaEnabled && !isAdmin && !disableRecaptcha;
    const hasEmailError = checkEmail({ emailField });

    const hasPasswordError = signInPasswordHasError(
      passwordField,
      loginReCaptchaField,
      notAdminNotDisableRecaptcha
    );

    return hasEmailError || hasPasswordError;
  }

  const renderSignIn = () => {
    const {
      handleSignIn,
      location,
      loginReCaptchaField,
      onForgotPasswordLinkClick,
      onSignUpClick,
      registrationModal,
      showQuizSignUp,
      showSwitcher,
      shouldShowAlternateModal,
      shouldShowFooterForgotPasswordLink,
      toggleFormState,
    } = props;

    const shouldShowDefaultSignInHeadline = !location;

    const recaptchaField =
      isRecaptchaEnabled && !isAdmin && !disableRecaptcha ? (
        <StyledReCAPTCHA
          render="explicit"
          captchaRef={captchaRef}
          onChange={responseToken => {
            loginReCaptchaField.onChange(responseToken || null);
          }}
        />
      ) : null;

    const emailField = renderEmailField();

    const passwordField = renderPasswordField({
      dataAutotag: 'loginmodal-password-fld',
      isPasswordLengthRestricted: false,
    });

    const toggleSignUp = event => {
      if (onSignUpClick) {
        onSignUpClick();
      }
      toggleFormState(SIGN_UP, event);
    };

    const toggleForgotPassword = event => {
      if (onForgotPasswordLinkClick) {
        onForgotPasswordLinkClick();
      }
      toggleFormState(FORGOT_PASSWORD, event);
    };

    const disableButton = determineSignInDisableButton();

    return (
      <Form
        onSubmit={handleSignIn}
        showSwitcher={showSwitcher}
        data-autotag="sign-in-form"
      >
        <Cell showSwitcher={showSwitcher}>
          {location ? (
            <SignInContentHeadline location={location} shouldShowSignInTitles />
          ) : null}
          {shouldShowDefaultSignInHeadline && !showQuizSignUp ? (
            <>
              <FormTitle data-qa-automation="alreadyHaveAnAccountText">
                <FormattedMessage
                  defaultMessage="Already have an account?"
                  id="global_checkout.already_have_account"
                />
              </FormTitle>
              <FormSubtitle
                data-autotag="signin-open-message"
                data-qa-automation="signInText"
              >
                <FormattedMessage
                  defaultMessage="Sign In"
                  id="global_checkout.sign_in_title"
                />
              </FormSubtitle>
              <InstructionsTop>
                <FormattedMessage
                  defaultMessage="Sign into your account for easy checkout"
                  id="global_checkout.sign_in_instructions"
                />
              </InstructionsTop>
            </>
          ) : null}
          {emailField}
          {passwordField}
          {recaptchaField}
          {!!cookies.automated_test && isTestSecretRecaptchaNeeded ? (
            <ProductionTestCaptcha
              onChange={loginReCaptchaField.onChange}
              data-autotag="production-test-captcha"
            />
          ) : null}
          <SubmitButton
            data-autotag="loginmodal-btn"
            disabled={disableButton}
            aria-label="Sign In"
            showQuizSignUp={showQuizSignUp}
          >
            <FormattedMessage
              defaultMessage="SIGN IN"
              id="global_checkout.sign_in_button"
            />
          </SubmitButton>
          {shouldShowAlternateModal || showQuizSignUp ? (
            <StyledParagraphWithMarginBottom showQuizSignUp={showQuizSignUp}>
              <FormLink
                onClick={toggleForgotPassword}
                data-autotag="forgot-password-link"
                showQuizSignUp={showQuizSignUp}
              >
                <FormattedMessage
                  defaultMessage="Forgot password?"
                  id="global_checkout.forgot_password_link"
                />
              </FormLink>
            </StyledParagraphWithMarginBottom>
          ) : null}
          {registrationModal || shouldShowAlternateModal || showQuizSignUp ? (
            <>
              {shouldShowFooterForgotPasswordLink ? (
                <BottomForgotPassword>
                  {renderForgotPasswordLink()}
                </BottomForgotPassword>
              ) : null}
              <StyledInstructionsBottom showQuizSignUp={showQuizSignUp}>
                <FormattedMessage
                  defaultMessage="New Here?"
                  id="global_checkout.registration_modal_new_here_title"
                />
                &nbsp;
                <FormLink
                  onClick={toggleSignUp}
                  showQuizSignUp={showQuizSignUp}
                >
                  <FormattedMessage
                    defaultMessage="Sign Up"
                    id="global_checkout.registration_modal_create_an_account"
                  />
                </FormLink>
                .
              </StyledInstructionsBottom>
            </>
          ) : (
            <Paragraph align={themeVersion === 'v2' ? 'left' : 'center'}>
              <FormLink
                onClick={toggleForgotPassword}
                data-autotag="forgot-password-link"
              >
                <FormattedMessage
                  defaultMessage="Forgot password?"
                  id="global_checkout.forgot_password_link"
                />
              </FormLink>
            </Paragraph>
          )}
        </Cell>
        {showSwitcher ? (
          <Cell inactive>
            <FormTitle>
              <FormattedMessage
                defaultMessage="New Here?"
                id="global_checkout.new_here_title"
              />
            </FormTitle>
            <FormSubtitle>
              <FormattedMessage
                defaultMessage="Create an account"
                id="global_checkout.create_an_account"
                data-autotag="create-account-button"
              />
            </FormSubtitle>
            <Instructions>
              <FormattedMessage
                defaultMessage="Sign up for secure checkout and easy order tracking"
                id="global_checkout.create_account_instructions"
              />
            </Instructions>
            <SecondaryButton
              onClick={toggleSignUp}
              data-autotag="create-account-button"
              aria-label="Create an account"
            >
              <FormattedMessage
                defaultMessage="Create an account"
                id="global_checkout.create_account_link"
              />
            </SecondaryButton>
          </Cell>
        ) : null}
      </Form>
    );
  };

  const handleBackToSignIn = event => {
    event.preventDefault();
    const {
      forgotPasswordReCaptchaField,
      loginReCaptchaField,
      onSignInClick,
      toggleFormState,
      signInScreen,
    } = props;
    toggleFormState(signInScreen, event);
    forgotPasswordReCaptchaField.onChange(null);
    loginReCaptchaField.onChange(null);
    onSignInClick && onSignInClick();
  };

  const renderForgotPassword = () => {
    const {
      emailField,
      forgotPasswordStatus,
      forgotPasswordReCaptchaField,
      handleForgotPassword,
      showQuizSignUp,
      showSwitcher,
      sizeFilterSignUp,
    } = props;

    const disableButton =
      !emailField.value ||
      (!forgotPasswordReCaptchaField.value &&
        isRecaptchaEnabled &&
        !isAdmin &&
        !disableRecaptcha) ||
      emailField.Error ||
      (forgotPasswordReCaptchaField.error &&
        isRecaptchaEnabled &&
        !isAdmin &&
        !disableRecaptcha);
    const body = forgotPasswordStatus.success ? (
      <Instructions>
        <FormattedMessage
          defaultMessage="Check your email at {email} and follow the instructions."
          id="global_checkout.forgot_password_confirmation_instructions"
          values={{
            email: emailField.value,
          }}
        />
      </Instructions>
    ) : (
      <>
        {!showQuizSignUp && (
          <NoPasswordResetInstructions>
            <FormattedMessage
              defaultMessage="Enter your email below to reset your password."
              id="global_checkout.forgot_password_instructions"
            />
          </NoPasswordResetInstructions>
        )}
        {renderEmailField()}
        {isRecaptchaEnabled && !isAdmin && !disableRecaptcha ? (
          <StyledReCAPTCHA
            render="explicit"
            onChange={responseToken => {
              forgotPasswordReCaptchaField.onChange(responseToken || '');
            }}
          />
        ) : null}
        {!!cookies.automated_test && isTestSecretRecaptchaNeeded ? (
          <ProductionTestCaptcha
            onChange={forgotPasswordReCaptchaField.onChange}
            data-autotag="production-test-captcha"
          />
        ) : null}
        <SubmitButton
          disabled={disableButton}
          data-autotag="reset-password"
          showQuizSignUp={showQuizSignUp}
        >
          <FormattedMessage
            defaultMessage="Reset password"
            id="global_checkout.forgot_password_button"
          />
        </SubmitButton>
        <BackToSignIn
          align={themeVersion === 'v2' ? 'left' : 'center'}
          showQuizSignUp={showQuizSignUp}
        >
          <FormLink
            onClick={handleBackToSignIn}
            showQuizSignUp={showQuizSignUp}
          >
            {themeVersion !== 'v2' ||
              (showQuizSignUp && (
                <>
                  <BackToSignInLeftCaret />
                  &nbsp;
                </>
              ))}
            <FormattedMessage
              defaultMessage="Back to sign in"
              id="global_checkout.back_to_sign_in_link"
            />
          </FormLink>
        </BackToSignIn>
      </>
    );

    return (
      <Form
        onSubmit={handleForgotPassword}
        showSwitcher={showSwitcher}
        data-autotag="forgot-modal"
      >
        <CenteredCell showQuizSignUp={showQuizSignUp}>
          <FormTitle
            flow={props.signInScreen}
            marginBottom="tiny"
            align="center"
          >
            {forgotPasswordStatus.success ? (
              <FormattedMessage
                defaultMessage="Email Sent"
                id="global_checkout.reset_status_email_sent"
              />
            ) : (
              <>
                {!showQuizSignUp && (
                  <FormattedMessage
                    defaultMessage="Forgot Password"
                    id="global_checkout.reset_status_forgot_password"
                  />
                )}
              </>
            )}
          </FormTitle>
          {body}
          {forgotPasswordStatus.success ? (
            <DoneButton
              onClick={
                showQuizSignUp || sizeFilterSignUp
                  ? e => props.toggleFormState('SIGN_IN', e)
                  : props.onResetSuccess
              }
              data-autotag="done-reset-password"
            >
              <FormattedMessage
                defaultMessage="Done"
                id="global_checkout.done"
              />
            </DoneButton>
          ) : null}
        </CenteredCell>
      </Form>
    );
  };

  const renderResetPassword = () => {
    const {
      passwordField,
      passwordVerifyField,
      handleBackToResetForm,
      handleResetPassword,
      resetPasswordStatus,
      disabled,
    } = props;

    const disableButton =
      passwordField.value.length === 0 ||
      passwordVerifyField.value.length === 0 ||
      !!passwordField.error ||
      (!!passwordVerifyField.error &&
        passwordVerifyField.value !== passwordField.value) ||
      disabled;

    let passwordErrorMessage;
    if (passwordField.error === 'PASSWORD_REQUIRED') {
      passwordErrorMessage = (
        <FormattedMessage
          defaultMessage="Enter a password"
          id="global_checkout.reset_password_form_val_enter_password"
        />
      );
    }

    let passwordVerifyErrorMessage;
    if (passwordVerifyField.error === 'PASSWORD_VERIFY_REQUIRED') {
      passwordVerifyErrorMessage = (
        <FormattedMessage
          defaultMessage="Enter password again"
          id="global_checkout.reset_password_form_val_enter_password_again"
        />
      );
    } else if (
      passwordVerifyField.error === 'PASSWORD_VERIFY_MATCH' &&
      passwordVerifyField.value !== passwordField.value
    ) {
      passwordVerifyErrorMessage = (
        <FormattedMessage
          defaultMessage="Passwords must match"
          id="global_checkout.reset_password_form_val_password_match_error"
        />
      );
    }

    let body;

    if (resetPasswordStatus.success) {
      body = (
        <Paragraph data-autotag="reset-password-info-sent">
          <FormattedMessage
            defaultMessage="Great, you’ve successfully updated your account."
            id="global_checkout.reset_password_success"
          />
        </Paragraph>
      );
    } else if (resetPasswordStatus.error) {
      body = (
        <>
          <Paragraph data-autotag="reset-password-info-sent">
            {resetPasswordStatus.error}
          </Paragraph>
          <Paragraph>
            <TextLink tagName="button" onClick={handleBackToResetForm}>
              <FormattedMessage
                defaultMessage="Back"
                id="site_navigation.back"
              />
            </TextLink>
          </Paragraph>
        </>
      );
    } else {
      body = (
        <>
          <FormTitle marginBottom="tiny" align="center">
            <FormattedMessage
              defaultMessage="Reset Password"
              id="global_checkout.reset_password_title"
            />
          </FormTitle>
          <Instructions align="center">
            <FormattedMessage
              defaultMessage="Enter a new password"
              id="global_checkout.reset_password_instructions"
            />
          </Instructions>

          <FormField>
            <StyledPasswordField
              {...passwordField}
              error={passwordErrorMessage}
              autocomplete="new-password"
              label={
                <FormattedMessage
                  defaultMessage="Password"
                  id="global_checkout.reset_password_field_label"
                />
              }
              name="password"
              data-autotag="dataAutotag"
            />
          </FormField>
          <FormField>
            <StyledPasswordField
              {...passwordVerifyField}
              error={passwordVerifyErrorMessage}
              autocomplete="new-password"
              label={
                <FormattedMessage
                  defaultMessage="Verify Password"
                  id="global_checkout.reset_password_verify_field_label"
                />
              }
              name="verify-password"
              data-autotag="dataAutotag"
            />
          </FormField>

          <SubmitButton
            data-gtm="signUpTag"
            data-autotag="reg-continue-btn"
            disabled={disableButton}
          >
            <FormattedMessage
              defaultMessage="Reset Password"
              id="global_checkout.reset_password_button"
            />
          </SubmitButton>
        </>
      );
    }

    return (
      <Form onSubmit={handleResetPassword}>
        <CenteredCell>{body}</CenteredCell>
      </Form>
    );
  };

  const renderCheckoutSignIn = (signInFlow = GUEST_SIGN_IN) => {
    const {
      handleSignIn,
      location,
      loginReCaptchaField,
      onForgotPasswordLinkClick,
      onSignUpClick,
      registrationModal,
      showQuizSignUp,
      showSwitcher,
      shouldShowAlternateModal,
      shouldShowFooterForgotPasswordLink,
      toggleFormState,
    } = props;

    const shouldShowDefaultSignInHeadline = !location;
    const toggledForm =
      signInFlow === CHECKOUT_SIGN_IN ? CHECKOUT_SIGN_UP : GUEST_SIGN_UP;
    const accountRequired = signInFlow === CHECKOUT_SIGN_IN;

    const recaptchaField =
      isRecaptchaEnabled && !isAdmin && !disableRecaptcha ? (
        <StyledReCAPTCHA
          render="explicit"
          captchaRef={captchaRef}
          onChange={responseToken => {
            loginReCaptchaField.onChange(responseToken || null);
          }}
        />
      ) : null;

    const emailField = renderEmailField();

    const passwordField = renderPasswordField({
      dataAutotag: 'loginmodal-password-fld',
      isPasswordLengthRestricted: false,
    });

    const toggleCheckoutSignUp = event => {
      if (onSignUpClick) {
        onSignUpClick();
      }
      toggleFormState(toggledForm, event);
    };

    const toggleForgotPassword = event => {
      if (onForgotPasswordLinkClick) {
        onForgotPasswordLinkClick();
      }
      toggleFormState(FORGOT_PASSWORD, event);
    };

    const disableButton = determineSignInDisableButton();

    return (
      <Form
        onSubmit={handleSignIn}
        showSwitcher={showSwitcher}
        data-autotag="sign-in-form"
      >
        {showSwitcher ? (
          <Cell inactive order={1}>
            <CellContent>
              <Link href="/cart">
                <BackToCartLink>
                  <FaArrowLeft
                    size={24}
                    title={intl.formatMessage(backToCart)}
                  />
                </BackToCartLink>
              </Link>
              <FormTitle flow={signInFlow} marginBottom="none">
                {accountRequired ? (
                  <FormattedMessage
                    defaultMessage="Check out now"
                    id="global_checkout.member_checkout_title"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Checkout As Guest"
                    id="global_checkout.guest_checkout_title"
                  />
                )}
              </FormTitle>
              <FormSubtitle flow={signInFlow}>
                <FormattedMessage
                  defaultMessage="Let's Get Started"
                  id="global_checkout.guest_checkout_subtitle"
                  data-autotag="guest-create-account-button"
                />
              </FormSubtitle>
              <Instructions>
                {accountRequired ? (
                  <FormattedMessage
                    defaultMessage="Enter your email and password to secure your membership and stay up to date on your order."
                    id="global_checkout.member_checkout_instructions"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Enter your email to stay up to date on your order."
                    id="global_checkout.guest_checkout_instructions"
                  />
                )}
              </Instructions>
              <SecondaryButton
                onClick={toggleCheckoutSignUp}
                data-autotag="guest-create-account-button"
                aria-label="Checkout As Guest"
              >
                <FormattedMessage
                  defaultMessage="Continue"
                  id="global_checkout.guest_create_account_link"
                />
              </SecondaryButton>
            </CellContent>
          </Cell>
        ) : null}
        <Cell showSwitcher={showSwitcher} order={2}>
          <CellContent $hasExtraPadding>
            {location ? (
              <SignInContentHeadline
                location={location}
                shouldShowSignInTitles
              />
            ) : null}
            {shouldShowDefaultSignInHeadline && !showQuizSignUp ? (
              <>
                <FormTitle
                  data-qa-automation="alreadyHaveAnAccountText"
                  marginBottom="none"
                  flow={signInFlow}
                >
                  <FormattedMessage
                    defaultMessage="Already have an account?"
                    id="global_checkout.already_have_account"
                  />
                </FormTitle>
                <FormSubtitle
                  data-autotag="signin-open-message"
                  data-qa-automation="signInText"
                >
                  <FormattedMessage
                    defaultMessage="Sign In"
                    id="global_checkout.sign_in_title"
                  />
                </FormSubtitle>
                <InstructionsTop>
                  <FormattedMessage
                    defaultMessage="Sign into your account for easy checkout"
                    id="global_checkout.sign_in_instructions"
                  />
                </InstructionsTop>
              </>
            ) : null}
            {emailField}
            {passwordField}
            {recaptchaField}
            {!!cookies.automated_test && isTestSecretRecaptchaNeeded ? (
              <ProductionTestCaptcha
                onChange={loginReCaptchaField.onChange}
                data-autotag="production-test-captcha"
              />
            ) : null}
            <SubmitButton
              data-autotag="loginmodal-btn"
              disabled={disableButton}
              aria-label="Sign In"
            >
              <FormattedMessage
                defaultMessage="SIGN IN"
                id="global_checkout.sign_in_button"
              />
            </SubmitButton>
            {shouldShowAlternateModal || showQuizSignUp ? (
              <StyledParagraphWithMarginBottom>
                <FormLink
                  onClick={toggleForgotPassword}
                  data-autotag="forgot-password-link"
                >
                  <FormattedMessage
                    defaultMessage="Forgot password?"
                    id="global_checkout.forgot_password_link"
                  />
                </FormLink>
              </StyledParagraphWithMarginBottom>
            ) : null}
            {registrationModal || shouldShowAlternateModal || showQuizSignUp ? (
              <>
                {shouldShowFooterForgotPasswordLink ? (
                  <BottomForgotPassword>
                    {renderForgotPasswordLink()}
                  </BottomForgotPassword>
                ) : null}
                <StyledInstructionsBottom>
                  <FormattedMessage
                    defaultMessage="New Here?"
                    id="global_checkout.registration_modal_new_here_title"
                  />
                  &nbsp;
                  <FormLink onClick={toggleCheckoutSignUp}>
                    <FormattedMessage
                      defaultMessage="Sign Up"
                      id="global_checkout.registration_modal_create_an_account"
                    />
                  </FormLink>
                  .
                </StyledInstructionsBottom>
              </>
            ) : (
              <Paragraph align="center">
                <FormLink
                  onClick={toggleForgotPassword}
                  data-autotag="forgot-password-link"
                >
                  <FormattedMessage
                    defaultMessage="Forgot password?"
                    id="global_checkout.forgot_password_link"
                  />
                </FormLink>
              </Paragraph>
            )}
          </CellContent>
        </Cell>
      </Form>
    );
  };

  /**
   * This renders the guest checkout sign up form (required email, optional password) or the
   *  activating VIP checkout sign up form (required email, required password) based on the sign up flow.
   */
  const renderCheckoutSignUp = (signUpFlow = GUEST_SIGN_UP) => {
    const {
      emailField,
      shouldEnableSubmitBtn,
      showSwitcher,
      toggleFormState,
      onSignInClick,
      shouldShowPrivacyNotice,
      showBottomTermsConditionsLink,
      showBottomPrivacyLink,
      location,
      handleCheckoutSignUp,
      displayGuestSignUpPassword,
      toggleDisplayGuestSignUpPassword,
    } = props;

    const toggledForm =
      signUpFlow === CHECKOUT_SIGN_UP ? CHECKOUT_SIGN_IN : GUEST_SIGN_IN;
    const accountRequired = signUpFlow === CHECKOUT_SIGN_UP;
    const isDisplayingPassword = displayGuestSignUpPassword || accountRequired;

    const toggleCheckoutSignIn = event => {
      if (onSignInClick) {
        onSignInClick();
      }
      toggleFormState(toggledForm, event);
    };

    const passwordField = renderPasswordField({
      dataAutotag: 'guest-sign-in-password-fld',
      isPasswordLengthRestricted: accountRequired,
    });

    const emailError = !!(!emailField.value || emailField.error);
    const shouldConsiderPasswordError = accountRequired
      ? true
      : displayGuestSignUpPassword && Boolean(passwordField.value);

    const disableButton = shouldConsiderPasswordError
      ? determineSignInDisableButton()
      : Boolean(emailError);

    return (
      <Form
        onSubmit={handleCheckoutSignUp}
        ref={formRef}
        showSwitcher={showSwitcher}
        data-autotag="guest-sign-up-form"
        data-qa-automation="guestSignUpForm"
      >
        <Cell order={1} showSwitcher={showSwitcher}>
          <CellContent>
            <Link href="/cart">
              <BackToCartLink>
                <FaArrowLeft size={24} title={intl.formatMessage(backToCart)} />
              </BackToCartLink>
            </Link>
            {location ? <SignInContentHeadline location={location} /> : null}
            {showSwitcher && (
              <>
                <FormTitle
                  data-qa-automation="guestCheckoutText"
                  flow={signUpFlow}
                  marginBottom="none"
                >
                  {accountRequired ? (
                    <FormattedMessage
                      defaultMessage="Check out now"
                      id="global_checkout.member_checkout_title"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Checkout As Guest"
                      id="global_checkout.guest_checkout_title"
                    />
                  )}
                </FormTitle>
                <FormSubtitle
                  flow={signUpFlow}
                  data-qa-automation="guestCheckoutSubText"
                >
                  <FormattedMessage
                    defaultMessage="Let's Get Started"
                    id="global_checkout.guest_checkout_subtitle"
                  />
                </FormSubtitle>
                <Instructions>
                  {accountRequired ? (
                    <FormattedMessage
                      defaultMessage="Enter your email and password to secure your membership and stay up to date on your order."
                      id="global_checkout.member_checkout_instructions"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Enter your email to stay up to date on your order."
                      id="global_checkout.guest_checkout_instructions"
                    />
                  )}
                </Instructions>
              </>
            )}
            {renderEmailField()}
            {(accountRequired || displayGuestSignUpPassword) && passwordField}
            {!isDisplayingPassword && (
              <AddPasswordInstructions>
                <FormattedMessage
                  defaultMessage="{addPasswordText} to set up your account for order tracking and fast checkout."
                  id="global_checkout.guest_sign_in_optional_password_instructions"
                  values={{
                    addPasswordText: (
                      <LinkStyleButton
                        onClick={toggleDisplayGuestSignUpPassword}
                        type="button"
                      >
                        <FormattedMessage
                          defaultMessage="Add a Password"
                          id="global_checkout.guest_sign_in_add_password"
                        />
                      </LinkStyleButton>
                    ),
                  }}
                />
              </AddPasswordInstructions>
            )}
            {renderSubscribeToEmails(true)}
            {shouldShowPrivacyNotice ? renderPrivacyNoticeMessage() : null}
            <SubmitButton
              data-gtm={guestSignUpTag}
              data-autotag="reg-continue-btn"
              aria-label={
                shouldConsiderPasswordError
                  ? intl.formatMessage(signUpAndCheckout)
                  : intl.formatMessage(checkoutAsGuest)
              }
              disabled={shouldEnableSubmitBtn ? false : disableButton}
              shouldShowPrivacyNotice={shouldShowPrivacyNotice}
            >
              {shouldShowPrivacyNotice ? (
                <FormattedMessage
                  defaultMessage="Confirm & Continue"
                  id="global_checkout.guest_checkout_confirm_continue"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Continue"
                  id="global_checkout.guest_checkout_continue"
                />
              )}
            </SubmitButton>
            <CellFooter>
              <PrivacyTermsConditionsBox>
                {showBottomPrivacyLink ? renderPrivacyPolicyLink() : null}
                {showBottomTermsConditionsLink
                  ? renderTermsConditionsLink()
                  : null}
              </PrivacyTermsConditionsBox>
            </CellFooter>
          </CellContent>
        </Cell>
        {showSwitcher ? (
          <Cell inactive order={2}>
            <CellContent $hasExtraPadding>
              <FormTitle flow={signUpFlow} marginBottom="none">
                <FormattedMessage
                  defaultMessage="Already have an account?"
                  id="global_checkout.already_have_account"
                />
              </FormTitle>
              <FormSubtitle>
                <FormattedMessage
                  defaultMessage="Sign In"
                  id="global_checkout.sign_in_title"
                />
              </FormSubtitle>
              <Instructions>
                <FormattedMessage
                  defaultMessage="Sign into your account for easy checkout"
                  id="global_checkout.sign_in_instructions"
                />
              </Instructions>
              <Paragraph>
                <SecondaryButton
                  onClick={toggleCheckoutSignIn}
                  data-autotag="toggle-signin-account-button"
                >
                  <FormattedMessage
                    defaultMessage="SIGN IN"
                    id="global_checkout.sign_in_button"
                  />
                </SecondaryButton>
              </Paragraph>
            </CellContent>
          </Cell>
        ) : null}
      </Form>
    );
  };

  const renderContent = () => {
    switch (props.toggleState) {
      case SIGN_UP:
        return renderSignUp();
      case SIGN_IN:
        return renderSignIn();
      case RESET_PASSWORD:
        return renderResetPassword();
      case FORGOT_PASSWORD:
        return renderForgotPassword();
      case GUEST_SIGN_UP:
        return renderCheckoutSignUp();
      case GUEST_SIGN_IN:
        return renderCheckoutSignIn();
      case CHECKOUT_SIGN_UP:
        return renderCheckoutSignUp(CHECKOUT_SIGN_UP);
      case CHECKOUT_SIGN_IN:
        return renderCheckoutSignIn(CHECKOUT_SIGN_IN);
      default:
        return null;
    }
  };

  return (
    <ToastContainer
      className={props.className}
      $shouldFitSpace={props.shouldFitSpace}
    >
      {renderContent()}
      <Toast
        show={!!props.toastMessage}
        onHide={props.hideMessage}
        delay={5000}
        positionBelowNav={props.isCheckoutSignUp}
      >
        {props.toastMessage}
      </Toast>
    </ToastContainer>
  );
});

SignInContent.displayName = 'SignInContent';

SignInContent.propTypes = {
  birthdateField: PropTypes.object,
  checkEmail: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emailField: PropTypes.object,
  exitIdleModal: PropTypes.bool,
  firstNameField: PropTypes.object,
  forgotPasswordReCaptchaField: PropTypes.object,
  forgotPasswordStatus: PropTypes.object,
  handleBackToResetForm: PropTypes.func,
  handleForgotPassword: PropTypes.func,
  handleResetPassword: PropTypes.func,
  handleSignIn: PropTypes.func,
  handleSignUp: PropTypes.func,
  hideMessage: PropTypes.func,
  inputVariant: PropTypes.oneOf(['default', 'fullBorder']),
  isSpeedy: PropTypes.bool,
  isCheckoutSignUp: PropTypes.bool,
  location: PropTypes.string,
  loginReCaptchaField: PropTypes.object,
  onForgotPasswordLinkClick: PropTypes.func,
  onSignUpClick: PropTypes.func,
  onResetSuccess: PropTypes.func,
  onSignInClick: PropTypes.func,
  passwordField: PropTypes.object,
  passwordVerifyField: PropTypes.object,
  referrerField: PropTypes.object,
  referrerOptions: PropTypes.array,
  registrationModal: PropTypes.bool,
  resetPasswordStatus: PropTypes.object,
  setEmailError: PropTypes.func,
  shouldEnableSubmitBtn: PropTypes.bool,
  shouldShowAlternateModal: PropTypes.bool,
  shouldFitSpace: PropTypes.bool,
  shouldShowFooterForgotPasswordLink: PropTypes.bool,
  shouldShowPrivacyNotice: PropTypes.bool,
  shouldShowPrivacyTermsBeforeCTA: PropTypes.bool,
  showBottomPrivacyLink: PropTypes.bool,
  showBottomTermsConditionsLink: PropTypes.bool,
  showEmailField: PropTypes.bool,
  showForgotPasswordHint: PropTypes.bool,
  showNameField: PropTypes.bool,
  showPasswordField: PropTypes.bool,
  showQuizSignUp: PropTypes.bool,
  showReferrerField: PropTypes.bool,
  showSwitcher: PropTypes.bool,
  showSwitchToSignInLink: PropTypes.bool,
  signInScreen: PropTypes.oneOf([SIGN_IN, GUEST_SIGN_IN]),
  signInStatus: PropTypes.object,
  signUpLabel: PropTypes.node,
  signUpStatus: PropTypes.object,
  sizeFilterSignUp: PropTypes.bool,
  subscribeToEmailsField: PropTypes.object,
  termsAndConditionsField: PropTypes.object,
  toastMessage: PropTypes.node,
  toggleFormState: PropTypes.func,
  toggleState: PropTypes.string,
  updateForgotPasswordStatus: PropTypes.func,
  zipCodeField: PropTypes.object,
  subscribeToEmailsLabel: PropTypes.string,
  privacyNotice: PropTypes.string,
  haveAnAccountMessage: PropTypes.string,
  terms: PropTypes.string,
};

SignInContent.defaultProps = {
  checkEmail: () => {},
  disabled: false,
  inputVariant: 'default',
  isSpeedy: false,
  location: null,
  referrerOptions: [],
  shouldEnableSubmitBtn: false,
  shouldShowAlternateModal: false,
  shouldShowPrivacyTermsBeforeCTA: false,
  showEmailField: true,
  showNameField: true,
  showPasswordField: true,
  showReferrerField: false,
  signInScreen: SIGN_IN,
};

SignInContent.BackToSignIn = BackToSignIn;
SignInContent.BottomForgotPassword = BottomForgotPassword;
SignInContent.Cell = Cell;
SignInContent.CellFooter = CellFooter;
SignInContent.CheckboxFormField = CheckboxFormField;
SignInContent.Form = Form;
SignInContent.FormField = FormField;
SignInContent.FormSubtitle = FormSubtitle;
SignInContent.FormTitle = FormTitle;
SignInContent.InstructionsBottom = InstructionsBottom;
SignInContent.InstructionsTop = InstructionsTop;
SignInContent.PrivacyNoticeMessage = PrivacyNoticeMessage;
SignInContent.PrivacyTermsConditionsBox = PrivacyTermsConditionsBox;
SignInContent.RegistrationModalHeadline = RegistrationModalHeadline;
SignInContent.SubscribeCheckboxLabel = SubscribeCheckboxLabel;
SignInContent.StyledInstructionsBottom = StyledInstructionsBottom;
SignInContent.StyledReCAPTCHA = StyledReCAPTCHA;
SignInContent.SubmitButton = SubmitButton;
SignInContent.SubscribeToEmailWrapper = SubscribeToEmailWrapper;
SignInContent.ToastContainer = ToastContainer;
SignInContent.StyledPasswordField = StyledPasswordField;

export default SignInContent;
