import React, { useCallback, useContext, useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle, css, withTheme } from 'styled-components';

import { Router } from '@techstyle/next-routes';
import { useCustomer, useMembership } from '@techstyle/react-accounts';
import { useBreakpoint } from '@techstyle/react-components';
import { useFeatures } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';
import { updateSessionDetail, useSession } from '@techstyle/redux-core';

import { toggleSignInModal, trackRegistrationSignUp } from '../../actions/auth';
import { SignUpMethod } from '../../constants/auth';
import { NavBarBGColors } from '../../constants/navBarBGColors';
import { SportNavState } from '../../constants/sportNav';
import { desktop, mobile } from '../../styles';
import { BasicButton } from '../../styles/button';
import { Paragraph } from '../../styles/paragraph';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import BurgerNav from '../BurgerNav/BurgerNav';
import { Component as DynamicRegistrationModal } from '../DynamicRegistrationModal';
import { Component as FavoritesNav } from '../FavoritesNav';
import { Component as GlobalHeaderStateProvider } from '../GlobalHeaderStateProvider';
import { useIsStickyFixed } from '../Header/components/MaybeSticky';
import { Component as InfluencerPromoModal } from '../InfluencerPromoModal';
import { Component as NavBarAttachment } from '../NavBarAttachment';
import { Component as NavLogo } from '../NavLogo';
import { Container as NavMenu } from '../NavMenu';
import NavSearch from '../NavSearch/NavSearch';
import { Component as NavToolBarDesktop } from '../NavToolBarDesktop';
import NavToolbarMobile from '../NavToolBarMobile/NavToolBarMobile';
import NewPricingSkinnyBanner from '../NewPricingSkinnyBanner/NewPricingSkinnyBanner';
import SearchProvider from '../SearchProvider/SearchProvider';
import ShoppingBagNavMobile from '../ShoppingBagNavMobile/ShoppingBagNavMobile';
import StyleContext from '../StyleContext/StyleContext';
import { Component as XtraSavageIcon } from '../XtraSavageIcon';

import NavBarMobile from './NavBarMobile';

const SignInModalStyles = createGlobalStyle`
  /* Force no scrolling of content underneath modal on Mobile Safari.
   * See: https://gist.github.com/claus/622a938d21d80f367251dc2eaaa1b2a9
   * This also fixes weird misalignment of cursor when typing into the inputs on Mobile Safari.
   */
  @media (max-width: 767px) {
    .SignInModal {
      position: fixed;

      /* Clicking in SignIn modal is way too slow because of the carousel and videos on the homepage. Just hide the page since the modal is full screen. */

      & [aria-hidden="true"] {
        display: none;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const NavBarContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: ${props => props.theme.nav.tabletHeight}px;
  background: ${({ theme }) => theme.context.backgroundState};
  color: ${props =>
    props.theme.context.backgroundState === NavBarBGColors.TRANSPARENT
      ? props.theme.colors.white
      : props.theme.colors.default};
  backface-visibility: hidden;

  ${mobile`
    height:${props => props.theme.nav.mobileHeight}px
  `}

  @media(min-width: ${props => props.theme.breakpoints.xlarge}px) {
    flex-wrap: wrap;
    height: auto;
  }

  &:hover,
  .sticky-outer-wrapper.active & {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.default};
  }

  .sticky-outer-wrapper.active & {
    box-shadow: 0 2px 4px 0 ${props => props.theme.colors.lightShadow1};
  }
`;

const Attachments = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0;
  margin-top: 16px;
`;

// On desktop we are moving the NavMenu out of the row, this keeps the logo properly centered in
// its place
const DesktopSpacer = styled.div`
  ${v1`
    display: none;
    background: ${props => props.theme.context.backgroundState};
  `}

  z-index: 3;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    ${v2`
      padding-left: 40px;
      align-items: center;
    `}
  }
`;

const XtraSavageIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: ${props => props.theme.spacing.small}px;
  ${props =>
    !props.isTablet &&
    css`
      @media (max-width: ${props => props.theme.breakpoints.xlarge}px) {
        display: none;
      }
    `}
`;

const FavoritesNavContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${props => props.theme.spacing.small}px;
  @media (max-width: ${props => props.theme.breakpoints.xlarge - 1}px) {
    width: 44px;
    margin: 0 2px;
  }
`;

const StyledLink = styled.a`
  color: inherit;
`;

const StyledXtraSavageIcon = styled(XtraSavageIcon)`
  height: 24px;
`;

const XtraSavageIconLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
`;

const XtraVipMemberSmallText = styled(Paragraph).attrs({ size: 8 })`
  letter-spacing: 1px;
  color: inherit;
`;

const StyledNavSearch = styled(NavSearch)`
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: none;
  }
`;

const NavToolBarDesktopWrapper = styled.div`
  ${desktop`
    display: ${props => (props.hideNavigation ? 'none' : 'flex')};
    flex: 1;
    margin-right: ${({ theme }) => theme.spacing.moderate}px;
  `}
`;

const TakeOurQuizBtn = styled(BasicButton)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.xlarge}px) {
    display: flex;
    align-items: center;
    ${v1`
      background-color: ${({ theme }) => theme.colors.lavender250};
      border: none;
      font-size: 12px;
      font-weight: 600;
      height: ${({ theme }) => theme.spacing.medium}px;
      justify-content: center;
      letter-spacing: 1.75px;
      outline: none;
      text-transform: uppercase;
      min-width: 125px;
      color: ${({ theme }) => theme.colors.active};
    `}
    ${v2`
      min-height: 32px;
      height: 32px;
      min-width: 148px;
      ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
    `}
  }
`;

const BorderlessNavToolbarMobile = styled(NavToolbarMobile)`
  border: none;
`;

const HowItWorksLink = styled.a`
  display: flex;s
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  ${v1`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
    text-align: left;
    color: ${({ theme }) => theme.colors.promo};
    width: 180px;
  `}

  ${v2`
      ${props => props.theme.links.variants.navigationPrimary}
      margin-right: 20px;
      border-bottom: 1px solid ${props =>
        props.isLoggedIn ? props.theme.colors.gunmetal : 'transparent'};
      `}
`;

function NavBar({
  authRequired,
  className,
  hideNavigation = false,
  hideNavLinks,
  hideNavLinksState,

  onHover,
  onUnhover,
  openPrivacyPolicy,
  theme,
  shouldDisplayFullLogo = true,
  title,
  allowNewPricingSkinnyBanner,
}) {
  const dispatch = useDispatch();
  const {
    initialScreen,
    signInModalIsOpen: isSignInModalOpen = false,
    location,
    onSuccess: onCloseSignInModal,
  } = useSelector(state => state.auth);
  const { cartDrawerIsOpen } = useSelector(state => state.cart);
  const [hover, setHover] = useState(false);
  const [backgroundState, setBackgroundState] = useState(NavBarBGColors.WHITE);
  const [influencerPromoModalOpen, setInfluencerPromoModalOpen] =
    useState(false);
  const { isMobile } = useBreakpoint();
  const router = useRouter();

  const { dateTimeModified } = useMembership();
  const { influencerPromoInfo, isInfluencer } = useCustomer();
  const { isLoggedIn, isVisitor } = useSession();
  const { query } = useRouter();
  const { isActive: isSearchActive } = useContext(SearchProvider.Context);
  const { isMouseOverHeader, setIsMouseOverHeader } = useContext(
    GlobalHeaderStateProvider.Context
  );
  const isStickyFixed = useIsStickyFixed();
  const { isBorderfreeCustomer } = useSelector(state => state.borderfree);
  const [
    { isEnabled: isMobileNavUpdatesFeatureEnabled },
    { isEnabled: isNewDesktopSearchNavEnabled },
  ] = useFeatures(['mobile_nav_updates', 'desktop_nav_update']);

  const isNewTheme = theme.themeVersion === 'v2';

  useEffect(() => {
    const transparentBGState =
      theme.context.transparentNav &&
      !isStickyFixed &&
      !hover &&
      !isSearchActive &&
      !isMouseOverHeader;

    // We need this because we lose track of the users mouse once the modal is open
    if (cartDrawerIsOpen) {
      setIsMouseOverHeader(true);
    }

    if (isSignInModalOpen) {
      setIsMouseOverHeader(true);
    }

    setBackgroundState(
      transparentBGState ? NavBarBGColors.TRANSPARENT : NavBarBGColors.WHITE
    );
  }, [
    cartDrawerIsOpen,
    hover,
    isInfluencer,
    isMouseOverHeader,
    isSearchActive,
    isSignInModalOpen,
    isStickyFixed,
    setIsMouseOverHeader,
    theme.context.transparentNav,
  ]);

  useEffect(() => {
    if (dateTimeModified && isVisitor && authRequired) {
      dispatch(toggleSignInModal(true));
    }

    return function cleanup() {
      if (onUnhover && typeof onUnhover === 'function') {
        onUnhover();
      }
    };
  }, [authRequired, dispatch, isVisitor, dateTimeModified, onHover, onUnhover]);

  // util functions

  const dispatchTrackRegistrationSignUp = eventAction => {
    dispatch(trackRegistrationSignUp(eventAction));
  };

  const handleHover = () => {
    setHover(true);
    if (onHover && typeof onHover === 'function') {
      onHover();
    }
  };

  const handleUnhover = () => {
    setHover(false);
    if (onUnhover && typeof onUnhover === 'function') {
      onUnhover();
    }
  };

  const handleOpen = () => {
    dispatch(updateSessionDetail({ name: 'signup_login', value: '' }));
    dispatch(toggleSignInModal(true, 'SIGN_IN', SignUpMethod.NAVBAR));
  };

  const handleResetSuccess = () => {
    onCloseSignInModal && onCloseSignInModal({ signedIn: false });
    dispatch(toggleSignInModal(false));
  };

  const handleSuccess = () => {
    if (isInfluencer) {
      setInfluencerPromoModalOpen(true);
    }
    onCloseSignInModal && onCloseSignInModal({ signedIn: true });
    dispatch(toggleSignInModal(false));
    setIsMouseOverHeader(false);
  };

  const handleClose = () => {
    onCloseSignInModal && onCloseSignInModal({ signedIn: false });
    dispatch(toggleSignInModal(false));
    setIsMouseOverHeader(true);
  };

  const handleCloseInfluencerPromo = () => {
    setInfluencerPromoModalOpen(false);
    setIsMouseOverHeader(false);
  };

  const pushUserToHIWPage = useCallback(
    event => {
      event.preventDefault();
      router.push('/xtrasavage');
    },
    [router]
  );

  const renderXtraSavageIcon = isTablet => {
    return (
      <XtraSavageIconContainer isTablet={isTablet}>
        <Link href="/xtrasavage" passHref>
          <StyledLink>
            <StyledXtraSavageIcon backgroundState={backgroundState} />
            <XtraSavageIconLabel>
              <FormattedMessage
                defaultMessage="Xtra VIP"
                id="site_navigation.savage_xtra_membership_icon_big_text"
              />
              <XtraVipMemberSmallText>
                <FormattedMessage
                  defaultMessage="How It works"
                  id="site_navigation.savage_xtra_membership_icon_small_text"
                />
              </XtraVipMemberSmallText>
            </XtraSavageIconLabel>
          </StyledLink>
        </Link>
      </XtraSavageIconContainer>
    );
  };

  const handleTakeQuizBtn = useCallback(() => {
    const fullQuery = {
      fromNavCta: 1,
      ...(query.mpid ? { mpid: query.mpid } : {}),
    };

    Router.push({
      pathname: '/product-quiz',
      query: fullQuery,
    });
  }, [query.mpid]);

  const isSearchOverlayVisible =
    isNewDesktopSearchNavEnabled && isSearchActive && !isBorderfreeCustomer;

  return (
    <StyleContext context={{ showNewNavBorder: false, backgroundState }}>
      <Wrapper className={className} id="navbar">
        <NavBarContent
          onMouseEnter={handleHover}
          onMouseLeave={handleUnhover}
          isMobile={isMobile}
        >
          {/*
           search field that overlays the navs
          */}
          <StyledNavSearch focusOnMount />
          {isNewTheme && isMobile && !hideNavigation ? (
            <BurgerNav showAccountModal={handleOpen} hideOnDesktop />
          ) : null}
          {hideNavigation ? null : (
            <>
              <NavMenu
                showAccountModal={handleOpen}
                hideNavLinks={
                  hideNavLinksState === SportNavState.TEASER
                    ? hideNavLinks
                    : hideNavLinks &&
                      backgroundState === NavBarBGColors.TRANSPARENT
                }
              />
              {!isSearchOverlayVisible ? (
                <DesktopSpacer>
                  <>
                    <BorderfreeGuard>
                      {!isNewDesktopSearchNavEnabled
                        ? renderXtraSavageIcon()
                        : null}
                    </BorderfreeGuard>
                    <BorderfreeGuard>
                      {!isMobile && isNewTheme ? (
                        <HowItWorksLink
                          data-qa-automation="howItWorksBtn"
                          onClick={pushUserToHIWPage}
                          isLoggedIn={isLoggedIn}
                        >
                          <FormattedMessage
                            defaultMessage="How Membership Works"
                            id="site_navigation.savage_xtra_membership_works"
                          />
                        </HowItWorksLink>
                      ) : null}
                      {!isLoggedIn && !isNewDesktopSearchNavEnabled ? (
                        <TakeOurQuizBtn onClick={handleTakeQuizBtn}>
                          <FormattedMessage
                            id="site_navigation.take_our_quiz_desktop"
                            defaultMessage="Take our quiz"
                          />
                        </TakeOurQuizBtn>
                      ) : null}
                      {isNewTheme && !isLoggedIn && !isMobile ? (
                        <TakeOurQuizBtn onClick={handleTakeQuizBtn} alternate>
                          <FormattedMessage
                            id="site_navigation.take_our_quiz_desktop"
                            defaultMessage="Take our quiz"
                          />
                        </TakeOurQuizBtn>
                      ) : null}
                      {isLoggedIn && !isNewDesktopSearchNavEnabled ? (
                        <FavoritesNavContainer>
                          <FavoritesNav size={24} />
                        </FavoritesNavContainer>
                      ) : null}
                    </BorderfreeGuard>
                  </>
                </DesktopSpacer>
              ) : null}
            </>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {isMobileNavUpdatesFeatureEnabled && isMobile ? (
            <BorderlessNavToolbarMobile
              backgroundState={backgroundState}
              showAccountModal={handleOpen}
              shouldDisplayFullLogo={shouldDisplayFullLogo}
              hideNavigation={hideNavigation}
              title={title}
            />
          ) : !isSearchOverlayVisible ? (
            <NavLogo
              shouldDisplayFullLogo={shouldDisplayFullLogo}
              backgroundState={backgroundState}
              title={title}
            />
          ) : null}
          <NavToolBarDesktopWrapper hideNavigation={hideNavigation}>
            {hideNavigation || isMobile ? null : (
              <NavToolBarDesktop showAccountModal={handleOpen} />
            )}
          </NavToolBarDesktopWrapper>
          {isNewTheme && isMobile && !hideNavigation ? (
            <ShoppingBagNavMobile />
          ) : null}
        </NavBarContent>
        {!hideNavigation &&
          isMobile &&
          !isMobileNavUpdatesFeatureEnabled &&
          !isNewTheme && (
            <NavBarMobile
              backgroundState={backgroundState}
              showAccountModal={handleOpen}
              allowNewPricingSkinnyBanner={allowNewPricingSkinnyBanner}
            />
          )}
        {!hideNavigation &&
          isMobile &&
          !isMobileNavUpdatesFeatureEnabled &&
          allowNewPricingSkinnyBanner &&
          isNewTheme && <NewPricingSkinnyBanner />}

        {isInfluencer && (
          <InfluencerPromoModal
            isOpen={influencerPromoModalOpen}
            onClose={handleCloseInfluencerPromo}
            influencerPromo={influencerPromoInfo}
          />
        )}
        {isSignInModalOpen ? (
          <DynamicRegistrationModal
            isSignInModalOpen={isSignInModalOpen}
            onClose={handleClose}
            onResetSuccess={handleResetSuccess}
            initialScreen={initialScreen}
            location={location}
            onSuccess={handleSuccess}
            trackRegistrationSignUp={dispatchTrackRegistrationSignUp}
            openPrivacyPolicy={openPrivacyPolicy}
          />
        ) : null}
        <NavBarAttachment.Consumer>
          {({ setContainer }) =>
            setContainer ? <Attachments ref={setContainer} /> : null
          }
        </NavBarAttachment.Consumer>
        <SignInModalStyles />
      </Wrapper>
    </StyleContext>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  /**
   * Whether unauthorized user should see login modal or not
   */
  authRequired: PropTypes.bool,
  /**
   * Whether to render interactive nav items (like NavMenu and NavToolbar),
   * or just the logo. You can use this if you are on a part of the site that
   * should bounce/exit points, like the checkout flow.
   */
  hideNavigation: PropTypes.bool,
  hideNavLinks: PropTypes.bool,
  hideNavLinksState: PropTypes.string,
  isStickyFixed: PropTypes.bool,
  /*
   * Whether to show or hide openPrivacyPolicy
   */
  openPrivacyPolicy: PropTypes.func,
  /*
   * Inform parent when NavBar content is hovered.
   */
  onHover: PropTypes.func,
  /*
   * Inform parent when NavBar content is unhovered.
   */
  onUnhover: PropTypes.func,
  theme: PropTypes.object,
  shouldDisplayFullLogo: PropTypes.bool,
  title: PropTypes.string,
  /**
   * Allow new pricing skinny banner to be shown if A/B Test returns it
   */
  allowNewPricingSkinnyBanner: PropTypes.bool,
};

export default withTheme(NavBar);
