import { connect } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';
import { getSession } from '@techstyle/redux-core';

import DebugVisitorStatus from './DebugVisitorStatus';

export function mapStateToProps(state) {
  return {
    authType: getSession(state).authType,
    isVip: getMembership(state).isVip,
    isVisitor: getSession(state).isVisitor,
    showVisitorStatus: state.debug.showVisitorStatus,
    visitorStatus: getSession(state).visitorStatus,
  };
}

export default connect(mapStateToProps)(DebugVisitorStatus);
