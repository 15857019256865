import { useCallback, useMemo } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useCustomer, useCustomerDetail } from '@techstyle/react-accounts';

import {
  checkIfPasswordlessLogin,
  type CheckIfPasswordlessOptions,
} from '../actions/auth/checkIfPasswordlessLogin';

/**
 * A hook to check if the user is a passwordless lead based on their email.
 * This uses the `/members/is-passwordless` endpoint in combination with the
 * `AUTOGEN_PASSWORD_UNSECURE` customer detail record.
 * To prevent excess calls to the `is-passwordless` endpoint, we first check the
 * that the customer detail record is set to `1` before making the call.
 */
export function useCheckIfPasswordlessLogin(disable = false) {
  const {
    value: customerDetailValue,
    networkStatus: customerDetailNetworkStatus,
  } = useCustomerDetail('AUTOGEN_PASSWORD_UNSECURE');
  const dispatch = useDispatch();
  const { email } = useCustomer();

  const params: CheckIfPasswordlessOptions = useMemo(() => {
    return {
      email,
    };
  }, [email]);

  const enabled = useMemo(() => {
    if (disable || !customerDetailNetworkStatus.isUpToDate) {
      return false;
    }

    const hasEmail = !!params.email;
    const hasPasswordlessCustomerDetail = customerDetailValue === 1;

    return hasEmail && hasPasswordlessCustomerDetail;
  }, [customerDetailNetworkStatus, customerDetailValue, disable, params]);

  const getIsPasswordlessLogin = useCallback(async () => {
    try {
      // @ts-ignore -- The return type is not correct, as we don't have a proper way to type responses in Redux
      const { payload } = (await dispatch(
        checkIfPasswordlessLogin(params)
      )) as { payload: { isPasswordlessLead: boolean } };

      return payload.isPasswordlessLead;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [dispatch, params]);

  const { data, isLoading: isPasswordlessLoginLoading } = useQuery({
    queryFn: getIsPasswordlessLogin,
    queryKey: ['checkIfPasswordlessLogin', params, customerDetailValue],
    enabled,
    staleTime: Infinity,
  });

  return useMemo(
    () => ({
      isPasswordlessLogin: enabled ? data : customerDetailValue === 1,
      isLoading:
        customerDetailNetworkStatus?.isLoading ||
        (enabled && isPasswordlessLoginLoading),
    }),
    [
      customerDetailNetworkStatus,
      customerDetailValue,
      data,
      enabled,
      isPasswordlessLoginLoading,
    ]
  );
}
