import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useTheme } from '@techstyle/react-components';
import { IconButton } from '@techstyle/react-components-legacy';
import { defineMessages, useIntl } from '@techstyle/react-intl';
import { updateSessionDetail } from '@techstyle/redux-core';

import { toggleCartDrawer } from '../../actions/cart';
import {
  getItemCountExcludingMembership,
  getInfluencerItemCountExcludingMembership,
} from '../../utils/selectors';

const StyledIconButton = styled(IconButton)`
  display: block;
  height: 100%;
  margin-right: 2px;
  margin-top: 2px;
  width: 100%;
  ${props => (props.theme.context.checkout ? 'cursor: default;' : '')}

  > svg {
    margin-bottom: 2px;
  }
`;
const messages = defineMessages({
  cartIconLabel: {
    defaultMessage: 'Cart',
    id: 'global_cta.cart_tt',
  },
});

function ShoppingBagNav() {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const cartItemCount = useSelector(state =>
    state.customer.isInfluencer
      ? getInfluencerItemCountExcludingMembership(state)
      : getItemCountExcludingMembership(state)
  );
  const showCartDrawer = () => {
    dispatch(toggleCartDrawer(true, { fetchCart: true }));
    dispatch(updateSessionDetail({ name: 'sxf_minicart', value: '' }));
  };
  return (
    <StyledIconButton
      onClick={showCartDrawer}
      disabled={theme.context.checkout}
      aria-label={intl.formatMessage(messages.cartIconLabel)}
      icon={{ type: 'bag', quantity: cartItemCount, size: 26 }}
      data-autotag="nav-cart-button"
      data-qa-automation="homeShoppingBag"
    />
  );
}

export default ShoppingBagNav;
