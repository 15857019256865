export const LOAD_CANCEL_REASONS_REQUEST = 'LOAD_CANCEL_REASONS_REQUEST';
export const LOAD_CANCEL_REASONS_SUCCESS = 'LOAD_CANCEL_REASONS_SUCCESS';
export const LOAD_CANCEL_REASONS_FAILURE = 'LOAD_CANCEL_REASONS_FAILURE';

export const TRACK_ONLINE_CANCEL_FAQ = 'TRACK_ONLINE_CANCEL_FAQ';
export const TRACK_ONLINE_CANCEL_PHONE_CHAT = 'TRACK_ONLINE_CANCEL_PHONE_CHAT';
export const TRACK_ONLINE_CANCEL_SKIP = 'TRACK_ONLINE_CANCEL_SKIP';
export const TRACK_ONLINE_CANCEL_TAKE_ME_TO_SKIP =
  'TRACK_ONLINE_CANCEL_TAKE_ME_TO_SKIP';
export const TRACK_ONLINE_CANCEL_SIGN_UP_SKIP_REMINDER =
  'TRACK_ONLINE_CANCEL_SIGN_UP_SKIP_REMINDER';

export const TRACK_ONLINE_CANCEL_SKIP_REMINDER_CONFIRMATION =
  'TRACK_ONLINE_CANCEL_SKIP_REMINDER_CONFIRMATION';
export const TRACK_ONLINE_CANCELLATION_REASON =
  'TRACK_ONLINE_CANCELLATION_REASON';
export const TRACK_PAUSE_MEMBERSHIP = 'TRACK_PAUSE_MEMBERSHIP';

export const PAUSE_MEMBERSHIP_REQUEST = 'PAUSE_MEMBERSHIP_REQUEST';
export const PAUSE_MEMBERSHIP_SUCCESS = 'PAUSE_MEMBERSHIP_SUCCESS';
export const PAUSE_MEMBERSHIP_FAILURE = 'PAUSE_MEMBERSHIP_FAILURE';

export function loadCancelReasons() {
  return {
    bentoApi: {
      endpoint: 'settings/membership/cancellationreasons',
      method: 'GET',
      actions: [
        LOAD_CANCEL_REASONS_REQUEST,
        LOAD_CANCEL_REASONS_SUCCESS,
        LOAD_CANCEL_REASONS_FAILURE,
      ],
    },
  };
}

export function pauseMembership({ skipCurrentMonth = false }) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/snooze',
      body: JSON.stringify({ skipCurrentMonth }),
      method: 'POST',
      actions: [
        PAUSE_MEMBERSHIP_REQUEST,
        PAUSE_MEMBERSHIP_SUCCESS,
        PAUSE_MEMBERSHIP_FAILURE,
      ],
    },
  };
}

export function trackOnlineCancelFaq() {
  return {
    type: TRACK_ONLINE_CANCEL_FAQ,
  };
}

export function trackOnlineCancelPhoneChat(eventLabel) {
  return {
    type: TRACK_ONLINE_CANCEL_PHONE_CHAT,
    eventLabel,
  };
}

export function trackOnlineCancelSkip() {
  return {
    type: TRACK_ONLINE_CANCEL_SKIP,
  };
}

export function trackCancellationReason(eventLabel) {
  return {
    type: TRACK_ONLINE_CANCELLATION_REASON,
    eventLabel,
  };
}

export function trackPauseMembership(eventLabel) {
  return {
    type: TRACK_PAUSE_MEMBERSHIP,
    eventLabel,
  };
}

export function trackTakeMeToSkip(eventLabel) {
  return {
    type: TRACK_ONLINE_CANCEL_TAKE_ME_TO_SKIP,
    eventLabel,
  };
}

export function trackSignUpForSkipReminder(eventLabel) {
  return {
    type: TRACK_ONLINE_CANCEL_SIGN_UP_SKIP_REMINDER,
    eventLabel,
  };
}

export function trackOCSkipReminderConfirmation(eventLabel) {
  return {
    type: TRACK_ONLINE_CANCEL_SKIP_REMINDER_CONFIRMATION,
    eventLabel,
  };
}
