import React from 'react';

import styled from 'styled-components';

import { FormattedDate, FormattedMessage } from '@techstyle/react-intl';

const PreorderShipDateWrapper = styled.div``;

export default class PreorderShipDate extends React.PureComponent {
  render() {
    const { className, preorderShipDate, isPreorderable } = this.props;
    if (isPreorderable && preorderShipDate) {
      return (
        <PreorderShipDateWrapper className={className}>
          <FormattedMessage
            id="global_checkout.preorder_ship_date"
            defaultMessage="Pre-Order estimated to ship: {date}"
            values={{ date: <FormattedDate value={preorderShipDate} /> }}
          />
        </PreorderShipDateWrapper>
      );
    } else {
      return null;
    }
  }
}
