export const TRACK_REMOVE_FROM_WISHLIST_REQUEST =
  'TRACK_REMOVE_FROM_WISHLIST_REQUEST';
export const TRACK_ADD_TO_WISHLIST_REQUEST = 'TRACK_ADD_TO_WISHLIST_REQUEST';

export function trackRemoveFromWishlistEvent({ eventLabel, eventCategory }) {
  return {
    type: TRACK_REMOVE_FROM_WISHLIST_REQUEST,
    payload: { eventLabel, eventCategory },
  };
}

export function trackAddToWishlistEvent({ eventLabel, eventCategory }) {
  return {
    type: TRACK_ADD_TO_WISHLIST_REQUEST,
    payload: { eventLabel, eventCategory },
  };
}
