import { basePageViewData } from '../utils';

const signUpModalOpened = ({ trackPageView, getContext }) => {
  const { TRACK_SIGN_UP_MODAL } = require('../../actions/auth');

  const signUpModalOpened = trackPageView((action, prevState, nextState) => {
    const { cookies } = getContext();
    const queryString = window.location.search;
    const gateway = Boolean(nextState.session.dmGatewayCode);
    const referringPageModule = new URLSearchParams(queryString).get('nav');
    const customerBucketGroup = (nextState.customer.id % 20) + 1;

    const SIGN_UP_LOCATIONS = {
      CARTDRAWER: '/cart/registration-start',
      EXIT: '/exit/registration-start',
      FAVORITES: '/favorites-nav/registration-start',
      NAVBAR: '/login/registration-start',
      QUIZ: '/quiz/registration-start',
      SPEEDY: '/speedy-signup/registration-start',
      WISHLIST: '/favorites/registration-start',
    };

    return {
      name: SIGN_UP_LOCATIONS[action.location],
      properties: {
        ...basePageViewData(nextState, { cookies }),
        customer_bucket_group: nextState.customer.id
          ? customerBucketGroup.toString()
          : '',
        dmg_code: gateway ? nextState.session.dmGatewayCode : undefined,
        gateway,
        page_name: SIGN_UP_LOCATIONS[action.location],
        referring_page_module: referringPageModule || '',
      },
    };
  });

  return {
    [TRACK_SIGN_UP_MODAL]: signUpModalOpened,
  };
};

export default signUpModalOpened;
