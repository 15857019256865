import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { HTMLAsset, useAsset } from '@techstyle/react-assets';
import { TextLink } from '@techstyle/react-components';

import { trackNavItemClick } from '../../actions/navigation';
import { useTrackPromotionClick } from '../../utils/useTrackPromotionClick';
import { useTrackPromotionViewed } from '../../utils/useTrackPromotionViewed';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import { EnterViewport } from '../EnterViewport';

const Container = styled.div`
  display: flex;
`;

const AssetWrapper = styled.div`
  margin-left: 12px;
  width: 182px;
`;

const RenderedAssetWrapper = styled.div``;

const StyledLink = styled.a`
  cursor: pointer;
`;

const StyledTextLink = styled(TextLink)`
  display: flex;
  line-height: 1.5;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: ${props => props.theme.colors.default};
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.4s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.default};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }
`;

const StyledImage = styled.img`
  width: 182px;
  height: 130px;
  object-fit: cover;
  border-radius: 2px 2px 0px 0px;
`;

const HTMLContent = styled(HTMLAsset)`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
`;

const CtaOnlyText = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: ${props => props.theme.colors.default};
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function NavAssets({ itemKey }) {
  const asset = useAsset(itemKey.replace('$', ''));
  const dispatch = useDispatch();
  const dispatchTrackPromotionViewed = useTrackPromotionViewed();
  const dispatchTrackPromotionClicked = useTrackPromotionClick();

  const renderAsset = () => {
    if (!asset || !asset.data) {
      return null;
    }

    const { assets } = asset.data;

    return assets.map((item, index) => {
      const { imageFilename, mobileImageFilename, options } = item;
      const hasLinkText = options.targetUrl && options.customVars.ctaText;
      const hideFromBorderfree = options.customVars.hideFromBorderfree;
      const altText = options.customVars.altText || null;

      const fileName = mobileImageFilename || imageFilename;
      const key = `${fileName}-${index}`;
      const renderImg = (
        <StyledImage
          src={fileName}
          alt={altText}
          data-testid="nav_asset_mobile"
        />
      );

      const sendGaEvent = () => {
        const gaLabel = item.container.name;
        const gaAction = 'Main Nav Marketing Card';
        dispatch(trackNavItemClick(gaAction, gaLabel));
      };
      const onEnterViewport = () => dispatchTrackPromotionViewed(item);

      const onAssetClick = () => {
        sendGaEvent();
        dispatchTrackPromotionClicked(item);
      };

      const renderedAsset = (
        <RenderedAssetWrapper key={key}>
          <EnterViewport once onEnterViewport={onEnterViewport} />
          {options.targetUrl ? (
            <Link route={options.targetUrl}>
              <StyledLink onClick={onAssetClick}>{renderImg}</StyledLink>
            </Link>
          ) : (
            renderImg
          )}
          {hasLinkText && (
            <Link route={options.targetUrl} passHref>
              <StyledTextLink onClick={onAssetClick}>
                {options.customVars.ctaText}
              </StyledTextLink>
            </Link>
          )}
          {!hasLinkText && options.customVars.ctaText ? (
            <CtaOnlyText>{options.customVars.ctaText}</CtaOnlyText>
          ) : null}

          <HTMLContent asset={item} />
        </RenderedAssetWrapper>
      );

      if (hideFromBorderfree) {
        return <BorderfreeGuard key={key}>{renderedAsset}</BorderfreeGuard>;
      }

      return renderedAsset;
    });
  };

  return (
    <Container>
      <AssetWrapper>{renderAsset()}</AssetWrapper>
    </Container>
  );
}

NavAssets.propTypes = {
  itemKey: PropTypes.string,
};
