import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useCustomer, useMembershipPeriod } from '@techstyle/react-accounts';
import { usePromos } from '@techstyle/react-promos';

import { useLDFlags } from './LD/useLDFlags';
import { getPromo } from './promos';
import {
  getGwpPromo,
  isGwpApplied as getIsGwpApplied,
  isGwpAvailable as getIsGwpAvailable,
} from './selectors';
import { useLoadProducts } from './useLoadProducts';

const STALE_TIME = 1000 * 60 * 5;

function useGiftWithPurchase() {
  const { 'gift-with-purchase': isGwpActive } = useLDFlags();
  const gwpPromo = useSelector(getGwpPromo);
  const isGwpAvailable = useSelector(getIsGwpAvailable);
  const isGwpApplied = useSelector(getIsGwpApplied);
  const { networkStatus: membershipPeriodNetworkStatus } =
    useMembershipPeriod();
  const { promos, networkStatus: promosNetworkStatus } = usePromos();
  const { optionSignatures = {}, networkStatus: customerNetworkStatus } =
    useCustomer();

  const { gwpFeaturedProductLocationId, gwpMinSubtotal } = useMemo(() => {
    const gwpPromoInfo = getPromo(promos, null, gwpPromo?.promoCode);

    return {
      gwpFeaturedProductLocationId: gwpPromoInfo?.featuredProductLocationId,
      gwpMinSubtotal: gwpPromoInfo?.minSubtotal,
    };
  }, [gwpPromo, promos]);

  // We should only fetch the products if the GWP feature is active, the membership period
  // is loaded, and the customer is loaded. If we don't wait for some of these, the `loadProducts` call
  // might have incorrect filters.
  const shouldFetch = useMemo(() => {
    const hasMembershipPeriod = membershipPeriodNetworkStatus.isUpToDate;
    const hasCustomer = customerNetworkStatus.isUpToDate;
    const hasPromos = promosNetworkStatus.isUpToDate;

    return !!(
      isGwpActive &&
      hasMembershipPeriod &&
      hasCustomer &&
      hasPromos &&
      gwpFeaturedProductLocationId
    );
  }, [
    customerNetworkStatus,
    isGwpActive,
    membershipPeriodNetworkStatus,
    promosNetworkStatus,
    gwpFeaturedProductLocationId,
  ]);

  const productFilters = useMemo(() => {
    // ProductDetailRecommendations adds the sizes, so we should add them here as well,
    // so that the `react-query` `queryKey` matches.
    const sizes = optionSignatures
      ? {
          braSizes: optionSignatures['bra-size'] || [],
          braletteSizes: optionSignatures['bralette-size'] || [],
          lingerieSizes: optionSignatures['lingerie-sleep-size'] || [],
          undieSizes: optionSignatures['bottom-size'] || [],
        }
      : {};

    return {
      ...sizes,
      includeOutOfStock: false,
      fpls: gwpFeaturedProductLocationId,
      includeSaleItems: true,
    };
  }, [optionSignatures, gwpFeaturedProductLocationId]);

  const { isLoading, data } = useLoadProducts({
    filters: productFilters,
    options: {
      shouldCategorize: true,
    },
    enabled: shouldFetch,
    staleTime: STALE_TIME,
  });

  const shouldDisplayGwp = useMemo(() => {
    if (isLoading || !data?.payload) {
      return false;
    }
    const { products } = data.payload;
    return products.some(
      product =>
        product.available_quantity_master > 0 ||
        product.availableQuantityMaster > 0
    );
  }, [data, isLoading]);

  return useMemo(
    () => ({
      isGwpApplied,
      isGwpAvailable: gwpFeaturedProductLocationId ? isGwpAvailable : false,
      gwpFeaturedProductLocationId,
      gwpMinSubtotal,
      shouldDisplayGwp,
    }),
    [
      isGwpApplied,
      isGwpAvailable,
      gwpFeaturedProductLocationId,
      gwpMinSubtotal,
      shouldDisplayGwp,
    ]
  );
}

export default useGiftWithPurchase;
