import { defineMessages } from '@techstyle/react-intl';

export const countryMessages = defineMessages({
  AF: { id: 'site_account_billship.country_AF', defaultMessage: 'Afghanistan' },
  AX: {
    id: 'site_account_billship.country_AX',
    defaultMessage: 'Aland Islands',
  },
  AL: { id: 'site_account_billship.country_AL', defaultMessage: 'Albania' },
  DZ: { id: 'site_account_billship.country_DZ', defaultMessage: 'Algeria' },
  AS: {
    id: 'site_account_billship.country_AS',
    defaultMessage: 'American Samoa',
  },
  AD: { id: 'site_account_billship.country_AD', defaultMessage: 'Andorra' },
  AO: { id: 'site_account_billship.country_AO', defaultMessage: 'Angola' },
  AI: { id: 'site_account_billship.country_AI', defaultMessage: 'Anguilla' },
  AQ: { id: 'site_account_billship.country_AQ', defaultMessage: 'Antarctica' },
  AG: {
    id: 'site_account_billship.country_AG',
    defaultMessage: 'Antigua and Barbuda',
  },
  AR: { id: 'site_account_billship.country_AR', defaultMessage: 'Argentina' },
  AM: { id: 'site_account_billship.country_AM', defaultMessage: 'Armenia' },
  AW: { id: 'site_account_billship.country_AW', defaultMessage: 'Aruba' },
  AU: { id: 'site_account_billship.country_AU', defaultMessage: 'Australia' },
  AT: { id: 'site_account_billship.country_AT', defaultMessage: 'Austria' },
  AZ: { id: 'site_account_billship.country_AZ', defaultMessage: 'Azerbaijan' },
  BS: { id: 'site_account_billship.country_BS', defaultMessage: 'Bahamas' },
  BH: { id: 'site_account_billship.country_BH', defaultMessage: 'Bahrain' },
  BD: { id: 'site_account_billship.country_BD', defaultMessage: 'Bangladesh' },
  BB: { id: 'site_account_billship.country_BB', defaultMessage: 'Barbados' },
  BY: { id: 'site_account_billship.country_BY', defaultMessage: 'Belarus' },
  BE: { id: 'site_account_billship.country_BE', defaultMessage: 'Belgium' },
  BZ: { id: 'site_account_billship.country_BZ', defaultMessage: 'Belize' },
  BJ: { id: 'site_account_billship.country_BJ', defaultMessage: 'Benin' },
  BM: { id: 'site_account_billship.country_BM', defaultMessage: 'Bermuda' },
  BT: { id: 'site_account_billship.country_BT', defaultMessage: 'Bhutan' },
  BO: { id: 'site_account_billship.country_BO', defaultMessage: 'Bolivia' },
  BQ: {
    id: 'site_account_billship.country_BQ',
    defaultMessage: 'Bonaire, Saint Eustatius and Saba',
  },
  BA: {
    id: 'site_account_billship.country_BA',
    defaultMessage: 'Bosnia and Herzegovina',
  },
  BW: { id: 'site_account_billship.country_BW', defaultMessage: 'Botswana' },
  BV: {
    id: 'site_account_billship.country_BV',
    defaultMessage: 'Bouvet Island',
  },
  BR: { id: 'site_account_billship.country_BR', defaultMessage: 'Brazil' },
  IO: {
    id: 'site_account_billship.country_IO',
    defaultMessage: 'British Indian Ocean Territory',
  },
  VG: {
    id: 'site_account_billship.country_VG',
    defaultMessage: 'British Virgin Islands',
  },
  BN: { id: 'site_account_billship.country_BN', defaultMessage: 'Brunei' },
  BG: { id: 'site_account_billship.country_BG', defaultMessage: 'Bulgaria' },
  BF: {
    id: 'site_account_billship.country_BF',
    defaultMessage: 'Burkina Faso',
  },
  BI: { id: 'site_account_billship.country_BI', defaultMessage: 'Burundi' },
  KH: { id: 'site_account_billship.country_KH', defaultMessage: 'Cambodia' },
  CM: { id: 'site_account_billship.country_CM', defaultMessage: 'Cameroon' },
  CA: { id: 'site_account_billship.country_CA', defaultMessage: 'Canada' },
  CV: { id: 'site_account_billship.country_CV', defaultMessage: 'Cape Verde' },
  KY: {
    id: 'site_account_billship.country_KY',
    defaultMessage: 'Cayman Islands',
  },
  CF: {
    id: 'site_account_billship.country_CF',
    defaultMessage: 'Central African Republic',
  },
  TD: { id: 'site_account_billship.country_TD', defaultMessage: 'Chad' },
  CL: { id: 'site_account_billship.country_CL', defaultMessage: 'Chile' },
  CN: { id: 'site_account_billship.country_CN', defaultMessage: 'China' },
  CX: {
    id: 'site_account_billship.country_CX',
    defaultMessage: 'Christmas Island',
  },
  CC: {
    id: 'site_account_billship.country_CC',
    defaultMessage: 'Cocos Islands',
  },
  CO: { id: 'site_account_billship.country_CO', defaultMessage: 'Colombia' },
  KM: { id: 'site_account_billship.country_KM', defaultMessage: 'Comoros' },
  CK: {
    id: 'site_account_billship.country_CK',
    defaultMessage: 'Cook Islands',
  },
  CR: { id: 'site_account_billship.country_CR', defaultMessage: 'Costa Rica' },
  HR: { id: 'site_account_billship.country_HR', defaultMessage: 'Croatia' },
  CU: { id: 'site_account_billship.country_CU', defaultMessage: 'Cuba' },
  CW: { id: 'site_account_billship.country_CW', defaultMessage: 'Curacao' },
  CY: { id: 'site_account_billship.country_CY', defaultMessage: 'Cyprus' },
  CZ: {
    id: 'site_account_billship.country_CZ',
    defaultMessage: 'Czech Republic',
  },
  CD: {
    id: 'site_account_billship.country_CD',
    defaultMessage: 'Democratic Republic of the Congo',
  },
  DK: { id: 'site_account_billship.country_DK', defaultMessage: 'Denmark' },
  DJ: { id: 'site_account_billship.country_DJ', defaultMessage: 'Djibouti' },
  DM: { id: 'site_account_billship.country_DM', defaultMessage: 'Dominica' },
  DO: {
    id: 'site_account_billship.country_DO',
    defaultMessage: 'Dominican Republic',
  },
  TL: { id: 'site_account_billship.country_TL', defaultMessage: 'East Timor' },
  EC: { id: 'site_account_billship.country_EC', defaultMessage: 'Ecuador' },
  EG: { id: 'site_account_billship.country_EG', defaultMessage: 'Egypt' },
  SV: { id: 'site_account_billship.country_SV', defaultMessage: 'El Salvador' },
  GQ: {
    id: 'site_account_billship.country_GQ',
    defaultMessage: 'Equatorial Guinea',
  },
  ER: { id: 'site_account_billship.country_ER', defaultMessage: 'Eritrea' },
  EE: { id: 'site_account_billship.country_EE', defaultMessage: 'Estonia' },
  ET: { id: 'site_account_billship.country_ET', defaultMessage: 'Ethiopia' },
  FK: {
    id: 'site_account_billship.country_FK',
    defaultMessage: 'Falkland Islands',
  },
  FO: {
    id: 'site_account_billship.country_FO',
    defaultMessage: 'Faroe Islands',
  },
  FJ: { id: 'site_account_billship.country_FJ', defaultMessage: 'Fiji' },
  FI: { id: 'site_account_billship.country_FI', defaultMessage: 'Finland' },
  FR: { id: 'site_account_billship.country_FR', defaultMessage: 'France' },
  GF: {
    id: 'site_account_billship.country_GF',
    defaultMessage: 'French Guiana',
  },
  PF: {
    id: 'site_account_billship.country_PF',
    defaultMessage: 'French Polynesia',
  },
  TF: {
    id: 'site_account_billship.country_TF',
    defaultMessage: 'French Southern Territories',
  },
  GA: { id: 'site_account_billship.country_GA', defaultMessage: 'Gabon' },
  GM: { id: 'site_account_billship.country_GM', defaultMessage: 'Gambia' },
  GE: { id: 'site_account_billship.country_GE', defaultMessage: 'Georgia' },
  DE: { id: 'site_account_billship.country_DE', defaultMessage: 'Germany' },
  GH: { id: 'site_account_billship.country_GH', defaultMessage: 'Ghana' },
  GI: { id: 'site_account_billship.country_GI', defaultMessage: 'Gibraltar' },
  GR: { id: 'site_account_billship.country_GR', defaultMessage: 'Greece' },
  GL: { id: 'site_account_billship.country_GL', defaultMessage: 'Greenland' },
  GD: { id: 'site_account_billship.country_GD', defaultMessage: 'Grenada' },
  GP: { id: 'site_account_billship.country_GP', defaultMessage: 'Guadeloupe' },
  GU: { id: 'site_account_billship.country_GU', defaultMessage: 'Guam' },
  GT: { id: 'site_account_billship.country_GT', defaultMessage: 'Guatemala' },
  GG: { id: 'site_account_billship.country_GG', defaultMessage: 'Guernsey' },
  GN: { id: 'site_account_billship.country_GN', defaultMessage: 'Guinea' },
  GW: {
    id: 'site_account_billship.country_GW',
    defaultMessage: 'Guinea-Bissau',
  },
  GY: { id: 'site_account_billship.country_GY', defaultMessage: 'Guyana' },
  HT: { id: 'site_account_billship.country_HT', defaultMessage: 'Haiti' },
  HM: {
    id: 'site_account_billship.country_HM',
    defaultMessage: 'Heard Island and McDonald Islands',
  },
  HN: { id: 'site_account_billship.country_HN', defaultMessage: 'Honduras' },
  HK: { id: 'site_account_billship.country_HK', defaultMessage: 'Hong Kong' },
  HU: { id: 'site_account_billship.country_HU', defaultMessage: 'Hungary' },
  IS: { id: 'site_account_billship.country_IS', defaultMessage: 'Iceland' },
  IN: { id: 'site_account_billship.country_IN', defaultMessage: 'India' },
  ID: { id: 'site_account_billship.country_ID', defaultMessage: 'Indonesia' },
  IR: { id: 'site_account_billship.country_IR', defaultMessage: 'Iran' },
  IQ: { id: 'site_account_billship.country_IQ', defaultMessage: 'Iraq' },
  IE: { id: 'site_account_billship.country_IE', defaultMessage: 'Ireland' },
  IM: { id: 'site_account_billship.country_IM', defaultMessage: 'Isle of Man' },
  IL: { id: 'site_account_billship.country_IL', defaultMessage: 'Israel' },
  IT: { id: 'site_account_billship.country_IT', defaultMessage: 'Italy' },
  CI: { id: 'site_account_billship.country_CI', defaultMessage: 'Ivory Coast' },
  JM: { id: 'site_account_billship.country_JM', defaultMessage: 'Jamaica' },
  JP: { id: 'site_account_billship.country_JP', defaultMessage: 'Japan' },
  JE: { id: 'site_account_billship.country_JE', defaultMessage: 'Jersey' },
  JO: { id: 'site_account_billship.country_JO', defaultMessage: 'Jordan' },
  KZ: { id: 'site_account_billship.country_KZ', defaultMessage: 'Kazakhstan' },
  KE: { id: 'site_account_billship.country_KE', defaultMessage: 'Kenya' },
  KI: { id: 'site_account_billship.country_KI', defaultMessage: 'Kiribati' },
  XK: { id: 'site_account_billship.country_XK', defaultMessage: 'Kosovo' },
  KW: { id: 'site_account_billship.country_KW', defaultMessage: 'Kuwait' },
  KG: { id: 'site_account_billship.country_KG', defaultMessage: 'Kyrgyzstan' },
  LA: { id: 'site_account_billship.country_LA', defaultMessage: 'Laos' },
  LV: { id: 'site_account_billship.country_LV', defaultMessage: 'Latvia' },
  LB: { id: 'site_account_billship.country_LB', defaultMessage: 'Lebanon' },
  LS: { id: 'site_account_billship.country_LS', defaultMessage: 'Lesotho' },
  LR: { id: 'site_account_billship.country_LR', defaultMessage: 'Liberia' },
  LY: { id: 'site_account_billship.country_LY', defaultMessage: 'Libya' },
  LI: {
    id: 'site_account_billship.country_LI',
    defaultMessage: 'Liechtenstein',
  },
  LT: { id: 'site_account_billship.country_LT', defaultMessage: 'Lithuania' },
  LU: { id: 'site_account_billship.country_LU', defaultMessage: 'Luxembourg' },
  MO: { id: 'site_account_billship.country_MO', defaultMessage: 'Macao' },
  MK: { id: 'site_account_billship.country_MK', defaultMessage: 'Macedonia' },
  MG: { id: 'site_account_billship.country_MG', defaultMessage: 'Madagascar' },
  MW: { id: 'site_account_billship.country_MW', defaultMessage: 'Malawi' },
  MY: { id: 'site_account_billship.country_MY', defaultMessage: 'Malaysia' },
  MV: { id: 'site_account_billship.country_MV', defaultMessage: 'Maldives' },
  ML: { id: 'site_account_billship.country_ML', defaultMessage: 'Mali' },
  MT: { id: 'site_account_billship.country_MT', defaultMessage: 'Malta' },
  MH: {
    id: 'site_account_billship.country_MH',
    defaultMessage: 'Marshall Islands',
  },
  MQ: { id: 'site_account_billship.country_MQ', defaultMessage: 'Martinique' },
  MR: { id: 'site_account_billship.country_MR', defaultMessage: 'Mauritania' },
  MU: { id: 'site_account_billship.country_MU', defaultMessage: 'Mauritius' },
  YT: { id: 'site_account_billship.country_YT', defaultMessage: 'Mayotte' },
  MX: { id: 'site_account_billship.country_MX', defaultMessage: 'Mexico' },
  FM: { id: 'site_account_billship.country_FM', defaultMessage: 'Micronesia' },
  MD: { id: 'site_account_billship.country_MD', defaultMessage: 'Moldova' },
  MC: { id: 'site_account_billship.country_MC', defaultMessage: 'Monaco' },
  MN: { id: 'site_account_billship.country_MN', defaultMessage: 'Mongolia' },
  ME: { id: 'site_account_billship.country_ME', defaultMessage: 'Montenegro' },
  MS: { id: 'site_account_billship.country_MS', defaultMessage: 'Montserrat' },
  MA: { id: 'site_account_billship.country_MA', defaultMessage: 'Morocco' },
  MZ: { id: 'site_account_billship.country_MZ', defaultMessage: 'Mozambique' },
  MM: { id: 'site_account_billship.country_MM', defaultMessage: 'Myanmar' },
  NA: { id: 'site_account_billship.country_NA', defaultMessage: 'Namibia' },
  NR: { id: 'site_account_billship.country_NR', defaultMessage: 'Nauru' },
  NP: { id: 'site_account_billship.country_NP', defaultMessage: 'Nepal' },
  NL: { id: 'site_account_billship.country_NL', defaultMessage: 'Netherlands' },
  NC: {
    id: 'site_account_billship.country_NC',
    defaultMessage: 'New Caledonia',
  },
  NZ: { id: 'site_account_billship.country_NZ', defaultMessage: 'New Zealand' },
  NI: { id: 'site_account_billship.country_NI', defaultMessage: 'Nicaragua' },
  NE: { id: 'site_account_billship.country_NE', defaultMessage: 'Niger' },
  NG: { id: 'site_account_billship.country_NG', defaultMessage: 'Nigeria' },
  NU: { id: 'site_account_billship.country_NU', defaultMessage: 'Niue' },
  NF: {
    id: 'site_account_billship.country_NF',
    defaultMessage: 'Norfolk Island',
  },
  KP: { id: 'site_account_billship.country_KP', defaultMessage: 'North Korea' },
  MP: {
    id: 'site_account_billship.country_MP',
    defaultMessage: 'Northern Mariana Islands',
  },
  NO: { id: 'site_account_billship.country_NO', defaultMessage: 'Norway' },
  OM: { id: 'site_account_billship.country_OM', defaultMessage: 'Oman' },
  PK: { id: 'site_account_billship.country_PK', defaultMessage: 'Pakistan' },
  PW: { id: 'site_account_billship.country_PW', defaultMessage: 'Palau' },
  PS: {
    id: 'site_account_billship.country_PS',
    defaultMessage: 'Palestinian Territory',
  },
  PA: { id: 'site_account_billship.country_PA', defaultMessage: 'Panama' },
  PG: {
    id: 'site_account_billship.country_PG',
    defaultMessage: 'Papua New Guinea',
  },
  PY: { id: 'site_account_billship.country_PY', defaultMessage: 'Paraguay' },
  PE: { id: 'site_account_billship.country_PE', defaultMessage: 'Peru' },
  PH: { id: 'site_account_billship.country_PH', defaultMessage: 'Philippines' },
  PN: { id: 'site_account_billship.country_PN', defaultMessage: 'Pitcairn' },
  PL: { id: 'site_account_billship.country_PL', defaultMessage: 'Poland' },
  PT: { id: 'site_account_billship.country_PT', defaultMessage: 'Portugal' },
  QA: { id: 'site_account_billship.country_QA', defaultMessage: 'Qatar' },
  CG: {
    id: 'site_account_billship.country_CG',
    defaultMessage: 'Republic of the Congo',
  },
  RE: { id: 'site_account_billship.country_RE', defaultMessage: 'Reunion' },
  RO: { id: 'site_account_billship.country_RO', defaultMessage: 'Romania' },
  RU: { id: 'site_account_billship.country_RU', defaultMessage: 'Russia' },
  RW: { id: 'site_account_billship.country_RW', defaultMessage: 'Rwanda' },
  BL: {
    id: 'site_account_billship.country_BL',
    defaultMessage: 'Saint Barthelemy',
  },
  SH: {
    id: 'site_account_billship.country_SH',
    defaultMessage: 'Saint Helena',
  },
  KN: {
    id: 'site_account_billship.country_KN',
    defaultMessage: 'Saint Kitts and Nevis',
  },
  LC: { id: 'site_account_billship.country_LC', defaultMessage: 'Saint Lucia' },
  MF: {
    id: 'site_account_billship.country_MF',
    defaultMessage: 'Saint Martin',
  },
  PM: {
    id: 'site_account_billship.country_PM',
    defaultMessage: 'Saint PIerre and Miquelon',
  },
  VC: {
    id: 'site_account_billship.country_VC',
    defaultMessage: 'Saint Vincent and the Grenadines',
  },
  WS: { id: 'site_account_billship.country_WS', defaultMessage: 'Samoa' },
  SM: { id: 'site_account_billship.country_SM', defaultMessage: 'San Marino' },
  ST: {
    id: 'site_account_billship.country_ST',
    defaultMessage: 'Sao Tome and Principe',
  },
  SA: {
    id: 'site_account_billship.country_SA',
    defaultMessage: 'Saudi Arabia',
  },
  SN: { id: 'site_account_billship.country_SN', defaultMessage: 'Senegal' },
  RS: { id: 'site_account_billship.country_RS', defaultMessage: 'Serbia' },
  SC: { id: 'site_account_billship.country_SC', defaultMessage: 'Seychelles' },
  SL: {
    id: 'site_account_billship.country_SL',
    defaultMessage: 'Sierra Leone',
  },
  SG: { id: 'site_account_billship.country_SG', defaultMessage: 'Singapore' },
  SX: {
    id: 'site_account_billship.country_SX',
    defaultMessage: 'Sint Maarten',
  },
  SK: { id: 'site_account_billship.country_SK', defaultMessage: 'Slovakia' },
  SI: { id: 'site_account_billship.country_SI', defaultMessage: 'Slovenia' },
  SB: {
    id: 'site_account_billship.country_SB',
    defaultMessage: 'Solomon Islands',
  },
  SO: { id: 'site_account_billship.country_SO', defaultMessage: 'Somalia' },
  ZA: {
    id: 'site_account_billship.country_ZA',
    defaultMessage: 'South Africa',
  },
  GS: {
    id: 'site_account_billship.country_GS',
    defaultMessage: 'South Georgia and the South Sandwich Islands',
  },
  KR: { id: 'site_account_billship.country_KR', defaultMessage: 'South Korea' },
  SS: { id: 'site_account_billship.country_SS', defaultMessage: 'South Sudan' },
  ES: { id: 'site_account_billship.country_ES', defaultMessage: 'Spain' },
  LK: { id: 'site_account_billship.country_LK', defaultMessage: 'Sri Lanka' },
  SD: { id: 'site_account_billship.country_SD', defaultMessage: 'Sudan' },
  SR: { id: 'site_account_billship.country_SR', defaultMessage: 'Suriname' },
  SJ: {
    id: 'site_account_billship.country_SJ',
    defaultMessage: 'Svalbard and Jan Mayen',
  },
  SZ: { id: 'site_account_billship.country_SZ', defaultMessage: 'Swaziland' },
  SE: { id: 'site_account_billship.country_SE', defaultMessage: 'Sweden' },
  CH: { id: 'site_account_billship.country_CH', defaultMessage: 'Switzerland' },
  SY: { id: 'site_account_billship.country_SY', defaultMessage: 'Syria' },
  TW: { id: 'site_account_billship.country_TW', defaultMessage: 'Taiwan' },
  TJ: { id: 'site_account_billship.country_TJ', defaultMessage: 'Tajikistan' },
  TZ: { id: 'site_account_billship.country_TZ', defaultMessage: 'Tanzania' },
  TH: { id: 'site_account_billship.country_TH', defaultMessage: 'Thailand' },
  TG: { id: 'site_account_billship.country_TG', defaultMessage: 'Togo' },
  TK: { id: 'site_account_billship.country_TK', defaultMessage: 'Tokelau' },
  TO: { id: 'site_account_billship.country_TO', defaultMessage: 'Tonga' },
  TT: {
    id: 'site_account_billship.country_TT',
    defaultMessage: 'Trinidad and Tobago',
  },
  TN: { id: 'site_account_billship.country_TN', defaultMessage: 'Tunisia' },
  TR: { id: 'site_account_billship.country_TR', defaultMessage: 'Turkey' },
  TM: {
    id: 'site_account_billship.country_TM',
    defaultMessage: 'Turkmenistan',
  },
  TC: {
    id: 'site_account_billship.country_TC',
    defaultMessage: 'Turks and Caicos Islands',
  },
  TV: { id: 'site_account_billship.country_TV', defaultMessage: 'Tuvalu' },
  VI: {
    id: 'site_account_billship.country_VI',
    defaultMessage: 'U.S. Virgin Islands',
  },
  UG: { id: 'site_account_billship.country_UG', defaultMessage: 'Uganda' },
  UA: { id: 'site_account_billship.country_UA', defaultMessage: 'Ukraine' },
  AE: {
    id: 'site_account_billship.country_AE',
    defaultMessage: 'United Arab Emirates',
  },
  GB: {
    id: 'site_account_billship.country_GB',
    defaultMessage: 'United Kingdom',
  },
  US: {
    id: 'site_account_billship.country_US',
    defaultMessage: 'United States',
  },
  UM: {
    id: 'site_account_billship.country_UM',
    defaultMessage: 'United States Minor Outlying Islands',
  },
  UY: { id: 'site_account_billship.country_UY', defaultMessage: 'Uruguay' },
  UZ: { id: 'site_account_billship.country_UZ', defaultMessage: 'Uzbekistan' },
  VU: { id: 'site_account_billship.country_VU', defaultMessage: 'Vanuatu' },
  VA: { id: 'site_account_billship.country_VA', defaultMessage: 'Vatican' },
  VE: { id: 'site_account_billship.country_VE', defaultMessage: 'Venezuela' },
  VN: { id: 'site_account_billship.country_VN', defaultMessage: 'Vietnam' },
  WF: {
    id: 'site_account_billship.country_WF',
    defaultMessage: 'Wallis and Futuna',
  },
  EH: {
    id: 'site_account_billship.country_EH',
    defaultMessage: 'Western Sahara',
  },
  YE: { id: 'site_account_billship.country_YE', defaultMessage: 'Yemen' },
  ZM: { id: 'site_account_billship.country_ZM', defaultMessage: 'Zambia' },
  ZW: { id: 'site_account_billship.country_ZW', defaultMessage: 'Zimbabwe' },
});

export const countryList = Object.keys(countryMessages);
