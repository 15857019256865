import React, { useMemo } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import {
  getRegion,
  isRihannasPickProduct,
  isNewProduct,
  isBestSellerProduct,
  isBackInStockProduct,
  isProductCustomizable,
  isTopRatedProduct,
  isTrendingProduct,
  isClfProduct,
  isExclusiveProduct,
  isUnlockedXclusiveProduct,
  isYouniversalProduct,
  isProductSet as checkIsProductSet,
  isSoldOut as checkIsSoldOut,
  getWarehouseQuantity,
  isFWProduct,
  isComingSoonProduct,
  isPreorderable as checkIsPreorderable,
  shouldProductUseCustomTag,
} from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as CLFFlag } from '../CLFFlag';
import { Component as FWFlag } from '../FWFlag';
import { Component as LowStockFlag } from '../LowStockFlag';
import { Component as ProductFlag } from '../ProductFlag';
import XclusiveFlag from '../XclusiveFlag/XclusiveFlag';

const locationLowStockIndicatorMasterProduct = config.get(
  'public.quantityRules.locationLowStockIndicator.masterProduct'
);

const Flag = styled.div``;

const StyledXclusiveFlag = styled(XclusiveFlag)`
  line-height: 10px;
`;

const StyledLowStockFlag = styled(LowStockFlag)`
  display: flex;
  align-items: center;
  height: 22px;
  ${v1`
    font-size: 12px;
    padding: 4px ${({ theme }) => theme.spacing.tiny}px;
    background-color: ${props => props.theme.colors.green200};
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.green700};
  `}
  ${v2`
    padding: 2px 8px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

export default function ProductDetailFlag({ className, product }) {
  const isBorderfreeCustomer = useSelector(
    state => state.borderfree.isBorderfreeCustomer
  );

  const shouldProductUseCustom = useSelector(state =>
    shouldProductUseCustomTag(state, { product })
  );

  const isCustomizable = useSelector(state =>
    isProductCustomizable(state, { product })
  );
  const isRihannasPick = useSelector(state =>
    isRihannasPickProduct(state, { product })
  );
  const isNew = useSelector(state => isNewProduct(state, { product }));
  const isBestSeller = useSelector(state =>
    isBestSellerProduct(state, { product })
  );
  const isBackInStock = useSelector(state =>
    isBackInStockProduct(state, { product })
  );
  const isTopRated = useSelector(state =>
    isTopRatedProduct(state, { product })
  );
  const isTrending = useSelector(state =>
    isTrendingProduct(state, { product })
  );
  const isClf = useSelector(state => isClfProduct(state, { product }));
  const isUnlockedXclusive = useSelector(state =>
    isUnlockedXclusiveProduct(state, { product })
  );
  const isYouniversal = useSelector(state =>
    isYouniversalProduct(state, { product })
  );
  const isFW = useSelector(state => isFWProduct(state, { product }));
  const isExclusive = useSelector(state =>
    isExclusiveProduct(state, { product })
  );
  const isPreorderable = useSelector(state =>
    checkIsPreorderable(state, { product })
  );

  const isProductSet = checkIsProductSet(product);
  const isSoldOut = useSelector(state => checkIsSoldOut(state, { product }));
  const isComingSoon = useSelector(state =>
    isComingSoonProduct(state, { product })
  );
  const quantity = useSelector(state =>
    getWarehouseQuantity(state, { product })
  );
  const warehouseLocation = useSelector(getRegion);
  const isLowStockEligible =
    locationLowStockIndicatorMasterProduct[warehouseLocation];
  const isLowStockElibile = quantity <= isLowStockEligible.lowStockMax;

  const flag = useMemo(() => {
    if (!isSoldOut && !isLowStockElibile && shouldProductUseCustom) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.custom_tag_label"
            defaultMessage="Custom Tag"
          />
        </ProductFlag>
      );
    } else if (isPreorderable) {
      return (
        <ProductFlag productTag="shopItEarly">
          <FormattedMessage
            id="global_checkout.pre_order_label"
            defaultMessage="Pre-order"
          />
        </ProductFlag>
      );
    } else if (isCustomizable) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.customizable"
            defaultMessage="Customizable"
          />
        </ProductFlag>
      );
    } else if (isComingSoon) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.coming_soon_label"
            defaultMessage="Coming Soon"
          />
        </ProductFlag>
      );
    } else if (isUnlockedXclusive) {
      return (
        <ProductFlag productTag="unlockedExclusive">
          <FormattedMessage
            id="global_checkout.unlocked_xclusive_label"
            defaultMessage="Unlocked Xclusive"
          />
        </ProductFlag>
      );
    } else if (isSoldOut) {
      return <StyledLowStockFlag isMasterProduct quantity={quantity} />;
    } else if (!isProductSet && !isSoldOut && isLowStockElibile) {
      return <StyledLowStockFlag isMasterProduct quantity={quantity} />;
    } else if (isYouniversal) {
      return (
        <ProductFlag productTag="youniversal">
          <FormattedMessage
            id="global_checkout.youniversal"
            defaultMessage="YOUniversal"
          />
        </ProductFlag>
      );
    } else if (isRihannasPick) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.rihannas_pick_label"
            defaultMessage="Rihanna’s Pick"
          />
        </ProductFlag>
      );
    } else if (isNew) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.new_label"
            defaultMessage="New"
          />
        </ProductFlag>
      );
    } else if (isBestSeller) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.best_seller_label"
            defaultMessage="Best seller"
          />
        </ProductFlag>
      );
    } else if (isBackInStock) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.back_in_stock_label"
            defaultMessage="Back in Stock"
          />
        </ProductFlag>
      );
    } else if (isTopRated) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.top_rated_label"
            defaultMessage="Top Rated"
          />
        </ProductFlag>
      );
    } else if (isTrending) {
      return (
        <ProductFlag>
          <FormattedMessage
            id="global_checkout.trending_label"
            defaultMessage="Trending"
          />
        </ProductFlag>
      );
    } else if (isFW) {
      return <FWFlag />;
    } else if (isExclusive && !isBorderfreeCustomer) {
      return <StyledXclusiveFlag />;
    } else if (isClf) {
      return <CLFFlag />;
    } else {
      return null;
    }
  }, [
    isBackInStock,
    isBestSeller,
    isBorderfreeCustomer,
    isClf,
    isComingSoon,
    isCustomizable,
    isExclusive,
    isFW,
    isNew,
    isPreorderable,
    isProductSet,
    isRihannasPick,
    isSoldOut,
    isTopRated,
    isTrending,
    isUnlockedXclusive,
    isYouniversal,
    isLowStockElibile,
    quantity,
    shouldProductUseCustom,
  ]);

  return (
    <Flag className={className} data-autotag="pdp-item-flag">
      {flag}
    </Flag>
  );
}

ProductDetailFlag.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
};
