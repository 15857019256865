import React, {
  createContext,
  useMemo,
  useCallback,
  useReducer,
  useContext,
} from 'react';

import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getNewDateFunction } from '@techstyle/redux-core';

import { loadProduct, loadProductSet } from '../../actions/products';
import { loadReviews } from '../../actions/reviews';
import { getDefaultSingleSelections } from '../../utils/productDetail';
import {
  getCurrentSelections,
  getDefaultColorForQuickView,
} from '../../utils/quickView';

const Context = createContext();

export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_GWP = 'ADD_TO_CART_GWP';
export const UPDATE_CART_ITEM_GWP = 'UPDATE_CART_ITEM_GWP';
export const ADD_TO_CART_UPSELL = 'ADD_TO_CART_UPSELL';

const initialState = {
  isOpen: false,
  bundleAlertIsOpen: false,
  isLoading: false,
  isBundleUpdateLoading: false,
  cartItem: null,
  currentProduct: null,
  selections: null,
  cartInteraction: UPDATE_CART_ITEM,
  source: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_QUICK_VIEW_MODAL':
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case 'CLOSE_QUICK_VIEW_MODAL':
      return initialState;
    case 'SEGUE_TO_BUNDLE_ALERT_MODAL':
      return {
        ...state,
        isOpen: false,
      };
    case 'SET_DEFAULT_ITEM':
      return {
        ...state,
        defaultItem: action.defaultItem,
      };
    case 'SET_CURRENT_PRODUCT':
      return {
        ...state,
        currentProduct: action.currentProduct,
      };
    case 'SET_SELECTIONS':
      return {
        ...state,
        selections: action.selections,
      };
    case 'SET_START_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_STOP_LOADING':
      return {
        ...state,
        isLoading: false,
      };
    case 'BUNDLE_ALERT_OPEN':
      return {
        ...state,
        bundleAlertIsOpen: true,
      };
    case 'BUNDLE_ALERT_CLOSE':
      return initialState;
    case 'START_BUNDLE_UPDATE_LOADER':
      return { ...state, isBundleUpdateLoading: true };
    case 'STOP_BUNDLE_UPDATE_LOADER':
      return { ...state, isBundleUpdateLoading: false };
    default:
      throw new Error('Unrecognized action passed to QuickViewProvider');
  }
};

export const useQuickViewContext = () => {
  return useContext(Context);
};

function QuickViewProvider({ children }) {
  const dispatchAction = useDispatch();
  const getNewDate = useSelector(getNewDateFunction);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { cartInteraction } = state;
  const userProfile = useSelector(state => state.customer);

  const openQuickViewModal = useCallback(options => {
    const { cartInteraction = UPDATE_CART_ITEM, source = '' } = options ?? {};

    dispatch({
      type: 'OPEN_QUICK_VIEW_MODAL',
      payload: {
        cartInteraction,
        source,
      },
    });
  }, []);

  const closeQuickViewModal = useCallback(() => {
    dispatch({ type: 'CLOSE_QUICK_VIEW_MODAL' });
  }, []);

  const closeQuickViewModalForBundleAlert = useCallback(() => {
    dispatch({ type: 'SEGUE_TO_BUNDLE_ALERT_MODAL' });
  }, []);

  const openBundleAlertModal = useCallback(() => {
    dispatch({ type: 'BUNDLE_ALERT_OPEN' });
  }, []);

  const closeBundleAlertModal = useCallback(() => {
    dispatch({ type: 'BUNDLE_ALERT_CLOSE' });
  }, []);

  const setSelections = useCallback(selections => {
    dispatch({ type: 'SET_SELECTIONS', selections });
  }, []);

  const setCurrentProduct = useCallback(currentProduct => {
    dispatch({ type: 'SET_CURRENT_PRODUCT', currentProduct });
  }, []);

  const setStartBundleUpdateLoading = useCallback(() => {
    dispatch({ type: 'START_BUNDLE_UPDATE_LOADER' });
  });

  const setStopBundleUpdateLoading = useCallback(() => {
    dispatch({ type: 'STOP_BUNDLE_UPDATE_LOADER' });
  });

  const setDefaultProductWithSelections = useCallback(
    async (productProp, isProductSet, useDefaultSelections) => {
      const defaultSelections = {};
      const { masterProductId } = productProp;
      const masterProductIds = [];

      dispatch({ type: 'SET_START_LOADING' });
      dispatch({ type: 'SET_DEFAULT_ITEM', defaultItem: productProp });

      if (masterProductId) {
        try {
          const result = await dispatchAction(
            isProductSet
              ? loadProductSet(masterProductId)
              : loadProduct(masterProductId)
          );

          if (result.payload) {
            dispatch({
              type: 'SET_CURRENT_PRODUCT',
              currentProduct: result.payload,
            });

            const products = isProductSet
              ? result.payload.products_in_set
              : [result.payload];

            products.forEach(product => {
              masterProductIds.push(product.master_product_id);

              if (!useDefaultSelections) {
                const { master_product_id: masterProductId } = product;
                const currentSelections = getCurrentSelections(
                  product,
                  isProductSet,
                  productProp,
                  getNewDate
                );
                defaultSelections[masterProductId] = currentSelections;
              } else {
                const sizeCache = JSON.parse(
                  localStorage.getItem('selectedSize')
                );

                const defaultSelection = getDefaultSingleSelections(
                  product,
                  userProfile,
                  false,
                  sizeCache
                );

                const defaultSelectionColorForQuickView =
                  getDefaultColorForQuickView({
                    masterProduct: product,
                    individualItemInCart: productProp,
                  });

                defaultSelections[masterProductId] = {
                  ...defaultSelection,
                  color: defaultSelectionColorForQuickView,
                };
              }
            });

            await dispatch({
              type: 'SET_SELECTIONS',
              selections: defaultSelections,
            });
          }
        } catch (err) {
          Sentry.captureException(err);
        }
      }

      await Promise.all(
        masterProductIds.map(async id => {
          return await dispatchAction(
            loadReviews({
              count: 5,
              masterProductId,
              shouldUseCache: false,
              groupCode: defaultSelections[id].group_code,
              isSet: true,
              combineLinkedProduct:
                defaultSelections[id].linked_master_product != null,
            })
          );
        })
      );

      dispatch({ type: 'SET_STOP_LOADING' });
    },
    [dispatchAction, getNewDate, userProfile]
  );

  const enabledInteractions = useMemo(() => {
    return {
      isAddToCartUpsellEnabled: cartInteraction === ADD_TO_CART_UPSELL,
      isUpdateCartItemEnabled: cartInteraction === UPDATE_CART_ITEM,
      isAddToCartGwpEnabled: cartInteraction === ADD_TO_CART_GWP,
      isUpdateCartItemGwpEnabled: cartInteraction === UPDATE_CART_ITEM_GWP,
      isGwpModeEnabled:
        cartInteraction === ADD_TO_CART_GWP ||
        cartInteraction === UPDATE_CART_ITEM_GWP,
    };
  }, [cartInteraction]);

  const value = useMemo(
    () => ({
      openQuickViewModal,
      closeQuickViewModal,
      closeQuickViewModalForBundleAlert,
      openBundleAlertModal,
      closeBundleAlertModal,
      setSelections,
      setCurrentProduct,
      setDefaultProductWithSelections,
      setStartBundleUpdateLoading,
      setStopBundleUpdateLoading,
      ...enabledInteractions,
      ...state,
    }),
    [
      openQuickViewModal,
      closeQuickViewModal,
      closeQuickViewModalForBundleAlert,
      openBundleAlertModal,
      closeBundleAlertModal,
      setSelections,
      setCurrentProduct,
      setDefaultProductWithSelections,
      setStartBundleUpdateLoading,
      setStopBundleUpdateLoading,
      state,
      enabledInteractions,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

QuickViewProvider.Context = Context;
QuickViewProvider.Consumer = Context.Consumer;

export default QuickViewProvider;
QuickViewProvider.propTypes = {
  children: PropTypes.node,
};
