import React from 'react';

import { ThemeProvider } from 'styled-components';

import newTheme from '../../styles/newTheme';
import defaultTheme from '../../styles/theme';
import { useLDFlags } from '../../utils/LD/useLDFlags';

const themes = {
  defaultTheme: defaultTheme,
  newTheme: newTheme,
};

const DynamicThemeProvider = ({ children }) => {
  const { 'theme-to-enable': themeToEnable } = useLDFlags();

  if (themeToEnable === 'defaultTheme') {
    return (
      <ThemeProvider theme={themes.defaultTheme}>{children}</ThemeProvider>
    );
  }

  return children;
};

export default DynamicThemeProvider;
