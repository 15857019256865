import styled, { css, keyframes } from 'styled-components';

import { desktop, mobile } from '../../styles';
import { v1, v2 } from '../../utils/themeVersioning';

const bounceIn = keyframes`
  0% {
    transform: scale(0);
    top: 13px;
    left: -17px;
  }
  85% {
    transform: scale(1.2);
    top: 13px;
    left: -6px;
  }
  100% {
    transform: scale(1);
  }
`;

const bounceInMobile = keyframes`
  0% {
    transform: scale(0);
    left: 15px;
  }
  85% {
    transform: scale(1.2);
    left: 29px;
  }
  100% {
    transform: scale(1);
  }
`;

const QuantityBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  padding: 0 4px 1px;
  height: 18px;
  min-width: 18px;
  line-height: 15px;
  letter-spacing: 0;
  border-radius: 55px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.promo};
  color: ${props => props.theme.colors.white};

  ${v1`
      right: -4px;
      top: 11px;
    `}
  ${v2`
    height: 12px;
    font-size: 9px;
    padding: 0 1px;
    line-height: 10px;
    min-width: 12px;
    top: -1px;
    left: 14px;
    font-weight: normal;
    border: none;
    background-color: ${props => props.theme.colors.lavender};
    color: ${props => props.theme.colors.black};
    `}

  ${props =>
    props.shouldBadgeAnimate &&
    css`
      ${mobile`
        animation: ${bounceInMobile} 0.221s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-delay: ${props => (props.hasNoDelay ? 0 : 4)}s;
      `}
      ${desktop`
        animation: ${bounceIn} 0.221s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-delay: ${props => (props.hasNoDelay ? 0 : 4)}s;
          `}
      transition-timing-function: ease;
    `}
`;

export default QuantityBadge;
