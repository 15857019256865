import { connect } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';
import { injectIntl } from '@techstyle/react-intl';

import { trackNavItemClick } from '../../actions/navigation';
import { getAdditionalSaleDiscount } from '../../utils/selectors';

import NavMenuDesktop from './NavMenuDesktop';

function mapStateToProps(state) {
  return {
    additionalDiscounts: getAdditionalSaleDiscount(state),
    isVip: getMembership(state).isVip,
  };
}

const mapDispatchToProps = {
  trackNavItemClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NavMenuDesktop));
