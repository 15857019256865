import React, {
  useLayoutEffect,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const defaultOptions = {
  threshold: 1,
  root: null,
  rootMargin: '0px',
};

export function useIntersectionObserver<T extends Element>(
  options: IntersectionObserverInit = defaultOptions
): [React.RefCallback<T>, IntersectionObserverEntry | null] {
  const elementRef = useRef<T | null>(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  const previousObserver = useRef(null);

  const fullOptions = useMemo(() => {
    return {
      ...defaultOptions,
      ...options,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const setupObserver = useCallback(() => {
    if (elementRef.current) {
      if (previousObserver.current) {
        previousObserver.current.disconnect();
        previousObserver.current = null;
      }

      const observer = new IntersectionObserver(
        ([entry]) => {
          setEntry(entry);
        },
        {
          ...fullOptions,
        }
      );

      observer.observe(elementRef.current);
      previousObserver.current = observer;
    }
  }, [fullOptions]);

  const customRef: React.RefCallback<T> = useCallback(
    node => {
      if (node?.nodeType === Node.ELEMENT_NODE) {
        elementRef.current = node;
        setupObserver();
      }
    },
    [setupObserver]
  );

  useEffect(() => {
    if (elementRef.current) {
      setupObserver();
    }
  }, [setupObserver]);

  return [customRef, entry];
}

export function useMeasure<T extends Element>(): [
  React.RefCallback<T>,
  {
    width: number | null;
    height: number | null;
  }
] {
  const [dimensions, setDimensions] = useState({
    width: null,
    height: null,
  });

  const previousObserver = useRef(null);

  const customRef: React.RefCallback<T> = useCallback(node => {
    if (previousObserver.current) {
      previousObserver.current.disconnect();
      previousObserver.current = null;
    }

    if (node?.nodeType === Node.ELEMENT_NODE) {
      const observer = new ResizeObserver(([entry]) => {
        if (entry && entry.borderBoxSize) {
          const { inlineSize: width, blockSize: height } =
            entry.borderBoxSize[0];

          setDimensions({ width, height });
        }
      });

      observer.observe(node);
      previousObserver.current = observer;
    }
  }, []);

  return [customRef, dimensions];
}

const defaultMutationObserverOptions: MutationObserverInit = {
  attributes: true,
  childList: true,
  subtree: true,
};

export function useMutationObserver<T extends Element>(
  options: MutationObserverInit = defaultMutationObserverOptions
): [React.RefCallback<T>, MutationRecord | null] {
  const elementRef = useRef<T | null>(null);
  const previousObserver = useRef(null);
  const [observerEntry, setObserverEntry] = useState<MutationRecord | null>(
    null
  );

  const fullOptions = useMemo(() => {
    return {
      ...defaultMutationObserverOptions,
      ...options,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const setupObserver = useCallback(() => {
    if (elementRef.current) {
      if (previousObserver.current) {
        previousObserver.current.disconnect();
        previousObserver.current = null;
      }

      const observer = new MutationObserver(([entry]) => {
        setObserverEntry(entry);
      });

      observer.observe(elementRef.current, fullOptions);
      previousObserver.current = observer;
    }
  }, [fullOptions]);

  const customRef: React.RefCallback<T> = useCallback(
    node => {
      if (node?.nodeType === Node.ELEMENT_NODE) {
        elementRef.current = node;
        setupObserver();
      }
    },
    [setupObserver]
  );

  useEffect(() => {
    if (elementRef.current) {
      setupObserver();
    }
  }, [setupObserver]);

  return [customRef, observerEntry];
}

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
