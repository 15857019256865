import React from 'react';

import ToastContext from '../components/ToastContext/ToastContext';

const toastWrapper = ComposedComponent => {
  const name =
    ComposedComponent.displayName || ComposedComponent.name || 'Component';

  class WrappedComponent extends React.PureComponent {
    static displayName = `toastWrapper(${name})`;

    render() {
      return (
        <ToastContext.Consumer>
          {({ showMessage, hideMessage }) => (
            <ComposedComponent
              {...this.props}
              showToastMessage={showMessage}
              hideToastMessage={hideMessage}
            />
          )}
        </ToastContext.Consumer>
      );
    }
  }

  return WrappedComponent;
};

export default toastWrapper;
