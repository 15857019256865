import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import config from 'config';
import Sticky from 'react-stickynode';
import { useTheme } from 'styled-components';

import { mobile } from '../../../styles';
import AutoHidingSticky from '../../AutoHidingSticky/AutoHidingSticky';

const doubleNavThreshold = config.get('public.navigation.doubleNavThreshold');

const StickyStateContext = createContext(false);

export const useIsStickyFixed = () => {
  const isStickyFixed = useContext(StickyStateContext);
  return isStickyFixed;
};

export function MaybeSticky({ children }) {
  const theme = useTheme();

  const [isStickyFixed, setIsStickyFixed] = useState(false);

  const handleStickyStateChange = useCallback(
    status => {
      if (status.status === Sticky.STATUS_FIXED) {
        setIsStickyFixed(true);
      } else {
        setIsStickyFixed(false);
      }
    },
    [setIsStickyFixed]
  );

  const { autoHideNav, mobileProducts } = useMemo(
    () => theme.context,
    [theme.context]
  );

  if (autoHideNav && mobileProducts) {
    return children;
  }
  return (
    <StickyStateContext.Provider value={isStickyFixed}>
      {autoHideNav ? (
        <AutoHidingSticky
          autoHideBreakpoint={mobile}
          doubleNavThreshold={doubleNavThreshold}
          onStateChange={handleStickyStateChange}
          theme={theme}
          className={undefined}
          shouldPreserveOnScrollDown={undefined}
        >
          {children}
        </AutoHidingSticky>
      ) : (
        <Sticky onStateChange={handleStickyStateChange}>{children}</Sticky>
      )}
    </StickyStateContext.Provider>
  );
}
