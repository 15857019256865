import React from 'react';

import { useSelector } from 'react-redux';

import { Price } from '@techstyle/react-components-legacy';
import { useIntl } from '@techstyle/react-intl';

import { getDomainCurrency } from '../../utils/selectors';

type ExtendedPriceProps = {
  trimZeros?: boolean;
  amount: number;
  rest?: any;
};

const ExtendedPrice = ({
  trimZeros = true,
  amount,
  ...props
}: ExtendedPriceProps) => {
  const intl = useIntl();
  const currency = useSelector(state => getDomainCurrency(state));

  return (
    <Price
      formatNumber={amount =>
        intl.formatNumber(amount, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
      amount={amount}
      currency={currency}
      trimZeros={trimZeros}
      {...props}
    />
  );
};

export default ExtendedPrice;
