import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
  height: 52px;
  width: 100%;
  background-color: ${props => props.theme.colors.lavender200};
  border-bottom: 1px solid ${props => props.theme.colors.lavender250};
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Message = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.default};
  font-size: 12;
  text-align: center;
`;

const AnimationContainer = styled.div`
  top: ${props => (props.shouldShowGoalMessage ? -52 : 0)}px;
  transition: top ${props => (props.shouldShowGoalMessage ? 400 : 600)}ms
    ease-in-out;
  position: relative;
  height: 100%;
  width: 100%;
`;

const AnimationProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const AnimationGoalWrapper = styled(AnimationProgressWrapper)`
  top: 52px;
`;

const PromotionProgressBarThresholdAnimation = ({
  className,
  progressBar,
  progressMessage,
  goalMessage,
  onTransitionStart = () => {},
  onTransitionEnd = () => {},
  shouldShowGoalMessage,
}) => {
  return (
    <AnimationWrapper className={className}>
      <Wrapper>
        <AnimationContainer
          shouldShowGoalMessage={shouldShowGoalMessage}
          onTransitionEnd={onTransitionEnd}
          onTransitionStart={onTransitionStart}
        >
          <AnimationProgressWrapper>
            {progressBar}
            <Message>{progressMessage}</Message>
          </AnimationProgressWrapper>

          <AnimationGoalWrapper>
            <Message>{goalMessage}</Message>
          </AnimationGoalWrapper>
        </AnimationContainer>
      </Wrapper>
    </AnimationWrapper>
  );
};

PromotionProgressBarThresholdAnimation.propTypes = {
  className: PropTypes.string,
  progressBar: PropTypes.element,
  progressMessage: PropTypes.element,
  goalMessage: PropTypes.element,
  onTransitionStart: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  shouldShowGoalMessage: PropTypes.bool,
};

export default PromotionProgressBarThresholdAnimation;
