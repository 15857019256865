import { handleActions } from 'redux-actions';

import { LOAD_WAITLIST_SUCCESS } from '../../actions/account';

const defaultState = {
  fetchedDate: null,
  items: [],
};

export default handleActions(
  {
    [LOAD_WAITLIST_SUCCESS]: (state, action) => {
      if (action.meta.fromCache) {
        return state;
      }
      return { ...action.payload, fetchedDate: action.meta.fetchedDate };
    },
  },
  defaultState
);
