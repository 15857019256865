import { handleActions } from 'redux-actions';

import {
  GET_BYO_CONFIG_REQUEST,
  GET_BYO_CONFIG_SUCCESS,
  GET_BYO_CONFIG_FAILURE,
} from '../actions/byo';

const defaultState = {
  config: [],
};

export default handleActions(
  {
    [GET_BYO_CONFIG_REQUEST]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GET_BYO_CONFIG_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      config: action.payload,
    }),
    [GET_BYO_CONFIG_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
    }),
  },
  defaultState
);
