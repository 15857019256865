import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Heading as BaseHeading } from '@techstyle/react-components';

import { v1, v2 } from '../utils/themeVersioning';

const defaultTypeScale = [48, 42, 36, 30, 24, 18];

const getFontSize = props => {
  const typeScale =
    (props.theme.heading && props.theme.heading.typeScale) || defaultTypeScale;
  const value = typeScale[props.level - 1] || defaultTypeScale[props.level - 1];
  return typeof value === 'number' ? `${value}px` : value;
};

const propStyles = css`
  ${v1`
    font-size: ${getFontSize};
    font-weight: ${props => (props.$bold ? 'bold' : 'normal')};
    line-height: 1.5;
  `}
  text-transform: ${props => (props.$uppercase ? 'uppercase' : 'initial')};
  margin: 0;
  text-align: ${props => props.align};
  color: ${props =>
    props.$white ? props.theme.colors.white : props.theme.colors.default};
  ${props => (props.theme.heading ? props.theme.heading.defaultStyle : '')};
`;

const Heading = styled(BaseHeading)`
  ${v2`
    ${props => {
      const variantLevel = props.level <= 3 ? `h${props.level}` : null;

      if (variantLevel) {
        return props.theme.header.variants[variantLevel];
      }
    }}
  `}
`;

const StyledHeading = ({
  white = false,
  bold = false,
  uppercase = false,
  ...props
}) => <Heading $white={white} $bold={bold} $uppercase={uppercase} {...props} />;

export const Header = styled(StyledHeading)`
  ${propStyles};
`;

StyledHeading.propTypes = {
  white: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};

Header.defaultProps = {
  align: 'inherit',
  level: 1,
};
