import { handleActions } from 'redux-actions';

import { LOAD_RETURNS_SETTINGS_SUCCESS } from '../../actions/account';

const defaultState = {
  returnReasons: [],
  restockingFees: [],
};

export default handleActions(
  {
    [LOAD_RETURNS_SETTINGS_SUCCESS]: (state, action) => {
      const { returnReasons, restockingFees } = action.payload;
      return {
        ...state,
        returnReasons,
        restockingFees,
      };
    },
  },
  defaultState
);
