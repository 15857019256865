import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading } from '@techstyle/react-components';

import { desktop, mobile } from '../../styles';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as ProductDetailFlag } from '../ProductDetailFlag';

const ProductNameContainer = styled.div`
  flex: 0 0 80%;
  margin-bottom: 0px;

  ${desktop`
    order: 1;
  `};
  ${mobile`
    flex: 0 0 100%;
  `};
`;

const ProductName = styled(Heading).attrs({
  level: 1,
})`
  display: inline-block;
  ${v1`
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  `}

  ${v2`
    ${({ theme }) => theme.header.variants.h3.textStyles}
  `}
`;

const DesktopFlagContainer = styled.div`
  display: none;

  ${desktop`
    display: inline-block;
    margin-bottom: 4px;
  `};
`;

const ProductDetailName = ({
  className,
  isProductFlagHidden = false,
  product,
}) => (
  <ProductNameContainer className={className}>
    {!isProductFlagHidden ? (
      <>
        <DesktopFlagContainer>
          <ProductDetailFlag product={product} />
        </DesktopFlagContainer>
      </>
    ) : null}
    <ProductName data-autotag="pdp-product-name-main">
      {product.label}
    </ProductName>
  </ProductNameContainer>
);

ProductDetailName.propTypes = {
  className: PropTypes.string,
  product: PropTypes.shape({
    master_product_id: PropTypes.number,
    label: PropTypes.string,
    /* Array of sizes for current/selected product */
    product_id_object_list: PropTypes.arrayOf(
      PropTypes.shape({
        available_quantity: PropTypes.number,
        label_instance: PropTypes.string,
        size: PropTypes.string,
      })
    ),
    /* Product Sets have nested products */
    product_in_sets: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          master_product_id: PropTypes.number,
          product_id_object_list: PropTypes.array,
          related_product_id_object_list: PropTypes.array,
        }),
      })
    ),
    /* Array of colors with corresponding product ids */
    related_product_id_object_list: PropTypes.arrayOf(
      PropTypes.shape({
        /* Array of sizes for color */
        product_id_object_list: PropTypes.array,
        related_product_id: PropTypes.number,
      })
    ),
  }),
  isProductFlagHidden: PropTypes.bool,
};

ProductDetailName.ProductNameContainer = ProductNameContainer;
ProductDetailName.ProductName = ProductName;

export default ProductDetailName;
