import { localStorage } from './helpers';

export const getSizeFromCache = ({ profile }) => {
  const sizeFromCache = JSON.parse(localStorage.getItem('selectedSize'));

  if (!sizeFromCache) {
    return null;
  }

  // We filter out existing profile sizes, we only care about
  // sizes that are blank/empty
  const uniqueSize = Object.keys(sizeFromCache)
    .filter(key => profile[key] === '' && isNotDualSize(sizeFromCache[key]))
    .reduce((obj, key) => {
      obj[key] = sizeFromCache[key];
      return obj;
    }, {});

  if (Object.keys(uniqueSize).length >= 1) {
    return uniqueSize;
  }

  return null;
};

export function isNotDualSize(size) {
  return !size.includes('/') && size !== 'OSFM' && !size.includes('-');
}
