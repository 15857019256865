import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.span`
  min-height: 12px;
  margin-left: ${props => props.theme.spacing.tiny}px;
  line-height: 12px;
  padding: 0 3px;
  background-color: ${props => props.theme.colors.lavender250};
  font-size: 8px;
  letter-spacing: 1px;
  font-weight: bold;
  color: ${props => props.theme.colors.default};
`;

const Badge = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Badge.defaultProps = {
  className: '',
  children: null,
};

Badge.Wrapper = Wrapper;

export default Badge;
