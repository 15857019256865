export const EMP_USER_TOKEN_REQUEST = 'EMP_USER_TOKEN_REQUEST';
export const EMP_USER_TOKEN_SUCCESS = 'EMP_USER_TOKEN_SUCCESS';
export const EMP_USER_TOKEN_FAILURE = 'EMP_USER_TOKEN_FAILURE';

export const BILL_ME_NOW_REQUEST = 'BILL_ME_NOW_REQUEST';
export const BILL_ME_NOW_SUCCESS = 'BILL_ME_NOW_SUCCESS';
export const BILL_ME_NOW_FAILURE = 'BILL_ME_NOW_FAILURE';

export function getUserToken(searchParams) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/tokens',
      method: 'GET',
      searchParams,
      actions: [
        EMP_USER_TOKEN_REQUEST,
        EMP_USER_TOKEN_SUCCESS,
        EMP_USER_TOKEN_FAILURE,
      ],
    },
  };
}

export function billMeNow() {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/period/purchase',
      method: 'POST',
      actions: [BILL_ME_NOW_REQUEST, BILL_ME_NOW_SUCCESS, BILL_ME_NOW_FAILURE],
    },
  };
}
