const addSetToCartFailEvents = ({ createEvent }) => {
  const { TRACK_ADD_SET_TO_CART_FAILURE } = require('../../actions/checkout');

  const addSetToCartFail = (action, prevState, nextState) => {
    let errorDetails = null;
    if (action.error && action.error.errors && action.error.errors.length) {
      errorDetails = JSON.stringify(action.error.errors[0]);
    }
    const productIdsInSet = action.meta.componentProductIds;
    return productIdsInSet.map(productId =>
      createEvent({
        name: 'Add to Cart Error',
        properties: {
          session_id: nextState.session.sessionId.toString(),
          customer_id: nextState.customer.id
            ? nextState.customer.id.toString()
            : null,
          product_id: productId.toString(),
          bundle_product_id: action.meta.setId.toString(),
          is_bundle: true,
          error_status_code: action.error.statusCode
            ? action.error.statusCode.toString()
            : '',
          error_message: action.error.message ? action.error.message : '',
          error_details: errorDetails,
        },
      })
    );
  };

  return { [TRACK_ADD_SET_TO_CART_FAILURE]: addSetToCartFail };
};

export default addSetToCartFailEvents;
