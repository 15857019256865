import { connect } from 'react-redux';

import { getMembership, loadProfile } from '@techstyle/react-accounts';
import { loadAssets } from '@techstyle/react-assets';
import { getSession, updateSessionDetail } from '@techstyle/redux-core';

import { toggleSignInModal } from '../../actions/auth';
import { trackByoInterstitialOpen } from '../../actions/byo';
import { toggleCartDrawer } from '../../actions/cart';
import {
  addFirstByoItem,
  addMembershipToCart,
  loadCart,
  updateItemQuantity,
  updateSetItemQuantity,
  deleteBasketItem,
  deleteBasketItemsByProductId,
  deleteMembershipFromCart,
  deleteProductSet,
  updateCartItem,
  sweepCart,
} from '../../actions/checkout';
import { loadProduct } from '../../actions/products';
import {
  allowExclusiveCheckout,
  cartHasOutOfStockItems,
  containsBundle,
  getAccountMembershipProductInfo,
  getByoSetsByProduct,
  getFreeShippingThreshold,
  getInfluencerItemCountExcludingMembership,
  getLineItems,
  getMembershipCartItem,
  getMembershipCartItemInfo,
  getMostExpensiveFirstAddedVipCheckoutItem,
  hasMembershipInCart,
  hasNonPreorderItems,
  hasOnlyMembershipCartItem,
  onlyHasPreorderItems,
} from '../../utils/selectors';

import CartDrawer from './CartDrawer';

const mapStateToProps = (state, ownProps) => {
  const cartItemInfluencerCount =
    getInfluencerItemCountExcludingMembership(state);

  const mostExpensive = getMostExpensiveFirstAddedVipCheckoutItem(state);

  return {
    allProducts: state.products,
    assets: state.assets,
    byoSetsForMostExpensiveItem: getByoSetsByProduct(state, {
      product: mostExpensive,
    }),
    mostExpensiveItem: mostExpensive,
    availableTokenQuantity: getMembership(state).availableTokenQuantity,
    cart: state.checkout,
    cartItemInfluencerCount,
    containsBundle: containsBundle(state),
    products: getLineItems(state),
    membershipItem: getMembershipCartItem(state),
    membershipItemInfo: getMembershipCartItemInfo(state),
    accountMembershipItemInfo: getAccountMembershipProductInfo(state),
    hasNonPreorderItems: hasNonPreorderItems(state),
    hasOutOfStockItems: cartHasOutOfStockItems(state),
    hasOnlyMembershipItem: hasOnlyMembershipCartItem(state),
    isEu: state.domain.tld !== '.com',
    isOpen: state.cart.cartDrawerIsOpen,
    allowOutOfStockCheckout: state.debug.allowOutOfStockCheckout,
    allowExclusiveCheckout: allowExclusiveCheckout(state, ownProps),
    freeShippingThreshold: getFreeShippingThreshold(state),
    hasMembershipInCart: hasMembershipInCart(state),
    isBorderfreeCustomer: state.borderfree.isBorderfreeCustomer,
    isDowngradedMember: getMembership(state).isDowngraded,
    isInfluencer: !!state.customer.isInfluencer,
    isVip: getMembership(state).isVip,
    isVisitor: getSession(state).isVisitor,
    isLead: getMembership(state).isLead,
    isPaygo: getMembership(state).isPaygo,
    onlyHasPreorderItems: onlyHasPreorderItems(state, ownProps),
    profile: state.customer,
    memberDiscounts: state.promos,
    tld: state.domain.tld,
  };
};

const mapDispatchToProps = {
  addFirstByoItem,
  addMembershipToCart,
  loadCart,
  loadProduct,
  loadProfile,
  loadAssets,
  updateItemQuantity,
  updateSetItemQuantity,
  deleteBasketItem,
  deleteBasketItemsByProductId,
  deleteMembershipFromCart,
  deleteProductSet,
  onToggle: toggleCartDrawer,
  toggleSignInModal,
  trackByoInterstitialOpen,
  updateCartItem,
  updateSessionDetail,
  sweepCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDrawer);
