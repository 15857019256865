import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { v1, v2 } from '../../utils/themeVersioning';

const Wrapper = styled.div`
  width: 100%;
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  ${props => (props.position === 'bottom' ? 'bottom: 0;' : 'top: 0;')};
  left: 0;
  z-index: 1101;
`;

const loaderAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%
  }
`;

const Loader = styled.div`
  width: 0;
  height: 4px;
  background-color: ${props => props.theme.colors.active};
  animation: ${loaderAnimation} 2s linear 0s infinite;
  ${v1`
      background-color: ${props => props.theme.colors.active};
    `}

  ${v2`
      background-color: ${props => props.theme.colors.lavender};
      `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${v1`
      background-color: ${props => props.theme.colors.scrimLavender};
    `}
  ${v2`
    background-color: transparent;
    `}
  pointer-events: none;
  z-index: ${props => props.theme.zIndex.increment};
`;

export default class ProgressLoader extends Component {
  static propTypes = {
    /**
     * Fixed positioning at the top of page
     */
    fixed: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    /**
     * Display an overlay/scrim over the parent, but the parent will still need `pointer-events: none` to prevent clicking the controls
     */
    overlay: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'bottom']),
  };

  static defaultProps = {
    fixed: true,
    overlay: false,
    position: 'top',
  };

  render() {
    const { fixed, overlay, loading, position } = this.props;
    if (!loading) {
      return null;
    }
    return (
      <>
        <Wrapper fixed={fixed} position={position}>
          <Loader fixed={fixed} data-testid="ProgressLoader_Loader" />
        </Wrapper>
        {overlay && <Overlay data-testid="ProgressLoader_Overlay" />}
      </>
    );
  }
}

ProgressLoader.Wrapper = Wrapper;
