import { getSession } from '@techstyle/redux-core';

const { TRACK_QUIZ_SCREEN } = require('../../actions/quiz');

const trackQuizScreenEvents = ({ getContext, trackEvent }) => {
  const trackQuizScreen = trackEvent((action, _prevState, nextState) => {
    const { cookies } = getContext();
    const queryString = window.location.search;
    const gateway = Boolean(nextState.session.dmGatewayCode);
    const referringPageModule = new URLSearchParams(queryString).get('nav');
    const customerBucketGroup = (nextState.customer.id % 20) + 1;
    const pageName = action.screenName;
    const questionNumber = action.screenQuestion
      ? action.currentStep
      : undefined;

    return {
      name: 'Quiz Modal Screen',
      properties: {
        referring_page_module: referringPageModule || '',
        session_id: nextState.session.sessionId.toString(),
        loggedin_status: getSession(nextState).isLoggedIn,
        automated_test: Boolean(cookies.get('automated_test')),
        feature: 'react',
        gateway,
        dmg_code: gateway ? nextState.session.dmGatewayCode : undefined,
        customer_bucket_group: nextState.customer.id
          ? customerBucketGroup.toString()
          : '',
        page_name: pageName,
        question_number: questionNumber,
        quiz_question: action.screenQuestion,
      },
    };
  });

  return { [TRACK_QUIZ_SCREEN]: trackQuizScreen };
};

export default trackQuizScreenEvents;
