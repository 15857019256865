import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { HTMLAsset, useAsset } from '@techstyle/react-assets';
import { FormattedMessage } from '@techstyle/react-intl';

import { mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';

const DynamicFormattedMessage = FormattedMessage;

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.default};
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 18px;
  letter-spacing: 0.75px;
`;

const dialogStyle = css`
  padding: 21px 35px ${props => props.theme.spacing.moderate}px;
  text-align: center;
  border-radius: 2px;
  max-width: 510px;
  ${mobile`
      width: 90%;
  `};
`;

const overlayStyle = css`
  background: ${props => props.theme.colors.scrim};
`;

const StyledModal = styled(BasicModal).attrs(props => ({
  overlayColor: props.theme.colors.scrim,
  ...props,
}))``;

const ModalBodyText = styled(HTMLAsset)`
  display: block;
  font-size: 12px;
  line-height: 16px;
  max-width: 510px;
  font-weight: 100;
  color: ${props => props.theme.colors.default};
`;

const BouncebackTermsModal = ({ closeModal, isOpen }) => {
  const assetContainerBouncebackTerms = useAsset(
    'bounceback_terms_and_conditions'
  );
  const { data } = assetContainerBouncebackTerms;
  const assetBouncebackTerms = data ? data.assets[0] : null;

  if (!assetBouncebackTerms) {
    return null;
  }

  const { customVars } = assetBouncebackTerms.options;
  const { title } = customVars;

  return (
    <StyledModal
      isOpen={isOpen}
      onExit={closeModal}
      overlayStyle={overlayStyle}
      dialogStyle={dialogStyle}
      titleId="bounceback-modal"
      transitionFrom={{
        transform: 'scale(0)',
        opacity: 0,
      }}
      transitionEnter={{
        transform: 'scale(1)',
        opacity: 1,
      }}
      transitionLeave={{
        transform: 'scale(0)',
        opacity: 0,
      }}
      springConfig={{
        tension: 170,
        friction: 26,
      }}
    >
      <ModalTitle id="bounceback-modal">
        {/** This is a fake id. We are getting back the text from an asset
         * but want to format it like a FormattedMessage, so we rely on
         * the defaultMessage to give us the correct value
         * (and need to supply a fake id to make this work)
         */}
        <DynamicFormattedMessage
          id="__bounceback_terms__"
          defaultMessage={title}
        />
      </ModalTitle>
      <ModalBodyText asset={assetBouncebackTerms} />
    </StyledModal>
  );
};

BouncebackTermsModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default BouncebackTermsModal;
