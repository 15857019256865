import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { useBreakpoint } from '@techstyle/react-components';
import { useFeature } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';
import { useDomain } from '@techstyle/redux-core';

import { mobile } from '../../styles';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as Link } from '../ContextualLink';
import { Container as LogOutButton } from '../LogOutButton';

const navLinks = [
  {
    label: (
      <FormattedMessage
        defaultMessage="Profile"
        id="site_account.profile_label"
      />
    ),
    href: '/account/profile',
    shouldHideFromVip: true,
    dataTag: 'myacctProfileMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Membership"
        id="site_account.membership_label"
      />
    ),
    href: '/account/membership',
    onlyForVip: true,
    dataTag: 'myacctMembershipMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Membership Support"
        id="site_account.membership_support_label"
      />
    ),
    href: '/account/membership-support',
    onlyForVip: true,
    shouldHideFromNavToolbar: true,
    dataTag: 'myacctMemberSupportMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Orders & returns"
        id="site_account.orders_and_returns_label"
      />
    ),
    href: '/account/orderhistory',
    dataTag: 'myacctOrderHistoryMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Reviews"
        id="site_account.reviews_label"
      />
    ),
    href: '/account/reviews',
    dataTag: 'myacctReviewMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Shipping Info"
        id="site_account.shipping_info_label"
      />
    ),
    href: '/account/shipping-info',
    dataTag: 'myacctShippingInfoMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Payment info"
        id="site_account.payment_info_label"
      />
    ),
    href: '/account/payment-info',
    hideForNonComUser: true,
    dataTag: 'myacctPaymentInfoMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Email preferences"
        id="site_account.email_preferences_label"
      />
    ),
    href: '/account/email-preferences',
    dataTag: 'myacctEmailPrefMenu',
  },
  {
    label: (
      <FormattedMessage
        defaultMessage="Favorites"
        id="site_navigation.favorites"
      />
    ),
    href: '/favorites',
    isNewDesktopNav: true,
    dataTag: 'myacctFavoriteMenu',
  },
];

const Wrapper = styled.div`
  margin: 0 auto;
`;

const List = styled.ul`
  padding: 0;
  ${mobile`
    padding: ${props => props.theme.context.mobileMenu && '16px'};
  `};
`;

const ListItem = styled.li`
  margin-top: ${props => (props.small ? 0 : '0.35em')};
  font-size: ${props => (props.small ? '12px' : '18px')};
  line-height: 1.5;
`;

const StyledLink = styled.a`
  ${({ isActive }) => `
    cursor: ${isActive ? 'auto' : 'pointer'};
    font-weight: ${isActive ? '700' : 'normal'};
  `};

  ${mobile`
    font-size: ${props => (props.isActive ? '16px' : '14px')};
    padding: 0;
  `};
  color: ${({ theme }) => theme.colors.default};
  ${v1`

    color: ${props =>
      props.theme.context.inverse
        ? props.theme.colors[props.isActive ? 'gray250' : 'white']
        : props.theme.colors[props.isActive ? 'subdued' : 'default']};
  `};
  ${v2`
    padding: 0;
    color: ${({ theme }) => theme.colors.gunmetal};
    text-transform: uppercase;
  `}
`;

const StyledLogOutButton = StyledLink.withComponent(LogOutButton);

const AccountLinks = ({
  className,
  isNavToolbar = false,
  showLogout = true,
  small = false,
}) => {
  const { tld } = useDomain();
  const { isVip } = useMembership();
  const { isDesktop } = useBreakpoint();
  const { isEnabled: shouldShowFavorites } = useFeature('desktop_nav_update');

  const renderAccountLinks = () => {
    const filteredNavLinks = navLinks.filter(link => {
      if (link.hideForNonComUser) {
        return tld === '.com';
      }

      if (link.isNewDesktopNav && (!shouldShowFavorites || !isDesktop)) {
        return false;
      }

      if (link.shouldHideFromNavToolbar && isNavToolbar) {
        return false;
      }

      if (link.shouldHideFromVip && isVip) {
        return false;
      }

      return !link.onlyForVip || (link.onlyForVip && isVip);
    });

    return (
      <List>
        {filteredNavLinks.map((link, index) => (
          <ListItem key={index} small={small}>
            <Link key={index} href={link.href} as={link.as} passHref>
              {({ isActive, isBack, isTop }) => (
                <StyledLink
                  isActive={isActive || isTop}
                  small={small}
                  data-autotag={link.dataTag}
                >
                  {link.label}
                </StyledLink>
              )}
            </Link>
          </ListItem>
        ))}
        {showLogout && (
          <ListItem small={small}>
            <StyledLogOutButton small={small} />
          </ListItem>
        )}
      </List>
    );
  };

  return <Wrapper className={className}>{renderAccountLinks()}</Wrapper>;
};

AccountLinks.propTypes = {
  /**
   * If the links are being displayed from the upper right dropdown
   */
  isNavToolbar: PropTypes.bool,
  /**
   * Show Log Out button at the end of the list
   */
  showLogout: PropTypes.bool,
  /**
   * The Account sidebar and NavToolbar flyout have small links
   */
  small: PropTypes.bool,

  className: PropTypes.string,
};

AccountLinks.Wrapper = Wrapper;
AccountLinks.List = List;
AccountLinks.ListItem = ListItem;
AccountLinks.StyledLink = StyledLink;
AccountLinks.StyledLogOutButton = StyledLogOutButton;

export default AccountLinks;
