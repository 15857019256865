import React from 'react';

import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import ToastContext from '../ToastContext/ToastContext';

const transitionIn = css`
  transform: translate3d(0, 0, 0);
`;

const transitionOut = css`
  transform: translate3d(0, -100%, 0);
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: ${props => (props.transitionState === 'exited' ? 0 : 'auto')};
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  overflow: hidden;
  z-index: ${props =>
    props.hideBehind ? -1 : props.theme.zIndex.navItems - 1};
`;

const Message = styled.div`
  background-color: ${props => props.theme.colors.active};
  color: ${props => props.theme.colors.white};
  ${props =>
    props.transitionState === 'exited' || props.transitionState === 'exiting'
      ? transitionOut
      : transitionIn};
  transition-property: transform;
  transition-duration: 300ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: center;
`;

// This isn't currently ideal because there is a bug in react-transition-group:
// https://github.com/reactjs/react-transition-group/issues/338
export default class ToastMessages extends React.PureComponent {
  lastMessage = null;

  static propTypes = {
    'data-autotag': PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { className, 'data-autotag': dataAutoTag } = this.props;
    return (
      <ToastContext.Consumer>
        {({ toastMessage, hideMessage, persist }) => (
          <Transition in={!!toastMessage} timeout={300}>
            {state => {
              // On transition out, keep showing the last message, otherwise it
              // will disappear instead of transitioning.
              if (toastMessage) {
                this.lastMessage = toastMessage;
              }
              return (
                <Wrapper
                  className={className}
                  transitionState={state}
                  aria-hidden={toastMessage ? 'false' : 'true'}
                >
                  <Message
                    data-autotag={dataAutoTag}
                    transitionState={state}
                    onClick={persist ? null : hideMessage}
                  >
                    {toastMessage || this.lastMessage}
                  </Message>
                </Wrapper>
              );
            }}
          </Transition>
        )}
      </ToastContext.Consumer>
    );
  }
}

ToastMessages.Message = Message;
