export const SignUpMethod = {
  AUTO_LOGIN: 'AUTO_LOGIN', // Signed in via autologin.
  CARTDRAWER: 'CARTDRAWER', // Signed up via cartdrawer.
  CART_CHECKOUT: 'CART_CHECKOUT', // Signed up during cart checkout.
  EXIT: 'EXIT', // Signed up through exit intent or idle time pop up.
  FAVORITES: 'FAVORITES', // Signed up via favorites.
  LIVE_SHOPPING: 'LIVE_SHOPPING', // Signed up via live shopping  page.
  NAVBAR: 'NAVBAR', // Signed up via navbar signin.
  NEWSLETTER_EMAIL_SIGNUP: 'NEWSLETTER_EMAIL_SIGNUP', // Signed up via newsletter email link
  QUIZ: 'QUIZ', // Signed up via quiz.
  SIGN_IN: 'SIGN_IN', // Any other sign in method.
  SIGN_IN_PAGE: 'SIGN_IN_PAGE', // Any other sign up method.
  SIGN_UP: 'SIGN_UP', // Any other sign up method.
  SIZE_FILTERS: 'SIZE_FILTERS', // Any other sign up method.
  SPEEDY: 'SPEEDY', // Signed up via grid size filters modal.
  WAITLIST: 'WAITLIST', // Signed up via waitlist.
  WISHLIST: 'WISHLIST', // Signed up via wishlist.
  GUEST_SIGN_UP: 'GUEST_SIGN_UP', // Any other sign up method.
};
