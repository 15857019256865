import { connect } from 'react-redux';

import { getRouteLabels } from '../../utils/selectors';

import RouteLabel, { ROUTES } from './RouteLabel';

function mapStateToProps(state) {
  return {
    labels: getRouteLabels(state, { defaultLabels: ROUTES }),
  };
}

export default connect(mapStateToProps)(RouteLabel);
