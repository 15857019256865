import { captureException } from '@sentry/nextjs';

import { getMembership, loadMembership } from '@techstyle/react-accounts';
import {
  ProductType,
  getTrackProductDetails,
  trackProductViewed,
} from '@techstyle/react-products';

import { getPartialTrackingData } from '../utils';

const productViewed = ({ createEvent, dispatch, getContext, getState }) => {
  const { isPreorderable } = require('../../utils/selectors');
  const productsMap = new Map();
  let productsPending = false;
  const wasSeenMap = new Map();

  const productViewed = async (action, prevState, nextState) => {
    await dispatch(loadMembership());
    const { isVip } = getMembership(getState());
    const payloadKey = action.payload.payloadKey || '{}';
    const currentGridKey = `${action.payload.fplId}_${action.payload.productSource}_${payloadKey}`;
    let products = productsMap.get(currentGridKey);
    let seenProducts = wasSeenMap.get(currentGridKey);
    let listCategory = null;
    let sortValue = null;
    const context = getContext();
    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'availableTokenQuantity',
      'dmg_code',
      'loggedin_status',
      'membership_status',
      'session_id',
      'store_group_id',
      'tests_active',
      'user_id',
      'user_status_initial',
      'membership_credits',
    ]);

    try {
      const payloadKeyJson = JSON.parse(payloadKey);
      const { backgroundCategoryIds, sort } = payloadKeyJson || {};

      if (backgroundCategoryIds && Array.isArray(backgroundCategoryIds)) {
        listCategory = backgroundCategoryIds.join();
      }

      if (sort) {
        if (Array.isArray(sort) && sort.length > 0) {
          sortValue = sort[0].field;
        } else if (typeof sort === 'string') {
          sortValue = sort;
        }
      }
    } catch (ex) {
      captureException(ex);
    }

    if (!products) {
      products = [];
      productsMap.set(currentGridKey, products);
    }
    if (!wasSeenMap.has(currentGridKey)) {
      wasSeenMap.clear();
    }
    if (!seenProducts) {
      seenProducts = new Set();
      wasSeenMap.set(currentGridKey, seenProducts);
    }
    if (!seenProducts.has(action.payload.product.masterProductId)) {
      products.push(action.payload);
      seenProducts.add(action.payload.product.masterProductId);
    }
    if (productsPending || !products.length) {
      return [];
    } else {
      productsPending = true;
      return new Promise(resolve => {
        setTimeout(() => {
          productsPending = false;
          const events = [];
          productsMap.forEach(productInfos => {
            const trackProductsViewed = productInfos.map(productInfo => {
              const { product } = productInfo;
              const isBundle = product.productTypeId === ProductType.BUNDLE;
              const isPreOrder = isPreorderable(getState(), { product });

              if (isBundle) {
                return product.componentProductIdObjectList.map(
                  (componentItem, currentComponentIndex) => {
                    const productDetailsToTrack = parseTrackProductDetail({
                      ...productInfo,
                      currentComponentIndex,
                      isVip,
                      isPreOrder,
                    });

                    return {
                      ...productDetailsToTrack,
                      product_id: String(productDetailsToTrack.product_id),
                    };
                  }
                );
              } else {
                const productDetailsToTrack = parseTrackProductDetail({
                  ...productInfo,
                  isVip,
                  isPreOrder,
                });

                return {
                  ...productDetailsToTrack,
                  product_id: String(productDetailsToTrack.product_id),
                };
              }
            });

            if (!trackProductsViewed.length) {
              return;
            }

            const fplId = products[0].fplId;

            events.push(
              createEvent({
                name: 'Product List Viewed',
                properties: {
                  ...additionalTrackingData,
                  fpl_id: fplId || null,
                  list_category: !fplId ? listCategory : null,
                  list_id: products[0].productSource || null,
                  products: trackProductsViewed.flat(),
                  sort: sortValue,
                },
              })
            );
          });
          productsMap.clear();
          resolve(events);
        }, 500);
      });
    }
  };

  return { [trackProductViewed]: productViewed };
};

const parseTrackProductDetail = productInfo => {
  const productDetail = getTrackProductDetails(productInfo);
  const { product, isPreOrder } = productInfo;

  productDetail.grid_position = productDetail.position;
  productDetail.category = product.defaultProductCategoryId;
  productDetail.subcategory = product.productCategoryIdList?.slice(-1)[0];
  productDetail.is_preorder = isPreOrder;
  productDetail.bundle_price = productDetail.is_bundle
    ? productDetail.sale_price || productDetail.vip_price
    : null;

  delete productDetail.bundle_alias;
  delete productDetail.bundle_quantity;
  delete productDetail.bundle_sale_price;
  delete productDetail.bundle_url;
  delete productDetail.grid_label;
  delete productDetail.position;
  delete productDetail.sale_price;
  delete productDetail.url;

  return productDetail;
};

export default productViewed;
