import React, { useState, useEffect, useCallback } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { areRoutesEqual } from '../../utils/routing';

const Context = React.createContext({
  routes: [],
  activeRoute: null,
  backRoute: null,
  topRoute: null,
  isActive: () => false,
  isBack: () => false,
  isTop: () => false,
});

RouteContext.Consumer = Context.Consumer;

RouteContext.propTypes = {
  children: PropTypes.element.isRequired,
  routeContext: PropTypes.func.isRequired,
};

function RouteContext({ routeContext, children }) {
  const router = useRouter();

  const getNewRouteState = useCallback(() => {
    const activeRoute = {
      asPath: router.asPath,
      query: router.query,
      route: router.route,
      pathname: router.pathname,
    };
    const routes = routeContext(activeRoute);
    const backRoute = routes[routes.length - 1];
    const topRoute = routes[0];
    return {
      routes,
      activeRoute,
      backRoute,
      topRoute,
      isActive: route => areRoutesEqual(route, activeRoute),
      isBack: route => areRoutesEqual(route, backRoute),
      isTop: route => areRoutesEqual(route, topRoute),
    };
  }, [routeContext, router]);

  const [value, setValue] = useState(getNewRouteState(routeContext));

  useEffect(() => {
    setValue(getNewRouteState(routeContext));
  }, [getNewRouteState, routeContext]);

  // Pass the current context value to the Provider's `value` prop.
  // Changes are detected using strict comparison (Object.is)
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const RouteContextWithRouter = RouteContext;

RouteContextWithRouter.Consumer = RouteContext.Consumer;

export default RouteContextWithRouter;
