import React from 'react';

import { MaybeSticky } from './MaybeSticky';
import { MouseOverWrapper } from './MouseOverWrapper';

export function StickyNavBarWrapper({ children }) {
  return (
    <MouseOverWrapper>
      <MaybeSticky>{children}</MaybeSticky>
    </MouseOverWrapper>
  );
}
