import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { mobile } from '../../styles';
import { BasicButton } from '../../styles/button';

const ModalParagraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 0px 48px;
  padding: 0px;

  ${mobile`
    margin: 0px;
    margin-right: 10%;
    margin-left: 10%;
  `};
`;

const ShopButton = styled(BasicButton).attrs({
  type: 'button',
})`
  min-width: 300px;
  background-color: ${props => props.theme.colors.default};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  margin-top: 10px;
  bottom: 0;
  ${mobile`
    min-width: 90%;
  `};
`;

function NewsletterSuccessContent({ onRequestClose, setShouldShowBackButton }) {
  useEffect(() => {
    setShouldShowBackButton(false);
  }, []);

  return (
    <>
      <ModalParagraph>
        <FormattedMessage
          id="global_cta.newsletter_modal_success_description"
          defaultMessage="Check your inbox now for your savage X exclusive offer"
        />
      </ModalParagraph>
      <ShopButton onClick={onRequestClose}>
        <FormattedMessage
          id="global_cta.newsletter_modal_success_button"
          defaultMessage="LET’S SHOP"
        />
      </ShopButton>
    </>
  );
}

NewsletterSuccessContent.propTypes = {
  onRequestClose: PropTypes.func,
  setShouldShowBackButton: PropTypes.func,
};

export default NewsletterSuccessContent;
