import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

const Wrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px ${props => props.theme.spacing.tiny}px;

  background-color: ${props => props.theme.colors.default};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  line-height: 1.5;
`;

const ShortWrapper = styled.span`
  color: ${props => props.theme.colors.default};
  display: flex;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.8px;
  padding-bottom: 4px;
  text-transform: uppercase;
`;

export default class XclusiveFlag extends React.PureComponent {
  static propTypes = {
    /**
     * styled props className
     */
    className: PropTypes.string,
    /**
     * Determines if should show the long or short label
     */
    shortLabel: PropTypes.bool,
  };

  static defaultProps = {
    alwaysShow: true,
    shortLabel: false,
  };

  renderLabel = (shortLabel, className) => {
    if (shortLabel) {
      return (
        <ShortWrapper className={className}>
          <FormattedMessage
            id="global_checkout.xclusive"
            defaultMessage="Xclusive Access"
          />
        </ShortWrapper>
      );
    } else {
      return (
        <Wrapper className={className}>
          <FormattedMessage
            id="global_checkout.xtra_savage_xclusive"
            defaultMessage="Xclusive Access for VIPs Only"
          />
        </Wrapper>
      );
    }
  };

  render() {
    const { className, shortLabel } = this.props;
    return this.renderLabel(shortLabel, className);
  }
}
