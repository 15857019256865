import React from 'react';

import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Modal } from '@techstyle/react-components';

import { Component as BackButton } from '../components/BackButton';
import { Component as CloseButton } from '../components/CloseButton';

export function BasicModal({
  backButton = <BackButton />,
  children,
  closeButton = <CloseButton />,
  dialogStyle,
  onBack,
  onExit,
  shouldHideCloseButton = false,
  shouldShowBackButton = false,
  ...props
}) {
  const isOneTrustAlertBoxOpen =
    typeof window !== 'undefined' &&
    window.OneTrust &&
    typeof window.OneTrust.IsAlertBoxClosed === 'function'
      ? !window.OneTrust.IsAlertBoxClosed()
      : false;

  const renderCloseButton = () => {
    if (closeButton && onExit) {
      closeButton = React.cloneElement(closeButton, {
        onClick: onExit,
      });
    }
    return closeButton;
  };

  const renderBackButton = () => {
    if (onBack) {
      backButton = React.cloneElement(backButton, {
        onClick: onBack,
      });
    }
    return backButton;
  };

  const dialogStyleModal = css`
    ${props => props.theme.modalDialog.defaultStyle};
    ${dialogStyle}
    overflow-x:hidden;
  `;

  return (
    <Modal
      dialogStyle={dialogStyleModal}
      onExit={onExit}
      springConfig={{ duration: 0 }}
      focusTrapConfig={{
        active: props.isOpen && isOneTrustAlertBoxOpen ? false : undefined,
      }}
      {...props}
    >
      {children}
      {shouldShowBackButton ? renderBackButton() : null}
      {shouldHideCloseButton ? null : renderCloseButton()}
    </Modal>
  );
}
BasicModal.propTypes = {
  backButton: PropTypes.node,
  children: PropTypes.node,
  closeButton: PropTypes.node,
  dialogStyle: PropTypes.array,
  isOpen: PropTypes.bool,
  onBack: PropTypes.func,
  onExit: PropTypes.func,
  shouldHideCloseButton: PropTypes.bool,
  shouldShowBackButton: PropTypes.bool,
  theme: PropTypes.object,
};
