import { newRouteLoaded } from '@techstyle/next-routes';
import {
  getSessionVisitorId,
  loadSessionSuccess,
  setHydrationStatus,
  HydrationStatus,
} from '@techstyle/redux-core';

import { getPartialTrackingData } from '../utils';

const basePageView = ({ trackPageView, getContext }) => {
  const pageView = trackPageView((action, prevState, nextState) => {
    // Attach `segmentCategory` specified by the page.
    const queryString = window.location.search;
    const referringPageModule = new URLSearchParams(queryString).get('nav');
    const customerBucketGroup = (nextState.customer.id % 20) + 1;
    const visitorId = getSessionVisitorId(nextState);

    const context = getContext();

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'availableTokenQuantity',
      'dmg_code',
      'feature',
      'gateway',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'store_id',
      'tests_active',
      'user_id',
      'user_status_initial',
    ]);

    return {
      name: window.location.pathname,
      category: nextState.marketing.pageInfo.segmentCategory,
      properties: {
        ...additionalTrackingData,
        customer_bucket_group: nextState.customer.id
          ? customerBucketGroup.toString()
          : '',
        page_name: window.location.pathname,
        referring_page_module: referringPageModule || '',
      },
      options: {
        anonymousId: String(visitorId) || undefined,
      },
    };
  });

  // Otherwise, wait until we have a client-side session to track the page view.
  // That way we can choose to read `dmGatewayCode` from the session in `pageView`
  // above if desired.
  const sessionPageView = (action, prevState, nextState) => {
    if (
      (prevState.session.isServerOnly || !prevState.session.sessionKey) &&
      !nextState.session.isServerOnly
    ) {
      return pageView(action, prevState, nextState);
    }
  };

  // If we already have a client-side session when hydration is complete, then we
  // can track the page view at that time.
  const hydrationPageView = (action, prevState, nextState) => {
    if (
      action.payload === HydrationStatus.COMPLETE &&
      nextState.session.sessionKey &&
      !nextState.session.isServerOnly
    ) {
      return pageView(action, prevState, nextState);
    }
  };
  return {
    [setHydrationStatus]: hydrationPageView,
    [loadSessionSuccess]: sessionPageView,
    [newRouteLoaded]: pageView,
  };
};

export default basePageView;
