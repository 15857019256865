import { handleActions } from 'redux-actions';

import { outOfDateStatus, upToDateStatus } from '@techstyle/redux-core';

import { DELETE_MEMBERSHIP_PROMO_CODE_SUCCESS } from '../actions/account';
import {
  APPLY_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_SUCCESS,
  USE_STORE_CREDIT_SUCCESS,
} from '../actions/cart';
import {
  LOAD_CART_SUCCESS,
  LOAD_CART_REQUEST,
  LOAD_CART_FAILURE,
  LOAD_BRAINTREE_SUCCESS,
  ADD_BASKET_ITEMS_REQUEST,
  ADD_BASKET_ITEMS_SUCCESS,
  ADD_BASKET_ITEMS_FAILURE,
  ADD_FIRST_BYO_ITEM_REQUEST,
  ADD_FIRST_BYO_ITEM_SUCCESS,
  ADD_FIRST_BYO_ITEM_FAILURE,
  ADD_BYO_ITEM_REQUEST,
  ADD_BYO_ITEM_SUCCESS,
  ADD_BYO_ITEM_FAILURE,
  DELETE_BASKET_ITEMS_REQUEST,
  DELETE_BASKET_ITEMS_SUCCESS,
  DELETE_BASKET_ITEMS_FAILURE,
  DELETE_BASKET_ITEMS_BY_PRODUCT_ID_REQUEST,
  DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS,
  DELETE_BASKET_ITEMS_BY_PRODUCT_ID_FAILURE,
  DELETE_MEMBERSHIP_ITEM_REQUEST,
  DELETE_MEMBERSHIP_ITEM_SUCCESS,
  DELETE_MEMBERSHIP_ITEM_FAILURE,
  ADD_PRODUCT_SET_REQUEST,
  ADD_PRODUCT_SET_SUCCESS,
  ADD_PRODUCT_SET_FAILURE,
  DELETE_PRODUCT_SET_REQUEST,
  DELETE_PRODUCT_SET_SUCCESS,
  DELETE_PRODUCT_SET_FAILURE,
  UPDATE_CART_LINE_ITEM_SUCCESS,
  UPDATE_ITEM_QUANTITY_REQUEST,
  UPDATE_ITEM_QUANTITY_SUCCESS,
  UPDATE_ITEM_QUANTITY_FAILURE,
  UPDATE_SET_ITEM_QUANTITY_REQUEST,
  UPDATE_SET_ITEM_QUANTITY_SUCCESS,
  UPDATE_SET_ITEM_QUANTITY_FAILURE,
  UPDATE_CART_ITEM_REQUEST,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_FAILURE,
  UPDATE_CART_WITH_ADDRESS_SUCCESS,
  UPDATE_CART_WITH_PAYMENT_SUCCESS,
  UPDATE_CART_WITH_SHIPPING_SUCCESS,
  GET_SHIPPING_OPTIONS_SUCCESS,
  SAVE_MONDIAL_ADDRESS_SUCCESS,
  SWEEP_CART_SUCCESS,
  DELETE_MONDIAL_ADDRESS_SUCCESS,
  SUBMIT_CART_ORDER_REQUEST,
  SUBMIT_CART_ORDER_FAILURE,
  COMPLETE_CART_ORDER_REQUEST,
  COMPLETE_CART_ORDER_SUCCESS,
  COMPLETE_CART_ORDER_FAILURE,
  CACHE_IN_PROGRESS_PAYMENT,
  CLEAR_IN_PROGRESS_PAYMENT,
  LOAD_CART_ITEM_COUNT_SUCCESS,
  GET_SHIPPING_OPTIONS_REQUEST,
  GET_SHIPPING_OPTIONS_FAILURE,
  CHANGE_DISCREET_PACKAGING_SUCCESS,
  SET_BRAINTREE_DEVICE_DATA,
} from '../actions/checkout';
import { MembershipActionLocation } from '../constants/checkout';

export const defaultState = {
  discounts: [],
  items: [],
  itemCount: 0, // Derivable, but the API returns it anyway... keep for now.
  loading: false,
  // The placed order (if the previous cart was submitted and succeeded).
  order: null,
  // The shipping options that apply to the current cart.
  shippingOptions: [],
  shippingOptionsNetworkStatus: outOfDateStatus(),
  // Payment information that has been entered but not saved or submitted.
  inProgressPaymentInfo: null,
  deselectedMembershipCheckout: false,
  fetchedDate: null,
};

export const updateStateFromPayload = (state, action) => {
  const { fetchedDate, isModifyingMembership = false } = action.meta || {};
  const {
    deselectedMembershipCheckout,
    inProgressPaymentInfo,
    order,
    shippingOptions,
    shippingOptionsNetworkStatus,
    deviceData,
  } = state;

  if (!fetchedDate) {
    throw new Error('Fetched Date empty.');
  }

  const { cartProviderType, items, ...payload } = action.payload;

  let newItems = items;
  if (cartProviderType !== 'ECHO' && items) {
    newItems = items.map(({ thumbnailImageSrc, ...item }) => ({ ...item }));
  }
  const actionsToInvalidateShippingOptionsOn = [
    ADD_BASKET_ITEMS_SUCCESS,
    DELETE_BASKET_ITEMS_SUCCESS,
    DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS,
    DELETE_PRODUCT_SET_SUCCESS,
    DELETE_MEMBERSHIP_ITEM_SUCCESS,
    ADD_PRODUCT_SET_SUCCESS,
    UPDATE_CART_ITEM_SUCCESS,
    UPDATE_CART_WITH_ADDRESS_SUCCESS,
    UPDATE_ITEM_QUANTITY_SUCCESS,
  ];

  const shouldInvalidateShippingOptions =
    actionsToInvalidateShippingOptionsOn.includes(action.type);

  return {
    ...defaultState,
    order,
    shippingOptions,
    shippingOptionsNetworkStatus:
      isModifyingMembership || shouldInvalidateShippingOptions
        ? outOfDateStatus()
        : shippingOptionsNetworkStatus,
    inProgressPaymentInfo,
    deselectedMembershipCheckout,
    fetchedDate,
    cartProviderType,
    items: newItems,
    deviceData,
    ...payload,
  };
};

const updateShouldUsePickupLocation = (state, action) => {
  let isPickUpLocation = state.isPickUpLocation;
  if (action.type === SAVE_MONDIAL_ADDRESS_SUCCESS) {
    isPickUpLocation = true;
  }
  if (action.type === DELETE_MONDIAL_ADDRESS_SUCCESS) {
    isPickUpLocation = false;
  }
  return {
    ...state,
    isPickUpLocation,
  };
};

const enableLoader = state => ({
  ...state,
  loading: true,
});

const disableLoader = state => ({
  ...state,
  loading: false,
});

const enableShippingOptionsLoader = state => {
  return {
    ...state,
    shippingOptionsNetworkStatus: {
      ...state.shippingOptionsNetworkStatus,
      isLoading: true,
    },
  };
};

const disableShippingOptionsLoader = state => ({
  ...state,
  shippingOptionsNetworkStatus: {
    ...state.shippingOptionsNetworkStatus,
    isLoading: false,
  },
});

export default handleActions(
  {
    [LOAD_CART_SUCCESS]: updateStateFromPayload,
    [LOAD_CART_ITEM_COUNT_SUCCESS]: (state, action) => {
      const { fetchedDate } = action.meta || {};

      if (!fetchedDate) {
        throw new Error('Fetched Date empty.');
      }

      return {
        ...state,
        fetchedDate,
        ...action.payload,
      };
    },
    [SET_BRAINTREE_DEVICE_DATA]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [LOAD_BRAINTREE_SUCCESS]: (state, action) => {
      const newState = updateStateFromPayload(state, action);

      return {
        ...newState,
        inProgressPaymentInfo: null,
      };
    },
    [ADD_BASKET_ITEMS_SUCCESS]: updateStateFromPayload,
    [ADD_FIRST_BYO_ITEM_SUCCESS]: updateStateFromPayload,
    [ADD_BYO_ITEM_SUCCESS]: updateStateFromPayload,
    [ADD_PRODUCT_SET_SUCCESS]: updateStateFromPayload,
    [DELETE_BASKET_ITEMS_SUCCESS]: updateStateFromPayload,
    [DELETE_BASKET_ITEMS_BY_PRODUCT_ID_SUCCESS]: updateStateFromPayload,
    [DELETE_MEMBERSHIP_ITEM_SUCCESS]: (state, action) => {
      const newState = updateStateFromPayload(state, action);
      if (
        !action.error &&
        (action.meta.location === MembershipActionLocation.SHOPPING_BAG ||
          action.meta.location === MembershipActionLocation.MINI_CART)
      ) {
        return {
          ...newState,
          deselectedMembershipCheckout: true,
        };
      }
      return newState;
    },
    [DELETE_PRODUCT_SET_SUCCESS]: updateStateFromPayload,
    [UPDATE_CART_LINE_ITEM_SUCCESS]: updateStateFromPayload,
    [UPDATE_ITEM_QUANTITY_SUCCESS]: updateStateFromPayload,
    [UPDATE_SET_ITEM_QUANTITY_SUCCESS]: updateStateFromPayload,
    [UPDATE_CART_ITEM_SUCCESS]: updateStateFromPayload,
    [APPLY_PROMO_CODE_SUCCESS]: updateStateFromPayload,
    [REMOVE_PROMO_CODE_SUCCESS]: updateStateFromPayload,
    [USE_STORE_CREDIT_SUCCESS]: updateStateFromPayload,
    [UPDATE_CART_WITH_ADDRESS_SUCCESS]: updateStateFromPayload,
    [UPDATE_CART_WITH_PAYMENT_SUCCESS]: updateStateFromPayload,
    [UPDATE_CART_WITH_SHIPPING_SUCCESS]: updateStateFromPayload,
    [ADD_BASKET_ITEMS_REQUEST]: enableLoader,
    [ADD_BASKET_ITEMS_FAILURE]: disableLoader,
    [ADD_PRODUCT_SET_REQUEST]: enableLoader,
    [ADD_PRODUCT_SET_FAILURE]: disableLoader,
    [ADD_PRODUCT_SET_SUCCESS]: updateStateFromPayload,
    [ADD_FIRST_BYO_ITEM_REQUEST]: enableLoader,
    [ADD_FIRST_BYO_ITEM_FAILURE]: disableLoader,
    [ADD_BYO_ITEM_REQUEST]: enableLoader,
    [ADD_BYO_ITEM_FAILURE]: disableLoader,
    [DELETE_BASKET_ITEMS_REQUEST]: enableLoader,
    [DELETE_BASKET_ITEMS_FAILURE]: disableLoader,
    [DELETE_BASKET_ITEMS_BY_PRODUCT_ID_REQUEST]: enableLoader,
    [DELETE_BASKET_ITEMS_BY_PRODUCT_ID_FAILURE]: disableLoader,
    [DELETE_MEMBERSHIP_ITEM_REQUEST]: enableLoader,
    [DELETE_MEMBERSHIP_ITEM_FAILURE]: disableLoader,
    [DELETE_MEMBERSHIP_ITEM_SUCCESS]: updateStateFromPayload,
    [DELETE_PRODUCT_SET_REQUEST]: enableLoader,
    [DELETE_PRODUCT_SET_FAILURE]: disableLoader,
    [LOAD_CART_REQUEST]: enableLoader,
    [LOAD_CART_FAILURE]: disableLoader,
    [UPDATE_ITEM_QUANTITY_REQUEST]: enableLoader,
    [UPDATE_ITEM_QUANTITY_FAILURE]: disableLoader,
    [UPDATE_ITEM_QUANTITY_SUCCESS]: updateStateFromPayload,
    [UPDATE_SET_ITEM_QUANTITY_REQUEST]: enableLoader,
    [UPDATE_SET_ITEM_QUANTITY_FAILURE]: disableLoader,
    [UPDATE_CART_ITEM_REQUEST]: enableLoader,
    [UPDATE_CART_ITEM_FAILURE]: disableLoader,
    [UPDATE_CART_ITEM_SUCCESS]: updateStateFromPayload,
    [SWEEP_CART_SUCCESS]: updateStateFromPayload,
    [SUBMIT_CART_ORDER_REQUEST]: enableLoader,
    [COMPLETE_CART_ORDER_REQUEST]: enableLoader,
    [COMPLETE_CART_ORDER_SUCCESS]: (state, action) => {
      if (
        action.payload.statusCode === 202 ||
        action.payload.adyenResponseType
      ) {
        return state;
      }
      return {
        ...defaultState,
        order: action.payload,
      };
    },
    [SUBMIT_CART_ORDER_FAILURE]: disableLoader,
    [COMPLETE_CART_ORDER_FAILURE]: disableLoader,
    [SAVE_MONDIAL_ADDRESS_SUCCESS]: updateShouldUsePickupLocation,
    [DELETE_MONDIAL_ADDRESS_SUCCESS]: updateShouldUsePickupLocation,
    [GET_SHIPPING_OPTIONS_REQUEST]: enableShippingOptionsLoader,
    [GET_SHIPPING_OPTIONS_SUCCESS]: (state, action) => ({
      ...state,
      shippingOptions: action.payload,
      shippingOptionsNetworkStatus: upToDateStatus(),
    }),
    [GET_SHIPPING_OPTIONS_FAILURE]: disableShippingOptionsLoader,
    [CACHE_IN_PROGRESS_PAYMENT]: (state, action) => ({
      ...state,
      inProgressPaymentInfo: action.paymentInfo,
    }),
    [CLEAR_IN_PROGRESS_PAYMENT]: (state, action) => ({
      ...state,
      inProgressPaymentInfo: null,
    }),
    [CHANGE_DISCREET_PACKAGING_SUCCESS]: updateStateFromPayload,
    [DELETE_MEMBERSHIP_PROMO_CODE_SUCCESS]: (state, action) => ({
      ...state,
      cartLineDiscounts: action.payload.cartLineDiscounts,
      discounts: action.payload.discounts,
    }),
  },
  defaultState
);
