var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6d526946cf63b0d225c7286161cde85453abb365"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import config from 'config';

if (config.get('public.sentry.enabled')) {
  Sentry.init({
    dsn: config.get('public.sentry.dsn'),
    integrations: [new RewriteFrames({ prefix: '~/' })],
    tracesSampleRate: config.get('public.sentry.tracesSampleRate'),
    sampleRate: config.get('public.sentry.sampleRate'),
    environment: config.get('public.sentry.environment'),
    allowUrls: config.get('public.sentry.allowUrls'),
    ignoreErrors: config.get('public.sentry.ignoreErrors'),
    debug: config.get('public.sentry.debug'),
  });
}
