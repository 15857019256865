import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { AssetContainer, HTMLAsset } from '@techstyle/react-assets';

import { desktop, mobile } from '../../styles';
import { BasicModal } from '../../styles/modal';
import { createMembershipPromoPriceAssetFilter } from '../../utils/assetFilters';
import { v1, v2 } from '../../utils/themeVersioning';
import { Component as ModalCloseButton } from '../ModalCloseButton';

const Content = styled.div`
  flex: 1;

  padding: 40px 78px;
  background: ${props =>
    props.isFreeTrial ? 'transparent' : props.theme.colors.white};

  ${v1`
      border: 8px solid ${props => props.theme.colors.lavender250};
    `}

  ${v2`
    border: 8px solid ${props => props.theme.colors.lavender};
    `}

  ${desktop`
    width: 640px;
    max-width: 100%;
  `};

  ${mobile`
    border: 0;
    padding: 40px 32px 150px 32px;
  `};
`;

const dialogStyle = css`
  display: flex;
  flex-direction: column;

  ${mobile`
    width: 100%;
    height: 100vh;
  `};
`;

const VipModal = styled(BasicModal)``;

const StyledHTMLAsset = styled(HTMLAsset)`
  margin: 0 auto;
`;
export default function VIPLearnMoreModal(props) {
  const { price: membershipPrice } = useMembership();

  const customMembershipPromoFilter = useMemo(() => {
    return createMembershipPromoPriceAssetFilter(membershipPrice);
  }, [membershipPrice]);

  return (
    <VipModal
      bodyOpenClassName="VIPLearnMoreModal ReactModal__Body--open"
      className={props.className}
      closeButton={
        <ModalCloseButton data-autotag="pdpLearnMoreModelCloseBtn" />
      }
      dialogStyle={dialogStyle}
      titleId="vip-learn-modal"
      {...props}
    >
      <Content id="vip-learn-modal" data-autotag="pdpLearnMoreModelLoc">
        <AssetContainer
          name="global_html_learnmorevip"
          filter={customMembershipPromoFilter}
        >
          {asset => {
            if (!asset.data) {
              return null;
            }
            return <StyledHTMLAsset asset={asset.data} />;
          }}
        </AssetContainer>
      </Content>
    </VipModal>
  );
}

VIPLearnMoreModal.propTypes = {
  className: PropTypes.string,
};
