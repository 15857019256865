import React from 'react';

import styled from 'styled-components';

import { useTheme } from '@techstyle/react-components';

import { desktop } from '../../styles';
import { Component as AutoHidingSticky } from '../AutoHidingSticky';
import { HeaderAttachmentContainer } from '../Header/HeaderAttachmentContainer';
import NavToolBarMobile from '../NavToolBarMobile/NavToolBarMobile';

const SubNav = styled.div`
  ${desktop`display: none;`}
  display: flex;
  align-items: center;
  justify-content: space-between;
  backface-visibility: hidden;
  background: ${props => props.theme.context.backgroundState};
  color: ${props =>
    props.theme.context.transparentNav
      ? props.theme.colors.white
      : props.theme.colors.default};
`;

const NavBarMobile = ({
  backgroundState,
  showAccountModal,
  allowNewPricingSkinnyBanner,
}) => {
  const isMobileProductsPage = useTheme(t => t.context.mobileProducts, null);
  const navbar = (
    <SubNav>
      <NavToolBarMobile
        backgroundState={backgroundState}
        showAccountModal={showAccountModal}
        allowNewPricingSkinnyBanner={allowNewPricingSkinnyBanner}
      />
    </SubNav>
  );

  if (!isMobileProductsPage) {
    return navbar;
  }

  return (
    <AutoHidingSticky
      shouldPreserveOnScrollDown
      className=""
      doubleNavThreshold={undefined}
    >
      {navbar}
      <HeaderAttachmentContainer origin="nav" />
    </AutoHidingSticky>
  );
};

export default NavBarMobile;
