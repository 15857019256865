import React, { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '@techstyle/react-components';
import { useFeatures } from '@techstyle/react-features';
import {
  useSession,
  useSessionActions,
  useSessionDetail,
} from '@techstyle/redux-core';

import { trackRegistrationSignUp } from '../../actions/auth';
import { SignUpMethod } from '../../constants/auth';
import { mobile, desktop } from '../../styles';
import { v1 } from '../../utils/themeVersioning';
import { Container as RegistrationModal } from '../RegistrationModal';
import { Container as SignIn } from '../SignIn';
import { Component as SignInContent } from '../SignInContent';

const SETS = 'sets';

const registrationModalDialogStyle = css`
  @media (min-width: ${props =>
      props.theme.breakpoints.small}px) and (max-width: ${props =>
      props.theme.breakpoints.large}px) {
    border-radius: 4px;
    overflow: visible;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}px) {
    border-radius: ${props => props.theme.spacing.tiny}px
      ${props => props.theme.spacing.tiny}px 0 0;
    top: 43px;
  }

  ${desktop`border-radius: 4px;`}
`;

const StyledSignIn = styled(SignIn)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${SignInContent.RegistrationModalHeadline},
  ${SignInContent.FormTitle} {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
    ${v1`
      text-transform: lowercase;
    `}

    ${mobile`
      font-size: 18px;
      margin-top: ${props => props.theme.spacing.large}px;
    `}
  }

  ${SignInContent.RegistrationModalHeadline}:first-letter,
  ${SignInContent.FormTitle}:first-letter {
    text-transform: capitalize;
  }

  ${SignInContent.FormSubtitle} {
    display: none;
  }

  ${SignInContent.StyledReCAPTCHA} {
    margin-top: ${props => props.theme.spacing.medium}px;

    ${mobile`
      margin-top: ${props => props.theme.spacing.moderate}px;
    `}
  }

  ${SignInContent.SubscribeToEmailWrapper} {
    margin-top: ${props => props.theme.spacing.small}px;
  }

  ${SignInContent.InstructionsTop} {
    color: ${props => props.theme.colors.default};
    text-align: center;
  }

  ${SignInContent.ToastContainer} {
    flex: 0;
  }

  ${SignInContent.SubmitButton} {
    ${mobile`margin-top: ${props => props.theme.spacing.moderate}px;`}
  }

  ${SignInContent.BackToSignIn} {
    font-size: 12px;
    text-align: left;
  }

  ${SignInContent.CellFooter} {
    flex-direction: column-reverse;
  }

  ${SignInContent.StyledInstructionsBottom},
  ${SignInContent.PrivacyTermsConditionsBox},
  ${SignInContent.BackToSignIn} {
    font-weight: 500;
  }
`;

const StyledRegistrationModal = styled(RegistrationModal)`
  ${RegistrationModal.Wrapper} {
    border-radius: 4px;
    padding: 0;
    overflow: auto;

    @media (max-width: ${props => props.theme.breakpoints.medium}px) {
      border-radius: ${props => props.theme.spacing.tiny}px
        ${props => props.theme.spacing.tiny}px 0 0;
    }

    ${desktop`
      height: 590px;
      overflow: hidden;
      overflow-y: auto;
    `}
  }

  ${RegistrationModal.CloseButton} {
    color: ${props => props.theme.colors.active};

    ${mobile`
      color: ${props => props.theme.colors.white};
      top: -56px;
    `}
  }

  ${RegistrationModal.StyledImageAsset} {
    ${mobile`
      height: 100%;
      min-height: 150px; /* prevent image from rendering very small on newer iOS devices */
    `}
  }

  ${RegistrationModal.StyledImageAssetContainer} {
    min-height: 150px;
  }

  ${RegistrationModal.RightContainer} {
    padding: 50px 50px ${({ theme }) => theme.spacing.small}px 50px;

    ${desktop`
        overflow: auto;
    `}

    ${mobile`
      padding: 0 ${({ theme }) => theme.spacing.moderate}px ${({ theme }) =>
      theme.spacing.medium}px;
    `}
  }
`;

const SpeedySignUp = () => {
  const { asPath, pathname, query, route } = useRouter();
  const { productType } = query;
  const { isLoggedIn } = useSession();
  const { isBorderfreeCustomer } = useSelector(state => state.borderfree);
  const pagesRendered = useSelector(state => state.pagesRendered);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);
  const [numberOfTimesSeen, setNumberOfTimesSeen] = useState(0);
  const sessionActions = useSessionActions();
  const { isMobile } = useBreakpoint();
  const modalAnimationConfig = isMobile
    ? {
        transitionFrom: {
          transform: 'translateY(100vh)',
        },
        transitionEnter: {
          transform: 'translateY(0vh)',
        },
        transitionLeave: {
          transform: 'translateY(100vh)',
        },
        springConfig: {
          duration: 500,
        },
      }
    : {};

  const [speedyEnhanced] = useFeatures(['speedy_enhanced']);

  const isSpeedyEnhancedEnabled = speedyEnhanced?.data?.value;
  const sizeFilterPromptSeenSession = useSessionDetail(
    'size_filter_prompt_seen'
  );

  const excludedPaths = [
    /best-sellers/,
    /cart/,
    /checkout/,
    /favorites/,
    /quiz/,
  ];
  const isExcludedSportPath =
    /sport/.test(asPath) && asPath.split('/').length === 2;

  const isExcludedPath = isSpeedyEnhancedEnabled
    ? excludedPaths.some(path => path.test(asPath)) ||
      asPath === '/' ||
      isExcludedSportPath
    : excludedPaths.some(path => path.test(asPath)) || isExcludedSportPath;

  const hasSizeFilterBeenApplied = Object.keys(query).some(queryParam =>
    queryParam.includes('size')
  );
  const hasSizeFilterModalBeenSeenThisSession =
    sizeFilterPromptSeenSession &&
    sizeFilterPromptSeenSession.networkStatus.isUpToDate &&
    sizeFilterPromptSeenSession.value;

  const isExcludedGridFilterCondition =
    (route.includes('products-bmig') &&
      hasSizeFilterBeenApplied &&
      !isLoggedIn) ||
    hasSizeFilterModalBeenSeenThisSession;

  const openExitModal = useCallback(() => {
    setIsRegistrationModalOpen(true);
    setHasSeen(true);
  }, [setIsRegistrationModalOpen]);

  const handleExitIntent = useCallback(() => {
    const vpWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (window.event.clientX >= vpWidth - 50) {
      return;
    }

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (window.event.clientY >= 50) {
      return;
    }

    // Reliable, works on mouse exiting window and
    // user switching active program
    const from = window.event.relatedTarget || window.event.toElement;
    if (!from) {
      openExitModal();
    }
  }, [openExitModal]);

  const handleModalClose = useCallback(() => {
    setHasSeen(true);
    setIsRegistrationModalOpen(false);
    setNumberOfTimesSeen(numberOfTimesSeen => numberOfTimesSeen + 1);
  }, []);

  const shouldShowProductInfo = pathname === '/product' && productType !== SETS;

  useEffect(() => {
    const thirdPage = pagesRendered >= 3;
    const fourthPage = pagesRendered >= 4 && pagesRendered < 7;
    const eighthPage = pagesRendered >= 8;
    const openModal = () => {
      setIsRegistrationModalOpen(true);
    };

    const shouldOpenModal =
      !isExcludedPath &&
      !isExcludedGridFilterCondition &&
      !isBorderfreeCustomer &&
      ((thirdPage && !isSpeedyEnhancedEnabled && !hasSeen) ||
        (isSpeedyEnhancedEnabled &&
          ((fourthPage && numberOfTimesSeen < 1) ||
            (eighthPage && numberOfTimesSeen < 2))));

    if (shouldOpenModal) {
      openModal();
    }
  }, [
    hasSeen,
    isBorderfreeCustomer,
    isExcludedGridFilterCondition,
    isExcludedPath,
    isSpeedyEnhancedEnabled,
    numberOfTimesSeen,
    pagesRendered,
    setIsRegistrationModalOpen,
  ]);

  useEffect(() => {
    if (isRegistrationModalOpen) {
      sessionActions.updateSessionDetail({
        name: 'speedy_signup_pop-up',
        value: '',
      });
    }
  }, [isRegistrationModalOpen, sessionActions]);

  useEffect(() => {
    if (hasSeen) {
      window.removeEventListener('mouseout', handleExitIntent);
    }
  }, [handleExitIntent, hasSeen]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <StyledRegistrationModal
      assetContainer={
        !shouldShowProductInfo ? 'registration_modal_html_banner' : null
      }
      isOpen={isRegistrationModalOpen}
      onRequestClose={handleModalClose}
      registrationModalDialogStyle={registrationModalDialogStyle}
      shouldShowProductInfo={shouldShowProductInfo}
      {...modalAnimationConfig}
    >
      <StyledSignIn
        initialScreen={SignUpMethod.SIGN_UP}
        handleModalClose={handleModalClose}
        registrationModal
        showSwitcher={false}
        showSubscribeToEmails
        showTermsConditions
        shouldShowFooterForgotPasswordLink
        showReferrerField
        onResetSuccess={() => setIsRegistrationModalOpen(false)}
        onSuccess={() => setIsRegistrationModalOpen(false)}
        onSignInClick={() => trackRegistrationSignUp('Sign In')}
        onSignUp={() => trackRegistrationSignUp('successful registration')}
        extraSignUpData={{
          method: SignUpMethod.SPEEDY,
        }}
      />
    </StyledRegistrationModal>
  );
};

export default SpeedySignUp;
