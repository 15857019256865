import React, { useCallback } from 'react';

import { useSetMouseOverHeader } from '../../GlobalHeaderStateProvider/GlobalHeaderStateProvider';

export const MouseOverWrapper = ({ children }) => {
  const setIsMouseOverHeader = useSetMouseOverHeader();
  const handleMouseEnter = useCallback(() => {
    setIsMouseOverHeader(true);
  }, [setIsMouseOverHeader]);

  const handleMouseLeave = useCallback(() => {
    setIsMouseOverHeader(false);
  }, [setIsMouseOverHeader]);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </div>
  );
};
