import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';
import { createSelector, useDomain } from '@techstyle/redux-core';

import { hasMembershipInCart as hasMembershipInCartSelector } from './selectors';

export const SEGMENTATION_OPTIONS = [
  'visitor',
  'ftv',
  'lead',
  'payg',
  'vip',
  'elite',
  'downgraded',
  'cancelled',
  'activating',
  'activating-vip',
  'repeat',
  'logged-in',
] as const;

export type SegmentationOptions = typeof SEGMENTATION_OPTIONS[number];

const DOMAIN_SEGMENTATION_OPTIONS = [
  '.com',
  '.co.uk',
  '.de',
  '.es',
  '.eu',
  '.fr',
] as const;

type DomainSegmentationOptions = typeof DOMAIN_SEGMENTATION_OPTIONS[number];

type SegmentedValues<T extends unknown> = {
  default?: T;
  [key: string]: T | undefined;
} & Partial<Record<SegmentationOptions, T>>;

type DomainSegmentedValues<T extends unknown> = {
  default?: T;
  [key: string]: T | undefined;
} & Partial<Record<DomainSegmentationOptions, T>>;
type MaybeDomainSegmentedValues<T extends unknown> =
  | DomainSegmentedValues<T>
  | T;

// This is a partial version of the state that gets derived by `getMembership`
type PartialMemberShipState = {
  isLead: boolean;
  isCancelled: boolean;
  isDowngraded: boolean;
  isPaygo: boolean;
  membershipLevelGroup: 'visitor' | 'elite' | 'vip' | 'lead';
};

// This is a partial version of the state that gets derived by `getSession`
type PartialSessionState = {
  isLoggedIn: boolean;
  isFirstTimeVisitor: boolean;
};

/**
 * Returns a list of potential segments for the current user.
 * Potential segments are roughly ordered by specificity.
 * For example, if a user is a regular VIP they will be in the `vip` and `repeat` segments.
 * If a user is a regular lead they will be in the `lead` and `activating` segments.
 */
export const getPotentialSegments = ({
  membershipState,
  sessionState,
  isMembershipInCart,
  anonymousServerSession = false,
}: {
  membershipState: PartialMemberShipState;
  sessionState: PartialSessionState;
  isMembershipInCart: boolean;
  anonymousServerSession?: boolean;
}): SegmentationOptions[] => {
  const { isLoggedIn, isFirstTimeVisitor } = sessionState;
  if (anonymousServerSession && !isLoggedIn) {
    return ['visitor', 'activating'];
  }

  const { membershipLevelGroup, isCancelled, isDowngraded, isPaygo } =
    membershipState;
  const potentialSegments = [membershipLevelGroup] as SegmentationOptions[];

  if (membershipLevelGroup === 'elite') {
    potentialSegments.push('vip');
  }

  if (['elite', 'vip'].includes(membershipLevelGroup)) {
    potentialSegments.push('repeat');
  } else {
    potentialSegments.push('activating');
  }

  if (isMembershipInCart) {
    potentialSegments.push('activating-vip');
  }

  if (isLoggedIn) {
    potentialSegments.push('logged-in');
  }

  if (isFirstTimeVisitor) {
    potentialSegments.unshift('ftv');
  }

  if (isCancelled) {
    potentialSegments.unshift('cancelled');
  }

  if (isDowngraded) {
    potentialSegments.unshift('downgraded');
  }

  if (isPaygo) {
    potentialSegments.unshift('payg');
  }

  return potentialSegments;
};

export const getPotentialSegmentsSelector = createSelector(
  [
    state => getMembership(state),
    state => state.session,
    state => hasMembershipInCartSelector(state),
    (state, ownProps) => ownProps?.anonymousServerSession,
  ],
  (membership, session, isMembershipInCart, anonymousServerSession) => {
    return getPotentialSegments({
      membershipState: membership,
      sessionState: session,
      isMembershipInCart,
      anonymousServerSession,
    });
  }
);

// It's possible for a key to be a comma separated list of segments, this will separate them into an array
const separateSegmentedValues = <T extends unknown, U = SegmentedValues<T>>(
  options: U
) => {
  const separatedOptions = {} as U;

  Object.keys(options).forEach(key => {
    const segments = key.split(',');
    segments.forEach(segment => {
      separatedOptions[segment] = options[key];
    });
  });

  return separatedOptions;
};

const isDomainSegmentedValue = <T extends unknown>(
  value: MaybeDomainSegmentedValues<T>
) => {
  if (typeof value !== 'object') {
    return false;
  }

  return Object.keys(value).some(key => {
    return DOMAIN_SEGMENTATION_OPTIONS.some(option => key.startsWith(option));
  });
};

const getDomainSegmentedValue = <T extends unknown>({
  options,
  tld,
}: {
  options: DomainSegmentedValues<T>;
  tld: string;
}): T | null => {
  const separatedOptions = separateSegmentedValues<T, DomainSegmentedValues<T>>(
    options
  );
  const value = separatedOptions?.[tld] || separatedOptions?.default || null;

  return value;
};

export const getSegmentedValue = <T extends unknown>({
  options,
  segments,
  tld,
}: {
  options: SegmentedValues<T>;
  segments: SegmentationOptions[];
  tld: string;
}): T => {
  const separatedOptions = separateSegmentedValues<T>(options);
  let value = separatedOptions?.default || null;

  segments.some(segment => {
    if (separatedOptions[segment] !== undefined) {
      let potentialValue = separatedOptions[segment];

      if (isDomainSegmentedValue<T>(potentialValue)) {
        potentialValue = getDomainSegmentedValue<T>({
          options: potentialValue as DomainSegmentedValues<T>,
          tld,
        });
        if (potentialValue === null) {
          return false;
        }
      }

      value = potentialValue;

      return true;
    }
    return false;
  });

  return value;
};

export const useSegmentedValue = <T extends unknown>(
  segmentedOptions?: SegmentedValues<T>
) => {
  // @ts-ignore - The types for `useSelector` seem to get confused with the `ownProps` argument
  const segments = useSelector(getPotentialSegmentsSelector);
  const { tld } = useDomain();
  const value = useMemo(() => {
    if (!segmentedOptions) {
      return null;
    }

    return getSegmentedValue<T>({ options: segmentedOptions, segments, tld });
    // This ESlint rule seems to get confused with the `T` generic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentedOptions, segments]);

  return value;
};
