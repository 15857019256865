import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { NavigationizerFeatureFlags } from '../constants/navigationizer';

const { hideFromBorderfree, isMobileOnly, isDesktopOnly } =
  NavigationizerFeatureFlags;

export function useNavFilter({ isMobile = true } = {}) {
  const isBorderfreeCustomer = useSelector(
    state => state.borderfree.isBorderfreeCustomer
  );

  const applyFeatureFlags = useCallback(
    navItem => {
      const shouldHideOnDesktop = navItem[isMobileOnly] && !isMobile;
      const shouldHideOnMobile = navItem[isDesktopOnly] && isMobile;
      const shouldHideFromBorderfree =
        navItem[hideFromBorderfree] && isBorderfreeCustomer;

      if (
        shouldHideOnDesktop ||
        shouldHideOnMobile ||
        shouldHideFromBorderfree
      ) {
        return false;
      }
      return true;
    },
    [isBorderfreeCustomer, isMobile]
  );

  return useCallback(
    parentFilter => (navItem, i, navItems, parentItem) => {
      if (!parentFilter(navItem, i, navItems, parentItem)) {
        return false;
      }
      if (!applyFeatureFlags(navItem)) {
        return false;
      }
      return true;
    },
    [applyFeatureFlags]
  );
}
