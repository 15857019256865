import config from 'config';

import { parseDate } from '@techstyle/redux-core';

import { getAvailableQuantitySize } from './productQuantity';
import { isOneSize } from './sizes';

const collectibleTag = config.get('public.productTags.collectible');
const dmGatewayCodes = config.get('public.dmGatewayCodes');
const defaultCategories = config.get('public.productBrowser.sections');
const unisexTag = config.get('public.productTags.unisex');

export const ProductTag = config.get('public.productTags');

/** Price */
export const getDefaultSelectedPrice = ({
  isVip,
  dmgCode,
  isBorderfreeCustomer,
}) => {
  if (isVip) {
    return 'defaultPrice';
  }
  if (isBorderfreeCustomer) {
    return 'retailPrice';
  }
  // US non-members should default to having the member price
  // selected unless they arrive through a specific gateway.
  const gateway = dmGatewayCodes[dmgCode];
  if (gateway && gateway.type === 'default_retail_price') {
    return 'retailPrice';
  }
  return 'defaultPrice';
};

/** Size */
function getSizeList(masterProduct) {
  return (
    masterProduct.product_id_object_list ||
    masterProduct.productIdObjectList ||
    []
  );
}

export const findDefaultSize = (
  masterProduct,
  userProfile,
  sizeCache = null
) => {
  const { profile } = userProfile;

  // No use trying to find a default if there is no profile and size-cache info.
  if (!profile && !sizeCache) {
    return;
  }

  const { tagIdList: _tagIdList } = masterProduct;
  const defaultProductCategoryId =
    masterProduct.defaultProductCategoryId ||
    masterProduct.default_product_category_id;
  const productCategoryIdList =
    masterProduct.productCategoryIdList ||
    masterProduct.product_category_id_list;
  const tagIdList = masterProduct.tag_id_list || _tagIdList || [];
  const {
    isHarness,
    isMenUndies,
    isMenSleep,
    isBraExtender,
    isSport,
    isSlippers,
  } = getProductCategoryTypes(productCategoryIdList, tagIdList);

  const sizeList = getSizeList(masterProduct);

  const isBra = () => {
    const brasCategoryId = defaultCategories.bras.defaultProductCategoryIds[0];

    return defaultProductCategoryId === brasCategoryId;
  };

  const isBralette = () => {
    const hasBraletteCategoryId = productCategoryIdList?.includes(
      defaultCategories.bras.subsections.bralettes.categoryId
    );

    const bralettesTagIds =
      defaultCategories.bras.subsections.bralettes.categoryTagIds;
    const hasBraletteTagId = bralettesTagIds.some(categoryId =>
      tagIdList.includes(categoryId)
    );

    return isBra() && hasBraletteCategoryId | hasBraletteTagId;
  };

  const isUndie = () => {
    const undiesCategoryId =
      defaultCategories.undies.defaultProductCategoryIds[0];

    return defaultProductCategoryId === undiesCategoryId || isMenUndies;
  };

  const isLingerie = () => {
    const lingerieAndSleepCategoryTagIds = [
      ...defaultCategories.sleep.categoryTagIds,
      ...defaultCategories.lingerie.categoryTagIds,
    ];
    const lingerieAndSleepCategoryIds = [
      ...defaultCategories.sleep.defaultProductCategoryIds,
      ...defaultCategories.lingerie.defaultProductCategoryIds,
    ];

    return (
      lingerieAndSleepCategoryTagIds.some(id => tagIdList.includes(id)) ||
      lingerieAndSleepCategoryIds.includes(defaultProductCategoryId) ||
      isMenSleep
    );
  };

  let sizeToFind;

  // This should come before `isBra` because bralettes are also bras.
  if (profile) {
    if (isBraExtender) {
      sizeToFind = profile['bra-extender'];
    } else if (isSlippers) {
      sizeToFind = profile['slipper-size'];
    } else if (isHarness) {
      sizeToFind = profile['harness-size'];
    } else if (isSport) {
      sizeToFind = profile['sport-size'];
    } else if (isBralette()) {
      sizeToFind = profile['bralette-size'];
    } else if (isBra()) {
      sizeToFind = profile['bra-size'];
    } else if (isUndie()) {
      sizeToFind = profile['bottom-size'];
    } else if (isLingerie()) {
      sizeToFind = profile['lingerie-sleep-size'];
    }
  }

  // If the user hasn't set its size profile and has size cache
  if (!sizeToFind && sizeCache) {
    if (isBraExtender) {
      sizeToFind = sizeCache['bra-extender'];
    } else if (isSlippers) {
      sizeToFind = sizeCache['slipper-size'];
    } else if (isHarness) {
      sizeToFind = sizeCache['harness-size'];
    } else if (isSport) {
      sizeToFind = sizeCache['sport-size'];
    } else if (isBralette()) {
      sizeToFind = sizeCache['bralette-size'];
    } else if (isBra()) {
      sizeToFind = sizeCache['bra-size'];
    } else if (isUndie()) {
      sizeToFind = sizeCache['bottom-size'];
    } else if (isLingerie()) {
      sizeToFind = sizeCache['lingerie-sleep-size'];
    }
  }

  if (sizeToFind) {
    return sizeList.find(product => {
      // We split the product size for dual size products e.g XL/2XL
      const sizes = product.size.toString().split('/');
      return sizes.includes(sizeToFind);
    });
  }
};

export const getDefaultSize = (masterProduct, profile, sizeCache = null) => {
  const sizeList = getSizeList(masterProduct);
  const isOneSizeProduct = isOneSize(masterProduct);

  if (sizeList.length === 1) {
    // Automatically select the only size available
    const onlySizeItem = sizeList[0];
    return {
      sizeProduct: onlySizeItem,
      value: onlySizeItem.size,
      error: null,
      band: null,
      cup: null,
    };
  } else if (isOneSizeProduct) {
    const masterProductId = masterProduct.master_product_id;
    const hasMultipleProductIds = sizeList.some(
      item => item.product_id !== masterProductId
    );

    if (hasMultipleProductIds) {
      const sizeProduct = sizeList.find(
        ({ product_id: productId }) => productId === masterProductId
      );

      return {
        sizeProduct,
        value: sizeProduct.size,
        error: null,
        band: null,
        cup: null,
      };
    }
  } else {
    if (profile || sizeCache) {
      // Automatically select the size set in the user's profile
      const defaultSize = findDefaultSize(masterProduct, profile, sizeCache);

      if (defaultSize) {
        const value = defaultSize.label_instance || defaultSize.labelInstance;
        return {
          sizeProduct: defaultSize,
          value,
          error: null,
          band: null,
          cup: null,
        };
      }
    }
  }

  // Do not automatically select a size
  return {
    sizeProduct: null,
    value: null,
    error: null,
    band: null,
    cup: null,
  };
};

export const findMatchingSize = (
  product,
  currentSize,
  sizeList,
  profile,
  isPreorderable = false
) => {
  if (currentSize.value !== null) {
    const isOneSizeProduct = isOneSize(product);
    let matchSize;

    if (isOneSizeProduct) {
      matchSize = sizeList.find(
        item => item.product_id === currentSize.sizeProduct.product_id
      );
    } else {
      const currentValueSize = currentSize.sizeProduct
        ? currentSize.sizeProduct.size
        : currentSize.value;
      matchSize = sizeList.find(item => item.size === currentValueSize);
    }

    // If the Size is sold out, display an error
    if (matchSize) {
      const availableQuantity = isPreorderable
        ? matchSize.available_quantity_preorder
        : matchSize.available_quantity;
      const isMatchSizeAvailable = availableQuantity > 0;
      const error = isMatchSizeAvailable ? null : 'SIZE_UNAVAILABLE';
      return {
        sizeProduct: matchSize,
        value: matchSize.size,
        error,
      };
    }
  }

  return getDefaultSize(product, profile);
};

/** Color */
function getColorList(masterProduct) {
  return (
    masterProduct.relatedProductIdObjectList ||
    masterProduct.related_product_id_object_list ||
    []
  );
}

export const getDefaultColor = masterProduct => {
  const defaultColorId =
    masterProduct.masterProductId || masterProduct.master_product_id;
  const colorList = getColorList(masterProduct);
  const defaultColor =
    colorList.find(color => color.related_product_id === defaultColorId) || {};
  // The `color` object that `ProductDetailColorSwatches` expects
  return {
    name: defaultColor.color,
    value: defaultColor.related_product_id,
    itemNumber: defaultColor.item_number,
    permalink: defaultColor.permalink,
    productId: defaultColor.related_product_id,
    sizes: defaultColor.product_id_object_list,
  };
};

/** Linked Products Helpers */
// Linked products combine a size list of two master products
export const getLinkedProductsSizeList = masterProduct => {
  // is the check for a linked product necessary?
  // or should this function be used for all master products, regardless of whether they are linked or not?
  if (masterProduct.linked_master_product) {
    const defaultColorId = masterProduct.master_product_id;
    const colorList = getColorList(masterProduct);
    const colorProduct = colorList.find(
      color => color.related_product_id === defaultColorId
    );

    return getSizeList(colorProduct);
  } else {
    return getSizeList(masterProduct);
  }
};

export const getLinkedProduct = product => {
  // A linked product could be on the masterProduct or colorProduct
  let linkedProduct = product.linked_master_product
    ? product.linked_master_product
    : null;
  if (Array.isArray(linkedProduct)) {
    linkedProduct = linkedProduct[0];
  }
  return linkedProduct;
};

export const checkPreorderForSingleItem = ({ product, now }) => {
  if (product.master_product_id && product.date_preorder_expires) {
    return now() <= parseDate(product.date_preorder_expires);
  } else if (product.masterProductId && product.datePreorderExpires) {
    return now() <= parseDate(product.datePreorderExpires);
  }
  return false;
};

export const checkPreorderForSets = ({ product, now }) => {
  const itemsInSet = product.products_in_set || product.bundleItems;
  return itemsInSet
    ? itemsInSet.some(setItem =>
        checkPreorderForSingleItem({ product: setItem, now })
      )
    : false;
};

/** Selections state for PDPs */
export const getDefaultSetsSelections = (
  setProduct,
  profile,
  sizeCache = null,
  now = () => {}
) => {
  /**
   * This is the shape of selections:
   * selections: {
   *   [master_product_id]: {
   *     masterProductId,
   *     color: {
   *       name
   *       sizes
   *       value
   *     },
   *     size: {
   *       sizeProduct
   *       value
   *       error
   *       band
   *       cup
   *     }
   *   }
   * }
   */
  const selections = {};
  let band = null;
  let cup = null;
  const masterProductArray = setProduct.products_in_set;

  masterProductArray.forEach(product => {
    const { master_product_id: masterProductId } = product;
    const displayBraSize =
      isSingleProductBra(product) && !isSingleProductBralette(product);
    const size = getDefaultSize(product, profile, sizeCache);
    const isPreorderItem = checkPreorderForSingleItem({ product, now });

    const color = getDefaultColor(product);
    if (displayBraSize && size.value != null) {
      const braSizeCategorized = findBraSizeCategory(size.value);
      band = braSizeCategorized.band;
      cup = braSizeCategorized.cup;
    }
    const availableQuantity = getAvailableQuantitySize(
      color.sizes,
      size,
      isPreorderItem
    );

    const isSizeSoldOut = size.sizeProduct ? availableQuantity <= 0 : false;
    const error = isSizeSoldOut ? 'SIZE_UNAVAILABLE' : null;
    selections[masterProductId] = {
      masterProductId,
      color,
      isPreorderItem,
      size: {
        ...size,
        error,
        band,
        cup,
      },
    };
  });

  return selections;
};

/** Selections state for PDPs */
export const getDefaultSingleSelections = (
  masterProduct,
  profile,
  isSoldOut = false,
  sizeCache = null,
  isPreorderable = false
) => {
  /**
   * This is the shape of selections:
   * selections: {
   *     masterProductId,
   *     color: {
   *       name
   *       sizes
   *       value
   *     },
   *     size: {
   *       sizeProduct
   *       value
   *       error
   *       band
   *       cup
   *     }
   * }
   */
  let selections = {};
  let band = null;
  let cup = null;

  const size = getDefaultSize(masterProduct, profile, sizeCache);
  const displayBraSize =
    isSingleProductBra(masterProduct) &&
    !isSingleProductBralette(masterProduct);

  if (displayBraSize && size.value != null) {
    const braSizeCategorized = findBraSizeCategory(size.value);
    band = braSizeCategorized.band;
    cup = braSizeCategorized.cup;
  }

  const masterProductId =
    masterProduct.masterProductId || masterProduct.master_product_id;
  const color = getDefaultColor(masterProduct);
  const availableQuantity = isSoldOut
    ? 0
    : getAvailableQuantitySize(color.sizes, size, isPreorderable);
  const isSizeSoldOut = size.sizeProduct ? availableQuantity <= 0 : isSoldOut;
  const error = isSizeSoldOut ? 'SIZE_UNAVAILABLE' : null;

  selections = {
    masterProductId,
    color: getDefaultColor(masterProduct),
    size: {
      ...getDefaultSize(masterProduct, profile, sizeCache),
      error,
      band,
      cup,
    },
  };

  return selections;
};

// Return only the populated size products from `selections`.
export const findSizeSetProducts = selections => {
  const ids = Object.keys(selections);
  return ids
    .map(id => selections[id].size.sizeProduct)
    .filter(product => product != null);
};

// Return only the populated size products from `selections`.
export const findSizeSingleProducts = selections => {
  return selections.size.sizeProduct ? [selections.size.sizeProduct] : [];
};

/**
 * Given a count of product images, return the list of angle names.
 * NOTE: Even if the image count is 0, the list will always include the
 * `laydown` angle. Products must have an image of this angle.
 */
function getProductImageAngles(imageCount) {
  const angles = [];
  imageCount = imageCount || 0;
  for (let i = 1; i < imageCount; i++) {
    angles.push(`${i}`);
  }
  angles.push('laydown');
  return angles;
}

/**
 * Given the number of product images (for the primary model) indicated by the
 * `image_count` product detail object, or 0 if none is found.
 */
function getProductImageCount(product) {
  if (product.product_detail_id_object_list) {
    const imageData = product.product_detail_id_object_list.find(detail => {
      return detail.object === 'image_count';
    });
    if (imageData) {
      return imageData.quantity || 0;
    }
  }
  return 0;
}

/**
 * Parse alternate models from a product's `meta_data` field if present.
 */
export function parseAltModels(product, isLinkedProduct = false) {
  const _metaData = product.metaData || product.meta_data;

  if (_metaData) {
    // FIXME: API returns non-array for linked products.
    const metaData = Array.isArray(_metaData) ? _metaData : [_metaData];
    const modelData = metaData.find(
      data => data.metaDescriptionOverride || data.meta_description_override
    );
    const masterProductId =
      product.masterProductId || product.master_product_id;

    if (modelData) {
      const { models, areModelsInJsonFormat } = parseProductMetaDescription(
        modelData.metaDescriptionOverride || modelData.meta_description_override
      );

      return models
        .map((model, index) => {
          let match;

          if (!areModelsInJsonFormat) {
            match = model.trim().match(/^(\d+):\s*(.+)/);
          }

          if (match || areModelsInJsonFormat) {
            return {
              product,
              isLinkedProduct,
              modelIndex: index + 1,
              modelId: `${masterProductId}-${index + 1}`,
              angles: getProductImageAngles(
                areModelsInJsonFormat ? model.images : parseInt(match[1], 10)
              ),
              description: areModelsInJsonFormat ? null : match[2],
              jsonFormat: areModelsInJsonFormat ? model : null,
            };
          }
        })
        .filter(Boolean);
    }
  }
  return [];
}

/**
 * Parse model's `meta_data` or product's `short_description` field if identified as a JSON.
 * Since short_desciption does not accept double quotes, we have an exception
 * to receive a JSON with a grave accent in placement for double quote.
 */
export function parseProductMetaDescription(description) {
  let models = [];
  let areModelsInJsonFormat = false;

  if (description) {
    try {
      models = JSON.parse(
        `[${description
          .replace(/\r?\n|\r/g, '')
          .replace(/\[/g, '{')
          .replace(/]/g, '}')
          .replace(/}{/g, '},{')
          .replace(/`/g, '"')}]`
      );
      areModelsInJsonFormat = true;
    } catch (exception) {
      models = description.split('|');
    }
  }

  return {
    models,
    areModelsInJsonFormat,
  };
}

/**
 * Get a list of models with descriptions and the available image angles for a
 * product. There will always be at least one "primary" model, and more will be
 * parsed from the product's `meta_data` field if present.
 *
 * The list will not include models from the linked product (if any).
 */
export function getProductModelImages(
  product,
  includeLinkedProduct = false,
  isLinkedProduct = false
) {
  const shortDescription =
    product.shortDescription || product.short_description;
  const masterProductId = product.masterProductId || product.master_product_id;
  const { models, areModelsInJsonFormat } =
    parseProductMetaDescription(shortDescription);

  const primaryModel = {
    product,
    isLinkedProduct,
    modelIndex: 0,
    modelId: `${masterProductId}-0`,
    angles: getProductImageAngles(getProductImageCount(product)),
    description: areModelsInJsonFormat ? null : shortDescription || null,
    // for some products, we have 2 models in short_description, one ambassador model and one member model
    // they will be separated by a comma in Console, and the first is the ambassdor model
    isCombinedModel: areModelsInJsonFormat
      ? models.length > 1
      : shortDescription && shortDescription.includes('|'),
    jsonFormat: areModelsInJsonFormat ? models : null,
  };

  let linkedModels = [];
  if (includeLinkedProduct) {
    const linkedProduct = getLinkedProduct(product);
    if (linkedProduct) {
      linkedModels = getProductModelImages(linkedProduct, false, true);
    }
  }

  return [
    primaryModel,
    ...parseAltModels(product, isLinkedProduct),
    ...linkedModels,
  ];
}

export function isSingleProductBralette(masterProduct) {
  const tagList = masterProduct.tag_id_list || masterProduct.tagIdList || [];
  const brasCategoryId = defaultCategories.bras.defaultProductCategoryIds[0];
  const bralettesTagIds =
    defaultCategories.bras.subsections.bralettes.categoryTagIds;
  const hasBraletteTagId = bralettesTagIds.some(categoryId =>
    tagList.includes(categoryId)
  );
  return (
    (
      masterProduct.product_category_id_list ||
      masterProduct.productCategoryIdList
    ).includes(brasCategoryId) && hasBraletteTagId
  );
}

export function isSingleProductBra(masterProduct) {
  const brasCategoryId = defaultCategories.bras.defaultProductCategoryIds[0];

  return (
    masterProduct.product_category_id_list ||
    masterProduct.productCategoryIdList ||
    []
  ).includes(brasCategoryId);
}

export function findBraSizeCategory(braSize) {
  // There is a numeric size introduced and we need to convert it to
  // string here to prevent from breaking.
  if (typeof braSize !== 'string') {
    braSize = braSize.toString();
  }

  const [band, cup] = braSize.match(/[a-z]+|[^a-z]+/gi) || [];
  return {
    band,
    cup,
  };
}

export function categorizeBraSize(braSizes) {
  const categorizedBraSizes = [...braSizes];
  return categorizedBraSizes.map(size => {
    return {
      ...size,
      ...findBraSizeCategory(size.value),
    };
  });
}

export function formatShoeSizes(shoeSizes) {
  return shoeSizes.map(size => {
    const [alphabet, numeric] =
      (size && size.value.match(/[a-z]+|[^a-z]+/gi)) || [];
    return {
      ...size,
      alphabet,
      numeric: numeric.replace(/[()]/g, ''),
    };
  });
}

/**
 * Return an object with all categories a product fits into.
 */
export function getProductCategoryTypes(productCategoryIds = [], tagList = []) {
  const productCategoryIdsSet = new Set(productCategoryIds);
  const tagListSet = new Set(tagList);
  const bralettesTagIds =
    defaultCategories.bras.subsections.bralettes.categoryTagIds;
  const brasCategoryId = defaultCategories.bras.defaultProductCategoryIds[0];
  const lingerieCategoryIds = defaultCategories.lingerie.categoryTagIds;
  const sleepCategoryIds = defaultCategories.sleep.categoryTagIds;
  const undiesCategoryId =
    defaultCategories.undies.defaultProductCategoryIds[0];
  const accessoriesId =
    defaultCategories.accessories.defaultProductCategoryIds[0];
  const setId = defaultCategories.sets.defaultProductCategoryIds[0];
  const menId = defaultCategories.men.categoryIds[0];
  const menUndiesId =
    defaultCategories.men.subsections.undies.defaultProductCategoryIds[0];
  const menSleepId =
    defaultCategories.men.subsections.sleep.defaultProductCategoryIds[0];
  const slippersId =
    defaultCategories.sleep.subsections.slippers.categoryTagIds[0];
  const harnessId =
    defaultCategories.accessories.subsections.harness.categoryTagIds[0];
  const harnessOneSizeId =
    defaultCategories.accessories.subsections.harnessOneSize.categoryTagIds[0];
  const trinketsId =
    defaultCategories.accessories.subsections.trinkets.categoryTagIds[0];
  const braExtenderIds =
    defaultCategories.accessories.subsections['bra-extenders'].categoryTagIds;
  const adhesiveBrasIds =
    defaultCategories.accessories.subsections['adhesive-bras'].categoryTagIds;
  const hasBraletteTagId = bralettesTagIds.some(categoryId =>
    tagListSet.has(categoryId)
  );
  const allGenderCategoryId =
    defaultCategories.allGender.defaultProductCategoryIds[0];
  const sportCategoryIds = defaultCategories.sport.categoryIds;

  return {
    isAllGender: productCategoryIdsSet.has(allGenderCategoryId),
    isBra: productCategoryIdsSet.has(brasCategoryId) && !hasBraletteTagId,
    isBralette: productCategoryIdsSet.has(brasCategoryId) && hasBraletteTagId,
    isLingerie: lingerieCategoryIds.some(categoryId =>
      tagListSet.has(categoryId)
    ),
    isSleep: sleepCategoryIds.some(categoryId =>
      productCategoryIdsSet.has(categoryId)
    ),
    isUndies: productCategoryIdsSet.has(undiesCategoryId),
    isAccessories: productCategoryIdsSet.has(accessoriesId),
    isSet: productCategoryIdsSet.has(setId),
    isMen: productCategoryIdsSet.has(menId),
    isMenUndies: productCategoryIdsSet.has(menUndiesId),
    isMenSleep: productCategoryIdsSet.has(menSleepId),
    isSlippers: productCategoryIdsSet.has(slippersId),
    isHarness: productCategoryIdsSet.has(harnessId),
    isHarnessOneSize: productCategoryIdsSet.has(harnessOneSizeId),
    isSport: sportCategoryIds.some(categoryId =>
      productCategoryIdsSet.has(categoryId)
    ),
    isTrinket: productCategoryIdsSet.has(trinketsId),
    isBraExtender: braExtenderIds.some(categoryId =>
      productCategoryIdsSet.has(categoryId)
    ),
    isAdhesiveBra: adhesiveBrasIds.some(categoryId =>
      productCategoryIdsSet.has(categoryId)
    ),
  };
}

export function getProductCategoryTypeId(
  productCategoryIdList,
  tagIdList = []
) {
  const {
    isBra,
    isBralette,
    isLingerie,
    isSleep,
    isUndies,
    isAccessories,
    isSet,
    isMen,
    isSlippers,
    isSport,
  } = getProductCategoryTypes(productCategoryIdList, tagIdList);

  if (isBralette) {
    return defaultCategories.bras.subsections.bralettes.categoryTagIds[0];
  } else if (isSport) {
    return defaultCategories.sport.defaultProductCategoryIds[0];
  } else if (isSlippers) {
    return defaultCategories.sleep.defaultProductCategoryIds[0];
  } else if (isBra) {
    return defaultCategories.bras.defaultProductCategoryIds[0];
  } else if (isLingerie) {
    return defaultCategories.lingerie.categoryTagIds[0];
  } else if (isSleep) {
    return defaultCategories.sleep.categoryTagIds[0];
  } else if (isUndies) {
    return defaultCategories.undies.defaultProductCategoryIds[0];
  } else if (isAccessories) {
    return defaultCategories.accessories.defaultProductCategoryIds[0];
  } else if (isSet) {
    return defaultCategories.sets.defaultProductCategoryIds[0];
  } else if (isMen) {
    return defaultCategories.men.categoryIds[0];
  }
  return '';
}

export function getProductCategoryKey(productCategoryIdList, tagIdList) {
  const {
    isBra,
    isMenUndies,
    isBralette,
    isHarness,
    isLingerie,
    isSleep,
    isUndies,
    isBraExtender,
    isSport,
    isSlippers,
  } = getProductCategoryTypes(productCategoryIdList, tagIdList);
  let productType = null;
  switch (true) {
    case isBralette:
      productType = 'bralette-size';
      break;
    case isBraExtender:
      productType = 'bra-extender';
      break;
    case isHarness:
      productType = 'harness-size';
      break;
    case isBra:
      productType = 'bra-size';
      break;
    case isLingerie:
    case isSleep:
      productType = 'lingerie-sleep-size';
      break;
    case isSport:
      productType = 'sport-size';
      break;
    case isSlippers:
      productType = 'slipper-size';
      break;
    case isUndies:
    case isMenUndies:
      productType = 'bottom-size';
      break;
  }
  return productType;
}

export function hasLinkedProduct(product) {
  return product.products_in_set.some(product => {
    return product.linked_master_product != null;
  });
}

export function getSetItemLinkedProduct(
  setItem,
  selectedColor,
  isLinkedSize = false
) {
  if (!isLinkedSize) {
    return null;
  }
  return setItem.related_product_id_object_list.filter(
    color => color.related_product_id === selectedColor
  )[0].linked_master_product[0];
}

export function checkIsCollectible(product) {
  if (product.tag_id_list) {
    // List available in product API data
    return product.tag_id_list.includes(collectibleTag);
  }
  return false;
}

export function getPairItWithProductIds(parentProduct) {
  return parentProduct && parentProduct.wear_it_with
    ? parentProduct.wear_it_with.map(product => product.master_product_id)
    : [];
}

export function isUnisex(product) {
  if (product.tag_id_list) {
    return product.tag_id_list.includes(unisexTag);
  }
  return false;
}

export function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function reformatItemsKeys(items) {
  const reformattedItemsList = [];
  items.forEach(item => {
    const newObject = {};
    Object.keys(item).forEach(camelKey => {
      newObject[camelToUnderscore(camelKey)] = item[camelKey];
    });
    reformattedItemsList.push(newObject);
  });
  return reformattedItemsList;
}
