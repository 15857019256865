import React, { Component } from 'react';

import { captureException } from '@sentry/nextjs';
import config from 'config';
import PropTypes from 'prop-types';
import styled, { useTheme, withTheme } from 'styled-components';

import { Router } from '@techstyle/next-routes';
import { AssetContainer } from '@techstyle/react-assets';
import { Heading } from '@techstyle/react-components';
import { OffCanvasMenu } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';

import { SignUpMethod } from '../../constants/auth';
import { MembershipActionLocation } from '../../constants/checkout';
import { SIGN_UP } from '../../constants/signIn';
import { BasicButton } from '../../styles/button';
import { Paragraph } from '../../styles/paragraph';
import { toStringSet } from '../../utils/assets';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { isProductSet } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import { useAutoBundle2 } from '../../utils/useAutoBundle2';
import useGiftWithPurchase from '../../utils/useGiftWithPurchase';
import { useLeadAgeWithInThreshold } from '../../utils/useLeadAgeThreshold';
import { useVipCTAStyling } from '../../utils/useVipCTAStyling';
import { Container as BasketItemList } from '../BasketItemList';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import { Component as Bounceback } from '../Bounceback';
import { useByoContext } from '../ByoProvider';
import { Component as CloseButton } from '../CloseButton';
import { Component as EmptyCartMessage } from '../EmptyCartMessage';
import { Component as NewPricingSkinnyBanner } from '../NewPricingSkinnyBanner';
import ProgressLoader from '../ProgressLoader/ProgressLoaderContainer';
import { Container as PromotionProgressBar } from '../PromotionProgressBar';
import { Component as StyleContext } from '../StyleContext';
import ToastContext from '../ToastContext/ToastContext';
import ToastMessages from '../ToastMessages/ToastMessages';

const Drawer = styled(OffCanvasMenu)`
  > .Overlay {
    z-index: 1100;
    max-width: 100vw;
    ${v2`
      background-color: ${({ theme }) => theme.colors.black}00;
      transition: 0.2s ease-in-out background-color;
    `}

    &.ReactModal__Overlay--after-open {
      ${v1`background-color: transparent;`}
      ${v2`background-color: ${({ theme }) => theme.colors.black}1A;`}
    }

    > .Modal {
      ${v1`height: 500px;`}

      box-shadow: ${props => `0 4px 8px ${props.theme.colors.lightShadow1}`}
      overflow: hidden;
      align-self: flex-start;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;

  background: ${props => props.theme.colors.white};
  pointer-events: ${props => (props.hasOverlay ? 'none' : 'auto')};

  ${v2`
    @media (max-width: ${props => props.theme.breakpoints.xxlarge - 1}px) {
      height: 100dvh;
    }
  `}
`;

const Body = styled.div`
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const DrawerCloseButton = styled(CloseButton)`
  top: 50%;
  right: 0;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  line-height: 1;
  color: ${props => props.theme.colors.subdued};
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 44px;
  border-bottom: 1px solid ${props => props.theme.colors.gray250};
`;

const CustomParagraph = styled(Paragraph).attrs({
  size: 12,
  align: 'center',
})`
  ${v1`
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    color: ${props =>
      props.default ? props.theme.colors.default : props.theme.colors.promo};
    padding: ${props => `0 0 ${props.theme.spacing.small}px 0`};

  `}
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph5Uppercase.textStyles}
    background-color: ${props => props.theme.colors.lavender200};
    color: ${props => props.theme.colors.gunmetal};
    padding: ${props =>
      `4px ${props.theme.spacing.tiny}px 4px ${props.theme.spacing.tiny}px`};
    margin-bottom: ${props => props.theme.spacing.tiny}px;
  `}
`;

const InfluencerToastMessage = styled(Paragraph).attrs({
  size: 12,
  align: 'center',
})`
  font-weight: 300;
  letter-spacing: 0.3px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.active};
  padding: 9px 28px;
`;

const Footer = styled.div`
  position: relative;
  margin-top: auto;
  padding: 16px 20px 20px 20px;
  ${v1`box-shadow: 0 -2px 4px 0 ${props => props.theme.colors.lightShadow1};`}
  ${v2`border-top: 1px solid ${props => props.theme.colors.stone}`}
`;

const Title = styled(Heading).attrs({
  level: 2,
})`
  ${v1`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
  `}
  ${v2`
    ${({ theme }) => theme.header.variants.h3.textStyles}
  `}
  text-align: center;
`;

const CheckoutButton = styled(BasicButton).attrs({
  fullWidth: true,
})``;

const TotalItem = styled.span`
  font-weight: bold;
`;

const TitleMessage = () => {
  const { themeVersion } = useTheme();

  return themeVersion === 'v2' ? (
    <FormattedMessage
      id="global_checkout.cart_title_your_bag"
      defaultMessage="Your Bag"
    />
  ) : (
    <FormattedMessage
      id="global_checkout.cart_title"
      defaultMessage="Shopping Bag"
    />
  );
};

class CartDrawer extends Component {
  static propTypes = {
    accountMembershipItemInfo: PropTypes.object,
    addFirstByoItem: PropTypes.func.isRequired,
    addMembershipToCart: PropTypes.func.isRequired,
    allProducts: PropTypes.object,
    allowExclusiveCheckout: PropTypes.bool,
    allowOutOfStockCheckout: PropTypes.bool,
    assets: PropTypes.object,
    availableTokenQuantity: PropTypes.number,
    byoSetsForMostExpensiveItem: PropTypes.array.isRequired,
    cart: PropTypes.object.isRequired,
    containsBundle: PropTypes.bool.isRequired,
    deleteBasketItem: PropTypes.func.isRequired,
    deleteBasketItemsByProductId: PropTypes.func.isRequired,
    deleteMembershipFromCart: PropTypes.func.isRequired,
    deleteProductSet: PropTypes.func.isRequired,
    freeShippingThreshold: PropTypes.number.isRequired,
    gwpMinSubtotal: PropTypes.number,
    hasOnlyMembershipItem: PropTypes.bool,
    hasMembershipInCart: PropTypes.bool,
    hasOutOfStockItems: PropTypes.bool,
    increaseByoStep: PropTypes.func.isRequired,
    initiateByoFlow: PropTypes.func.isRequired,
    isAutoBundle2FeatureEnabled: PropTypes.bool,
    isBorderfreeCustomer: PropTypes.bool,
    isDowngradedMember: PropTypes.bool,
    isEu: PropTypes.bool.isRequired,
    isFlowInitiated: PropTypes.bool.isRequired,
    isFreeShippingFlagEnabled: PropTypes.bool,
    isGwpAvailable: PropTypes.bool,
    isInfluencer: PropTypes.bool,
    isLead: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    isPaygo: PropTypes.bool,
    isVip: PropTypes.bool,
    isVipCTAStyling: PropTypes.bool,
    // If the user is a visitor, show Sign In modal after clicking 'Checkout'
    isVisitor: PropTypes.bool,
    loadAssets: PropTypes.func,
    loadCart: PropTypes.func.isRequired,
    loadProfile: PropTypes.func,
    membershipItem: PropTypes.object,
    membershipItemInfo: PropTypes.object,
    mostExpensiveItem: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    setByoGroupCode: PropTypes.func.isRequired,
    shouldDisplayGwp: PropTypes.bool,
    tld: PropTypes.string,
    toggleSignInModal: PropTypes.func,
    trackAddedItems: PropTypes.func.isRequired,
    trackByoInterstitialOpen: PropTypes.func.isRequired,
    updateItemQuantity: PropTypes.func.isRequired,
    updateSessionDetail: PropTypes.func,
    updateSetItemQuantity: PropTypes.func.isRequired,
    sweepCart: PropTypes.func.isRequired,
    isGuestCheckoutEnabled: PropTypes.bool,
  };

  static defaultProps = {
    allowOutOfStockCheckout: false,
    hasOnlyMembershipItem: false,
  };

  async componentDidMount() {
    if (this.props.isOpen) {
      this.props.loadCart();
    }
    await this.props.loadAssets(['upsell_html_minicart']);
  }

  componentDidUpdate(prevProps, prevState) {
    // This will `return` immediately every time if profile is already loaded.
    const { loadProfile } = this.props;
    loadProfile();
  }

  renderAssetContainer = asset => {
    const {
      accountMembershipItemInfo,
      allowExclusiveCheckout,
      cart,
      freeShippingThreshold,
      isVip,
      membershipItem,
      membershipItemInfo,
      isFreeShippingFlagEnabled,
    } = this.props;

    const hasMembershipItem = !!membershipItem;

    const activeMembershipInfo =
      accountMembershipItemInfo || membershipItemInfo;

    const hasMemberFreeShipping = activeMembershipInfo
      ? activeMembershipInfo.freeShipping
      : false;

    const shouldShowProgressBar =
      !hasMemberFreeShipping && !allowExclusiveCheckout;

    let hasFreeShipping = false;

    if (isVip) {
      hasFreeShipping = !!asset || hasMemberFreeShipping;
    } else {
      hasFreeShipping = !!asset && hasMembershipItem;
    }

    if (isFreeShippingFlagEnabled && (hasFreeShipping || !hasFreeShipping)) {
      return (
        <CustomParagraph>
          <FormattedMessage
            id="global_checkout.free_shipping_congratulation"
            defaultMessage="Congrats! You get Free Shipping."
          />
        </CustomParagraph>
      );
    } else if (!hasFreeShipping || !isFreeShippingFlagEnabled) {
      return (
        <BorderfreeGuard>
          {shouldShowProgressBar || !isFreeShippingFlagEnabled ? (
            <PromotionProgressBar
              total={cart.subtotalMinusDiscount}
              promoThreshold={freeShippingThreshold}
              promoType="freeShipping"
            />
          ) : null}
        </BorderfreeGuard>
      );
    } else {
      return null;
    }
  };

  renderInfluencerToastMessage = () => {
    const { cartItemInfluencerCount, profile } = this.props;
    const influencerLimit = profile.influencerInfo.maxProductQty;

    if (cartItemInfluencerCount <= influencerLimit) {
      const total = `${cartItemInfluencerCount}/${influencerLimit}`;
      return (
        <InfluencerToastMessage>
          <FormattedMessage
            id="global_checkout.influencer_number_items"
            defaultMessage="You've added {total} items to your cart."
            values={{
              total: <TotalItem>{total}</TotalItem>,
            }}
          />
        </InfluencerToastMessage>
      );
    } else {
      return null;
    }
  };

  handleClose = () => {
    this.props.onToggle(false);
  };

  signInBeforeCheckout = showMessage => event => {
    const {
      isVisitor,
      updateSessionDetail,
      products,
      toggleSignInModal,
      isGuestCheckoutEnabled,
    } = this.props;

    if (isVisitor && !isGuestCheckoutEnabled) {
      updateSessionDetail({
        name: 'signup_minicart',
        value: '',
      });

      const product = [...products].sort((a, b) => a.lineId - b.lineId)[
        products.length - 1
      ];
      const isSet = isProductSet(product);
      toggleSignInModal(
        true,
        SIGN_UP,
        SignUpMethod.CARTDRAWER,
        product.masterProductId,
        isSet
      ).then(data => {
        if (data && data.signedIn) {
          this.continueCheckout(true);
        } else {
          showMessage(
            <FormattedMessage
              id="global_checkout.sign_in_error_message"
              defaultMessage="Please sign in or sign up to continue checkout."
            />
          );
        }
      });
    } else {
      // Already logged in
      this.continueCheckout();
    }
  };

  continueCheckout = async isAfterSignIn => {
    const {
      addFirstByoItem,
      availableTokenQuantity,
      byoSetsForMostExpensiveItem,
      containsBundle,
      deleteBasketItem,
      increaseByoStep,
      initiateByoFlow,
      isAutoBundle2FeatureEnabled,
      isFlowInitiated,
      isVip,
      mostExpensiveItem,
      onToggle,
      profile,
      setByoGroupCode,
      tld,
      trackAddedItems,
      trackByoInterstitialOpen,
      sweepCart,
    } = this.props;
    onToggle(false);

    const twoItemBundleByoProductId = config.get(
      `public.byo.twoItemBundleByoProductId`
    )[tld];

    const twoItemBundle =
      byoSetsForMostExpensiveItem.find(
        byoSet => byoSet.byoProductId === twoItemBundleByoProductId
      ) || null;

    const doInterstitialLogic = async () => {
      try {
        const result = await addFirstByoItem(
          mostExpensiveItem.productId,
          twoItemBundle.byoProductId,
          1
        );
        if (result?.payload?.lastByoGroupCode) {
          await deleteBasketItem(
            mostExpensiveItem.cartLineId,
            MembershipActionLocation.MINI_CART
          );

          const byoGridUrl = twoItemBundle.combinationOptions[0]?.byoGridsUrl;

          initiateByoFlow({
            byoSet: twoItemBundle,
            isInterstitialFlow: true,
            firstProductMpid: mostExpensiveItem.masterProductId,
          });

          setByoGroupCode(result.payload.lastByoGroupCode);
          trackAddedItems(mostExpensiveItem);
          increaseByoStep();
          trackByoInterstitialOpen({
            masterProductId: mostExpensiveItem.masterProductId,
            profile,
          });
          Router.push(
            `${byoGridUrl}?lastMasterProductId=${mostExpensiveItem.masterProductId}&byoInterstitial=true`
          );
        }
      } catch (e) {
        captureException(e);
        Router.push('/cart');
      }
    };

    // If AutoBundle2 Is On and they meet the Reqs do Sweep
    if (availableTokenQuantity > 0 && isVip && isAutoBundle2FeatureEnabled) {
      try {
        const result = await sweepCart();
        const itemsFromSweep = result?.payload?.items;
        const sweepContainsBundles = itemsFromSweep.some(
          item => item.bundleItems?.length > 0
        );
        if (
          !sweepContainsBundles &&
          !isFlowInitiated &&
          twoItemBundle !== null
        ) {
          await doInterstitialLogic();
        }
      } catch (e) {
        captureException(e);
      }
    }

    if (
      availableTokenQuantity > 0 &&
      !containsBundle &&
      !isFlowInitiated &&
      twoItemBundle !== null &&
      !isAutoBundle2FeatureEnabled
    ) {
      await doInterstitialLogic();
    } else {
      // FIXME: For some reason making the cart load twice fixes an issue where incorrect pricing data
      // is displayed in the cart. After the first load of the cart the information is incorrect, but it
      // is correct after the second load. This is a temporary fix until there is an API fix.
      // The second load happens on the cart page, see `pages/cart.js`
      if (isAfterSignIn) {
        await this.props.loadCart();
      }
      Router.push('/cart');
    }
  };

  updateItemQuantity = async (productGroupKey, productType, quantity) => {
    productType
      ? await this.props.updateSetItemQuantity(productGroupKey, quantity)
      : await this.props.updateItemQuantity(productGroupKey, quantity);
  };

  deleteItem = async (
    productGroupKey,
    productType,
    showMessage,
    shouldDeleteByProductId,
    isEchoCart
  ) => {
    const { deleteBasketItem, deleteBasketItemsByProductId, deleteProductSet } =
      this.props;
    const deleteItem =
      (productType && !isEchoCart && deleteProductSet) ||
      (shouldDeleteByProductId && deleteBasketItemsByProductId) ||
      deleteBasketItem;

    await deleteItem(
      productGroupKey,
      MembershipActionLocation.MINI_CART,
      false
    );

    const toastMessage = shouldDeleteByProductId ? (
      <FormattedMessage
        id="global_checkout.bundled_items_removed_message"
        defaultMessage="Bundled items removed"
      />
    ) : (
      <FormattedMessage
        id="global_checkout.cart_item_removed_message"
        defaultMessage="Item removed"
      />
    );

    showMessage(toastMessage);
  };

  addMembershipToCart = async () => {
    // Track when membership item is manually added back to cart
    await this.props.addMembershipToCart({
      location: MembershipActionLocation.MINI_CART,
    });
  };

  removeMembership = async showMessage => {
    // TODO: if DELETE_MEMBERSHIP_ITEM actions use /cart/items/${itemId} endpoint
    // this will need to change
    await this.props.deleteMembershipFromCart({
      location: MembershipActionLocation.MINI_CART,
    });

    showMessage(
      <FormattedMessage
        id="global_checkout.vip_item_removed_message"
        defaultMessage="Savage X Rewards Membership Savings Removed"
      />
    );
  };

  getUpsellProduct = () => {
    const { assets, products, isBorderfreeCustomer } = this.props;
    const upsellAssets = assets.containers.upsell_html_minicart;

    if (!upsellAssets || !upsellAssets.data) {
      return null;
    }

    if (upsellAssets.data.assets && upsellAssets.data.assets.length) {
      // the api is returning the promo is the h2l of priority order
      // and it's also only returning corresponding assets based on user segements
      // so the first promo in asset array should always be the best promo (promo with highest priority)
      const bestPromo = upsellAssets.data.assets[0];
      const { fplId, flags, numProducts, numPerOrder } =
        bestPromo.options.customVars;
      const promoProducts = products.filter(
        product =>
          product.fplIds &&
          product.fplIds.length &&
          product.fplIds.includes(fplId)
      );
      // display the upsell promo bundle when
      // 1. in the mini cart - from Cart Drawer
      // 2. is a bundle promo - checking formattedflags
      // 3. the product has the asset fplId - promoProducts
      // 4. the product starts a new set of promo bundle - promoStartProduct
      // 5. won't be showing promo with 'noBorderFree' flag for border free customer
      if (promoProducts.length && flags) {
        const formattedflags = toStringSet(
          bestPromo.options.customVars.flags,
          true
        );

        if (formattedflags.has('noborderfree') && isBorderfreeCustomer) {
          return null;
        }

        const remainder = promoProducts.length % numProducts;
        const promoStartProduct = remainder
          ? promoProducts[promoProducts.length - remainder]
          : null;

        if (
          formattedflags.has('bundle') &&
          promoProducts.length / numProducts < numPerOrder
        ) {
          return promoStartProduct;
        }
      }
    }
    return null;
  };

  renderPromoProgressBar = () => {
    const { cart, gwpMinSubtotal, isGwpAvailable, shouldDisplayGwp } =
      this.props;

    // we check for shouldDisplayGwp because GWP is the promo that should be displayed
    const promoThreshold = gwpMinSubtotal;

    const promoThresholdExists = promoThreshold != null;

    const shouldRenderPromoProgressBar =
      isGwpAvailable && shouldDisplayGwp && promoThresholdExists;

    if (!shouldRenderPromoProgressBar) {
      return null;
    }

    const isGoalReached = cart.subtotalMinusDiscount >= promoThreshold;

    const promoType = 'gwp';

    return (
      <PromotionProgressBar
        isGoalReached={isGoalReached}
        total={cart.subtotalMinusDiscount}
        promoThreshold={promoThreshold}
        promoType={promoType}
        shouldAnimateOnThreshold
        shouldShowProgressBar
      />
    );
  };

  render() {
    const {
      accountMembershipItemInfo,
      allowExclusiveCheckout,
      allowOutOfStockCheckout,
      assets,
      cart,
      hasNonPreorderItems,
      hasOnlyMembershipItem,
      hasOutOfStockItems,
      isBorderfreeCustomer,
      isOpen,
      isVip,
      isVipCTAStyling,
      isInfluencer,
      membershipItem,
      membershipItemInfo,
      onlyHasPreorderItems,
      products,
    } = this.props;

    const activeMembershipInfo =
      accountMembershipItemInfo || membershipItemInfo;

    const hasMemberFreeShipping = activeMembershipInfo
      ? activeMembershipInfo.freeShipping
      : false;

    const shouldShowProgressBar =
      !hasMemberFreeShipping && !allowExclusiveCheckout;

    const borderfreeCustomerWithMembership =
      isBorderfreeCustomer && membershipItem;

    const upsellProduct = this.getUpsellProduct();

    let showCartFooter = true;
    if (!isVip && !isBorderfreeCustomer && !hasNonPreorderItems) {
      showCartFooter = false;
    }

    // When the site initially loads, we hit `cart/items/count` which only returns
    // `cart.itemCount` but not `cart.items`. The `hasNotLoadedCart` check allows
    // us to display a custom UI if the cart is not properly loaded yet.
    const hasNotLoadedCart = cart.items.length === 0 && cart.itemCount > 0;
    const emptyCartMessage = hasNotLoadedCart ? null : <EmptyCartMessage />;
    const upsellAssetToTrack =
      assets?.containers?.upsell_html_minicart?.data?.assets?.[0];

    return (
      <Drawer
        right
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        closeButton={null}
      >
        <StyleContext context="cartDrawer">
          <ToastMessages data-autotag="mini-cart-toast-message" />
          <Content
            hasOverlay={cart.loading}
            data-autotag="mini-cart-modal"
            className="bfx-minicart-container"
          >
            <ProgressLoader overlay />
            <NewPricingSkinnyBanner />
            <Header>
              <Title>
                <TitleMessage />
              </Title>
              <DrawerCloseButton onClick={this.handleClose} iconSize={12} />
            </Header>
            <Bounceback
              prevCartTotal={this.prevCartSubTotalMinusDiscount}
              shouldHideTerms
            />

            {this.renderPromoProgressBar()}

            {cart.itemCount > 0 &&
            !hasOnlyMembershipItem &&
            !hasNotLoadedCart ? (
              <>
                <Body>
                  {isInfluencer && this.renderInfluencerToastMessage()}
                  <ToastContext.Consumer>
                    {({ showMessage }) => (
                      <BasketItemList
                        pSource="mini_cart"
                        lineDiscounts={cart.cartLineDiscounts}
                        deleteBasketItem={({
                          isEchoCart,
                          productGroupKey,
                          productType,
                          shouldDeleteByProductId,
                        }) => {
                          this.deleteItem(
                            productGroupKey,
                            productType,
                            showMessage,
                            shouldDeleteByProductId,
                            isEchoCart
                          );
                        }}
                        hasNonPreorderItems={hasNonPreorderItems}
                        isBorderfreeCustomer={isBorderfreeCustomer}
                        isTopItem={!shouldShowProgressBar}
                        isVip={isVip}
                        isInfluencer={isInfluencer}
                        location={MembershipActionLocation.MINI_CART}
                        membershipItem={membershipItem}
                        onlyHasPreorderItems={onlyHasPreorderItems}
                        products={products}
                        removeMembership={() => {
                          this.removeMembership(showMessage);
                        }}
                        showPrice={false}
                        showQuantitySelector={false}
                        showQuantity={false}
                        showRemoveIcon
                        upsellAssetToTrack={upsellAssetToTrack}
                        updateItemQuantity={this.updateItemQuantity}
                        upsellProduct={upsellProduct}
                      />
                    )}
                  </ToastContext.Consumer>
                </Body>
                {showCartFooter && (
                  <Footer>
                    <AssetContainer name="mini_cart_shipping">
                      {asset => this.renderAssetContainer(asset.data)}
                    </AssetContainer>
                    {isBorderfreeCustomer ? (
                      <CheckoutButton
                        onClick={this.continueCheckout}
                        disabled={
                          hasOnlyMembershipItem ||
                          (hasOutOfStockItems && !allowOutOfStockCheckout) ||
                          borderfreeCustomerWithMembership
                        }
                        alternate={isVipCTAStyling}
                        role="button"
                      >
                        <FormattedMessage
                          id="global_checkout.checkout_cta"
                          defaultMessage="Checkout"
                        />
                      </CheckoutButton>
                    ) : (
                      <ToastContext.Consumer>
                        {({ showMessage }) => (
                          <CheckoutButton
                            onClick={this.signInBeforeCheckout(showMessage)}
                            disabled={
                              hasOnlyMembershipItem ||
                              (hasOutOfStockItems &&
                                !allowOutOfStockCheckout) ||
                              borderfreeCustomerWithMembership
                            }
                            role="button"
                            alternate={isVipCTAStyling}
                            data-autotag="mini-cart-checkout-btn"
                          >
                            <FormattedMessage
                              id="global_checkout.checkout_cta"
                              defaultMessage="Checkout"
                            />
                          </CheckoutButton>
                        )}
                      </ToastContext.Consumer>
                    )}
                  </Footer>
                )}
              </>
            ) : (
              emptyCartMessage
            )}
          </Content>
        </StyleContext>
      </Drawer>
    );
  }
}

function CartDrawerWithHooks(props) {
  const { isGwpAvailable, gwpMinSubtotal, shouldDisplayGwp, isGwpApplied } =
    useGiftWithPurchase();
  const {
    'free-shipping': freeShipping,
    'guest-checkout': isGuestCheckoutEnabled,
    'vip-free-express': freeVipExpressShipping,
  } = useLDFlags();
  const isWithinLeadThreshold = useLeadAgeWithInThreshold(
    freeShipping?.threshold,
    props.isOpen
  );

  const freeShippingEnabled =
    (freeShipping?.enabled && isWithinLeadThreshold) || freeVipExpressShipping;

  const autoBundle2Enabled = useAutoBundle2();

  const {
    initiateByoFlow,
    setByoGroupCode,
    increaseByoStep,
    trackAddedItems,
    isFlowInitiated,
  } = useByoContext();

  const isVipCTAStyling = useVipCTAStyling();

  return (
    <CartDrawer
      {...props}
      isAutoBundle2FeatureEnabled={autoBundle2Enabled}
      isGwpAvailable={isGwpApplied ? false : isGwpAvailable}
      gwpMinSubtotal={gwpMinSubtotal}
      shouldDisplayGwp={shouldDisplayGwp}
      initiateByoFlow={initiateByoFlow}
      setByoGroupCode={setByoGroupCode}
      increaseByoStep={increaseByoStep}
      trackAddedItems={trackAddedItems}
      isFlowInitiated={isFlowInitiated}
      isFreeShippingFlagEnabled={freeShippingEnabled}
      isVipCTAStyling={isVipCTAStyling}
      isGuestCheckoutEnabled={isGuestCheckoutEnabled}
    />
  );
}

export default withTheme(CartDrawerWithHooks);
