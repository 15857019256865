import { getPartialTrackingData } from '../utils';

const pdpViewEvents = ({ getContext, trackEvent }) => {
  const { TRACK_PDP_VIEW } = require('../../actions/products');
  const pdpView = trackEvent((action, prevState, nextState) => {
    const context = getContext();

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'availableTokenQuantity',
      'brand',
      'dmg_code',
      'feature',
      'loggedin_status',
      'session_id',
      'store_group_id',
      'user_status_initial',
      'tests_active',
      'membership_credits',
    ]);

    return {
      name: 'Product Viewed',
      properties: {
        ...additionalTrackingData,
        ...action.payload,
      },
    };
  });

  return { [TRACK_PDP_VIEW]: pdpView };
};

export default pdpViewEvents;
