import { getPartialTrackingData } from '../utils';

const trackProductListViewed = ({ trackEvent, getContext }) => {
  const { TRACK_PRODUCT_LIST_VIEWED } = require('../../actions/products');
  const productListViewed = trackEvent((action, _prevState, nextState) => {
    const {
      list_id: listId,
      list_category: listCategory,
      fpl_id: fplId,
      sort,
    } = action.payload;
    const context = getContext();

    const additionalTrackingData = getPartialTrackingData(nextState, context, [
      'automated_test',
      'availableTokenQuantity',
      'dmg_code',
      'loggedin_status',
      'membership_status',
      'session_id',
      'store_group_id',
      'tests_active',
      'user_id',
      'user_status_initial',
      'membership_credits',
    ]);

    return {
      name: 'Product List Viewed',
      properties: {
        ...additionalTrackingData,
        fpl_id: fplId || null,
        list_category: fplId || !listCategory ? null : listCategory,
        list_id: listId || null,
        sort: sort || null,
        ...action.payload,
      },
    };
  });

  return { [TRACK_PRODUCT_LIST_VIEWED]: productListViewed };
};

export default trackProductListViewed;
