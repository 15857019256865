import React, { Component } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled, { css, withTheme } from 'styled-components';

import { Heading } from '@techstyle/react-components';
import { FormattedMessage } from '@techstyle/react-intl';

import { MembershipActionLocation } from '../../constants/checkout';
import toastWrapper from '../../hoc/toastWrapper';
import { desktop } from '../../styles';
import { Paragraph } from '../../styles/paragraph';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import {
  isOutOfStock,
  isMembershipItem as checkIsMembershipItem,
} from '../../utils/selectors';
import { isOneSize } from '../../utils/sizes';
import { v1, v2 } from '../../utils/themeVersioning';
import { useAutoBundle2 } from '../../utils/useAutoBundle2';
import { Component as BasketItemDetails } from '../BasketItemDetails';
import { Component as BasketItemPrice } from '../BasketItemPrice';
import { Component as BasketItemQuantitySelector } from '../BasketItemQuantitySelector';
import { Component as BasketItemStock } from '../BasketItemStock';
import { Component as CartRemoveButton } from '../CartRemoveButton';
import { Component as MemberCreditsCartToggle } from '../MemberCreditsCartToggle';
import { Container as PreorderShipDate } from '../PreorderShipDate';
import { Component as ProductImage } from '../ProductImage';
import { Component as ProductLink } from '../ProductLink';
import PsourceProvider from '../PsourceProvider/PsourceProvider';
import {
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_GWP,
  useQuickViewContext,
} from '../QuickViewProvider';
import UpsellMessage from '../UpsellMessage/UpsellMessage';

const setId = config.get('public.productTypes.setId');
const byoSetId = config.get('public.productTypes.byoSetId');

const Wrapper = styled.article`
  padding: ${props => props.theme.spacing.small}px 0;
  line-height: 1.5;
  ${v1`border-bottom: 1px solid ${props => props.theme.colors.lavender250};`}
  ${v2`border-bottom: 1px solid ${props => props.theme.colors.gray200}`};
  ${props => props.isLastToRender && `border-bottom: none;`}
  ${props =>
    props.theme.context.cart &&
    `
    background: ${
      props.isMemberCreditApplied && props.theme.themeVersion === 'v1'
        ? props.theme.colors.lavender100
        : 'inherit'
    };
    padding: ${props.theme.spacing.small}px;
  `}

  ${props =>
    props.theme.context.summaryDrawer &&
    `
    padding: ${props.theme.spacing.small}px;
  `}

  ${props =>
    props.theme.context.checkout &&
    `padding: ${props.theme.spacing.small}px ${props.theme.spacing.medium}px;`}
  ${props =>
    props.theme.context.checkout &&
    !props.theme.context.summaryDrawer &&
    !props.theme.context.cart &&
    `
    &:first-child {
      border-top: none;
    }
  `}

  ${props =>
    props.theme.context.cartDrawer &&
    `
    &:first-child {
      border-top: none;
    }
  `}

  ${props =>
    props.theme.context.orders &&
    `
      padding: ${props.theme.spacing.small}px;
      ${desktop`
        padding: 0;
      `}
  `}

  ${props =>
    props.showVipInfo &&
    css`
      border: none;
    `};

  ${props =>
    props.shouldDisplayUpsellMessage &&
    css`
      padding-bottom: ${props => props.theme.spacing.tiny}px;
    `}
`;

const Row = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${props =>
    props.noMargin ? 0 : `${props.theme.spacing.tiny}px`};

  ${props =>
    props.theme.context.cart &&
    `
    background: ${
      props.isMemberCreditApplied ? props.theme.colors.lavender100 : 'inherit'
    };
  `}

  ${props =>
    props.theme.context.cartDrawer &&
    `
    padding: 0 ${props.theme.spacing.small}px;
    ${desktop`
      padding: 0;
    `}
  `}
`;

const ImageWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80px;
  flex-direction: column;
  margin-right: 12px;

  ${props =>
    (props.theme.context.checkout || props.theme.context.orders) &&
    desktop`
      flex-basis: 67px;
    `};

  ${props =>
    props.theme.context.cart &&
    desktop`
      flex-basis: 110px;
    `};

  ${props =>
    props.theme.context.vipItem &&
    css`
      flex-basis: 48px;
      ${desktop`flex-basis: 48px;`};
    `};
`;

const ImageButtonWrapper = styled.button`
  position: relative;

  :hover {
    cursor: pointer;
  }
`;

const EditIcon = styled(FaEdit).attrs(props => ({
  color: props.theme.colors.lavender500,
}))`
  position: absolute;
  top: 8px;
  right: 7px;
`;

const ImageNewRatioWrapper = styled(ImageWrapper)`
  flex-basis: 60px;

  ${props =>
    props.theme.context.cart &&
    desktop`
      flex-basis: 80px;
    `};
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 12px;
  min-width: 50px;

  ${props =>
    props.theme.context.cartDrawer &&
    `
    flex-direction: column;
    min-width: 10px;
  `}
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  ${v2`text-transform: uppercase;`}
`;

const ContentRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

const ErrorWrapper = styled.div`
  flex: 1 1 100%;
`;

const ErrorParagraph = styled(Paragraph).attrs({ size: 12 })`
  margin-top: ${props => props.theme.spacing.tiny}px;

  ${props =>
    props.theme.context.cart &&
    css`
      margin-top: 0;
    `}

  color: ${props => props.theme.colors.error};
`;

const NameWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin-right: auto;
  margin-bottom: 2px;
  ${v2`
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
  `}
`;

const GwpWrapper = styled(NameWrapper)`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const RemoveWrapper = styled.div`
  float: right;
  height: 24px;
  ${v2`
    height: 16px;
  `}
`;

const StyledProductImage = styled(ProductImage).attrs({
  width: 80,
  height: 80,
})`
  width: 100%;
  height: auto;
  vertical-align: top;
  background-color: ${props => props.theme.colors.white};
`;

const ProductName = styled(Heading).attrs({
  level: 4,
})`
  display: inline;
  font-size: inherit;
  font-weight: ${props => (props.theme.context.cartDrawer ? 'normal' : '500')};
  line-height: inherit;
  color: ${props => props.theme.colors.default};
  order: 0;
  ${v2`${props =>
    props.theme.subHeader.variants.subHeading4Uppercase.textStyles}`}
  ${desktop`
    font-weight: ${props =>
      props.theme.context.cartDrawer ? 'normal' : '600'};
  `}
`;

const ItemHeader = styled.div`
  ${v2`
      ${({ theme }) => theme.subHeader.variants.subHeading4Uppercase.textStyles}
    `}
`;

const Hidden = styled.span`
  display: none;
`;

const ExclusiveProductMessage = styled.div`
  width: 100%;
  padding-top: 16px;
  color: ${props => (props.isPromo ? 'inherit' : props.theme.colors.error)};
  font-size: 12px;
`;

const StyledPreorderShipDate = styled(PreorderShipDate)`
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  margin: ${props => props.theme.spacing.tiny}px 0 0;
  ${props =>
    props.theme.context.cartDrawer &&
    `padding: 0 ${props.theme.spacing.small}px;`}
`;

const PreorderMembershipMessage = styled.div`
  color: ${props => props.theme.colors.error};
  font-weight: 500;
  margin: 18px 0;
  ${props =>
    props.theme.context.cartDrawer &&
    `padding: 0 ${props.theme.spacing.small}px;`}
`;

const ShippingDelayMessage = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.error};
  margin-top: ${props => props.theme.spacing.tiny}px;
`;

const EditButton = styled.button`
  font-size: 12px;
  font-weight: 300;
  color: ${props => props.theme.colors.active};

  ${v2`
    ${({ theme }) => theme.links.variants.textLinkSecondary}
  `}
  :hover {
    cursor: pointer;
  }
`;

const BundleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 16px 16px;
`;

const BundleLeftCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const BundleHeaderPriceRow = styled.div`
  display: flex;
`;

const BundleSubTextWrapper = styled.div`
  color: ${props => props.theme.colors.gray500};
  font-family: Plain;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
`;

class BasketItem extends Component {
  static Wrapper = Wrapper;
  static RightColumn = RightColumn;

  static propTypes = {
    allowExclusiveCheckout: PropTypes.bool,
    addToWishlist: PropTypes.func,
    autoBundle2Enabled: PropTypes.bool,
    availableTokenQuantity: PropTypes.number,
    cartItems: PropTypes.array,
    className: PropTypes.string,
    deleteBasketItem: PropTypes.func,
    discounts: PropTypes.array,
    extraDetail: PropTypes.node,
    getNewDate: PropTypes.func,
    gwpProduct: PropTypes.object,
    hasMinimumQuantityTag: PropTypes.bool,
    hideDetails: PropTypes.bool,
    hidePreorderMessaging: PropTypes.bool,
    hideToastMessage: PropTypes.func,
    isDowngraded: PropTypes.bool,
    isExchange: PropTypes.bool,
    isNMP: PropTypes.bool,
    isVip: PropTypes.bool,
    location: PropTypes.string,
    membershipItem: PropTypes.object,
    minimumQuantity: PropTypes.number,
    noLink: PropTypes.bool,
    openQuickViewModal: PropTypes.func,
    product: PropTypes.object.isRequired,
    checkoutMaterialsEnabled: PropTypes.bool,
    renderDetails: PropTypes.func,
    renderError: PropTypes.func,
    showExclusiveProductMessage: PropTypes.bool,
    setDefaultProductWithSelections: PropTypes.func,
    shouldShowQuickView: PropTypes.bool,
    showFree: PropTypes.bool,
    showPrice: PropTypes.bool,
    showQuantity: PropTypes.bool,
    showQuantitySelector: PropTypes.bool,
    showRemoveIcon: PropTypes.bool,
    showStock: PropTypes.bool,
    showToastMessage: PropTypes.func,
    shouldDisplayUpsellMessage: PropTypes.bool,
    theme: PropTypes.object,
    thumbnail: PropTypes.string,
    tokenToCashEnabled: PropTypes.bool,
    trackAddToWishlistEvent: PropTypes.func,
    trackQuickViewModalOpen: PropTypes.func,
    trackRemoveGwp: PropTypes.func,
    updateItemQuantity: PropTypes.func,
    hasMembershipItem: PropTypes.bool,
    hasNonPreorderItems: PropTypes.bool,
    isBorderfreeCustomer: PropTypes.bool,
    isPreorderable: PropTypes.bool,
    showVipInfo: PropTypes.bool,
    isClearanceItem: PropTypes.bool,
    pSource: PropTypes.string,
  };

  static defaultProps = {
    allowExclusiveCheckout: false,
    hidePreorderMessaging: false,
    isExclusive: false,
    isVip: false,
    linkToProduct: true,
    noLink: false,
    shouldDisplayUpsellMessage: false,
    showExclusiveProductMessage: false,
    showFree: false,
    showPrice: true,
    showQuantity: true,
    showQuantitySelector: true,
    showRemoveIcon: true,
    showStock: true,
    showUpsellMessage: false,
  };

  changeQuantity = event => {
    const quantity = event.target.value;
    const { productId, groupKey, productTypeId } = this.props.product;
    const isSet = productTypeId === setId || productTypeId === byoSetId;
    const id = isSet ? groupKey : productId;
    this.props.updateItemQuantity(id, isSet, quantity);
  };

  deleteItem = async () => {
    const { gwpProduct, product, trackRemoveGwp } = this.props;
    const { cartLineId, lineId, productId, groupKey, productTypeId } = product;
    const isGwpProduct = product === gwpProduct;
    const {
      cartItems,
      deleteBasketItem,
      hasMinimumQuantityTag,
      isPreorderable,
      minimumQuantity,
    } = this.props;
    const isSet = productTypeId === setId || productTypeId === byoSetId;

    const itemQuantity = cartItems.filter(
      item => item.masterProductId === this.props.product.masterProductId
    ).length;
    const isBelowMinimumQuantity =
      itemQuantity <= minimumQuantity && hasMinimumQuantityTag;
    const shouldDeleteByProductId = isBelowMinimumQuantity;

    /**
     * Wishlist Set items do not have a `groupKey`.
     * To delete an item using the /cart api, use `lineId`
     * but to delete an item from the wishlist, use `productId`
     */
    const id =
      (isSet && groupKey) ||
      (isBelowMinimumQuantity && productId) ||
      lineId ||
      cartLineId ||
      productId;
    deleteBasketItem({
      productGroupKey: id,
      productType: isSet,
      isEchoCart: !!cartLineId,
      isPreorderable,
      minimumQuantity,
      productId,
      shouldDeleteByProductId,
    });

    if (isGwpProduct) {
      trackRemoveGwp(product.label);
    }
  };

  moveItemFromCartToWishlist = async event => {
    const { masterProductId, label } = this.props.product;
    await this.props.addToWishlist(masterProductId);
    this.deleteItem(event, 'moved_to_wishlist');
    this.props.trackAddToWishlistEvent({
      eventLabel: label,
      eventCategory: 'Shopping Bag',
    });
  };

  renderError() {
    const { renderError } = this.props;
    if (renderError && renderError(this.props)) {
      return (
        <ErrorWrapper>
          <ErrorParagraph>{renderError(this.props)}</ErrorParagraph>
        </ErrorWrapper>
      );
    }
  }

  handleShowQuickViewModal = ({ item, trigger }) => {
    const {
      gwpProduct,
      autoBundle2Enabled,
      openQuickViewModal,
      product,
      showToastMessage,
      hideToastMessage,
      setDefaultProductWithSelections,
      trackQuickViewModalOpen,
    } = this.props;
    const { bundleItems, productTypeId } = product;
    const isRegularSet = productTypeId === setId;
    const isByoSet = !!(productTypeId === byoSetId && bundleItems);
    const singleProduct = isRegularSet || isByoSet ? bundleItems[0] : product;
    const hasCustomText = !!(
      singleProduct.customText || singleProduct.customMessage
    );
    const isGwpProduct = item === gwpProduct;
    if ((isByoSet && !autoBundle2Enabled) || hasCustomText) {
      showToastMessage(
        <FormattedMessage
          id="global_checkout.quick_view_not_editable"
          defaultMessage="Sorry this item cannot be edited"
        />
      );
      return;
    }
    hideToastMessage();
    if (isByoSet && autoBundle2Enabled) {
      setDefaultProductWithSelections(
        {
          ...item,
          cartLineId: product.cartLineId,
          bundleLabel: product.instanceLabel,
        },
        isRegularSet,
        false
      );
    } else {
      setDefaultProductWithSelections(product, isRegularSet, false);
    }

    const cartInteraction = isGwpProduct
      ? UPDATE_CART_ITEM_GWP
      : UPDATE_CART_ITEM;
    openQuickViewModal({ source: 'shopping_bag', cartInteraction });
    trackQuickViewModalOpen({ label: trigger });
  };

  renderExclusiveMessage() {
    const { showExclusiveProductMessage, allowExclusiveCheckout } = this.props;
    if (showExclusiveProductMessage) {
      if (allowExclusiveCheckout) {
        return (
          <ExclusiveProductMessage isPromo>
            <FormattedMessage
              id="global_checkout.exclusive_product_promo_message"
              defaultMessage="48 hours only! Xtra Xclusive access!"
            />
          </ExclusiveProductMessage>
        );
      } else {
        return (
          <ExclusiveProductMessage>
            <FormattedMessage
              id="global_checkout.exclusive_product_message"
              defaultMessage="This is a Xtra Savage exclusive, to checkout with this product you must join Xtra Savage"
            />
          </ExclusiveProductMessage>
        );
      }
    }
  }

  renderOptionalLink = (product, component, noLink = false) => {
    return (
      <PsourceProvider.Consumer>
        {({ psource: psourceFromProvider }) => {
          const { pSource } = this.props;
          const psourceToUse = pSource || psourceFromProvider;
          const extraQueryParams = psourceToUse
            ? { psrc: psourceToUse }
            : undefined;

          if (noLink || this.props.noLink || !product.permalink) {
            return component;
          }
          return (
            <ProductLink product={product} extraQueryParams={extraQueryParams}>
              <a>{component}</a>
            </ProductLink>
          );
        }}
      </PsourceProvider.Consumer>
    );
  };

  renderRows({ isProductSet, shouldShowEditButton }) {
    const {
      autoBundle2Enabled,
      availableTokenQuantity,
      checkoutMaterialsEnabled,
      discounts,
      extraDetail,
      getNewDate,
      gwpProduct,
      hideDetails,
      isExchange,
      isPreorderable,
      isDowngraded,
      isVip,
      location,
      membershipItem,
      product,
      renderDetails,
      shouldShowQuickView,
      showFree,
      showPrice,
      showQuantity,
      showQuantitySelector,
      showRemoveIcon,
      showStock,
      thumbnail,
      isClearanceItem,
    } = this.props;
    // Product data is still stored under each line item currently, so grab it
    // from the first item. This should be further normalized in the future.
    const {
      bundleItems,
      byoProductId,
      label,
      masterLabel,
      instanceLabel,
      productTypeId,
      tagIds,
    } = product;
    // BYO Bundles changes to productTypeId 14 on order summaries for some reason so while
    // We wait for Fission to fix that bug, we are using Tag IDs from Console in the mean time
    // Created one for BYO for QA and Prod 10871 for QA and 9817 for Prod
    const isByoSet = !!(
      (productTypeId === byoSetId ||
        tagIds?.includes(9817) ||
        tagIds?.includes(10871)) &&
      bundleItems
    );
    const isOneSizeProduct = isOneSize(product);
    const hasSoldOutItemInSet = isProductSet
      ? isOutOfStock(product, getNewDate)
      : false;
    const rows = isProductSet && bundleItems.length ? bundleItems : [product];
    return rows.map((item, index) => {
      const isGwpProduct = item === gwpProduct;
      const isHeader = index === 0;
      const shouldShowRemoveIcon =
        (isHeader && byoProductId) || (!byoProductId && showRemoveIcon);
      const itemHeader = isHeader ? (
        <ItemHeader
          onClick={
            shouldShowQuickView
              ? () =>
                  this.handleShowQuickViewModal({
                    item,
                    trigger: 'product name',
                  })
              : null
          }
        >
          {isGwpProduct && (
            <GwpWrapper>
              <FormattedMessage
                id="global_checkout.away_from_gwp_message_p2"
                defaultMessage="free gift!"
              />
            </GwpWrapper>
          )}
          <NameWrapper>
            {this.renderOptionalLink(
              product,
              <ProductName
                data-autotag="mini-cart-product-name"
                className="bfx-product-name"
              >
                {(isOneSizeProduct ? instanceLabel : masterLabel) || label}
              </ProductName>,
              isGwpProduct || shouldShowQuickView
            )}
          </NameWrapper>
        </ItemHeader>
      ) : null;

      return (
        <Row
          noMargin={isHeader}
          key={`basketitem-${product.productId}-${index}`}
        >
          <ImageNewRatioWrapper>
            {shouldShowQuickView ? (
              <ImageButtonWrapper
                onClick={() =>
                  this.handleShowQuickViewModal({
                    item,
                    trigger: 'product image',
                  })
                }
              >
                {autoBundle2Enabled && isByoSet ? <EditIcon /> : null}
                <StyledProductImage
                  product={item}
                  angle="LAYDOWN"
                  size={8}
                  src={thumbnail}
                  className="bfx-product-image"
                />
              </ImageButtonWrapper>
            ) : (
              this.renderOptionalLink(
                product,
                <StyledProductImage
                  product={item}
                  angle="LAYDOWN"
                  size={8}
                  src={
                    thumbnail
                  } /* If provided, will override automatic CDN URL */
                  className="bfx-product-image"
                />,
                isGwpProduct
              )
            )}
          </ImageNewRatioWrapper>
          <ContentWrapper>
            {!autoBundle2Enabled || (autoBundle2Enabled && !isByoSet)
              ? itemHeader
              : null}
            <ContentRow>
              <BasketItemDetails
                autoBundleEnabled={autoBundle2Enabled}
                checkoutMaterialsEnabled={checkoutMaterialsEnabled}
                hideColor={isGwpProduct}
                hideDetails={hideDetails}
                isByoSet={isByoSet}
                isExchange={isExchange}
                product={item}
                renderDetails={renderDetails}
                discounts={discounts}
                hideMaterials={location === MembershipActionLocation.MINI_CART}
                isHeader={isHeader}
                isClearanceItem={isClearanceItem}
                shouldShowTokenIneligibleMessage={
                  location === MembershipActionLocation.SHOPPING_BAG &&
                  !product.tokenRedemptionQty &&
                  (isDowngraded || isVip) &&
                  availableTokenQuantity
                }
              />
            </ContentRow>
            {shouldShowEditButton ? (
              <EditButton
                onClick={() =>
                  this.handleShowQuickViewModal({
                    item,
                    trigger: 'edit',
                  })
                }
              >
                <FormattedMessage
                  id="global_checkout.edit_item"
                  defaultMessage="Edit"
                />
              </EditButton>
            ) : null}
            {isHeader && (!isProductSet || hasSoldOutItemInSet) && (
              <BasketItemStock
                product={product}
                showQuantity={showQuantity}
                showQuantitySelector={showQuantitySelector}
                showStock={showStock}
                isPreorderable={isPreorderable}
              />
            )}
            <BasketItemQuantitySelector
              isProductSet={isProductSet}
              onChange={this.changeQuantity}
              product={product}
              showQuantitySelector={showQuantitySelector}
            />
          </ContentWrapper>
          {!autoBundle2Enabled || (autoBundle2Enabled && !isByoSet) ? (
            <RightColumn>
              {shouldShowRemoveIcon ? (
                <RemoveWrapper>
                  <CartRemoveButton onClick={this.deleteItem} />
                </RemoveWrapper>
              ) : null}
              {showPrice && isHeader && !isGwpProduct && (
                <BasketItemPrice
                  discounts={discounts}
                  isClearanceItem={isClearanceItem}
                  isVip={isVip}
                  isDowngraded={isDowngraded}
                  membershipItem={membershipItem}
                  product={product}
                  showFree={showFree}
                  showRemoveIcon={showRemoveIcon}
                />
              )}
            </RightColumn>
          ) : null}
          {!isProductSet && isHeader ? extraDetail : null}
        </Row>
      );
    });
  }

  render() {
    const {
      autoBundle2Enabled,
      availableTokenQuantity,
      className,
      discounts,
      extraDetail,
      gwpProduct,
      hasMembershipItem,
      hasNonPreorderItems,
      hidePreorderMessaging,
      isClearanceItem,
      isPreorderable,
      isNMP,
      isVip,
      isBorderfreeCustomer,
      location,
      membershipItem,
      product,
      pSource,
      shouldDisplayUpsellMessage,
      shouldShowQuickView,
      showFree,
      showPrice,
      showRemoveIcon,
      showVipInfo,
      theme: { context },
      tokenToCashEnabled,
    } = this.props;
    // Product data is still stored under each line item currently, so grab it
    // from the first item. This should be further normalized in the future.
    const { bundleItems, isExtraExclusive, productTypeId } = product;
    // byo bundle only has product type of 21 before submitting the order
    // when order is completed, byo set will be converted to regular product set type of 14
    const isByoSet = !!(productTypeId === byoSetId && bundleItems);
    const isRegularSet = !!(productTypeId === setId && bundleItems);
    const isProductSet = isRegularSet || isByoSet;
    const isCartOrCheckout = !!(context.cart || context.checkout);
    const isGwpProduct = product === gwpProduct;
    const singleProduct = isProductSet ? bundleItems[0] : product;
    const isOneSizeProduct = isOneSize(product);
    const { label, masterLabel, instanceLabel, byoProductId } = product;
    const hasCustomText = !!(
      singleProduct.customText || singleProduct.customMessage
    );
    const shouldShowShippingDelayMessage = hasCustomText && isCartOrCheckout;
    const shouldShowRemoveIcon =
      byoProductId || (!byoProductId && showRemoveIcon);
    const shouldShowPreorderMembershipMessage =
      !isVip &&
      !isBorderfreeCustomer &&
      isPreorderable &&
      hasNonPreorderItems &&
      !hasMembershipItem;

    const showTokenToggle = tokenToCashEnabled
      ? location === MembershipActionLocation.SHOPPING_BAG &&
        product.tokenRedemptionQty > 0 &&
        availableTokenQuantity > 0 &&
        !isGwpProduct &&
        !isNMP
      : location === MembershipActionLocation.SHOPPING_BAG &&
        product.tokenRedemptionQty > 0 &&
        availableTokenQuantity > 0 &&
        !isGwpProduct;
    let classes;
    if (className) {
      classes = className;
    } else if (context.cartDrawer) {
      classes = 'bfx-minicart-product';
    } else {
      classes = 'bfx-product';
    }
    return (
      <MemberCreditsCartToggle.Provider>
        <Wrapper
          isLastToRender={this.props.isLastToRender}
          data-testid="BasketItemWrapper"
          data-autotag={isByoSet ? 'byoset_added_to_cart' : null}
          className={classes}
          isMemberCreditApplied={product.tokenQtyApplied > 0}
          showVipInfo={showVipInfo}
          shouldDisplayUpsellMessage={shouldDisplayUpsellMessage}
        >
          {isByoSet && autoBundle2Enabled ? (
            <BundleHeader>
              <BundleLeftCol>
                <ProductName
                  data-autotag="mini-cart-product-name"
                  className="bfx-product-name"
                >
                  {(isOneSizeProduct ? instanceLabel : masterLabel) || label}
                </ProductName>
                <BundleSubTextWrapper>
                  <FormattedMessage
                    id="global_checkout.cart_bundle_sub_text"
                    defaultMessage="Bundled for Max Savings"
                  />
                </BundleSubTextWrapper>
              </BundleLeftCol>
              <BundleHeaderPriceRow>
                {showPrice && (
                  <BasketItemPrice
                    discounts={discounts}
                    isClearanceItem={isClearanceItem}
                    isVip={isVip}
                    membershipItem={membershipItem}
                    product={product}
                    showFree={showFree}
                    showRemoveIcon={showRemoveIcon}
                  />
                )}
                {shouldShowRemoveIcon ? (
                  <RemoveWrapper>
                    <CartRemoveButton onClick={this.deleteItem} />
                  </RemoveWrapper>
                ) : null}
              </BundleHeaderPriceRow>
            </BundleHeader>
          ) : null}
          {this.renderRows({
            isProductSet,
            shouldShowEditButton:
              shouldShowQuickView && !isByoSet && !hasCustomText,
          })}
          {
            /* for Leads, we need to show them that membership is required for
             * ordering preorder items. There's an error state if they have
             * only preorder items but no instock items, so we don't want to show this
             * if they ALSO don't have instock items.
             */
            shouldShowPreorderMembershipMessage ? (
              <PreorderMembershipMessage>
                <FormattedMessage
                  id="global_checkout.preorder_requires_instock_item"
                  defaultMessage="Pre-Order is an Xtra Savage exclusive, to pre-order this item you
                must join Xtra Savage VIP Membership."
                />
              </PreorderMembershipMessage>
            ) : null
          }
          {hidePreorderMessaging ? null : (
            <StyledPreorderShipDate product={product} />
          )}
          {shouldShowShippingDelayMessage ? (
            <ShippingDelayMessage>
              <FormattedMessage
                id="global_checkout.customizable_products_late_shipping_message"
                defaultMessage="Please allow 4-6 weeks for personalization and shipping from the time order is placed. Non-refundable. Preordering styles from the SavageXYou Collection requires payment upfront"
              />
            </ShippingDelayMessage>
          ) : null}
          {showTokenToggle ? (
            <MemberCreditsCartToggle item={product} location={location} />
          ) : null}
          <Row noMargin>
            <ImageWrapper />
            <ContentWrapper>
              {isProductSet ? extraDetail : null}
              {context.cart && this.renderError()}
              <Hidden className="bfx-sku">{product.itemNumber}</Hidden>
              <Hidden className="bfx-product-customData-label">
                productId
              </Hidden>
              <Hidden className="bfx-product-customData-value">
                {product.productId}
              </Hidden>
            </ContentWrapper>
            {isExtraExclusive ? this.renderExclusiveMessage() : null}
            {!context.cart && this.renderError()}
          </Row>
          {shouldDisplayUpsellMessage ? (
            <UpsellMessage pSource={pSource} />
          ) : null}
        </Wrapper>
      </MemberCreditsCartToggle.Provider>
    );
  }
}

function BasketItemWithHooks(props) {
  const quickViewContext = useQuickViewContext();
  const {
    'checkout-materials': checkoutMaterials,
    'token-to-cash': tokenToCashEnabled,
    'edit-in-cart': quickViewFeature,
  } = useLDFlags();
  const isMembershipItem = useSelector(state =>
    checkIsMembershipItem(state, props)
  );

  const autoBundle2Enabled = useAutoBundle2();
  return (
    <BasketItem
      {...props}
      {...quickViewContext}
      checkoutMaterialsEnabled={checkoutMaterials}
      autoBundle2Enabled={autoBundle2Enabled}
      tokenToCashEnabled={tokenToCashEnabled}
      shouldShowQuickView={
        props.theme.context.cart && quickViewFeature && !isMembershipItem
      }
    />
  );
}

BasketItemWithHooks.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(toastWrapper(BasketItemWithHooks));
