import React from 'react';

import { useMembership } from '@techstyle/react-accounts';
import { Script } from '@techstyle/react-marketing';
import { useSession } from '@techstyle/redux-core';

export default function LuckyOrangeSnippet() {
  const { isVip, networkStatus } = useMembership();
  const { isFirstTimeVisitor, isLoggedIn } = useSession();

  const isLoaded = networkStatus.isUpToDate && isLoggedIn;
  const nonFTVSegment = isVip ? 'VIP' : 'Lead';
  const userSegment = isFirstTimeVisitor ? 'FTV' : nonFTVSegment;

  if (!isLoaded) {
    return null;
  }

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `
        (function() {          
          var customData = {
            'customerSegment' : '${userSegment}',
          }          
          window._loq = window._loq || []    
          window._loq.push(['custom', customData])
        })();        
      `,
      }}
    />
  );
}
