import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { getSession, useSession } from '@techstyle/redux-core';

import { Component as AutoLoginPrompt } from '../AutoLoginPrompt';
import { Component as Loading } from '../Loading';
import { Component as MainTemplate } from '../MainTemplate';
import ToastContext from '../ToastContext/ToastContext';
import ToastMessages from '../ToastMessages/ToastMessages';

// FIXME: toast message is displayed behind the modal.
const StyledToastMessages = styled(ToastMessages)`
  z-index: 2001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const MembershipGuard = ({ children, method }) => {
  const { networkStatus } = useMembership();
  const { isServerOnly } = useSession();
  const loading = networkStatus.isLoading;
  const hasMembershipResolved = !isServerOnly && networkStatus.isUpToDate;

  const { isLoggedInWithCredentials, isGmsLoggedIn } = useSelector(state =>
    getSession(state)
  );

  if (!hasMembershipResolved || loading) {
    return <Loading />;
  }

  return (
    <>
      {isLoggedInWithCredentials || isGmsLoggedIn ? (
        children
      ) : (
        <MainTemplate hideNewsletterModal>
          <ToastContext>
            <StyledToastMessages />
            <AutoLoginPrompt method={method} />
          </ToastContext>
        </MainTemplate>
      )}
    </>
  );
};

MembershipGuard.propTypes = {
  children: PropTypes.node,
  method: PropTypes.string,
};

export default MembershipGuard;
