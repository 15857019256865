import React from 'react';

import { Builder, builder } from '@builder.io/react';
import config from 'config';

import {
  dynamicRoutesExtension,
  assetRoutesExtension,
  routesExtension,
} from '@techstyle/next-routes';
import { errorLoggingExtension } from '@techstyle/next-server';
import createRegistry from '@techstyle/next-server/registry';
import { abTestExtension } from '@techstyle/react-abtest';
import {
  accountsExtension,
  autoLoginExtension,
  gmsLoginExtension,
} from '@techstyle/react-accounts';
import { adminExtension } from '@techstyle/react-admin';
import { assetsExtension } from '@techstyle/react-assets';
import { styledComponentsExtension } from '@techstyle/react-components';
import { featuresExtension } from '@techstyle/react-features';
import { apolloExtension } from '@techstyle/react-graphql';
import { intlExtension } from '@techstyle/react-intl';
import {
  marketingExtension,
  GoogleTagManagerSnippet,
  CuralateSnippet,
  OneTrustSnippet,
  SegmentSnippet,
  SegmentGTMVariables,
  Site24x7RUMSnippet,
  sentryFeatureTagMiddleware,
  OneTrustSetupWithSegment,
} from '@techstyle/react-marketing';
import { navExtension } from '@techstyle/react-navigation';
import { performanceExtension } from '@techstyle/react-performance';
import { productsExtension } from '@techstyle/react-products';
import { promosExtension } from '@techstyle/react-promos';
import { searchExtension } from '@techstyle/react-search';
import { reduxExtension, configureStore } from '@techstyle/redux-core';

import googleTagManagerEvents, {
  GoogleTagManagerVariables,
  AdditionalGoogleTagManagerVariables,
} from './googleTagManagerEvents';
import launchDarklyExtension from './launchDarklyExtension';
import routes from './routes';
import segmentEvents from './segmentEvents';
import getIsAnonymousServerSessionEnabled from './server/getIsAnonymousServerSessionEnabled';
import { Component as BorderfreeSnippet } from './src/components/BorderfreeSnippet';
import { ConstructorIoSnippet } from './src/components/ConstructorIoSnippet/ConstructorIoSnippet';
import { Component as QueueItSnippet } from './src/components/QueueItSnippet';
import rebrandTheme from './src/styles/newTheme';
import { getIgnoreBuilderProductListingRoutes } from './src/utils/getIgnoreBuilderProductListingRoutes';
import { getShouldUseRoutingByTld } from './src/utils/getShouldUseRoutingByTld';
import { getUseBuilderRouting } from './src/utils/getUseBuilderRouting';
import { isBuilderPreview } from './src/utils/isBuilderPreview';
import { assetContainerRewriteMap } from './src/utils/routing';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line node/no-unpublished-require
  const { default: setupLocatorUI } = require('@locator/runtime');
  setupLocatorUI();
}

builder.init(config.public.builderApi.key);
const registry = createRegistry();

registry.register(performanceExtension());
registry.register(errorLoggingExtension());
registry.register(
  reduxExtension(configureStore, null, {
    enableContentPreview: isBuilderPreview,
    getIsAnonymousServerSessionEnabled,
  })
);
registry.register(
  apolloExtension({
    globalEnableGetDataFromTree: false,
  })
);
registry.register(adminExtension());
registry.register(
  styledComponentsExtension(rebrandTheme, { enableGlobalFontFaces: false })
);
registry.register(launchDarklyExtension());
registry.register(autoLoginExtension());
registry.register(gmsLoginExtension());
registry.register(
  dynamicRoutesExtension({
    routeTypePageMap: {
      PRODUCT_LISTING: '/products-bmig',
    },
    builderRoutePageMap: {
      page: '/builder/page',
    },
    getUseBuilderRouting,
    getIgnoreBuilderProductListingRoutes,
    getShouldUseRoutingByTld,
  })
);
registry.register(assetRoutesExtension({ assetContainerRewriteMap }));
registry.register(routesExtension({ routes }));
registry.register(
  intlExtension({
    globalResourceBundles: [
      'global_checkout',
      'global_cta',
      'global_login',
      'metanav_items',
      'quiz_results',
      'search',
      'site_account',
      'site_account_profile',
      'site_byo',
      'site_emp_onboarding',
      'site_error',
      'site_footer',
      'site_login',
      'site_navigation',
      'site_product_grid',
      'site_product_option',
      'site_seo_description',
      'site_seo_title',
      'site_tracking',
      'the_savages',
      'cookie_bar',
    ],
  })
);
registry.register(abTestExtension({ shouldUseActivatedTests: true }));
registry.register(assetsExtension());
registry.register(productsExtension());
registry.register(accountsExtension());
registry.register(searchExtension());
registry.register(
  navExtension({
    globalPrefetchNavs: [
      'site_navigation',
      'updated_nav',
      'sport_navigation',
      'site_navigation_mobile',
      'featured_categories',
      'new_vip_offer_column',
      'vip_offers',
      'site_footer',
      'site_footer_secondary',
      'product_sidebar_navigation',
    ],
  })
);
registry.register(
  featuresExtension({
    globalPrefetchFeatures: [
      'captcha_key',
      'mobile_nav_updates',
      'desktop_nav_update',
    ],
  })
);
registry.register(promosExtension());
registry.register(
  marketingExtension({
    getRequestInfo(ctx) {
      const { tld } = ctx.req.context.domain;

      return {
        /** when agents in borderfree countries gms login a customer's account,
         * we want to bypass borderfree, so they can see what users see */
        // SXF-5175: disable borderfree
        borderfreeEnabled: false,
        sentryEnabled: config.get('public.sentry.enabled'),
        queueItEnabled: config.get('public.queueIt.enabled'),
        // since there is no tld for .ca or .com in the config, it won't render for those countries/regions
        oneTrustApiKey: config.get('public.oneTrust.apiKeys')[tld],
        site24x7RUMKey: config.get('public.site24x7.rum.appKey')[tld],
      };
    },
    head(requestInfo) {
      const { oneTrustApiKey } = requestInfo;
      return (
        <>
          {oneTrustApiKey ? <OneTrustSnippet apiKey={oneTrustApiKey} /> : null}
        </>
      );
    },
    beforeContent(requestInfo) {
      const { oneTrustApiKey } = requestInfo;
      const isPreviewOrEditing = Builder.isEditing || Builder.isPreviewing;
      return (
        <>
          {!isPreviewOrEditing && oneTrustApiKey && (
            <OneTrustSetupWithSegment />
          )}
          <GoogleTagManagerSnippet.Setup />
          <GoogleTagManagerVariables />
          <AdditionalGoogleTagManagerVariables />
          <SegmentGTMVariables />
          {!oneTrustApiKey ? <SegmentSnippet /> : null}
        </>
      );
    },
    afterContent(requestInfo) {
      return (
        <>
          <ConstructorIoSnippet />
        </>
      );
    },
    beforeNextScript(requestInfo) {
      const { borderfreeEnabled } = requestInfo;
      return (
        <>
          {borderfreeEnabled ? <BorderfreeSnippet /> : null}
          <CuralateSnippet skipServerRender={false} />
        </>
      );
    },
    afterNextScript(requestInfo) {
      const { queueItEnabled, site24x7RUMKey } = requestInfo;
      return (
        <>
          {queueItEnabled && <QueueItSnippet />}
          <Site24x7RUMSnippet appKey={site24x7RUMKey} />
        </>
      );
    },
    afterMount(requestInfo) {
      const { borderfreeEnabled } = requestInfo;
      // Defer import to here because it imports many other modules, some of
      // which call `config.get`.
      const {
        Component: BorderfreeEvents,
      } = require('./src/components/BorderfreeEvents');
      const BorderfreeEvent = BorderfreeEvents;

      return <>{borderfreeEnabled ? <BorderfreeEvent /> : null}</>;
    },
    googleTagManagerEvents,
    segmentEvents,
    reduxMiddlewares(context) {
      if (!context.isServer) {
        return [sentryFeatureTagMiddleware()];
      }
    },
  })
);

registry.register(() => {
  const savagexExtension = require('./savagexExtension').default;
  return savagexExtension();
});

export default registry;
