import React, { useContext, useMemo, useState } from 'react';

const Context = React.createContext();

export const useSetMouseOverHeader = () => {
  const { setIsMouseOverHeader } = useContext(Context);
  return setIsMouseOverHeader;
};

export default function GlobalHeaderStateProvider({ children }) {
  const [isMouseOverHeader, setIsMouseOverHeader] = useState(false);
  const [isNavTransparent, setIsNavTransparent] = useState(false);

  const value = useMemo(
    () => ({
      isMouseOverHeader,
      isNavTransparent,
      setIsMouseOverHeader,
      setIsNavTransparent,
    }),
    [
      isMouseOverHeader,
      isNavTransparent,
      setIsMouseOverHeader,
      setIsNavTransparent,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

GlobalHeaderStateProvider.Context = Context;
