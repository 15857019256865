import React, { useEffect, useState } from 'react';

import Router from 'next/router';

import ProgressLoader from '../ProgressLoader/ProgressLoader';

export default function PageLoadingIndicator() {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };

    const handleStop = () => {
      setLoading(false);
    };

    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleStop);
    Router.events.on('routeChangeError', handleStop);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleStop);
      Router.events.off('routeChangeError', handleStop);
    };
  }, []);

  return <ProgressLoader loading={isLoading} />;
}
