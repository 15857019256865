import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ImageAsset, useAsset } from '@techstyle/react-assets';

import { NavBarBGColors } from '../../constants/navBarBGColors';
import { desktop } from '../../styles';

const IconWrapper = styled.span``;

const StyledLogo = styled(ImageAsset)`
  height: 24px;

  img {
    vertical-align: middle;
    width: 23px;
    height: 24px;
  }

  ${desktop`
    height: ${props => (props.size === 'large' ? '48px' : '24px')};
    img {
      width: ${props => (props.size === 'large' ? '48px' : '23px')};
      height: ${props => (props.size === 'large' ? '48px' : '24px')};
      padding: ${props =>
        props.size === 'large' ? `${props.theme.spacing.tiny}px 0` : '0px'};
    }
  `}
`;

const BlackLogo = styled(StyledLogo)`
  display: ${props =>
    props.backgroundState === NavBarBGColors.WHITE ? 'inline-block' : 'none'};
`;

const WhiteLogo = styled(StyledLogo)`
  display: ${props =>
    props.backgroundState === NavBarBGColors.WHITE ? 'none' : 'inline-block'};
`;

XtraSavageIcon.propTypes = {
  backgroundState: PropTypes.string,
  size: PropTypes.string,
};

XtraSavageIcon.defaultProps = {
  backgroundState: NavBarBGColors.WHITE,
};

function XtraSavageIcon({ backgroundState, size }) {
  const assetContainerXtraSavageIcon = useAsset('global_image_xtrasavageicon', {
    multi: true,
  });
  const { data } = assetContainerXtraSavageIcon;
  const assetsXtraSavageIcon = data ? data.assets : [];

  const renderXLogo = asset => {
    if (
      asset.options.customVars &&
      asset.options.customVars.color === 'white'
    ) {
      return (
        <WhiteLogo
          asset={asset}
          backgroundState={backgroundState}
          size={size}
          key={`XtraSavageIcon-${asset.id}`}
          alt={asset.options.customVars.alt || 'XtraSavageIcon-WhiteLogo'}
        />
      );
    } else if (
      asset.options.customVars &&
      asset.options.customVars.color === 'black'
    ) {
      return (
        <BlackLogo
          asset={asset}
          backgroundState={backgroundState}
          size={size}
          key={`XtraSavageIcon-${asset.id}`}
          alt={asset.options.customVars.alt || 'XtraSavageIcon-BlackLogo'}
        />
      );
    }
    return null;
  };

  return (
    <IconWrapper>
      {assetsXtraSavageIcon.map(asset => {
        return renderXLogo(asset);
      })}
    </IconWrapper>
  );
}

export default XtraSavageIcon;
