const buildEvents = (events = [], methods) =>
  events.reduce((acc, func) => {
    acc = {
      ...acc,
      ...func(methods),
    };

    return acc;
  }, {});

export default buildEvents;
