import React, { useCallback, useMemo, useState } from 'react';

const Context = React.createContext();

export default function SearchProvider({ children }) {
  const [isActive, setActive] = useState(false);
  const [closeHamburger, setCloseHamburger] = useState(() => {});

  const toggleActive = useCallback((value = isActive => !isActive) => {
    return setActive(value);
  }, []);

  const value = useMemo(
    () => ({ closeHamburger, isActive, setCloseHamburger, toggleActive }),
    [closeHamburger, isActive, toggleActive, setCloseHamburger]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

SearchProvider.Context = Context;
