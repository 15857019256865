import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { Paragraph } from '../../styles/paragraph';
import { v2 } from '../../utils/themeVersioning';
import { Component as LowStockFlag } from '../LowStockFlag';

const ItemData = styled(Paragraph).attrs({ size: 12 })`
  font-weight: normal;
  ${v2`
    ${props => props.theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}
`;

const LowStockMsg = styled(ItemData)`
  color: ${({ theme }) => theme.colors.green500};
  margin-top: 4px;
`;

const OutOfStockMsg = styled(ItemData)`
  color: ${props => props.theme.colors.error};
`;

function BasketItemStock({
  isPreorderable,
  showQuantity = true,
  showQuantitySelector = true,
  showStock = true,
  product,
}) {
  const { availableQuantity, availableQuantityPreorder, quantity } = product;

  const unavailableCount = isPreorderable
    ? Math.max(0, quantity - availableQuantityPreorder)
    : Math.max(0, quantity - availableQuantity);

  const displayQuantity =
    showQuantity && !showQuantitySelector ? (
      <ItemData>
        <FormattedMessage id="global_checkout.qty" defaultMessage="Qty" />:{' '}
        {quantity}
      </ItemData>
    ) : null;

  if (showStock) {
    return (
      <LowStockFlag
        isMasterProduct
        quantity={
          isPreorderable ? availableQuantityPreorder : availableQuantity
        }
      >
        {({ quantity, label, status }) => {
          if (status === LowStockFlag.OUT_OF_STOCK) {
            return <OutOfStockMsg>{label}</OutOfStockMsg>;
          } else if (unavailableCount) {
            return (
              <OutOfStockMsg>
                <FormattedMessage
                  id="global_checkout.quantity_left"
                  defaultMessage="Only {quantity} left!"
                  values={{ quantity: quantity }}
                />
                <FormattedMessage
                  id="global_checkout.update_quantity_to_continue_checkout"
                  defaultMessage="To continue checkout, update the quantity to {quantity}."
                  values={{ quantity: quantity }}
                />
              </OutOfStockMsg>
            );
          } else if (status === LowStockFlag.CRITICAL_STOCK) {
            return <LowStockMsg>{label}</LowStockMsg>;
          }
          return displayQuantity;
        }}
      </LowStockFlag>
    );
  }
  return displayQuantity;
}

BasketItemStock.propTypes = {
  isPreorderable: PropTypes.bool,
  product: PropTypes.object.isRequired,
  showQuantity: PropTypes.bool,
  showQuantitySelector: PropTypes.bool,
  showStock: PropTypes.bool,
};

export default BasketItemStock;
