import config from 'config';

import { splitTest } from '@techstyle/react-abtest';
import { signUp as baseSignUp } from '@techstyle/react-accounts';

import { SignUpMethod } from '../../constants/auth';

export const TRACK_REGISTRATION_PROMPT_SIGN_UP =
  'TRACK_REGISTRATION_PROMPT_SIGN_UP';

export const SIGN_UP_METHOD_SUCCESS = 'SIGN_UP_METHOD_SUCCESS';

export const TRACK_SIGN_UP_MODAL = 'TRACK_SIGN_UP_MODAL';

export const TOGGLE_SIGN_IN_MODAL = 'TOGGLE_SIGN_IN_MODAL';

export const TRACK_REGISTRATION_FAILURE = 'TRACK_REGISTRATION_FAILURE';

export function toggleSignInModal(
  isOpen,
  initialScreen,
  location,
  productId,
  isProductSet
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: TOGGLE_SIGN_IN_MODAL,
        payload: isOpen,
        initialScreen: initialScreen || 'SIGN_IN',
        onSuccess: resolve,
        onCancel: reject,
        meta: { location, productId, isProductSet },
      });
    });
  };
}

export function trackSignUpMethod(eventAction) {
  return {
    type: SIGN_UP_METHOD_SUCCESS,
    eventAction,
  };
}

export function trackSignUpModal(location) {
  return {
    type: TRACK_SIGN_UP_MODAL,
    location,
  };
}

export function signUp({
  firstName = '',
  lastName = '',
  email,
  password,
  optOutStatus,
  quizDetails,
  profile,
  method = SignUpMethod.SIGN_UP,
  details = {},
}) {
  return async (dispatch, getState) => {
    const { domain } = getState();
    const marketingList = config.get('public.sailthru.marketingLists')[
      domain.tld
    ];
    const marketingLists = marketingList ? [marketingList] : [];
    const meta = { method };

    const membershipTypeId = 1;

    let signupMethod;
    switch (meta.method) {
      case 'CARTDRAWER':
        signupMethod = { signup_source: 'checkout' };
        break;
      case 'CART_CHECKOUT':
        signupMethod = { signup_source: 'cart' };
        break;
      case 'EXIT':
        signupMethod = { signup_source: 'exit' };
        break;
      case 'FAVORITES':
        signupMethod = { signup_source: 'favorites_nav' };
        break;
      case 'LIVE_SHOPPING':
        signupMethod = { signup_source: 'live_shopping' };
        break;
      case 'NAVBAR':
        signupMethod = { signup_source: 'login' };
        break;
      case 'NEWSLETTER_EMAIL_SIGNUP':
        signupMethod = { signup_source: 'email_signup' };
        break;
      case 'QUIZ':
        signupMethod = { signup_source: 'quiz' };
        break;
      case 'SIGN_IN':
        signupMethod = { signup_source: 'signin' };
        break;
      case 'SIGN_IN_PAGE':
        signupMethod = { signup_source: 'sign_in_page' };
        break;
      case 'SIZE_FILTERS':
        signupMethod = { signup_source: 'size_filters' };
        break;
      case 'SPEEDY':
        signupMethod = { signup_source: 'speedy' };
        break;
      case 'WAITLIST':
        signupMethod = { signup_source: 'waitlist' };
        break;
      case 'WISHLIST':
        signupMethod = { signup_source: 'wishlist' };
        break;
    }

    details = { ...details, ...signupMethod, ...profile };

    const payload = {
      firstName,
      lastName,
      email,
      password,
      optOutStatus,
      marketingLists,
      membershipTypeId,
      quizDetails,
      details,
    };

    const result = await dispatch(baseSignUp(payload, { method }));
    dispatch(splitTest('AAsessionregistrationcomplete'));

    let isFirstTimeSignUp = true;
    // We only want to track the first time a user signs up, if they are a returning passwordless
    // lead, we do not want to track them as a new sign up. The signup endpoint will return a flag
    // `isPasswordlessLead` if the user is a returning passwordless lead, this flag is not present
    // the first time going through guest checkout.
    if (result?.payload.customer.optionSignatures?.isPasswordlessLead) {
      isFirstTimeSignUp = false;
    }

    if (isFirstTimeSignUp) {
      dispatch(trackSignUpMethod(meta));
    }

    return result;
  };
}

export function trackRegistrationSignUp(eventAction) {
  return {
    type: TRACK_REGISTRATION_PROMPT_SIGN_UP,
    eventAction,
  };
}

export function trackRegistrationFailure(error, source = null) {
  return {
    type: TRACK_REGISTRATION_FAILURE,
    error,
    source,
  };
}
