import React, { Component } from 'react';

import config from 'config';
import Link from 'next/link';
import PropTypes from 'prop-types';

import formatProductUrl from '../../utils/formatProductUrl';

export default class ProductLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    product: PropTypes.object.isRequired,
    searchQuery: PropTypes.string,
    pSource: PropTypes.string,
    extraQueryParams: PropTypes.object,
  };

  static defaultProps = {
    searchQuery: undefined,
    shouldNotBeClickable: false,
  };

  render() {
    const {
      className,
      product,
      children,
      searchQuery,
      extraQueryParams,
      pSource,
      shouldNotBeClickable,
      ...rest
    } = this.props;

    const productId = product.masterProductId || product.master_product_id;
    const productTypeSetId = config.get('public.productTypes.setId');
    const productTypeId = product.productTypeId || product.product_type_id;
    const productType = productTypeId === productTypeSetId ? 'sets' : undefined;

    // We cannot generate a product url without a productId
    if (!productId || shouldNotBeClickable) {
      return React.cloneElement(children, { className });
    }

    const { as, href } = formatProductUrl({
      permalink: product.permalink,
      productId,
      productType,
      searchQuery,
      extraQueryParams,
      pSource,
    });

    const child =
      typeof children === 'function'
        ? children({ product, productId, productType, as, href })
        : children;

    return (
      <Link as={as} href={href} {...rest}>
        {child && className ? React.cloneElement(child, { className }) : child}
      </Link>
    );
  }
}
