import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logOut } from '@techstyle/react-accounts';

import { trackAccountSegmentEvents } from '../../actions/account';

import LogOutButton from './LogOutButton';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClick: logOut,
      trackAccountSegmentEvents,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(LogOutButton);
