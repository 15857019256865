import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { AssetContainer } from '@techstyle/react-assets';
import { useBreakpoint, useTheme } from '@techstyle/react-components';
import { Mobile, Desktop } from '@techstyle/react-components-legacy';
import { FormattedMessage } from '@techstyle/react-intl';
import { NavContainer } from '@techstyle/react-navigation';

import { NavigationizerFeatureFlags } from '../../constants/navigationizer';
import { mobile } from '../../styles';
import { Header } from '../../styles/header';
import { v1, v2 } from '../../utils/themeVersioning';
import { getNavTrackingUrl } from '../../utils/url';
import { Component as Badge } from '../Badge';
import { Component as NavAssetMobile } from '../NavAssetMobile';
import { Component as NavMenuSubCategoryLinkElement } from '../NavMenuSubCategoryLinkElement';

const StyledBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.active};
  background-color: ${({ theme }) => theme.colors.lavender250};
  font-size: 10px;
  min-height: 20px;
  line-height: 0;
  padding: 0 ${({ theme }) => theme.spacing.tiny}px;
  border-radius: 2px;
`;

const AssetWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 200px;
  margin-top: 36px;
  padding-right: 30px;
`;

const Wrapper = styled.div`
  ${mobile`
    margin-top: ${props => props.theme.spacing.moderate}px;
  `};
  ${props =>
    props.theme.context.hoverMenu &&
    css`
      display: flex;
    `};
`;

const StyledSection = styled.section`
  ${props =>
    props.theme.context.hoverMenu &&
    css`
      width: 150px;
      margin-right: 20px;
      margin-bottom: ${props => props.theme.spacing.moderate}px;
    `};

  &:first-of-type {
    ${props =>
      props.theme.context.hoverMenu &&
      css`
        width: 125px;
      `};
  }
`;

const GridStyledSection = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 0;
  break-inside: avoid;

  @media (min-width: ${({ theme }) => theme.breakpoints.xlarge + 1}px) {
    margin-bottom: 28px;
  }
`;

const Container = styled.div`
  display: grid;
  column-count: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.xlarge + 1}px) {
    grid-template:
      'Main Sub'
      'Main Sub'
      'Main Sub';

    ${GridStyledSection}:first-child {
      grid-area: Main;
      margin-right: 48px;
    }

    ${GridStyledSection}:only-of-type {
      grid-area: Sub;
      margin-right: 0px;
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
`;

List.Item = styled.li`
  margin-bottom: ${props => props.theme.spacing.small}px;
  ${props =>
    props.theme.context.hoverMenu &&
    css`
      margin-bottom: ${props => props.theme.spacing.tiny}px;
      padding-left: 0;
    `};

  ${({ colorName, theme }) => css`
    ${v1` & > a {
      color: ${theme.colors[colorName] || theme.colors.default};
    }`}
  `}
`;

const TitleLink = styled.a`
  color: inherit;

  &:active {
    color: ${props => props.theme.colors.promo};
  }
`;

const Title = styled(Header).attrs({
  level: 1,
  uppercase: true,
})`
  margin-bottom: ${props => props.theme.spacing.small}px;
  margin-top: ${props => props.theme.spacing.moderate}px;
  text-align: left;
  white-space: nowrap;
  padding: 0 ${props => props.theme.spacing.moderate}px;

  ${v1`
    font-size: 12px;
  letter-spacing: 1.6px;
  color: ${props => props.theme.colors.subdued};
  font-weight: normal;
    line-height: 1;
    `}

  ${v2`
    ${props => props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
    color: ${props => props.theme.colors.grey};
  `}

  ${props =>
    props.theme.context.hoverMenu &&
    css`
      margin-top: 0;
      padding: 0;

      ${v1`
        font-size: 14px;
      letter-spacing: normal;
      font-weight: bold;
        color: ${props =>
          props.theme.context.inverse
            ? props.theme.colors.white
            : props.theme.colors.default};
      `}

      ${v2`
        ${props =>
          props.theme.paragraph.variants.paragraph3Uppercase.textStyles}
      `}
    `};
`;

const OffersLabel = styled.span``;

const NewVipOffersLabel = styled.span`
  color: ${props => props.theme.colors.promo};
`;

const CustomColoredLabel = styled.span`
  color: ${({ colorName, theme }) =>
    theme.colors[colorName] || theme.colors.default};
`;

const getIsCMSAsset = navItem =>
  Boolean(navItem.itemKey && navItem.itemKey.startsWith('$'));

const SpecialFormattingWrapper = ({ children, item }) => {
  const { itemKey } = item;

  if (itemKey === '_mainNavSpecialOffers') {
    return <OffersLabel>{children}</OffersLabel>;
  }

  if (itemKey === '_mainNavNewVipOffers') {
    return <NewVipOffersLabel>{children}</NewVipOffersLabel>;
  }

  if (itemKey.includes('__color_')) {
    const colorName = itemKey.replace('__color_', '');
    return (
      <CustomColoredLabel colorName={colorName}>{children}</CustomColoredLabel>
    );
  }

  return children;
};

const SmartTitle = ({ children, item, shouldShowBadge = false }) => {
  const isNew = item[NavigationizerFeatureFlags.isNew];
  const isSale = item[NavigationizerFeatureFlags.isSale];

  if (item.url) {
    const itemUrl = getNavTrackingUrl(item);
    return (
      <div>
        <Title>
          <Link route={itemUrl} passHref>
            <TitleLink>{children}</TitleLink>
          </Link>
          {isNew && shouldShowBadge ? (
            <StyledBadge>
              <FormattedMessage
                id="site_navigation.new_badge"
                defaultMessage="NEW"
              />
            </StyledBadge>
          ) : null}
          {isSale && shouldShowBadge ? (
            <StyledBadge>
              <FormattedMessage
                id="site_navigation.sale_badge"
                defaultMessage="SALE"
              />
            </StyledBadge>
          ) : null}
        </Title>
      </div>
    );
  }

  return (
    <Title>
      {children}
      {isNew && shouldShowBadge ? (
        <StyledBadge>
          <FormattedMessage
            id="site_navigation.new_badge"
            defaultMessage="NEW"
          />
        </StyledBadge>
      ) : null}
      {isSale && shouldShowBadge ? (
        <StyledBadge>
          <FormattedMessage
            id="site_navigation.sale_badge"
            defaultMessage="SALE"
          />
        </StyledBadge>
      ) : null}
    </Title>
  );
};

const SmartNavContainer = ({ children, navItem }) => {
  const { itemKey: handle } = navItem;

  if (handle && handle.startsWith('#')) {
    return <NavContainer handle={handle.substring(1)}>{children}</NavContainer>;
  }

  return children({ data: navItem });
};

function NavMenuSubCategories({
  className,
  gaAction,
  intl,
  navItem,
  trackNavItemClick,
  onClickSubCategories = () => {},
  shouldShowBadge = false,
}) {
  const theme = useTheme();
  const { matches } = useBreakpoint(
    `(min-width: ${theme.breakpoints.small}px) and (max-width: ${
      theme.breakpoints.xlarge + 1
    }px)`
  );
  if (!navItem) {
    return null;
  }

  const navItems = navItem.navItems;

  if (!navItems) {
    return null;
  }

  const renderLink = (link, key) => {
    const sendGaEvent = () => {
      if (!link.gaLabel) {
        return;
      }
      trackNavItemClick(gaAction, link.gaLabel);
    };

    const handleClick = () => {
      sendGaEvent();

      onClickSubCategories();
    };

    if (!link.url) {
      // This likely indicates a bug in nav data, as all nav items in these
      // sections should have URLs (they can't be unlinked section headings, for
      // example). So render nothing.
      return null;
    }

    const navItemLink = getNavTrackingUrl(link);

    let colorName;
    if (link.itemKey.includes('__color_')) {
      colorName = link.itemKey.replace('__color_', '');
    }

    return (
      <List.Item key={key} onClick={handleClick} colorName={colorName}>
        <NavMenuSubCategoryLinkElement
          gaLabel={link.gaLabel}
          isNew={link[NavigationizerFeatureFlags.isNew]}
          isSale={link[NavigationizerFeatureFlags.isSale]}
          isResourceBundle={link.isResourceBundle}
          label={link.label}
          url={navItemLink}
        />
      </List.Item>
    );
  };

  const assetImages = [];
  const renderColumn = item => {
    if (getIsCMSAsset(item)) {
      assetImages.push(<NavAssetMobile itemKey={item.itemKey} />);
      return null;
    }

    if (item.column) {
      return item.navItems
        ? item.navItems.map(_item => renderColumn(_item))
        : null;
    }

    const renderedTitle = (
      <SmartTitle item={item} shouldShowBadge={shouldShowBadge}>
        {item.isResourceBundle ? (
          <SpecialFormattingWrapper item={item}>
            {intl.formatMessage({
              id: item.label,
              defaultMessage: item.label,
            })}
          </SpecialFormattingWrapper>
        ) : (
          item.label
        )}
      </SmartTitle>
    );

    return (
      <GridStyledSection key={item.id}>
        {navItem.label.trim() !== item.label.trim() && (
          <Mobile>{renderedTitle}</Mobile>
        )}
        <Desktop>{renderedTitle}</Desktop>

        <SmartNavContainer navItem={item}>
          {({ data }) => (
            <List>{((data && data.navItems) || []).map(renderLink)}</List>
          )}
        </SmartNavContainer>
      </GridStyledSection>
    );
  };

  return (
    // Although we aren't using this `AssetContainer` to render the asset
    // directly, we still use it because by mounting this component it will
    // cause the asset to be refetched when necessary (since only rendered
    // assets are refetched).
    <AssetContainer name="products_image_banner">
      {() => (
        <>
          <Wrapper className={className}>
            <Container>{navItems.map(renderColumn)}</Container>
          </Wrapper>
          {matches ? (
            <AssetWrapper>
              {assetImages.length ? assetImages : null}
            </AssetWrapper>
          ) : null}
        </>
      )}
    </AssetContainer>
  );
}

NavMenuSubCategories.propTypes = {
  className: PropTypes.string,
  gaAction: PropTypes.string,
  intl: PropTypes.object,
  navItem: PropTypes.object,
  trackNavItemClick: PropTypes.func,
  onClickSubCategories: PropTypes.func,
  shouldShowBadge: PropTypes.bool,
};

NavMenuSubCategories.StyledSection = StyledSection;
NavMenuSubCategories.Title = Title;

export default NavMenuSubCategories;
