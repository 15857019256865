import { loadProfile } from '@techstyle/react-accounts';

export const QUIZ_ADVANCE_PAGE = 'QUIZ_ADVANCE_PAGE';
export const QUIZ_REGISTRATION_COMPLETE = 'QUIZ_REGISTRATION_COMPLETE';
export const QUIZ_REGISTRATION_START = 'QUIZ_REGISTRATION_START';
export const QUIZ_SKIP_LINK = 'QUIZ_SKIP_LINK';
export const QUIZ_START = 'QUIZ_START';
export const LOAD_QUIZ_REQUEST = 'LOAD_QUIZ_REQUEST';
export const LOAD_QUIZ_SUCCESS = 'LOAD_QUIZ_SUCCESS';
export const LOAD_QUIZ_FAILURE = 'LOAD_QUIZ_FAILURE';
export const TRACK_IDENTIFY_QUIZ_TRAITS = 'TRACK_IDENTIFY_QUIZ_TRAITS';
export const TRACK_QUIZ_SCREEN = 'TRACK_QUIZ_SCREEN';

export const GET_QUIZ_DETAILS_REQUEST = 'GET_QUIZ_DETAILS_REQUEST';
export const GET_QUIZ_DETAILS_SUCCESS = 'GET_QUIZ_DETAILS_SUCCESS';
export const GET_QUIZ_DETAILS_FAILURE = 'GET_QUIZ_DETAILS_FAILURE';

export const TRACK_QUIZ_START_REQUEST = 'TRACK_QUIZ_START_REQUEST';
export const TRACK_QUIZ_START_SUCCESS = 'TRACK_QUIZ_START_SUCCESS';
export const TRACK_QUIZ_START_FAILURE = 'TRACK_QUIZ_START_FAILURE';

export function advanceQuizPage(pageNumber) {
  return dispatch =>
    dispatch({
      type: QUIZ_ADVANCE_PAGE,
      payload: pageNumber,
    });
}

export function trackRegistrationComplete() {
  return dispatch =>
    dispatch({
      type: QUIZ_REGISTRATION_COMPLETE,
    });
}

export function trackRegistrationStart() {
  return dispatch =>
    dispatch({
      type: QUIZ_REGISTRATION_START,
    });
}

export function skipQuiz(pageNumber) {
  return dispatch =>
    dispatch({
      type: QUIZ_SKIP_LINK,
      payload: pageNumber,
    });
}

export function startQuiz() {
  return dispatch => dispatch({ type: QUIZ_START });
}

export function loadQuiz(id) {
  return {
    bentoApi: {
      endpoint: `quiz/${id}`,
      method: 'GET',
      actions: [LOAD_QUIZ_REQUEST, LOAD_QUIZ_SUCCESS, LOAD_QUIZ_FAILURE],
    },
  };
}

// Can be removed once new prodcut quiz modal takes over
export function trackIdentifyQuizTraits({ quizDetails, profile }) {
  return async (dispatch, getState, context) => {
    await dispatch(loadProfile());
    const state = getState();
    const {
      customer: {
        profile: { 'membership-id': id },
      },
    } = state;

    const quizTraits = quizDetails.reduce((traitObj, answer) => {
      return { ...traitObj, [answer.name]: answer.value };
    }, {});

    const traits = {
      ...quizTraits,
      ...profile,
    };

    return dispatch({
      type: TRACK_IDENTIFY_QUIZ_TRAITS,
      payload: { traits, id },
    });
  };
}

export function trackQuizScreen(screenName, screenQuestion, currentStep) {
  return { type: TRACK_QUIZ_SCREEN, screenName, screenQuestion, currentStep };
}

export function trackQuizTraits(traits) {
  return {
    type: TRACK_IDENTIFY_QUIZ_TRAITS,
    payload: { id: null, traits },
  };
}

export function getQuizDetails(customerId) {
  return {
    bentoApi: {
      endpoint: `members/${customerId}/quizDetails`,
      method: 'GET',
      actions: [
        GET_QUIZ_DETAILS_REQUEST,
        GET_QUIZ_DETAILS_SUCCESS,
        GET_QUIZ_DETAILS_FAILURE,
      ],
    },
  };
}

export function trackQuizStart() {
  return {
    bentoApi: {
      endpoint: `sessions/quiz/started`,
      method: 'POST',
      actions: [
        TRACK_QUIZ_START_REQUEST,
        TRACK_QUIZ_START_SUCCESS,
        TRACK_QUIZ_START_FAILURE,
      ],
    },
  };
}
