import React, { useState, useMemo } from 'react';

import PropTypes from 'prop-types';

const ProgressLoaderContext = React.createContext({
  isLoading: false,
  setLoading: e => e,
});

export const useProgressLoader = () => React.useContext(ProgressLoaderContext);

export const ProgressLoaderConsumer = ProgressLoaderContext.Consumer;

export const ProgressLoaderProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const states = useMemo(
    () => ({
      isLoading,
      setLoading,
    }),
    [isLoading]
  );
  return (
    <ProgressLoaderContext.Provider value={states}>
      {children}
    </ProgressLoaderContext.Provider>
  );
};

ProgressLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProgressLoaderContext;
