import { handleActions } from 'redux-actions';

import { LOAD_QUIZ_SUCCESS } from '../actions/quiz';

const defaultState = {
  quiz: {},
};

/* FIXME: Organize quiz by id */
export default handleActions(
  {
    [LOAD_QUIZ_SUCCESS]: (state, action) => ({
      ...state,
      quiz: action.payload,
    }),
  },
  defaultState
);
