import { PromoType } from '@techstyle/react-promos';

export function getPromo(discounts, promoId = null, code = null) {
  if (!discounts || !Array.isArray(discounts)) {
    return null;
  }
  return discounts.find(
    discount =>
      discount.promoId === promoId ||
      discount.code === code ||
      discount.promoCode === code
  );
}

export const promoPriceHiddenPromoTypes = new Set([
  PromoType.BOGO,
  PromoType.BOGO_WITH_DISCOUNT,
  PromoType.BUY_X_GET_Y_OFF,
  PromoType.BUY_X_GET_EACH_Y,
]);

export function shouldShowPromoPrice(promo) {
  const { promoTypeId, promo_type_id: promoTypeIdOld } = promo || {};
  const promoType = promoTypeId || promoTypeIdOld;

  return !promoPriceHiddenPromoTypes.has(promoType);
}
