import { getPromoAssetFlags } from '@techstyle/react-components';

export function createMembershipPromoPriceAssetFilter(membershipPrice) {
  return (asset, index, assets) => (asset, index, assets) => {
    const flags = getPromoAssetFlags(asset);
    if (flags.has('alwaysShow')) {
      return true;
    }
    if (+membershipPrice === 39.95) {
      return flags.has('promo');
    } else {
      return !flags.has('promo');
    }
  };
}
