import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useCustomer } from '@techstyle/react-accounts';
import { FormattedMessage } from '@techstyle/react-intl';

import { isEmpVip as getIsEmpVip } from '../../utils/selectors';

const COLON_BREAK = ': ';

const NavLabel = styled.div`
  ${({ theme }) => theme.paragraph.variants.paragraph3Uppercase.textStyles}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 250px;
`;

const GuestLabel = styled.div`
  max-width: 250px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const VipNavLabelWrapper = styled.div`
  ${({ theme }) => theme.paragraph.variants.paragraph3Uppercase.textStyles}
  display: flex;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;

  > :first-child {
    flex-grow: 1;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  > :last-child {
    flex-grow: 0;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MemberCreditsNavLabelV2 = () => {
  const { firstName } = useCustomer();

  const isEmpVip = useSelector(getIsEmpVip);

  if (isEmpVip && firstName) {
    return (
      <VipNavLabelWrapper>
        <FormattedMessage
          id="site_navigation.savage_xtra_member"
          defaultMessage="Xtra VIP Member"
        />
        {COLON_BREAK}

        <span>{firstName}</span>
      </VipNavLabelWrapper>
    );
  }
  if (!firstName) {
    return (
      <GuestLabel>
        <FormattedMessage
          id="site_navigation.savage_guest"
          defaultMessage="Your Account"
        />
      </GuestLabel>
    );
  }
  return <NavLabel>{firstName}</NavLabel>;
};
