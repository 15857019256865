import { createSegmentEvents } from '@techstyle/react-marketing';

import trackEvents from './src/segment-events/event';
import identifyEvents from './src/segment-events/identify';
import pageViewEvents from './src/segment-events/page-view';
import resetEvents from './src/segment-events/reset';

// All the event types in segment  -> https://github.com/rangle/redux-beacon/blob/master/packages/segment/src/segment.ts

export default createSegmentEvents(methods => {
  return {
    ...trackEvents(methods),
    ...pageViewEvents(methods),
    ...identifyEvents(methods),
    ...resetEvents(methods),
  };
});
