export const GET_BYO_CONFIG_REQUEST = 'GET_BYO_CONFIG_REQUEST' as const;
export const GET_BYO_CONFIG_SUCCESS = 'GET_BYO_CONFIG_SUCCESS' as const;
export const GET_BYO_CONFIG_FAILURE = 'GET_BYO_CONFIG_FAILURE' as const;

export const TRACK_BUILD_A_SET_CLICK = 'TRACK_BUILD_A_SET_CLICK' as const;
export const TRACK_BYO_MODAL_OPTION_CLICK =
  'TRACK_BYO_MODAL_OPTION_CLICK' as const;
export const TRACK_COMPLETE_SET_CLICK = 'TRACK_COMPLETE_SET_CLICK' as const;
export const TRACK_SHOW_BYO_EXIT_MODAL = 'TRACK_SHOW_BYO_EXIT_MODAL' as const;
export const TRACK_EXIT_OPTION_CLICK = 'TRACK_EXIT_OPTION_CLICK' as const;
export const TRACK_BYO_INTERSTITIAL_OPEN =
  'TRACK_BYO_INTERSTITIAL_OPEN' as const;
export const TRACK_BYO_INTERSTITIAL_CLOSE =
  'TRACK_BYO_INTERSTITIAL_CLOSE' as const;

export function getByoSetsConfig() {
  return {
    bentoApi: {
      endpoint: `products/byo/config?filterInactiveConfigs=true`,
      method: 'GET',
      actions: [
        GET_BYO_CONFIG_REQUEST,
        GET_BYO_CONFIG_SUCCESS,
        GET_BYO_CONFIG_FAILURE,
      ],
    },
  };
}

export function trackBuildASetButtonClick(productInfo) {
  return {
    type: TRACK_BUILD_A_SET_CLICK,
    productInfo,
  };
}

export function trackByoModalOptionsClick(eventLabel) {
  return {
    type: TRACK_BYO_MODAL_OPTION_CLICK,
    eventLabel,
  };
}

export function trackCompleteByoSet(payload, byoProductId) {
  return {
    type: TRACK_COMPLETE_SET_CLICK,
    payload,
    byoProductId,
  };
}

export function trackShowByoExitModal(eventLabel) {
  return {
    type: TRACK_SHOW_BYO_EXIT_MODAL,
    eventLabel,
  };
}

export function trackExitOptionClick(eventLabel) {
  return {
    type: TRACK_EXIT_OPTION_CLICK,
    eventLabel,
  };
}

export function trackByoInterstitialOpen(payload) {
  return {
    type: TRACK_BYO_INTERSTITIAL_OPEN,
    payload,
  };
}

export function trackByoInterstitialClose(payload) {
  return {
    type: TRACK_BYO_INTERSTITIAL_CLOSE,
    payload,
  };
}
