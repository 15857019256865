import { setNoRedirect } from '../actions/borderfree';
import { updateDebugFlags } from '../actions/debug';

export default function bootstrapStore(
  dispatch,
  { isServer, hasPreloadedState, req }
) {
  if (isServer) {
    if (hasPreloadedState) {
      return;
    }

    if (!req.query) {
      return;
    }

    if (req.query.echo === '1') {
      dispatch(updateDebugFlags({ shouldForceEcho: true }));
    }

    if (req.query.echo === '0') {
      dispatch(updateDebugFlags({ shouldForceEcho: false }));
    }

    if (req.query.noredirect === '1') {
      dispatch(setNoRedirect(true));
    }

    if (req.query.test_order === '1') {
      dispatch(updateDebugFlags({ testOrder: true }));
    }

    if (req.query.show_visitor_status === 'true') {
      dispatch(updateDebugFlags({ showVisitorStatus: true }));
    }
  } else {
    // This will queue up a `membership` endpoint call on the client
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    // FIXME
    // const queryParams = new URLSearchParams(window.location.search);
    // const ip = queryParams.get('ip');
    //
    // let headers = {
    //   'x-forwarded-user-agent': navigator.userAgent,
    //   'x-forwarded-uri': window.location.pathname + window.location.search,
    //   'x-forwarded-referrer': document.referrer,
    // };
    // if (ip) {
    //   headers = { ...headers, 'true-client-ip': ip };
    // }
    // dispatch(loadSession({ headers }));
  }
}
