import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { useBreakpoint } from '@techstyle/react-components';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from '@techstyle/react-intl';
import { useDomain } from '@techstyle/redux-core';

import { trackProductSearchResult } from '../../actions/products';
import { BorderfreeStoreType } from '../../reducers/borderfree';
import { mobile } from '../../styles';
import { getIOSVersion } from '../../utils/getIOSVersion';
import { useLDFlags } from '../../utils/LD/useLDFlags';
import { isMembershipLoadedSelector } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';
import ProgressLoader from '../ProgressLoader/ProgressLoader';
import { SearchInput as SearchInputConstructor } from '../SearchInput/SearchInput';
import { SearchInputLegacy } from '../SearchInput/SearchInputLegacy';
import { Component as SearchInputField } from '../SearchInputField';
import SearchProvider from '../SearchProvider/SearchProvider';
import SearchToggle from '../SearchToggle/SearchToggle';

const messages = defineMessages({
  placeholder: {
    id: 'search.placeholder',
    defaultMessage: 'Search...',
  },
  newPlaceholder: {
    id: 'search.new_placeholder',
    defaultMessage: 'Search for categories, products, styles, or anything else',
  },
});

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  ${v1`
      padding-bottom: 4px;
    `}

  ${mobile`
    padding: 0 ${props => props.theme.spacing.small}px;
    ${v1`margin-top: 48px;`}
    ${v2`margin-top: 40px;`}
  `};

  ${ProgressLoader.Wrapper} {
    padding: 0 ${props => props.theme.spacing.small}px;
  }
`;

const HackyIOSInput = styled.input`
  position: absolute;
  left: -9999px;
`;

const HeaderContainer = styled.div<{
  focused: boolean;
  shouldShowPlaceHolder: boolean;
  inputLength: number;
}>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background-color: ${props => props.theme.colors.white};

  ${v1`
      box-shadow: 0 1px 4px 0 ${({ theme }) => theme.colors.lightShadow1};
  min-height: 38px;

  border-bottom: 1px solid
    ${props => (props.focused ? props.theme.focusColor : 'transparent')};
    `}
  ${v2`

      border-color: ${props =>
        props.focused ? props.theme.colors.gunmetal : 'transparent'};
      border-style: solid;
      border-width: 1px;

    `}

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    box-shadow: none;
    ${v1`
        border-radius: 2px;
    ${props =>
      props.shouldShowPlaceHolder
        ? `background-color: ${props.theme.colors.lavender100};`
        : `background-color: unset;`}
      `}
  }

  ${mobile`

    ${v1`
      box-shadow: none;
    background-color: ${props => props.theme.colors.lavender100};

    >input::placeholder {
      font-size: 12px;
      font-weight: 400;
    }
    `}

    ${v2`
      padding: 16px;
      ${props => (props.inputLength < 1 ? `flex-direction: row-reverse;` : '')}
    `}

  `}
`;

const LeftSide = styled.div<{ inputLength: number }>`
  display: flex;

  ${v2`
    margin-left: 16px;
    `}
  ${mobile`
      ${v2`
        ${props => (props.inputLength > 0 ? 'display: none;' : '')}
        margin-left: 0px;
      `}
    `}
`;
const RightSide = styled.div<{ isActive: boolean }>`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: row;
  opacity: ${props => (props.isActive ? 1 : 0)};
`;

const ClearButton = styled.button`
  ${v1`
        font-size: 14px;
  line-height: 2.14;
  color: ${props => props.theme.colors.active};

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    font-size: 12px;
  }
  &:focus {
    outline: 2px solid ${props => props.theme.focusColor};
  }
      `}

  ${v2`
      ${props => props.theme.paragraph.variants.paragraph2Uppercase.textStyles}
    `}

  ${mobile`
    padding-right: ${props => props.theme.spacing.tiny}px;
    ${v1`
      span {
      font-size: 12px;
    }
    `}

  `}
`;

const StyledSearchToggle = styled(SearchToggle)`
  cursor: pointer;
  ${v1`
    max-height: 60px;
    max-width: 60px;
    padding: 0 8px 0 12px;
    `}

  justify-content: center;
`;

const CloseIcon = styled.button`
  cursor: pointer;
  max-height: 60px;
  max-width: 60px;
  padding: 10px;
  line-height: 0;
  img {
    width: 20px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    img {
      width: 16px;
    }
  }

  ${mobile`
    position: fixed;

    top: ${props => props.theme.spacing.small}px;
    padding: 0;
    width: 18px;

    ${v1`
      right: ${props => props.theme.spacing.small}px;
    `}

    ${v2`
      left: ${props => props.theme.spacing.small}px;
    `}
  `};

  &:focus {
    outline: 2px solid ${props => props.theme.focusColor};
  }
`;

const SearchIcon = styled.img.attrs({
  src: '/static/images/search.svg',
  alt: 'Search icon',
})<{ size?: number }>`
  width: ${props => props.size || 12}px;
  transition: 0.5s width;
`;

type SearchFieldProps = {
  className?: string;
  /**
   * Boolean to make the search input focus when its mounted :(
   */
  focusOnMount?: boolean;
  isNewDesktopSearchNavEnabled?: boolean;
  /**
   * Whether or not the mobile search modal has fully opened (loading transition ended)
   */
  isSearchModalOpen?: boolean;
  /**
   * Whether or not the mobile search bar has finished expanding after focus
   */
  isSearchBarExpanded?: boolean;
  /*
   * Whether or not the searchField is being rendered in the MobileSearchPane component
   */
  mobilePane?: boolean;
  /**
   * Function that calls back if enter is pressed without having highlighted an item
   */
  onHighlightedItemEnter?: (selectedData: any) => void;
  /**
   * Function that calls back if enter is pressed on a highlighted item
   */
  onNonHighlightedEnter?: (selectedData: any) => void;
};

// TODO: remove this after reducers are migrated to TS
type TempStateType = {
  borderfree: BorderfreeStoreType;
};

export default function SearchField({
  className,
  focusOnMount = false,
  isNewDesktopSearchNavEnabled,
  isSearchModalOpen,
  isSearchBarExpanded,
  mobilePane,
  onHighlightedItemEnter,
  onNonHighlightedEnter,
}: SearchFieldProps) {
  const router = useRouter();
  const { query } = router;
  const { 'constructor-search': constructorSearchEnabled } = useLDFlags();
  const dispatch = useDispatch();
  const { isVip } = useMembership();
  const { tld } = useDomain();
  const { isMobile } = useBreakpoint(null);
  const intl = useIntl();
  const iosInputRef = useRef(null);

  const { isActive, toggleActive } = useContext(SearchProvider.Context);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(
    (query.q as string) || ''
  );

  const isMembershipLoaded = useSelector(isMembershipLoadedSelector);
  const { isBorderfreeCustomer } = useSelector(
    (state: TempStateType) => state.borderfree
  );
  // need to have on ref or a better way to focus functionally
  const inputRef = useRef(null);

  const focusInput = useCallback(() => {
    if (iosInputRef.current) {
      iosInputRef.current.setAttribute('autofocus', 'autofocus');
      inputRef.current.focus();
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback(value => {
    setSearchInputValue(value);
  }, []);

  const handleClose = useCallback(() => {
    toggleActive(false);
  }, [toggleActive]);

  const handleFocus = useCallback(() => {
    toggleActive(true);
    setInputFocused(true);
  }, [toggleActive]);

  const handleClear = useCallback(() => {
    focusInput();
    setSearchInputValue('');
    dispatch(
      trackProductSearchResult({
        eventAction: 'Select',
        eventKey: 'clear',
      })
    );
  }, [focusInput, dispatch]);

  const handleBlur = useCallback(() => {
    if (mobilePane) {
      return;
    }

    if (inputRef.current) {
      setInputFocused(false);
      toggleActive(false);
    }
  }, [mobilePane, toggleActive]);

  const handleNonHighlightedEnter = useCallback(
    selectedData => {
      if (onNonHighlightedEnter) {
        onNonHighlightedEnter(selectedData);
      } else {
        Sentry.captureMessage(
          'No onNonHighlightedEnter function provided to handle enter keydown with no selection.',
          'warning'
        );
      }
    },
    [onNonHighlightedEnter]
  );

  const handleHighlightedItemEnter = useCallback(
    selectedData => {
      if (onHighlightedItemEnter) {
        onHighlightedItemEnter(selectedData);
      } else {
        Sentry.captureMessage(
          'No onHighlightedItemEnter function provided to handle enter keydown or click with item selection.',
          'warning'
        );
      }
    },
    [onHighlightedItemEnter]
  );

  const handleClearButtonFocus = useCallback(
    () => toggleActive(true),
    [toggleActive]
  );

  const preventDefault = useCallback(e => e.preventDefault(), []);

  useEffect(() => {
    if (
      (mobilePane && isSearchModalOpen && isActive) ||
      (!mobilePane && isActive)
    ) {
      focusInput();
    }
  }, [focusInput, isActive, isSearchModalOpen, mobilePane]);

  const handleTouchEnd = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isMembershipLoaded) {
    return null;
  }

  const shouldShowPlaceHolder =
    isActive || !isNewDesktopSearchNavEnabled || isBorderfreeCustomer;
  const placeHolderToUse =
    isNewDesktopSearchNavEnabled && !isBorderfreeCustomer
      ? messages.newPlaceholder
      : messages.placeholder;
  const isIOS = getIOSVersion();

  const SearchInput = constructorSearchEnabled
    ? SearchInputConstructor
    : SearchInputLegacy;

  return (
    <>
      {mobilePane && focusOnMount && isIOS && (
        // this is needed to force IOS keyboard to show on focus
        // eslint-disable-next-line jsx-a11y/no-autofocus
        <HackyIOSInput type="text" ref={iosInputRef} autoFocus />
      )}
      <SearchInput
        focusOnMount={focusOnMount}
        innerRef={inputRef}
        isSearchActive={isActive}
        isVip={isVip}
        inputFocused={inputFocused}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onHighlightedItemEnter={handleHighlightedItemEnter}
        onNonHighlightedEnter={handleNonHighlightedEnter}
        onTouchEnd={handleTouchEnd}
        placeholder={
          shouldShowPlaceHolder ? intl.formatMessage(placeHolderToUse) : null
        }
        shouldShowPlaceHolder={shouldShowPlaceHolder}
        tld={tld}
        value={searchInputValue}
      >
        {({ loading }) => (
          <SearchContainer className={className}>
            <HeaderContainer
              shouldShowPlaceHolder={shouldShowPlaceHolder}
              focused={inputFocused}
              inputLength={searchInputValue.length}
            >
              <LeftSide inputLength={searchInputValue.length}>
                <StyledSearchToggle
                  extended={isSearchBarExpanded}
                  searchIcon={
                    <SearchIcon size={isActive || isMobile ? 12 : 23} />
                  }
                />
              </LeftSide>
              <SearchInputField />
              {(isSearchBarExpanded || mobilePane) && (
                <RightSide isActive={isActive}>
                  {/*
            The onBlur event on SearchInput fires first during click and
                  normally prevents the onClick event of ClearButton firing.
                  The events attached below to ClearButton fix that while
                  allowing keyboards to still access it.
                  */}
                  {searchInputValue.length > 0 && (
                    <ClearButton
                      onClick={handleClear}
                      onFocus={handleClearButtonFocus}
                      onBlur={handleClose}
                      onMouseDown={preventDefault}
                      onTouchStart={preventDefault}
                    >
                      <FormattedMessage
                        id="search.clear_button"
                        defaultMessage="Clear"
                      />
                    </ClearButton>
                  )}
                  <CloseIcon onClick={handleClose}>
                    <img
                      src="/static/images/close-searchbar.svg"
                      alt="Close icon"
                    />
                  </CloseIcon>
                </RightSide>
              )}
            </HeaderContainer>
            {loading && (
              <ProgressLoader
                fixed={false}
                loading={loading}
                position="bottom"
              />
            )}
          </SearchContainer>
        )}
      </SearchInput>
    </>
  );
}
