import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { useMembership } from '@techstyle/react-accounts';
import { useBreakpoint } from '@techstyle/react-components';
import { useFeature } from '@techstyle/react-features';
import { FormattedMessage } from '@techstyle/react-intl';
import { updateSessionDetail, useSession } from '@techstyle/redux-core';

import { toggleSignInModal } from '../../actions/auth';
import { FORGOT_PASSWORD } from '../../constants/signIn';
import { useCheckIfPasswordlessLogin } from '../../utils/useCheckIfPasswordlessLogin';
import { Container as LogOutButton } from '../LogOutButton';

const LogoutIcon = styled(FaArrowRight)`
  font-size: 14px;
  margin-left: ${({ theme }) => theme.spacing.tiny}px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 94px;
  padding-bottom: 28px;
  background: ${({ theme }) => theme.colors.black800};
`;

const StyledLogOutButton = styled(LogOutButton)`
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
`;

List.Item = styled.li`
  display: flex;
  align-items: center;
  margin: 28px 0 0;
  padding: 0 24px;
  ${props =>
    props.theme.context.hoverMenu &&
    css`
      margin-bottom: ${props => props.theme.spacing.tiny}px;
      padding-left: 0;
    `};
`;

const HowXtraVipWorksLink = () => {
  return (
    <List.Item>
      <Link route="/xtrasavage" passHref>
        <StyledLink
          data-autotag="xtra-savage-main-menu"
          data-qa-automation="homeXtraSavageLink"
          bottomMargin
        >
          <FormattedMessage
            defaultMessage="How Membership Works"
            id="site_navigation.savage_xtra_membership_works"
          />
        </StyledLink>
      </Link>
    </List.Item>
  );
};

const MemberInfoCard = ({ closeMenu, onLogin }) => {
  const { isVip } = useMembership();
  const { isLoggedIn, isVisitor } = useSession();
  const dispatch = useDispatch();
  const mobileNavUpdatesFeature = useFeature('mobile_nav_updates');
  const isMobileNavUpdatesFeatureEnabled =
    !!mobileNavUpdatesFeature?.data?.value;
  const { isMobile } = useBreakpoint();
  const { isPasswordlessLogin } = useCheckIfPasswordlessLogin();

  const handleClickFavoritesNav = useCallback(() => {
    if (isVisitor) {
      dispatch(
        updateSessionDetail({ name: 'signup_favorites_nav', value: '' })
      );
    }
  }, [dispatch, isVisitor]);

  const handlePasswordlessAccountClick = useCallback(() => {
    if (isPasswordlessLogin) {
      dispatch(toggleSignInModal(true, FORGOT_PASSWORD));
      closeMenu();
    }
  }, [closeMenu, dispatch, isPasswordlessLogin]);

  return (
    <Wrapper>
      <List>
        {!isLoggedIn ? (
          <>
            <HowXtraVipWorksLink />
            <List.Item>
              <StyledLink
                onClick={onLogin}
                data-qa-automation="homePageSigninLink"
              >
                <FormattedMessage
                  defaultMessage="Sign In"
                  id="site_navigation.sign_in"
                />
              </StyledLink>
            </List.Item>
          </>
        ) : (
          <>
            <List.Item>
              {isPasswordlessLogin ? (
                <StyledLink
                  data-qa-automation="passwordlessAccountLink"
                  onClick={handlePasswordlessAccountClick}
                >
                  <StyledLink
                    data-autotag="xtra-savage-main-menu"
                    data-qa-automation="homeXtraSavageLink"
                    bottomMargin
                  >
                    <FormattedMessage
                      defaultMessage="View Account"
                      id="site_navigation.view_account"
                    />
                  </StyledLink>
                </StyledLink>
              ) : (
                <Link
                  route={`/account/${isVip ? 'membership' : 'profile'}`}
                  passHref
                >
                  <StyledLink
                    data-autotag="xtra-savage-main-menu"
                    data-qa-automation="homeXtraSavageLink"
                    bottomMargin
                  >
                    <FormattedMessage
                      defaultMessage="View Account"
                      id="site_navigation.view_account"
                    />
                  </StyledLink>
                </Link>
              )}
            </List.Item>
            {isMobileNavUpdatesFeatureEnabled && isMobile ? (
              <List.Item>
                <Link route="/favorites" passHref>
                  <StyledLink
                    data-autotag="xtra-savage-main-menu"
                    data-qa-automation="homeXtraSavageLink"
                    onClick={handleClickFavoritesNav}
                  >
                    <FormattedMessage
                      defaultMessage="Favorites"
                      id="site_navigation.favorites"
                    />
                  </StyledLink>
                </Link>
              </List.Item>
            ) : null}
            <HowXtraVipWorksLink />
            <List.Item>
              <StyledLogOutButton />
              <LogoutIcon />
            </List.Item>
          </>
        )}
      </List>
    </Wrapper>
  );
};

MemberInfoCard.propTypes = {
  closeMenu: PropTypes.func,
  onLogin: PropTypes.func,
};

export default withTheme(MemberInfoCard);
