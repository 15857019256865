import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  handleNonHighlightedEnter,
  handleHighlightedItemEnter,
} from '../../utils/search';
import SearchField from '../SearchField/SearchField';
import SearchProvider from '../SearchProvider/SearchProvider';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex.navItems + 1};
  background-color: ${props => props.theme.colors.white};
  /*
    only show this search between 768px - s1023px
   */
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.large - 1}px) {
    display: none;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.xlarge}px) {
    display: none;
  }
`;

const NavSearch = ({ focusOnMount }) => {
  const { isActive, toggleActive } = useContext(SearchProvider.Context);

  const handleNonHighlightedSelection = useCallback(
    selectedItem => {
      toggleActive(false);
      handleNonHighlightedEnter(selectedItem);
    },
    [toggleActive]
  );
  const handleHighlightedItemSelection = useCallback(
    selectedItem => {
      toggleActive(false);
      handleHighlightedItemEnter(selectedItem);
    },
    [toggleActive]
  );

  return isActive ? (
    <Wrapper>
      <SearchField
        focusOnMount={focusOnMount}
        onNonHighlightedEnter={handleNonHighlightedSelection}
        onHighlightedItemEnter={handleHighlightedItemSelection}
      />
    </Wrapper>
  ) : null;
};

NavSearch.propTypes = {
  /**
   * Boolean for if this component should focus when mounted
   */
  focusOnMount: PropTypes.bool,
  /**
   * Boolean for if search is active
   */
  isSearchActive: PropTypes.bool,
};

export default NavSearch;
