export const LoginErrors = {
  LOGIN_FAILED: 'LOGIN_FAILED',
  SERVER_ERROR: 'SERVER_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  GENERAL_ERROR: 'GENERAL_ERROR',
  INVALID_RECAPTCHA: 'INVALID_RECAPTCHA',
  RECAPTCHA_REQUIRED: 'RECAPTCHA_REQUIRED',
  ZIPCODE_REQUIRED: 'ZIPCODE_REQUIRED',
  ZIPCODE_INVALID: 'ZIPCODE_INVALID',
  PASSWORDLESS_LOGIN_ATTEMPT: 'PASSWORDLESS_LOGIN_ATTEMPT',
  PASSWORDLESS_SIGN_UP_ATTEMPT: 'PASSWORDLESS_SIGN_UP_ATTEMPT',
};

// Screen states.
export const SIGN_IN = 'SIGN_IN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGN_UP = 'SIGN_UP';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const GUEST_SIGN_UP = 'GUEST_SIGN_UP';
export const GUEST_SIGN_IN = 'GUEST_SIGN_IN';
export const CHECKOUT_SIGN_UP = 'CHECKOUT_SIGN_UP';
export const CHECKOUT_SIGN_IN = 'CHECKOUT_SIGN_IN';

// Errors.
export const ACCOUNT_EXISTS = 'ACCOUNT_EXISTS';
export const ACCOUNT_EXISTS_GUEST = 'ACCOUNT_EXISTS_GUEST';
export const AUTH_FAILED = 'AUTH_FAILED';
export const EMAIL_REQUIRED = 'EMAIL_REQUIRED';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const REFERRER_REQUIRED = 'REFERRER_REQUIRED';
export const PASSWORD_INVALID = 'PASSWORD_INVALID';
export const PASSWORD_REQUIRED = 'PASSWORD_REQUIRED';
export const PASSWORD_VERIFY_MATCH = 'PASSWORD_VERIFY_MATCH';
export const PASSWORD_VERIFY_REQUIRED = 'PASSWORD_VERIFY_REQUIRED';
export const TERMS_CONDITIONS_REQUIRED = 'TERMS_CONDITIONS_REQUIRED';
