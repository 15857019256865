import React, { useContext, useEffect } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { useFeature } from '@techstyle/react-features';

import { loadCartItemCount } from '../../actions/checkout';
import { Component as BorderfreeGuard } from '../BorderfreeGuard';
import { Component as BurgerNav } from '../BurgerNav';
import { Component as FavoritesNav } from '../FavoritesNav';
import { Component as MemberCreditsNav } from '../MemberCreditsNav';
import { Component as NavLogo } from '../NavLogo';
import { Component as NewPricingSkinnyBanner } from '../NewPricingSkinnyBanner';
import SearchProvider from '../SearchProvider/SearchProvider';
import SearchToggle from '../SearchToggle/SearchToggle';
import ShoppingBagNav from '../ShoppingBagNav/ShoppingBagNav';

const empMembershipTypeId = config.get('public.membershipTypeIds.emp');

const Wrapper = styled.nav`
  flex: 1;
  z-index: ${props => props.theme.zIndex.navItems};
  border-top: 1px solid ${props => props.theme.colors.gray150};
  border-bottom: 1px solid ${props => props.theme.colors.gray150};
`;

const List = styled.ul`
  margin: 0px 6px;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 40px;
  line-height: 40px;
  ${props => props.hideOnMobile && `display: none;`}
`;

const SearchWrapper = styled.div`
  height: 100%;
  transition: all 0.5s;
`;

const StyledSearchToggle = styled(SearchToggle)`
  width: 44px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export function NavToolBarMobile({
  backgroundState,
  className,
  showAccountModal,
  hideNavigation = false,
  shouldDisplayFullLogo,
  title,
  allowNewPricingSkinnyBanner,
}) {
  const dispatch = useDispatch();
  const { isActive: isSearchActive } = useContext(SearchProvider.Context);
  const mobileNavUpdatesFeature = useFeature('mobile_nav_updates');
  const isMobileNavUpdatesFeatureEnabled =
    !!mobileNavUpdatesFeature?.data?.value;

  useEffect(() => {
    if (loadCartItemCount) {
      dispatch(loadCartItemCount({ ifNotFetched: true }));
    }
  }, [dispatch]);

  const { membershipTypeId, isVip, isDowngraded } = useMembership();
  const shouldShowMemberCreditsNav =
    membershipTypeId === empMembershipTypeId && isVip && !isDowngraded;
  return (
    <Wrapper data-qa-automation="homePageHamNav" className={className}>
      <List>
        {!hideNavigation ? (
          <Item>
            <BurgerNav showAccountModal={showAccountModal} />
          </Item>
        ) : null}
        {!isMobileNavUpdatesFeatureEnabled ? (
          <>
            <BorderfreeGuard>
              <Item>
                <FavoritesNav size={24} shouldShowCountBadge />
              </Item>
            </BorderfreeGuard>
            <Item>
              <SearchWrapper extended={isSearchActive}>
                <StyledSearchToggle />
              </SearchWrapper>
            </Item>
          </>
        ) : (
          <NavLogo
            shouldDisplayFullLogo={shouldDisplayFullLogo}
            backgroundState={backgroundState}
            title={title}
          />
        )}
        {shouldShowMemberCreditsNav && !isMobileNavUpdatesFeatureEnabled ? (
          <Item>
            <MemberCreditsNav />
          </Item>
        ) : null}
        {!hideNavigation ? (
          <Item data-autotag="my-cart">
            <ShoppingBagNav />
          </Item>
        ) : null}
      </List>
      {allowNewPricingSkinnyBanner ? <NewPricingSkinnyBanner /> : null}
    </Wrapper>
  );
}

NavToolBarMobile.propTypes = {
  backgroundState: PropTypes.string,
  className: PropTypes.string,
  hideNavigation: PropTypes.bool,
  showAccountModal: PropTypes.func,
  shouldDisplayFullLogo: PropTypes.bool,
  title: PropTypes.string,
  allowNewPricingSkinnyBanner: PropTypes.bool,
};

export default NavToolBarMobile;
