import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useCustomerDetail, useMembership } from '@techstyle/react-accounts';
import { useAsset } from '@techstyle/react-assets';

import { getBirthdayDetails } from './selectors';

function useBirthdayPromo() {
  const { isVip } = useMembership();
  const { isBirthMonth } = useSelector(getBirthdayDetails);
  const isDisabled = !isBirthMonth || !isVip;

  const birthdayPromoDetail = useCustomerDetail(
    isDisabled ? null : 'birthday_promo_used'
  );
  const isBirthdayPromoDetailLoaded =
    birthdayPromoDetail &&
    birthdayPromoDetail.networkStatus.isUpToDate &&
    !birthdayPromoDetail.networkStatus.isLoading;
  const hasUsedBirthdayPromo = !!(
    birthdayPromoDetail && birthdayPromoDetail.value
  );

  const birthdayPromoCodeAsset = useAsset(
    isDisabled ? null : 'birthday_promo_codes'
  );
  const customVars =
    (birthdayPromoCodeAsset &&
      birthdayPromoCodeAsset.data &&
      birthdayPromoCodeAsset.data.assets &&
      birthdayPromoCodeAsset.data.assets[0].options.customVars) ||
    {};
  const promoCode = customVars.promoCode;
  const discountPercent = customVars.discountPercent;
  const isBirthdayAssetLoaded =
    birthdayPromoCodeAsset &&
    birthdayPromoCodeAsset.networkStatus.isUpToDate &&
    !birthdayPromoCodeAsset.networkStatus.isLoading &&
    !!promoCode &&
    !!discountPercent;

  const isBirthdayPromoReady =
    isDisabled || (isBirthdayPromoDetailLoaded && isBirthdayAssetLoaded);

  return useMemo(
    () => ({
      discountPercent,
      hasUsedBirthdayPromo,
      isBirthdayPromoReady,
      promoCode,
    }),
    [discountPercent, hasUsedBirthdayPromo, isBirthdayPromoReady, promoCode]
  );
}

export default useBirthdayPromo;
