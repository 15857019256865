import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

export default function TrackPageRenders({ pageRendered, children }) {
  const router = useRouter();

  const trackPageRender = useCallback(() => {
    const { route, pathname, query, asPath } = router;
    pageRendered({ route, pathname, query, asPath });
  }, [pageRendered, router]);

  useEffect(() => {
    trackPageRender();
  }, [trackPageRender]);

  return children;
}
