import { connect } from 'react-redux';

import { isPreorderable, getPreorderShipDate } from '../../utils/selectors';

import PreorderShipDate from './PreorderShipDate';

export function mapStateToProps(state, ownProps) {
  return {
    isPreorderable: isPreorderable(state, ownProps),
    preorderShipDate: getPreorderShipDate(state, ownProps),
  };
}
export default connect(mapStateToProps)(PreorderShipDate);
