const prepModelData = model => {
  if (model) {
    const {
      modelId,
      description,
      isDefaultModel = false,
      isMemberModel = false,
      jsonFormat,
      modelSize: _modelSize,
    } = model;

    const doesDescriptionExist =
      description &&
      typeof description === 'string' &&
      !description.includes('|') &&
      description.match(/.*- \d'\d{1,2}'' size .*/);

    let modelHeight, modelName, modelSize;
    if (doesDescriptionExist) {
      modelHeight = description.match(/[0-9]'[0-9]{1,2}''/)?.[0];
      modelName = description.match(/.*(?= -)/)?.[0];
    }

    if (Array.isArray(jsonFormat)) {
      modelHeight = jsonFormat[0].height;
      modelName = jsonFormat[0].name;
      modelSize = jsonFormat[0].size;
    } else if (
      jsonFormat &&
      Object.prototype.hasOwnProperty.call(jsonFormat, 'name')
    ) {
      modelHeight = jsonFormat.height;
      modelName = jsonFormat.name;
      modelSize = jsonFormat.size;
    }

    if (modelSize !== _modelSize) {
      //* Sometimes modelSize from the jsonFormat is not always the correct size
      //* so we fallback to the model object `modelSize` Field.
      modelSize = _modelSize;
    }

    return {
      model_height: modelHeight || null,
      model_name: modelName || null,
      is_default_model: isDefaultModel,
      is_member_model: isMemberModel,
      model_id: modelId,
      model_size: modelSize,
    };
  }

  return {};
};

export default prepModelData;
