export const TRACK_QUICK_VIEW_MODAL_OPEN = 'TRACK_QUICK_VIEW_MODAL_OPEN';
export const TRACK_QUICK_VIEW_UPDATE_CART = 'TRACK_QUICK_VIEW_UPDATE_CART';
export const TRACK_QUICK_VIEW_ADD_TO_CART = 'TRACK_QUICK_VIEW_ADD_TO_CART';
export const TRACK_VIEW_CLICK_FULL_DETAILS = 'TRACK_VIEW_CLICK_FULL_DETAILS';
export const TRACK_QUICK_VIEW_CHANGE_PRODUCT_ATTRIBUTE =
  'TRACK_QUICK_VIEW_CHANGE_PRODUCT_ATTRIBUTE';

export const trackQuickViewModalOpen = (payload = {}) => ({
  type: TRACK_QUICK_VIEW_MODAL_OPEN,
  payload,
});

export const trackQuickViewUpdateCart = (payload = {}) => ({
  type: TRACK_QUICK_VIEW_UPDATE_CART,
  payload,
});

export const trackQuickViewAddToCart = (payload = '') => ({
  type: TRACK_QUICK_VIEW_ADD_TO_CART,
  payload,
});

export const trackQuickViewClickFullDetails = (payload = {}) => ({
  type: TRACK_VIEW_CLICK_FULL_DETAILS,
  payload,
});

export const trackQuickViewChangeProductAttribute = (payload = {}) => ({
  type: TRACK_QUICK_VIEW_CHANGE_PRODUCT_ATTRIBUTE,
  payload,
});
