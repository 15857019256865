import { connect } from 'react-redux';

import { getMembership } from '@techstyle/react-accounts';

import { getGwpProduct } from '../../utils/selectors';

import BasketItemList from './BasketItemList';

const mapStateToProps = (state, ownProps) => {
  return {
    gwpProduct: getGwpProduct(state),
    order: ownProps.order || state.checkout,
    membershipPrice: getMembership(state).price,
  };
};

export default connect(mapStateToProps)(BasketItemList);
