import React from 'react';

import Link from 'next/link';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TextLink, useTheme } from '@techstyle/react-components';
import { useIntl } from '@techstyle/react-intl';
import { useSession } from '@techstyle/redux-core';

import { trackNavItemClick } from '../../actions/navigation';
import { mobile } from '../../styles';
import { useNavFilter } from '../../utils/navigation';
import { getNavTrackingUrl } from '../../utils/url';
import { FooterSocialIcons, NavContainers } from '../Footer/Footer';
import { Container as LogOutButton } from '../LogOutButton';
import NewThemeIcon from '../NewThemeIcon/NewThemeIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const StyledLogOutButton = styled(LogOutButton)`
  ${props => props.theme.links.variants.navigationPrimary}
`;

const StyledSocialsContainer = styled(FooterSocialIcons)`
  ${mobile`
    background-color: ${({ theme }) => theme.colors.marble};
    padding: 20px;
    justify-content: space-between;
    min-width: unset;
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;

// The `desktopOnly` prop should not appear in the DOM
// FIXME: Use prop filtering featured of styled-components v5 when upgrading.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilteredTextLink = React.forwardRef(({ desktopOnly, ...props }, ref) => (
  <TextLink ref={ref} {...props} />
));

FilteredTextLink.displayName = 'FilteredTextLink';

const BottomLink = styled(FilteredTextLink)`
  flex: 0 0 auto;
  position: relative;
  padding: 0px 0px 3px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-out;
  ${props => props.theme.links.variants.navigationPrimary}

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.colors.white};
    white-space: normal;
  }

  &:hover {
    &::before {
      width: 100%;
      transition: all 0.2s ease-out;
    }
  }

  ${mobile`
      ${props => (props.desktopOnly ? 'display: none;' : '')}
      margin-bottom: 18px;

  `};
`;

const LogoutIcon = styled(NewThemeIcon).attrs({ type: 'arrowright' })`
  margin-left: ${({ theme }) => theme.spacing.tiny}px;
  color: ${({ theme }) => theme.colors.black};
`;

const RebrandHamburgerNavFooter = () => {
  const { isLoggedIn } = useSession();
  const navFilter = useNavFilter({ isMobile: false });
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const isNewTheme = theme.themeVersion === 'v2';

  const handleSendGaEvent = (gaAction, navItem) => () => {
    if (!navItem.gaLabel) {
      return;
    }
    dispatch(trackNavItemClick(gaAction, navItem.gaLabel));
  };

  const openOneTrustModal = event => {
    if (event) {
      event.preventDefault();
    }

    if (
      typeof window !== 'undefined' &&
      window.OneTrust &&
      window.OneTrust.ToggleInfoDisplay
    ) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  const renderLink = (navItem, navSettings) => {
    const sendGaEvent = handleSendGaEvent(navSettings.gaAction, navItem);

    if (navItem.itemKey === '_footerOpenNewsletter') {
      return <> </>;
    }

    if (navItem.itemKey === '_footerOpenCookieSettings') {
      return (
        <BottomLink key={navItem.id} onClick={openOneTrustModal}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </BottomLink>
      );
    }

    const navItemLink = getNavTrackingUrl(navItem);

    return (
      <Link key={navItem.id} href={navItemLink} passHref>
        <BottomLink onClick={sendGaEvent}>
          {navItem.isResourceBundle
            ? intl.formatMessage({
                id: navItem.label,
                defaultMessage: navItem.label,
              })
            : navItem.label}
        </BottomLink>
      </Link>
    );
  };

  return (
    <Wrapper>
      <StyledSocialsContainer className={null} isNewTheme={isNewTheme} />

      <NavContainers isMobile={false} navFilter={navFilter}>
        {({ primaryNav }) => (
          <LinksWrapper>
            {primaryNav.navItems.map(navItem =>
              renderLink(navItem, primaryNav.navSettings)
            )}
            {isLoggedIn ? (
              <div>
                <StyledLogOutButton
                  onClick={null}
                  trackAccountSegmentEvents={null}
                  className={null}
                />
                <LogoutIcon />
              </div>
            ) : null}
          </LinksWrapper>
        )}
      </NavContainers>
    </Wrapper>
  );
};

export default RebrandHamburgerNavFooter;
