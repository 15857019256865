import { connect } from 'react-redux';

import { getDateNowFunction } from '@techstyle/redux-core';

import AssetCountdown from './AssetCountdown';

function mapStateToProps(state) {
  return {
    getNow: getDateNowFunction(state),
  };
}

export default connect(mapStateToProps)(AssetCountdown);
