import { connect } from 'react-redux';

import { hasMembershipInCart as hasMembershipInCartSelector } from '../../utils/selectors';

import PromotionProgressBar from './PromotionProgressBar';

export function mapStateToProps(state) {
  return {
    isCartLoaded: (() => {
      const hasMembershipInCart = hasMembershipInCartSelector(state);
      if (hasMembershipInCart) {
        return state.checkout.itemCount === state.checkout.items.length - 1;
      }

      return state.checkout.itemCount === state.checkout.items.length;
    })(),
  };
}

export default connect(mapStateToProps)(PromotionProgressBar);
