import React, { useMemo } from 'react';

import config from 'config';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  getMembership,
  logInSuccess,
  logOutSuccess,
  loadMembershipSuccess,
  loadMembershipFailure,
  useMembership,
} from '@techstyle/react-accounts';
import {
  createGoogleTagManagerEvents,
  GoogleTagManagerSnippet,
  SessionVariables,
  CustomerSizeVariables,
  CustomerVariables,
  MembershipVariables,
  ABTestVariables,
} from '@techstyle/react-marketing';
import {
  getNewDateFunction,
  getSessionVisitorId,
  loadSessionSuccess,
  parseDate,
} from '@techstyle/redux-core';

import {
  TRACK_PAUSE_MEMBERSHIP,
  TRACK_ONLINE_CANCEL_FAQ,
  TRACK_ONLINE_CANCEL_PHONE_CHAT,
  TRACK_ONLINE_CANCEL_SKIP,
  TRACK_ONLINE_CANCELLATION_REASON,
  TRACK_ONLINE_CANCEL_TAKE_ME_TO_SKIP,
  TRACK_ONLINE_CANCEL_SKIP_REMINDER_CONFIRMATION,
  TRACK_ONLINE_CANCEL_SIGN_UP_SKIP_REMINDER,
} from './src/actions/account/onlineCancel';
import {
  TRACK_ADD_TO_WISHLIST_REQUEST,
  TRACK_REMOVE_FROM_WISHLIST_REQUEST,
} from './src/actions/account/wishlist';
import { TRACK_AMBASSADOR_SHOP } from './src/actions/ambassadors';
import {
  TRACK_BUILD_A_SET_CLICK,
  TRACK_BYO_MODAL_OPTION_CLICK,
  TRACK_COMPLETE_SET_CLICK,
  TRACK_SHOW_BYO_EXIT_MODAL,
  TRACK_EXIT_OPTION_CLICK,
} from './src/actions/byo';
import {
  TRACK_SIZE_PROMPT_ACTION,
  TRACK_SIZE_UPDATE_SUCCESS,
} from './src/actions/customer/profile';
import { TRACK_EMAIL_OPT_IN_INTERACTIONS } from './src/actions/email';
import {
  TRACK_EMP_INFO_DRAWER_OPEN,
  TRACK_EMP_INFO_DRAWER_CTAS,
  TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_OPEN,
  TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_MORE,
  TRACK_MONTHLY_MEMBERSHIP_REQUEST,
  TRACK_MEMBERSHIP_SKIP_ACTIONS_REQUEST,
  TRACK_MEMBERSHIP_LEARN_MORE,
  TRACK_MEMBERSHIP_CANCELLATION_CONFIRMATION,
  TRACK_MEMBERSHIP_INACTIVE_SKIP,
  TRACK_MEMBERSHIP_ACTIVE_SKIP,
  TRACK_MEMBERSHIP_ACTIVE_SKIP_ACTIONS,
  TRACK_EMP_NEW_TOKEN_MODAL_CTA_CLICK,
} from './src/actions/membership';
import { TRACK_NAV_ITEM_CLICK } from './src/actions/navigation';
import {
  ERROR_PAGE_RENDERED,
  PAGE_RENDERED,
  TRACK_FEATURED_COLLECTION_TILES,
  TRACK_HOMEPAGE_CAROUSEL,
  TRACK_FIT_GUARANTEE,
} from './src/actions/pagesRendered';
import {
  QUIZ_ADVANCE_PAGE,
  QUIZ_REGISTRATION_START,
  QUIZ_SKIP_LINK,
  QUIZ_START,
} from './src/actions/quiz';
import { SignUpMethod } from './src/constants/auth';
import {
  MembershipActionLocation,
  MembershipEventAction,
} from './src/constants/checkout';
import { getSXFMembershipStatus } from './src/segment-events/utils';
import getProductSku from './src/utils/getProductSku';
import { useEmailPreferencesWithVars } from './src/utils/useEmailPreferencesWithVars';

const searchEventLabelValue = {
  clear: 'Clear',
  braSizes: 'Size',
  braletteSizes: 'Size',
  lingerieSizes: 'Size',
  undieSizes: 'Size',
  collectionIds: 'Collection',
  fabricTagIds: 'Fabric',
  colorTagIds: 'Color',
  categoryTagIds: 'Category',
  relevance: 'Relevance',
  newest: 'New Arrivals',
  bestsellers: 'Best Sellers',
  priceh2l: 'High to Low',
  pricel2h: 'Low to High',
};

const lastImpressions = [];
/*
returns an array of items for the the ecommerce object:
  'products': [{ // List of productFieldObjects.
      'name': 'Triblend Android T-Shirt', // Name or ID is required.
      'id': '12345',
      'price': '15.25',
      'brand': 'Google',
      'category': 'Apparel',
      'variant': 'Gray',
      'quantity': 1,
      'coupon': '' // Optional fields may be omitted or set to empty string.
    },
    {
      'name': 'Donut Friday Scented T-Shirt',
      'id': '67890',
      'price': '33.75',
      'brand': 'Google',
      'category': 'Apparel',
      'variant': 'Black',
      'quantity': 1
    }
  ]
*/

const getAllCartObj = items => {
  const cartItems = items.map(item => {
    const skuParts = item.itemNumber ? item.itemNumber.split('-') : [];
    // Membership Item does not have an itemNumber, so fallback to masterProductId
    return {
      dimension1: item.productId,
      id: item.masterProductId,
      price: item.discount
        ? parseFloat((item.purchaseUnitPrice - item.discount.amount).toFixed(2))
        : item.purchaseUnitPrice,
      quantity: item.quantity,
      base_sku: skuParts[0],
      product_sku: `${skuParts[0]}-${skuParts[1]}`,
      full_sku: item.itemNumber || item.masterProductId,
      variant: item.variant,
    };
  });
  return cartItems;
};

/*
returns the action field object to the ecommerce object:
  'actionField': {
    'id': 'T12345', // Transaction ID. Required for purchases and refunds.
    'affiliation': 'Online Store',
    'revenue': '35.43', // Total transaction value (incl. tax and shipping)
    'tax': '4.90',
    'shipping': '5.99',
    'coupon': 'SUMMER_SALE'
  }
*/
const getActionField = payload => {
  return {
    coupon: payload.discounts ? payload.discounts : [],
    revenue: payload.total,
    tax: payload.tax,
    id: payload.cartId ? payload.cartId : payload.orderId,
    shipping: payload.shipping,
  };
};
// custom data to add to the ecommerce object on cart adds
const getCartInfo = payload => {
  return payload.items
    ? {
        cart_subtotal: payload.subtotal,
        cart_discount: payload.discount,
        cart_subtotal_after_discounts: payload.subtotalMinusDiscount,
        cart_products: payload.items.map(item => item.productId).join(','),
      }
    : {};
};

// custom data to add to the ecommerce object on purchase
const getOrderInfo = payload => {
  return payload.items
    ? {
        subtotal: payload.subtotal,
        discount: payload.discount,
        subtotal_after_discounts: payload.subtotalMinusDiscount,
        products: payload.items.map(item => item.productId).join(','),
      }
    : {};
};

// aggregate getting the needed child objects
const getEcomObj = payload => {
  return {
    actionField: getActionField(payload),
    products: getAllCartObj(payload.items),
  };
};

// aggregate getting the needed child objects

const getOrderEcomObj = payload => {
  return {
    actionField: getActionField(payload),
    products: getAllCartObj(payload.orderLines),
  };
};

// aggregate getting the ecommerce object and conversion events
const emitOrderConfObject = (action, prevState, nextState) => {
  if (action.payload.adyenResponseType) {
    // This indicates the order is not yet complete (Adyen is
    // awaiting more authentication details)
    return;
  }

  const visitorId = getSessionVisitorId(nextState);
  const SET_ID = config.get('public.productTypes.setId');
  const BYO_SET_ID = config.get('public.productTypes.byoSetId');
  const products = action.payload.orderLines.flatMap(order => {
    if ([SET_ID, BYO_SET_ID].includes(order.productTypeId)) {
      return order.bundleItems.map(item => ({
        product_id: item.masterProductId,
        sku: item.itemNumber,
        list_id: order.productSource,
        bundle_product_id: order.masterProductId,
        price: item.purchaseUnitPrice,
      }));
    }

    return {
      product_id: order.masterProductId,
      sku: order.itemNumber,
      list_id: order.productSource,
      price: order.purchaseUnitPrice,
    };
  });

  if (action.payload.isActivatingVip) {
    const dateAdded = parseDate(nextState.membership.dateTimeAdded);
    const getNewDate = getNewDateFunction(nextState);
    const dateActivated = getNewDate();
    const datesAreInSameMonth =
      dateAdded.getMonth() === dateActivated.getMonth() &&
      dateAdded.getYear() === dateActivated.getYear();
    const checkDatesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    const datesAreOnSameDay = checkDatesAreOnSameDay(dateAdded, dateActivated);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'vipConversion',
        session_id: nextState.session.sessionId.toString(),
        customer_bucket_group: (action.payload.customerId % 20) + 1,
        dmg_code: nextState.session.dmGatewayCode,
        visitor_group: (visitorId % 20) + 1,
        m1: datesAreInSameMonth,
        d1: datesAreOnSameDay,
        sha_email: nextState.membership.shaEmail,
        order_id: action.payload.orderId.toString(),
        total: action.payload.total,
        customer_id: action.payload.customerId.toString(),
        visitor_id: visitorId.toString(),
      });
    }
  }

  window.dataLayer.push({
    event: 'orderCompleted',
    order_id: action.payload.orderId.toString(),
    page_hostname: window.location.hostname,
    products: products,
    subtotal: action.payload.subtotal,
    sha_email: nextState.membership.shaEmail,
    visitor_group: (visitorId % 20) + 1,
    visitor_id: visitorId.toString(),
    customer_bucket_group: (action.payload.customerId % 20) + 1,
    customer_id: action.payload.customerId.toString(),
    session_id: nextState.session.sessionId.toString(),
    total: action.payload.total,
    is_activating: action.payload.isActivatingVip,
    store_id: nextState.customer.storeId,
  });

  return {
    ...emitOrderConversionEvent(action, prevState),

    ecommerce: {
      purchase: getOrderEcomObj(action.payload),
    },
    ...getOrderInfo(action.payload),
  };
};

/*
  base ecommerce array example for purchase (they are mostly the same everywhere):
    'ecommerce': {
      'detail': {
        'actionField': {
        },
        'products': [{ // List of productFieldObjects.
            'name': 'Triblend Android T-Shirt', // Name or ID is required.
            'id': '12345',
            'price': '15.25',
            'brand': 'Google',
            'category': 'Apparel',
            'variant': 'Gray',
            'quantity': 1,
            'coupon': '' // Optional fields may be omitted or set to empty string.
          }
        ]
      }
    }
*/

const emitProductClick = (action, prevState, nextState) => {
  const matchingImpression = lastImpressions.find(
    product => product.id === action.payload.master_product_id
  );
  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: matchingImpression && matchingImpression.list },
        products: [
          {
            name: action.payload.label,
            id: action.payload.master_product_id,
            brand: 'Savage X',
            category: action.payload.default_product_category_id,
            variant: action.payload.color,
          },
        ],
      },
    },
  };
};

const emitProductDetailView = (action, prevState, nextState) => {
  const skuParts = action.payload.item_number
    ? action.payload.item_number.split('-')
    : [];

  const matchingImpression = lastImpressions.find(
    product => product.id === action.payload.master_product_id
  );

  return {
    event: 'productDetailView',
    ecommerce: {
      detail: {
        actionField: {},
        products: [
          {
            name: action.payload.label,
            id: action.payload.master_product_id,
            price: action.payload.default_unit_price,
            brand: 'Savage X',
            category: action.payload.default_product_category_id,
            variant: action.payload.color,
            full_sku: action.payload.item_number,
            base_sku: `${skuParts[0]}`,
            product_sku: `${skuParts[0]}-${skuParts[1]}`,
            list: matchingImpression && matchingImpression.list,
          },
        ],
      },
    },
  };
};

const emitOrderConversionEvent = (action, prevState) => {
  let orderAction = '';
  let orderLabel = '';
  if (
    !!action.payload.membershipDetail &&
    action.payload.membershipDetail.orderId === action.payload.orderId
  ) {
    orderAction = 'join_membership_first';
    orderLabel = action.payload.membershipDetail.inFreeTrial
      ? 'JoinXtraSavage_free'
      : 'JoinXtraSavage';
  } else if (
    !!action.payload.membershipDetail &&
    action.payload.splitOrders &&
    action.payload.splitOrders.some(
      order => order.orderId === action.payload.membershipDetail.orderId
    )
  ) {
    orderAction = 'join_membership_first';
    orderLabel = 'JoinXtraSavage';
  } else if (
    !!action.payload.membershipDetail &&
    action.payload.membershipDetail.orderId > 0
  ) {
    orderAction = 'xtra_savage_repeat';
    orderLabel = 'repeatXtraSavge';
  } else if (
    !!getMembership(prevState) &&
    getMembership(prevState).membershipLevelGroupId === 200
  ) {
    orderAction = 'online_retail_repeat';
    orderLabel = 'onlineRetailRepeat';
  } else {
    orderAction = 'online_retail_first';
    orderLabel = 'onlineRetail';
  }

  return {
    event: 'Order Complete',
    eventCategory: 'orderEvent',
    eventAction: orderAction,
    eventLabel: orderLabel,
    hitType: 'event',
  };
};

const emitSetAdded = (
  item,
  nextState,
  psrc,
  visitorId,
  category,
  sessionId
) => {
  item.bundleItems.forEach((bundle, index) => {
    const productSku = getProductSku(bundle);

    window.dataLayer.push({
      event: 'productAdded',
      page_hostname: window.location.host,
      customer_bucket_group: nextState.customer.id
        ? `${(nextState.customer.id % 20) + 1}`
        : null,
      list_id: psrc,
      product_id: bundle.masterProductId.toString(),
      sku: productSku,
      sha_email: nextState.membership.shaEmail || '',
      visitor_group: `${(visitorId % 20) + 1}`,
      visitor_id: visitorId.toString(),
      session_id: sessionId,
      customer_id: nextState.customer.id
        ? nextState.customer.id.toString()
        : '',
      category: category,
      store_id: `${nextState.customer.storeId || nextState.storeGroup.storeId}`,
      price: bundle.purchaseUnitPrice.toString(),
      bundle_product_id: item.masterProductId.toString(),
      bundle_index: `${bundle.bundleIndex ? bundle.bundleIndex : index + 1}`,
    });
  });
};

const emitByoCompleted = (action, _prevState, nextState) => {
  const { items } = action.payload;
  const byoProductId = action.byoProductId;
  if (window.dataLayer) {
    const queryString = window.location.search;
    const psrc = new URLSearchParams(queryString).get('psrc');
    const visitorId = getSessionVisitorId(nextState);
    const item = items.find(item => item.byoProductId === byoProductId);
    const defaultProductCategoryIdsToProductType = config.get(
      'public.defaultProductCategoryIdsToProductType'
    );
    const category = item?.defaultProductCategoryId
      ? defaultProductCategoryIdsToProductType[item.defaultProductCategoryId]
      : '';
    const sessionId = action.payload.sessionId.toString();

    emitSetAdded(item, nextState, psrc, visitorId, category, sessionId);
  }

  return {
    event: 'trackEvent',
    eventCategory: 'Build Your Own',
    eventAction: 'Complete Set',
    eventLabel: '',
  };
};

const filterCheckoutItems = checkout => {
  if (checkout.cartProviderType === 'ECHO') {
    return checkout.items.map(item => item.cartLineId);
  }

  return checkout.items.map(item => item.lineId);
};

const emitItemQtyChange = (action, prevState, nextState) => {
  const { getMembershipCartItem } = require('./src/utils/selectors');
  // grab all cart items
  const prevItems = new Set(filterCheckoutItems(prevState.checkout));
  const nextItems = new Set(filterCheckoutItems(nextState.checkout));

  const membershipItem =
    getMembershipCartItem(prevState) || getMembershipCartItem(nextState);
  const eventAction = action.meta.eventAction || null;

  let eventLabel;
  switch (action.meta.location) {
    case MembershipActionLocation.PDP:
      eventLabel = 'Free Trial – pdp';
      break;
    case MembershipActionLocation.SHOPPING_BAG:
      eventLabel = 'Free Trial – shopping bag';
      break;
    case MembershipActionLocation.XTRA_SAVAGE_PAGE:
      eventLabel = 'Free Trial – xtra savage page';
      break;
    case MembershipActionLocation.MINI_CART:
      eventLabel = 'Free Trial – mini cart';
      break;
    case MembershipActionLocation.UPSELLS:
      eventLabel = 'Upsells';
      break;
    default:
      eventLabel = 'Free Trial - other';
  }

  let event = 'sameCart';
  let key = 'nochange';
  let products = [];
  let updatedProducts = [];
  // if next state's items are more than the items in prev state, this means that
  // the user added items to the cart.
  if (prevItems.size < nextItems.size) {
    const { source } = action.meta;
    const queryString = window.location.search;
    const psrc = source ?? new URLSearchParams(queryString).get('psrc');
    const visitorId = getSessionVisitorId(nextState);
    const { productId, setId } = action.meta;
    const itemId = productId || setId;
    const item = action.payload.items.find(item => item.productId === itemId);
    const productSku = getProductSku(item);
    const defaultProductCategoryIdsToProductType = config.get(
      'public.defaultProductCategoryIdsToProductType'
    );
    const category = item?.defaultProductCategoryId
      ? defaultProductCategoryIdsToProductType[item.defaultProductCategoryId]
      : '';
    const sessionId = action.payload.sessionId.toString();

    const SET_ID = config.get('public.productTypes.setId');

    if (SET_ID === item.productTypeId && window.dataLayer) {
      emitSetAdded(item, nextState, psrc, visitorId, category, sessionId);
    } else {
      window.dataLayer.push({
        event: 'productAdded',
        page_hostname: window.location.host,
        customer_bucket_group: nextState.customer.id
          ? `${(nextState.customer.id % 20) + 1}`
          : '',
        list_id: psrc,
        product_id: item.masterProductId.toString(),
        sku: productSku,
        sha_email: nextState.membership.shaEmail || '',
        visitor_group: (visitorId % 20) + 1,
        visitor_id: visitorId.toString(),
        session_id: sessionId,
        customer_id: nextState.customer.id
          ? nextState.customer.id.toString()
          : '',
        category: category,
        store_id: nextState.customer.storeId || nextState.storeGroup.storeId,
        price: item.purchaseUnitPrice,
      });
    }

    event = 'addToCart';
    key = 'add';
    // grab all items that are in nextItems but not in prevItems
    products = nextState.checkout.items.filter(
      item => !prevItems.has(item.lineId)
    );
    // if Free Trial Membership was added to cart, add variant variable + location added
    const membershipIndex = products.findIndex(
      product =>
        membershipItem && product.productId === membershipItem.productId
    );
    if (membershipIndex > -1) {
      const productToUpdate = products[membershipIndex];
      const updatedProduct = {
        ...productToUpdate,
        variant: eventLabel,
      };
      updatedProducts = [
        ...products.slice(0, membershipIndex),
        updatedProduct,
        ...products.slice(membershipIndex + 1),
      ];
    } else {
      updatedProducts = [...products];
    }
  } else {
    event = 'removeFromCart';
    key = 'remove';
    // grab all items that are in prevItems but not in nextItems
    products = prevState.checkout.items.filter(
      item => !nextItems.has(item.lineId)
    );
    // if Free Trial Membership was added to cart, add variant variable + location removed
    const membershipIndex = products.findIndex(
      product =>
        membershipItem && product.productId === membershipItem.productId
    );
    if (membershipIndex > -1) {
      const productToUpdate = products[membershipIndex];
      const updatedProduct = {
        ...productToUpdate,
        variant: eventLabel,
      };
      updatedProducts = [
        ...products.slice(0, membershipIndex),
        updatedProduct,
        ...products.slice(membershipIndex + 1),
      ];
    } else {
      updatedProducts = [...products];
    }
  }
  const checkoutObj = {
    ...nextState.checkout,
    items: updatedProducts,
  };
  return {
    event,
    eventAction,
    ecommerce: {
      [key]: getEcomObj(checkoutObj),
    },
    ...getCartInfo(checkoutObj),
  };
};

const emitTrackPage = (action, prevState, nextState) => {
  const shouldSkip = nextState.session.isServerOnly;

  if (shouldSkip) {
    return;
  }

  const retEvts = [];
  const pageView = {
    page: action.url.asPath,
    event: 'pageview',
  };

  retEvts.push(pageView);

  const emitCheckoutObj = emitCheckoutStep(action.url, prevState);
  if (emitCheckoutObj) {
    retEvts.push(emitCheckoutObj);
  }

  return retEvts;
};

const sessionPageView = (action, prevState, nextState) => {
  if (
    (prevState.session.isServerOnly || !prevState.session.sessionKey) &&
    !nextState.session.isServerOnly
  ) {
    return emitTrackPage(
      { ...action, url: { asPath: Router.asPath } },
      prevState,
      nextState
    );
  }
};

const emitCheckoutStep = (url, prevState) => {
  const step =
    {
      '/cart': 1,
      '/checkout/order-information?step=1': 2,
      '/checkout/order-information?step=2': 3,
      '/checkout/order-information?step=3': 4,
    }[url.asPath] || 0;

  if (step > 0) {
    return {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: step,
          },
          products: getAllCartObj(prevState.checkout.items),
        },
      },
    };
  }
};

const emitErrorEvent = (action, prevState) => {
  const { statusCode, url } = action;

  let eventAction = '';
  switch (statusCode) {
    case 404:
      eventAction = 'PageNotFound';
      break;
    default:
      eventAction = 'PageError';
      break;
  }

  return {
    event: 'pageError',
    eventAction: eventAction,
    eventCategory: 'Error',
    eventLabel: url ? url.asPath : '',
  };
};

const emitEnterSweepstakesEvent = (action, prevState) => {
  const { isSweepstakesActive } = require('./src/utils/selectors');
  const isActive = isSweepstakesActive(prevState);
  if (isActive) {
    // fires an event when a user joins the mailing list through the sweepstakes
    return {
      event: 'Sign Up Event',
      eventCategory: 'conversionEvent',
      eventAction: 'Registration Complete',
      eventLabel: 'Email Signup – Sweepstakes',
    };
  }
  // fires an event when a user joins the mailing list through the Fashion Experience sign up
  return {
    event: 'Sign Up Event',
    eventCategory: 'conversionEvent',
    eventAction: 'Registration Complete',
    eventLabel: 'Email Signup – Splash',
  };
};

export default createGoogleTagManagerEvents(({ dispatch }) => {
  return action => {
    const {
      LOAD_PRODUCT_SUCCESS,
      LOAD_PRODUCT_SET_SUCCESS,
      TRACK_PRODUCT_IMPRESSION,
      TRACK_PRODUCT_MODEL_CHANGE,
      TRACK_PRODUCT_MODEL_NO_CHANGE,
      TRACK_PDP_MODEL_THUMBNAILS_CLICK,
      TRACK_MODEL_COLLAPSIBLE_ACTION,
      TRACK_PRODUCT_SEARCH_RESULT,
      TRACK_MEMBERSHIP_CTA,
      TRACK_PDP_CROSS_SELL_CLICK,
      TRACK_GRID_RECOMMENDATIONS,
      TRACK_PAIR_IT_WITH_CLICK,
      TRACK_COMPLETE_THE_LOOK_CLICK,
      TRACK_QUICK_MATCH_CLICK,
      TRACK_PDP_RADIO_TOGGLE,
      TRACK_PDP_SET_DETAILS_OPEN,
      TRACK_PDP_SET_DETAILS_CLOSE,
      TRACK_PDP_SET_REVIEWS_OPEN,
      TRACK_PDP_SET_REVIEWS_CLOSE,
      TRACK_PDP_SET_REVIEWS_PAGINATION,
      TRACK_PDP_SET_SUMMARY_OPEN,
      TRACK_PDP_SET_SUMMARY_CLOSE,
      TRACK_CLICK_SIZE_SELECTION,
      TRACK_PRODUCT_CLICK,
      TRACK_MARKETING_CARD_CLICK,
    } = require('./src/actions/products');
    const {
      TRACK_REGISTRATION_PROMPT_SIGN_UP,
      TRACK_SIGN_UP_MODAL,
      SIGN_UP_METHOD_SUCCESS,
    } = require('./src/actions/auth');
    const {
      ADD_BASKET_ITEMS_SUCCESS,
      ADD_PRODUCT_SET_SUCCESS,
      DELETE_BASKET_ITEMS_SUCCESS,
      DELETE_PRODUCT_SET_SUCCESS,
      DELETE_MEMBERSHIP_ITEM_SUCCESS,
      COMPLETE_CART_ORDER_SUCCESS,
      UPDATE_ITEM_QUANTITY_SUCCESS,
      TRACK_CHECKOUT_TOGGLE_REQUEST,
      TRACK_ADD_MEMBERSHIP_LINE,
      TRACK_REMOVE_MEMBERSHIP_LINE,
      TRACK_PAYMENT_METHOD_SELECTION,
      TRACK_EXPRESS_CHECKOUT_SELECTION,
      TRACK_EDIT_SHIPPING,
      TRACK_EDIT_PAYMENT,
      TRACK_CLAIM_GWP_CTA,
      TRACK_ADD_GWP,
      TRACK_REMOVE_GWP,
      TRACK_UNDO_GWP,
    } = require('./src/actions/checkout');
    const {
      ADD_TO_WAITLIST_SUCCESS,
      REMOVE_FROM_WAITLIST_SUCCESS,
    } = require('./src/actions/account');
    const {
      JOIN_EMAIL_LIST_SUCCESS,
      ENTER_SWEEPSTAKES_SUCCESS,
    } = require('./src/actions/contacts');
    const {
      UPSELL_LEARN_MORE_REQUEST,
      UPSELL_NO_THANKS_REQUEST,
      UPSELL_ADD_FREE_TRIAL_REQUEST,
    } = require('./src/actions/abTest');
    const {
      TRACK_PDP_LEARN_MORE_REQUEST,
      TRACK_CLICK_PROMO_OFFER_REQUEST,
    } = require('./src/actions/cart');

    const eventsMap = {
      [PAGE_RENDERED]: emitTrackPage,
      [loadMembershipFailure]: (action, prevState) => {
        if (window.dataLayer) {
          window.dataLayer.push({
            savage_persona: null,
            dmg_code: prevState.session.dmGatewayCode,
          });
        }
        return {
          event: 'membershipDetail',
          isMember: getMembership(prevState).isVip,
          isLoggedIn: 0,
          visit_type: 'Logged Out',
        };
      },

      [loadMembershipSuccess]: (action, prevState, nextState) => {
        if (window.dataLayer) {
          const dlObject = {
            savage_persona: nextState.persona || null,
            dmg_code: nextState.session.dmGatewayCode,
            hashed_user_email: action.payload.hashedUserEmail,
            sha_email: action.payload.shaEmail,
          };
          window.dataLayer.push(dlObject);
        }

        const { isVip, isLead, availableTokenQuantity } =
          getMembership(nextState);

        return {
          event: 'membershipDetail',
          availableTokenQuantity,
          customer_id: action.payload.customerId,
          isLead,
          isLoggedIn: 1,
          isMember: isVip,
          membership_activation_date: action.payload.dateActivated,
          membership_id: action.payload.membershipId,
          membership_level_group_id: action.payload.membershipLevelGroupId,
          user_registered_date: action.payload.dateAdded,
          visit_type: 'Logged In',
        };
      },
      [loadSessionSuccess]: (action, prevState, nextState) => {
        const events = [
          {
            event: 'sessionDetail',
            cookie_visitor: action.payload.sessionVisitor,
            session_id: action.payload.sessionId
              ? action.payload.sessionId
              : parseInt(action.payload.session.split('-')[0], 16),
            store_group_id: action.payload.storeGroupId,
          },
        ];

        const pageViewEvents = sessionPageView(action, prevState, nextState);

        if (pageViewEvents) {
          events.push(...pageViewEvents);
        }

        return events;
      },
      [logOutSuccess]: (action, prevState) => ({
        event: 'Log Out Event',
        eventCategory: 'Account',
        eventAction: 'Log Out',
        eventLabel: 'User Log Out',
        hitType: 'event',
      }),

      [logInSuccess]: (action, prevState, nextState) => ({
        event: 'Log In Event',
        eventCategory: 'Account',
        eventAction: 'Log In',
        eventLabel: 'User Log In',
        hitType: 'event',
      }),

      [UPSELL_LEARN_MORE_REQUEST]: (action, prevState, nextState) => ({
        event: 'Upsell Learn More',
        eventCategory: 'Checkout Upsell Free Trial',
        eventAction: 'Upsell Learn more',
        eventLabel: 'Upsell Learn More',
      }),

      [UPSELL_NO_THANKS_REQUEST]: (action, prevState, nextState) => ({
        event: 'Upsell No Thanks',
        eventCategory: 'Checkout Upsell Free Trial',
        eventAction: 'Upsell No Thanks',
        eventLabel: 'Upsell No Thanks',
      }),

      [UPSELL_ADD_FREE_TRIAL_REQUEST]: (action, prevState, nextState) => ({
        event: 'Upsell Add Free Trial',
        eventCategory: 'Checkout Upsell Free Trial',
        eventAction: 'Upsell Add To Bag',
        eventLabel: 'Upsell Add To Bag',
      }),

      [TRACK_CHECKOUT_TOGGLE_REQUEST]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'Shopping Bag',
        eventAction: action.eventAction,
      }),

      [TRACK_AMBASSADOR_SHOP]: action => ({
        event: 'trackEvent',
        eventCategory: 'Ambassador Shops',
        eventAction: action.eventAction,
        eventLabel: action.eventLabel,
      }),

      [TRACK_CLAIM_GWP_CTA]: () => ({
        event: 'trackEvent',
        eventCategory: 'Shopping Bag',
        eventAction: 'Claim Gift',
      }),

      [TRACK_ADD_GWP]: action => ({
        event: 'trackEvent',
        eventCategory: 'Shopping Bag',
        eventAction: 'Add Gift',
        eventLabel: action.eventLabel,
      }),

      [TRACK_REMOVE_GWP]: action => ({
        event: 'trackEvent',
        eventCategory: 'Shopping Bag',
        eventAction: 'Remove Gift',
        eventLabel: action.eventLabel,
      }),

      [TRACK_UNDO_GWP]: () => ({
        event: 'trackEvent',
        eventCategory: 'Shopping Bag',
        eventAction: 'Undo',
      }),

      [TRACK_CLICK_PROMO_OFFER_REQUEST]: action => ({
        event: 'trackEvent',
        eventCategory: 'Mini Cart',
        eventAction: 'Promo Click',
        eventLabel: action.eventLabel,
      }),

      [TRACK_MONTHLY_MEMBERSHIP_REQUEST]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: action.eventCategory,
        eventAction: 'How it works',
        eventLabel: action.eventLabel,
      }),

      [TRACK_ADD_TO_WISHLIST_REQUEST]: action => ({
        event: 'trackEvent',
        eventCategory: action.payload.eventCategory,
        eventAction: 'Add to Wishlist',
        eventLabel: action.payload.eventLabel,
      }),

      [TRACK_REMOVE_FROM_WISHLIST_REQUEST]: action => ({
        event: 'trackEvent',
        eventCategory: action.payload.eventCategory,
        eventAction: 'Remove from Wishlist',
        eventLabel: action.payload.eventLabel,
      }),

      [TRACK_EMAIL_OPT_IN_INTERACTIONS]: action => ({
        event: 'trackEvent',
        eventCategory: 'Grids',
        eventAction: 'Email Opt In',
        eventLabel: action.eventLabel,
      }),

      [TRACK_MEMBERSHIP_CANCELLATION_CONFIRMATION]: action => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: action.isCancellationConfirmed
            ? 'Cancellation Complete'
            : 'Promo Redeemed',
          eventLabel: '',
        };
        return event;
      },
      [TRACK_MEMBERSHIP_INACTIVE_SKIP]: () => {
        return {
          event: 'trackEvent',
          eventCategory: 'Membership',
          eventAction: 'Skip',
          eventLabel: 'Inactive',
        };
      },

      [TRACK_MEMBERSHIP_ACTIVE_SKIP]: () => {
        return {
          event: 'trackEvent',
          eventCategory: 'Membership',
          eventAction: 'Skip',
          eventLabel: 'Active',
        };
      },

      [TRACK_MEMBERSHIP_ACTIVE_SKIP_ACTIONS]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Membership',
          eventAction: 'Skip',
          eventLabel: action.eventLabel,
        };
      },
      [TRACK_MEMBERSHIP_SKIP_ACTIONS_REQUEST]: (
        action,
        prevState,
        nextState
      ) => {
        const trackedEventDetails = {
          event: 'Membership Skip',
          eventCategory: 'Membership',
          eventAction: 'Skip Initial',
        };
        if (action.eventLabel) {
          return {
            ...trackedEventDetails,
            eventLabel: action.eventLabel,
          };
        }

        return trackedEventDetails;
      },

      [TRACK_MEMBERSHIP_LEARN_MORE]: action => {
        return {
          event: 'membership',
          eventAction: 'learn_more',
          eventLabel: action.location,
        };
      },
      [TRACK_ONLINE_CANCEL_FAQ]: () => ({
        event: 'trackEvent',
        eventCategory: 'Online Cancel',
        eventAction: 'FAQ',
        eventLabel: 'Step 1',
      }),

      [TRACK_ONLINE_CANCEL_PHONE_CHAT]: action => {
        const eventLabel = `Step ${action.eventLabel.step || 1}`;
        const event = {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: action.eventLabel.action,
          eventLabel: eventLabel,
        };
        return event;
      },

      [TRACK_ONLINE_CANCEL_SKIP]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: 'Skip the Month Hyperlink',
          eventLabel: '',
        };
      },

      [TRACK_ONLINE_CANCEL_TAKE_ME_TO_SKIP]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: 'Take me to skip',
          eventLabel: '',
        };
      },

      [TRACK_ONLINE_CANCEL_SKIP_REMINDER_CONFIRMATION]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: 'Confirm Skip Reminder',
          eventLabel: '',
        };
      },

      [TRACK_ONLINE_CANCEL_SIGN_UP_SKIP_REMINDER]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: 'Sign Up for Skip Reminder',
          eventLabel: '',
        };
      },

      [TRACK_ONLINE_CANCELLATION_REASON]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'Online Cancel',
          eventAction: 'Cancellation Reason',
          eventLabel: action.eventLabel,
        };
      },

      [TRACK_REGISTRATION_PROMPT_SIGN_UP]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'Registration Prompt Modal',
        eventAction: action.eventAction,
        eventLabel: 'Registration Prompt',
      }),

      [TRACK_CLICK_SIZE_SELECTION]: action => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'PDP',
          eventAction: 'Size Selection',
          eventLabel: action.eventLabel.productID,
          dimension16: action.eventLabel.prodSize,
        };
        if (action.eventLabel.isSet) {
          event.dimension1 = action.eventLabel.productSetId;
        }
        return event;
      },

      [TRACK_PRODUCT_CLICK]: emitProductClick,

      [TRACK_PAIR_IT_WITH_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'pdp',
        eventAction: 'Pair It With Click',
        eventLabel: action.eventLabel,
      }),

      [TRACK_COMPLETE_THE_LOOK_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'pdp',
        eventAction: 'Complete the Look Click',
        eventLabel: action.eventLabel,
      }),

      [TRACK_QUICK_MATCH_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'pdp',
        eventAction: 'Quick Match',
        eventLabel: action.eventLabel,
      }),

      [TRACK_PDP_RADIO_TOGGLE]: action => ({
        event: 'trackEvent',
        eventCategory: 'pdp',
        eventAction: 'membership_toggle',
        eventLabel: action.eventLabel,
      }),

      [TRACK_PDP_LEARN_MORE_REQUEST]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'VIP_CTA',
        eventLabel: 'Learn More About Flexible Membership',
      }),
      [TRACK_PDP_SET_SUMMARY_OPEN]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set size and color selector',
        eventLabel: 'open',
      }),

      [TRACK_PDP_SET_SUMMARY_CLOSE]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set size and color selector',
        eventLabel: 'close',
      }),

      [TRACK_PDP_SET_DETAILS_OPEN]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set product details',
        eventLabel: 'open',
      }),

      [TRACK_SIZE_PROMPT_ACTION]: action => ({
        event: 'trackEvent',
        eventCategory: 'Profile',
        eventAction: 'Input Size Pop-up',
        eventLabel: action.eventLabel,
      }),

      [TRACK_SIZE_UPDATE_SUCCESS]: action => ({
        event: 'trackEvent',
        eventCategory: 'Profile',
        eventAction: 'Update Size',
        eventLabel: action.eventLabel,
      }),

      [TRACK_PDP_SET_DETAILS_OPEN]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set product details',
        eventLabel: 'open',
      }),
      [TRACK_PDP_SET_DETAILS_CLOSE]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set product details',
        eventLabel: 'close',
      }),
      [TRACK_PDP_SET_REVIEWS_OPEN]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set reviews',
        eventLabel: 'open',
      }),
      [TRACK_PDP_SET_REVIEWS_CLOSE]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set reviews',
        eventLabel: 'close',
      }),
      [TRACK_PDP_SET_REVIEWS_PAGINATION]: (action, prevState, nextState) => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'set reviews',
        eventLabel: `page ${action.page}`,
      }),
      [TRACK_SIGN_UP_MODAL]: action => {
        const isSpeedy =
          action.location === 'SPEEDY' || action.location === 'EXIT';
        if (isSpeedy) {
          return {
            event: 'trackEvent',
            eventAction: 'Speedy Signup',
            eventCategory: 'Registration',
            eventLabel: 'Pop Up',
          };
        }
      },
      [SIGN_UP_METHOD_SUCCESS]: (action, prevState, nextState) => {
        let eventLabel;
        switch (action.eventAction.method) {
          case SignUpMethod.CARTDRAWER:
            eventLabel = 'Lead Registration – Cartdrawer Checkout';
            break;
          case SignUpMethod.CART_CHECKOUT:
            eventLabel = 'Lead Registration – Cart Checkout';
            break;
          case SignUpMethod.EXIT:
            eventLabel = 'Lead Registration – Exit';
            break;
          case SignUpMethod.NEWSLETTER_EMAIL_SIGNUP:
            eventLabel = 'Lead Registration – Footer';
            break;
          case SignUpMethod.NAVBAR:
            eventLabel = 'Lead Registration – Navbar';
            break;
          case SignUpMethod.QUIZ:
            eventLabel = 'Lead Registration – Quiz';
            break;
          case SignUpMethod.SPEEDY:
            eventLabel = 'Lead Registration – Speedy';
            break;
          case SignUpMethod.WAITLIST:
            eventLabel = 'Lead Registration – Waitlist';
            break;
          case SignUpMethod.WISHLIST:
            eventLabel = 'Lead Registration – Wishlist';
            break;
          default:
            eventLabel = 'registration_complete';
        }

        if (window.dataLayer) {
          const visitorId = getSessionVisitorId(nextState);
          window.dataLayer.push({
            event: 'completeRegistration',
            category: 'registration',
            page_hostname: window.location.hostname,
            session_id: nextState.session.sessionId.toString(),
            store_id: nextState.customer.storeId,
            store_group_id: nextState.storeGroup.storeGroupId,
            customer_bucket_group: (nextState.customer.id % 20) + 1,
            dmg_code: nextState.session.dmGatewayCode,
            visitor_group: (visitorId % 20) + 1,
            sha_email: nextState.customer.shaEmail,
            source: eventLabel,
            customer_id: nextState.customer.id.toString(),
          });
        }

        return {
          event: 'Sign Up Event',
          eventCategory: 'conversionEvent',
          eventAction: 'Registration Complete',
          eventLabel,
          hitType: 'event',
          customer_id: nextState.customer.id,
        };
      },
      [TRACK_EDIT_SHIPPING]: ({ category }) => {
        const event = {
          event: 'trackEvent',
          eventCategory: category,
          eventAction: 'Edit Shipping',
        };
        return event;
      },
      [TRACK_EDIT_PAYMENT]: ({ category }) => {
        const event = {
          event: 'trackEvent',
          eventCategory: category,
          eventAction: 'Edit Payment',
        };
        return event;
      },
      [TRACK_PAYMENT_METHOD_SELECTION]: ({ paymentType }) => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'Checkout',
          eventAction: 'Payment Method',
          eventLabel: paymentType,
        };
        return event;
      },
      [TRACK_EXPRESS_CHECKOUT_SELECTION]: ({ paymentType, category }) => {
        const event = {
          event: 'trackEvent',
          eventCategory: category,
          eventAction: 'Express Checkout',
          eventLabel: paymentType,
        };
        return event;
      },
      [TRACK_FEATURED_COLLECTION_TILES]: action => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'Homepage',
          eventAction: 'Featured Collections',
          eventLabel: action.eventLabel,
        };
        return event;
      },
      [TRACK_HOMEPAGE_CAROUSEL]: action => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'Homepage',
          eventAction: 'Carousel',
          eventLabel: action.eventLabel,
        };
        return event;
      },
      [TRACK_FIT_GUARANTEE]: action => {
        const event = {
          event: 'trackEvent',
          eventCategory: 'Homepage',
          eventAction: '30-Day Fit Guarantee',
          eventLabel: action.eventLabel,
        };
        return event;
      },
      [TRACK_EMP_INFO_DRAWER_OPEN]: () => ({
        event: 'trackEvent',
        eventCategory: 'Info Drawer',
        eventAction: 'Open Info Drawer',
      }),
      [TRACK_EMP_INFO_DRAWER_CTAS]: action => ({
        event: 'trackEvent',
        eventCategory: 'Info Drawer',
        eventAction: 'Shopping CTA',
        eventLabel: action.eventLabel,
      }),
      [TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_OPEN]: () => ({
        event: 'trackEvent',
        eventCategory: 'Info Drawer',
        eventAction: 'Discover Perks',
      }),
      [TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_MORE]: () => ({
        event: 'trackEvent',
        eventCategory: 'Info Drawer',
        eventAction: 'How It Works',
      }),

      [LOAD_PRODUCT_SUCCESS]: emitProductDetailView, // returns the ecommerce array for product detail views to measure metrics when people view a PDP

      [LOAD_PRODUCT_SET_SUCCESS]: emitProductDetailView,

      [ADD_BASKET_ITEMS_SUCCESS]: emitItemQtyChange, // returns an AddToCart array to the dataLayer, used when updating quantity on checkout

      [ADD_PRODUCT_SET_SUCCESS]: emitItemQtyChange,

      [DELETE_BASKET_ITEMS_SUCCESS]: emitItemQtyChange, // returns an AddToCart array to the dataLayer, used when updating quantity on checkout

      [DELETE_PRODUCT_SET_SUCCESS]: emitItemQtyChange,

      [DELETE_MEMBERSHIP_ITEM_SUCCESS]: emitItemQtyChange,

      [COMPLETE_CART_ORDER_SUCCESS]: emitOrderConfObject, // returns the ecommerce array to the dataLayer to measure buys on the site

      [UPDATE_ITEM_QUANTITY_SUCCESS]: emitItemQtyChange, // returns an AddToCart array to the dataLayer, used when updating quantity on checkout

      [ADD_TO_WAITLIST_SUCCESS]: (action, prevState) => ({
        event: 'addToWaitlist',
        eventCategory: 'Waitlist',
        eventAction: 'Waitlist Add',
      }), // returns an addToWaitlist event object to the dataLayer, used when updating quantity on checkout

      [TRACK_ADD_MEMBERSHIP_LINE]: action => ({
        event: 'membership',
        eventAction: MembershipEventAction.MANUAL_ADD,
        eventLabel: action.meta.location,
      }),

      [TRACK_REMOVE_MEMBERSHIP_LINE]: action => {
        return {
          event: 'membership',
          eventAction: MembershipEventAction.REMOVE,
          eventLabel: action.meta.location,
        };
      },

      [REMOVE_FROM_WAITLIST_SUCCESS]: (action, prevState) => ({
        event: 'removeFromWaitlist',
        eventCategory: 'Waitlist',
        eventAction: 'Waitlist Remove',
      }), // returns an addToWaitlist event object to the dataLayer, used when updating quantity on checkout

      [ERROR_PAGE_RENDERED]: emitErrorEvent,

      [ENTER_SWEEPSTAKES_SUCCESS]: emitEnterSweepstakesEvent,

      [JOIN_EMAIL_LIST_SUCCESS]: (action, prevState) => ({
        event: 'Sign Up Event',
        eventCategory: 'conversionEvent',
        eventAction: 'Registration Complete',
        eventLabel: 'Email Signup',
        customer_id: action.payload.customerId,
        hashed_user_email: action.payload.hashedUserEmail,
        sha_email: action.payload.shaEmail,
      }), // Fires an event when a user joins the mailing list separate from creating an account

      [QUIZ_ADVANCE_PAGE]: action => ({
        page: `${Router.asPath}`,
        event: 'trackPage',
      }),

      [QUIZ_START]: action => ({
        event: 'Quiz Start Event',
        eventCategory: 'conversionEvent',
        eventAction: 'Quiz Start',
        eventLabel: 'quiz_start',
      }),

      [QUIZ_REGISTRATION_START]: action => ({
        event: 'Registration Start Event',
        eventCategory: 'conversionEvent',
        eventAction: 'Registration Start',
        eventLabel: 'registration_start',
      }),

      [QUIZ_SKIP_LINK]: action => ({
        event: 'Skip Quiz',
        eventCategory: 'Skip Quiz',
        eventAction: `Skipped ${action.payload}`,
      }),

      [TRACK_PDP_CROSS_SELL_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'PDP',
        eventAction: 'Cross Sell Click',
        eventLabel: action.eventLabel,
      }),

      [TRACK_GRID_RECOMMENDATIONS]: action => ({
        event: 'trackEvent',
        eventCategory: 'Grids',
        eventAction: 'Grid Recommendations Click',
        eventLabel: action.eventLabel,
      }),

      [TRACK_PRODUCT_IMPRESSION]: action => {
        if (action.payload && action.payload.length) {
          const impressions = action.payload.map(trackProduct => {
            const { list, position, product } = trackProduct;
            lastImpressions.unshift({
              id: product.master_product_id || product.masterProductId,
              list,
            });
            // save the 20 most recent products because there can be multiple qualifying lists on the screen at once and they are in groups of 4
            if (lastImpressions.length > 20) {
              lastImpressions.pop();
            }
            return {
              brand: 'Savage X',
              category:
                product.default_product_category_id ||
                product.defaultProductCategoryId,
              id: product.master_product_id || product.masterProductId,
              list,
              name: product.label,
              position,
              price: product.default_unit_price || product.defaultUnitPrice,
              variant: product.color,
            };
          });

          return {
            event: 'trackEvent',
            eventCategory: 'Ecommerce',
            eventAction: 'Impression',
            ecommerce: {
              impressions: impressions,
            },
          };
        }
      },

      [TRACK_PRODUCT_MODEL_CHANGE]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'pdp',
          eventAction: 'model_dropdown',
          eventLabel: `${action.model.product.master_product_id}: ${action.model.description}`,
        };
      },

      [TRACK_PRODUCT_MODEL_NO_CHANGE]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'pdp',
          eventAction: 'model_dropdown',
          eventLabel: 'model_no_change',
        };
      },

      [TRACK_PDP_MODEL_THUMBNAILS_CLICK]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'pdp',
          eventAction: 'Alt Models',
          eventLabel: action.model.modelInfo,
          dimension1: action.model.product.master_product_id,
          dimension16: action.model.modelSize,
        };
      },

      [TRACK_MODEL_COLLAPSIBLE_ACTION]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'pdp',
          eventAction: 'Alt Models',
          eventLabel: action.payload.eventLabel,
          dimension1: action.payload.model.product.master_product_id,
        };
      },

      [TRACK_PRODUCT_SEARCH_RESULT]: action => {
        const { eventAction, eventKey } = action.eventDetails;
        if (!searchEventLabelValue[eventKey]) {
          return;
        }
        return {
          event: 'trackEvent',
          eventCategory: 'Search',
          eventAction: eventAction,
          eventLabel: searchEventLabelValue[eventKey],
        };
      },
      [TRACK_MEMBERSHIP_CTA]: action => {
        return {
          event: 'trackEvent',
          eventCategory: 'pdp',
          eventAction: 'membership_cta',
          eventLabel: action.eventLabel,
        };
      },
      [TRACK_NAV_ITEM_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'navigation',
        eventAction: action.eventAction,
        eventLabel: action.eventLabel,
      }),
      [TRACK_MARKETING_CARD_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'Marketing Card',
        eventAction: action.eventAction,
        eventLabel: action.eventLabel,
      }),
      [TRACK_PAUSE_MEMBERSHIP]: action => ({
        event: 'trackEvent',
        eventCategory: 'Pause Page',
        eventAction: 'Online Cancel',
        eventLabel: action.eventLabel,
      }),
      [TRACK_BUILD_A_SET_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'Build Your Own',
        eventAction: 'Build a Set',
        eventLabel: '',
        dimension1: action.productInfo.productId,
        dimension18: action.productInfo.productCategoryId,
      }),
      [TRACK_BYO_MODAL_OPTION_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'Build Your Own',
        eventAction: 'Click Byo Modal Option',
        eventLabel: action.eventLabel,
      }),
      [TRACK_COMPLETE_SET_CLICK]: emitByoCompleted,
      [TRACK_SHOW_BYO_EXIT_MODAL]: action => ({
        event: 'trackEvent',
        eventCategory: 'Build Your Own',
        eventAction: 'Exit Modal Pop-Up',
        eventLabel: action.eventLabel,
      }),
      [TRACK_EXIT_OPTION_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'Build Your Own',
        eventAction: 'Exit Modal Button Click',
        eventLabel: action.eventLabel,
      }),
      [TRACK_EMP_NEW_TOKEN_MODAL_CTA_CLICK]: action => ({
        event: 'trackEvent',
        eventCategory: 'Member Credit Success Notification',
        eventAction: 'Shopping CTA',
        eventLabel: action.eventLabel,
      }),
    };

    return eventsMap[action.type];
  };
});

function EmailPreferencesVariables({ dataLayerName }) {
  const { networkStatus, optOutStatus, vars } = useEmailPreferencesWithVars();
  const data = useMemo(() => {
    if (networkStatus.isUpToDate) {
      if (vars?.emailLevel) {
        // FIXME?: The values from the original code seem like they are the _opposite_ of what
        // they should be. e.g. `newsletter_optin: 'none' was based on the _opt out_ status,
        // so this was saying the customer was opted out when they were opted in.
        switch (vars.emailLevel) {
          case 'transactional':
            return { newsletter_optin: 'basic' };
          case 'all':
            return { newsletter_optin: 'none' };
        }
      } else {
        return { newsletter_optin: optOutStatus };
      }
    }
    return null;
  }, [networkStatus, optOutStatus, vars]);

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

EmailPreferencesVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

/**
 * There is a default `GoogleTagManagerVariables` component from `@techstyle/react-marketing`
 * that includes all of these variables, but we need to make our own version that has a custom
 * `EmailPreferencesVariables` component that uses the `useEmailPreferencesWithVars` hook instead
 * of the original `useEmailPreferences` hook. This is required because SXF needs to supply the
 * variable list to the `loadEmailPreferences` action, and we load those from LD. The original
 * hook does not have access to the LD flag values, hence the need for a custom component.
 */
export function GoogleTagManagerVariables({ dataLayerName }) {
  return (
    <>
      <SessionVariables dataLayerName={dataLayerName} />
      <CustomerSizeVariables dataLayerName={dataLayerName} />
      <CustomerVariables dataLayerName={dataLayerName} />
      <MembershipVariables dataLayerName={dataLayerName} />
      <EmailPreferencesVariables dataLayerName={dataLayerName} />
      <ABTestVariables dataLayerName={dataLayerName} />
    </>
  );
}

GoogleTagManagerVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

// This will be used to add additional variables to the GTM dataLayer that aren't already
// included in the the shared code. It's possible that this will be temporary until there is alignment
// on what values will be included in the dataLayer.
export function AdditionalGoogleTagManagerVariables() {
  const { availableTokenQuantity, networkStatus: membershipNetworkStatus } =
    useMembership();
  const membershipStatus = useSelector(getSXFMembershipStatus);

  const data = useMemo(() => {
    return {
      availableTokenQuantity: membershipNetworkStatus.isUpToDate
        ? availableTokenQuantity ?? 0
        : undefined,
      sxf_membership_status: membershipStatus,
    };
  }, [availableTokenQuantity, membershipNetworkStatus, membershipStatus]);

  return <GoogleTagManagerSnippet.Push data={data} />;
}
