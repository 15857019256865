import { useCallback } from 'react';

import { AutocompleteResponse } from '@constructor-io/constructorio-client-javascript';
import { useQuery } from '@tanstack/react-query';

import useCioClient from './useCioClient';

export function useCioAutoComplete(query: string) {
  const cioClient = useCioClient();

  const loadAutoSuggestions = useCallback(
    query => async () => {
      return await cioClient.autocomplete.getAutocompleteResults(query, {
        resultsPerSection: {
          Products: 3,
          'Search Suggestions': 3,
        },
      });
    },
    [cioClient?.autocomplete]
  );

  const normalizeAutoSuggestData = useCallback((data: AutocompleteResponse) => {
    const products = data.sections.Products.map(product => ({
      ...product.data,
      master_product_id: product.data.id,
    }));

    return {
      products,
      suggestions: data.sections['Search Suggestions'],
    };
  }, []);

  return useQuery({
    queryFn: loadAutoSuggestions(query),
    select: normalizeAutoSuggestData,
    queryKey: ['cio-autocomplete', query],
    enabled: !!cioClient && !!query,
  });
}
