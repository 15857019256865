import { useEffect, useState, useMemo, useCallback } from 'react';

import config from 'config';

import { useCustomer, useMembership } from '@techstyle/react-accounts';
import { useDomain, useCookies } from '@techstyle/redux-core';

import { useLDFlags } from './LD/useLDFlags';

const deploymentIds = config.get('public.genesysWebChatDeploymentId');
const GENESYS_COOKIE_NAME = 'SVGX-GENERAL-GENESYS_MESSENGER_ALWAYSSHOW';

const useGenesysWebChatAction = () => {
  const { 'genesys-web-chat': genesysWebChat } = useLDFlags();
  const genesysFeatureEnabler = genesysWebChat;

  const { email } = useCustomer();
  const { customerId } = useMembership();
  const { tld } = useDomain();

  const deploymentId = deploymentIds[tld];
  const [cookies, setCookie, removeCookie] = useCookies([GENESYS_COOKIE_NAME]);
  const expireGenesysCookie = useMemo(() => {
    return new Date(new Date().getTime() + 180 * 60 * 1000);
  }, []);
  const showMsgAlways = cookies[GENESYS_COOKIE_NAME];
  const [showIconOnMobileApp, setShowIconOnMobileApp] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    const showMessengerByMobileApp = url.searchParams.get('fromMobileApp');

    if (window?.Genesys) {
      if (customerId && email) {
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              customer_id: customerId,
              email: email,
            },
          },
        });
      }

      if (!cookies[GENESYS_COOKIE_NAME] && showMessengerByMobileApp) {
        setShowIconOnMobileApp(true);
        setCookie([GENESYS_COOKIE_NAME], true, {
          expires: expireGenesysCookie,
        });
      }

      if (showMsgAlways || showMessengerByMobileApp) {
        window.Genesys('subscribe', 'Launcher.ready', function () {
          window.Genesys('command', 'Launcher.show');
          if (showMessengerByMobileApp) {
            window.Genesys('command', 'Messenger.open', {});
          }
        });
      }
    }
  }, [
    setShowIconOnMobileApp,
    cookies,
    customerId,
    email,
    expireGenesysCookie,
    setCookie,
    showMsgAlways,
  ]);

  const hideGenesys = useCallback(() => {
    if (typeof window?.Genesys === 'function') {
      window.Genesys('command', 'Messenger.close');
    }
    removeCookie(GENESYS_COOKIE_NAME);
  }, [setCookie]);

  const genesysHandler = () => {
    window.Genesys(
      'command',
      'Messenger.open',
      {},
      function () {
        if (!cookies[GENESYS_COOKIE_NAME]) {
          setCookie([GENESYS_COOKIE_NAME], true, {
            expires: expireGenesysCookie,
          });
        }
      }, // if resolved
      function () {
        // if rejected
        window.Genesys('command', 'Messenger.close');
      }
    );
  };

  return {
    hideGenesys,
    deploymentId,
    genesysFeatureEnabler,
    genesysHandler,
    showIconOnMobileApp,
    showMsgAlways,
  };
};

export default useGenesysWebChatAction;
