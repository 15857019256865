import { useEffect } from 'react';

import { format } from 'url';

import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { Router } from '@techstyle/next-routes';

import { trackShowByoExitModal } from '../../actions/byo';
import { getSectionsAndQueryFromPath } from '../../utils/routing';
import { useByoContext } from '../ByoProvider';

export default function ByoRoutingGuard() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { root, section, subsection } = getSectionsAndQueryFromPath(
    router.asPath
  );
  const isByoPage = root === 'byo';
  const {
    isLoading,
    isFlowInitiated,
    isInterstitialFlow,
    isProgressBarModalOpen,
    setIsExitModalOpen,
  } = useByoContext();

  useEffect(() => {
    const showBrowserDefaultModal = e => {
      e.preventDefault();
      e.stopPropagation();
      e.returnValue = '';
    };

    /**
     * In byo flow, we don't want users to go back to previous steps if the previous products are added
     * so we will set up some limits for clicking on back button on browsers
     * 1. on BYO grids, when clicking on back button, we will show a exit confirmation modal, current page doesn't change
     * 2. on PDPs, we allow users to go back to BYO grids to browse before selecting, but if they added the current product to the
     *    set in this PDP, then they can't go back. */
    if (isFlowInitiated) {
      // handle pressing back button on browser
      if (isByoPage || isProgressBarModalOpen) {
        window.history.pushState(
          {
            as: router.asPath,
            options: { shallow: true },
            url: format({
              pathname: router.pathname,
              query: router.query,
            }),
          },
          null,
          null
        );
      }
      router.beforePopState(({ url, as, options }) => {
        if (isByoPage || isProgressBarModalOpen || isInterstitialFlow) {
          window.history.pushState({ url, as, options }, null, null);
        }
        const { root } = getSectionsAndQueryFromPath(as);
        // only allow the action of browser's back button when
        // - it's not loading (making api call to add item in set)
        // - it's going back to BYO grids from PDP
        // - current product is not added to set (progress bar modal is not open)
        if (
          !isByoPage &&
          root === 'byo' &&
          !isProgressBarModalOpen &&
          !isLoading
        ) {
          return true;
        }
        setIsExitModalOpen(true);
        dispatch(trackShowByoExitModal('Browser back button'));
        return false;
      });
      // handle reloading, show browser default prompt
      if (!isInterstitialFlow) {
        window.addEventListener('beforeunload', showBrowserDefaultModal);
      }
    } else {
      // handle redirect byo grids after reloading
      if (isByoPage) {
        let redirectUrl;
        if (section === 'sale') {
          redirectUrl = '/sale';
        } else {
          redirectUrl = subsection
            ? `/products/${section}/${subsection}`
            : `/products/${section}`;
        }
        Router.push(redirectUrl);
      }
    }

    return () => {
      window.removeEventListener('beforeunload', showBrowserDefaultModal);
    };
  }, [
    router,
    section,
    isByoPage,
    isFlowInitiated,
    isInterstitialFlow,
    setIsExitModalOpen,
    isProgressBarModalOpen,
    isLoading,
    subsection,
    dispatch,
  ]);

  return null;
}
