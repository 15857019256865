import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { setAnimationTimeout } from '../../utils/helpers';

/**
 * A component that delays the MouseEnter event for its child, so that it must
 * remain hovered for a certain amount of time before being activated.
 *
 * There is no wrapper element, the single child will be cloned with an
 * `onMouseEnter` and `onMouseLeave` props.
 */

function HoverDelay({ children, onEnter, timeout = 150, value }) {
  let cancelActivation = null;

  const clearActivationTimer = () => {
    if (cancelActivation) {
      cancelActivation();
      cancelActivation = null;
    }
  };

  const handleEnter = () => {
    if (cancelActivation) {
      return;
    }
    cancelActivation = setAnimationTimeout(() => {
      clearActivationTimer();
      onEnter(value);
    }, timeout);
  };

  const handleLeave = () => {
    clearActivationTimer();
  };

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      clearActivationTimer();
    };
  }, []);

  return React.cloneElement(children, {
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
  });
}

HoverDelay.propTypes = {
  children: PropTypes.node,
  onEnter: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  value: PropTypes.any,
};

export default HoverDelay;
