import { useCallback, useEffect, useRef, useState } from 'react';

import { useMembership } from '@techstyle/react-accounts';
import { parseDate, useRenderTime } from '@techstyle/redux-core';

export function useLeadAgeWithInThreshold(
  thresholdInMinutes: number,
  checkRealtime: boolean = false
) {
  const thresholdMiliseconds = thresholdInMinutes * 60 * 1000;
  const [isWithinThreshold, setIsWithinThreshold] = useState(false);
  const intervalRef = useRef(null);
  const { dateTimeAdded } = useMembership();
  const getTime = useRenderTime();

  const getThresholdState = useCallback(
    (currentTime: number) => {
      if (thresholdMiliseconds === null) {
        setIsWithinThreshold(false);
      } else {
        const memberJoinDate = parseDate(dateTimeAdded);
        const endDate = memberJoinDate.getTime() + thresholdMiliseconds;

        setIsWithinThreshold(endDate >= currentTime);
      }
    },
    [dateTimeAdded, thresholdMiliseconds]
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (typeof getTime === 'function') {
        const currentTime = getTime();
        getThresholdState(currentTime);
      }
    }, 1000);
    if (!checkRealtime) {
      clearInterval(intervalRef.current);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [checkRealtime, getThresholdState, getTime]);

  return isWithinThreshold;
}
