import { getSession } from '@techstyle/redux-core';

const { TRACK_FILTER_REVIEWS } = require('../../actions/tracking');
/**
 * Filter options tracking for reviews section of PDP
 */
const trackFilterReviewsEvents = ({ getContext, trackEvent }) => {
  const trackFilterReviews = trackEvent((action, prevState, nextState) => {
    const { cookies } = getContext();
    const { filterBy, sortOption } = action.payload;

    return {
      name: 'Filter Reviews',
      properties: {
        automated_test: Boolean(cookies.get('automated_test')),
        category: 'pdp',
        feature: 'react',
        filter: filterBy,
        loggedin_status: getSession(nextState).isLoggedIn,
        session_id: nextState.session.sessionId.toString(),
        sort_label: sortOption,
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
      },
    };
  });

  return { [TRACK_FILTER_REVIEWS]: trackFilterReviews };
};

export default trackFilterReviewsEvents;
