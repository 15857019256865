import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from './Loading';

export function mapStateToProps(state, ownProps) {
  // const { exampleState } = state;
  return {
    // exampleState
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // onExampleAction: exampleAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
