import React from 'react';

import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Link } from '@techstyle/next-routes';
import { FormattedMessage, injectIntl } from '@techstyle/react-intl';

import { v1, v2 } from '../../utils/themeVersioning';
import { Component as Badge } from '../Badge';

const StyledBadge = styled(Badge)`
  ${v1`
    color: ${({ theme }) => theme.colors.active};
  background-color: ${({ theme }) => theme.colors.lavender250};
  font-size: 10px;
  min-height: 20px;
  line-height: 20px;
  padding: 0 ${({ theme }) => theme.spacing.tiny}px;
  border-radius: 2px;
  `}

  ${v2`
    display: flex;
    padding: 2px 20px;
    margin: 0px ${({ theme }) => theme.spacing.tiny}px; 0px 0px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
    color: ${({ theme }) => theme.colors.gunmetal};
  `}
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.moderate}px;

  ${v1`
    color: ${props =>
      props.theme.context.inverse
        ? props.theme.colors.white
        : props.theme.colors.default};
  font-size: 16px;

  letter-spacing: 0.3px;

  &:active {
    color: ${props => props.theme.colors.active};
  }
    `}

  ${props =>
    props.theme.context.hoverMenu &&
    css`
      display: inline-flex;
      padding: 0 5px 0 0;

      ${v1`&:hover {
        padding: 0 0 0 5px;
      }
        font-size: 14px;
      transition-property: padding;
      transition-duration: 0.4s;
      transition-timing-function: ease-out;
        `}
    `};

  ${Badge.Wrapper} {
    margin-top: 2px;
  }
`;

const StyledLabelWrapper = styled.span`
  ${v2`
        ${props => props.theme.links.variants.navigationPrimary}
    color: ${props => props.theme.colors.gunmetal};
      `}
`;

class NavMenuSubCategoryLinkElement extends React.PureComponent {
  static propTypes = {
    gaLabel: PropTypes.string,
    intl: PropTypes.object,
    isNew: PropTypes.bool,
    isSale: PropTypes.bool,
    isResourceBundle: PropTypes.bool,
    url: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.string,
    theme: PropTypes.object,
  };

  render() {
    const {
      gaLabel,
      intl,
      isSale,
      isNew,
      isResourceBundle,
      label,
      url,
      theme,
    } = this.props;
    const linkProps = typeof url === 'string' ? { route: url } : url;

    const renderLabel = () => {
      if (isResourceBundle) {
        return (
          <StyledLabelWrapper>
            {intl.formatMessage({
              id: label,
              defaultMessage: label,
            })}
          </StyledLabelWrapper>
        );
      }

      return <StyledLabelWrapper>{label}</StyledLabelWrapper>;
    };

    const renderTags = () => (
      <>
        {isNew && (
          <StyledBadge>
            <FormattedMessage
              id="site_navigation.new_badge"
              defaultMessage="NEW"
            />
          </StyledBadge>
        )}
        {isSale && (
          <StyledBadge>
            <FormattedMessage
              id="site_navigation.sale_badge"
              defaultMessage="SALE"
            />
          </StyledBadge>
        )}
      </>
    );

    return (
      <Link passHref {...linkProps}>
        <StyledLink data-autotag={gaLabel}>
          {theme.themeVersion === 'v2' && renderTags()}
          {renderLabel() || FormattedMessage}
          {theme.themeVersion === 'v1' && renderTags()}
        </StyledLink>
      </Link>
    );
  }
}

export default injectIntl(withTheme(NavMenuSubCategoryLinkElement));
