import { combineReducers } from 'redux';

import addresses from './addresses';
import onlineCancel from './onlineCancel';
import orders from './orders';
import payment from './payment';
import returnsSettings from './returnsSettings';
import reviews from './reviews';
import waitlist from './waitlist';

export default combineReducers({
  addresses,
  orders,
  payment,
  returnsSettings,
  reviews,
  waitlist,
  onlineCancel,
});
