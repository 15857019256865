import React from 'react';

import Icon from 'react-icon-base';

type IconTypes =
  | 'caretLeft'
  | 'caretRight'
  | 'caretUp'
  | 'caretDown'
  | 'close'
  | 'x'
  | 'cart'
  | 'heart'
  | 'favorite'
  | 'burger'
  | 'arrowright'
  | 'tiktok'
  | 'youtube'
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'pinterest'
  | 'person'
  | 'star'
  | 'starOutline';

type Props = {
  size?: number | string;
  type: IconTypes;
};

const VIEWBOX = {
  caretLeft: '0 0 24 24',
  caretRight: '0 0 24 24',
  caretLUp: '0 0 24 24',
  caretDown: '0 0 24 24',
  close: '0 0 24 24',
  x: '0 0 24 24',
  cart: '0 0 24 24',
  heart: '0 0 24 24',
  burger: '0 0 24 24',
  arrowright: '0 0 24 24',
  tiktok: '0 0 24 24',
  youtube: '0 0 24 24',
  facebook: '0 0 24 24',
  instagram: '0 0 24 24',
  twitter: '0 0 24 24',
  pinterest: '0 0 24 24',
  person: '0 0 24 24',
  star: '0 0 24 24',
  starOutline: '0 0 24 24',
};

const EMPTY_PROPS = {};

const ADDITIONAL_PROPS = {
  starOutline: {
    fill: 'none',
  },
};

const Path = ({ type }: Props) => {
  switch (type) {
    case 'caretLeft':
      return (
        <path
          id="Vector"
          d="M14 17.3078L8.69225 12L14 6.69226L14.7078 7.40001L10.1078 12L14.7078 16.6L14 17.3078Z"
          fill="currentColor"
          stroke="currentColor"
        />
      );
    case 'caretRight':
      return (
        <path
          id="Vector"
          d="M13.2922 12L8.69225 7.40001L9.4 6.69226L14.7077 12L9.4 17.3078L8.69225 16.6L13.2922 12Z"
          fill="currentColor"
          stroke="currentColor"
        />
      );
    case 'caretUp':
      return (
        <path
          d="M6.39225 14.3L11.7 8.99225L17.0078 14.3L16.3 15.0077L11.7 10.4077L7.1 15.0077L6.39225 14.3Z"
          fill="currentColor"
          stroke="currentColor"
        />
      );
    case 'caretDown':
      return (
        <path
          d="M17.0078 9.7L11.7 15.0078L6.39225 9.7L7.1 8.99225L11.7 13.5923L16.3 8.99225L17.0078 9.7Z"
          fill="currentColor"
          stroke="currentColor"
        />
      );
    case 'close':
    case 'x':
      return (
        <path
          d="M11.6459 11.6463L6.39949 6.39989L6.40013 6.39925L11.6466 11.6457L12.0001 11.9992L12.3537 11.6457L17.6001 6.39925L17.6008 6.39989L12.3543 11.6463L12.0008 11.9999L12.3543 12.3534L17.6008 17.5999L17.6001 17.6005L12.3537 12.3541L12.0001 12.0005L11.6466 12.3541L6.40013 17.6005L6.39949 17.5999L11.6459 12.3534L11.9995 11.9999L11.6459 11.6463Z"
          fill="currentColor"
          stroke="currentColor"
        />
      );
    case 'cart':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3501 4.81233C10.7877 4.38367 11.3812 4.14286 12 4.14286C12.6188 4.14286 13.2123 4.38367 13.6499 4.81233C14.0875 5.24098 14.3333 5.82236 14.3333 6.42857L14.3333 7.57143H9.66667V6.42857C9.66667 5.82236 9.9125 5.24098 10.3501 4.81233ZM15.5 7.57143H19V19H5V7.57143H8.5V6.42857C8.5 5.51926 8.86875 4.64719 9.52513 4.0042C10.1815 3.36122 11.0717 3 12 3C12.9283 3 13.8185 3.36122 14.4749 4.00421C15.1313 4.64719 15.5 5.51926 15.5 6.42857L15.5 7.57143ZM14.3333 8.61039V11H15.5V8.61039H17.9231V17.961H6.07692V8.61039H8.5V11H9.66667V8.61039H14.3333Z"
          fill="currentColor
          "
        />
      );
    case 'heart':
    case 'favorite':
      return (
        <path
          d="M12 19.6537L11.2423 18.9692C9.61025 17.4782 8.26025 16.2019 7.19225 15.1404C6.12442 14.0787 5.2815 13.1425 4.6635 12.3317C4.0455 11.5208 3.61375 10.7872 3.36825 10.1307C3.12275 9.47432 3 8.81407 3 8.1499C3 6.87807 3.43208 5.81015 4.29625 4.94615C5.16025 4.08199 6.22817 3.6499 7.5 3.6499C8.3795 3.6499 9.2045 3.8749 9.975 4.3249C10.7455 4.7749 11.4205 5.4294 12 6.2884C12.5795 5.4294 13.2545 4.7749 14.025 4.3249C14.7955 3.8749 15.6205 3.6499 16.5 3.6499C17.7718 3.6499 18.8398 4.08199 19.7038 4.94615C20.5679 5.81015 21 6.87807 21 8.1499C21 8.81407 20.8773 9.47432 20.6318 10.1307C20.3863 10.7872 19.9545 11.5208 19.3365 12.3317C18.7185 13.1425 17.8787 14.0787 16.8173 15.1404C15.7558 16.2019 14.4026 17.4782 12.7578 18.9692L12 19.6537ZM12 18.2999C13.6 16.8537 14.9167 15.615 15.95 14.5837C16.9833 13.5522 17.8 12.6572 18.4 11.8989C19 11.1406 19.4167 10.4688 19.65 9.88365C19.8833 9.29832 20 8.7204 20 8.1499C20 7.1499 19.6667 6.31657 19 5.6499C18.3333 4.98324 17.5 4.6499 16.5 4.6499C15.7038 4.6499 14.9692 4.87715 14.2962 5.33165C13.6231 5.78615 13.0205 6.4704 12.4885 7.3844H11.5115C10.9667 6.45757 10.3609 5.77007 9.69425 5.3219C9.02758 4.8739 8.29617 4.6499 7.5 4.6499C6.51283 4.6499 5.68267 4.98324 5.0095 5.6499C4.3365 6.31657 4 7.1499 4 8.1499C4 8.7204 4.11667 9.29832 4.35 9.88365C4.58333 10.4688 5 11.1406 5.6 11.8989C6.2 12.6572 7.01667 13.5489 8.05 14.5739C9.08333 15.5989 10.4 16.8409 12 18.2999Z"
          fill="currentColor"
        />
      );
    case 'burger':
      return (
        <path
          d="M4 17.2692V16.2692H20V17.2692H4ZM4 12.5V11.5H20V12.5H4ZM4 7.73071V6.73071H20V7.73071H4Z"
          fill="currentColor"
        />
      );
    case 'arrowright':
      return (
        <path
          d="M17.0788 12.5H5V11.5H17.0788L11.2865 5.70775L12 5L19 12L12 19L11.2865 18.2923L17.0788 12.5Z"
          fill="currentColor"
        />
      );
    case 'tiktok':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M20.0413 10.3828H20.0054C18.3541 10.3828 16.8463 9.89062 15.6258 9.01172V15.3047C15.6258 18.4688 13.0052 21 9.77427 21C6.54338 21 3.95866 18.4688 3.95866 15.3047C3.95866 12.1406 6.54338 9.57422 9.77427 9.57422C10.0615 9.57422 10.3128 9.60938 10.5999 9.64453V12.8086C10.3128 12.7031 10.0615 12.668 9.77427 12.668C8.30242 12.668 7.08186 13.8633 7.08186 15.3047C7.08186 16.7461 8.30242 17.9414 9.77427 17.9414C11.2461 17.9414 12.4667 16.7461 12.4667 15.3047V3H15.6258C15.6258 3.03516 15.6258 3.03516 15.6258 3.07031C15.6258 3.31641 15.6258 3.5625 15.6976 3.80859C15.913 4.96875 16.6309 5.98828 17.6361 6.62109C18.3182 7.07812 19.1439 7.32422 20.0054 7.32422C20.0054 7.32422 20.0054 7.32422 20.0413 7.32422V10.3828Z"
          fill="currentColor"
        />
      );
    case 'youtube':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M21.3875 7.39453C21.8183 8.87109 21.8183 12.0352 21.8183 12.0352C21.8183 12.0352 21.8183 15.1641 21.3875 16.6758C21.1721 17.5195 20.4901 18.1523 19.6644 18.3633C18.1207 18.75 12.018 18.75 12.018 18.75C12.018 18.75 5.87925 18.75 4.3356 18.3633C3.50993 18.1523 2.82785 17.5195 2.61246 16.6758C2.18167 15.1641 2.18167 12.0352 2.18167 12.0352C2.18167 12.0352 2.18167 8.87109 2.61246 7.39453C2.82785 6.55078 3.50993 5.88281 4.3356 5.67188C5.87925 5.25 12.018 5.25 12.018 5.25C12.018 5.25 18.1207 5.25 19.6644 5.67188C20.4901 5.88281 21.1721 6.55078 21.3875 7.39453ZM10.0076 14.8828L15.1052 12.0352L10.0076 9.1875V14.8828Z"
          fill="currentColor"
        />
      );
    case 'facebook':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M20.9029 12.0527C20.9029 16.4121 17.6361 20.0332 13.3642 20.666V14.584H15.4463L15.8412 12.0527H13.3642V10.4355C13.3642 9.73242 13.7231 9.06445 14.836 9.06445H15.9489V6.91992C15.9489 6.91992 14.9437 6.74414 13.9385 6.74414C11.9282 6.74414 10.5999 7.97461 10.5999 10.1543V12.0527H8.33832V14.584H10.5999V20.666C6.32799 20.0332 3.09709 16.4121 3.09709 12.0527C3.09709 7.23633 7.08186 3.33398 12 3.33398C16.9181 3.33398 20.9029 7.23633 20.9029 12.0527Z"
          fill="currentColor"
        />
      );
    case 'instagram':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M12.0183 7.93945C14.3255 7.93945 16.2299 9.76758 16.2299 11.9824C16.2299 14.2324 14.3255 16.0254 12.0183 16.0254C9.67445 16.0254 7.80668 14.2324 7.80668 11.9824C7.80668 9.76758 9.67445 7.93945 12.0183 7.93945ZM12.0183 14.6191C13.5198 14.6191 14.7284 13.459 14.7284 11.9824C14.7284 10.541 13.5198 9.38086 12.0183 9.38086C10.4801 9.38086 9.27159 10.541 9.27159 11.9824C9.27159 13.459 10.5168 14.6191 12.0183 14.6191ZM17.3652 7.79883C17.3652 8.32617 16.9258 8.74805 16.3764 8.74805C15.8271 8.74805 15.3876 8.32617 15.3876 7.79883C15.3876 7.27148 15.8271 6.84961 16.3764 6.84961C16.9258 6.84961 17.3652 7.27148 17.3652 7.79883ZM20.1486 8.74805C20.2218 10.0488 20.2218 13.9512 20.1486 15.252C20.0753 16.5176 19.7824 17.6074 18.8302 18.5566C17.878 19.4707 16.706 19.752 15.3876 19.8223C14.0326 19.8926 9.96743 19.8926 8.61238 19.8223C7.29396 19.752 6.15865 19.4707 5.16984 18.5566C4.21764 17.6074 3.92466 16.5176 3.85141 15.252C3.77817 13.9512 3.77817 10.0488 3.85141 8.74805C3.92466 7.48242 4.21764 6.35742 5.16984 5.44336C6.15865 4.5293 7.29396 4.24805 8.61238 4.17773C9.96743 4.10742 14.0326 4.10742 15.3876 4.17773C16.706 4.24805 17.878 4.5293 18.8302 5.44336C19.7824 6.35742 20.0753 7.48242 20.1486 8.74805ZM18.3907 16.623C18.8302 15.6035 18.7203 13.1426 18.7203 11.9824C18.7203 10.8574 18.8302 8.39648 18.3907 7.3418C18.0977 6.67383 17.5484 6.11133 16.8525 5.86523C15.7538 5.44336 13.1902 5.54883 12.0183 5.54883C10.8098 5.54883 8.24615 5.44336 7.18409 5.86523C6.45164 6.14648 5.90229 6.67383 5.60931 7.3418C5.16984 8.39648 5.2797 10.8574 5.2797 11.9824C5.2797 13.1426 5.16984 15.6035 5.60931 16.623C5.90229 17.3262 6.45164 17.8535 7.18409 18.1348C8.24615 18.5566 10.8098 18.4512 12.0183 18.4512C13.1902 18.4512 15.7538 18.5566 16.8525 18.1348C17.5484 17.8535 18.1343 17.3262 18.3907 16.623Z"
          fill="currentColor"
        />
      );
    case 'twitter':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M19.1543 8.32617C19.1543 8.50195 19.1543 8.64258 19.1543 8.81836C19.1543 13.7051 15.4629 19.2949 8.67773 19.2949C6.56836 19.2949 4.63477 18.6973 3.01758 17.6426C3.29883 17.6777 3.58008 17.7129 3.89648 17.7129C5.61914 17.7129 7.20117 17.1152 8.4668 16.1309C6.84961 16.0957 5.47852 15.041 5.02148 13.5645C5.26758 13.5996 5.47852 13.6348 5.72461 13.6348C6.04102 13.6348 6.39258 13.5645 6.67383 13.4941C4.98633 13.1426 3.7207 11.666 3.7207 9.87305V9.83789C4.21289 10.1191 4.81055 10.2598 5.4082 10.2949C4.38867 9.62695 3.75586 8.50195 3.75586 7.23633C3.75586 6.5332 3.93164 5.90039 4.24805 5.37305C6.07617 7.58789 8.81836 9.06445 11.877 9.24023C11.8066 8.95898 11.7715 8.67773 11.7715 8.39648C11.7715 6.35742 13.4238 4.70508 15.4629 4.70508C16.5176 4.70508 17.4668 5.12695 18.1699 5.86523C18.9785 5.68945 19.7871 5.37305 20.4902 4.95117C20.209 5.83008 19.6465 6.5332 18.873 6.99023C19.6113 6.91992 20.3496 6.70898 20.9824 6.42773C20.4902 7.16602 19.8574 7.79883 19.1543 8.32617Z"
          fill="currentColor"
        />
      );
    case 'pinterest':
      return (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M20.9029 12C20.9029 16.8164 16.9181 20.7188 12 20.7188C11.0666 20.7188 10.1692 20.6133 9.34349 20.332C9.70247 19.7695 10.241 18.8203 10.4563 18.0469C10.564 17.6602 10.9948 15.9727 10.9948 15.9727C11.282 16.5352 12.1436 16.9922 13.0411 16.9922C15.7335 16.9922 17.672 14.5664 17.672 11.5781C17.672 8.69531 15.2668 6.51562 12.1795 6.51562C8.33832 6.51562 6.29209 9.04688 6.29209 11.7891C6.29209 13.0898 6.97416 14.6719 8.08703 15.1992C8.26652 15.2695 8.37422 15.2344 8.41012 15.0586C8.41012 14.9531 8.58961 14.3555 8.66141 14.0742C8.66141 14.0039 8.66141 13.8984 8.58961 13.8281C8.23062 13.4062 7.94343 12.5977 7.94343 11.8242C7.94343 9.92578 9.41528 8.0625 11.9641 8.0625C14.118 8.0625 15.6617 9.50391 15.6617 11.6133C15.6617 13.9688 14.4411 15.5859 12.8616 15.5859C12 15.5859 11.3538 14.8828 11.5333 14.0391C11.7846 12.9844 12.2872 11.8594 12.2872 11.1211C12.2872 10.4531 11.9282 9.89062 11.1743 9.89062C10.2769 9.89062 9.55888 10.8047 9.55888 12C9.55888 12.7734 9.81017 13.3008 9.81017 13.3008C9.81017 13.3008 8.9486 16.957 8.7691 17.625C8.58961 18.3984 8.66141 19.4531 8.73321 20.1211C5.43051 18.8555 3.09709 15.7266 3.09709 12C3.09709 7.18359 7.08186 3.28125 12 3.28125C16.9181 3.28125 20.9029 7.18359 20.9029 12Z"
          fill="currentColor"
        />
      );
    case 'person':
      return (
        <path
          fill="currentColor"
          d="M17.65 5C18.3953 5 19 5.58789 19 6.3125V17.6875C19 18.4121 18.3953 19 17.65 19H6.35C5.60469 19 5 18.4121 5 17.6875V6.3125C5 5.58789 5.60469 5 6.35 5H17.65ZM17.65 17.6875V6.3125H6.35V17.6875H17.65ZM12 12C11.0072 12 10.2 11.2152 10.2 10.25C10.2 9.28477 11.0072 8.5 12 8.5C12.9928 8.5 13.8 9.28477 13.8 10.25C13.8 11.2152 12.9928 12 12 12ZM9.48001 15.5C9.13125 15.5 8.85 15.2649 8.85 14.975V14.45C8.85 13.5805 9.69655 12.875 10.74 12.875H10.8806C11.2238 13.0144 11.6034 13.0938 12 13.0938C12.3966 13.0938 12.7734 13.0144 13.1194 12.875H13.26C14.3034 12.875 15.15 13.5805 15.15 14.45V14.975C15.15 15.2649 14.8687 15.5 14.52 15.5H9.48001Z"
        />
      );
    case 'star':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5223 1.54573C11.6675 1.07591 12.3325 1.07591 12.4777 1.54572L14.7241 8.81443C14.7888 9.02392 14.9825 9.16679 15.2018 9.16679H22.5219C23.0002 9.16679 23.2055 9.77377 22.8255 10.0641L16.8601 14.6215C16.6936 14.7487 16.6241 14.9662 16.686 15.1664L18.9541 22.5053C19.0978 22.9704 18.5597 23.3458 18.1728 23.0503L12.3035 18.5665C12.1243 18.4296 11.8757 18.4296 11.6965 18.5665L5.82609 23.0505C5.43923 23.346 4.90114 22.9706 5.04487 22.5055L7.31303 15.1664C7.37489 14.9662 7.30538 14.7487 7.13889 14.6215L1.17435 10.0641C0.794339 9.77373 0.999677 9.16679 1.47792 9.16679H8.79819C9.01747 9.16679 9.21115 9.02392 9.2759 8.81443L11.5223 1.54573Z"
          fill="currentColor"
        />
      );
    case 'starOutline':
      return (
        <path
          d="M12 1.69336L14.2464 8.96206C14.3759 9.38105 14.7633 9.66679 15.2018 9.66679L22.5219 9.66679L16.5566 14.2242C16.2236 14.4786 16.0845 14.9137 16.2083 15.3141L18.4764 22.653L12.607 18.1692C12.2487 17.8954 11.7514 17.8954 11.393 18.1691L5.52258 22.6531L7.79073 15.314C7.91447 14.9137 7.77544 14.4786 7.44246 14.2242L1.47792 9.66679L8.79819 9.66679C9.23674 9.66679 9.62411 9.38106 9.75361 8.96206L12 1.69336Z"
          stroke="currentColor"
        />
      );
    default:
      return null;
  }
};

const NewThemeIcon = (props: Props) => {
  const { type, ...otherProps } = props;

  const viewBox = VIEWBOX[type];
  const additionalProps = ADDITIONAL_PROPS[type] ?? EMPTY_PROPS;

  return (
    <Icon viewBox={viewBox} {...otherProps} {...additionalProps}>
      <Path {...props} />
    </Icon>
  );
};
export default NewThemeIcon;
