import { connect } from 'react-redux';

import { injectIntl } from '@techstyle/react-intl';

import { trackNavItemClick } from '../../actions/navigation';
import { getFeaturedLinks } from '../../utils/selectors';

import NavMenuSubCategories from './NavMenuSubCategories';

function mapStateToProps(state, ownProps) {
  return {
    ...getFeaturedLinks(state, ownProps),
  };
}

const mapDispatchToProps = {
  trackNavItemClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NavMenuSubCategories));
