import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormattedMessage } from '@techstyle/react-intl';

import { mobile } from '../../styles';
import { getRegion } from '../../utils/selectors';
import { v1, v2 } from '../../utils/themeVersioning';

const locationLowStockIndicator = config.get(
  'public.quantityRules.locationLowStockIndicator.product'
);
const locationLowStockIndicatorMasterProduct = config.get(
  'public.quantityRules.locationLowStockIndicator.masterProduct'
);

const CRITICAL_STOCK = 'CRITICAL_STOCK';
const HIGH_IN_STOCK = 'HIGH_IN_STOCK';
const LOW_IN_SIZE_STOCK = 'LOW_IN_SIZE_STOCK';
const LOW_IN_STOCK = 'LOW_IN_STOCK';
const OUT_OF_STOCK = 'OUT_OF_STOCK';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  ${v1`
    background: ${props =>
      props.userSizes ? props.theme.colors.green200 : props.theme.colors.white};
    color: ${props => props.theme.colors.green700};
    font-size: 12px;
    line-height: ${props => (props.userSizes ? 1.2 : 1.5)};
    min-height: ${props => props.theme.spacing.moderate}px;
    padding: ${props => (props.userSizes ? '3px' : '0')}
      ${props => props.theme.spacing.tiny}px;
  `}
  ${v2`
    padding: 2px 8px;
    background-color: ${({ theme }) => theme.colors.lavender200};
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.paragraph.variants.paragraph4Uppercase.textStyles}
  `}

  ${mobile`
    max-width: ${props => (props.userSizes ? '62%' : '100%')};
  `}
`;

function LowStockFlag({
  className,
  children,
  isMasterProduct,
  quantity,
  selectedSize,
  sizeQuantity,
}) {
  const warehouseLocation = useSelector(getRegion);

  let lowStockRules;
  if (isMasterProduct) {
    lowStockRules = locationLowStockIndicatorMasterProduct[warehouseLocation];
  } else {
    lowStockRules = locationLowStockIndicator[warehouseLocation];
  }

  const getQuantityStatus = () => {
    if (quantity <= 0) {
      return OUT_OF_STOCK;
    } else if (
      sizeQuantity != null &&
      sizeQuantity > 0 &&
      sizeQuantity <= lowStockRules.lowSizeStockMax
    ) {
      return LOW_IN_SIZE_STOCK;
    } else if (quantity <= lowStockRules.criticalStockMax) {
      return CRITICAL_STOCK;
    } else if (quantity <= lowStockRules.lowStockMax) {
      return LOW_IN_STOCK;
    }
    return HIGH_IN_STOCK;
  };

  const renderLabel = status => {
    switch (status) {
      case OUT_OF_STOCK:
        return (
          <FormattedMessage
            id="global_checkout.out_of_stock_message"
            defaultMessage="Out of Stock"
          />
        );

      case LOW_IN_SIZE_STOCK:
        return (
          <FormattedMessage
            id="global_checkout.low_size_stock"
            defaultMessage="{size} - only {quantity} left!"
            values={{
              quantity: sizeQuantity,
              size: selectedSize,
            }}
          />
        );

      case CRITICAL_STOCK:
      case LOW_IN_STOCK:
        return (
          <FormattedMessage
            id="global_checkout.almost_gone"
            defaultMessage="Almost Gone!"
          />
        );

      default:
        return '';
    }
  };

  // Render functions can use this to decide what to render for each state.
  const status = getQuantityStatus();
  const label = renderLabel(status);
  const lowInSizeStockStatus = LOW_IN_SIZE_STOCK === status;

  // Custom render function.
  if (children) {
    return children({ quantity, status, label });
  }

  return label ? (
    <Wrapper className={className} userSizes={lowInSizeStockStatus}>
      {label}
    </Wrapper>
  ) : null;
}

LowStockFlag.CRITICAL_STOCK = CRITICAL_STOCK;
LowStockFlag.HIGH_IN_STOCK = HIGH_IN_STOCK;
LowStockFlag.LOW_IN_SIZE_STOCK = LOW_IN_SIZE_STOCK;
LowStockFlag.LOW_IN_STOCK = LOW_IN_STOCK;
LowStockFlag.OUT_OF_STOCK = OUT_OF_STOCK;

LowStockFlag.propTypes = {
  /**
   * A custom render function that accepts the status code, quantity, and
   * resulting label in an argument.
   */
  children: PropTypes.func,
  className: PropTypes.string,
  /**
   * The product quantity.
   */
  quantity: PropTypes.number.isRequired,
  selectedSize: PropTypes.string,
  /**
   * The quantity for user product sizes
   */
  sizeQuantity: PropTypes.number,
};

export default LowStockFlag;
