import { createGlobalStyle } from 'styled-components';

// general styling for afterpay messages
// override the modal background color from mint to white
// adjust the padding of the modal on iphone 5
export const AfterpayStyles = createGlobalStyle`
  .afterpay-paragraph {
    font-size: 12px;
    font-weight: 300;
    color: ${props => props.theme.colors.default};
  }
  .afterpay-logo,
  .Clearpay-logo {
    width: 62px;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
  .afterpay-link {
    line-height: 12px;
    vertical-align: middle;
  }
  #afterpay-modal-overlay #afterpay-modal-modal {
    background: ${props => props.theme.colors.white} !important;
    @media (max-width: ${props => props.theme.breakpoints.large}px){
      width: 100% !important;
      height: 100% !important;
      max-width: 100% !important;
    }
  }
  #afterpay-modal-overlay .afterpay_modal_wrapper * {
    background: ${props => props.theme.colors.white} !important
  }
  @media (max-width: 320px) {
    #afterpay-modal-overlay .afterpay_modal_wrapper .grid-container {
      padding: 4px !important
    }
  }`;
