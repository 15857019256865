import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTrackPromotionViewed } from '../../utils/useTrackPromotionViewed';

const EnterViewportElement = styled.span`
  position: absolute;
  top: 0;
  height: 1px;
  width: 1px;
`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const EnterViewportBase = ({
  children,
  once = false,
  onEnterViewport,
  threshold = 0.01,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const previousIsVisible = usePrevious(isVisible);
  const onEnterViewportRef = useRef(onEnterViewport);

  function onChange(observer, unobserve) {
    const { isIntersecting } = observer;

    if (isIntersecting && !isVisible) {
      setIsVisible(true);
      once && unobserve();
    } else if (!isIntersecting) {
      setIsVisible(false);
    }
  }

  useLayoutEffect(() => {
    onEnterViewportRef.current = onEnterViewport;
  }, [onEnterViewport]);

  useEffect(() => {
    if (onEnterViewportRef.current && isVisible && !previousIsVisible) {
      onEnterViewportRef.current();
    }
  }, [isVisible, previousIsVisible]);

  return (
    <Observer onChange={onChange} threshold={threshold}>
      {children || <EnterViewportElement />}
    </Observer>
  );
};

const EnterViewport = props => {
  if (!props.onEnterViewport) {
    return props.children || null;
  }

  return <EnterViewportBase {...props} />;
};

export const TrackPromotionEnterViewport = ({ asset, ...props }) => {
  const dispatchTrackPromotionViewed = useTrackPromotionViewed();

  if (!asset) {
    return props.children || null;
  }

  return (
    <EnterViewportBase
      onEnterViewport={() => dispatchTrackPromotionViewed(asset)}
      {...props}
    />
  );
};

TrackPromotionEnterViewport.propTypes = {
  asset: PropTypes.object,
  children: PropTypes.node,
  onEnterViewport: PropTypes.func,
};

EnterViewportBase.propTypes = {
  onEnterViewport: PropTypes.func,
  children: PropTypes.node,
  once: PropTypes.bool,
};

EnterViewport.propTypes = EnterViewportBase.propTypes;

export default EnterViewport;
