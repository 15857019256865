import { defineMessages } from '@techstyle/react-intl';

import { fabrics } from './fabrics';

export const mapColorsToTags = colorQuery => {
  const newQuery = new Set();
  colorQuery.split(',').map((color, index) => {
    switch (color.toLowerCase()) {
      case 'pearl':
      case 'bare':
        newQuery.add('5415');
        break;
      case 'cherry blossom':
      case 'rose quartz':
      case 'lollipop':
      case 'pink af':
        newQuery.add('5412');
        break;
      case 'honey':
      case 'dessert':
      case 'mocha':
      case 'brown sugar':
      case 'tobacco':
      case 'truffle':
      case 'spiced':
      case 'cocoa':
        newQuery.add('5404');
        break;
      case 'caviar':
        newQuery.add('5340');
        break;
      case 'bay':
      case 'mint':
      case 'emerald':
        newQuery.add('5406');
        break;
      case 'navy':
      case 'electric blue':
      case 'cold':
        newQuery.add('5405');
        break;
      case 'candy apple red':
        newQuery.add('5414');
        break;
      case 'highlighter':
        newQuery.add('5416');
        break;
      case 'fairy dust':
      case 'amethyst':
        newQuery.add('5413');
        break;
      case 'dusty peach':
        newQuery.push('5410');
        break;
      case 'emerald tiger':
      case 'green leopard':
        newQuery.add('5406');
        newQuery.add('5409');
        break;
      case 'desert flower':
        newQuery.add('5413');
        newQuery.add('5409');
        break;
      case 'pink tiger':
        newQuery.add('5412');
        newQuery.add('5409');
        break;
      default:
        break;
    }
  });
  return Array.from(newQuery).join(',');
};

export const defaultColors = defineMessages({
  Black: {
    id: 'site_product_grid.color_black',
    defaultMessage: 'Black',
  },
  Blue: {
    id: 'site_product_grid.color_blue',
    defaultMessage: 'Blue',
  },
  Brown: {
    id: 'site_product_grid.color_brown_nude',
    defaultMessage: 'Brown/Nude',
  },
  Green: {
    id: 'site_product_grid.color_green',
    defaultMessage: 'Green',
  },
  Grey: {
    id: 'site_product_grid.color_grey',
    defaultMessage: 'Grey',
  },
  Multi: {
    id: 'site_product_grid.color_multi',
    defaultMessage: 'Multi',
  },
  Orange: {
    id: 'site_product_grid.color_orange',
    defaultMessage: 'Orange',
  },
  Pink: {
    id: 'site_product_grid.color_pink',
    defaultMessage: 'Pink',
  },
  Purple: {
    id: 'site_product_grid.color_purple',
    defaultMessage: 'Purple',
  },
  Red: {
    id: 'site_product_grid.color_red',
    defaultMessage: 'Red',
  },
  White: {
    id: 'site_product_grid.color_white',
    defaultMessage: 'White',
  },
  Yellow: {
    id: 'site_product_grid.color_yellow',
    defaultMessage: 'Yellow',
  },
  Chiffon: {
    id: 'site_product_grid.swatch_chiffon',
    defaultMessage: 'Chiffon',
  },
  Cotton: {
    id: 'site_product_grid.swatch_cotton',
    defaultMessage: 'Cotton',
  },
  Embroidered: {
    id: 'site_product_grid.swatch_embroidered',
    defaultMessage: 'Embroidered',
  },
  'Faux Leather': {
    id: 'site_product_grid.swatch_faux_leather',
    defaultMessage: 'Faux Leather',
  },
  Knit: {
    id: 'site_product_grid.swatch_knit',
    defaultMessage: 'Knit',
  },
  Lace: {
    id: 'site_product_grid.swatch_lace',
    defaultMessage: 'Lace',
  },
  Marabou: {
    id: 'site_product_grid.swatch_marabou',
    defaultMessage: 'Marabou',
  },
  Mesh: {
    id: 'site_product_grid.swatch_mesh',
    defaultMessage: 'Mesh',
  },
  Microfiber: {
    id: 'site_product_grid.swatch_microfiber',
    defaultMessage: 'Microfiber',
  },
  Satin: {
    id: 'site_product_grid.swatch_satin',
    defaultMessage: 'Satin',
  },
  Velvet: {
    id: 'site_product_grid.swatch_velvet',
    defaultMessage: 'Velvet',
  },
});

const fabricValues = fabrics.reduce(
  (accum, fabric) =>
    (accum = { ...accum, [fabric.label]: { value: fabric.value } }),
  {}
);

export const colorValues = {
  Black: {
    value: '#000000',
  },
  Blue: {
    value: '#2950c0',
  },
  Brown: {
    value: '#ba9983',
  },
  Green: {
    value: '#46d060',
  },
  Grey: {
    value: '#b7b6b6',
  },
  Multi: {
    value: '/static/images/taste-the-rainbow.jpg',
  },
  Orange: {
    value: '#ff9567',
  },
  Pink: {
    value: '#fdc8e5',
  },
  Purple: {
    value: '#b9ace8',
  },
  Red: {
    value: '#fd3e3e',
  },
  White: {
    value: '#ffffff',
  },
  Yellow: {
    value: '#fff156',
  },
  Nude: {
    value: '#ba9983',
  },
  ...fabricValues,
};
