export const LOAD_ACCOUNT_PAYMENT_METHODS_REQUEST =
  'LOAD_ACCOUNT_PAYMENT_METHODS_REQUEST';
export const LOAD_ACCOUNT_PAYMENT_METHODS_SUCCESS =
  'LOAD_ACCOUNT_PAYMENT_METHODS_SUCCESS';
export const LOAD_ACCOUNT_PAYMENT_METHODS_FAILURE =
  'LOAD_ACCOUNT_PAYMENT_METHODS_FAILURE';

export const ADD_ACCOUNT_PAYMENT_METHOD_REQUEST =
  'ADD_ACCOUNT_PAYMENT_METHOD_REQUEST';
export const ADD_ACCOUNT_PAYMENT_METHOD_SUCCESS =
  'ADD_ACCOUNT_PAYMENT_METHOD_SUCCESS';
export const ADD_ACCOUNT_PAYMENT_METHOD_FAILURE =
  'ADD_ACCOUNT_PAYMENT_METHOD_FAILURE';

export function loadAccountPaymentMethods() {
  return (dispatch, getState) => {
    const { account } = getState();
    if (!account.payment.networkStatus.isLoading) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/payments',
          method: 'GET',
          actions: [
            LOAD_ACCOUNT_PAYMENT_METHODS_REQUEST,
            LOAD_ACCOUNT_PAYMENT_METHODS_SUCCESS,
            LOAD_ACCOUNT_PAYMENT_METHODS_FAILURE,
          ],
        },
      });
    }
  };
}

// NOTE: `paymentType` is neither a provided nor a payment method, it's a
// confusing mix of either...
export function addAccountPaymentMethod(paymentType, params) {
  let endpoint = 'accounts/me/payments';
  if (paymentType !== 'vantiv') {
    endpoint += `/${paymentType}`;
  }
  return {
    bentoApi: {
      endpoint,
      method: 'POST',
      actions: [
        ADD_ACCOUNT_PAYMENT_METHOD_REQUEST,
        ADD_ACCOUNT_PAYMENT_METHOD_SUCCESS,
        ADD_ACCOUNT_PAYMENT_METHOD_FAILURE,
      ],
      body: JSON.stringify(params),
    },
  };
}
