export const TRACK_MONTHLY_MEMBERSHIP_REQUEST =
  'TRACK_MONTHLY_MEMBERSHIP_REQUEST';

export const TRACK_MEMBERSHIP_SKIP_ACTIONS_REQUEST =
  'TRACK_MEMBERSHIP_SKIP_ACTIONS_REQUEST';

export const TRACK_MEMBERSHIP_CANCELLATION_CONFIRMATION =
  'TRACK_MEMBERSHIP_CANCELLATION_CONFIRMATION';

export const TRACK_MEMBERSHIP_INACTIVE_SKIP = 'TRACK_MEMBERSHIP_INACTIVE_SKIP';
export const TRACK_MEMBERSHIP_ACTIVE_SKIP = 'TRACK_MEMBERSHIP_ACTIVE_SKIP';
export const TRACK_MEMBERSHIP_ACTIVE_SKIP_ACTIONS =
  'TRACK_MEMBERSHIP_ACTIVE_SKIP_ACTIONS';

export const TRACK_MEMBERSHIP_LEARN_MORE = 'TRACK_MEMBERSHIP_LEARN_MORE';

export const TRACK_EMP_INFO_DRAWER_OPEN = 'TRACK_EMP_INFO_DRAWER_OPEN';
export const TRACK_EMP_INFO_DRAWER_CTAS = 'TRACK_EMP_INFO_DRAWER_CTAS';
export const TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_OPEN =
  'TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_OPEN';
export const TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_MORE =
  'TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_MORE';

export const TRACK_MEMBERSHIP_SKIP_STEP = 'TRACK_MEMBERSHIP_SKIP_STEP';

export const TRACK_EMP_NEW_TOKEN_MODAL_CTA_CLICK =
  'TRACK_EMP_NEW_TOKEN_MODAL_CTA_CLICK';

export function trackMonthlyMembershipPage(eventLabel, eventCategory) {
  return {
    type: TRACK_MONTHLY_MEMBERSHIP_REQUEST,
    eventLabel,
    eventCategory,
  };
}

export function trackMembershipSkipActions(eventLabel) {
  return {
    type: TRACK_MEMBERSHIP_SKIP_ACTIONS_REQUEST,
    eventLabel,
  };
}

export function trackMembershipCancellationConfirmation(
  isCancellationConfirmed
) {
  return {
    type: TRACK_MEMBERSHIP_CANCELLATION_CONFIRMATION,
    isCancellationConfirmed,
  };
}

export function trackMembershipInactiveSkip() {
  return {
    type: TRACK_MEMBERSHIP_INACTIVE_SKIP,
  };
}

export function trackMembershipActiveSkip() {
  return {
    type: TRACK_MEMBERSHIP_ACTIVE_SKIP,
  };
}

export function trackMembershipActiveSkipActions(eventLabel) {
  return {
    type: TRACK_MEMBERSHIP_ACTIVE_SKIP_ACTIONS,
    eventLabel,
  };
}

export function trackMembershipLearnMore(location) {
  return {
    type: TRACK_MEMBERSHIP_LEARN_MORE,
    location,
  };
}

export function trackEmpInfoDrawerOpen() {
  return {
    type: TRACK_EMP_INFO_DRAWER_OPEN,
  };
}

export function trackEmpInfoDrawerCtas(ctaName) {
  return {
    type: TRACK_EMP_INFO_DRAWER_CTAS,
    eventAction: 'Shopping CTA',
    eventLabel: ctaName,
  };
}

export function trackEmpInfoDrawerDiscoverPerksOpen() {
  return {
    type: TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_OPEN,
  };
}

export function trackEmpInfoDrawerDiscoverPerksMore() {
  return {
    type: TRACK_EMP_INFO_DRAWER_DISCOVER_PERKS_MORE,
  };
}

export function trackMembershipSkipStep(stepName) {
  return {
    type: TRACK_MEMBERSHIP_SKIP_STEP,
    stepName,
  };
}
export function trackEmpNewTokenModalCTAClick(eventLabel) {
  return {
    type: TRACK_EMP_NEW_TOKEN_MODAL_CTA_CLICK,
    eventLabel,
  };
}
