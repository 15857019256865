import React, { FC } from 'react';

import styled from 'styled-components';

import { mobile } from '../../styles';
import { v2 } from '../../utils/themeVersioning';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.spacing.moderate / 2}px 0
    ${props => props.theme.spacing.moderate / 2}px;
  gap: ${props => props.theme.spacing.small}px;

  ${mobile`
    justify-content: center;
  `}
`;

const NavTitle = styled.h3`
  > span {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-transform: uppercase;
    height: 24px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: left;

    ${mobile`
      text-wrap: wrap;
    `}
    ${v2`
      ${({ theme }) => theme.subHeader.variants.subHeading2Uppercase.textStyles}
    `}
  }
`;

const NavLogo = styled.div`
  height: 24px;
`;

type NavLogoWithTitleProps = {
  navLogo: JSX.Element;
  title: string;
};

const NavLogoWithTitle: FC<NavLogoWithTitleProps> = ({ navLogo, title }) => {
  return (
    <Wrapper>
      <NavLogo>{navLogo}</NavLogo>
      <NavTitle>{title}</NavTitle>
    </Wrapper>
  );
};
export default NavLogoWithTitle;
