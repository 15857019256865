import { useContext, useEffect, useState } from 'react';

import { useFeature } from '@techstyle/react-features';

import { Component as MetaNavProvider } from '../components/MetaNavProvider';
import { MetaNavState } from '../constants/metaNavState';

export const useTopNavSelector = () => {
  const LINGERIE_NAV = 'updated_nav';
  const sportUnlockFeatureEnabled = useFeature('sport_unlock')?.isEnabled;
  const { metaNavState } = useContext(MetaNavProvider.Context);
  const [selectedNav, setSelectedNav] = useState(metaNavState);

  useEffect(() => {
    switch (metaNavState) {
      case MetaNavState.SPORT:
        if (sportUnlockFeatureEnabled) {
          setSelectedNav(LINGERIE_NAV);
        } else {
          setSelectedNav(LINGERIE_NAV);
        }
        break;
      case MetaNavState.LINGERIE:
      default:
        setSelectedNav(LINGERIE_NAV);
    }
  }, [metaNavState, sportUnlockFeatureEnabled]);

  return selectedNav;
};
