import Routes from 'next-routes';

const routes = Routes();

routes
  .add('/featured/:pageKey', 'featuredTemplate')
  .add('/products/:productType(sets)?/:productId(\\d+)', 'product')
  .add('/shop/:productType(sets)?/:permalink-:productId(\\d+)', 'product');

// Rewrites compatibility! This will conditionally enable patterns as necessary,
// depending on whether rewrites are enabled or disabled. Rewrites are
// conditionally enabled due to A/B testing BMIG grids vs. existing SX grids.
// This is temporary until we have rewrites SPA navigation support.
//
// All the routes within this condition are expected to be in the rewrites tool!
if (
  // If we're on the server, it doesn't matter whether rewrites are enabled or
  // disabled: if they're enabled, and they would match this route, then they
  // would have already intercepted that render.
  !process.browser ||
  // Jest won't have `__NEXT_DATA__` set during tests...
  !window.__NEXT_DATA__ ||
  // In the browser, we need to know whether rewrites are enabled to know
  // whether this route pattern should mask the server/non-SPA navigation
  // fallback.
  !window.__NEXT_DATA__.props.initialProps.rewritesEnabled
) {
  routes
    .add(
      '/products/:defaultFpls(new|new-vip)/:section?/:subsection?',
      'products'
    )
    .add('/products/:section?/:subsection?', 'products')
    .add('/:collection(features/[\\w-]+)', 'products')
    .add('/byo/:section?', 'products')
    .add('/:collection(sale)/:section?/:subsection?', 'products');
}

routes
  .add('/account/address/:address(\\d+)', 'account/address')
  .add('/account/payment/:payment(\\d+)', 'account/payment')
  .add('/account/orderdetails/:orderId(\\d+)', 'account/orderdetails')
  .add('/account/initiatereturn/:orderId(\\d+)', 'account/initiatereturn')
  .add('/account/completereturn/:orderId(\\d+)', 'account/completereturn')
  .add('/account/returnconfirmation/:rmaId(\\d+)', 'account/returnconfirmation')
  .add('/account/writereview/:reviewId(\\d+)', 'account/writereview')
  .add(
    '/checkout/order-confirmation/:orderId(\\d+)',
    'checkout/order-confirmation'
  )
  .add('/:help(help|hilfe|aide|ayuda)/faq', 'help/faq')
  .add('/:help(help|hilfe|aide|ayuda)/contact', 'help/contact')
  .add('/:collection(xtrasavage)', 'xtrasavage')
  .add('/dmg/:dmgId', 'dmg')
  .add('/dms:siteId', 'dms')
  .add('/tickets', 'show');

const { Link, Router } = routes;

export default routes;
export { Link, Router };
