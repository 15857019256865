const addToCartFailEvents = ({ trackEvent }) => {
  const { TRACK_ADD_TO_CART_FAILURE } = require('../../actions/checkout');
  const addToCartFail = trackEvent((action, prevState, nextState) => {
    let errorDetails = null;
    if (action.error && action.error.errors && action.error.errors.length) {
      errorDetails = JSON.stringify(action.error.errors[0]);
    }
    return {
      name: 'Add to Cart Error',
      properties: {
        session_id: nextState.session.sessionId.toString(),
        customer_id: nextState.customer.id
          ? nextState.customer.id.toString()
          : null,
        product_id: action.meta.productId.toString(),
        bundle_product_id: null,
        is_bundle: false,
        error_status_code: action.error.statusCode
          ? action.error.statusCode.toString()
          : '',
        error_message: action.error.message ? action.error.message : '',
        error_details: errorDetails,
      },
    };
  });

  return { [TRACK_ADD_TO_CART_FAILURE]: addToCartFail };
};

export default addToCartFailEvents;
