import config from 'config';

export function getCountryRedirect(country, domain, getDomainRedirect) {
  if (!country) {
    return;
  }
  const countryDomains = config.get('public.borderfree.countryDomains');
  const countryTLD = countryDomains[country];
  if (countryTLD && domain.tld !== countryTLD) {
    return getDomainRedirect({ tld: countryTLD });
  }
}
